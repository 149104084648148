import { Box, RadioGroup, styled } from "@mui/material";

export const StyledInnerBox = styled(Box)({
  display: "flex",
  flexDirection: "column",
  gap: "24px",
});

export const StyledRadioGroup = styled(RadioGroup)({
  width: "59px",
  marginTop: "-16px",
});

export const StyledFormFieldBox = styled(Box)({
  width: "48%",
  display: "flex",
});
