// General imports
import React from "react";

import Autocomplete from "@mui/material/Autocomplete";
// import { Autocomplete } from "material-ui-formik-components/Autocomplete";
import { fieldToTextField } from "formik-material-ui";

// Components
import { CssTextField } from "../../../../../components/css-text-field/css-text-field.component";

/**
 * LeadTimeAutoComplete:
 * This functional component is used to allow the supplier to filter  the duration to finish the part.
 *
 * @param {objects} props
 */
export const LeadTimeAutoComplete = ({ textFieldProps, ...props }) => {
  const {
    form: { setTouched, setFieldValue },
  } = props;
  const { name, error, helperText, ...field } = fieldToTextField(props);

  return (
    <div>
      <Autocomplete
        id={name}
        {...props}
        {...field}
        onBlur={() => setTouched({ [name]: true })}
        onChange={(_, newValue) => {
          setFieldValue("leadTime", newValue ? newValue : "");
        }}
        getOptionLabel={(option) => option.name || ""}
        style={{ width: "100%" }}
        renderInput={(params) => (
          <CssTextField
            {...params}
            {...textFieldProps}
            helperText={helperText}
            error={error}
          />
        )}
      />
    </div>
  );
};
