import React, { useCallback, useEffect, useState } from "react";
import {
  Autocomplete,
  Box,
  ListItem,
  SelectChangeEvent,
  TextField
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import {
  selectIsFormCompleted,
  selectQuestionnaireName,
  selectSubtierCompanies,
  selectVisibleSteps
} from "../../redux/selectors";
import { CustomTypography } from "@next/components/custom-typography";
import {
  PaginationSizeForFormTable,
  PartialStep,
  Questionnaire,
  Spec,
  vendorManagementActions
} from "../../redux";
import { getActiveStepIndex } from "../../utils/getActiveStepIndex";
import { t } from "assets/configi18n/i18n";
import * as S from "./select-supplier-auto-complete.styled";
import { debounce } from "lodash";

type Props = {
  row: any;
  searchValue: string;
  setSearchValue: (searchValue: string) => void;
};

export const SelectSupplierAutoComplete: React.FC<Props> = ({
  row,
  searchValue,
  setSearchValue
}) => {
  const { companyId } = useParams<{ companyId: string }>();
  const dispatch = useDispatch();
  const isFormCompleted = useSelector(selectIsFormCompleted);
  const steps = useSelector(selectVisibleSteps);
  const activeFormStepIndex = getActiveStepIndex(steps);
  const step = steps[activeFormStepIndex];
  const questionnaireName = useSelector(selectQuestionnaireName);
  const isTableInPomDistributorQuestionnaire =
    questionnaireName === Questionnaire.PomDistributor;
  const [open, setOpen] = useState<boolean>(false);
  const [localSearchValue, setLocalSearchValue] = useState(searchValue);
  const [selectedSupplier, setSelectedSuppliers] = useState(
    row?.source_of_supply || []
  );
  const subtierCompaniesForAutoCompleteOptions = useSelector(
    selectSubtierCompanies
  );
  const autoCompleteOptions = subtierCompaniesForAutoCompleteOptions || [];

  const onChangeHandler = (value, reason) => {
    if (reason === "clear") {
      saveSelectedSupplier(value);
    }
    setSelectedSuppliers(value);
  };
  const onCloseHandler = () => {
    saveSelectedSupplier();
    setOpen(false);
  };

  const saveSelectedSupplier = (newSelection = null) => {
    if (!newSelection) {
      newSelection = selectedSupplier;
    }
    const specification_id = row?.specification_id;
    if (!specification_id) return; // Can't save data without specification id

    const manufacturingSites = newSelection
      .map((item) => item.manufacturing_site_address)
      .join(",");

    const spec: Spec = {
      source_of_supply: newSelection.map((item) => item.company_id),
      manufacturing_site: manufacturingSites
    };

    dispatch(
      vendorManagementActions.saveSpecOfScopeRequest({
        specificationId: specification_id,
        spec: spec,
        companyId: companyId,
        onSuccess: onUpdateSuccess
      })
    );
  };

  const debouncedSetSearchValue = useCallback(
    debounce((value: string) => {
      setSearchValue(value);
    }, 300),
    [setSearchValue]
  );

  useEffect(() => {
    return () => {
      debouncedSetSearchValue.cancel();
    };
  }, [debouncedSetSearchValue]);

  const handleSearchInputChange = (event: SelectChangeEvent) => {
    const localSearchValue = event.target.value;
    setLocalSearchValue(localSearchValue);
    debouncedSetSearchValue(localSearchValue);
  };

  const renderInput = (params: any) => {
    return (
      <TextField
        {...params}
        value={localSearchValue}
        onChange={handleSearchInputChange}
        onKeyDown={(event) => event.stopPropagation()}
        onClick={() => setOpen(true)}
        variant="standard"
        placeholder={selectedSupplier.length > 0 ? "" : "Search supplier..."}
        InputProps={{
          ...params.InputProps,
          disableUnderline: true
        }}
      />
    );
  };

  const renderTags = (tags) => {
    return (
      <Box pl={1} maxWidth="325px">
        {tags.length > 1 ? (
          <CustomTypography variant="body1">
            {t("orders:filterItems", { count: tags.length })}
          </CustomTypography>
        ) : (
          <CustomTypography variant="body1">
            {tags?.[0]?.title}
          </CustomTypography>
        )}
      </Box>
    );
  };

  const onUpdateSuccess = () => {
    reFetchTableDataToGetUpdatedCount();
    checkInputChange();
  };

  const reFetchTableDataToGetUpdatedCount = () => {
    dispatch(
      vendorManagementActions.fetchSpecsOfScopeRequest({
        companyId,
        pagination: { page: 1, pageSize: PaginationSizeForFormTable }, // pagination and filter_completed are hardcoded, this request is used only to get the count data
        isInPomDistributor: isTableInPomDistributorQuestionnaire,
        stepId: step.step,
        filter_completed: false,
        isForFetchingToGetCountData: true
      })
    );
  };

  const checkInputChange = () => {
    if (isFormCompleted) {
      updateFieldStatusOfStep("is_completed", false, activeFormStepIndex);
    } else {
      updateFieldStatusOfStep("is_partially_filled", true, activeFormStepIndex);
    }
  };

  const updateFieldStatusOfStep = (
    key: "is_completed" | "is_partially_filled",
    value: boolean,
    stepIndex: number
  ) => {
    const step = steps[stepIndex];
    const partialStep: PartialStep = {
      [key]: value
    };
    dispatch(
      vendorManagementActions.updateStepRequest({
        stepId: step.id,
        data: partialStep
      })
    );
  };

  return (
    <Autocomplete
      multiple={true}
      value={selectedSupplier}
      options={autoCompleteOptions}
      onClose={onCloseHandler}
      onChange={(event, value, reason) => onChangeHandler(value, reason)}
      disableCloseOnSelect
      isOptionEqualToValue={(option, values) =>
        option.company_id === values.company_id
      }
      renderTags={renderTags}
      getOptionLabel={(option) => option.title}
      renderInput={renderInput}
      fullWidth
      open={open}
      sx={{ width: "446px" }}
      renderOption={(props, option) => (
        <ListItem {...props}>
          <Box display="flex" flexDirection="column" maxWidth="400px">
            <S.StyledTypography>{option.title}</S.StyledTypography>
            <S.StyledSubHeadingTypography>
              {option?.address}
            </S.StyledSubHeadingTypography>
          </Box>
        </ListItem>
      )}
    />
  );
};
