import {
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Theme,
} from "@mui/material";
import React from "react";
import FileCopyOutlinedIcon from "@mui/icons-material/FileCopyOutlined";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import { useTranslation } from "react-i18next";
import { createStyles, makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    listItemIcon: {
      minWidth: "32px",
    },
    icon: {
      width: "20px",
      height: "20px",
      color: theme.palette.text.primary,
    },
  })
);

export enum ProjectPartTableMenuItemType {
  CLONE = "CLONE",
  DELETE = "DELETE",
}

type Props = {
  anchorEl: null | Element;
  setAnchorEl: (anchorEl: null | Element) => void;
  onClickMenuItem: (menuItem: ProjectPartTableMenuItemType) => void;
  disabledClone?: boolean;
  disabledDelete?: boolean;
};

export const ProjectPartTableEditMenu: React.FC<Props> = ({
  anchorEl,
  setAnchorEl,
  onClickMenuItem,
  disabledClone,
  disabledDelete,
}) => {
  const { t } = useTranslation();
  const classes = useStyles();

  const onClick = (menuItem: ProjectPartTableMenuItemType) => () =>
    onClickMenuItem(menuItem);

  return (
    <Menu
      keepMounted
      open={Boolean(anchorEl)}
      anchorEl={anchorEl}
      onClose={() => setAnchorEl(null)}
      anchorOrigin={{
        vertical: "top",
        horizontal: "left",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "left",
      }}
    >
      <MenuItem
        onClick={onClick(ProjectPartTableMenuItemType.CLONE)}
        disabled={disabledClone}
      >
        <ListItemIcon className={classes.listItemIcon}>
          <FileCopyOutlinedIcon className={classes.icon} />
        </ListItemIcon>
        <ListItemText primary={t("common:clone")} />
      </MenuItem>

      <MenuItem
        onClick={onClick(ProjectPartTableMenuItemType.DELETE)}
        disabled={disabledDelete}
      >
        <ListItemIcon className={classes.listItemIcon}>
          <DeleteOutlineOutlinedIcon className={classes.icon} />
        </ListItemIcon>
        <ListItemText primary={t("common:delete")} />
      </MenuItem>
    </Menu>
  );
};
