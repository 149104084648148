import {
  Box,
  List,
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
  Paper,
  Theme,
  Typography,
} from "@mui/material";
import { RfqDetails, RfqInfo } from "@next/modules/project/redux";
import React from "react";
import { useTranslation } from "react-i18next";
import EventAvailableOutlinedIcon from "@mui/icons-material/EventAvailableOutlined";
import FlagOutlinedIcon from "@mui/icons-material/FlagOutlined";
import { formatDate } from "helpers/formatDate";
import { createStyles, makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      width: "100%",
      overflow: "auto",
      maxHeight: "507px",
    },
    dates: {
      display: "flex",
      alignItems: "center",
      marginTop: "8px",
    },
    dateIcon: {
      width: "17px",
      color: theme.palette.text.disabled,
    },
    dateItem: {
      display: "flex",
      alignItems: "center",
    },
    dateText: {
      display: "flex",
      alignItems: "center",
      marginLeft: theme.spacing(0.7),
    },
    dateDivider: {
      margin: theme.spacing(0, 1.2),
      color: theme.palette.text.disabled,
    },
    dateGreyText: {
      color: theme.palette.action.active,
    },
  })
);

type Props = {
  rfq: Partial<RfqDetails & RfqInfo>;
};

export const RfqInformations: React.FC<Props> = ({ rfq }) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const isRFI = rfq?.request_type === "rfi";

  return (
    <Paper variant="outlined">
      <ListItem divider>
        <ListItemText
          primary={
            <>
              <Box mb={1}>
                <Typography variant="body2" className={classes.dateGreyText}>
                  {t(
                    isRFI
                      ? "workspaceNext:QA:requestForInformation"
                      : "workspaceNext:QA:requestForQuotes"
                  )}
                </Typography>
              </Box>

              <Typography variant="body1">{rfq?.rfq_display_name}</Typography>

              {rfq?.reference_number ? (
                <Typography variant="body1">
                  {t("QAPartsTable:reference")}: {rfq?.reference_number}
                </Typography>
              ) : null}

              <div className={classes.dates}>
                <div className={classes.dateItem}>
                  <EventAvailableOutlinedIcon className={classes.dateIcon} />

                  <div className={classes.dateText}>
                    <Typography
                      variant="body2"
                      className={classes.dateGreyText}
                    >
                      {t("workspaceNext:QA:createdAt")}
                    </Typography>

                    <Typography variant="body2">
                      {formatDate(rfq?.created_at, "DD-MMM-YYYY")}{" "}
                    </Typography>
                  </div>
                </div>

                <div className={classes.dateDivider}>·</div>

                <div className={classes.dateItem}>
                  <FlagOutlinedIcon className={classes.dateIcon} />

                  <div className={classes.dateText}>
                    <Typography
                      variant="body2"
                      className={classes.dateGreyText}
                    >
                      {t("workspaceNext:QA:endsOn")}
                    </Typography>

                    <Typography variant="body2">
                      {formatDate(rfq?.deadline, "DD-MMM-YYYY")}
                    </Typography>
                  </div>
                </div>
              </div>
            </>
          }
        />
      </ListItem>

      <List component="nav" className={classes.container}>
        <ListItem key="list-header">
          <ListItemText
            primary={<strong>{t("workspaceNext:QA:partNumber")}</strong>}
          />
          <ListItemSecondaryAction>
            <Typography>
              <strong>{t("workspaceNext:QA:qty")}</strong>
            </Typography>
          </ListItemSecondaryAction>
        </ListItem>

        {rfq?.parts?.map((part, index) => (
          <ListItem key={index}>
            <ListItemText primary={part?.name} />
            <ListItemSecondaryAction>
              <Typography color="textSecondary">x {part?.quantity}</Typography>
            </ListItemSecondaryAction>
          </ListItem>
        ))}
      </List>
    </Paper>
  );
};
