import React, { useEffect, useRef, useState } from "react";
import { Box, Grid, Link, Theme, Typography } from "@mui/material";
import { Form, Formik, FormikProps } from "formik";
import { Trans, useTranslation } from "react-i18next";
import { GenericTextField } from "components/generic-text-field/generic-text-field.component";
import CustomButton from "@next/components/custom-button";
import { profileActions } from "services/profile";
import { useDispatch, useSelector } from "react-redux";
import { getSelectedLanguage } from "@next/utils/browserUtils";
import { selectIsRegisterLoading } from "services/profile/profile.selectors";
import { frontendUrl } from "urls";
import { popupIframe } from "helpers/popupIframe";
import { workspaceNextActions } from "@next/modules/workspace/redux";
import { selectCheckEmailExistsLoading } from "@next/modules/workspace/redux/selectors";
import { GenericPasswordField } from "components/generic-password-field/generic-password-field.component";
import axyaLogo from "assets/img/axya-logo.svg";

import {
  signUpFormInitialValues,
  signUpFormValidations,
} from "./sign-up.definitions";
import { useEnterKeyListener } from "@next/hooks/useEnterKeyListener";
import { createStyles, makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      textAlign: "center",
      gap: theme.spacing(6),
    },
    alreadyHaveAccountBtn: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      marginTop: theme.spacing(2),
    },
    emailTextField: {
      "& .MuiInputBase-root.Mui-disabled": {
        backgroundColor: theme.palette.divider,
        color: theme.palette.text.primary,
        border: "none",
      },
    },
  })
);

export interface SignupFormValues {
  first_name: string;
  last_name: string;
  email: string;
  password1: string;
  how?: string;
}

interface Props {
  onSignUp: (values: SignupFormValues) => void;
  isJoinTeam?: boolean;
  email?: string;
  first_name?: string;
  last_name?: string;
  fromPortal?: boolean;
}

export const Signup: React.FC<Props> = ({
  onSignUp,
  isJoinTeam,
  email,
  first_name,
  last_name,
  fromPortal,
}) => {
  const formRef = useRef<FormikProps<SignupFormValues>>(null);
  const { t } = useTranslation();
  const classes = useStyles();
  const dispatch = useDispatch();
  const activeLanguage = getSelectedLanguage();
  const isRegisterLoading = useSelector(selectIsRegisterLoading);
  const [termsLink, setTermsLink] = useState("");
  const [policiesLink, setPoliciesLink] = useState("");
  const checkEmailAlreadyExistLoading = useSelector(
    selectCheckEmailExistsLoading
  );

  const handleSignUpSubmit = (values: SignupFormValues) => {
    if (fromPortal) {
      delete values.how;
    }

    if (isJoinTeam) {
      let user = {
        ...values,
        password2: values.password1,
        language: activeLanguage,
      };
      dispatch(profileActions.register(user));
    } else {
      dispatch(
        workspaceNextActions.checkEmailAlreadyExistsRequest({
          email: values.email,
          onSuccess: () => {
            onSignUp(values);
          },
        })
      );
    }
  };

  useEffect(() => {
    if (activeLanguage === "fr") {
      // setTermsLink("https://grad4.com/termes-et-conditions/");
      setTermsLink("https://axya.co/en/buyers-terms-of-services");
      setPoliciesLink("https://grad4.com/politique-de-confidentialite/");
    } else {
      // setTermsLink("https://grad4.com/en/terms-and-conditions/");
      setTermsLink("https://axya.co/en/buyers-terms-of-services");
      setPoliciesLink("https://grad4.com/en/privacy-policy/");
    }
  }, [activeLanguage]);

  useEnterKeyListener(() => {
    // eslint-disable-next-line @typescript-eslint/no-unused-expressions
    formRef?.current?.submitForm();
  });

  return (
    <Formik
      initialValues={signUpFormInitialValues({ email, first_name, last_name })}
      onSubmit={handleSignUpSubmit}
      validationSchema={signUpFormValidations(fromPortal)}
      innerRef={formRef}
    >
      {({ handleSubmit }) => (
        <Form onSubmit={handleSubmit}>
          <Box className={classes.root}>
            <img src={axyaLogo} alt={"Axya Logo"} />
            <Typography variant="h4">
              {isJoinTeam
                ? t("userOnboarding:joinYourTeam")
                : t("userOnboarding:signUp")}
            </Typography>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <GenericTextField
                  name="email"
                  label={isJoinTeam ? "" : t("userOnboarding:email")}
                  size="small"
                  disabled={isJoinTeam}
                  className={classes.emailTextField}
                />
              </Grid>
              <Grid item xs={6}>
                <GenericTextField
                  name="first_name"
                  label={t("userOnboarding:firstName")}
                  size="small"
                />
              </Grid>
              <Grid item xs={6}>
                <GenericTextField
                  name="last_name"
                  label={t("userOnboarding:lastName")}
                  size="small"
                />
              </Grid>
              <Grid item xs={12}>
                <GenericPasswordField
                  name="password1"
                  label={t("userOnboarding:password")}
                  size="small"
                  helperText={t("userOnboarding:passwordHelperText")}
                />
              </Grid>
              {!fromPortal && (
                <Grid item xs={12}>
                  <GenericTextField
                    name="how"
                    label={t("userOnboarding:howYouHearAboutUs")}
                    size="small"
                  />
                </Grid>
              )}
            </Grid>
            <Box>
              <Trans
                i18nKey="userOnboarding:termsAndPrivacyPolicy"
                components={[
                  <Link
                    style={{ cursor: "pointer" }}
                    onClick={() => popupIframe({ url: termsLink })}
                  >
                    Terms of Services
                  </Link>,
                  <Link
                    style={{ cursor: "pointer" }}
                    onClick={() => popupIframe({ url: policiesLink })}
                  >
                    Privacy Policy
                  </Link>,
                ]}
              />
            </Box>
            <CustomButton
              type="submit"
              variant="contained"
              color="primary"
              fullWidth
              loading={isRegisterLoading || checkEmailAlreadyExistLoading}
              data-pid={"btnRegisterSignupDetails"}
            >
              {t("userOnboarding:signUp")}
            </CustomButton>
          </Box>
          <Box className={classes.alreadyHaveAccountBtn}>
            <CustomButton
              variant="text"
              href={frontendUrl.login}
              color="primary"
              fullWidth
              data-pid={"btnAlreadyHaveAnAccount"}
            >
              {t("userOnboarding:alreadyHaveAnAccount")}
            </CustomButton>
          </Box>
        </Form>
      )}
    </Formik>
  );
};
