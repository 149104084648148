import React from "react";
import { Chip, Theme, alpha } from "@mui/material";
import ErrorOutlineOutlinedIcon from "@mui/icons-material/ErrorOutlineOutlined";
import GavelIcon from "@mui/icons-material/Gavel";
import { useTranslation } from "react-i18next";
import { formatDate } from "helpers/formatDate";

import { createStyles, makeStyles } from "@mui/styles";

type Variant = "revision-requested" | "awarded";

const useStyles = makeStyles<Theme, Props>((theme) => {
  const getColor = (variant: Variant) =>
    variant === "revision-requested" ? "warning" : "success";

  return createStyles({
    tag: {
      cursor: "unset",
      textOverflow: "ellipsis",
      overflow: "hidden",
      color: ({ variant }) => theme.palette[getColor(variant)].dark,
      backgroundColor: ({ withBg, variant }) =>
        withBg
          ? alpha(theme.palette[getColor(variant)].main, 0.1)
          : "transparent",
      fontSize: theme.typography.caption.fontSize,

      "& .MuiChip-icon": {
        color: ({ variant }) => theme.palette[getColor(variant)].main,
        fontSize: 20,
        marginLeft: ({ withBg }) => (withBg ? undefined : 0),
        marginRight: -8,
      },
    },
  });
});

interface Props {
  withBg?: boolean;
  variant: Variant;
  deadline?: string;
}

const StatusVariantTag: React.FC<Props> = ({ withBg, variant, deadline }) => {
  const { t } = useTranslation();
  const classes = useStyles({ withBg, variant });

  const labelKey =
    variant === "revision-requested" ? "revisionRequested" : "awarded";

  const icon =
    variant === "revision-requested" ? (
      <ErrorOutlineOutlinedIcon />
    ) : (
      <GavelIcon style={{ fontSize: 16 }} />
    );

  return (
    <Chip
      icon={icon}
      label={t(`tabView:${labelKey}`, {
        date: deadline ? formatDate(deadline, "DD-MMM-YYYY") : "",
      })}
      className={classes.tag}
    />
  );
};

export default StatusVariantTag;
