import React, { useCallback } from "react";
import { Box, lighten } from "@mui/material";
import { useTranslation } from "react-i18next";
import { RFQPartsRevisionModal } from "@next/modules/workspace/modals/rfq-parts-revision-modal/rfq-parts-revision-modal";
import { modalsActions } from "@next/redux/modalsSlices";
import { WorkspaceModalTypes } from "@next/modules/workspace/modals/types";
import { useDispatch, useSelector } from "react-redux";
import { useHasCompanyRole } from "@next/hooks/useHasCompanyRole";
import CustomButton from "@next/components/custom-button";
import { createStyles, makeStyles } from "@mui/styles";
import {
  selectRevisionSendInProgress,
  selectSendRevisionLoading,
} from "@next/modules/workspace/redux/selectors";
import { getIsRFQPartModified } from "services/workspace/workspace.selectors";

const useStyles = makeStyles((theme) =>
  createStyles({
    rootCommon: {
      display: "flex",
      borderRadius: theme.spacing(1),
      padding: theme.spacing(1.5),
    },
    rootRevision: {
      flexDirection: "column",
      color: theme.palette.primary.main,
    },
    rootEdit: {
      alignItems: "center",
      gap: theme.spacing(1),
      color: theme.palette.common.black,
    },
    dismissBtn: {
      backgroundColor: theme.palette.common.white,
      "&:hover": {
        backgroundColor: lighten(theme.palette.common.black, 0.92),
      },
    },
    icon: {
      fontSize: 21,
    },
    actionButtonsWrapper: {
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
      padding: "24px 0",
      gap: "16px",
    },
    actionButtonStyle: {
      padding: "10px 16px",
    },
  })
);

interface Props {
  onDismiss?: () => void;
}

const RFQPartsStatusBar: React.FC<Props> = ({ onDismiss }) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const dispatch = useDispatch();
  const sendRevisionLoading = useSelector(selectSendRevisionLoading);
  const revisionSendInProgress = useSelector(selectRevisionSendInProgress);
  const isRFQPartModified = useSelector(getIsRFQPartModified);

  const hasViewerOrRestrictedViewerRole = useHasCompanyRole([
    "viewer",
    "restricted_viewer",
  ]);

  const onSend = useCallback(() => {
    dispatch(
      modalsActions.showModal({
        key: WorkspaceModalTypes.RFQ_PARTS_REVISION_MODAL,
      })
    );
  }, []);

  if (hasViewerOrRestrictedViewerRole) return null;

  return (
    <Box className={classes.actionButtonsWrapper}>
      <CustomButton
        className={classes.actionButtonStyle}
        variant="text"
        disabled={!isRFQPartModified}
        onClick={() => {
          dispatch(
            modalsActions.showModal({
              key: WorkspaceModalTypes.CANCEL_EDIT_RFQ_MODAL,
            })
          );
        }}
      >
        {t("project:actionCancelChanges")}
      </CustomButton>
      <CustomButton
        variant="outlined"
        className={classes.actionButtonStyle}
        onClick={onDismiss}
        loading={sendRevisionLoading}
        disabled={!isRFQPartModified || revisionSendInProgress}
      >
        {t("project:withoutRevisionNote")}
      </CustomButton>
      <CustomButton
        variant="contained"
        className={classes.actionButtonStyle}
        onClick={onSend}
        disabled={!isRFQPartModified || revisionSendInProgress}
      >
        {t("project:withRevisionNote")}
      </CustomButton>
      <RFQPartsRevisionModal />
    </Box>
  );
};

export default RFQPartsStatusBar;
