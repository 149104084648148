import { Theme, alpha } from "@mui/material";
import CustomButton from "@next/components/custom-button/custom-button";
import React, { PropsWithChildren } from "react";
import { amqCompanyPrimaryColor } from "../public-rfq-creation-form/public-rfq-creation-form.base";

import { createStyles, makeStyles, withStyles } from "@mui/styles";

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      display: "flex",
      justifyContent: "center",
    },
  })
);

export const OrangeOutlinedButton = withStyles((theme: Theme) => ({
  root: {
    height: 43,
    minWidth: "150px",
    "&.MuiButton-outlined": {
      color: amqCompanyPrimaryColor,
      borderColor: amqCompanyPrimaryColor,
      "&:hover": {
        backgroundColor: alpha(amqCompanyPrimaryColor, 0.1),
      },
    },
    "&.MuiButton-contained": {
      color: theme.palette.getContrastText(amqCompanyPrimaryColor),
      backgroundColor: amqCompanyPrimaryColor,
      "&:hover": {
        backgroundColor: alpha(amqCompanyPrimaryColor, 0.8),
      },
    },
  },
}))(CustomButton);

type Props = {
  onClick?: () => void;
};

const PublicRfqCreationFormButton: React.FC<PropsWithChildren<Props>> = ({
  onClick,
  children,
}) => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <OrangeOutlinedButton
        type="submit"
        variant="contained"
        size="large"
        onClick={onClick}
      >
        {children}
      </OrangeOutlinedButton>
    </div>
  );
};

export default PublicRfqCreationFormButton;
