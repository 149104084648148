export const inboxConstants = {
  FETCH_QUOTATION_MESSAGES_REQUEST: "FETCH_QUOTATION_MESSAGES_REQUEST",
  FETCH_QUOTATION_MESSAGES_SUCCESS: "FETCH_QUOTATION_MESSAGES_SUCCESS",
  FETCH_QUOTATION_MESSAGES_FAILURE: "FETCH_QUOTATION_MESSAGES_FAILURE",

  FETCH_QUOTATION_MESSAGE_DETAILS_REQUEST:
    "FETCH_QUOTATION_MESSAGE_DETAILS_REQUEST",
  FETCH_QUOTATION_MESSAGE_DETAILS_SUCCESS:
    "FETCH_QUOTATION_MESSAGE_DETAILS_SUCCESS",
  FETCH_QUOTATION_MESSAGE_DETAILS_FAILURE:
    "FETCH_QUOTATION_MESSAGE_DETAILS_FAILURE",

  POST_QUOTATION_MESSAGE_REQUEST: "POST_QUOTATION_MESSAGE_REQUEST",
  POST_QUOTATION_MESSAGE_SUCCESS: "POST_QUOTATION_MESSAGE_SUCCESS",
  POST_QUOTATION_MESSAGE_FAILURE: "POST_QUOTATION_MESSAGE_FAILURE",

  FETCH_INBOX_MESSAGES_FAILURE: "FETCH_INBOX_MESSAGES_FAILURE",
  FETCH_INBOX_MESSAGES_SUCCESS: "FETCH_INBOX_MESSAGES_SUCCESS",
  FETCH_INBOX_MESSAGES_REQUEST: "FETCH_INBOX_MESSAGES_REQUEST",

  SET_ACTIVE_QUOTATION: "SET_ACTIVE_QUOTATION",

  /**
   * Socket Constants
   */
  PUBLISH_ROOM_MESSAGE: "PUBLISH_ROOM_MESSAGE",

  INBOX_QUOTATION_MESSAGES_COUNT_UPDATE:
    "INBOX_QUOTATION_MESSAGES_COUNT_UPDATE",

  COMPANY_ONLINE_STATUS_UPDATE: "COMPANY_ONLINE_STATUS_UPDATE",

  INBOX_QUOTATION_COUNT_UPDATE: "INBOX_QUOTATION_COUNT_UPDATE",

  INBOX_MESSAGE_COUNT_UPDATE: "INBOX_MESSAGE_COUNT_UPDATE",

  INBOX_MESSAGE_QUOTATION_DROP: "INBOX_MESSAGE_QUOTATION_DROP",

  HANDLE_INBOX_FILE_UPLOAD_REQUEST: "HANDLE_INBOX_FILE_UPLOAD_REQUEST",
  HANDLE_INBOX_FILE_UPLOAD_SUCCESS: "HANDLE_INBOX_FILE_UPLOAD_SUCCESS",
  HANDLE_INBOX_FILE_UPLOAD_FAILURE: "HANDLE_INBOX_FILE_UPLOAD_FAILURE",

  DELETE_FILE_UPLOAD_SUCCESS: "DELETE_FILE_UPLOAD_SUCCESS",
  CLEAR_FILE_ATTACHMENTS: "CLEAR_FILE_ATTACHMENTS",

  SET_MINIMIZE_CHAT_WINDOW: "SET_MINIMIZE_CHAT_WINDOW",

  SET_SELECTED_FILTER_RFQ: "SET_SELECTED_FILTER_RFQ",
};
