import React from "react";
import {
  Box,
  Dialog,
  DialogContent,
  IconButton,
  Typography,
} from "@mui/material";
import { t } from "assets/configi18n/i18n";

import CloseIcon from "@mui/icons-material/Close";
import { useDispatch, useSelector } from "react-redux";
import { getModal, modalsActions } from "@next/redux/modalsSlices";
import { WorkspaceModalTypes } from "./types";
import { NDATable } from "../components/nda-table/nda-table";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  dialogTitle: { padding: theme.spacing(2) },
  dialogContent: {
    padding: theme.spacing(1),
    height: 500,
  },
}));

export const NDAListModal = () => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const modal = useSelector(getModal(WorkspaceModalTypes.NDA_LIST_MODAL));

  if (!modal) return null;

  const onClickCloseModal = () => {
    dispatch(modalsActions.closeModal(WorkspaceModalTypes.NDA_LIST_MODAL));
  };

  return (
    <>
      <Dialog
        open={!!modal}
        onClose={onClickCloseModal}
        closeAfterTransition
        fullWidth={true}
        className={classes.root}
        maxWidth={"md"}
      >
        <div className={classes.dialogTitle}>
          <Box display="flex" alignItems="center">
            <Box flexGrow={1}>
              <Typography variant="h6">
                {t("workspaceNext:ndaTable:title")}
              </Typography>
            </Box>

            <Box>
              <IconButton onClick={onClickCloseModal} size="large">
                <CloseIcon />
              </IconButton>
            </Box>
          </Box>
          <Typography variant="caption">
            {t("workspaceNext:ndaTable:ndaDescription")}
          </Typography>
        </div>

        <DialogContent className={classes.dialogContent}>
          <NDATable />
        </DialogContent>
      </Dialog>
    </>
  );
};
