import { SvgIconProps, Typography } from "@mui/material";
import React, { ReactNode } from "react";
import { createStyles, makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      display: "flex",
      alignItems: "center",
      color: theme.palette.text.primary,
    },
    featureText: {
      marginLeft: theme.spacing(0.75),
    },
  })
);

type Props = {
  Icon: React.ComponentType<SvgIconProps>;
  hidden?: boolean;
  featureText: ReactNode;
};

const CompanyFeatureWithIcon: React.FC<Props> = ({
  Icon,
  featureText,
  hidden = false,
}) => {
  const classes = useStyles();
  if (!!hidden) return null;

  return (
    <div className={classes.root}>
      <Icon />
      <Typography variant="body2" className={classes.featureText}>
        {featureText}
      </Typography>
    </div>
  );
};

export default CompanyFeatureWithIcon;
