import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button } from "@mui/material";
import { NewsfeedPost } from "../redux/types";
import { newsfeedPostTypeConstants } from "../redux/consts";
import { modalsActions } from "@next/redux/modalsSlices";
import { SharedModalTypes } from "@next/modals/types";
import { t } from "assets/configi18n/i18n";
import {
  openBuyerProfileNewTab,
  openProfileInNewTab,
} from "@next/utils/browserUtils";
import { getCompany } from "services/profile/profile.selectors";
import { checkIsSellerCompany } from "@next/utils/checkCompanyUtils";
import { CompanyTypeNext } from "@next/redux/types";
import { useCompanyPlan } from "@next/hooks/useIsPremiumCompany";

type Props = {
  post: NewsfeedPost;
};

const PostTypeButton: React.FC<Props> = ({ post }) => {
  const dispatch = useDispatch();

  const company = useSelector(getCompany);
  const isSupplier = checkIsSellerCompany(company?.type as CompanyTypeNext);
  const isPremiumCompany = useCompanyPlan("premium");

  let buttonProps;
  switch (post.type) {
    case newsfeedPostTypeConstants.NEW_BUYER:
      buttonProps = {
        text: t("newsfeed:buttons:openProfile"),
        dataPid: "Openbuyer_Newsfeed",
        onClick: () => {
          if (isSupplier && !isPremiumCompany) {
            dispatch(
              modalsActions.showModal({
                key: SharedModalTypes.UPGRADE_TO_PREMIUM_MODAL,
              })
            );
          } else {
            openBuyerProfileNewTab(post.metadata.company_id!);
          }
        },
      };
      break;
    case newsfeedPostTypeConstants.NEW_SUPPLIER:
      buttonProps = {
        text: t("newsfeed:buttons:openProfile"),
        dataPid: "Opensupplier_Newsfeed",
        onClick: () => {
          if (isSupplier && !isPremiumCompany) {
            dispatch(
              modalsActions.showModal({
                key: SharedModalTypes.UPGRADE_TO_PREMIUM_MODAL,
              })
            );
          } else {
            openProfileInNewTab(post.title, String(post.metadata.company_id));
          }
        },
      };
      break;
    case newsfeedPostTypeConstants.ANNOUNCEMENT:
      buttonProps = {
        text: t("newsfeed:buttons:participate"),
        dataPid: "Announcement_Newsfeed",
        onClick: () => window.open(post.url, "_blank"),
      };
      break;
    case newsfeedPostTypeConstants.INDUSTRY_NEWS:
      buttonProps = {
        text: t("newsfeed:buttons:open"),
        dataPid: "IndustryNews_Newsfeed",
        onClick: () => window.open(post.url, "_blank"),
      };
      break;
    default:
      buttonProps = {};
  }

  return (
    <Button
      color="primary"
      variant="outlined"
      data-pid={buttonProps.dataPid}
      onClick={buttonProps.onClick}
      onAuxClick={buttonProps.onClick}
    >
      {buttonProps.text}
    </Button>
  );
};

export default PostTypeButton;
