import { FileData } from "@next/components/attach-file/attach-file-list.types";
import moment from "moment";
import * as yup from "yup";
import { Order } from "../../redux";
import { t } from "assets/configi18n/i18n";

export type SendOrderFormState = {
  name: string;
  note: string;
  delivery_date: string;
  files: FileData[];
  created_by?: string;
  supplier?: { pk?: number };
};

export const sendOrderFormValidationSchema = yup.object({
  name: yup.string().required(t("common:error:thisFieldIsRequired")),
  note: yup.string().nullable(),
  delivery_date: yup.date().required(t("common:error:thisFieldIsRequired")),
  files: yup.array(),
  created_by: yup.string().nullable(),
});

export const getSendOrderFormInitialValues = (
  initialOrder: Partial<Order> | undefined
): SendOrderFormState => {
  return {
    name: initialOrder?.name || "",
    note: initialOrder?.note || "",
    delivery_date: initialOrder?.delivery_date || moment().format("YYYY-MM-DD"),
    files: initialOrder?.files || [],
    created_by: undefined,
    supplier: undefined,
  };
};
