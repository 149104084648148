/**
 * Use this component to display characteristics of parts
 */

import React, { useState } from "react";

import { Box, Card, CardContent, Grid } from "@mui/material";

import CurrencyField from "../../../components/common/fields/CurrencyField";
import PartDetailsDialog from "../../../components/part-dialog/part-details-dialog.component";
import { PartTilesDetails } from "./part-tiles-details/part-tiles-details.component";

export const DetailsSummaryPartTiles = (props) => {
  const { part, quotation, t, isSeller } = props;
  const [open, setOpen] = useState(false);

  const clickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Grid item xs={12} className="c-details-summary-part-tiles">
      <Box display="flex" className="c-details-summary-part-tiles--price">
        <PartTilesDetails
          part={part}
          clickOpen={clickOpen}
          quotation={{
            rfqPk: quotation?.rfq_info?.pk,
            rfqName: quotation?.rfq_info?.rfq_name,
          }}
          t={t}
          isSeller={isSeller}
        />
        <Card elevation={0} className="c-details-summary-part-tiles--card">
          <CardContent className="c-details-summary-part-tiles--card-content border-content">
            <Box display="flex" justifyContent="center" m={1}>
              <Box
                textAlign="left"
                fontWeight="fontWeightRegular"
                fontSize={16}
                className="c-box"
              >
                {t("quantity")}
              </Box>
              <Box
                flexGrow={1}
                textAlign="center"
                fontWeight="fontWeightMedium"
                fontSize={16}
                className="c-details-summary-part-tiles--quantity c-box"
              >
                {part.quantity}
              </Box>
            </Box>
          </CardContent>
          <CardContent className="c-details-summary-part-tiles--card-content">
            <Box display="flex" justifyContent="center" m={2}>
              <Box
                textAlign="left"
                fontWeight="fontWeightRegular"
                fontSize={16}
                className="c-box"
              >
                {t("unitPrice")}
              </Box>
              <Box
                flexGrow={1}
                textAlign="center"
                fontWeight="fontWeightMedium"
                fontSize="h6.fontSize"
                className="c-box"
              >
                <CurrencyField value={part.unitPrice} />
              </Box>
              <Box
                textAlign="right"
                fontWeight="fontWeightMedium"
                fontSize="h6.fontSize"
                className="c-box"
              >
                <CurrencyField value={part.price} />
              </Box>
            </Box>
          </CardContent>
        </Card>
        <PartDetailsDialog isOpen={open} part={part} onClose={handleClose} />
      </Box>
    </Grid>
  );
};
