/* eslint-disable react-hooks/exhaustive-deps */
import { GridRowModel, useGridApiRef } from "@mui/x-data-grid-pro-v5";
import { getAddOrderPartsTableColumns } from "./add-order-parts-table.columns";
import { v4 as uuid } from "uuid";
import { useState } from "react";
import { AddOrderPartsTableField } from "./add-order-parts-table.types";

export const ADD_ORDER_PARTS_TABLE_STATE_KEY = "ADD_ORDER_PARTS_TABLE_STATE";

export const useAddOrderPartsData = () => {
  const apiRef = useGridApiRef();
  const addOrderPartColumns = getAddOrderPartsTableColumns(apiRef);
  const [gridData, setGridData] = useState<{
    rows: GridRowModel[];
  }>({
    rows: [],
  });

  const onClickAddRow = () => {
    const id = uuid();
    apiRef.current.updateRows([
      {
        id,
        [AddOrderPartsTableField.PART_NUMBER]: "New Part",
        [AddOrderPartsTableField.QUANTITY]: 1,
      },
    ]);
    apiRef.current.setRowMode(id, "edit");

    // Wait for the grid to render with the new row
    setTimeout(() => {
      apiRef.current.scrollToIndexes({
        rowIndex: apiRef.current.getRowsCount() - 1,
      });

      apiRef.current.setCellFocus(id, "name");
    }, 100);
  };

  return {
    gridData,
    setGridData,
    columns: addOrderPartColumns,
    onClickAddRow,
    apiRef,
  };
};
