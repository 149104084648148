import React from "react";
import { useSelector } from "react-redux";
import { TopicType } from "../../redux";
import { Topic } from "../questions-answers/topic/topic";
import CollaboratorsList from "components/workspace/buyer-rfqs-view/collaborators-list";
import { getRfqDetails } from "services/workspace/workspace.selectors";
import { selectOrderByIsPortal } from "@next/modules/orders/redux/selectors";
import AddIcon from "@mui/icons-material/Add";
import { useHasCompanyRole } from "@next/hooks/useHasCompanyRole";

type Props = {
  isPortal?: boolean;
  isInOrderDetail?: boolean;
  topic: TopicType;
};

export const InternalCommunication: React.FC<Props> = ({
  isPortal,
  isInOrderDetail,
  topic,
}) => {
  const rfqDetails = useSelector(getRfqDetails);
  const orderDetail = useSelector(selectOrderByIsPortal(isPortal));
  const isEngineeringRole = useHasCompanyRole(["viewer", "restricted_viewer"]);

  return (
    <Topic
      replyDisabled={false}
      topic={topic}
      showToastOnMessage={true}
      disableAccordion
      showExpandIcon={false}
      verticalBorders
      initiallyOpen
      rfqIdForInternalChat={
        isInOrderDetail ? orderDetail?.rfq.pk : rfqDetails.pk
      }
      customComponentInFooter={
        <CollaboratorsList
          data={{
            collaborators: isInOrderDetail
              ? orderDetail?.collaborators
              : rfqDetails.collaborators,
            createdBy: isInOrderDetail
              ? orderDetail?.created_by
              : rfqDetails.created_by,
            rfqPk: isInOrderDetail ? orderDetail?.rfq.pk : rfqDetails.pk,
            poId: isInOrderDetail ? orderDetail?.pk : null,
          }}
          hideLoggedInUser
          editMode={isEngineeringRole ? false : true}
          size="small"
          customAddIcon={
            <AddIcon sx={{ color: "black", fontSize: "1.5rem" }} />
          }
          displayCollaboratorsCountToBeNotified
        />
      }
    />
  );
};
