import React from "react";

import { useDispatch, useSelector } from "react-redux";
import { Formik } from "formik";
import CompanyReferencesBase from "./company-references.form.base";
import { CompanyReferencesValues } from "./company-references.form.types";
import { selectCompanyProfile } from "@next/modules/profile/redux/selectors";
import { validationSchema } from "./company-references.form.definitions";
import { profileNextActions } from "@next/modules/profile/redux";

const CompanyReferences: React.FC = () => {
  const dispatch = useDispatch();
  const companyProfile = useSelector(selectCompanyProfile);
  const initialValues: CompanyReferencesValues = {
    references: Array.isArray(companyProfile?.references)
      ? companyProfile?.references
      : [],
  };

  const handleSubmitCompanyReferences = (values: CompanyReferencesValues) => {
    dispatch(
      profileNextActions.updateCompanyProfileRequest({
        id: companyProfile?.id,
        references: values?.references,
      })
    );
  };

  return (
    <>
      <Formik
        render={(props) => <CompanyReferencesBase {...props} />}
        initialValues={initialValues}
        onSubmit={handleSubmitCompanyReferences}
        validationSchema={validationSchema}
      />
    </>
  );
};

export default CompanyReferences;
