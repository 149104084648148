import React from "react";
import { Box, Theme, Typography } from "@mui/material";
import { CustomTypography } from "@next/components/custom-typography";
import { OrderDetailFollowupsTimelineItem } from "./order-detail-followups-timeline-item";
import { selectOrder } from "@next/modules/orders/redux/selectors";
import { useDispatch, useSelector } from "react-redux";
import { getFollowupTimelineIcon } from "./order-detail-followups-timeline.utils";
import { ordersActions } from "@next/modules/orders/redux";
import moment from "moment";
import OfflineBoltRoundedIcon from "@mui/icons-material/OfflineBoltRounded";
import TopicAccordionWrapper from "@next/modules/workspace/components/questions-answers/topic/topic-accordion-wrapper/topic-accordion-wrapper";
import { useTranslation } from "react-i18next";
import { createStyles, makeStyles } from "@mui/styles";
import { Timeline } from "@mui/lab";

type StyleProps = {
  showOnlyDynamicFollowups?: boolean;
};

const useStyles = makeStyles<Theme, StyleProps>((theme: Theme) =>
  createStyles({
    accordionDetailsRoot: {
      width: "100%",
      flexDirection: "column",
      alignItems: "stretch",
      border: "none",
      paddingLeft: theme.spacing(0),
      paddingTop: theme.spacing(0),
      paddingRight: theme.spacing(0),
    },
    root: {
      padding: 12,
      paddingTop: ({ showOnlyDynamicFollowups }) =>
        showOnlyDynamicFollowups ? theme.spacing(4) : "default",
      marginLeft: theme.spacing(2),
      textAlign: "left",
    },
    oppositeContent: {
      flex: 0,
      padding: 0,
    },
    content: {
      paddingTop: 0,
      paddingLeft: "10px",
      display: "flex",
      justifyContent: "space-between",
    },
    "& .MuiTimelineItem-root:before": {
      flex: 0,
    },
    titleIcon: {
      color: theme.palette.warning.light,
    },
  })
);

type Props = {
  descriptionText?: string;
  showOnlyDynamicFollowups?: boolean;
};

export const OrderDetailFollowupsTimeline: React.FC<Props> = ({
  showOnlyDynamicFollowups,
  descriptionText,
}) => {
  const { t } = useTranslation();
  const classes = useStyles({ showOnlyDynamicFollowups });
  const dispatch = useDispatch();
  const orderDetail = useSelector(selectOrder);

  const onClickMute = (orderFollowupPk?: number, nextIsMuted?: boolean) => {
    if (!orderFollowupPk || !orderDetail?.pk) return;

    dispatch(
      ordersActions.updateOrderFollowupRequest({
        orderPk: orderDetail?.pk,
        followupPk: orderFollowupPk,
        is_muted: !!nextIsMuted,
      })
    );
  };

  const onChangeEditDate = (
    orderFollowupPk?: number,
    event?: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => {
    if (!orderFollowupPk || !orderDetail?.pk) return;

    const newEditDate = moment(event?.target?.value).utcOffset(0, true);

    if (newEditDate?.isValid()) {
      dispatch(
        ordersActions.updateOrderFollowupRequest({
          orderPk: orderDetail?.pk,
          followupPk: orderFollowupPk,
          send_date: newEditDate.toDate(),
        })
      );
    }
  };

  return (
    <TopicAccordionWrapper
      title={
        <Box display="flex" alignItems="center">
          <OfflineBoltRoundedIcon className={classes.titleIcon} />

          <Box ml={"8px"}>
            <CustomTypography variant="h6">
              {t("orders:automatedFollowups")}
            </CustomTypography>
          </Box>
        </Box>
      }
      expandedKey="OrderDetailShipment"
      titleVariant="h6"
      initiallyOpen
      accordionDetailsClasses={{ root: classes.accordionDetailsRoot }}
      disableAccordion={true}
    >
      <Box>
        {descriptionText ? (
          <Box ml="24px">
            <Typography variant="body2" color="textSecondary">
              {descriptionText}
            </Typography>
          </Box>
        ) : null}

        <Timeline className={classes.root}>
          {orderDetail?.followups?.map((followup, index) => {
            return (
              <OrderDetailFollowupsTimelineItem
                key={index}
                followUp={followup}
                Icon={getFollowupTimelineIcon(followup?.type)}
                lastItem={index === orderDetail?.followups?.length - 1}
                onClickMute={onClickMute}
                onChangeEditDate={onChangeEditDate}
              />
            );
          })}
        </Timeline>
      </Box>
    </TopicAccordionWrapper>
  );
};
