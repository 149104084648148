import * as yup from "yup";
import { t } from "assets/configi18n/i18n";

const oneLetterOneNumberRegex = new RegExp(
  "^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])"
);

export const validationSchema = yup.object().shape({
  old_password: yup
    .string()
    .required(t("profile:validations:aPasswordIsRequired")),
  new_password1: yup
    .string()
    .required(t("profile:validations:aPasswordIsRequired"))
    .min(8, t("profile:validations:thePasswordMustBeLongerThan8Characters"))
    .oneOf(
      [yup.ref("new_password2"), null],
      t("profile:validations:passwordsDoNotMatch")
    )
    .matches(oneLetterOneNumberRegex, {
      message: t("profile:validations:passwordMinimumComplexity"),
      excludeEmptyString: true,
    }),
  new_password2: yup
    .string()
    .required(t("profile:validations:aPasswordIsRequired"))
    .min(8, t("profile:validations:thePasswordMustBeLongerThan8Characters"))
    .oneOf(
      [yup.ref("new_password1"), null],
      t("profile:validations:passwordsDoNotMatch")
    )
    .matches(oneLetterOneNumberRegex, {
      message: t("profile:validations:passwordMinimumComplexity"),
      excludeEmptyString: true,
    })
    .nullable(),
});
