import React, { useCallback, useRef, useState } from "react";
import {
  Box,
  Button,
  FormControlLabel,
  Switch,
  Tooltip,
  Typography,
} from "@material-ui/core";
import { QuoteComparisonCurrencyField } from "./quote-comparison-currency-field";
import { t } from "assets/configi18n/i18n";
import QuotesHeaderTabList, {
  quotesHeaderProps,
} from "./quote-comparison-constants";
import { createStyles, makeStyles } from "@mui/styles";
import { Menu, MenuItem, Theme } from "@mui/material";
import { InfoOutlined } from "@mui/icons-material";
import { workspaceNextActions } from "../../redux";
import { useDispatch, useSelector } from "react-redux";
import { toggleShowQuotationVariance } from "../../redux/selectors";
import { fileDownloadFromUrl } from "@next/utils/downloadUtils";
import { backendUrl } from "urls";
import useStateCallback from "@next/hooks/useStateCallback";
import { getCompany } from "services/profile/profile.selectors";
import { getRfqDetails } from "services/workspace/workspace.selectors";
import { useReactToPrint } from "react-to-print";
import { getReportsButtonAvailable } from "services/part-configuration/part-configuration.selectors";
import { downloadFile } from "helpers/download";
import { QuotationPDFViewer } from "@next/components/quotation-pdf-viewer";
import { useHasCompanyRole } from "@next/hooks/useHasCompanyRole";

type quoteComparsionHeaderContainerProps = {
  selectedData: any;
  config: any;
  autoSelectLowestPrice: any;
  onClickCustomizeView: any;
  onClickAwardSelection: any;
  isAwardedRfq: any;
  columnView: any;
  setColumnView: any;
};
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    quoteHeaderMainSection: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      marginLeft: "16px",
      marginRight: "16px",
    },
    quotesHeaderLeftSection: {
      display: "flex",
      alignItems: "center",
      gap: "4px",
      margin: "10px 0",
      flexWrap: "wrap",
    },
    leftHeaderPane: {
      display: "flex",
      alignItems: "center",
      gap: "16px",
    },
    headerRightSection: {
      display: "flex",
      alignItems: "center",
      gap: 8,
    },
    switch_track: {
      color: "green",
      backgroundColor: "red",
    },
    formControlWrapper: {
      display: "flex",
      alignItems: "center",
      gap: "8px",
    },
    formControlStyle: {
      margin: 0,
      backgroundColor: "transparent",
      "& .mui-jss-MuiSwitch-root-459": {
        "& .mui-jss-MuiSwitch-track-470": {
          backgroundColor: "#C2C2C2",
        },
        "& .mui-jss-MuiButtonBase-root-400": {
          paddingRight: "8px",
        },
      },
    },
  })
);
const QuoteComparisonHeaderContainer: React.FC<
  quoteComparsionHeaderContainerProps
> = ({
  selectedData,
  config,
  autoSelectLowestPrice,
  onClickCustomizeView,
  onClickAwardSelection,
  isAwardedRfq,
  columnView,
  setColumnView,
}) => {
  const classes = useStyles();
  const { tabsList } = QuotesHeaderTabList(columnView);
  const dispatch = useDispatch();
  const hasViewerRole = useHasCompanyRole(["viewer", "restricted_viewer"]);
  const showQuotationVariance = useSelector(toggleShowQuotationVariance);
  const company = useSelector(getCompany);
  const rfqDetails = useSelector(getRfqDetails);
  const reportsButtonAvailable = useSelector(getReportsButtonAvailable);
  const [anchorEl, setAnchorEl] = useState(null);
  const [showPDF, setShowPDF] = useStateCallback(false);

  const openExportMennu = useCallback((e) => {
    setAnchorEl(e.currentTarget);
  }, []);

  const handleChangeTab = (event: any, tabName: string) => {
    if (tabName == t("workspaceNext:quoteComparison:autoSelectLowestPrice")) {
      autoSelectLowestPrice();
    } else if (tabName == t("workspaceNext:quoteComparison:customizeView")) {
      onClickCustomizeView();
    } else if (tabName == t("workspaceNext:quoteComparison:export")) {
      openExportMennu(event);
    }
  };
  const closeExportMenu = useCallback(() => {
    setAnchorEl(null);
  }, []);

  const handleMenuItemClick = useCallback((cb) => {
    closeExportMenu();
    if (typeof cb === "function") cb();
  }, []);

  const pdfComponentRef = useRef();

  const handlePrint = useReactToPrint({
    content: () => pdfComponentRef.current,
    onAfterPrint: () => setShowPDF(false),
    documentTitle: rfqDetails.rfq_display_name,
  });

  const onClickExportQuoteComparisonSheet = () => {
    dispatch(
      workspaceNextActions.exportQuotesComparisonSheetRequest({
        rfqPk: rfqDetails.pk,
        rfqDisplayName: rfqDetails.rfq_display_name,
      })
    );
  };

  return (
    <Box className={classes.quoteHeaderMainSection}>
      <Box className={classes.quotesHeaderLeftSection}>
        <Box className={classes.leftHeaderPane}>
          <Box>
            <Button
              variant="outlined"
              color={`${columnView ? "primary" : "default"}`}
              style={{
                borderTopRightRadius: 0,
                borderBottomRightRadius: 0,
                borderRight: columnView
                  ? "1px solid rgba(38, 130, 253, 0.5)"
                  : "unset",
              }}
              onClick={() => setColumnView()}
            >
              {t("workspaceNext:quoteComparison:allQuotes")}
            </Button>
            <Button
              variant="outlined"
              color={`${columnView ? "default" : "primary"}`}
              style={{
                borderTopLeftRadius: 0,
                borderBottomLeftRadius: 0,
                borderLeft: columnView
                  ? "unset"
                  : "1px solid rgba(38, 130, 253, 0.5)",
              }}
              onClick={() => setColumnView()}
            >
              {t("workspaceNext:quoteComparison:selectionRecap")}
            </Button>
          </Box>
          {tabsList &&
            tabsList.map((tab: quotesHeaderProps, tabIndex: number) => {
              return (
                tab.showTab && (
                  <Button
                    onClick={(event: any) =>
                      handleChangeTab(event, tab.headerName)
                    }
                    variant="outlined"
                    color="primary"
                    key={tabIndex}
                  >
                    {tab.headerName}
                  </Button>
                )
              );
            })}
        </Box>
        {!columnView && (
          <Box className={classes.formControlWrapper}>
            <FormControlLabel
              className={classes.formControlStyle}
              control={
                <Switch
                  checked={showQuotationVariance}
                  onChange={() => {
                    dispatch(
                      workspaceNextActions.showQuotationVariance(
                        !showQuotationVariance
                      )
                    );
                  }}
                  name="unit price variance"
                  color="primary"
                />
              }
              label={
                <Typography variant="body2">
                  {t("workspaceNext:quoteComparison:unitPriceVariance")}
                </Typography>
              }
            />
            <Tooltip
              title={t(
                "workspaceNext:quoteComparison:unitPriceVarianceTooltip"
              )}
            >
              <InfoOutlined
                sx={{
                  color: "#00000061",
                }}
              />
            </Tooltip>
          </Box>
        )}
      </Box>
      <Box className={classes.headerRightSection}>
        <Box mt="5px" display="flex">
          <Typography variant="body2" color="textSecondary">
            {t("workspaceNext:quoteComparison:totalSelected")}
          </Typography>
          <Box ml="4px">
            <Typography variant="body2" style={{ fontWeight: "bold" }}>
              <QuoteComparisonCurrencyField
                value={selectedData?.convertedTotalPriceSum}
                currency={config?.selected?.currency}
              />
            </Typography>
          </Box>
        </Box>
        {hasViewerRole ? null : (
          <Button
            variant="contained"
            color="primary"
            data-pid="btnQCAwardSelection"
            disabled={
              selectedData?.selectedSuppliersParts?.length === 0 || isAwardedRfq
            }
            onClick={onClickAwardSelection}
          >
            {t("workspaceNext:quoteComparison:proceedSelection", {
              count: selectedData?.selectedSuppliersParts?.length,
            })}
          </Button>
        )}
      </Box>
      {showPDF ? (
        <div style={{ display: "none" }}>
          <QuotationPDFViewer
            ref={pdfComponentRef}
            title={rfqDetails.rfq_display_name}
            quotations={rfqDetails.quotations}
          />
        </div>
      ) : null}
      <Menu
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={closeExportMenu}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
      >
        {rfqDetails.quotation_extra_fields ? null : (
          <MenuItem
            onClick={() =>
              handleMenuItemClick(() => setShowPDF(true, handlePrint))
            }
          >
            {t("rfq:quotations:exportQuotationsPDF")}
          </MenuItem>
        )}
        <MenuItem
          onClick={() =>
            handleMenuItemClick(() =>
              fileDownloadFromUrl(
                `${backendUrl.rfqs}/${rfqDetails.pk}/exportConsolidatedQuotationReport`,
                `consolidated_quotation_report_${rfqDetails.pk}`,
                undefined,
                true
              )
            )
          }
        >
          {t("rfq:quotations:exportQuotationsXLSX")}
        </MenuItem>

        {!!company?.requires_consolidated_report ? (
          <Tooltip
            open={!!rfqDetails.consolidated_report ? false : undefined}
            title={
              !rfqDetails.consolidated_report
                ? "The consolidated export will be available after the RFQ end date"
                : null
            }
          >
            <span>
              <MenuItem
                onClick={() => downloadFile(rfqDetails.consolidated_report)}
                disabled={!rfqDetails.consolidated_report}
              >
                {t("rfq:quotations:exportConsolidatedReport")}
              </MenuItem>
            </span>
          </Tooltip>
        ) : null}

        {!!reportsButtonAvailable ? (
          <MenuItem onClick={onClickExportQuoteComparisonSheet}>
            {t("rfq:quotations:exportQuotesComparisonSheet")}
          </MenuItem>
        ) : null}
      </Menu>
    </Box>
  );
};
export default QuoteComparisonHeaderContainer;
