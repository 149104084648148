import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import ReleaseModal from "../modals/release-modal";
import { getAnnouncementsConfig } from "services/part-configuration/part-configuration.selectors";
import { AnnouncementConfig } from "../types";
import { AnnouncementModalTypes } from "../modals/types";
import { getModal, modalsActions } from "@next/redux/modalsSlices";

const ReleaseAnnouncement: React.FC = () => {
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);
  const [defaultSlug, setDefaultSlug] = useState<string>("");
  const announcementConfig = useSelector(
    getAnnouncementsConfig
  ) as AnnouncementConfig;

  const isShowWhatsNewModal = useSelector(
    getModal(AnnouncementModalTypes.WHATS_NEW_MODAL)
  );
  useEffect(() => {
    if (announcementConfig) {
      if (
        isShowWhatsNewModal &&
        isShowWhatsNewModal.data?.userOpen &&
        announcementConfig.latest_release
      ) {
        setDefaultSlug(announcementConfig.latest_release);
        setOpen(true);
      }
      const lastSeenSlug = localStorage.getItem(
        AnnouncementModalTypes.WHATS_NEW_MODAL
      );
      if (
        announcementConfig.active_release &&
        lastSeenSlug !== announcementConfig.active_release
      ) {
        setDefaultSlug(announcementConfig.active_release);
        setOpen(true);
      }
    } else {
      dispatch(
        modalsActions.closeModal(AnnouncementModalTypes.WHATS_NEW_MODAL)
      );
    }
  }, [announcementConfig, isShowWhatsNewModal]);

  return (
    <ReleaseModal
      open={open}
      slug={defaultSlug}
      onClose={() => {
        setOpen(false);
        // Mark as seen

        if (isShowWhatsNewModal && isShowWhatsNewModal?.data?.userOpen) {
          dispatch(
            modalsActions.closeModal(AnnouncementModalTypes.WHATS_NEW_MODAL)
          );
        } else {
          if (announcementConfig.active_release)
            localStorage.setItem(
              AnnouncementModalTypes.WHATS_NEW_MODAL,
              announcementConfig.active_release
            );
        }
      }}
    />
  );
};

export default ReleaseAnnouncement;
