import React from "react";

import { Avatar, Box } from "@mui/material";
import { GridColDef, GridRenderCellParams } from "@mui/x-data-grid-pro-v5";
import { getInitials } from "@next/utils/stringUtils";
import { CustomTypography } from "@next/components/custom-typography";

const defaultColumnConfig: Partial<GridColDef> = {
  disableColumnMenu: true,
  sortable: false,
  hideSortIcons: true,
};

const SupplierCell: React.FC<GridRenderCellParams> = (params: any) => {
  return (
    <Box display="flex" alignItems="center">
      <Avatar
        src={params?.row?.picture}
        style={{ width: "32px", height: "32px" }}
      >
        {getInitials(params?.row?.name)}
      </Avatar>

      <Box ml="8px">
        <CustomTypography variant="caption">
          {params?.row?.name}
        </CustomTypography>
      </Box>
    </Box>
  );
};

export const getSellerDashboardBuyerViewsTableColumns = () => {
  const _columns: GridColDef[] = [
    {
      ...defaultColumnConfig,
      field: "name",
      flex: 1,
      renderCell: SupplierCell,
    },
  ];

  return _columns;
};
