import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { FormControl, FormControlLabel, Radio, TextField } from "@mui/material";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import { useParams } from "react-router-dom";
import { FormFooter } from "../../form-footer/form-footer";
import { FormParentBox } from "../../form-parent-box";
import { FormBoxLg } from "../../form-box/form-box-lg";
import { FormTitle } from "../../form-title/form-title";
import { FormFieldBox } from "../../form-field-box";
import { SubtierOnboardingFormTable } from "../../table/subtier-onboarding-form-table/subtier-onboarding-form-table";
import { FormFieldErrorTypography } from "../../form-field-error-typography/form-field-error-typography";
import {
  selectFormData,
  selectIsAllStepsCompleted,
  selectIsFormCompleted,
  selectIsLastStep,
  selectIsThereUnsavedChangesInFormStep,
  selectVisibleSteps,
} from "@next/modules/vendor-management/redux/selectors";
import {
  FieldMandatoryText,
  PartialStep,
  SubtiersOnboardingFormData,
  UpdateSubtiersOnboardingFormData,
  VendorManagementModalTypes,
  vendorManagementActions,
} from "@next/modules/vendor-management/redux";
import { modalsActions } from "@next/redux/modalsSlices";
import { showInvalidMessage } from "@next/modules/vendor-management/utils/showInvalidMessage";
import { showCompletedMessage } from "@next/modules/vendor-management/utils/showCompletedMessage";
import { getActiveStepIndex } from "@next/modules/vendor-management/utils/getActiveStepIndex";
import { showSavedMessage } from "@next/modules/vendor-management/utils/showSavedMessage";
import * as S from "./subtiers-onboarding-form.styled";

const completionText =
  "On completion of the transformation programme, all suppliers and subtiers suppliers will lose all access to applications on the Bombardier system, " +
  "and all applications will have to be accessed via Airbus gateway(s). " +
  "Only tier 1 companies that have a contract with Airbus can be registered on Airbus Partner Portal (APP). " +
  "As per Airbus policy (and as stated in the GTC document and Airbus methods), subtiers (or nth tier) suppliers are managed by the tier 1 company. " +
  "Setting up n-tier suppliers will require additional IM user configuration.";

export const SubtiersOnboardingForm: React.FC = () => {
  const { companyId } = useParams<{ companyId: string }>();
  const dispatch = useDispatch();
  const isFormCompleted = useSelector(selectIsFormCompleted);
  const isLastStep = useSelector(selectIsLastStep);
  const isAllStepsCompleted = useSelector(selectIsAllStepsCompleted);
  const isThereUnsavedChanges = useSelector(
    selectIsThereUnsavedChangesInFormStep
  );
  const steps = useSelector(selectVisibleSteps);
  const activeFormStepIndex = getActiveStepIndex(steps);
  const subtierOnboardingFormData = useSelector(
    selectFormData
  ) as SubtiersOnboardingFormData;
  const {
    handleSubmit,
    formState: { errors },
    control,
    getValues,
    setError,
  } = useForm<SubtiersOnboardingFormData>({
    defaultValues: subtierOnboardingFormData,
  });

  useEffect(() => {
    dispatch(
      vendorManagementActions.fetchSubtierOnboardingTableDataRequest(companyId)
    );
  }, []);

  const saveChanges = (isForCompletion: boolean) => {
    const additionalComment = getValues("additional_comment");
    const isSubtierWillBeImpactedIMSeparation = getValues(
      "confirmSubtierOnboarding"
    );
    const body: UpdateSubtiersOnboardingFormData = {
      formData: {
        confirmSubtierOnboarding: isSubtierWillBeImpactedIMSeparation,
        additional_comment: additionalComment,
      },
      companyId: companyId,
    };
    dispatch(
      vendorManagementActions.updateSubtiersOnboardingFormDataRequest({
        ...body,
      })
    );

    const fieldOfStep = isForCompletion
      ? "is_completed"
      : "is_partially_filled";
    updateFieldStatusOfStepForSaveAndCompletion(
      fieldOfStep,
      true,
      activeFormStepIndex
    );
    if (!isForCompletion) {
      showSavedMessage();
    }
    dispatch(vendorManagementActions.setIsThereUnsavedChangesInFormStep(false));
  };

  const confirmStepCompletion: SubmitHandler<SubtiersOnboardingFormData> = (
    data
  ) => {
    const isSubtierWillBeImpactedIMSeparation = getValues(
      "confirmSubtierOnboarding"
    );
    if (
      isSubtierWillBeImpactedIMSeparation !== null &&
      isSubtierWillBeImpactedIMSeparation !== undefined
    ) {
      makeFormStepCompletionProcess();
    } else {
      setError("confirmSubtierOnboarding", { type: "manual" });
      showInvalidMessage();
    }
  };

  const makeFormStepCompletionProcess = () => {
    saveChanges(true);
    showCompletedMessage();
    if (isAllStepsCompleted) {
      showThankYouModal();
    } else if (!isLastStep) {
      routeNextStep();
    }
  };

  const routeNextStep = () => {
    const newActiveStepIndex = activeFormStepIndex + 1;
    setActiveFormStepIndex(newActiveStepIndex);
  };

  const setActiveFormStepIndex = (index: number) => {
    updateFieldStatusOfStepForSaveAndCompletion("is_active", true, index);
  };

  const showThankYouModal = () => {
    dispatch(
      modalsActions.showModal({
        key: VendorManagementModalTypes.THANK_YOU_MODAL,
      })
    );
  };

  const updateFieldStatusOfStepForSaveAndCompletion = (
    key: "is_completed" | "is_partially_filled" | "is_active",
    value: boolean,
    stepIndex: number
  ) => {
    const step = steps[stepIndex];
    const partialStep: PartialStep = {
      [key]: value,
    };
    dispatch(
      vendorManagementActions.updateStepRequest({
        stepId: step.id,
        data: partialStep,
      })
    );
  };

  const onInvalid = () => {
    showInvalidMessage();
  };

  const checkInputChange = () => {
    if (isFormCompleted) {
      updateFieldStatusOfStepForSaveAndCompletion(
        "is_completed",
        false,
        activeFormStepIndex
      );
    } else if (!isThereUnsavedChanges) {
      dispatch(
        vendorManagementActions.setIsThereUnsavedChangesInFormStep(true)
      );
    }
  };

  return (
    <>
      <FormParentBox>
        <FormBoxLg isCompleted={isFormCompleted}>
          <FormTitle
            title={"Subtier onboarding"}
            isCompleted={isFormCompleted}
          />

          <FormFieldBox>
            <S.StyledTypography variant="body1">
              Please confirm if your subtiers have access to these tools:
            </S.StyledTypography>

            <SubtierOnboardingFormTable />
          </FormFieldBox>

          <S.StyledFormFieldBox>
            <S.StyledTypography>{completionText}</S.StyledTypography>
            <S.StyledTypography>
              Will the Subtier be impacted info management (IM) Separation ?
            </S.StyledTypography>

            <FormControl error={errors.confirmSubtierOnboarding ? true : false}>
              <Controller
                name="confirmSubtierOnboarding"
                control={control}
                render={({ field }) => (
                  <FormFieldBox>
                    <S.StyledRadioGroup
                      {...field}
                      value={field.value === null ? "" : field.value}
                      onChange={(e) => {
                        const value = e.target.value === "true" ? true : false;
                        field.onChange(value);
                        checkInputChange();
                      }}
                    >
                      <FormControlLabel
                        value="true"
                        control={<Radio />}
                        label="Yes"
                      />
                      <FormControlLabel
                        value="false"
                        control={<Radio />}
                        label="No"
                      />
                    </S.StyledRadioGroup>
                    {errors.confirmSubtierOnboarding ? (
                      <FormFieldErrorTypography text={FieldMandatoryText} />
                    ) : null}
                  </FormFieldBox>
                )}
              />
            </FormControl>
          </S.StyledFormFieldBox>

          <FormControl margin="none">
            <Controller
              name="additional_comment"
              control={control}
              defaultValue={subtierOnboardingFormData?.additional_comment || ""}
              render={({ field: { onChange, value } }) => (
                <S.StyledFormFieldBox>
                  <TextField
                    sx={{ width: "100%" }}
                    multiline
                    rows={3}
                    placeholder="Additional comment..."
                    value={value || ""}
                    onChange={(e) => {
                      onChange(e);
                      checkInputChange();
                    }}
                  />
                </S.StyledFormFieldBox>
              )}
            />
          </FormControl>
        </FormBoxLg>
      </FormParentBox>

      <FormFooter
        saveChanges={() => saveChanges(false)}
        confirmStepCompletion={handleSubmit(confirmStepCompletion, onInvalid)}
        isCompleted={isFormCompleted}
      />
    </>
  );
};
