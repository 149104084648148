import { FieldArray, FormikProps } from "formik";
import React, { useCallback, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import SupplierListItem from "../rfq-creation-form/supplier-list-item";
import { RFQSupplierType } from "../rfq-creation-form/rfq-creation.form.types";
import {
  Box,
  Button,
  DialogActions,
  DialogContent,
  Divider,
  Typography,
} from "@mui/material";
import SuppliersAutoCompleteDropdown from "../rfq-creation-form/suppliers-autocomplete-dropdown";
import CustomListItem from "../rfq-creation-form/custom-list-item";
import CustomButton from "@next/components/custom-button";
import { modalsActions } from "@next/redux/modalsSlices";
import { WorkspaceModalTypes } from "../../modals/types";
import { selectUpdateRFQLoading } from "@next/modules/project/redux/selectors";
import { FormValues } from "./add-supplier-form.types";
import { useTranslation } from "react-i18next";
import SupplierContactsList from "../supplier-contacts-list/supplier-contacts-list";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  dialogContent: {
    padding: theme.spacing(3),
  },
  footer: {
    flex: 1,
    display: "flex",
    justifyContent: "space-between",
    padding: theme.spacing(0.5),
  },
  lastListItem: {
    marginBottom: 0,
  },
  listItem: {
    marginBottom: theme.spacing(1.5),
  },
}));

const AddSupplierFormBase: React.FC<
  FormikProps<FormValues> & { rfqDetails: any }
> = ({ values, submitForm, rfqDetails }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const loading = useSelector(selectUpdateRFQLoading);
  const { t } = useTranslation();

  const onCancel = useCallback(() => {
    dispatch(modalsActions.closeModal(WorkspaceModalTypes.ADD_SUPPLIERS_MODAL));
  }, []);

  const includedSuppliers = useMemo(() => {
    const included = new Set();
    if (Array.isArray(rfqDetails?.targeted_suppliers)) {
      for (const item of rfqDetails?.targeted_suppliers) included.add(item.id);
    }
    if (Array.isArray(rfqDetails?.marketplace_suppliers)) {
      for (const item of rfqDetails?.marketplace_suppliers)
        included.add(item.id);
    }
    return included;
  }, [rfqDetails?.targeted_suppliers, rfqDetails?.marketplace_suppliers]);

  return (
    <>
      <DialogContent
        style={{
          overflowY:
            values.targeted_suppliers.length > 0 ? undefined : "hidden",
        }}
        dividers
        className={classes.dialogContent}
      >
        <Box mb={2}>
          <Typography variant="body2" color="textSecondary">
            {t("workspaceNext:addSuppliersModal:caption")}
          </Typography>
        </Box>

        <SuppliersAutoCompleteDropdown
          paperWidth="100%"
          showGroupsAndMarketplace={false}
          getOptionDisabled={(option: RFQSupplierType) =>
            includedSuppliers.has(option.pk)
          }
        />

        {values.targeted_suppliers.length ? (
          <Box mt={3}>
            <FieldArray
              name="targeted_suppliers"
              render={(arrayHelpers) =>
                values.targeted_suppliers.map((item, index) => (
                  <Box>
                    {index === 0 ? (
                      <Box mb="12px">
                        <Divider />
                      </Box>
                    ) : null}
                    <CustomListItem
                      rootClassName={
                        index === values.targeted_suppliers.length - 1
                          ? classes.lastListItem
                          : classes.listItem
                      }
                      key={item.pk}
                      onRemove={() => arrayHelpers.remove(index)}
                    >
                      <Box
                        display="flex"
                        alignItems="center"
                        justifyContent="space-between"
                      >
                        <SupplierListItem
                          name={item.name}
                          uuid={item.uuid}
                          picture={item.picture}
                          openProfileOnClick
                          tooltipTitle={item.name}
                          maxWidth={316}
                        />
                        <SupplierContactsList
                          supplier={item}
                          users={item.target_profiles}
                          size="small"
                        />
                      </Box>
                    </CustomListItem>
                    <Box
                      mt="12px"
                      mb={
                        index === values.targeted_suppliers.length - 1
                          ? 0
                          : "12px"
                      }
                    >
                      <Divider />
                    </Box>
                  </Box>
                ))
              }
            />
          </Box>
        ) : null}
      </DialogContent>

      <DialogActions>
        <Box className={classes.footer}>
          <Button onClick={onCancel}>
            <Typography variant="body2">
              {t("workspaceNext:addSuppliersModal:cancel")}
            </Typography>
          </Button>
          <CustomButton
            loading={loading}
            autoFocus
            variant="contained"
            color="primary"
            disabled={values.targeted_suppliers.length === 0}
            onClick={submitForm}
          >
            {t("workspaceNext:addSuppliersModal:submit")}
          </CustomButton>
        </Box>
      </DialogActions>
    </>
  );
};

export default AddSupplierFormBase;
