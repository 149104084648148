import { useEffect, useState } from "react";
import { useSelector } from "react-redux";

import { isEmpty } from "lodash";
import {
  getIsEditQuotationMode,
  getQuotationDetails,
} from "services/quotation/quotation.selectors";
import { getActiveQuotationThread } from "services/inbox/inbox.selectors";
import { getActiveRfqDetails } from "services/marketplace/marketplace.selectors";
import { getContractDetails } from "services/workspace/workspace.selectors";
import {
  PartExtraField,
  ProjectCompanyPart,
  QuotationExtraField,
} from "@next/modules/project/redux";

type QuotationViewData = {
  parts: ProjectCompanyPart[];
  partExtraFields: PartExtraField[];
  quotationExtraFields: QuotationExtraField[];
};

type QuotationViewDataParams = {
  isContract?: boolean | undefined;
  isMessenger?: boolean | undefined;
};

export const useQuotationViewData = (params: QuotationViewDataParams) => {
  const [quotationViewData, setQuotationViewData] = useState<QuotationViewData>(
    {
      parts: [],
      partExtraFields: [],
      quotationExtraFields: [],
    }
  );

  const contractDetails = useSelector(getContractDetails);
  const activeQuotationThread = useSelector(getActiveQuotationThread);
  const isEditQuotationMode = useSelector(getIsEditQuotationMode);
  const activeRfqDetails = useSelector(getActiveRfqDetails);
  const quotationDetails = useSelector(getQuotationDetails);

  useEffect(() => {
    setQuotationViewData((prev: QuotationViewData) => {
      if (params?.isContract && !isEmpty(contractDetails)) {
        return {
          ...prev,
          parts: contractDetails?.quotations[0].parts || [],
          partExtraFields: contractDetails?.part_extra_fields || [],
          quotationExtraFields: contractDetails?.quotation_extra_fields || [],
        };
      }

      if (params?.isMessenger && !isEmpty(activeQuotationThread)) {
        return {
          ...prev,
          parts: activeQuotationThread?.quotation?.parts || [],
          partExtraFields:
            activeQuotationThread?.quotation?.part_extra_fields || [],
          quotationExtraFields:
            activeQuotationThread?.quotation?.quotation_extra_fields || [],
        };
      }

      if (!isEditQuotationMode && !isEmpty(activeRfqDetails)) {
        return {
          ...prev,
          parts: activeRfqDetails?.parts || [],
          partExtraFields: activeRfqDetails?.part_extra_fields || [],
          quotationExtraFields: activeRfqDetails?.quotation_extra_fields || [],
        };
      }

      return {
        ...prev,
        parts: quotationDetails?.parts || [],
        partExtraFields: quotationDetails?.rfq_info?.part_extra_fields || [],
        quotationExtraFields:
          quotationDetails?.rfq_info?.quotation_extra_fields || [],
      };
    });
  }, [
    isEditQuotationMode,
    quotationDetails,
    activeRfqDetails,
    contractDetails,
    activeQuotationThread,
    params?.isContract,
    params?.isMessenger,
  ]);

  return {
    quotationViewData,
    isPartsExtraFieldsAvailable:
      quotationViewData?.parts && !isEmpty(quotationViewData?.partExtraFields),

    isQuotationExtraFieldsAvailable:
      quotationViewData?.parts &&
      !isEmpty(quotationViewData?.quotationExtraFields),

    isDetailsExtraFieldsAvailable:
      !isEditQuotationMode && !isEmpty(activeRfqDetails)
        ? activeRfqDetails?.parts &&
          activeRfqDetails?.part_extra_fields &&
          activeRfqDetails?.quotation_extra_fields
        : quotationDetails?.parts &&
          quotationDetails?.rfq_info?.part_extra_fields &&
          quotationDetails?.rfq_info?.quotation_extra_fields,
    isNotCustomQuotation:
      quotationViewData?.parts &&
      isEmpty(quotationViewData?.partExtraFields) &&
      isEmpty(quotationViewData?.quotationExtraFields),
  };
};
