import { ProjectCompanyPart } from "@next/modules/project/redux";
import { workspaceNextActions } from "@next/modules/workspace/redux";
import {
  selectRfqFormPartsList,
  selectRfqFormPartsListLoading,
  selectRfqFormPartsListNext,
} from "@next/modules/workspace/redux/selectors";
import React, { useCallback, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import PaginatedAutoComplete from "@next/components/paginated-autocomplete";
import { Box, Button } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import { useTranslation } from "react-i18next";
import { useField } from "formik";
import { selectProjectCode } from "@next/modules/project/redux/selectors";
import { createStyles, makeStyles } from "@mui/styles";
import useUpdateEffect from "@next/hooks/useUpdateEffect";

interface PartsAutoCompleteDropdownProps {
  onCreate?: () => void;
  pageSize?: number;
  fromPM?: boolean;
  hideCreatePartButton?: boolean;
  multiple?: boolean;
  disablePlaceholder?: boolean;
  templateId?: number;
}

const useStyles = makeStyles((theme) =>
  createStyles({
    button: {
      height: 40,
      marginLeft: theme.spacing(2),
    },
  })
);

const PartsAutoCompleteDropdown: React.FC<PartsAutoCompleteDropdownProps> = ({
  pageSize = 12,
  onCreate,
  fromPM,
  hideCreatePartButton,
  multiple,
  disablePlaceholder,
  templateId,
}) => {
  const { t } = useTranslation();
  const partsList = useSelector(selectRfqFormPartsList);
  const nextPage = useSelector(selectRfqFormPartsListNext);
  const loading = useSelector(selectRfqFormPartsListLoading);
  const projectCode = useSelector(selectProjectCode);
  const dispatch = useDispatch();
  const classes = useStyles();
  const [field, _, fieldHelpers] = useField("parts");

  const onEndReached = useCallback(() => {
    if (typeof nextPage === "string") {
      dispatch(workspaceNextActions.fetchPartsRequest({ url: nextPage }));
    }
  }, [nextPage]);

  const onSearch = useCallback(
    (search) => {
      dispatch(workspaceNextActions.fetchPartsReset());
      dispatch(
        workspaceNextActions.fetchPartsRequest({
          query: {
            page: 1,
            pageSize,
            search,
            project: fromPM ? projectCode : undefined,
            template_id: templateId ? templateId : undefined,
          },
        })
      );
    },
    [pageSize, fromPM, projectCode, templateId]
  );

  const onInitialFetch = useCallback(() => {
    dispatch(workspaceNextActions.fetchPartsReset());
    dispatch(
      workspaceNextActions.fetchPartsRequest({
        query: {
          page: 1,
          pageSize,
          project: fromPM ? projectCode : undefined,
          template_id: templateId ? templateId : undefined,
        },
      })
    );
  }, [pageSize, fromPM, projectCode, templateId]);

  useUpdateEffect(() => {
    onInitialFetch();
  }, [templateId]);

  useEffect(() => {
    dispatch(workspaceNextActions.fetchPartsReset());
    return () => {
      dispatch(workspaceNextActions.fetchPartsReset());
    };
  }, []);
  return (
    <Box display="flex">
      <Box flex={1}>
        <PaginatedAutoComplete
          name={field.name}
          value={field.value}
          onSelectionChange={fieldHelpers.setValue}
          loading={loading}
          options={partsList}
          onEndReached={onEndReached}
          onSearch={onSearch}
          onInitialFetch={onInitialFetch}
          renderOption={(option) => option.name}
          getOptionSelected={(
            option: ProjectCompanyPart,
            value: ProjectCompanyPart
          ) => option.pk === value.pk}
          placeholder={
            disablePlaceholder
              ? null
              : t("workspaceNext:rfqDrawer:existingParts")
          }
          multiple={multiple}
          disableCloseOnSelect={multiple}
          getInputFieldValue={(value: ProjectCompanyPart) => value.name}
        />
      </Box>
      {!hideCreatePartButton ? (
        <Button
          className={classes.button}
          variant="outlined"
          startIcon={<AddIcon />}
          onClick={onCreate}
        >
          {t("workspaceNext:rfqDrawer:createPart")}
        </Button>
      ) : null}
    </Box>
  );
};

export default PartsAutoCompleteDropdown;
