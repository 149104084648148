import React, { useCallback } from "react";
import { SellerDashboardCard } from "../seller-dashboard-card/seller-dashboard-card";
import { Badge, Theme, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { modalsActions } from "@next/redux/modalsSlices";
import { SharedModalTypes } from "@next/modals/types";
import { Link } from "react-router-dom";
import { useCompanyPlan } from "@next/hooks/useIsPremiumCompany";
import { createStyles, makeStyles, withStyles } from "@mui/styles";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    premiumGreenIcon: {
      marginLeft: theme.spacing(0.5),
    },
    premiumText: {
      marginLeft: theme.spacing(2),
    },
  })
);

export const PremiumStyledBadge = withStyles((theme: Theme) =>
  createStyles({
    badge: {
      backgroundColor: theme.palette.success.main,
      color: theme.palette.success.main,
      "&::after": {
        position: "absolute",
        top: 0,
        left: 0,
        width: "100%",
        height: "100%",
        borderRadius: "50%",
        animation: "$ripple 1.2s infinite ease-in-out",
        border: "1px solid currentColor",
        content: '""',
      },
    },
    "@keyframes ripple": {
      "0%": {
        transform: "scale(.8)",
        opacity: 1,
      },
      "100%": {
        transform: "scale(2.4)",
        opacity: 0,
      },
    },
  })
)(Badge);

export const SellerDashboardPremiumStatusCard = () => {
  const classes = useStyles();
  const { t } = useTranslation();
  const isPremiumCompany = useCompanyPlan("premium");
  const dispatch = useDispatch();

  const upgrade = useCallback(() => {
    dispatch(
      modalsActions.showModal({
        key: SharedModalTypes.UPGRADE_TO_PREMIUM_MODAL,
      })
    );
  }, []);

  return (
    <SellerDashboardCard
      variant={isPremiumCompany ? "success" : "default"}
      title={t("sellerDashboard:currentPlan")}
      action={
        !isPremiumCompany && (
          <Typography variant="body1">
            <Link to="#" onClick={upgrade} data-pid="linkSellerUpgrade">
              {t("sellerDashboard:upgrade")}
            </Link>
          </Typography>
        )
      }
      content={
        <>
          {isPremiumCompany ? (
            <PremiumStyledBadge
              variant="dot"
              className={classes.premiumGreenIcon}
            />
          ) : null}

          <Typography
            variant="h6"
            className={isPremiumCompany ? classes.premiumText : ""}
          >
            {t(
              `sellerDashboard:${isPremiumCompany ? "premium" : "regularPlan"}`
            )}
          </Typography>
        </>
      }
    />
  );
};
