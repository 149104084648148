import React from "react";
import * as S from "./requests-report-table.styled";
import { getRequestsReportTableColumns } from "./requests-report-table.columns";
import { RequestsReportTable as RequestsReportTableType } from "@next/modules/analytics/redux";
import { useTranslation } from "react-i18next";
import { Box } from "@mui/material";

export const RequestsReportTable: React.FC<RequestsReportTableType> = ({
  id,
  rows,
}) => {
  const { t } = useTranslation();
  const columns = getRequestsReportTableColumns(t);

  return (
    <S.RootBox>
      <Box width="95%" pb="24px">
        <S.StyledDiv>
          <S.StyledTypography variant="h6">
            {t("analytics:requestsReport:table:title")}
          </S.StyledTypography>
        </S.StyledDiv>

        <S.StyledDataGridPro
          rows={rows}
          columns={columns}
          disableSelectionOnClick={true}
          disableColumnResize
          headerHeight={44}
          rowHeight={60}
          hideFooter
        />
      </Box>
    </S.RootBox>
  );
};
