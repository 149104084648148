import React from "react";
import { AppTheme } from "layout/AppTheme";
import Chart from "react-apexcharts";
import { LegendList } from "../../analytic-graph-card/analytic-graph-card-legend-list/analytic-graph-card-legend-list";
import { formatNumber } from "helpers/formatNumber";
import { SupplierReportChart } from "@next/modules/analytics/redux";

const legendTitles = ["Total value (CAD $)", "Cumulative value"];
const colors = [AppTheme.palette.primary.main];

export const SupplierReportColumnChart: React.FC<SupplierReportChart> = ({
  id,
  x_axis,
  y_axes,
}) => {
  const data = {
    series: [
      {
        name: "Total value (CAD $)",
        type: "column",
        data: y_axes[0].values,
      },
      {
        name: "Cumulative",
        type: "line",
        data: y_axes[1].values,
      },
    ],
    options: {
      chart: {
        type: "line" as "line",
        stacked: false,
        toolbar: {
          show: false,
        },
      },
      plotOptions: {
        bar: {
          columnWidth: "28%",
          borderRadius: 2,
          borderRadiusWhenStacked: "all" as "all",
          borderRadiusApplication: "around" as "around",
        },
      },
      stroke: {
        width: [1, 2],
        colors: [colors[0]],
      },
      dataLabels: {
        show: false,
        enabled: false,
      },
      xaxis: {
        categories: x_axis,
        axisBorder: {
          show: false,
        },
      },
      yaxis: [
        {
          tickAmount: 6,
          max: 12000000,
          lines: {
            show: false,
          },
          axisBorder: {
            show: false,
          },
          title: {
            text: "Total value (CAD $)",
          },
          tooltip: {
            enabled: true,
          },
          labels: {
            formatter: (value: number) => formatNumber(value),
          },
        },
        {
          tickAmount: 6,
          opposite: true,
          axisTicks: {
            show: true,
          },
          axisBorder: {
            show: false,
          },
          labels: {
            formatter: (value: number) => formatNumber(value),
          },
          title: {
            text: "Cumulative",
          },
          tooltip: {
            enabled: true,
          },
        },
      ],
      legend: {
        show: false,
      },
      colors: [colors[0]],
      fill: {
        opacity: 1,
      },
    },
  };
  return (
    <>
      <LegendList legendTitles={legendTitles} colors={colors} />
      <Chart
        options={data.options}
        series={data.series}
        type="bar"
        height={"370"}
      />
    </>
  );
};
