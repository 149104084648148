export const PO_GRID_SUPPLIER_FILTER = "PO_GRID_SUPPLIER_FILTER";

export const PO_GRID_INTERNAL_NOTES_FILTER = "PO_GRID_INTERNAL_NOTES_FILTER";

export const ORDERS_GRID_TABLE_NAME = "MAIN_TABLE";
export const ORDERS_GRID_COLUMN_STATE = "PO_GRID_STATE";

export const ORDER_PARTS_TABLE_NAME = "ORDER_PARTS_TABLE_NAME";
export const PO_PART_GRID_STATE = "PO_PART_GRID_STATE";

export const BUYER_RFQ_GRID_COLUMN_STATE = "BUYER_RFQ_GRID_COLUMN_STATE";

export const BUYER_PARTS_GRID_COLUMN_STATE = "BUYER_PARTS_GRID_COLUMN_STATE";
