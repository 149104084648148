import { t } from "assets/configi18n/i18n";
import _ from "lodash";
import {
  CreateMultiContractData,
  CreateMultiContractState,
  GroupedCreateMultiContractData,
  Quotation,
} from "./create-multi-contract-table.types";

export const getSuppliersOptionsFromQuotations = (quotations?: Quotation[]) => {
  const cancelAndLeaveInRfqOptions = [
    {
      label: t("project:multiContract:cancelPart"),
      value: CreateMultiContractState.CANCEL,
    },
    {
      label: t("project:multiContract:leaveInRFQ"),
      value: CreateMultiContractState.LEAVE_IN_RFQ,
    },
  ];

  return [
    ...(quotations?.map((item) => {
      return {
        value: item?.company_info?.company_pk,
        label: item?.company_info?.company_name,
        picture: item?.company_info?.company_picture,
        parts: item?.parts,
      };
    }) || []),
    ...cancelAndLeaveInRfqOptions,
  ];
};

export const getGroupedMultiContractData = (
  multiContractData: CreateMultiContractData[]
): GroupedCreateMultiContractData[] => {
  return _(multiContractData)
    .groupBy((x) =>
      x.supplier_id && !isNaN(x.supplier_id)
        ? x.supplier_id
        : CreateMultiContractState.LEAVE_IN_RFQ
    )
    .map((value, key) => {
      return {
        id: value?.[0]?.id,
        supplier_id: parseInt(key),
        parts: value.map((item) => item.part),
      };
    })
    .orderBy(["id"], ["asc"])
    .value();
};

export const normalizeDataForApi = (
  groupedMultiContractData: GroupedCreateMultiContractData[]
) => {
  const multiContractRFQ = groupedMultiContractData
    ?.filter(
      (item) => item.supplier_id !== CreateMultiContractState.LEAVE_IN_RFQ
    )
    ?.map(({ supplier_name, ...rest }) => {
      return {
        ...rest,
        parts: rest?.parts?.map(
          (part) => (part?.pk as number) || (part as number)
        ),
      };
    });

  return multiContractRFQ || [];
};
