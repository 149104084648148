import {
  LineItemType,
  LineItemTypeFromBE,
  OrderLineItem,
  OrderLineItemChanges,
  ProposalDecision,
  QAMessageData,
  RevisionProposal,
  UpdatedLineItems,
} from "./types";

const changeLineItemTypeToItemType = (
  change: LineItemType
): LineItemTypeFromBE => {
  switch (change) {
    case LineItemType.QUANTITY:
      return "quantity";
    case LineItemType.UPDATED_DELIVERY_DATE:
      return "shipping";
    case LineItemType.PRICE:
      return "price";
    case LineItemType.STATUS:
      return "state_change";
    default:
      return change;
  }
};

const changeItemTypeToLineItemType = (
  type: LineItemTypeFromBE
): LineItemType => {
  switch (type) {
    case "quantity":
      return LineItemType.QUANTITY;
    case "price":
      return LineItemType.PRICE;
    case "shipping":
      return LineItemType.UPDATED_DELIVERY_DATE;
    case "state_change":
      return LineItemType.STATUS;
  }
};

export const convertLineItemChangesToUpdatedLineItems = (
  lineItems: OrderLineItem[]
): UpdatedLineItems[] => {
  const updatedData = lineItems.map((lineItem) => {
    return Object.keys(lineItem?.previous_line_item).map((key) => {
      return {
        id: lineItem.id,
        type: changeLineItemTypeToItemType(key as LineItemType),
        value: lineItem?.[key] as string,
      };
    });
  });

  return updatedData.flat(1);
};

export function checkIsValuesOfLineItemTypeConsistent(
  array: OrderLineItem[],
  key: LineItemType
) {
  if (array.length === 0) {
    return false;
  }
  const firstValue = array[0][key];
  // Check if all objects in the array have the same value for the specified key
  const allSame = array.every((item) => item[key] === firstValue);
  if (allSame) {
    return true;
  } else {
    return false;
  }
}

export const convertToOrderLineItemChanges = (
  data: RevisionProposal[]
): OrderLineItemChanges[] => {
  const newData: OrderLineItemChanges[] = data?.reduce(
    (result: OrderLineItemChanges[], item) => {
      const existingItem = result.find(
        (element) => element.id === item.part.id
      );

      if (existingItem) {
        // Update existing item
        switch (changeItemTypeToLineItemType(item.revision_type)) {
          case LineItemType.QUANTITY:
            existingItem.quantity = Number(item.new_value);
            if (item.previous_value !== undefined) {
              existingItem.previous_line_item = {
                ...existingItem.previous_line_item,
                quantity: Number(item.previous_value),
              };
              existingItem.decision = {
                ...existingItem.decision,
                quantity: null,
              };
            }
            break;
          case LineItemType.PRICE:
            existingItem.quotation_price = Number(item.new_value);
            if (item.previous_value !== undefined) {
              existingItem.previous_line_item = {
                ...existingItem.previous_line_item,
                quotation_price: Number(item.previous_value),
              };
              existingItem.decision = {
                ...existingItem.decision,
                quotation_price: null,
              };
            }
            break;
          case LineItemType.UPDATED_DELIVERY_DATE:
            existingItem.updated_delivery_date = item.new_value;
            if (item.previous_value !== undefined) {
              existingItem.previous_line_item = {
                ...existingItem.previous_line_item,
                updated_delivery_date: item.previous_value,
              };
              existingItem.decision = {
                ...existingItem.decision,
                updated_delivery_date: null,
              };
            }
            break;
          default:
            break;
        }
      } else {
        // Create new item
        const newItem: OrderLineItemChanges = {
          id: item.part.id,
          name: item.part.name,
          previous_line_item: {},
          extra_fields: item.part.extra_fields,
          decision: {},
        };

        switch (changeItemTypeToLineItemType(item.revision_type)) {
          case LineItemType.QUANTITY:
            newItem.quantity = item.new_value;
            if (item.previous_value !== undefined) {
              newItem.previous_line_item.quantity = item.previous_value;
              newItem.decision.quantity = null;
            }
            break;
          case LineItemType.PRICE:
            newItem.quotation_price = item.new_value;
            if (item.previous_value !== undefined) {
              newItem.previous_line_item.quotation_price = item.previous_value;
              newItem.decision.quotation_price = null;
            }
            break;
          case LineItemType.UPDATED_DELIVERY_DATE:
            newItem.updated_delivery_date = item.new_value;
            if (item.previous_value !== undefined) {
              newItem.previous_line_item.updated_delivery_date =
                item.previous_value;
              newItem.decision.updated_delivery_date = null;
            }
            break;
          default:
            break;
        }

        result.push(newItem);
      }

      return result;
    },
    []
  );

  return newData;
};

export const extractProposalDecisions = (
  revisionProposals: RevisionProposal[],
  orderLineItemChanges: OrderLineItemChanges[]
): ProposalDecision[] => {
  const decisions: ProposalDecision[] = [];

  revisionProposals.forEach((proposal) => {
    const correspondingChange = orderLineItemChanges.find(
      (change) => change.id === proposal.part.id
    );

    if (correspondingChange && correspondingChange.decision) {
      const decision: "approved" | "rejected" =
        correspondingChange.decision[
          changeItemTypeToLineItemType(proposal.revision_type)
        ] === "approved"
          ? "approved"
          : "rejected";

      decisions.push({ id: proposal.id, decision });
    } else {
      decisions.push({ id: proposal.id, decision: "rejected" });
    }
  });

  return decisions;
};

export const convertQAMessageDataToOrderLineItemChanges = (
  data: QAMessageData[]
): OrderLineItemChanges[] => {
  const newData: OrderLineItemChanges[] = [];

  // eslint-disable-next-line @typescript-eslint/no-unused-expressions
  data?.forEach((item) => {
    const existingItemIndex = newData?.findIndex((el) => el.id === item.id);
    const existingItem =
      existingItemIndex !== -1 ? newData[existingItemIndex] : undefined;

    if (existingItem) {
      // Update existing item
      switch (changeItemTypeToLineItemType(item.type)) {
        case LineItemType.QUANTITY:
          existingItem.quantity = item.new_value;
          existingItem.previous_line_item.quantity = item.previous_value;
          break;
        case LineItemType.PRICE:
          existingItem.quotation_price = item.new_value;
          existingItem.previous_line_item.quotation_price = item.previous_value;
          break;
        case LineItemType.UPDATED_DELIVERY_DATE:
          existingItem.updated_delivery_date = item.new_value;
          existingItem.previous_line_item.updated_delivery_date =
            item.previous_value;
          break;
        case LineItemType.STATUS:
          existingItem.status = item.new_value;
          existingItem.previous_line_item.status = item.previous_value;
          break;
        default:
          break;
      }

      // Update decision if available
      if (item.decision !== undefined) {
        if (!existingItem.decision) {
          existingItem.decision = {};
        }
        existingItem.decision[changeItemTypeToLineItemType(item.type)] =
          item.decision;
      }

      // Update existing item in the array
      newData[existingItemIndex] = existingItem;
    } else {
      // Create new item
      const newItem: OrderLineItemChanges = {
        id: item.id,
        name: item.part_name,
        previous_line_item: {},
        extra_fields: { line_id: item.line_id || "" }, // Add extra_fields with line_id
      };

      // Add new fields based on the item type
      switch (changeItemTypeToLineItemType(item.type)) {
        case LineItemType.QUANTITY:
          newItem.quantity = item.new_value;
          newItem.previous_line_item.quantity = item.previous_value;
          break;
        case LineItemType.PRICE:
          newItem.quotation_price = item.new_value;
          newItem.previous_line_item.quotation_price = item.previous_value;
          break;
        case LineItemType.UPDATED_DELIVERY_DATE:
          newItem.updated_delivery_date = item.new_value;
          newItem.previous_line_item.updated_delivery_date =
            item.previous_value;
          break;
        case LineItemType.STATUS:
          newItem.status = item.new_value;
          newItem.previous_line_item.status = item.previous_value;
          break;
        default:
          break;
      }

      // Add decision if available
      if (item.decision !== undefined) {
        newItem.decision = {
          [changeItemTypeToLineItemType(item.type)]: item.decision,
        };
      }

      newData.push(newItem);
    }
  });

  return newData;
};
