import React, { useMemo } from "react";
import { Theme } from "@mui/material";
import { useTranslation } from "react-i18next";
import { TabsView } from "@next/components/tabs-view";
import { useSelector } from "react-redux";
import { checkCountAndReturn } from "@next/modules/workspace/pages/buyer-rfqs/buyer-rfqs.utils";
import { useTableSavedQueryPagination } from "@next/hooks/useTableSavedQueryPagination";
import {
  SUPPLIER_LINE_ITEMS_TABLE_NAVIGATION_KEY,
  SupplierLineItemsTable,
} from "../components/supplier-line-items-table/supplier-line-items-table";
import { selectSupplierLineItemsCounts } from "../redux/selectors";
import { createStyles, makeStyles } from "@mui/styles";

export enum OrdersTabType {
  ACTIVE = 0,
  SHIPPED = 1,
  CANCELED = 2,
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      marginRight: -theme.spacing(4),
      marginTop: -theme.spacing(4),
    },
  })
);

export const SupplierLineItemsPage = () => {
  const classes = useStyles();
  const { t } = useTranslation();

  const { savedTableQueryPagination, setSavedTableQueryPagination } =
    useTableSavedQueryPagination(SUPPLIER_LINE_ITEMS_TABLE_NAVIGATION_KEY);

  const selectedTab = savedTableQueryPagination?.tab || 0;

  const setSelectedTab = (tab: number) => {
    setSavedTableQueryPagination({ ...savedTableQueryPagination, tab });
  };

  const lineItemCounts = useSelector(selectSupplierLineItemsCounts);

  const tabItems = useMemo(
    () => [
      {
        tabName: t("orders:supplierLineItemTable:tabs:active", {
          count: checkCountAndReturn(lineItemCounts?.active_count),
        }),
        pid: "supplierLineItems:activeTab",
      },
      {
        tabName: t("orders:supplierLineItemTable:tabs:shipped", {
          count: checkCountAndReturn(lineItemCounts?.shipped_count),
        }),
        pid: "supplierLineItems:shippedTab",
      },
      {
        tabName: t("orders:supplierLineItemTable:tabs:cancelled", {
          count: checkCountAndReturn(lineItemCounts?.cancelled_count),
        }),
        pid: "supplierLineItems:cancelledTab",
      },
    ],
    [lineItemCounts]
  );

  const tabHandleChange = (_: React.ChangeEvent<{}>, value: any) =>
    setSelectedTab(value);

  return (
    <div className={classes.root}>
      <div>
        <TabsView
          value={selectedTab}
          tabItems={tabItems}
          handleChange={tabHandleChange}
        />
        <div>
          <SupplierLineItemsTable
            isShippedOrders={selectedTab === OrdersTabType.SHIPPED}
            isCanceledOrders={selectedTab === OrdersTabType.CANCELED}
            disableFiltering={selectedTab !== OrdersTabType.ACTIVE}
          />
        </div>
      </div>
    </div>
  );
};
