import React from "react";
import { useParams } from "react-router-dom";
import { RfqInformations } from "../../components/public-question-answers/rfq-informations";
import { usePublicQuestionAnswerData } from "./public-question-answer.hooks";
import PortalPageLayout from "@next/components/portal-page-layout/portal-page-layout";
import PageLoader from "@next/components/page-loader/page-loader";

export const PublicQuestionAnswer = () => {
  const params = useParams<{ token?: string }>();

  const publicToken = params?.token;
  const { question, questionLoading } =
    usePublicQuestionAnswerData(publicToken);

  if (!question || questionLoading) {
    return <PageLoader />;
  }

  return (
    <PortalPageLayout
      SideBarComponent={<RfqInformations rfq={question?.rfq} />}
      question={question}
      qaToken={publicToken || ""}
    />
  );
};
