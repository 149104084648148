import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Box, Paper, Typography } from "@mui/material";
import { Field, Form, Formik } from "formik";
import { GenericTextField } from "components/generic-text-field/generic-text-field.component";
import {
  getInitialCapabilities,
  getNormalizedCapabilitiesTypes,
  getPKsFromFormikValues,
} from "./company-capabilities.utils";
import { selectCompanyProfile } from "@next/modules/profile/redux/selectors";
import {
  CapabilityTypes,
  companyCapabilitiesActions,
} from "@next/modules/company-capabilities/redux";
import {
  selectCompanyCapabilitiesTypes,
  selectPatchCompanyCapabilitiesLoading,
} from "@next/modules/company-capabilities/redux/selectors";
import FormikAutoComplete, {
  FormikOnChangeParams,
} from "@next/components/formik-autocomplete/formik-autocomplete";
import CustomButton from "@next/components/custom-button";
import { profileNextActions } from "@next/modules/profile/redux";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  capabilityContainer: {
    display: "flex",
    flexDirection: "column",
    gridGap: theme.spacing(4),
  },
  capabilityItems: {
    display: "flex",
    flexDirection: "column",
    gap: "16px",
  },
}));

export const CompanyCapabilities = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const classes = useStyles();
  const companyProfile = useSelector(selectCompanyProfile);
  const companyCapabilitiesTypes = useSelector(selectCompanyCapabilitiesTypes);
  const capabilities = getNormalizedCapabilitiesTypes(
    companyCapabilitiesTypes?.[CapabilityTypes.CAPABILITIES] || []
  );
  const submitCapabilitiesLoading = useSelector(
    selectPatchCompanyCapabilitiesLoading
  );

  useEffect(() => {
    dispatch(
      companyCapabilitiesActions.fetchCompanyCapabilitesTypesRequest({})
    );
  }, []);

  const initialValues = {
    additionalDetails: companyProfile?.capabilities_comment || "",
    ...getInitialCapabilities(companyProfile?.expertise),
  };
  const handleSubmitCapabilities = (values: any) => {
    const { additionalDetails, ...capabilities } = values;

    dispatch(
      companyCapabilitiesActions.patchCompanyCapabilitiesRequest({
        capabilities: getPKsFromFormikValues(capabilities),
        capabilities_comment: additionalDetails,
        onSuccess: () => {
          dispatch(
            profileNextActions.fetchCompanyProfileRequest({
              companyId: companyProfile?.id as number,
              isPublic: false,
            })
          );
        },
      })
    );
  };

  return (
    <section className="c-company-capabilities">
      <Box className="c-company-capabilities--subtitle">
        {t("companyCapabilities:subtitle")}
      </Box>
      <div>
        <Formik
          initialValues={initialValues}
          onSubmit={handleSubmitCapabilities}
        >
          {({ handleSubmit }) => {
            return (
              <Form onSubmit={handleSubmit}>
                <Box className={classes.capabilityContainer}>
                  {Object.keys(capabilities).map((parentKey) => {
                    const items = capabilities[parentKey];
                    return (
                      <Box className={classes.capabilityItems}>
                        <Typography variant="h5">{parentKey}</Typography>
                        <Box className={classes.capabilityItems}>
                          {items.map((item) => (
                            <React.Fragment key={item.pk}>
                              <Field
                                name={`${parentKey}-${item.pk}`}
                                component={FormikAutoComplete}
                                options={item.capabilities.map((item) => ({
                                  name: item.name,
                                  value: item.pk,
                                }))}
                                multiple
                                customOnChange={({
                                  details,
                                }: FormikOnChangeParams) => {
                                  dispatch(
                                    companyCapabilitiesActions.toggleCompanyCapability(
                                      details?.option?.value
                                    )
                                  );
                                }}
                                PaperComponent={(props: any) => {
                                  return <Paper elevation={8} {...props} />;
                                }}
                                label={item.name}
                                fieldSize="medium"
                                enableCheckboxes
                                disableClearable
                                autoHighlight
                                selectOnFocus
                                disableCloseOnSelect
                                freeSolo
                                enableGrouping
                              />
                            </React.Fragment>
                          ))}
                        </Box>
                      </Box>
                    );
                  })}
                </Box>
                <Box className="c-company-capabilities--title margin-top">
                  {t("companyCapabilities:title2")}
                </Box>
                <Box className="c-company-capabilities--subtitle">
                  {t("companyCapabilities:subtitle2")}
                </Box>
                <Box className="c-company-capabilities--text-field">
                  <GenericTextField
                    id="additionalDetails"
                    name="additionalDetails"
                    label={t("companyCapabilities:title2")}
                    multiline={true}
                    rows="4"
                  />
                </Box>
                <Box
                  display="flex"
                  alignItems="center"
                  justifyContent="flex-start"
                  className="c-company-capabilities--btn"
                >
                  <CustomButton
                    variant="contained"
                    type="submit"
                    size="medium"
                    color="primary"
                    loading={submitCapabilitiesLoading}
                  >
                    {t("common:save")}
                  </CustomButton>
                </Box>
              </Form>
            );
          }}
        </Formik>
      </div>
    </section>
  );
};
