import { t } from "assets/configi18n/i18n";
import * as yup from "yup";

const supplierSchema = yup.object().shape({
  name: yup
    .string()
    .required(t("workspaceNext:inviteSupplier:error:companyNameIsRequired")),
  email: yup
    .string()
    .email(t("workspaceNext:inviteSupplier:error:emailIsInvalid"))
    .required(t("workspaceNext:inviteSupplier:error:emailIsRequired")),
  contact_name: yup.string(),
});

export const validationSchema = yup.object().shape({
  suppliers: yup.array(supplierSchema),
});
