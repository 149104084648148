import {
  DataGridProProps,
  DataGridPro as DataGridProV5Base,
} from "@mui/x-data-grid-pro-v5";
import React, { useEffect } from "react";
import { initializeDataGridProV5License } from "./data-grid-pro-v5.utils";

const DataGridProV5: React.FC<DataGridProProps> = (props) => {
  useEffect(() => {
    initializeDataGridProV5License();
  }, []);

  return <DataGridProV5Base {...props} />;
};

export default DataGridProV5;
