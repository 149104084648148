/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getMySuppliersColumns } from "./my-suppliers-table.columns";
import {
  selectFavoriteSuppliers,
  selectFavoriteSuppliersLoading,
} from "../../redux/selectors";
import { workspaceNextActions } from "../../redux";
import { useGridData } from "@next/hooks/useGridData-v5";
import i18n from "assets/configi18n/i18n";

export const useMySuppliersTableData = (paginatedQuery: string) => {
  const dispatch = useDispatch();

  const favoriteSuppliers = useSelector(selectFavoriteSuppliers);
  const favoriteSuppliersLoading = useSelector(selectFavoriteSuppliersLoading);
  const favoriteSuppliersColumns = useMemo(
    () => getMySuppliersColumns(),
    [favoriteSuppliersLoading, i18n?.language]
  );

  const { gridData, setGridData } = useGridData({
    rows: favoriteSuppliers,
    columns: favoriteSuppliersColumns,
    loading: favoriteSuppliersLoading,
  });

  useEffect(() => {
    dispatch(
      workspaceNextActions.fetchFavoriteSuppliersRequest({
        query: paginatedQuery,
      })
    );
  }, [paginatedQuery]);

  return {
    gridData,
    setGridData,
  };
};
