import { useDispatch, useSelector } from "react-redux";

import {
  selectBuyerProfile,
  selectBuyerProfileLoading,
} from "../../redux/selectors";
import { useEffect } from "react";
import { dashboardActions } from "../../redux";

export const useSellerDashboardBuyerProfileData = (buyerProfileId: number) => {
  const dispatch = useDispatch();
  const buyerProfile = useSelector(selectBuyerProfile);
  const buyerProfileLoading = useSelector(selectBuyerProfileLoading);

  useEffect(() => {
    if (buyerProfileId) {
      dispatch(
        dashboardActions.fetchBuyerProfileRequest({ id: buyerProfileId })
      );
    }
  }, [buyerProfileId]);

  return {
    buyerProfile,
    buyerProfileLoading,
  };
};
