import React from "react";

import { useSelector } from "react-redux";
import Avatar from "@mui/material/Avatar";
import { Tooltip } from "@mui/material";
import { getUser } from "services/profile/profile.selectors";
import { CustomizedMenus } from "../../../../../components/utils/customized-menus/customized-menus.component";
import { UserProfileMenu } from "./user-profile-menu/user-profile-menu";
import { useTranslation } from "react-i18next";

export const UserProfile = () => {
  const [anchorEl, setAnchorEl] = React.useState<
    (EventTarget & HTMLSpanElement) | null
  >(null);
  const user = useSelector(getUser);
  const { t } = useTranslation();

  return (
    <CustomizedMenus
      anchorEl={anchorEl}
      isMenuOpen={Boolean(anchorEl)}
      handleMenuClose={() => setAnchorEl(null)}
      anchorElement={
        <Tooltip title={Boolean(anchorEl) ? "" : t("appTour:profile")}>
          <Avatar
            style={{
              borderRadius: "100%",
              cursor: "pointer",
            }}
            alt="User Profile Image"
            src={user?.picture}
            variant="circular"
            onClick={(e) => setAnchorEl(e.currentTarget)}
          />
        </Tooltip>
      }
      menuItems={<UserProfileMenu />}
      marginTop={20}
    />
  );
};
