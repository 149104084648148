import { Box, Chip, CircularProgress, Tooltip, alpha } from "@mui/material";
import React, { useCallback, useMemo, useState } from "react";
import WatchLaterIcon from "@mui/icons-material/WatchLater";
import EmailIcon from "@mui/icons-material/Email";
import { formatDate } from "helpers/formatDate";
import { MUIDatePicker } from "@next/components/mui-date-picker";
import moment from "moment";
import { useTranslation } from "react-i18next";
import { AppTheme } from "layout/AppTheme";

import { createStyles, makeStyles } from "@mui/styles";

const dateFormat = "MMM D YYYY";

enum State {
  Unset = "unset",
  Set = "set",
  Sent = "sent",
}

const useStyles = makeStyles((theme) =>
  createStyles({
    successChip: {
      color: theme.palette.success.dark,
      backgroundColor: alpha(theme.palette.success.main, 0.1),
      "& .MuiChip-icon": {
        color: "inherit",
      },
    },
    defaultChip: {
      color: theme.palette.text.primary,
      "& .MuiChip-icon": {
        color: "inherit",
      },
    },
  })
);

interface Props {
  value: string | null;
  onChange?: (value: string | null) => void;
  loading?: boolean;
}

const RFQFollowupButton: React.FC<Props> = ({ value, onChange, loading }) => {
  const { t } = useTranslation();
  const styles = useStyles();
  const [open, setOpen] = useState(false);

  const date = useMemo(() => moment(value), [value]);
  const sent = useMemo(() => date.isBefore(moment()), [date]);

  const state = useMemo(() => {
    if (!date.isValid()) return State.Unset;
    if (sent) return State.Sent;
    return State.Set;
  }, [date, sent]);

  const label = useMemo(() => {
    if (state === State.Unset) return t("rfq:followupButton:unsetLabel");

    const formattedDate = formatDate(date, dateFormat);
    if (state === State.Sent)
      return t("rfq:followupButton:sentLabel", { date: formattedDate });
    return t("rfq:followupButton:setLabel", { date: formattedDate });
  }, [state, date]);

  const onDelete = useCallback(() => {
    onChange && onChange(null);
  }, [onChange]);

  const onDateChange = useCallback(
    (newDate) => {
      onChange && onChange(formatDate(newDate, "YYYY-MM-DD"));
    },
    [onChange]
  );

  const node = (
    <Box>
      <Chip
        icon={
          sent ? (
            <EmailIcon fontSize="small" />
          ) : (
            <WatchLaterIcon
              style={{ color: AppTheme.palette.text.secondary }}
            />
          )
        }
        onDelete={value && !sent ? onDelete : undefined}
        label={label}
        variant={state === State.Sent ? "outlined" : "outlined"}
        color={state === State.Set ? "primary" : "default"}
        className={
          sent ? styles.successChip : !value ? styles.defaultChip : undefined
        }
        onClick={
          state !== State.Sent && !loading ? () => setOpen(true) : undefined
        }
        deleteIcon={
          loading ? <CircularProgress color="inherit" size={16} /> : undefined
        }
      />
      <MUIDatePicker
        open={open}
        onClose={() => setOpen(false)}
        value={date}
        onChange={onDateChange}
        style={{ display: "none" }}
        shouldDisableDate={(date) =>
          moment(date).isBefore(moment().endOf("day"))
        }
        showTodayButton={false}
      />
    </Box>
  );

  return state === State.Unset ? (
    <Tooltip title={t("rfq:followupButton:unsetTooltip")}>{node}</Tooltip>
  ) : (
    node
  );
};

export default RFQFollowupButton;
