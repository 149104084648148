import { Avatar, Link, Theme, Typography } from "@mui/material";
import React from "react";
import { Trans, useTranslation } from "react-i18next";
import PublicRfqCreationFormButton from "../public-rfq-creation-form-button";
import DoneIcon from "@mui/icons-material/Done";
import { createStyles, makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: "flex",
      flexDirection: "column",
      height: "100%",
      justifyContent: "space-between",
      overflow: "auto",
      maxHeight: "calc(100vh - 170px)",
    },
    title: { display: "flex", alignItems: "center" },
    avatar: {
      marginRight: "8px",
      backgroundColor: theme.palette.success.main,
      width: 24,
      height: 24,
    },
    icon: {
      color: "white",
      width: 16,
      height: 16,
    },
  })
);

type Props = {
  name?: string;
  onClickSendAnotherOne: () => void;
};

const PublicRfqCreationSuccess: React.FC<Props> = ({
  name,
  onClickSendAnotherOne,
}) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const questionMail = "question@axya.co";

  return (
    <div className={classes.root}>
      <div>
        <Typography variant="h6" className={classes.title}>
          <Avatar className={classes.avatar}>
            <DoneIcon className={classes.icon} />
          </Avatar>
          {t("workspaceNext:publicRfqOLD:form:yourRequestHasBeenSent")}
        </Typography>
        <br />
        <Typography variant="body1" paragraph>
          <Trans
            i18nKey="workspaceNext:publicRfqOLD:successBody"
            values={{ name, link: questionMail }}
            components={[
              <Link
                href={`mailto:${questionMail}`}
                target="_blank"
                rel="noopener noreferrer"
              >
                {questionMail}
              </Link>,
            ]}
          />
        </Typography>
      </div>

      <PublicRfqCreationFormButton onClick={onClickSendAnotherOne}>
        {t("workspaceNext:publicRfqOLD:form:sendAnotherOne")}
      </PublicRfqCreationFormButton>
    </div>
  );
};

export default PublicRfqCreationSuccess;
