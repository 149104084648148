import { Box, IconButton, Tooltip, Typography, useTheme } from "@mui/material";
import { FileData } from "@next/components/attach-file/attach-file-list.types";
import { CustomTypography } from "@next/components/custom-typography";
import { fileDownloadFromUrl } from "@next/utils/downloadUtils";
import Viewer from "components/part-dialog/viewer/viewer.component";
import React, { useCallback } from "react";
import GetAppOutlinedIcon from "@mui/icons-material/GetAppOutlined";
import { useTranslation } from "react-i18next";
import { createStyles, makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) =>
  createStyles({
    img: {
      width: "100%",
      height: "100%",
      objectFit: "cover",
    },
    unknown: {
      width: "100%",
      height: "100%",
      display: "grid",
      placeItems: "center",
      textTransform: "uppercase",
    },
  })
);

interface Props {
  file: Partial<FileData>;
}

const CompanyProfileDocCard: React.FC<Props> = ({ file }) => {
  const theme = useTheme();
  const classes = useStyles();
  const { t } = useTranslation();

  const handleClick = useCallback(() => {
    if (file.file_url && file.file_name)
      fileDownloadFromUrl(file.file_url, file.file_name);
  }, []);

  return (
    <Box
      border={`1px solid ${theme.palette.divider}`}
      borderRadius="8px"
      overflow="hidden"
    >
      <Box height="198px" borderBottom={`1px solid ${theme.palette.divider}`}>
        {file.file_extension === "pdf" ? (
          <Viewer
            src={file.file_url}
            disableSnapshot
            activeFilePk={file.pk || -1}
          />
        ) : ["jpg", "jpeg", "png", "svg", "webp", "gif"].includes(
            file.file_extension || ""
          ) ? (
          <img src={file.file_url} className={classes.img} />
        ) : (
          <Box className={classes.unknown}>
            <Typography variant="body1">
              {file.file_extension || t("profile:companyProfile:noFile")}
            </Typography>
          </Box>
        )}
      </Box>
      <Box
        p={2}
        zIndex={1}
        position="relative"
        bgcolor={theme.palette.common.white}
        display="flex"
        alignItems="center"
        justifyContent="space-between"
      >
        <CustomTypography variant="body2">{file.file_name}</CustomTypography>
        {file.file_url && (
          <Tooltip title={t("profile:companyProfile:download")}>
            <IconButton
              onClick={handleClick}
              style={{ padding: 8 }}
              size="large"
            >
              <GetAppOutlinedIcon />
            </IconButton>
          </Tooltip>
        )}
      </Box>
    </Box>
  );
};

export default CompanyProfileDocCard;
