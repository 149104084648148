import {
  Avatar,
  Box,
  Chip,
  Divider,
  FormHelperText,
  Typography,
} from "@mui/material";
import { GenericTextInput } from "components/utils/input/text";
import { Form, FormikProps } from "formik";
import React from "react";
import { useTranslation } from "react-i18next";
import { SendOrderFormState } from "./send-order-form.definitions";
import { useDispatch, useSelector } from "react-redux";
import {
  selectDeleteOrderFileLoading,
  selectOrder,
  selectUploadOrderFileLoading,
} from "../../redux/selectors";
import SupplierContactsList from "@next/modules/workspace/components/supplier-contacts-list/supplier-contacts-list";
import { OrderDetailFollowupsTimeline } from "../order-detail/order-detail-followups-timeline/order-detail-followups-timeline";
import { useDeepCompareUpdateEffect } from "@next/hooks/useDeepCompareEffect";
import { DebouncedFunc } from "lodash";
import SendOrderDrawerFooter from "../../modals/send-order-drawer/send-order-drawer-footer";
import { AttachFileControlled } from "@next/components/attach-file-controlled";
import { ordersActions } from "../../redux";
import { FileData } from "@next/components/attach-file/attach-file-list.types";
import UsersPaginatedAutoComplete from "@next/modules/workspace/components/rfq-creation-form/users-paginated-autocomplete";
import SuppliersAutoCompleteDropdown from "@next/modules/workspace/components/rfq-creation-form/suppliers-autocomplete-dropdown";
import { useIsStaffUser } from "@next/modules/profile/hooks/useIsStaffUser";
import { selectCompanyUsers } from "services/profile/profile.selectors";
import { getSupportedPOFileTypes } from "@next/utils/constantUtils";
import { useHasCompanyRole } from "@next/hooks/useHasCompanyRole";

export const SendOrderFormBase: React.FC<
  FormikProps<SendOrderFormState> & {
    saveOrder: DebouncedFunc<(values: any) => void>;
  }
> = ({
  handleSubmit,
  values,
  touched,
  errors,
  setFieldValue,
  saveOrder,
  isSubmitting,
}) => {
  const { t } = useTranslation();
  const uploadOrderFileLoading = useSelector(selectUploadOrderFileLoading);
  const deleteOrderFileLoading = useSelector(selectDeleteOrderFileLoading);
  const orderDetail = useSelector(selectOrder);
  const isStaffUser = useIsStaffUser();
  const companyUsers = useSelector(selectCompanyUsers);
  const dispatch = useDispatch();
  const hasRestrictedViewerRole = useHasCompanyRole(["restricted_viewer"]);

  const isSupplierExistInOrder = orderDetail?.supplier?.id;

  useDeepCompareUpdateEffect(() => {
    saveOrder(values);
  }, [values?.delivery_date, values?.name, values?.note, values?.created_by]);

  const onAddFiles = (newFiles: File[]) => {
    if (!orderDetail?.pk) return;

    dispatch(
      ordersActions.uploadOrderFileRequest({
        pk: orderDetail?.pk,
        attachments: newFiles,
        onSuccess: (newFileData: FileData[]) => {
          setFieldValue("files", [...(values.files || []), ...newFileData]);
        },
      })
    );
  };

  const onDeleteFiles = (fileName: string) => {
    const foundFile = orderDetail?.files?.find((f: FileData) =>
      f.file_name.includes(fileName)
    );

    if (!orderDetail?.pk || !foundFile?.pk) return;

    dispatch(
      ordersActions.deleteOrderFileRequest({
        orderPk: orderDetail?.pk,
        filePk: foundFile?.pk,
        onSuccess: () => {
          setFieldValue(
            "files",
            values.files?.filter((f) => f.pk !== foundFile?.pk) || []
          );
        },
      })
    );
  };

  return (
    <>
      <Form onSubmit={handleSubmit}>
        <Box p="48px" pt="24px">
          <Box display="flex" alignItems="center">
            <Chip label={orderDetail?.line_items_count || ""} size="small" />

            <Box ml="8px">
              {t("orders:sendOrderDrawer:lineItems", {
                count: orderDetail?.line_items_count || 0,
              })}
            </Box>
          </Box>

          {isSupplierExistInOrder ? (
            <Box
              display="flex"
              alignItems="center"
              justifyContent={"space-between"}
              mt="20px"
            >
              <Box display="flex" alignItems="center">
                <Typography color="textSecondary">
                  {t("orders:sendOrderDrawer:to")}
                </Typography>

                <Box ml="8px" display="flex" alignItems="center">
                  <Avatar style={{ width: "32px", height: "32px" }} />

                  <Box ml="8px">
                    <Typography>{orderDetail?.supplier?.name}</Typography>
                  </Box>
                </Box>
              </Box>

              <Box display="flex" maxWidth="60%">
                <SupplierContactsList
                  supplier={orderDetail?.supplier}
                  users={orderDetail?.supplier?.target_profiles}
                />
              </Box>
            </Box>
          ) : null}

          <Box mt="24px">
            <GenericTextInput
              name="name"
              label={t("orders:sendOrderDrawer:orderNumber")}
              value={values.name}
              error={touched.name && !!errors.name}
            />
          </Box>

          {isStaffUser ? (
            <Box mt="24px">
              <UsersPaginatedAutoComplete
                options={companyUsers}
                fieldName="created_by"
                placeholder={t("orders:sendOrderDrawer:createOnBehalfOf")}
                valueKey="email"
                multiple={false}
              />
            </Box>
          ) : null}

          {!isSupplierExistInOrder ? (
            <Box mt="24px">
              <SuppliersAutoCompleteDropdown
                showGroupsAndMarketplace={false}
                multiple={false}
                fieldName="supplier"
                paperWidth="100%"
              />
            </Box>
          ) : null}

          <Box mt="24px">
            <GenericTextInput
              name="note"
              label={t("orders:sendOrderDrawer:addANote")}
              value={values.note}
              multiline
              rows={"6"}
              error={touched.note && !!errors.note}
            />
          </Box>

          <Box mt="24px">
            <AttachFileControlled
              files={values?.files || []}
              onAddFiles={onAddFiles}
              onDeleteFile={onDeleteFiles}
              loading={uploadOrderFileLoading || deleteOrderFileLoading}
              btnProps={{
                variant: "outlined",
                fullWidth: false,
                size: "medium",
                disabled: values?.files.length > 0,
              }}
              multiple={false}
              btnText={t("orders:sendOrderDrawer:attachPurchaseOrder")}
              acceptFiles={`${getSupportedPOFileTypes()}`}
            />

            {touched.files && !!errors.files && (
              <FormHelperText id="rfi-attachments-error" error={true}>
                {t("orders:sendOrderDrawer:filesRequiredErrorMsg")}
              </FormHelperText>
            )}
          </Box>
        </Box>

        <Divider light />

        <Box p="24px">
          <OrderDetailFollowupsTimeline
            descriptionText={t("orders:sendOrderDrawer:followUpText")}
            showOnlyDynamicFollowups={true}
          />
        </Box>
      </Form>

      {!hasRestrictedViewerRole ? (
        <SendOrderDrawerFooter
          onSubmit={handleSubmit}
          isSubmitting={isSubmitting}
        />
      ) : null}
    </>
  );
};
