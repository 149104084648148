import React from "react";
import { Dialog } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { ProjectModalTypes } from "./types";
import { getModal, modalsActions } from "@next/redux/modalsSlices";
import { BulkFileImport } from "../components/bulk-file-import/bulk-file-import";
import { projectActions } from "../redux";

export const ProjectBulkFileImportModal = () => {
  const dispatch = useDispatch();
  const modal = useSelector(getModal(ProjectModalTypes.BULK_FILE_IMPORT_MODAL));

  if (!modal) return null;

  const onClose = () => {
    dispatch(projectActions.resetBomFilesUploadReport());
    dispatch(
      modalsActions.closeModal(ProjectModalTypes.BULK_FILE_IMPORT_MODAL)
    );
  };

  return (
    <Dialog open={!!modal} closeAfterTransition maxWidth="md">
      <BulkFileImport
        onCloseModal={onClose}
        filesCount={modal?.data?.filesCount}
        onAttachmentsChange={modal?.data?.onAttachmentsChange}
      />
    </Dialog>
  );
};
