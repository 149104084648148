import { Box } from "@material-ui/core";
import { styled } from "@mui/material";

type Props = {
  isCompleted: boolean;
};

const FormBoxMd = styled(Box, {
  shouldForwardProp: (prop) => prop !== "isCompleted",
})<Props>(({ theme, isCompleted }) => ({
  width: "48%",
  display: "flex",
  flexDirection: "column",
  gap: "48px",
  padding: "48px",
  borderRadius: "16px",
  zIndex: 1,
  backgroundColor: "#ffffff",
  border: isCompleted
    ? `2px solid ${theme.palette.success.main}`
    : `1px solid ${theme.palette.divider}`,

  [theme.breakpoints.down("lg")]: {
    width: "60%",
  },
  [theme.breakpoints.down("md")]: {
    width: "80%",
  },
  [theme.breakpoints.down("sm")]: {
    width: "95%",
  },
}));
export default FormBoxMd;
