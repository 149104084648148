import React, { ReactNode, useCallback, useState } from "react";

import { useField } from "formik";
import { TextField, TextFieldProps } from "@mui/material";
import { useTranslation } from "react-i18next";

type colorType = "primary" | "secondary" | undefined;
type inputType = "text" | "number" | "file" | "date" | "email" | "password";
export interface ITextInputProps {
  placeholder?: string;
  rows?: string;
  label?: ReactNode;
  onChange?: () => void;
  onBlur?: (event: any) => void;
  disabled?: boolean;
  color?: colorType;
  name?: string;
  value?: string | number;
  className?: string;
  type?: inputType;
  ignorePasswordManager?: boolean;
  autoComplete?: string;
  maxLength?: number;
  hideHelperText?: boolean;
}

export const GenericTextInput: React.FC<TextFieldProps & ITextInputProps> = (
  props
) => {
  const { t } = useTranslation();
  const [field, meta] = useField(props as string);
  const errorText = meta.error && meta.touched ? meta.error : "";
  const {
    color = "primary",
    placeholder,
    rows,
    label,
    className,
    disabled,
    name,
    onBlur,
    type,
    variant = "outlined",
    ignorePasswordManager = true,
    maxLength,
    autoComplete,
    size,
    inputRef,
    multiline,
    maxRows,
    ...restProps
  } = props;

  const [len, setLen] = useState(field?.value?.length || 0);

  const onChange = useCallback(
    (e) => {
      setLen(e.target?.value?.length || 0);
      if (typeof field?.onChange === "function") field.onChange(e);
    },
    [field?.onChange]
  );

  return (
    <TextField
      {...restProps}
      color={color}
      placeholder={placeholder}
      label={label}
      type={type}
      rows={rows}
      fullWidth
      multiline={multiline === undefined ? !!rows : multiline}
      maxRows={maxRows}
      className={className}
      helperText={
        !props.hideHelperText
          ? errorText ||
            props.helperText ||
            (maxLength
              ? len
                ? t("misc:inputs:charsRemaining", { count: maxLength - len })
                : t("misc:inputs:maxChars", { count: maxLength })
              : "")
          : null
      }
      error={!!errorText}
      disabled={disabled}
      {...field}
      inputRef={inputRef}
      onChange={onChange}
      name={name}
      onBlur={onBlur}
      variant={variant}
      inputProps={{ "data-lpignore": ignorePasswordManager, maxLength }}
      autoComplete={autoComplete}
      size={size}
    />
  );
};
