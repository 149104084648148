import React from "react";

import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
} from "@mui/material";

type Props = {
  title: string;
  content?: string;
  dialogActions: React.ReactNode;
  isOpen: boolean;
  handleClose: () => void;
};

const ConfirmationDialog: React.FC<Props> = ({
  title,
  content = "",
  dialogActions,
  isOpen,
  handleClose,
}) => {
  return (
    <Dialog
      open={isOpen}
      onClose={handleClose}
      PaperProps={{ style: { minWidth: "400px" } }}
    >
      <DialogTitle>{title}</DialogTitle>

      {content ? (
        <DialogContent style={{ minWidth: "400px" }}>
          <Typography variant="body2">{content}</Typography>
        </DialogContent>
      ) : null}

      <DialogActions>{dialogActions}</DialogActions>
    </Dialog>
  );
};

export default React.memo(ConfirmationDialog);
