import { Grid, Theme } from "@mui/material";
import React, { useCallback, useState } from "react";
import PublicRfqCreationSuccess from "./public-rfq-creation-success";
import PublicRfqCreationForm from "./public-rfq-creation-form";
import { PublicRFQFormValuesType } from "./public-rfq-creation-form/public-rfq-creation-form.types";
import { useDispatch } from "react-redux";
import { DiscordChannel, workspaceNextActions } from "../../redux";
import { createStyles, makeStyles } from "@mui/styles";

const useStyles = makeStyles<Theme>((theme: Theme) =>
  createStyles({
    content: {},
    paper: {
      padding: "24px",
    },
    body: {
      position: "relative",
    },
  })
);

const PublicRfqCreation = () => {
  const [submittedData, setSubmittedData] = useState<
    PublicRFQFormValuesType | undefined
  >();
  const classes = useStyles();
  const dispatch = useDispatch();

  const onSubmit = useCallback((values: PublicRFQFormValuesType) => {
    setSubmittedData(values);

    dispatch(
      workspaceNextActions.createDiscordNotifyRequest({
        title: "AMQ_PUBLIC_RFQ_CREATED",
        channel_id: DiscordChannel.BUYER_ACTIVITY,
        ...values,
      })
    );
  }, []);

  const onClickSendAnotherOne = () => {
    setSubmittedData(undefined);
  };

  if (submittedData) {
    return (
      <PublicRfqCreationSuccess
        name={submittedData?.first_name}
        onClickSendAnotherOne={onClickSendAnotherOne}
      />
    );
  }

  return (
    <Grid container className={classes.body}>
      <Grid item className={classes.content}>
        <PublicRfqCreationForm onSubmit={onSubmit} />
      </Grid>
    </Grid>
  );
};

export default PublicRfqCreation;
