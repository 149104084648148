import React from "react";
import CustomModal from "@next/components/custom-modal/custom-modal";
import { SharedModalTypes } from "@next/modals/types";
import { modalsActions } from "@next/redux/modalsSlices";

import { useDispatch } from "react-redux";

export const OrderDetailHeaderModal = ({ partViewFileData, isModalOpen }) => {
  const dispatch = useDispatch();

  if (!partViewFileData) return null;

  return (
    <CustomModal
      isModalOpen={isModalOpen}
      disableContentPadding
      customWidth="100%"
      handleModalClose={() => {
        dispatch(
          modalsActions.closeModal(SharedModalTypes.SUPPLIER_VIEW_PART_MODAL)
        );
      }}
      modalState={{
        modalHeader: " ",
        modalBody: (
          <>
            <iframe
              style={{ width: "100%", height: "100%" }}
              src={partViewFileData.file_url}
              itemType="application/pdf"
              title={partViewFileData.pk}
            />
          </>
        ),
      }}
      dialogProps={{
        sx: {
          "& .MuiPaper-elevation": {
            maxWidth: "100%",
            height: "100%",
          },
          "& .MuiDialogContent-dividers": {
            overflow: "hidden",
          },
        },
      }}
    />
  );
};
