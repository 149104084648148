import React, { useState } from "react";
import { Avatar, Button, Theme } from "@mui/material";
import { useTranslation } from "react-i18next";
import CompanyAvatar from "../company-profile-full/company-avatar";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme: Theme) => ({
  root: { display: "flex", alignItems: "center" },
  avatar: {
    width: "80px",
    height: "80px",
  },
  input: {
    display: "none",
  },
  uploadLogo: {
    marginLeft: theme.spacing(2),
  },
}));

type Props = {
  value?: string;
  onChange: (file: File) => void;
  companyName?: string;
};

const ChangeAvatar: React.FC<Props> = ({ value, onChange, companyName }) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const [avatarPreview, setAvatarPreview] = useState<string>();

  const handleOnChangeAvatar = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event?.currentTarget?.files?.[0];
    if (file) {
      const fileReader = new FileReader();

      fileReader.onload = () => {
        onChange(file);
        setAvatarPreview(fileReader.result as string);
      };

      fileReader.readAsDataURL(file);
    }
  };

  return (
    <div className={classes.root}>
      {companyName ? (
        <CompanyAvatar
          avatarSrc={avatarPreview || value}
          companyName={companyName}
          showName={false}
        />
      ) : (
        <Avatar
          className={classes.avatar}
          variant="circular"
          src={avatarPreview || value}
        />
      )}
      <div className={classes.uploadLogo}>
        <Button variant="outlined" component="label">
          <input
            name="avatar"
            accept="image/*"
            id="contained-button-file"
            type="file"
            hidden
            onChange={handleOnChangeAvatar}
          />
          {companyName
            ? t("userCompany:uploadLogo")
            : t("userCompany:uploadProfilePicture")}
        </Button>
      </div>
    </div>
  );
};

export default ChangeAvatar;
