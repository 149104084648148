// Collection of useful Regex for forms verification

//allows letters and -
export const letterRegEx = /^[a-zA-Z \-,.()]*$/;
//forces a minium of 1 uppercase and a number
export const oneLetterOneNumber = new RegExp(
  "^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])"
);
// Only numbers
export const numberRegExp = /^[0-9\b]+$/;
//Allows Only numbers followed by exactly 1 dot and more numbers ex:"1221.212""
//TODO: dissallow start by .
export const onlyFloats = /^[0-9]*\.?[0-9]*$/;
// Only phone number
export const phoneRegExp = /^(\+\d{1,2}\s)?\(?\d{3}\)?[\s.-]\d{3}[\s.-]\d{4}$/;
//Url Check
export const urlRegex =
  /[-a-zA-Z0-9@:%._~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_.~#?&//=]*)/;
//ensure URL starts with HTTP/HTTPS:
export const httpUrlRegex =
  /https|http?:\/\/(www\.)?[-a-zA-Z0-9@:%._~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_.~#?&//=]*)/;

//Equivalent Regex Validator that is in backend validators.py
export const websiteValidator =
  /^((https|http):\/\/)?(www\.|WWW\.)?([A-Za-z0-9-]+)(\.)([A-Za-z]+)(\/[a-zA-Z0-9#]+\/?)*\/*$/;

//File type validator
export const SUPPORTED_FORMATS = ["image/jpg", "image/jpeg", "image/gif"];

//Postal code validator
export const postalCodeRegex = /^(\d{5}|[A-Za-z]\d[A-Za-z] ?\d[A-Za-z]\d)$/;

export const backendErrorRegex = /[\[\]\{\}\'/"/]/g;
