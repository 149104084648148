import { Box, Button, styled } from "@mui/material";
import { CustomTypography } from "@next/components/custom-typography";

export const StyledParentBox = styled(Box)(({ theme }) => ({
  backgroundColor: `${theme.palette.background.paper}`,
  width: "370px",
  display: "flex",
  flexDirection: "column",
  padding: "16px",
  gap: "16px",
}));

export const StyledFooterBox = styled(Box)({
  display: "flex",
  flexDirection: "row",
  justifyContent: "flex-end",
});

export const StyledSaveButton = styled(Button)(({ theme }) => ({
  width: "91px",
  height: "40px",
  display: "flex",
  flexDirection: "row",
  justifyContent: "center",
  alignItems: "center",
  borderRadius: "8px",
  "&:disabled": {
    opacity: "0.5",
    backgroundColor: `${theme.palette.primary.main}`,
    color: "#ffffff",
  },
}));

export const StyledInfoTypography = styled(CustomTypography)(({ theme }) => ({
  color: `${theme.palette.text.secondary}`,
  letterSpacing: "0.25px",
  marginLeft: "4px",
}));

export const StyledBox = styled(Box)({
  display: "flex",
  flexDirection: "row",
  gap: "8px",
  alignItems: "center",
  marginTop: "-6px",
});
