// This is the component to display a part in the workspace and then add it to a bundle
// This component is displayed on the main page in the workspace once you add a part

// General imports
import React, { useEffect, useRef, useState } from "react";

import { useSelector } from "react-redux";
import { useTracking } from "react-tracking";
import { Link } from "react-router-dom";

// Material ui imports
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Checkbox,
  Divider,
  Grid,
  Typography,
} from "@mui/material";

import PartThumbnail from "../../../components/part-dialog/thumbnail/thumbnail.component";
import { PartActions } from "./part-actions/part-actions.component";
import PartDialog from "../../../components/part-dialog/part-dialog.component";
import AddTags from "../../../components/add-tags/add-tags.component";
import ConfirmationDialog from "../../../@next/components/confirmation-dialog/confirmation-dialog";
import { frontendUrl } from "urls";
import { createStyles, makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) =>
  createStyles({
    partActive: {
      borderColor: theme.palette.primary.main,

      "& .MuiPaper-root": {
        border: `1px solid ${theme.palette.primary.main}`,
      },
    },
  })
);

export const Part = ({
  part,
  checked,
  deletePart,
  getPartById,
  token,
  onClickCheckBox,
  onClick,
  t,
  clonePart,
  handleClonePart,
  hidePartActions = false,
  showQuantity = false,
  showTags = true,
}) => {
  const tracking = useTracking();
  const [isEditMode, setIsEditMode] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [isOpenTagDialog, setIsOpenTagDialog] = useState(false);
  const [tagsHeight, setTagsHeight] = useState(0);
  const [isRfqPartEdit, setIsRfqPartEdit] = useState(false);
  const [rfqPartDetails, setRfqPartDetails] = useState({});
  const [isConfirmationModalOpen, setIsConfirmationModalOpen] = useState(false);
  const classes = useStyles();

  const useOutsideClickHandler = (ref) => {
    useEffect(() => {
      const handleClickOutside = (event) => {
        if (ref.current && !ref.current.contains(event.target)) {
          setAnchorEl(null);
        }
      };

      // Bind the event listener
      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        // Unbind the event listener on clean up
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }, [ref]);
  };

  const profile = useSelector((state) => state.profile);
  const rfqName = part.rfq ? part.rfq.display_name : "";

  const wrapperRef = useRef(null);
  useOutsideClickHandler(wrapperRef);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const addTagClickHandler = () => {
    setIsOpenTagDialog(true);
  };

  // function to open part part
  const onClickOpenPartDialog = () => {
    getPartById(token, part.pk);
    setIsEditMode(true);
    setAnchorEl(null);
    tracking.trackEvent({
      scout_category: "feature",
      scout_feature_name: "workspace_edit_part",
      scout_company: profile.company.name,
      scout_username: profile.user.username,
      scout_user_type: profile.user.type,
      scout_date: new Date(),
    });
  };
  const handleCloseModal = () => {
    setIsEditMode(false);
  };

  // function to delete part
  const onClickDeletePart = () => {
    deletePart(token, part.pk);
    tracking.trackEvent({
      scout_category: "feature",
      scout_feature_name: "workspace_delete_part",
      scout_company: profile.company.name,
      scout_username: profile.user.username,
      scout_user_type: profile.user.type,
      scout_date: new Date(),
    });
  };

  // function to clone part
  const onClickClonePart = () => {
    clonePart(token, part.pk, t);
    handleClonePart();
    setAnchorEl(null);
    tracking.trackEvent({
      scout_category: "feature",
      scout_feature_name: "workspace_clone_part",
      scout_company: profile.company.name,
      scout_username: profile.user.username,
      scout_user_type: profile.user.type,
      scout_date: new Date(),
    });
  };

  const tagsRef = useRef(null);

  // add overlay on card hover using transform property with the tags container height
  const partTagsOnMouseOver = () => {
    if (tagsHeight > 45 && tagsHeight < 200)
      return (tagsRef.current.style.cssText = `transform: translateY(-${tagsHeight}px); bottom : -62%`);
    // to prevent flickering on hover, set bottom to -71% when there are longer tag names and exceeds more than 4 rows
    else if (tagsHeight > 200)
      return (tagsRef.current.style.cssText = `transform: translateY(-${tagsHeight}px); height: auto; bottom : -71%`);
  };
  const partTagsOnMouseOut = () => {
    tagsRef.current.style.cssText = `transform: translateY(0%); height: 100%; bottom : -51%`;
  };

  const onclickCloseTagDialog = () => {
    setIsOpenTagDialog(false);
    partTagsOnMouseOut();
  };

  const handleEditRfqPartModalOpen = (part) => {
    setIsRfqPartEdit(true);
    setIsEditMode(true);
    setRfqPartDetails(part);

    // tracking
    tracking.trackEvent({
      scout_category: "feature",
      scout_feature_name: "rfq_edit_part",
      scout_company: profile.company.name,
      scout_username: profile.user.username,
      scout_user_type: profile.user.type,
      scout_date: new Date(),
    });
  };

  const openTagsDialog = () => {
    setIsOpenTagDialog(true);
    // tracking
    tracking.trackEvent({
      scout_category: "part_tags",
      scout_feature_name: "open_tags_dialog",
      scout_company: profile.company.name,
      scout_username: profile.user.username,
      scout_user_type: profile.user.type,
      scout_date: new Date(),
    });
  };

  const openConfirmationDialogue = () => {
    setIsConfirmationModalOpen(true);
  };

  const handleCloseDialog = () => {
    setIsConfirmationModalOpen(false);
  };
  return (
    part && (
      <section
        className={`c-part ${checked ? classes.partActive : ""}${
          hidePartActions ? "c-part--edit-rfq" : ""
        }`}
        ref={wrapperRef}
      >
        <Card>
          <CardHeader
            action={
              <>
                {hidePartActions ? (
                  <span onClick={() => handleEditRfqPartModalOpen(part)}>
                    {t("editRFQPart")}
                  </span>
                ) : (
                  <>
                    <Grid onClick={onClickCheckBox}>
                      <Checkbox
                        checked={checked}
                        edge="start"
                        tabIndex={-1}
                        disableRipple
                        color="primary"
                        className="c-part--checkbox"
                      />
                    </Grid>
                    <Grid item>
                      <PartActions
                        onClickDeletePart={openConfirmationDialogue}
                        onClickAddTagHandler={addTagClickHandler}
                        onClickOpenPartDialog={onClickOpenPartDialog}
                        handleClick={handleClick}
                        deletePart={deletePart}
                        open={Boolean(anchorEl)}
                        anchorEl={anchorEl}
                        t={t}
                        onClickClonePart={onClickClonePart}
                        hideClonePartMenuItem={!part?.rfq?.pk}
                      />
                    </Grid>
                  </>
                )}
              </>
            }
          />
          <PartThumbnail part={part} onClick={onClick} />
          <div
            className={showTags && "c-part--overlay"}
            ref={tagsRef}
            onMouseOver={showTags && partTagsOnMouseOver}
            onMouseOut={showTags && partTagsOnMouseOut}
          >
            <CardContent>
              <Grid item>
                <Typography variant="h6">{part.name}</Typography>
              </Grid>
              <Grid className="c-part--tags-container" xs={10} item>
                {rfqName && (
                  <>
                    <Box component="div">
                      <Link
                        className="color-link"
                        to={`${frontendUrl.bundleView}/${part.rfq.pk}`}
                      >
                        {rfqName}
                      </Link>
                    </Box>
                  </>
                )}
                {part.processes && (
                  <Box component="div">
                    {t("process")} :{" "}
                    {part.processes.map((p) => ` ${p.name}`).join()}
                  </Box>
                )}
                <Box component="div">
                  {t("alloy")} : {part.alloy}
                </Box>
                <Box component="div">
                  {t("treatment")} :{" "}
                  {part.treatment
                    ? part.treatment
                    : t("QUOTE_LIST:noTreatment")}
                </Box>
              </Grid>
              {showQuantity && part.quantity && (
                <>
                  <Divider light />
                  <Grid className="c-part--tags-container" xs={10} item>
                    <Box component="div">
                      {t("quantity")} : {part.quantity}
                    </Box>
                  </Grid>
                </>
              )}
            </CardContent>
            {showTags && (
              <CardContent className="c-part--add-tag">
                <AddTags
                  t={t}
                  partPk={part.pk}
                  tags={part.tags}
                  open={isOpenTagDialog}
                  onClickOpenTagDialog={() => openTagsDialog()}
                  onclickCloseTagDialog={onclickCloseTagDialog}
                  setTagsHeight={setTagsHeight}
                />
              </CardContent>
            )}
          </div>
        </Card>
        {isEditMode && (
          <PartDialog
            isOpen={isEditMode}
            isEditMode={isEditMode}
            handleCloseModal={handleCloseModal}
            isRfqPartEdit={isRfqPartEdit}
            rfqPartDetails={rfqPartDetails}
          />
        )}

        <ConfirmationDialog
          title={t("confirmation")}
          content={t("areYouSureYouWantToDeleteThisItem")}
          isOpen={isConfirmationModalOpen}
          handleClose={handleCloseDialog}
          dialogActions={
            <div>
              <Button autoFocus onClick={handleCloseDialog} color="primary">
                {t("cancel")}
              </Button>
              <Button onClick={onClickDeletePart} color="primary">
                {t("RFQ:yes")}
              </Button>
            </div>
          }
        />
      </section>
    )
  );
};
