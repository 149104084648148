import { Menu, MenuItem, Theme, Typography } from "@mui/material";
import React, { useCallback, useState } from "react";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import { useDispatch } from "react-redux";
import {
  PartUpload,
  ProjectCompanyPart,
  projectActions
} from "@next/modules/project/redux";
import { createStyles, makeStyles } from "@mui/styles";
import { useTranslation } from "react-i18next";
import { FileData } from "@next/components/attach-file/attach-file-list.types";
import CustomButton from "@next/components/custom-button";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { PartsDropdownForm } from "./bulk-file-import-summary-item-file-status";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: "flex",
      paddingLeft: "8px",
      paddingRight: "8px",
      marginTop: "12px"
    },
    left: {
      width: "50%",
      display: "flex",
      justifyContent: "space-between"
    },
    right: {
      width: "50%"
    },
    arrow: {
      paddingRight: "20px",
      display: "flex",
      alignItems: "center"
    },
    arrowIcon: {
      width: "16px",
      height: "16px",
      color: theme.palette.text.secondary
    },
    menu: {
      width: "100%",
      "& .MuiMenu-paper": {
        width: "400px"
      }
    },
    fullWidth: {
      width: "100%"
    },
    customBtn: {
      justifyContent: "space-between"
    }
  })
);

type Props = {
  file: any;
  error?: string;
  onAttachmentsChange?: (files: FileData[], filePkToDelete?: number) => void;
};

export const BulkFileImportSummaryItemFileStatusAttachment: React.FC<Props> = ({
  file,
  error,
  onAttachmentsChange
}) => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const { t } = useTranslation();
  const attachmentFilePk = file?.attachment?.pk;
  const reportFilePk = file?.report?.file_pk;
  const isAdditionalAttachment = typeof onAttachmentsChange === "function";
  const [fileAttachedTo, setFileAttachedTo] = useState(
    isAdditionalAttachment
      ? t("project:bulkFileImport:summary:additionalFiles")
      : t("project:bulkFileImport:summary:doNotImport")
  );
  const [lastSelectedPart, setLastSelectedPart] =
    useState<Partial<ProjectCompanyPart>>();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClose = () => {
    setAnchorEl(null);
  };

  const updateLastSelectedPartFiles = () => {
    if (!!lastSelectedPart) {
      const lastSelectedPartFilesPkWithoutCurrentFilePk =
        lastSelectedPart?.part_uploads_list
          ?.filter((file: PartUpload) => file?.pk !== reportFilePk)
          .map((file: PartUpload) => file?.pk) || [];
      dispatch(
        projectActions.updateProjectPartRequest({
          pk: lastSelectedPart?.pk as number,
          part: {
            files: [...lastSelectedPartFilesPkWithoutCurrentFilePk]
          },
          skipSuccessToast: true
        })
      );
    }
  };

  const handleClick = (isDeletion: boolean) => {
    if (isAdditionalAttachment) {
      onAttachmentsChange(
        [file?.attachment] as FileData[],
        isDeletion ? attachmentFilePk : -1
      );
    }
    setFileAttachedTo(
      t(
        `project:bulkFileImport:summary:${
          isDeletion ? "doNotImport" : "additionalFiles"
        }`
      )
    );
    updateLastSelectedPartFiles();
    handleClose();
  };

  const onSubmit = (values: any) => {
    const part = values?.parts;
    if (!part || part.length === 0) return;
    const currentFilesPk =
      part?.part_uploads_list?.map((file: PartUpload) => file?.pk) || [];

    if (reportFilePk) {
      dispatch(
        projectActions.updateProjectPartRequest({
          pk: part?.pk as number,
          part: {
            files: [...currentFilesPk, reportFilePk]
          },
          onSuccess: (updatedPart) => {
            setLastSelectedPart(updatedPart);
          },
          skipSuccessToast: true
        })
      );
      if (isAdditionalAttachment) {
        onAttachmentsChange([file?.attachment] as FileData[], attachmentFilePk);
      }
      setFileAttachedTo(part?.name);
      handleClose();
    }
  };

  const renderDropdown = useCallback(
    () => (
      <div>
        <CustomButton
          id="basic-button"
          aria-controls={open ? "basic-menu" : undefined}
          aria-haspopup="true"
          aria-expanded={open ? "true" : undefined}
          onClick={(event) => setAnchorEl(event.currentTarget)}
          endIcon={<KeyboardArrowDownIcon />}
          fullWidth
          className={classes.customBtn}
          color="secondary"
        >
          <Typography variant="body2">{fileAttachedTo}</Typography>
        </CustomButton>
        <Menu
          id="basic-menu"
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          MenuListProps={{
            "aria-labelledby": "basic-button"
          }}
          className={classes.menu}
        >
          {isAdditionalAttachment && (
            <MenuItem onClick={() => handleClick(false)}>
              {t("project:bulkFileImport:summary:additionalFiles")}
            </MenuItem>
          )}
          <MenuItem onClick={() => handleClick(true)}>
            {t("project:bulkFileImport:summary:doNotImport")}
          </MenuItem>
          <MenuItem>
            <div style={{ width: "100%" }}>
              <PartsDropdownForm onSubmit={onSubmit} />
            </div>
          </MenuItem>
        </Menu>
      </div>
    ),
    [fileAttachedTo, open, anchorEl, handleClick, onSubmit]
  );

  return (
    <div className={classes.root}>
      <div className={classes.left}>
        <Typography variant="body2">{file?.attachment?.file_name}</Typography>

        <div className={classes.arrow}>
          <ArrowForwardIcon className={classes.arrowIcon} />
        </div>
      </div>
      <div className={classes.right}>
        {renderDropdown()}
        {error && <Typography>{error}</Typography>}
      </div>
    </div>
  );
};
