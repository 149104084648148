import { Box, Typography } from "@mui/material";
import { Formik } from "formik";
import React from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { ordersActions } from "../../redux";
import { CreateOrderFormBase } from "./create-order-form.base";
import {
  CreateOrderFormState,
  createOrderFormValidationSchema,
} from "./create-order-form.definitions";

type Props = {
  rfqPk?: number;
  rfqName?: string;
  onClose: () => void;
  isManualOrder?: boolean;
};

export const CreateOrderForm: React.FC<Props> = ({
  rfqPk,
  rfqName,
  onClose,
  isManualOrder,
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const onSubmit = (values: CreateOrderFormState) => {
    dispatch(
      ordersActions.createOrderRequest({
        name: values.orderName,
        ...(rfqPk && { rfq_pk: rfqPk }),
      })
    );
  };

  return (
    <Box p="8px">
      {rfqName && !isManualOrder ? (
        <Box display={"flex"}>
          <Typography color="textSecondary">
            {t("orders:sendOrderDrawer:reference")}:
          </Typography>
          <Box ml="5px"></Box> <Typography>{rfqName}</Typography>
        </Box>
      ) : null}

      <Formik
        initialValues={{ orderName: "" }}
        validationSchema={createOrderFormValidationSchema}
        onSubmit={onSubmit}
        validateOnMount
        render={(props) => (
          <CreateOrderFormBase
            onClose={onClose}
            isManualOrder={isManualOrder}
            {...props}
          />
        )}
      />
    </Box>
  );
};
