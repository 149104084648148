import React, { useCallback } from "react";
import { useTranslation } from "react-i18next";

// Form Validation Import
import { Form, Formik } from "formik";

// MaterialUI specific Import
import { Box, Container, Typography } from "@mui/material";

// Image Assets
import logoAxyaBlack from "assets/img/logoHorizontalBlack.svg";
import * as Yup from "yup";
import CustomButton from "@next/components/custom-button";
import { GenericTextField } from "components/generic-text-field/generic-text-field.component";
import { useDispatch, useSelector } from "react-redux";
import { workspaceNextActions } from "@next/modules/workspace/redux";
import { selectCheckEmailExistsLoading } from "@next/modules/workspace/redux/selectors";
import { createStyles, makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) =>
  createStyles({
    logo: {
      height: "28px",
    },
    root: {
      display: "flex",
      flexDirection: "column",
      textAlign: "center",
      gap: theme.spacing(6),
    },
    box: {
      background: theme.palette.common.white,
      borderRadius: "24px",
      padding: theme.spacing(6),
    },
    nextBtn: {
      marginTop: theme.spacing(4),
    },
  })
);

export const PortalLogin: React.FC = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const checkEmailAlreadyExistLoading = useSelector(
    selectCheckEmailExistsLoading
  );

  const { t } = useTranslation();

  const loginValidation = Yup.object().shape({
    email: Yup.string()
      .required(t("userOnboarding:anEmailAddressIsRequired"))
      .email(t("userOnboarding:emailAddressIsInvalid")),
  });

  const checkEmail = useCallback(
    (values) => {
      dispatch(
        workspaceNextActions.checkEmailAlreadyExistsRequest({
          email: values.email,
          fromPortal: true,
        })
      );
    },
    [dispatch]
  );

  return (
    <div className={"auth"}>
      <Container maxWidth="sm" className={classes.box}>
        <Box className={classes.root}>
          <Box>
            <img
              src={logoAxyaBlack}
              alt={"Axya Logo"}
              className={classes.logo}
            />
          </Box>
          <Typography variant="h5">
            {t("userOnboarding:enterYourEmail")}
          </Typography>
          <Formik
            initialValues={{ email: "" }}
            onSubmit={checkEmail}
            validationSchema={loginValidation}
          >
            <Form>
              <GenericTextField
                name="email"
                label={t("userOnboarding:email")}
                size="small"
              />
              <CustomButton
                variant="contained"
                color="primary"
                fullWidth
                className={classes.nextBtn}
                type="submit"
                loading={checkEmailAlreadyExistLoading}
                data-pid={"btnCheckEmailPortalLogin"}
              >
                {t("common:continue")}
              </CustomButton>
            </Form>
          </Formik>
        </Box>
      </Container>
    </div>
  );
};

export default PortalLogin;
