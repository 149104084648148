import {
  PREMIUM_SUPPLIER_SUBSCRIPTION_COST,
  SUPPORTED_FILE_TYPES,
  SUPPORTED_IMAGE_FILE_TYPES,
  SUPPORTED_NDA_FILE_TYPES,
  SUPPORTED_PO_FILE_TYPES,
  SUPPORTED_QNA_FILE_TYPES
} from "@next/constants";
import i18n from "assets/configi18n/i18n";
import { store } from "store";
import { dynamicEnvs } from "urls";
import { formatCurrency } from "./numberUtils";

export const getSupportedFileTypes = () =>
  SUPPORTED_FILE_TYPES.map((value) => `.${value}`);

export const getSupportedImageFileTypes = () =>
  SUPPORTED_IMAGE_FILE_TYPES.map((value) => `.${value}`);

export const getSupportedNDAFileTypes = () =>
  SUPPORTED_NDA_FILE_TYPES.map((value) => `.${value}`);

export const getSupportedQnAFileTypes = () =>
  SUPPORTED_QNA_FILE_TYPES.map((value) => `.${value}`);

export const getSupportedPOFileTypes = () =>
  SUPPORTED_PO_FILE_TYPES.map((value) => `.${value}`);

export enum APP_ENVIRONMENT {
  PRODUCTION = "app.axya.co",
  STAGING = "staging.app.axya.co",
  NIGHTLY = "nightly.app.axya.co",
  DEMO = "demo.axya.co",
  AIRBUS = "airbus.app.axya.co",
  LOCALHOST = "localhost"
}

export const checkAppEnvironmentIs = (checkEnvironments: APP_ENVIRONMENT[]) => {
  return checkEnvironments.includes(
    window.location.hostname as APP_ENVIRONMENT
  );
};

export const getAppEnvironmentOverrides = () => {
  const envs: any = {};
  Object.keys(dynamicEnvs).forEach((key: string) => {
    const value = localStorage.getItem(key);
    if (value) {
      envs[key] = value;
    }
  });

  return envs;
};

export const getSupplierDirectoryLink = () => {
  const state = store.getState();
  const company = state?.profile?.company;
  const language = i18n.language;
  const supplierDirectoryLink = `${
    import.meta.env.VITE_SUPPLIER_DIRECTORY_IFRAME_URL || ""
  }/${language === "en" ? "en" : "fr"}/suppliers_directory/?_company=${
    company?.uuid
  }&page=0`;

  return supplierDirectoryLink;
};

export const getPremiumSupplierSubscriptionCost = () => {
  return formatCurrency(PREMIUM_SUPPLIER_SUBSCRIPTION_COST);
};

export const colorOptions = {
  lightgrey: "#CDCDCD",
  royalBlue: "#2682FD",
  redOrange: "#FF3E32",
  darkGold: "#FFA92C",
  limeGreen: "#12D67D",
  violetBlue: "#7736F2",
  darkNavy: "#0A2344",
  paleYellow: "#F5F85F",
  pink: "#F535D6"
};
