import React from "react";
import { Box, Chip, Theme, Tooltip, tooltipClasses } from "@mui/material";
import { createStyles, makeStyles, styled } from "@mui/styles";
import VisibilityIcon from "@mui/icons-material/Visibility";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import RfqFileViewHistory from "./rfq-addenda-file-view-history";
import { RFQActivity } from "@next/modules/workspace/redux";

type Props = {
  activityType: string;
  activityData: RFQActivity[];
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    chip: {
      border: "none",
      "& .MuiSvgIcon-root": {
        marginLeft: 8,
      },
      backgroundColor: theme.palette.chip.main,
    },
  })
);

const HtmlTooltip = styled(({ className, ...props }: any) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: "black",
    borderRadius: 8,
    maxWidth: 420,
    width: 420,
    maxHeight: 200,
    overflowY: "auto",
    fontSize: theme.typography.pxToRem(12),
  },
}));

export const RFQActivityChip: React.FC<Props> = ({
  activityType,
  activityData,
}) => {
  const classes = useStyles();

  return (
    <Box alignSelf="flex-start">
      <HtmlTooltip
        title={
          <RfqFileViewHistory
            isActivityTypeView={activityType === "view"}
            activityData={activityData}
          />
        }
      >
        <Chip
          className={classes.chip}
          variant="outlined"
          size="small"
          label={activityData?.length || 0}
          icon={
            activityType === "view" ? <VisibilityIcon /> : <ArrowDownwardIcon />
          }
        />
      </HtmlTooltip>
    </Box>
  );
};
