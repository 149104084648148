import React from "react";
import { useSelector } from "react-redux";
import { PosEndUsersQuestionnaire } from "./pos-end-users-questionnaire";
import { PomManufacturerQuestionnaire } from "./pom-manufacturer-questionnaire";
import { PomDistributorQuestionnaire } from "./pom-distributor-questionnaire";
import { ThankYouModal } from "../modals/thank-you-modal/thank-you-modal";
import { selectQuestionnaireName } from "../../redux/selectors";
import { Questionnaire } from "../../redux";

export const Questionnaries: React.FC = () => {
  const questionnaire = useSelector(selectQuestionnaireName);

  return (
    <>
      {questionnaire === Questionnaire.PosEndUsers ? (
        <PosEndUsersQuestionnaire />
      ) : null}
      {questionnaire === Questionnaire.PomManufacturer ? (
        <PomManufacturerQuestionnaire />
      ) : null}
      {questionnaire === Questionnaire.PomDistributor ? (
        <PomDistributorQuestionnaire />
      ) : null}

      <ThankYouModal />
    </>
  );
};
