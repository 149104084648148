import React from "react";
import {
  Box,
  Chip,
  CircularProgress,
  Menu,
  MenuItem,
  Typography
} from "@mui/material";
import {
  GridColDef,
  GridColumns,
  GridRenderCellParams
} from "@mui/x-data-grid-pro-v5";
import { t } from "assets/configi18n/i18n";
import IconButtonCell from "@next/components/table-cells/icon-button-cell-v5";
import { CompanyMembersField } from "./company-members.types";
import GenericAvatar from "@next/components/generic-avatar";
import AccessLevelMenuItem from "@next/modules/profile/components/access-level-menu-item/access-level-menu-item";
import {
  getAccessLevelHelperText,
  getAccessLevelTitle
} from "@next/modals/components/invite-company-user/invite-company-user.utils";
import { CompanyRole, profileNextActions } from "@next/modules/profile/redux";
import CustomButton from "@next/components/custom-button";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import { formatDate } from "helpers/formatDate";
import { useDispatch, useSelector } from "react-redux";
import { selectUpdateMemberTeamPermissionLoading } from "@next/modules/profile/redux/selectors";
import { withStyles } from "@mui/styles";
import { humanizedDuration } from "@next/utils/dateTimeUtils";
import { CompanyTypeNext } from "@next/redux/types";

const NameCell: React.FC<GridRenderCellParams> = ({ row }) => {
  let fullName = "";
  if (row?.first_name && row?.last_name) {
    fullName = `${row?.first_name} ${row?.last_name}`;
  } else if (row?.first_name) {
    fullName = row?.first_name;
  } else if (row?.last_name) {
    fullName = row?.last_name;
  }

  let invitationStatus;
  if (row?.invitation_status !== "accepted") {
    invitationStatus =
      row?.invitation_status.charAt(0).toUpperCase() +
      row?.invitation_status.slice(1);
  }
  return (
    <Box display="flex" justifyContent="center" alignItems="center" gap="12px">
      <GenericAvatar url={row?.picture} name={fullName} email={row?.email} />
      {fullName && <Typography variant="body2">{fullName}</Typography>}
      <Typography variant="body2" color="textSecondary">
        {row?.email || row?.username}
      </Typography>
      {invitationStatus && (
        <Chip
          label={
            invitationStatus === "Pending"
              ? t("profile:members:pending")
              : invitationStatus === "Expired"
                ? t("profile:members:expired")
                : invitationStatus
          }
          variant="outlined"
          color="primary"
          size="small"
        />
      )}
    </Box>
  );
};

const JoinedOnCell: React.FC<GridRenderCellParams> = ({ row }) => {
  const date = row?.date_joined
    ? formatDate(row.date_joined, "MMM DD, YYYY")
    : "-";
  return <Typography variant="body2">{date}</Typography>;
};

const LastConnectionCell: React.FC<GridRenderCellParams> = ({ row }) => {
  return (
    <Typography
      variant="body2"
      color={row?.last_login ? "primary" : "secondary"}
    >
      {row?.last_login ? humanizedDuration(row.last_login) : "-"}
    </Typography>
  );
};

const TeamPermissionCell: React.FC<GridRenderCellParams> = ({ row }) => {
  const dispatch = useDispatch();
  const updateLoadingArray = useSelector(
    selectUpdateMemberTeamPermissionLoading
  );

  const StyledMenuItem = withStyles(({ selected }) => ({
    root: {
      "&": {
        backgroundColor: selected ? "rgba(38, 130, 253, 0.08)" : "transparent"
      }
    }
  }))(MenuItem);

  const currentUserUpdateLoading = updateLoadingArray?.includes(row?.id);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [selectedAccessLevel, setSelectedAccessLevel] = React.useState<string>(
    getAccessLevelTitle(row?.user_type) || getAccessLevelTitle("normal")
  );
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleMenuItemClick = (event: React.MouseEvent<HTMLElement>) => {
    const value = event.currentTarget.getAttribute("value");
    if (value) {
      dispatch(
        profileNextActions.updateMemberTeamPermissionRequest({
          email: row?.email,
          user_type: value as CompanyRole,
          userId: row?.id,
          onSuccess: () => {
            setSelectedAccessLevel(getAccessLevelTitle(value as CompanyRole));
          }
        })
      );
      setAnchorEl(null);
    }
  };

  return (
    <Box display={"flex"} alignItems={"center"} gap={"12px"}>
      <CustomButton
        id="basic-button"
        onClick={handleClick}
        endIcon={open ? <ExpandLessIcon /> : <ExpandMoreIcon />}
        color="secondary"
      >
        <Typography variant="body2">{selectedAccessLevel}</Typography>
      </CustomButton>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "basic-button"
        }}
      >
        <StyledMenuItem
          value="normal"
          onClick={handleMenuItemClick}
          selected={
            selectedAccessLevel === getAccessLevelTitle("normal") ? true : false
          }
        >
          <AccessLevelMenuItem
            title={getAccessLevelTitle("normal")}
            helperText={getAccessLevelHelperText("normal")}
          />
        </StyledMenuItem>
        <StyledMenuItem
          value="viewer"
          onClick={handleMenuItemClick}
          selected={
            selectedAccessLevel === getAccessLevelTitle("viewer") ? true : false
          }
        >
          <AccessLevelMenuItem
            title={getAccessLevelTitle("viewer")}
            helperText={getAccessLevelHelperText("viewer")}
          />
        </StyledMenuItem>
        <StyledMenuItem
          value="restricted_viewer"
          onClick={handleMenuItemClick}
          selected={
            selectedAccessLevel === getAccessLevelTitle("restricted_viewer")
              ? true
              : false
          }
        >
          <AccessLevelMenuItem
            title={getAccessLevelTitle("restricted_viewer")}
            helperText={getAccessLevelHelperText("restricted_viewer")}
          />
        </StyledMenuItem>
      </Menu>
      {currentUserUpdateLoading && <CircularProgress size={30} />}
    </Box>
  );
};

const defaultColumnConfig: Partial<GridColDef> = {
  disableColumnMenu: true,
  sortable: false,
  hideSortIcons: true
};

export const getCompanyMembersColumns = (type: CompanyTypeNext) => {
  const _columns: GridColumns = [
    {
      ...defaultColumnConfig,
      field: CompanyMembersField.NAME,
      headerName: t("profile:members:name"),
      renderCell: NameCell,
      flex: 2
    },
    {
      ...defaultColumnConfig,
      field: CompanyMembersField.JOINED_ON,
      headerName: t("profile:members:joinedOn"),
      renderCell: JoinedOnCell,
      flex: 1
    },
    {
      ...defaultColumnConfig,
      field: CompanyMembersField.LAST_CONNECTION,
      headerName: t("profile:members:lastConnection"),
      renderCell: LastConnectionCell,
      flex: 1
    },
    {
      ...defaultColumnConfig,
      field: CompanyMembersField.TEAM_PERMISSION,
      headerName: t("profile:members:teamPermission"),
      renderCell: (params) => <TeamPermissionCell {...params} />,
      flex: 2,
      hide: type !== CompanyTypeNext.DO
    },
    {
      ...defaultColumnConfig,
      field: CompanyMembersField.ACTION_BUTTON,
      headerName: " ",
      width: 40,
      align: "center",
      renderCell: (params) => (
        <IconButtonCell
          disableMenu={params?.row?.invitation_status === "accepted"}
          disableHoverEffect={params?.row?.invitation_status === "accepted"}
          {...params}
        />
      ),
      disableColumnMenu: true
    }
  ];

  return _columns;
};
