import React, { useState } from "react";
import { Box, Button, Divider } from "@mui/material";
import { CustomTypography } from "@next/components/custom-typography";
import { Unstable_Popup as BasePopup } from "@mui/base/Unstable_Popup";
import * as S from "./analytic-graph-card.styled";
import { AppTheme } from "layout/AppTheme";
import { Typography } from "@material-ui/core";
import { useTranslation } from "react-i18next";

type CardContentType = {
  title: string;
  boldTypographyContent: string;
  smallTypographyContent?: string;
};

export type ContentType = {
  mainTitle: string;
  description?: string;
  cardContents?: CardContentType[];
};

type Props = {
  content: ContentType;
  GraphComponent: React.ReactNode;
  graphEditInputs?: { firstInput: number; secondInput: number };
  setGraphEditInputs?: (
    value:
      | { firstInput: number; secondInput: number }
      | ((prevState: { firstInput: number; secondInput: number }) => {
          firstInput: number;
          secondInput: number;
        })
  ) => void;
};

export const AnalyticGraphCard: React.FC<Props> = ({
  content,
  GraphComponent,
  graphEditInputs,
  setGraphEditInputs,
}) => {
  const { t } = useTranslation();
  const [anchor, setAnchor] = React.useState<null | HTMLElement>(null);
  const [popupInputs, setPopupInputs] = useState({
    firstInput: graphEditInputs?.firstInput,
    secondInput: graphEditInputs?.secondInput,
  });

  const handleClickManageSettingsBtn = (
    event: React.MouseEvent<HTMLElement>
  ) => {
    setAnchor(anchor ? null : event.currentTarget);
  };
  const open = Boolean(anchor);
  const id = open ? "simple-popper" : undefined;

  const handleClickUpdateBtn = (event: any) => {
    if (
      !Number.isNaN(popupInputs.firstInput) &&
      !Number.isNaN(popupInputs.secondInput)
    ) {
      setGraphEditInputs({
        firstInput: popupInputs.firstInput,
        secondInput: popupInputs.secondInput,
      });
      setAnchor(anchor ? null : event.currentTarget);
    }
  };

  const statusCards = content.cardContents?.map((cardContent, i) => {
    return (
      <React.Fragment key={cardContent?.title}>
        <div>
          <Box>
            <S.StyledTypography tooltipTitle={null} variant="body1">
              {cardContent?.title}
            </S.StyledTypography>
          </Box>

          <S.PercentageDiv>
            <S.BoldTypography tooltipTitle={null}>
              {cardContent?.boldTypographyContent}

              {cardContent?.smallTypographyContent ? (
                <S.StyledTypography tooltipTitle={null} marginLeft={"10px"}>
                  {cardContent?.smallTypographyContent}
                </S.StyledTypography>
              ) : null}
            </S.BoldTypography>
          </S.PercentageDiv>
        </div>
        {i !== content.cardContents.length - 1 && (
          <S.StyledDivider orientation="vertical" variant="middle" flexItem />
        )}
      </React.Fragment>
    );
  });

  const PopupComponent = graphEditInputs ? (
    <BasePopup id={id} open={open} anchor={anchor}>
      <S.PopupDiv>
        <S.InnerDiv>
          <S.PopupCardDiv>
            <Typography variant="body1">
              {t(
                "analytics:ordersReport:charts:shipment:popup:cards:left:title"
              )}
            </Typography>

            <Typography variant="body2">
              {t(
                "analytics:ordersReport:charts:shipment:popup:cards:left:text:before"
              )}
              <S.StyledLabel>
                {` ${graphEditInputs.firstInput} ${t(
                  "analytics:global:workingDays"
                )} `}
              </S.StyledLabel>
              {t(
                "analytics:ordersReport:charts:shipment:popup:cards:left:text:after"
              )}
            </Typography>

            <S.CustomTextField
              value={popupInputs.firstInput}
              onChange={(e) =>
                setPopupInputs({
                  ...popupInputs,
                  firstInput: parseInt(e.target.value),
                })
              }
              type="number"
              size="small"
              helperText={t("analytics:global:workingDays")}
            />
          </S.PopupCardDiv>

          <Divider style={{ height: "180px" }} orientation="vertical" />

          <S.PopupCardDiv>
            <Typography variant="body1">
              {t(
                "analytics:ordersReport:charts:shipment:popup:cards:right:title"
              )}
            </Typography>

            <Typography variant="body2">
              {t(
                "analytics:ordersReport:charts:shipment:popup:cards:right:text:before"
              )}
              <S.StyledLabel>
                {` ${graphEditInputs.secondInput} ${t(
                  "analytics:global:workingDays"
                )} `}
              </S.StyledLabel>{" "}
              {t(
                "analytics:ordersReport:charts:shipment:popup:cards:right:text:after"
              )}
            </Typography>

            <S.CustomTextField
              value={popupInputs.secondInput}
              onChange={(e) =>
                setPopupInputs({
                  ...popupInputs,
                  secondInput: parseInt(e.target.value),
                })
              }
              type="number"
              size="small"
              helperText={t("analytics:global:workingDays")}
            />
          </S.PopupCardDiv>
        </S.InnerDiv>

        <Box height={"72px"}>
          <Button
            onClick={handleClickUpdateBtn}
            size="medium"
            variant="contained"
            color="primary"
            disableElevation={true}
            style={{ position: "absolute", right: "0px", margin: "16px" }}
          >
            {t("analytics:ordersReport:charts:shipment:popup:btnUpdate")}
          </Button>
        </Box>
      </S.PopupDiv>
    </BasePopup>
  ) : null;

  return (
    <>
      <S.RootBox>
        <div style={{ width: "100%" }}>
          <S.HeaderDiv>
            <S.InnerDiv>
              <CustomTypography variant="h6">
                {content.mainTitle}
              </CustomTypography>
              {graphEditInputs ? (
                <Button
                  variant="text"
                  onClick={handleClickManageSettingsBtn}
                  style={{ marginLeft: "12px", cursor: "pointer" }}
                >
                  {t("analytics:ordersReport:charts:shipment:popup:btnManage")}
                </Button>
              ) : null}
              {graphEditInputs ? PopupComponent : null}
            </S.InnerDiv>

            {content.description && (
              <CustomTypography
                variant="body1"
                color={AppTheme.palette.text.secondary}
              >
                {content.description}
              </CustomTypography>
            )}

            {graphEditInputs ? (
              <CustomTypography
                variant="body1"
                color={AppTheme.palette.text.secondary}
                tooltipTitle={null}
              >
                {"Note : " + t("analytics:ordersReport:charts:shipment:note")}
              </CustomTypography>
            ) : null}
          </S.HeaderDiv>

          {statusCards ? <S.OverallStats>{statusCards}</S.OverallStats> : null}

          {statusCards ? (
            <Divider
              style={{ marginTop: "24px" }}
              orientation="horizontal"
              variant="middle"
              flexItem
            />
          ) : null}

          <S.StyledGraphBox>{GraphComponent}</S.StyledGraphBox>
        </div>
      </S.RootBox>
    </>
  );
};
