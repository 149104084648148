import { call, put, takeLatest } from "redux-saga/effects";
import {
  fetchAnnouncementsFailure,
  fetchAnnouncementsRequest,
  fetchAnnouncementsSuccess,
  getAnnouncementBySlugFailure,
  getAnnouncementBySlugRequest,
  getAnnouncementBySlugSuccess,
} from "./slices";
import { Announcement } from "./types";
import {
  getAnnouncementBySlug,
  getAnnouncements,
} from "../services/announcement-service";
import { PayloadAction } from "@reduxjs/toolkit";

export function* fetchAnnouncementsSaga() {
  try {
    const data: Announcement[] = yield call(getAnnouncements);

    yield put(fetchAnnouncementsSuccess(data));
  } catch (error) {
    yield put(fetchAnnouncementsFailure((error as Error).message));
  }
}
function* getAnnouncementBySlugSaga(action: PayloadAction<string>) {
  try {
    const announcement: Announcement = yield call(
      getAnnouncementBySlug,
      action.payload
    );
    yield put(getAnnouncementBySlugSuccess(announcement));
  } catch (error) {
    yield put(getAnnouncementBySlugFailure("Failed to fetch announcement."));
  }
}

// Watch
export function* announcementSaga() {
  yield takeLatest(fetchAnnouncementsRequest, fetchAnnouncementsSaga);
  yield takeLatest(
    getAnnouncementBySlugRequest.type,
    getAnnouncementBySlugSaga
  );
}
