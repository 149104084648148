import React, { useMemo, useState } from "react";
import {
  Box,
  Checkbox,
  Chip,
  FormControl,
  FormControlLabel,
  FormLabel,
  ListItemIcon,
  ListItemText,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  SelectChangeEvent,
} from "@mui/material";
import { Subscription, profileNextActions } from "@next/modules/profile/redux";
import { useDispatch, useSelector } from "react-redux";
import { getCompany } from "services/profile/profile.selectors";
import { getBuyersNetwork } from "services/part-configuration/part-configuration.selectors";
import { Buyer } from "./switch-subscription-rfq-recap.types";
import { SelectBuyerMenuProps } from "./switch-subscription-rfq-recap.definitions";
import { useTranslation } from "react-i18next";
import { selectUpdateCompanyProfileError } from "@next/modules/profile/redux/selectors";
import useUpdateEffect from "@next/hooks/useUpdateEffect";
import { createStyles, makeStyles } from "@mui/styles";

const useStyles = makeStyles(() =>
  createStyles({
    root: { display: "flex", flexDirection: "column" },
    chips: {
      display: "flex",
      flexWrap: "wrap",
    },
    chip: {
      margin: 2,
    },
    select: {
      "&.Mui-disabled": {
        opacity: 0.3,
      },
      height: 60,
    },
  })
);

type Props = {
  rfqRecapSubscription: Subscription;
};

const SwitchSubscriptionRfqRecap: React.FC<Props> = ({
  rfqRecapSubscription,
}) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const company = useSelector(getCompany);
  const companySubscribedToCompaniesIds = useMemo(
    () => company?.subscribed_to_companies?.map((item: Buyer) => item?.id),
    [company?.subscribed_to_companies]
  );
  const buyersNetwork = useSelector(getBuyersNetwork) as Buyer[];
  const updateCompanyProfileError = useSelector(
    selectUpdateCompanyProfileError
  );

  const [isSubscribed, setIsSubscribed] = useState<string>(
    `${rfqRecapSubscription?.is_user_subscribed}`
  );
  const [selectedBuyers, setSelectedBuyers] = useState<number[]>(
    companySubscribedToCompaniesIds
  );

  useUpdateEffect(() => {
    // If there is any error, we should reset the state with current data
    if (updateCompanyProfileError) {
      setSelectedBuyers(companySubscribedToCompaniesIds);
    }
  }, [updateCompanyProfileError]);

  const handleChangeRadioGroup = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setIsSubscribed(event.target?.value);

    dispatch(
      profileNextActions.enableSubscriptionRequest({
        name: rfqRecapSubscription?.name,
        subPk: rfqRecapSubscription?.pk,
        isEnabled: event.target?.value === "true",
        eventType: rfqRecapSubscription?.event_type,
      })
    );
  };

  const handleChangeSelect = (event: SelectChangeEvent<any>) => {
    const newSelectedBuyers = event.target.value as number[];

    setSelectedBuyers(newSelectedBuyers);

    if (company?.id) {
      dispatch(
        profileNextActions.updateCompanyProfileRequest({
          id: `${company.id}`,
          subscribed_to_companies: newSelectedBuyers,
        })
      );
    }
  };

  return (
    <div className={classes.root}>
      <FormControl component="fieldset">
        <Box mb="8px">
          <FormLabel focused={false} component="legend">
            {t("profile:userProfile:newRfqsDailySummary")}
          </FormLabel>
        </Box>
        <RadioGroup value={isSubscribed} onChange={handleChangeRadioGroup}>
          <FormControlLabel
            value="true"
            control={<Radio color="primary" />}
            label={t("profile:userProfile:wholeAxyaNetwork")}
          />
          <FormControlLabel
            value="false"
            control={<Radio color="primary" />}
            label={t("profile:userProfile:selectedBuyers")}
          />
        </RadioGroup>
      </FormControl>

      <Select
        variant="outlined"
        multiple
        displayEmpty
        value={selectedBuyers}
        onChange={handleChangeSelect}
        className={classes.select}
        renderValue={(selected) => {
          const selectedValues = selected as number[];

          return selectedValues?.length === 0 ? (
            <span>{t("profile:userProfile:selectBuyers")}</span>
          ) : (
            <div className={classes.chips}>
              {selectedValues?.map((value) => {
                const selectedBuyer = buyersNetwork?.find(
                  (item) => item?.id === value
                );

                if (selectedBuyer) {
                  return (
                    <Chip
                      key={selectedBuyer?.id}
                      label={selectedBuyer?.name}
                      className={classes.chip}
                    />
                  );
                }
              })}
            </div>
          );
        }}
        MenuProps={{
          ...SelectBuyerMenuProps,
          anchorOrigin: {
            vertical: "bottom",
            horizontal: "center",
          },
          transformOrigin: {
            vertical: "top",
            horizontal: "center",
          },
        }}
        disabled={isSubscribed === "true"}
      >
        {buyersNetwork?.map((buyer: Buyer) => (
          <MenuItem key={buyer?.id} value={buyer?.id}>
            <ListItemIcon>
              <Checkbox
                checked={
                  selectedBuyers.findIndex((item) => item === buyer?.id) >= 0
                }
                color="primary"
              />
            </ListItemIcon>

            <ListItemText primary={buyer?.name} />
          </MenuItem>
        ))}
      </Select>
    </div>
  );
};

export default SwitchSubscriptionRfqRecap;
