import { t } from "assets/configi18n/i18n";
import { BulkFileImportStep } from "./bulk-file-import";

export const getTitleByStep = (step: BulkFileImportStep) => {
  switch (step) {
    case BulkFileImportStep.UPLOAD_FILE:
      return t("project:bulkFileImport:title");
    case BulkFileImportStep.SUMMARY:
      return t("project:bulkFileImport:summary:title");

    default:
      return null;
  }
};
