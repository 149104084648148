import React, { useCallback, useEffect, useMemo, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { NewsfeedPostComponent } from "../components/newsfeed-post";
import {
  selectNewsfeed,
  selectNewsfeedIsNextPageAvailable,
  selectNewsfeedLoading,
  selectNewsfeedPage,
} from "../redux/selectors";
import { newsfeedActions } from "../redux/slices";
import { Box, Divider, Typography } from "@mui/material";
import { BuyerProfileDrawer } from "@next/modules/dashboard/modals/buyer-profile-drawer";
import { t } from "assets/configi18n/i18n";
import Skeleton from "@mui/material/Skeleton";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles({
  divider: {
    marginTop: "24px",
    marginBottom: "24px",
  },
});

export const Newsfeed = () => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const newsfeed = useSelector(selectNewsfeed);
  const newsfeedPage = useSelector(selectNewsfeedPage);
  const newsfeedLoading = useSelector(selectNewsfeedLoading);
  const newsfeedIsNextPageAvailable = useSelector(
    selectNewsfeedIsNextPageAvailable
  );

  const observer = useRef<IntersectionObserver | null>(null);

  useEffect(() => {
    dispatch(newsfeedActions.fetchNewsfeedRequest({ page: 1, reset: true }));
  }, [dispatch]);

  const lastElementRef = useCallback(
    (node) => {
      if (observer.current) observer.current.disconnect();
      observer.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting && newsfeedIsNextPageAvailable) {
          dispatch(
            newsfeedActions.fetchNewsfeedRequest({ page: newsfeedPage + 1 })
          );
        }
      });
      if (node) observer.current.observe(node);
    },
    [dispatch, newsfeedPage, newsfeedIsNextPageAvailable]
  );

  const skeletons = useMemo(
    () =>
      Array.from({ length: 5 }).map((_) => (
        <div>
          <Skeleton
            width="100%"
            height={210}
            variant="rectangular"
            style={{ borderRadius: 4 }}
          />
          <Divider className={classes.divider} />
        </div>
      )),
    []
  );

  const posts = useMemo(
    () =>
      newsfeed.map((post) => (
        <div key={post.title}>
          <NewsfeedPostComponent post={post} />
          <Divider className={classes.divider} />
        </div>
      )),
    [newsfeed]
  );

  return (
    <>
      <Box maxWidth={880} marginX="auto">
        <Typography variant="h5">{t("newsfeed:whatsNewOnAxya")}</Typography>
        <Divider className={classes.divider} />
        {posts}
        {newsfeedLoading && skeletons}
        {!newsfeedLoading && <div ref={lastElementRef}>&nbsp;</div>}
      </Box>
      <BuyerProfileDrawer />
    </>
  );
};
