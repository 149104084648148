// RF-TODO: convert store to type safety store
export const getActiveRfqDetails = (state: any) =>
  state.marketplace.activeRfqDetails;

export const getRecommendedRFQList = (state: any) =>
  state.marketplace.recommendedRFQList;

export const marketplaceSelectors = {
  getActiveRfqDetails,
  getRecommendedRFQList,
};
