import React, { ComponentType, ReactNode, useMemo } from "react";
import { Box, Button, Typography } from "@mui/material";
import { getSupportedFileTypes } from "@next/utils/constantUtils";
import { useDispatch, useSelector } from "react-redux";
import { modalsActions } from "@next/redux/modalsSlices";
import { SharedModalTypes } from "@next/modals/types";
import { useTranslation } from "react-i18next";
import { AttachFileList } from "./attach-file-list";
import { FileData } from "./attach-file-list.types";
import { convertFilesToObjects, pickFiles } from "@next/utils/fileUtils";
import { AttachFilePickButton } from "./attach-file-pick-button";
import { selectFileUploadProgress } from "@next/modules/workspace/redux/selectors";
import { createStyles, makeStyles } from "@mui/styles";
import snackbarUtils from "@next/utils/snackbarUtils";

const useStyles = makeStyles((theme) =>
  createStyles({
    attachFile: { display: "flex", alignItems: "center" },
    captionIcon: {
      marginLeft: theme.spacing(1),
      color: theme.palette.success.main
    },
    captionText: {
      marginLeft: theme.spacing(1),
      color: theme.palette.action.active
    },
    button: {
      whiteSpace: "nowrap",
      textOverflow: "ellipsis",
      overflow: "hidden"
    },
    bottomSpacing: {
      marginBottom: theme.spacing(1.5)
    },
    topSpacing: {
      marginTop: theme.spacing(1.5)
    }
  })
);

export type AttachFileProps = {
  files: (File | FileData)[];
  onChangeFiles: (files: (File | FileData)[]) => void;
  onDeleteFiles?: (filesName: string) => void;
  btnProps?: Partial<React.ComponentProps<typeof Button>>;
  btnText?: string;
  captionText?: string | ReactNode;
  captionIcon?: React.ReactElement;
  filesOnTop?: boolean;
  accept?: string;
  disableAcceptSpecificTypes?: boolean;
  deletedFieldName?: string;
  addedFieldName?: string;
  buttonComponent?: ({ onClick }: { onClick: () => void }) => React.ReactNode;
  fileListContainerClassName?: string;
  customAttachButton?: ComponentType;
  skipDeleteConfirmationModal?: boolean;
};

const AttachFile: React.FC<AttachFileProps> = ({
  files = [],
  onChangeFiles,
  onDeleteFiles,
  btnProps,
  btnText,
  captionText,
  captionIcon,
  filesOnTop = false,
  accept = `${getSupportedFileTypes()}`,
  disableAcceptSpecificTypes,
  buttonComponent,
  fileListContainerClassName,
  customAttachButton: CustomAttachButton,
  skipDeleteConfirmationModal
}) => {
  const acceptedFileTypes = !disableAcceptSpecificTypes ? accept : undefined;
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const fileUploadProgress = useSelector(selectFileUploadProgress);
  const classes = useStyles();
  const attachedFiles: FileData[] = useMemo(
    () => convertFilesToObjects(files, fileUploadProgress),
    [files, fileUploadProgress]
  );

  const onPickFiles = () => {
    pickFiles({
      multiple: true,
      accept: acceptedFileTypes,
      maxMBs: 10,
      onChange: (newFiles) => {
        for (let file of newFiles) {
          const isExist = attachedFiles.find(
            (item) => item.file_name === file.name
          );
          if (isExist) {
            return snackbarUtils.error(t("workspaceNext:fileAlreadyExist"));
          }
        }

        onChangeFiles([...files, ...newFiles]);
      }
    });
  };

  const onClickDelete = (fileName: string) => {
    const fileToDelete = attachedFiles.find(
      (item) => item.file_name === fileName
    );
    if (skipDeleteConfirmationModal) {
      if (typeof onDeleteFiles === "function") {
        onDeleteFiles(`${fileToDelete?.file_name}`);
      }
      onChangeFiles(
        attachedFiles.filter((item) => item.file_name !== fileName)
      );
    } else {
      dispatch(
        modalsActions.showModal({
          key: SharedModalTypes.ATTACH_FILE_DELETE_CONFIRMATION_MODAL,
          data: () => {
            if (typeof onDeleteFiles === "function") {
              onDeleteFiles(`${fileToDelete?.file_name}`);
            }
            onChangeFiles(
              attachedFiles.filter((item) => item.file_name !== fileName)
            );
          }
        })
      );
    }
  };

  const FilesList = (
    <AttachFileList files={attachedFiles} onClickDelete={onClickDelete} />
  );

  return (
    <>
      {filesOnTop ? (
        <Box
          className={`${classes.bottomSpacing} ${fileListContainerClassName}`}
        >
          {FilesList}
        </Box>
      ) : null}

      {buttonComponent ? (
        buttonComponent({ onClick: onPickFiles })
      ) : (
        <div className={classes.attachFile}>
          {CustomAttachButton ? (
            <CustomAttachButton
              onClick={onPickFiles}
              className={classes.button}
              {...(btnProps as any)}
            >
              {btnText || t("workspaceNext:attachFile")}
            </CustomAttachButton>
          ) : (
            <AttachFilePickButton
              onClick={onPickFiles}
              className={classes.button}
              {...(btnProps as any)}
            >
              {btnText || t("workspaceNext:attachFile")}
            </AttachFilePickButton>
          )}

          {captionIcon ? (
            <div className={classes.captionIcon}>{captionIcon}</div>
          ) : null}
          {captionText ? (
            <Typography variant="body2" className={classes.captionText}>
              {captionText}
            </Typography>
          ) : null}
        </div>
      )}

      {!filesOnTop ? (
        <Box className={`${classes.topSpacing} ${fileListContainerClassName}`}>
          {FilesList}
        </Box>
      ) : null}
    </>
  );
};

export default AttachFile;
