import React, { useState } from "react";
import Chart from "react-apexcharts";
import { useDispatch, useSelector } from "react-redux";

import Pagination from "@mui/material/Pagination";
import { Box, CircularProgress } from "@mui/material";

import StatePriceTable from "../stats-price-table/stats-price-table.component";
import StateDelayTable from "../stats-delay-table/stats-delay-table.component";

import { marketplaceActions } from "../../../../services/marketplace/marketplace.actions";
import chartSvg from "../../../../assets/img/Chart.svg";

export const Graph = ({ t }) => {
  const dispatch = useDispatch();
  const reduxState = useSelector((state) => state);
  const token = reduxState.profile.token;
  const statsList = reduxState.marketplace.statsList;
  const rfqId = reduxState.quotation.quotationDetails.rfq;
  const statsError = reduxState.marketplace.statsError;
  const [isInitialLoadDone, setIsInitialLoadDone] = useState(false);
  const [state, setState] = React.useState({
    isStatsLoading: true,
    options: {
      chart: {
        id: "basic-bar",
      },
      grid: { show: false, position: "center" },
      theme: {
        palette: "palette8", // upto palette10
      },
      xaxis: {
        categories: [],
        position: "bottom",
        axisBorder: {
          show: true,
        },
        axisTicks: {
          show: false,
        },
        labels: {
          show: true,
          formatter: function (val) {
            if (val?.length > 0 && Array.isArray(val)) {
              return val?.map((item) => {
                // add days delay text only to the delay, not to the supplier name
                return typeof item === "number"
                  ? `${item} ${t("daysDelay")}`
                  : item;
              });
            }
          },
        },
      },
      yaxis: {
        axisBorder: {
          show: false,
        },
        axisTicks: {
          show: false,
        },
        labels: {
          show: false,
        },
      },
      plotOptions: {
        bar: {
          dataLabels: {
            position: "top", // top, center, bottom
          },
        },
      },
      dataLabels: {
        enabled: true,
        formatter: function (val) {
          return "$" + val;
        },
        offsetY: -20,
        style: {
          fontSize: "10px",
          colors: ["#5a5a5a"],
        },
      },
      stroke: {
        show: false,
        width: 2,
        colors: ["transparent"],
      },
    },
    series: [],
  });
  const handlePageChange = (e, page) => {
    fetchStats((page = page));
  };

  // memoizing function to prevent multiple API calls for same RFQid
  const fetchStats = React.useCallback(
    (page = 1) => {
      dispatch(marketplaceActions.getRFQStats(rfqId, token, page));
    },
    [rfqId]
  );

  React.useEffect(() => {
    if (statsError && statsError.length > 0) {
      setState({
        ...state,
        isStatsLoading: false,
      });
    }
    if (!isInitialLoadDone) {
      setState({
        ...state,
        isStatsLoading: true,
      });
    } else if (reduxState.marketplace.statsLoading) {
      setState({
        ...state,
        isStatsLoading: true,
      });
    }
  }, [reduxState.marketplace.statsLoading]);
  React.useEffect(() => {
    fetchStats();
    setIsInitialLoadDone(true);
  }, [rfqId]);

  React.useEffect(() => {
    if (statsList && Object.keys(statsList).length > 0) {
      setState({
        ...state,
        isStatsLoading: false,
        series: statsList.barValue,
        options: {
          ...state.options,
          xaxis: {
            ...state.options.xaxis,
            categories: statsList.xAxis,
          },
        },
      });
    }
  }, [statsList]);

  return (
    <section className="c-graph">
      {!state.isStatsLoading &&
      statsList &&
      Object.keys(statsList).length > 0 &&
      statsError.length === 0 ? (
        statsList.type === "Chart" ? (
          <>
            <Chart
              options={state.options}
              series={state.series}
              type="bar"
              className="c-graph--bar"
              height="300"
            />
            {statsList.supplierComments && (
              <section className="c-comments-section">
                {statsList.supplierComments.map((supplierComment) => {
                  return (
                    supplierComment[1] && (
                      <div>
                        <h6>{supplierComment[0]} : </h6>
                        <i>{supplierComment[1]}</i>
                      </div>
                    )
                  );
                })}
              </section>
            )}
          </>
        ) : (
          <Box display="flex" flexDirection="column" width="100%">
            <StatePriceTable data={statsList.data.rankingByPartPrice} t={t} />
            <StateDelayTable
              data={statsList.data.rankingByDelay}
              language={reduxState.profile.user.language}
              t={t}
            />
          </Box>
        )
      ) : state.isStatsLoading ? (
        <>
          <Box
            display="flex"
            width={"100%"}
            alignItems="center"
            justifyContent="center"
            alignContent="center"
            flex="1"
          >
            <CircularProgress />
          </Box>
        </>
      ) : (
        <section className="c-graph--empty-view" align="center">
          <img src={chartSvg} alt="chart" width="160" />
          <p>{t("noGraphText")}</p>
        </section>
      )}
      <Box
        display="flex"
        width={"100%"}
        alignItems="center"
        justifyContent="center"
      >
        {statsList && statsList.totalPages ? (
          <Pagination
            onChange={handlePageChange}
            count={statsList.totalPages}
            hidePrevButton
            hideNextButton
          />
        ) : (
          <></>
        )}
      </Box>
    </section>
  );
};
