import { PayloadAction } from "@reduxjs/toolkit";
import { put, takeEvery, takeLatest } from "redux-saga/effects";
import { safeCall } from "@next/redux/safeCall";
import { vendorManagementService } from "../services/vendor-management-services";
import { vendorManagementActions } from "./slices";
import {
  AddOrUpdateProcessControlStandard,
  AddSpecToScopeRequest,
  AddSpecToSubtierSupplier,
  DeleteProcessControlStandard,
  DeleteSpecOfSubtierSupplier,
  FetchDesignAndBuiltDrawings,
  FetchFormDataRequest,
  FetchSpecsOfBufferStockInput,
  FetchSpecsOfLeadTimeProductionInput,
  FetchSpecsOfSubtierSupplier,
  FetchStepsRequest,
  PaginationAndFilterForScopeSpecs,
  PaginationAndFilterForSpecification,
  QuestionnaireForms,
  RemoveSpecFromScopeRequest,
  SaveAdditionalComment,
  SaveSpecOfScopeRequest,
  SendSupportMessageInput,
  UpdateApprovalFormFile,
  UpdateDesignAndBuildDrawing,
  UpdateFormDataRequest,
  UpdateIdentificationDataBody,
  UpdateLeadTimeImplFormData,
  UpdateSpecOfBufferStockStrategy,
  UpdateSpecOfLeadTimeForProduction,
  UpdateSpecOfSubtierSupplier,
  UpdateStepRequest,
  UpdateSubtierFormData,
  UpdateSubtierOnboardingTableRow,
  UpdateSubtiersOnboardingFormData
} from "./types";
import snackbarUtils from "@next/utils/snackbarUtils";

function* handleFetchFormDataRequest({
  payload
}: PayloadAction<FetchFormDataRequest>) {
  const { response, error } = yield safeCall(
    vendorManagementService.fetchFormData,
    payload
  );
  if (error) {
    yield put(
      vendorManagementActions.fetchFormDataFailure(
        new Error("Something went wrong. Please try again.")
      )
    );
  } else {
    yield put(
      vendorManagementActions.fetchFormDataSuccess({
        data: response.data,
        stepName: payload.formName,
        fetcha2psRequest: payload?.fetcha2psRequest
      })
    );
    if (typeof payload.onSuccess === "function") {
      payload.onSuccess();
    }
  }
}

function* handleUpdateFormDataRequest({
  payload
}: PayloadAction<UpdateFormDataRequest>) {
  const { response, error } = yield safeCall(
    vendorManagementService.updateFormData,
    payload
  );

  if (error) {
    yield put(
      vendorManagementActions.updateFormDataFailure(
        new Error("Something went wrong. Please try again.")
      )
    );
  } else {
    yield put(
      vendorManagementActions.updateFormDataSuccess({
        data: response.data,
        stepName: payload.formName
      })
    );
    if (payload.formName === QuestionnaireForms.Identification) {
      yield put(
        vendorManagementActions.fetchSupplierByCompanyIdSuccess(response.data)
      );
    }
  }
}

function* handleFetchStepsRequest({
  payload
}: PayloadAction<FetchStepsRequest>) {
  const { response, error } = yield safeCall(
    vendorManagementService.fetchSteps,
    payload
  );

  if (error) {
    yield put(
      vendorManagementActions.fetchStepsFailure(
        new Error("Something went wrong. Please try again.")
      )
    );
  } else {
    yield put(vendorManagementActions.fetchStepsSuccess(response.data));
  }
}

function* handleUpdateStepRequest({
  payload
}: PayloadAction<UpdateStepRequest>) {
  const { response, error } = yield safeCall(
    vendorManagementService.updateStep,
    payload
  );

  if (error) {
    yield put(
      vendorManagementActions.updateStepFailure(
        new Error("Something went wrong. Please try again.")
      )
    );
  } else {
    const isForActivenessChange = Object.keys(payload.data).includes(
      "is_active"
    );
    yield put(
      vendorManagementActions.updateStepSuccess({
        stepId: payload.stepId,
        data: response.data,
        isForActivenessChange: isForActivenessChange
      })
    );
  }
}

function* handleProcessControlStandardsRequest({
  payload
}: PayloadAction<string>) {
  const { response, error } = yield safeCall(
    vendorManagementService.fetchProcessControlStandards,
    payload
  );

  if (error) {
    yield put(
      vendorManagementActions.fetchProcessControlStandardsFailure(
        new Error("Something went wrong. Please try again.")
      )
    );
  } else {
    yield put(
      vendorManagementActions.fetchProcessControlStandardsSuccess(response.data)
    );
  }
}

function* handleFetchAllSpecificationsRequest({
  payload
}: PayloadAction<PaginationAndFilterForSpecification>) {
  const { response, error } = yield safeCall(
    vendorManagementService.fetchAllSpecifications,
    payload
  );

  if (error) {
    yield put(
      vendorManagementActions.fetchAllSpecificationsFailure(
        new Error("Something went wrong. Please try again.")
      )
    );
  } else {
    yield put(
      vendorManagementActions.fetchAllSpecificationsSuccess(response.data)
    );
  }
}

function* handleDeleteProcessControlStandardRequest({
  payload
}: PayloadAction<DeleteProcessControlStandard>) {
  const { response, error } = yield safeCall(
    vendorManagementService.deleteProcessControlStandard,
    payload
  );

  if (error) {
    yield put(
      vendorManagementActions.deleteProcessControlStandardFailure(
        new Error("Something went wrong. Please try again.")
      )
    );
  } else {
    yield put(
      vendorManagementActions.deleteProcessControlStandardSuccess(payload)
    );
  }
}

function* handleAddProcessControlStandardRequest({
  payload
}: PayloadAction<AddOrUpdateProcessControlStandard>) {
  const { response, error } = yield safeCall(
    vendorManagementService.addProcessControlStandard,
    payload
  );

  if (error) {
    yield put(
      vendorManagementActions.addProcessControlStandardFailure(
        new Error("Something went wrong. Please try again.")
      )
    );
  } else {
    yield put(
      vendorManagementActions.addProcessControlStandardSuccess(response.data)
    );
  }
}

function* handleUpdateProcessControlStandardRequest({
  payload
}: PayloadAction<AddOrUpdateProcessControlStandard>) {
  const { response, error } = yield safeCall(
    vendorManagementService.updateProcessControlStandard,
    payload
  );

  if (error) {
    yield put(
      vendorManagementActions.updateProcessControlStandardFailure(
        new Error("Something went wrong. Please try again.")
      )
    );
  } else {
    yield put(
      vendorManagementActions.updateProcessControlStandardSuccess(response.data)
    );
  }
}

function* handleUpdateApprovalFormFileRequest({
  payload
}: PayloadAction<UpdateApprovalFormFile>) {
  const { response, error } = yield safeCall(
    vendorManagementService.updateApprovalFormFile,
    payload
  );

  if (error) {
    yield put(
      vendorManagementActions.updateApprovalFormFileFailure(
        new Error("Something went wrong. Please try again.")
      )
    );
  } else {
    yield put(
      vendorManagementActions.updateApprovalFormFileSuccess(response.data)
    );
  }
}

function* handleDeleteApprovalFormFileRequest({
  payload
}: PayloadAction<string>) {
  const { response, error } = yield safeCall(
    vendorManagementService.deleteApprovalFormFile,
    payload
  );

  if (error) {
    yield put(
      vendorManagementActions.deleteApprovalFormFileFailure(
        new Error("Something went wrong. Please try again.")
      )
    );
  } else {
    yield put(vendorManagementActions.deleteApprovalFormFileSuccess(payload));
  }
}

function* handleFetchAclpClassificationsRequest() {
  const { response, error } = yield safeCall(
    vendorManagementService.fetchAclpClassifications
  );

  if (error) {
    yield put(
      vendorManagementActions.fetchAclpClassificationsFailure(
        new Error("Something went wrong. Please try again.")
      )
    );
  } else {
    yield put(
      vendorManagementActions.fetchAclpClassificationsSuccess(response.data)
    );
  }
}

function* handleFetchSupplierCategoriesRequest() {
  const { response, error } = yield safeCall(
    vendorManagementService.fetchSupplierCategories
  );

  if (error) {
    yield put(
      vendorManagementActions.fetchSupplierCategoriesFailure(
        new Error("Something went wrong. Please try again.")
      )
    );
  } else {
    yield put(
      vendorManagementActions.fetchSupplierCategoriesSuccess(response.data)
    );
  }
}

function* handleFetchSpecsOfScopeRequest({
  payload
}: PayloadAction<PaginationAndFilterForScopeSpecs>) {
  const { response, error } = yield safeCall(
    vendorManagementService.fetchSpecsOfScope,
    payload
  );

  if (error) {
    yield put(
      vendorManagementActions.fetchSpecsOfScopeFailure(
        new Error("Something went wrong. Please try again.")
      )
    );
  } else {
    yield put(
      vendorManagementActions.fetchSpecsOfScopeSuccess({
        isForFetchingToGetCountData: payload.isForFetchingToGetCountData,
        response: response.data
      })
    );
  }
}

function* handleRemoveSpecFromScopeRequest({
  payload
}: PayloadAction<RemoveSpecFromScopeRequest>) {
  const { response, error } = yield safeCall(
    vendorManagementService.removeSpecFromScope,
    payload
  );

  if (error) {
    yield put(
      vendorManagementActions.removeSpecFromScopeFailure(
        new Error("Something went wrong. Please try again.")
      )
    );
  } else {
    yield put(
      vendorManagementActions.removeSpecFromScopeSuccess(
        payload.specification_id
      )
    );
    if (payload.onSuccess) {
      payload.onSuccess();
    }
  }
}

function* handleSaveAdditionalCommentFieldOfFormRequest({
  payload
}: PayloadAction<SaveAdditionalComment>) {
  const { response, error } = yield safeCall(
    vendorManagementService.saveAdditionalCommentFieldOfForm,
    payload
  );

  if (error) {
    yield put(
      vendorManagementActions.saveAdditionalCommentFieldOfFormFailure(
        new Error("Something went wrong. Please try again.")
      )
    );
  }
}

function* handleAddSpecToScopeRequest({
  payload
}: PayloadAction<AddSpecToScopeRequest>) {
  const { response, error } = yield safeCall(
    vendorManagementService.addSpecToScope,
    payload
  );

  if (error) {
    yield put(
      vendorManagementActions.addSpecToScopeFailure(
        new Error("Something went wrong. Please try again.")
      )
    );
  } else {
    yield put(vendorManagementActions.addSpecToScopeSuccess(payload.row));
    if (payload.onSuccess) {
      payload.onSuccess();
    }
  }
}

function* handleSaveSpecOfScopeRequest({
  payload
}: PayloadAction<SaveSpecOfScopeRequest>) {
  const { response, error } = yield safeCall(
    vendorManagementService.saveSpecOfScope,
    payload
  );

  if (error) {
    yield put(
      vendorManagementActions.saveSpecOfScopeFailure(
        new Error("Something went wrong. Please try again.")
      )
    );
  } else {
    yield put(
      vendorManagementActions.saveSpecOfScopeSuccess({
        specificationId: payload.specificationId,
        spec: response.data
      })
    );
    if (payload.onSuccess) {
      payload.onSuccess();
    }
  }
}

function* handleUpdateLeadTimeImplFormDataRequest({
  payload
}: PayloadAction<UpdateLeadTimeImplFormData>) {
  const { response, error } = yield safeCall(
    vendorManagementService.updateLeadTimeImplFormData,
    payload
  );

  if (error) {
    yield put(
      vendorManagementActions.updateLeadTimeImplFormDataFailure(
        new Error("Something went wrong. Please try again.")
      )
    );
  } else {
    yield put(
      vendorManagementActions.updateLeadTimeImplFormDataSuccess(response.data)
    );
  }
}

function* handleUpdateSubtiersFormDataRequest({
  payload
}: PayloadAction<UpdateSubtierFormData>) {
  const { response, error } = yield safeCall(
    vendorManagementService.updateSubtiersFormData,
    payload
  );

  if (error) {
    yield put(
      vendorManagementActions.updateSubtierFormDataFailure(
        new Error("Something went wrong. Please try again.")
      )
    );
  } else {
    yield put(
      vendorManagementActions.updateSubtierFormDataSuccess(response.data)
    );
  }
}

function* handleFetchSubtierCompaniesRequest({
  payload
}: PayloadAction<string>) {
  const { response, error } = yield safeCall(
    vendorManagementService.fetchSubtierCompaniesRequest,
    payload
  );

  if (error) {
    yield put(
      vendorManagementActions.fetchSubtierCompaniesFailure(
        new Error("Something went wrong. Please try again.")
      )
    );
  } else {
    yield put(
      vendorManagementActions.fetchSubtierCompaniesSuccess(response.data)
    );
  }
}

function* handleFetchSpecsOfSubtierSupplierRequest({
  payload
}: PayloadAction<FetchSpecsOfSubtierSupplier>) {
  const { response, error } = yield safeCall(
    vendorManagementService.fetchSpecsOfSubtierSupplier,
    payload
  );

  if (error) {
    yield put(
      vendorManagementActions.fetchSpecsOfSubtierSupplierFailure(
        new Error("Something went wrong. Please try again.")
      )
    );
  } else {
    const specOfSubtierSupplier = {
      supplier_id: payload.supplierId,
      rows: response.data
    };
    yield put(
      vendorManagementActions.fetchSpecsOfSubtierSupplierSuccess(
        specOfSubtierSupplier
      )
    );
  }
}

function* handleUpdateSpecOfSubtierSupplierRequest({
  payload
}: PayloadAction<UpdateSpecOfSubtierSupplier>) {
  const { response, error } = yield safeCall(
    vendorManagementService.updateSpecOfSubtierSupplier,
    payload
  );

  if (error) {
    yield put(
      vendorManagementActions.updateSpecOfSubtierSupplierFailure(
        new Error("Something went wrong. Please try again.")
      )
    );
  } else {
    yield put(
      vendorManagementActions.updateSpecOfSubtierSupplierSuccess({
        spec: response.data,
        supplierId: payload.supplierId,
        specificationId: payload.specificationId
      })
    );
  }
}

function* handleDeleteSpecOfSubtierSupplierRequest({
  payload
}: PayloadAction<DeleteSpecOfSubtierSupplier>) {
  const { response, error } = yield safeCall(
    vendorManagementService.deleteSpecOfSubtierSupplier,
    payload
  );

  if (error) {
    yield put(
      vendorManagementActions.deleteSpecOfSubtierSupplierFailure(
        new Error("Something went wrong. Please try again.")
      )
    );
  } else {
    yield put(
      vendorManagementActions.deleteSpecOfSubtierSupplierSuccess({
        supplierId: payload.supplierId,
        specificationId: payload.specificationId
      })
    );
  }
}

function* handleAddSpecToSubtierSupplierRequest({
  payload
}: PayloadAction<AddSpecToSubtierSupplier>) {
  const { response, error } = yield safeCall(
    vendorManagementService.addSpecToSubtierSupplier,
    payload
  );

  if (error) {
    yield put(
      vendorManagementActions.addSpecToSubtierSupplierFailure(
        new Error("Something went wrong. Please try again.")
      )
    );
  } else {
    yield put(
      vendorManagementActions.addSpecToSubtierSupplierSuccess({
        supplierId: payload.supplierId,
        spec: response.data
      })
    );
  }
}

function* handleFetchSubtierOnboardingTableDataRequest({
  payload
}: PayloadAction<string>) {
  const { response, error } = yield safeCall(
    vendorManagementService.fetchSubtierOnboardingTableData,
    payload
  );

  if (error) {
    yield put(
      vendorManagementActions.fetchSubtierOnboardingTableDataFailure(
        new Error("Something went wrong. Please try again.")
      )
    );
  } else {
    yield put(
      vendorManagementActions.fetchSubtierOnboardingTableDataSuccess(
        response.data
      )
    );
  }
}

function* handleUpdateSubtiersOnboardingFormDataRequest({
  payload
}: PayloadAction<UpdateSubtiersOnboardingFormData>) {
  const { response, error } = yield safeCall(
    vendorManagementService.updateSubtiersOnboardingFormData,
    payload
  );

  if (error) {
    yield put(
      vendorManagementActions.updateSubtiersOnboardingFormDataFailure(
        new Error("Something went wrong. Please try again.")
      )
    );
  } else {
    yield put(
      vendorManagementActions.updateSubtiersOnboardingFormDataSuccess(
        response.data
      )
    );
  }
}

function* handleFetchUpdateSubtierOnboardingTableRowRequest({
  payload
}: PayloadAction<UpdateSubtierOnboardingTableRow>) {
  const { response, error } = yield safeCall(
    vendorManagementService.updateSubtierOnboardingTableRow,
    payload
  );

  if (error) {
    yield put(
      vendorManagementActions.updateSubtierOnboardingTableRowFailure(
        new Error("Something went wrong. Please try again.")
      )
    );
  } else {
    yield put(
      vendorManagementActions.updateSubtierOnboardingTableRowSuccess({
        supplierId: payload.supplierId,
        data: response.data
      })
    );
  }
}

function* handleFetchDesignAndBuiltDrawingsRequest({
  payload
}: PayloadAction<FetchDesignAndBuiltDrawings>) {
  const { response, error } = yield safeCall(
    vendorManagementService.fetchDesignAndBuiltDrawings,
    payload
  );

  if (error) {
    yield put(
      vendorManagementActions.fetchDesignAndBuiltDrawingsFailure(
        new Error("Something went wrong. Please try again.")
      )
    );
  } else {
    yield put(
      vendorManagementActions.fetchDesignAndBuiltDrawingsSuccess({
        response: response.data,
        isForFetchingToGetCountData: payload.isForFetchingToGetCountData
      })
    );
  }
}

function* handleUpdateDesignAndBuiltDrawingRequest({
  payload
}: PayloadAction<UpdateDesignAndBuildDrawing>) {
  const { response, error } = yield safeCall(
    vendorManagementService.updateDesignAndBuiltDrawing,
    payload
  );

  if (error) {
    yield put(
      vendorManagementActions.updateDesignAndBuiltDrawingFailure(
        new Error("Something went wrong. Please try again.")
      )
    );
  } else {
    yield put(
      vendorManagementActions.updateDesignAndBuiltDrawingSuccess({
        drawingId: payload.drawingId,
        partOfRow: response.data
      })
    );
    if (payload.onSuccess) {
      payload.onSuccess();
    }
  }
}

function* handleFetchSpecsOfLeadTimeForProductionRequest({
  payload
}: PayloadAction<FetchSpecsOfLeadTimeProductionInput>) {
  const { response, error } = yield safeCall(
    vendorManagementService.fetchSpecsOfLeadTimeForProduction,
    payload
  );

  if (error) {
    yield put(
      vendorManagementActions.fetchSpecsOfLeadTimeForProductionFailure(
        new Error("Something went wrong. Please try again.")
      )
    );
  } else {
    yield put(
      vendorManagementActions.fetchSpecsOfLeadTimeForProductionSuccess({
        response: response.data,
        isForFetchingToGetCountData: payload.isForFetchingToGetCountData
      })
    );
  }
}

function* handleUpdateSpecOfLeadTimeForProductionRequest({
  payload
}: PayloadAction<UpdateSpecOfLeadTimeForProduction>) {
  const { response, error } = yield safeCall(
    vendorManagementService.updateSpecOfLeadTimeForProduction,
    payload
  );

  if (error) {
    yield put(
      vendorManagementActions.updateSpecOfLeadTimeForProductionFailure(
        new Error("Something went wrong. Please try again.")
      )
    );
  } else {
    yield put(
      vendorManagementActions.updateSpecOfLeadTimeForProductionSuccess(
        response.data
      )
    );
    if (payload.onSuccess) {
      payload.onSuccess();
    }
  }
}

function* handleFetchSpecsOfBufferStockStrategyRequest({
  payload
}: PayloadAction<FetchSpecsOfBufferStockInput>) {
  const { response, error } = yield safeCall(
    vendorManagementService.fetchSpecsOfBufferStockStrategy,
    payload
  );

  if (error) {
    yield put(
      vendorManagementActions.fetchSpecsOfBufferStockStrategyFailure(
        new Error("Something went wrong. Please try again.")
      )
    );
  } else {
    yield put(
      vendorManagementActions.fetchSpecsOfBufferStockStrategySuccess({
        response: response.data,
        isForFetchingToGetCountData: payload.isForFetchingToGetCountData
      })
    );
  }
}

function* handleUpdateSpecOfBufferStockStrategyRequest({
  payload
}: PayloadAction<UpdateSpecOfBufferStockStrategy>) {
  const { response, error } = yield safeCall(
    vendorManagementService.updateSpecOfBufferStockStrategy,
    payload
  );

  if (error) {
    yield put(
      vendorManagementActions.updateSpecOfBufferStockStrategyFailure(
        new Error("Something went wrong. Please try again.")
      )
    );
  } else {
    yield put(
      vendorManagementActions.updateSpecOfBufferStockStrategySuccess(
        response.data
      )
    );
    if (payload.onSuccess) {
      payload.onSuccess();
    }
  }
}

function* handleFetchSuppliersBySearchTextRequest({
  payload
}: PayloadAction<string>) {
  const { response, error } = yield safeCall(
    vendorManagementService.fetchSuppliersBySearchText,
    payload
  );

  if (error) {
    yield put(
      vendorManagementActions.fetchSuppliersFailure(
        new Error("Something went wrong. Please try again.")
      )
    );
  } else {
    yield put(vendorManagementActions.fetchSuppliersSuccess(response.data));
  }
}

function* handleFetchSuppliersByCompanyIdRequest({
  payload
}: PayloadAction<string>) {
  const { response, error } = yield safeCall(
    vendorManagementService.fetchSuppliersByCompanyId,
    payload
  );

  if (error) {
    yield put(
      vendorManagementActions.fetchSupplierByCompanyIdFailure(
        new Error("Something went wrong. Please try again.")
      )
    );
  } else {
    yield put(
      vendorManagementActions.fetchSupplierByCompanyIdSuccess(response.data)
    );
  }
}

function* handleSendSupportMessageRequest({
  payload
}: PayloadAction<SendSupportMessageInput>) {
  const { response, error } = yield safeCall(
    vendorManagementService.sendSupportMessage,
    payload
  );

  if (error) {
    yield put(
      vendorManagementActions.sendSupportMessageFailure(
        new Error("Something went wrong, Please try again.")
      )
    );
    yield snackbarUtils.error("Something went wrong, Please try again.");
  } else {
    yield put(vendorManagementActions.sendSupportMessageSuccess());
    yield snackbarUtils.error("Support request successfully sent.");
    if (typeof payload.onSuccess === "function") {
      payload.onSuccess();
    }
  }
}

function* handleFetchIdentificationDataRequest({
  payload
}: PayloadAction<string>) {
  const { response, error } = yield safeCall(
    vendorManagementService.fetchIdentificationData,
    payload
  );

  if (error) {
    yield put(
      vendorManagementActions.fetchIdentificationDataFailure(
        new Error("Something went wrong. Please try again.")
      )
    );
  } else {
    yield put(
      vendorManagementActions.fetchIdentificationDataSuccess(response.data)
    );
  }
}

function* handleUpdateIdentificationDataRequest({
  payload
}: PayloadAction<UpdateIdentificationDataBody>) {
  const { response, error } = yield safeCall(
    vendorManagementService.updateIdentificationData,
    payload
  );

  if (error) {
    yield put(
      vendorManagementActions.updateIdentificationDataFailure(
        new Error("Something went wrong. Please try again.")
      )
    );
  } else {
    yield put(
      vendorManagementActions.updateIdentificationDataSuccess(response.data)
    );
    payload.onSuccess();
  }
}

export function* vendorManagementSaga() {
  yield takeLatest(
    vendorManagementActions.fetchFormDataRequest,
    handleFetchFormDataRequest
  );

  yield takeLatest(
    vendorManagementActions.updateFormDataRequest,
    handleUpdateFormDataRequest
  );

  yield takeLatest(
    vendorManagementActions.fetchStepsRequest,
    handleFetchStepsRequest
  );

  yield takeEvery(
    vendorManagementActions.updateStepRequest,
    handleUpdateStepRequest
  );

  yield takeLatest(
    vendorManagementActions.fetchProcessControlStandardsRequest,
    handleProcessControlStandardsRequest
  );

  yield takeLatest(
    vendorManagementActions.fetchAllSpecificationsRequest,
    handleFetchAllSpecificationsRequest
  );

  yield takeLatest(
    vendorManagementActions.deleteProcessControlStandardRequest,
    handleDeleteProcessControlStandardRequest
  );

  yield takeLatest(
    vendorManagementActions.addProcessControlStandardRequest,
    handleAddProcessControlStandardRequest
  );

  yield takeLatest(
    vendorManagementActions.updateProcessControlStandardRequest,
    handleUpdateProcessControlStandardRequest
  );

  yield takeEvery(
    vendorManagementActions.updateApprovalFormFileRequest,
    handleUpdateApprovalFormFileRequest
  );

  yield takeEvery(
    vendorManagementActions.deleteApprovalFormFileRequest,
    handleDeleteApprovalFormFileRequest
  );

  yield takeLatest(
    vendorManagementActions.fetchAclpClassificationsRequest,
    handleFetchAclpClassificationsRequest
  );

  yield takeLatest(
    vendorManagementActions.fetchSupplierCategoriesRequest,
    handleFetchSupplierCategoriesRequest
  );

  yield takeLatest(
    vendorManagementActions.fetchSpecsOfScopeRequest,
    handleFetchSpecsOfScopeRequest
  );

  yield takeEvery(
    vendorManagementActions.removeSpecFromScopeRequest,
    handleRemoveSpecFromScopeRequest
  );

  yield takeLatest(
    vendorManagementActions.saveAdditionalCommentFieldOfFormRequest,
    handleSaveAdditionalCommentFieldOfFormRequest
  );

  yield takeEvery(
    vendorManagementActions.addSpecToScopeRequest,
    handleAddSpecToScopeRequest
  );

  yield takeEvery(
    vendorManagementActions.saveSpecOfScopeRequest,
    handleSaveSpecOfScopeRequest
  );

  yield takeLatest(
    vendorManagementActions.updateLeadTimeImplFormDataRequest,
    handleUpdateLeadTimeImplFormDataRequest
  );

  yield takeLatest(
    vendorManagementActions.updateSubtierFormDataRequest,
    handleUpdateSubtiersFormDataRequest
  );

  yield takeLatest(
    vendorManagementActions.fetchSubtierCompaniesRequest,
    handleFetchSubtierCompaniesRequest
  );

  yield takeEvery(
    vendorManagementActions.fetchSpecsOfSubtierSupplierRequest,
    handleFetchSpecsOfSubtierSupplierRequest
  );

  yield takeEvery(
    vendorManagementActions.updateSpecOfSubtierSupplierRequest,
    handleUpdateSpecOfSubtierSupplierRequest
  );

  yield takeLatest(
    vendorManagementActions.deleteSpecOfSubtierSupplierRequest,
    handleDeleteSpecOfSubtierSupplierRequest
  );

  yield takeEvery(
    vendorManagementActions.addSpecToSubtierSupplierRequest,
    handleAddSpecToSubtierSupplierRequest
  );

  yield takeLatest(
    vendorManagementActions.fetchSubtierOnboardingTableDataRequest,
    handleFetchSubtierOnboardingTableDataRequest
  );

  yield takeLatest(
    vendorManagementActions.updateSubtiersOnboardingFormDataRequest,
    handleUpdateSubtiersOnboardingFormDataRequest
  );

  yield takeLatest(
    vendorManagementActions.updateSubtierOnboardingTableRowRequest,
    handleFetchUpdateSubtierOnboardingTableRowRequest
  );

  yield takeLatest(
    vendorManagementActions.fetchDesignAndBuiltDrawingsRequest,
    handleFetchDesignAndBuiltDrawingsRequest
  );

  yield takeLatest(
    vendorManagementActions.updateDesignAndBuiltDrawingRequest,
    handleUpdateDesignAndBuiltDrawingRequest
  );

  yield takeLatest(
    vendorManagementActions.fetchSpecsOfLeadTimeForProductionRequest,
    handleFetchSpecsOfLeadTimeForProductionRequest
  );

  yield takeEvery(
    vendorManagementActions.updateSpecOfLeadTimeForProductionRequest,
    handleUpdateSpecOfLeadTimeForProductionRequest
  );

  yield takeLatest(
    vendorManagementActions.fetchSpecsOfBufferStockStrategyRequest,
    handleFetchSpecsOfBufferStockStrategyRequest
  );

  yield takeEvery(
    vendorManagementActions.updateSpecOfBufferStockStrategyRequest,
    handleUpdateSpecOfBufferStockStrategyRequest
  );

  yield takeLatest(
    vendorManagementActions.fetchSuppliersRequest,
    handleFetchSuppliersBySearchTextRequest
  );
  yield takeLatest(
    vendorManagementActions.fetchSupplierByCompanyIdRequest,
    handleFetchSuppliersByCompanyIdRequest
  );
  yield takeLatest(
    vendorManagementActions.sendSupportMessageRequest,
    handleSendSupportMessageRequest
  );

  yield takeLatest(
    vendorManagementActions.fetchIdentificationDataRequest,
    handleFetchIdentificationDataRequest
  );

  yield takeLatest(
    vendorManagementActions.updateIdentificationDataRequest,
    handleUpdateIdentificationDataRequest
  );
}
