/**
 * This component is used to display features of selected quotations in the marketPlace.
 */
// General imports
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
// Hooks
import { useTranslation } from "react-i18next";
// Material UI
import { CircularProgress, Grid } from "@mui/material";

// Form handling and validation
import { Formik } from "formik";
import * as yup from "yup";
import { isEmpty } from "lodash";

// Use this component to calculate and display subtotal fees
import { DetailsSummaryHeader } from "components/rfqs-details-summary/details-summary-header/details-summary-header.component";
import PartExtraFieldsTable from "components/quotation/project-part-info-table.container";

import { QuotationExtraFieldsTable } from "@next/modules/marketplace/components/quotation/quotation-extra-fields-table";
import { useSavedExtraFieldsRowModels } from "@next/modules/marketplace/components/quotation/quotation-extra-fields-table.hooks";

// Helpers functions
import { getCharacteristicsOfPart } from "helpers/get-characteristics-of-part";
import { history } from "helpers/history";
import { frontendUrl } from "urls";

//Actions
import { marketplaceActions } from "services/marketplace";
import { quotationActions } from "services/quotation";

import { marketplaceActions as marketplaceActionsNext } from "@next/modules/marketplace/redux";
import { selectCustomQuotationImportedData } from "@next/modules/marketplace/redux/selectors";
import { getLeadTimeDelayUnit } from "@next/components/lead-time/lead-time.definitions";

// Use this component to display list of part
import { PartTile } from "./part-tile/part-tile.component";
import { SubmitQuotation } from "./submit-quotation/submit-quotation.component";
import { SubTotalFees } from "./subtotal-fees/subtotal-fees.component";
import { useQuotationViewData } from "./quotation-view-hooks";
import { useAxyaTracking } from "@next/hooks/useAxyaTracking";
import { workspaceNextActions } from "@next/modules/workspace/redux";

/**
 * QuotationView:
 * This functional component is used to display feature of active rfq and create quotation
 * @param {objects} props
 *
 * the data we have to submit look like this
 *
 */

export default function QuotationView(props) {
  const { token, activeQuote, isRfqDetailsLoading } = props;
  const dispatch = useDispatch();
  const location = useLocation();
  const { t } = useTranslation("workspace");
  const axyaTracking = useAxyaTracking();

  const disableLocalStorageValues =
    location?.pathname?.includes("revise/quotation");
  const {
    extraFieldsRowModels,
    saveExtraFieldsRowModels,
    clearSavedExtraFieldsRowModels,
  } = useSavedExtraFieldsRowModels(activeQuote?.pk, disableLocalStorageValues);

  const {
    quotationViewData,
    isPartsExtraFieldsAvailable,
    isQuotationExtraFieldsAvailable,
    isDetailsExtraFieldsAvailable,
  } = useQuotationViewData();

  const customQuotationImportedData = useSelector(
    selectCustomQuotationImportedData
  );

  const isEditQuotationMode = useSelector(
    (state) => state.quotation.isEditQuotationMode
  );

  const showPaymentManagementLabel =
    !isEditQuotationMode && activeQuote?.subject_to_payment_management;
  const paymentTermsRequired =
    !isEditQuotationMode && activeQuote?.net_ds?.length > 0;

  const [isSubmitting, setIsSubmitting] = useState(false);

  // uploads file state
  const [uploads, setUploads] = useState([]);

  // highlight selected extension file in the list of extension
  const [selectedIndex, setSelectedIndex] = useState(1);

  // use this state set parts total price
  const [partsTotalPrices, setPartsTotalPrices] = useState({});

  // use this state set for each part the sub total price
  const [subTotalPrice, setSubTotalPrice] = useState(0);

  // use this state set total price for each part
  const [totalPrice, setTotalPrice] = useState(0);

  // Fixed Fees state
  const [fees, setFees] = useState(0);

  // Updates the total price when the `fees` state changes
  useEffect(() => {
    updateTotal();
  }, [fees]);

  // A `change` event handler for fixed fees field to set `fees` state
  const onFeesFieldChange = (feesValue) => {
    setFees(feesValue);
  };
  /**
   * Updates the price of the selected quote
   * @param {*} partId
   * @param {*} qt
   * @param {*} price
   */
  const onPartPriceFieldChange = (partId, qt, price) => {
    partsTotalPrices[partId] = {
      qt,
      price,
    };

    setPartsTotalPrices(partsTotalPrices);
    updateTotal();
  };

  //Updates the total price of when added fixed fees
  const updateTotal = () => {
    const total = Object.values(partsTotalPrices).reduce((accumulator, obj) => {
      return obj.qt * obj.price + accumulator;
    }, 0.0);

    setSubTotalPrice(Number.parseFloat(total).toFixed(2));
    setTotalPrice(Number.parseFloat(total + fees).toFixed(2));
  };

  const onUploadSelectionChange = (uploadInfo) => {
    if (!uploadInfo) {
      return;
    }
    // Sets the currently active upload in the viewer as active
    setUploadItemActive(uploadInfo.pk);
  };

  // append file and update the state
  const appendFiles = (newUploads) => {
    setUploads((prevUploads) => [...prevUploads, ...newUploads]);

    // Triggers a preview of the last upload in the viewer
    onUploadSelectionChange(newUploads[newUploads.length - 1]);
  };

  // update the state of upload active item i
  const setUploadItemActive = (index) => {
    setSelectedIndex(index);
  };

  /**
   * Removes an upload from  the list
   * @param {*} uploadToRemove
   */
  const removeFile = (uploadToRemove) => {
    const upload = uploads.find(
      (fileUpload) => fileUpload.pk === uploadToRemove.pk
    );
    const index = uploads.indexOf(upload);
    // Remove the uploaded item
    uploads.splice(index, 1);

    if (upload && uploadToRemove?.pk) {
      dispatch(
        workspaceNextActions.deletePortalQuoteRequest({
          filePk: uploadToRemove.pk,
        })
      );
    }

    // Update the state to reflect the change in the list
    setUploads([...uploads]);
  };

  // submit the quotation
  const onSuccessfulFormSubmitClick = (values) => {
    const quote = {
      rfq: activeQuote.pk,
      delay: isQuotationExtraFieldsAvailable ? "0" : values.leadTime.delay,
      delay_unit: isQuotationExtraFieldsAvailable
        ? "d"
        : values.leadTime.delay_unit,
      additional_details: values.additionalDetails,
      files: uploads.map((info) => info.pk),
      fixed_charge: fees || 0,
      total_price: Number.parseFloat(totalPrice) || 0,
      parts: activeQuote.parts.map((part) => {
        let data = {
          name: part.name,
          pk: part.pk,
          price: 0,
        };
        if (!isEmpty(extraFieldsRowModels)) {
          data.extra_fields = extraFieldsRowModels.get(part.pk);
        }

        const importedPart = customQuotationImportedData.find(
          (customQuotationImportedPart) =>
            customQuotationImportedPart?.pk === part.pk
        );

        if (importedPart) {
          data = {
            ...data,
            extra_fields: {
              ...data?.extra_fields,
              ...importedPart.quotation_extra_fields,
            },
          };
        }

        const partPrice = partsTotalPrices[part.pk];

        if (partPrice) {
          data.price = partPrice.price;
        }

        return data;
      }),
      net_d: values.netDs?.id || null,
    };

    if (isEditQuotationMode) {
      quote["rfq"] = null;
      delete quote["rfq"];
      const callBack = () => {
        dispatch(quotationActions.setIsEditQuotationMode(false));
        history.push(frontendUrl.supplierQuotes);
      };
      dispatch(
        quotationActions.editQuotation(
          token,
          quote,
          activeQuote["pk"],
          t,
          callBack
        )
      );
    } else {
      dispatch(
        marketplaceActions.createQuotation(token, quote, t, () => {
          axyaTracking({
            scout_category: "usage_metric",
            scout_rfq_pk: activeQuote.pk,
            scout_action: "quote",
          });
        })
      );
    }
    setIsSubmitting(true);
    clearSavedExtraFieldsRowModels();
    dispatch(marketplaceActionsNext.clearCustomQuotationImportedData());
  };

  const validationSchema = yup.object({
    unitPrice: yup.number().min(0).required(t("thisFieldIsRequired")),
    fixedFees: yup.string().required(t("thisFieldIsRequired")),
    leadTime: yup.string().required(t("thisFieldIsRequired")),
    numberOfDays: yup.string(),
    netDs:
      (showPaymentManagementLabel || paymentTermsRequired) &&
      yup.string().required(t("thisFieldIsRequired")),
  });

  const [initialValues, setInitialValues] = useState({
    unitPrice: "",
    fixedFees: "",
    leadTime: "",
    numberOfDays: "",
    additionalDetails: "",
    netDs: "",
  });

  useEffect(() => {
    if (isEditQuotationMode) {
      if (activeQuote?.quotation_uploads_list) {
        setUploads((prevUploads) => [
          ...prevUploads,
          ...activeQuote?.quotation_uploads_list,
        ]);
      }

      // Get active quotation part details
      const activeQuotationPartDetails = getCharacteristicsOfPart(activeQuote);
      // Set quotation  values  to edit
      setInitialValues({
        ...initialValues,
        unitPrice: activeQuotationPartDetails.map((part) =>
          Number.parseFloat(part?.unitPrice)
        ),
        fixedFees: Number.parseFloat(activeQuote?.fixed_charge) || "",
        leadTime: {
          name: getLeadTimeDelayUnit(
            activeQuote?.delay,
            activeQuote?.delay_unit
          ),
          delay: activeQuote?.delay,
        },
        numberOfDays: activeQuote?.delay || "",

        additionalDetails: activeQuote?.additional_details,
        netDs: activeQuote?.net_d,
      });
    }
  }, [isEditQuotationMode]);

  return (
    <>
      <Formik
        initialValues={initialValues}
        onSubmit={onSuccessfulFormSubmitClick}
        validationSchema={
          !activeQuote.quotation_extra_fields ? validationSchema : null
        }
        enableReinitialize={isEditQuotationMode ? true : false}
      >
        {(props) => {
          const {
            values,
            errors,
            handleChange,
            handleSubmit,
            touched,
            setFieldValue,
          } = props;
          return (
            <div className="c-quotation-view">
              {isRfqDetailsLoading ? (
                <Grid
                  container
                  direction="column"
                  justifyContent="center"
                  alignItems="center"
                >
                  <Grid item>
                    <CircularProgress />
                  </Grid>
                </Grid>
              ) : (
                <form onSubmit={handleSubmit}>
                  <Grid container direction="row">
                    <Grid item xs={12}>
                      <>
                        {!isEditQuotationMode && (
                          <DetailsSummaryHeader
                            quotation={activeQuote}
                            startMessage={t("requestForQuotationsSubmittedOn")}
                            submissionDate={
                              activeQuote ? activeQuote.created_at : ""
                            }
                            endMessage={t(
                              "remainingBeforeTheEndOfRequestForQuotations"
                            )}
                            tag={activeQuote ? activeQuote.tag : ""}
                            rfqAdditionalDetails={
                              activeQuote ? activeQuote.additional_details : ""
                            }
                            rfqAdditionalDetailsRichText={
                              activeQuote
                                ? activeQuote.additional_details_rich_text
                                : ""
                            }
                            nda={activeQuote ? activeQuote.nda : ""}
                            supplier_signed_nda={
                              activeQuote ? activeQuote.supplier_signed_nda : ""
                            }
                            displayName={
                              activeQuote ? activeQuote.rfq_display_name : ""
                            }
                            t={t}
                            dateToDisplay={
                              activeQuote ? activeQuote.deadline : ""
                            }
                            subText={t("endDateRfq")}
                          />
                        )}
                      </>
                      {isPartsExtraFieldsAvailable && (
                        <>
                          <Grid
                            item
                            xs={12}
                            style={{ background: "white", marginTop: 16 }}
                          >
                            <PartExtraFieldsTable
                              parts={quotationViewData?.parts}
                              partExtraFields={
                                quotationViewData?.partExtraFields
                              }
                            />
                          </Grid>
                        </>
                      )}
                      {isQuotationExtraFieldsAvailable && (
                        <>
                          <Grid
                            item
                            xs={12}
                            style={{ background: "white", marginTop: 16 }}
                          >
                            <QuotationExtraFieldsTable
                              activeQuote={{
                                parts: quotationViewData?.parts,
                                quotation_extra_fields:
                                  quotationViewData?.quotationExtraFields,
                              }}
                              extraFieldsRowModels={extraFieldsRowModels}
                              saveExtraFieldsRowModels={
                                saveExtraFieldsRowModels
                              }
                              isEditable={
                                activeQuote?.itnb_reason ? false : true
                              }
                              partExtraFields={
                                quotationViewData?.partExtraFields
                              }
                            />
                          </Grid>
                        </>
                      )}

                      {!isDetailsExtraFieldsAvailable &&
                        quotationViewData?.parts.map((part, index) => {
                          return (
                            <PartTile
                              part={part}
                              quotation={activeQuote}
                              onPartPriceFieldChange={onPartPriceFieldChange}
                              t={t}
                              errors={errors}
                              values={values}
                              handleChange={handleChange}
                              setFieldValue={setFieldValue}
                              touched={touched}
                              nda={
                                (activeQuote.nda &&
                                  !activeQuote.supplier_signed_nda) ||
                                (activeQuote.supplier_signed_nda &&
                                  !activeQuote.supplier_signed_nda.is_approved)
                              }
                              isEditQuotationMode={isEditQuotationMode}
                              index={index}
                            />
                          );
                        })}
                      <SubTotalFees
                        totalPrice={totalPrice}
                        subTotalPrice={subTotalPrice}
                        onFeesFieldChange={onFeesFieldChange}
                        setUploads={appendFiles}
                        uploads={uploads}
                        onUploadSelectionChange={onUploadSelectionChange}
                        removeFile={removeFile}
                        selectedIndex={selectedIndex}
                        t={t}
                        errors={errors}
                        values={values}
                        handleChange={handleChange}
                        setFieldValue={setFieldValue}
                        touched={touched}
                        nda={
                          (activeQuote.nda &&
                            !activeQuote.supplier_signed_nda) ||
                          (activeQuote.supplier_signed_nda &&
                            !activeQuote.supplier_signed_nda.is_approved)
                        }
                        activeQuote={activeQuote}
                      />

                      <SubmitQuotation
                        totalPrice={totalPrice}
                        t={t}
                        errors={errors}
                        values={values}
                        touched={touched}
                        setFieldValue={setFieldValue}
                        isSubmitting={isSubmitting}
                        handleChange={handleChange}
                        nda={
                          (activeQuote.nda &&
                            !activeQuote.supplier_signed_nda) ||
                          (activeQuote.supplier_signed_nda &&
                            !activeQuote.supplier_signed_nda.is_approved)
                        }
                        isEditQuotationMode={isEditQuotationMode}
                        itnbReason={activeQuote?.itnb_reason}
                        showPaymentManagementLabel={showPaymentManagementLabel}
                        activeQuote={activeQuote}
                      />
                    </Grid>
                  </Grid>
                </form>
              )}
            </div>
          );
        }}
      </Formik>
    </>
  );
}
