import React from "react";
import { useTranslation } from "react-i18next";
import { Avatar, Box, Button, Grid, Grow, Typography } from "@mui/material";
import logo from "../../../../assets/img/logo-manssenger.jpeg";
import { InstigatorName } from "./instigator-name";

const QuotationDropMessage = ({
  id,
  message,
  quotationCreatedAt,
  activeQuotationThread,
}) => {
  const { t } = useTranslation();

  return (
    <Grow in>
      <Box
        id={id}
        flex="0 0 auto"
        my={1}
        display="flex"
        flexDirection="column"
        className="c-g4-communiaction-msg"
      >
        <Box flexGrow={1} display="flex" p={2}>
          {activeQuotationThread && (
            <Box>
              <Avatar style={{ width: 32, height: 32 }} src={logo}></Avatar>
            </Box>
          )}
          {activeQuotationThread && (
            <Grid
              className="c-chat-dialog--instigator-name"
              container
              direction="column"
            >
              <InstigatorName
                name={"Axya Communications"}
                time={quotationCreatedAt}
                companyUUID={activeQuotationThread?.instigator?.uuid}
              />
              <Typography
                variant="body2"
                className="c-chat-dialog--instigator-status"
              >
                Axya
              </Typography>
              <Typography
                variant="body2"
                className="c-chat-dialog--instigator-title"
              >
                {t("rating:rateSupplierMessage", {
                  supplier_company_name: activeQuotationThread.instigator.name,
                })}
              </Typography>
              <Button
                variant="contained"
                size="small"
                type="submit"
                color="primary"
                className="c-chat-dialog--instigator-rate-button"
              >
                <a target="_blank" href={message.content} rel="noreferrer">
                  {t("rating:rateYourExperience")}
                </a>
              </Button>
            </Grid>
          )}
        </Box>
      </Box>
    </Grow>
  );
};

export default QuotationDropMessage;
