import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import Modal from "@next/components/custom-modal";
import { getModal, modalsActions } from "@next/redux/modalsSlices";
import { OrdersModalTypes } from "./types";
import { AddOrderPartsTable } from "../components/add-order-parts/add-order-parts-table";
import { ADD_ORDER_PARTS_TABLE_STATE_KEY } from "../components/add-order-parts/add-order-parts-table.hooks";

export const AddOrderPartsModal = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const modal = useSelector(getModal(OrdersModalTypes.ADD_ORDER_PARTS));

  if (!modal) return null;

  const onClose = () => {
    localStorage.removeItem(ADD_ORDER_PARTS_TABLE_STATE_KEY);
    dispatch(modalsActions.closeModal(OrdersModalTypes.ADD_ORDER_PARTS));
  };

  return (
    <Modal
      handleModalClose={onClose}
      customWidth="800px"
      modalState={{
        modalHeader: t("orders:addOrderParts:title"),
        modalBody: <AddOrderPartsTable />,
      }}
      isModalOpen={true}
    />
  );
};
