import React, { useMemo } from "react";
import CustomBreadcrumbs from "../custom-breadcrumbs/custom-breadcrumbs";
import { Box } from "@mui/material";
import { frontendUrl } from "urls";
import { useTranslation } from "react-i18next";
import { useIsLocationPath } from "@next/hooks/useIsPath";
import { useProjectCodeFromLocation } from "@next/hooks/useProjectCodeFromLocation";
import DraftRFQsMenu from "@next/modules/workspace/components/draft-rfqs-menu/draft-rfqs-menu";
import { useSelector } from "react-redux";
import { getRfqDetails } from "services/workspace/workspace.selectors";
import {
  selectOrder,
  selectOrderLoading,
} from "@next/modules/orders/redux/selectors";
import { ProjectOptionsMenu } from "@next/modules/project/components/projects/project-options-menu/project-options-menu";
import { selectProjectStats } from "@next/modules/project/redux/selectors";

export const CustomBreadcrumbsBar = () => {
  const { t } = useTranslation();
  const isProjectsPath = useIsLocationPath(frontendUrl.projects);
  const isProjectsRootPath = useIsLocationPath(frontendUrl.projects, true);
  const isRFQDetailsPath = useIsLocationPath(frontendUrl.bundleView);
  const isOrdersPath = useIsLocationPath(frontendUrl.orders);
  const isOrdersRootPath = useIsLocationPath(frontendUrl.orders, true);
  const projectCode = useProjectCodeFromLocation();
  const activeProject = useSelector(selectProjectStats);
  const activeRFQDetails = useSelector(getRfqDetails);
  const activeOrderDetail = useSelector(selectOrder);
  const isSelectOrderLoading = useSelector(selectOrderLoading);

  const getBreadcrumbsData = useMemo(() => {
    if (isProjectsPath && !isProjectsRootPath) {
      return {
        pathNames: [
          t("mainNavigationSidebar:projects"),
          activeProject?.display_name || projectCode,
        ].filter(Boolean),
        linkRootTo: frontendUrl.projects,
      };
    }

    if (isRFQDetailsPath && activeRFQDetails) {
      return {
        pathNames: [
          t("mainNavigationSidebar:requests"),
          activeRFQDetails.rfq_display_name,
        ].filter(Boolean),
        linkRootTo: frontendUrl.buyerQuotes,
      };
    }

    if (isOrdersPath && !isOrdersRootPath && !isSelectOrderLoading) {
      return {
        pathNames: [
          t("mainNavigationSidebar:orders"),
          activeOrderDetail?.name || "",
        ].filter(Boolean),
        linkRootTo: frontendUrl.orders,
      };
    }

    return {};
  }, [
    isProjectsPath,
    isProjectsRootPath,
    isRFQDetailsPath,
    activeRFQDetails,
    isOrdersPath,
    isOrdersRootPath,
    isSelectOrderLoading,
    projectCode,
    activeProject,
    activeOrderDetail,
    t,
  ]);

  const { pathNames, linkRootTo } = getBreadcrumbsData;

  if (!pathNames || !linkRootTo) return null;

  return (
    <Box ml="24px" mt="24px" display="flex" alignItems="center">
      {pathNames && pathNames.length > 0 && (
        <CustomBreadcrumbs pathNames={pathNames} linkRootTo={linkRootTo} />
      )}
      {isProjectsPath && !isProjectsRootPath && projectCode && (
        <Box ml="12px">
          <DraftRFQsMenu projectCode={projectCode} />
          <ProjectOptionsMenu />
        </Box>
      )}
    </Box>
  );
};
