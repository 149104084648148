import { useFormikContext } from "formik";
import { useEffect } from "react";

export const useFormikScrollToError = (
  querySelector?: string,
  options?: ScrollIntoViewOptions
) => {
  const formik = useFormikContext();
  const submitting = formik?.isSubmitting;

  useEffect(() => {
    //FIXME: accessing elements through DOM is an antipattern in React
    const el = document.querySelector(querySelector || ".Mui-error");
    const elem = el?.parentElement ?? el;
    if (elem)
      elem.scrollIntoView({
        behavior: "smooth",
        block: "center",
        ...(options || {}),
      });
  }, [submitting]);
};
