import React, { Fragment } from "react";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import { FormControl, FormControlLabel, Radio, TextField } from "@mui/material";
import { FormFooter } from "../../form-footer/form-footer";
import { FormTitle } from "../../form-title/form-title";
import { FormFieldBox } from "../../form-field-box";
import { FormFieldLabel } from "../../form-field-label/form-field-label";
import { FormFieldErrorTypography } from "../../form-field-error-typography/form-field-error-typography";
import { FormBoxMd } from "../../form-box/form-box-md";
import { FormParentBox } from "../../form-parent-box";
import {
  FieldMandatoryText,
  OnboardingFormData,
  OnboardingRadioGroupKeysWithLabel,
  PartialStep,
  QuestionnaireForms,
  VendorManagementModalTypes,
  vendorManagementActions,
} from "@next/modules/vendor-management/redux";
import {
  selectFormData,
  selectIsAllStepsCompleted,
  selectIsFormCompleted,
  selectIsLastStep,
  selectIsThereUnsavedChangesInFormStep,
  selectVisibleSteps,
} from "@next/modules/vendor-management/redux/selectors";
import { modalsActions } from "@next/redux/modalsSlices";
import { showCompletedMessage } from "@next/modules/vendor-management/utils/showCompletedMessage";
import { showInvalidMessage } from "@next/modules/vendor-management/utils/showInvalidMessage";
import { getActiveStepIndex } from "@next/modules/vendor-management/utils/getActiveStepIndex";
import { showSavedMessage } from "@next/modules/vendor-management/utils/showSavedMessage";
import * as S from "./onboarding-form.styled";

export const OnboardingForm: React.FC = () => {
  const { companyId } = useParams<{ companyId: string }>();
  const dispatch = useDispatch();
  const isFormCompleted = useSelector(selectIsFormCompleted);
  const steps = useSelector(selectVisibleSteps);
  const activeFormStepIndex = getActiveStepIndex(steps);
  const isLastStep = useSelector(selectIsLastStep);
  const isAllStepsCompleted = useSelector(selectIsAllStepsCompleted);
  const isThereUnsavedChanges = useSelector(
    selectIsThereUnsavedChangesInFormStep
  );
  const onboardingFormDataState = useSelector(
    selectFormData
  ) as OnboardingFormData;
  const {
    handleSubmit,
    formState: { errors },
    control,
    watch,
    setError,
  } = useForm<OnboardingFormData>({
    defaultValues: onboardingFormDataState,
  });

  const saveChanges = (isForCompletion: boolean) => {
    const formValues = watch();
    dispatch(
      vendorManagementActions.updateFormDataRequest({
        data: formValues,
        formName: QuestionnaireForms.Onboarding,
        companyId: companyId,
      })
    );
    const fieldOfStep = isForCompletion
      ? "is_completed"
      : "is_partially_filled";
    updateFieldStatusOfStepForSaveAndCompletion(
      fieldOfStep,
      true,
      activeFormStepIndex
    );
    if (!isForCompletion) {
      showSavedMessage();
    }
    dispatch(vendorManagementActions.setIsThereUnsavedChangesInFormStep(false));
  };

  const confirmStepCompletion: SubmitHandler<OnboardingFormData> = (data) => {
    const isValidated = validateRadioGroupsAndSetError();
    if (isValidated) {
      makeFormStepCompletionProcess();
    } else {
      showInvalidMessage();
    }
  };

  const makeFormStepCompletionProcess = () => {
    saveChanges(true);
    showCompletedMessage();
    if (isAllStepsCompleted) {
      showThankYouModal();
    } else if (!isLastStep) {
      routeNextStep();
    }
  };

  const routeNextStep = () => {
    const newActiveStepIndex = activeFormStepIndex + 1;
    setActiveFormStepIndex(newActiveStepIndex);
  };

  const setActiveFormStepIndex = (index: number) => {
    updateFieldStatusOfStepForSaveAndCompletion("is_active", true, index);
  };

  const showThankYouModal = () => {
    dispatch(
      modalsActions.showModal({
        key: VendorManagementModalTypes.THANK_YOU_MODAL,
      })
    );
  };

  const validateRadioGroupsAndSetError = () => {
    let isValid = true;
    const formValues = watch();
    const formData = formValues;
    const keys = Object.keys(OnboardingRadioGroupKeysWithLabel);
    keys.forEach((key) => {
      if (formData[key] === null || formData[key] === undefined) {
        setError(key as keyof OnboardingFormData, { type: "manual" });
        isValid = false;
      }
    });
    return isValid;
  };

  const onInvalid = () => {
    showInvalidMessage();
  };

  const checkInputChange = () => {
    if (isFormCompleted) {
      updateFieldStatusOfStepForSaveAndCompletion(
        "is_completed",
        false,
        activeFormStepIndex
      );
      dispatch(
        vendorManagementActions.setIsThereUnsavedChangesInFormStep(true)
      );
    } else if (!isThereUnsavedChanges) {
      dispatch(
        vendorManagementActions.setIsThereUnsavedChangesInFormStep(true)
      );
    }
  };

  const updateFieldStatusOfStepForSaveAndCompletion = (
    key: "is_completed" | "is_partially_filled" | "is_active",
    value: boolean,
    stepIndex: number
  ) => {
    const step = steps[stepIndex];
    const partialStep: PartialStep = {
      [key]: value,
    };
    dispatch(
      vendorManagementActions.updateStepRequest({
        stepId: step.id,
        data: partialStep,
      })
    );
  };

  const renderRadioGroupField = (
    formFieldLabelComponent: React.ReactNode,
    key: keyof OnboardingFormData
  ) => {
    return (
      <FormControl fullWidth margin="none" error={errors[key] ? true : false}>
        <FormFieldBox>
          {formFieldLabelComponent}

          <Controller
            name={key}
            control={control}
            render={({ field }) => (
              <>
                <S.StyledRadioGroup
                  {...field}
                  value={field?.value === null ? "" : field.value}
                  onChange={(e) => {
                    const value = e.target.value === "true" ? true : false;
                    field.onChange(value);
                    checkInputChange();
                  }}
                >
                  <FormControlLabel
                    value="true"
                    control={<Radio />}
                    label="Yes"
                  />
                  <FormControlLabel
                    value="false"
                    control={<Radio />}
                    label="No"
                  />
                </S.StyledRadioGroup>
                {errors[key] ? (
                  <FormFieldErrorTypography text={FieldMandatoryText} />
                ) : null}
              </>
            )}
          />
        </FormFieldBox>
      </FormControl>
    );
  };

  return (
    <>
      <FormParentBox>
        <FormBoxMd isCompleted={isFormCompleted}>
          <FormTitle
            title={"Supplier access onboarding"}
            isCompleted={isFormCompleted}
          />

          {Object.entries(OnboardingRadioGroupKeysWithLabel).map(
            ([key, label]) => (
              <Fragment key={key}>
                {renderRadioGroupField(
                  <FormFieldLabel label={label} />,
                  key as keyof OnboardingFormData
                )}

                {key === "normaster" ? (
                  <FormTitle title={"IT Autonomy"} />
                ) : null}
              </Fragment>
            )
          )}

          <FormControl margin="none">
            <Controller
              name="additional_comment"
              control={control}
              defaultValue={onboardingFormDataState?.additional_comment || ""}
              render={({ field: { onChange, value } }) => (
                <TextField
                  sx={{ width: "100%" }}
                  multiline
                  rows={3}
                  placeholder="Additional comment..."
                  value={value || ""}
                  onChange={(e) => {
                    onChange(e);
                    checkInputChange();
                  }}
                />
              )}
            />
          </FormControl>
        </FormBoxMd>
      </FormParentBox>
      <FormFooter
        saveChanges={() => saveChanges(false)}
        confirmStepCompletion={handleSubmit(confirmStepCompletion, onInvalid)}
        isCompleted={isFormCompleted}
      />
    </>
  );
};
