import React from "react";
import { Box, Theme, Typography } from "@mui/material";
import {
  selectAllLineItemsChangesHaveDecision,
  selectLineItemsChangesFromRevisionProposal,
  selectOrderActionPanelType,
} from "@next/modules/orders/redux/selectors";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { createStyles, makeStyles } from "@mui/styles";
import CustomButton from "@next/components/custom-button";
import { ActionPanelType } from "@next/modules/orders/redux";
import { modalsActions } from "@next/redux/modalsSlices";
import { OrdersModalTypes } from "@next/modules/orders/modals/types";
import CheckIcon from "@mui/icons-material/Check";
import { FinalizeProposalReviewModal } from "@next/modules/orders/modals/finalize-proposal-review-modal";

const useStyles = makeStyles<Theme>((theme: Theme) =>
  createStyles({
    footer: {
      width: "100%",
      bottom: 0,
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      gap: theme.spacing(2),
      padding: theme.spacing(3),
      borderTop: `1px solid ${theme.palette.divider}`,
    },
  })
);
const ActionsPanelFooter: React.FC = () => {
  const { t } = useTranslation();
  const classes = useStyles();
  const dispatch = useDispatch();
  const panelType = useSelector(selectOrderActionPanelType);
  const allllLineItemsChangesHaveDecision = useSelector(
    selectAllLineItemsChangesHaveDecision
  );
  const lineItemsChanges = useSelector(
    selectLineItemsChangesFromRevisionProposal
  );

  const onClickFinalizeReview = () => {
    dispatch(
      modalsActions.showModal({
        key: OrdersModalTypes.FINALIZE_PROPOSAL_REVIEW_MODAL,
        data: { lineItemsChanges: lineItemsChanges, asModal: true },
      })
    );
  };

  return (
    <>
      {panelType === ActionPanelType.Review ? (
        <Box className={classes.footer}>
          <Typography textAlign={"center"}>
            {t(
              "orders:orderDetail:actionPanel:footer:finalizeReviewDescription"
            )}
          </Typography>
          <CustomButton
            size="large"
            variant="contained"
            color="primary"
            fullWidth
            sx={{ height: "56px" }}
            startIcon={<CheckIcon />}
            onClick={onClickFinalizeReview}
            disabled={!allllLineItemsChangesHaveDecision}
          >
            {t("orders:orderDetail:actionPanel:footer:finalizeReview")}
          </CustomButton>
        </Box>
      ) : null}
      <FinalizeProposalReviewModal />
    </>
  );
};

export default ActionsPanelFooter;
