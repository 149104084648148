import {
  Box,
  Card,
  CardActionArea,
  CardContent,
  Chip,
  SvgIconProps,
  Theme,
  Tooltip,
  Typography,
} from "@mui/material";
import React from "react";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import { AppTheme } from "layout/AppTheme";
import CurrencyField from "components/common/fields/CurrencyField";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { createStyles, makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    content: {
      width: "361px",
      height: "108px",
      justifyContent: "space-between",
      display: "flex",
      flexDirection: "column",
      padding: theme.spacing(3),
    },
    arrowIcon: {
      color: theme.palette.text.secondary,
      width: "16px",
      height: "16px",
    },
    titleContainer: {
      display: "flex",
      justifyContent: "space-between",
    },
  })
);

type Props = {
  count: number;
  title: string;
  Icon?: React.ComponentType<SvgIconProps>;
  compactMode?: boolean;
  isCurrency?: boolean;
  iconColor?: string;
  tooltip?: string;
  chipCount?: number;
};

export const DashboardCard: React.FC<Props> = ({
  count = 0,
  title,
  Icon,
  compactMode,
  isCurrency,
  iconColor,
  tooltip,
  chipCount,
}) => {
  const classes = useStyles();

  return (
    <Card variant="outlined">
      <CardActionArea>
        <CardContent className={classes.content}>
          {compactMode ? (
            <Box
              display="flex"
              flexDirection="column"
              alignItems="center"
              justifyContent="center"
              height="100%"
            >
              <Typography variant="h5">
                {isCurrency ? (
                  <CurrencyField value={count} toFixedValue={0} />
                ) : (
                  count
                )}
              </Typography>

              <Box mt="16px" display="flex" alignItems="center">
                <Typography variant="body2">{title}</Typography>

                <Box ml="6px">
                  {tooltip ? (
                    <Tooltip title={tooltip}>
                      <InfoOutlinedIcon style={{ height: "18px" }} />
                    </Tooltip>
                  ) : null}
                </Box>
              </Box>
            </Box>
          ) : (
            <>
              <Box
                display="flex"
                justifyContent="space-between"
                alignItems="center"
              >
                <Box display="flex" alignItems="center">
                  {Icon && (
                    <Icon
                      style={{
                        color: iconColor
                          ? iconColor
                          : AppTheme.palette.primary.main,
                        width: "18px",
                      }}
                    />
                  )}

                  <Box ml="8px">
                    <Typography variant="body2">{title}</Typography>
                  </Box>
                </Box>

                <Box className={classes.titleContainer}>
                  <ArrowForwardIcon
                    color="action"
                    className={classes.arrowIcon}
                  />
                </Box>
              </Box>

              <Box
                mt="8px"
                display="flex"
                justifyContent="space-between"
                alignItems="center"
              >
                <Typography variant="h5">{count}</Typography>
                {chipCount && (
                  <Chip label={chipCount} color="primary" size="small" />
                )}
              </Box>
            </>
          )}
        </CardContent>
      </CardActionArea>
    </Card>
  );
};
