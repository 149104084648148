import React from "react";
import { Box, Divider, Theme, Typography } from "@mui/material";
import { formatDate } from "helpers/formatDate";
import i18n from "assets/configi18n/i18n";
import { EN_DATE_FORMAT, FR_DATE_FORMAT } from "@next/constants";
import { createStyles, makeStyles } from "@mui/styles";
import { LineItemUpdateTypeTag } from "../order-state-tag";
import { ReviewChangeButton } from "./review-changes-button";
import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";
import { LineItemType, OrderLineItemChanges } from "../../redux";
import rightArrow from "assets/img/arrow-cool-down.svg";

type StyleProps = {
  padding?: string;
};

const useStyles = makeStyles<Theme, StyleProps>((theme: Theme) =>
  createStyles({
    rootContainer: {
      display: "flex",
      flexDirection: "column",
      padding: ({ padding }) => padding || "24px",
      gap: "16px",
    },
    contentBox: {
      display: "flex",
      gap: "8px",
      alignItems: "center",
      padding: "8px",
    },
  })
);

export const PreviewLineItemChanges: React.FC<{
  lineItemsChanges?: OrderLineItemChanges[];
  onMakeDecision?: (
    id: number,
    type: LineItemType,
    decision: "approved" | "rejected" | null
  ) => void;
  lockDecision?: boolean;
  padding?: string;
}> = ({ lineItemsChanges, onMakeDecision, lockDecision, padding }) => {
  const classes = useStyles({ padding });
  const customFormat = i18n.language === "en" ? EN_DATE_FORMAT : FR_DATE_FORMAT;

  if (!lineItemsChanges) return null;

  return (
    <Box className={classes.rootContainer}>
      {lineItemsChanges.map((item) => (
        <Box key={item.id}>
          <Typography variant="caption" className={classes.contentBox}>
            {item?.extra_fields?.line_id && (
              <Typography variant="caption" fontWeight={700}>
                #{item?.extra_fields?.line_id}&nbsp;
              </Typography>
            )}
            {item.name}
          </Typography>
          {Object.keys(item?.previous_line_item).map((key) => {
            return (
              <>
                <Box
                  key={key}
                  display={"flex"}
                  alignItems={"center"}
                  justifyContent={"space-between"}
                >
                  <Typography variant="caption" className={classes.contentBox}>
                    <LineItemUpdateTypeTag type={key as LineItemType} />
                    <s>
                      {key === LineItemType.UPDATED_DELIVERY_DATE
                        ? formatDate(
                            item?.previous_line_item?.[key],
                            customFormat
                          )
                        : item?.previous_line_item?.[key]}
                    </s>
                    <img
                      src={rightArrow}
                      style={{ width: "16px", height: "16px" }}
                    />
                    {key === LineItemType.UPDATED_DELIVERY_DATE
                      ? formatDate(item?.[key], customFormat)
                      : item?.[key]}
                    {lockDecision && item?.decision?.[key] !== undefined && (
                      <Typography variant="caption">
                        {item?.decision?.[key] === "approved" ? (
                          <ReviewChangeButton
                            icon={<CheckIcon />}
                            status="approved"
                            readOnly
                          />
                        ) : item?.decision?.[key] === "rejected" ? (
                          <ReviewChangeButton
                            icon={<CloseIcon />}
                            status="rejected"
                            readOnly
                          />
                        ) : null}
                      </Typography>
                    )}
                  </Typography>
                  {!lockDecision && item?.decision?.[key] !== undefined && (
                    <Box display={"flex"} gap={"8px"}>
                      <ReviewChangeButton
                        icon={<CheckIcon />}
                        buttonType="approve"
                        status={
                          item?.decision?.[key] === "approved"
                            ? "approved"
                            : null
                        }
                        onClick={(e, revert) =>
                          onMakeDecision?.(
                            item.id,
                            key as LineItemType,
                            revert ? null : "approved"
                          )
                        }
                      />
                      <ReviewChangeButton
                        icon={<CloseIcon />}
                        buttonType="reject"
                        status={
                          item?.decision?.[key] === "rejected"
                            ? "rejected"
                            : null
                        }
                        onClick={(e, revert) =>
                          onMakeDecision?.(
                            item.id,
                            key as LineItemType,
                            revert ? null : "rejected"
                          )
                        }
                      />
                    </Box>
                  )}
                </Box>
              </>
            );
          })}
          {onMakeDecision && !lockDecision && (
            <Box mt={"24px"}>
              <Divider />
            </Box>
          )}
        </Box>
      ))}
    </Box>
  );
};
