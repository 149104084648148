import * as yup from "yup";
import { t } from "assets/configi18n/i18n";

export const validationSchema = yup.object().shape({
  first_name: yup
    .string()
    .required(t("userCompany:theNameOfTheCompanyIsRequired")),
  last_name: yup
    .string()
    .required(t("userCompany:theNameOfTheCompanyIsRequired")),
  picture: yup.string(),
});
