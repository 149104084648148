import React from "react";

import { useSelector } from "react-redux";
import { useTracking } from "react-tracking";

import { useTranslation } from "react-i18next";
import { Avatar, Box, Grow, Link, Typography } from "@mui/material";
import GetAppIcon from "@mui/icons-material/GetApp";
import { formatDate } from "helpers/formatDate";
import { downloadFile } from "../../../../helpers/download";
import moment from "moment";

const Message = ({ id, message, user, isHideAuthorInfo }) => {
  const { t } = useTranslation();
  const tracking = useTracking();
  const profile = useSelector((state) => state.profile);
  // if the message created today or yesterday show corresponding text
  // if not show date
  const getCreatedDate = (date) => {
    const today = formatDate(new Date());
    const yesterday = formatDate(moment().subtract(1, "days"));
    const createdDate = formatDate(date);
    if (createdDate === today) {
      return t("inbox:today");
    } else if (createdDate === yesterday) {
      return t("inbox:yesterday");
    } else {
      return createdDate;
    }
  };

  const handleDownloadClick = (e, fileUrl) => {
    downloadFile(e, fileUrl);
    tracking.trackEvent({
      scout_category: "manufacturer_messenger",
      scout_feature_name: "download_message_file",
      scout_company: profile.company["name"],
      scout_username: profile.user["name"],
      scout_user_type: profile.user["type"],
      scout_date: new Date(),
    });
  };

  return (
    <>
      <Grow in>
        <>
          {!isHideAuthorInfo && (
            <Box
              id={id}
              flex="0 0 auto"
              my={1}
              display="flex"
              flexDirection="column"
              className="c-user-info"
            >
              <Box
                display="flex"
                flexDirection="row"
                className="c-user-info--author-info"
              >
                <Avatar
                  height={48}
                  width={48}
                  src={
                    message.author.email === user.email
                      ? user.picture
                      : message.author.picture
                  }
                />
                <Box pl={1} width="100%">
                  <>
                    {message.author.email === user.email
                      ? `${user.first_name} ${user.last_name}`
                      : `${message.author.first_name} ${message.author.last_name} `}
                    {" · "}
                    <span className="c-user-info--created-time">
                      {`${getCreatedDate(message.created_at)} ${t(
                        "inbox:at"
                      )} ${moment(message.created_at).format("hh:mm a")}`}
                    </span>
                    <span className="c-user-info--company-name">
                      {message.author.email.indexOf("axya.co") >= 0
                        ? t("inbox:grad4CC")
                        : message.company.name}
                    </span>
                  </>
                </Box>
              </Box>
            </Box>
          )}
          <Box minWidth={0} className="c-message">
            <Typography
              variant="body1"
              className="c-message--typography c-message-content"
            >
              {message.content}
              {message.attachments &&
                message.attachments.map((file) => {
                  return (
                    <Link
                      download
                      onClick={(e) => handleDownloadClick(e, file.url)}
                      className="c-message--file-download-link"
                    >
                      <Box
                        className="c-message--attachments"
                        display="flex"
                        alignItems="center"
                        justifyContent="space-between"
                      >
                        <span>
                          {file.name}
                          {file.extension}
                        </span>

                        <GetAppIcon />
                      </Box>
                    </Link>
                  );
                })}
            </Typography>
          </Box>
        </>
      </Grow>
    </>
  );
};

export default Message;
