import { Grid, Theme } from "@mui/material";
import React from "react";
import PublicRfqLandingDescription from "./public-rfq-landing-description";
import { PublicRfqLandingSteps } from "./public-rfq-landing-steps/public-rfq-landing-steps";
import { createStyles, makeStyles } from "@mui/styles";

const useStyles = makeStyles<Theme>((theme: Theme) =>
  createStyles({
    root: { height: "100%" },
    left: {
      padding: theme.spacing(6),
      borderRight: `solid 1px ${theme.palette.divider}`,
    },
    right: {
      display: "flex",
      padding: "56px",
      paddingLeft: "64px",
      paddingRight: "64px",
      [theme.breakpoints.down("md")]: {
        paddingTop: theme.spacing(5),
      },
      backgroundColor: "#FAFAFA",
    },
  })
);

const PublicRfqLanding = () => {
  const classes = useStyles();

  return (
    <Grid container className={classes.root}>
      <Grid item xs={12} md={5} className={classes.left}>
        <PublicRfqLandingDescription />
      </Grid>

      <Grid
        item
        xs={12}
        md={7}
        justifyContent="center"
        className={classes.right}
      >
        <PublicRfqLandingSteps />
      </Grid>
    </Grid>
  );
};

export default PublicRfqLanding;
