import { FileData } from "@next/components/attach-file/attach-file-list.types";
import { saveAs } from "file-saver";
import JSZip from "jszip";

type QuotationData = {
  company_name: string;
  quotation_uploads_list: FileData[];
};

export const useOnClickDownloadAllQuotations = (
  quotations: QuotationData[]
) => {
  return () => {
    const zip = new JSZip();

    // Helper function to download a single file
    const downloadFile = (fileInfo: FileData) => {
      if (!fileInfo.file_url) {
        return Promise.reject(new Error("File URL is not defined"));
      }

      return fetch(fileInfo.file_url)
        .then((resp) => resp.blob())
        .then((blob) => {
          return {
            filename: fileInfo.file_name + "." + fileInfo.file_extension,
            blob
          };
        });
    };

    const quotationsPromises = quotations.map((quotation) => {
      return Promise.all(
        quotation.quotation_uploads_list.map(downloadFile)
      ).then((fileBlobs) => {
        fileBlobs.forEach((fileBlob) => {
          zip
            .folder(quotation.company_name)
            .file(fileBlob.filename, fileBlob.blob);
        });
      });
    });

    Promise.all(quotationsPromises).then(() => {
      zip.generateAsync({ type: "blob" }).then((content: any) => {
        saveAs(content, "quotations.zip");
      });
    });
  };
};
