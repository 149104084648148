import { SellerDashboardBuyerProfile } from "@next/modules/dashboard/components/seller-dashboard-buyer-profile/seller-dashboard-buyer-profile";
import { selectBuyerProfileLoading } from "@next/modules/dashboard/redux/selectors";
import React from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { createStyles, makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      backgroundColor: theme.palette.common.white,
      border: `1px solid ${theme.palette.divider}`,
      borderRadius: theme.spacing(2),
    },
  })
);

const BuyerProfileView: React.FC = () => {
  const classes = useStyles();
  const { id } = useParams<{ id: string }>();
  const loading = useSelector(selectBuyerProfileLoading);

  if (!id) return null;

  return (
    <div
      className={classes.root}
      style={{ border: loading ? "none" : undefined }}
    >
      <SellerDashboardBuyerProfile buyerProfileId={+id} />
    </div>
  );
};

export default BuyerProfileView;
