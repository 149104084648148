import { Box, Divider, Typography } from "@mui/material";
import CustomButton from "@next/components/custom-button";
import React from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useStyles } from "./rfq-creation-form-lite.styles";
import {
  selectCreateEasyRfqLoading,
  selectUploadRfqAttachmentsLoading,
} from "../../redux/selectors";

interface Props {
  onSubmit?: () => void;
}

const RFQCreationFormLiteFooter: React.FC<Props> = (props) => {
  const { onSubmit } = props;
  const { t } = useTranslation();
  const classes = useStyles();
  const createEasyRfqLoading = useSelector(selectCreateEasyRfqLoading);
  const uploadRfqAttachmentsLoading = useSelector(
    selectUploadRfqAttachmentsLoading
  );

  return (
    <div className={classes.footer}>
      <Divider light />

      <Box m={2} display="flex" justifyContent="flex-end" alignItems="center">
        <CustomButton
          loading={createEasyRfqLoading}
          onClick={onSubmit}
          type="submit"
          variant="contained"
          color="primary"
          className={classes.rfqAttachmentBtn}
          disabled={uploadRfqAttachmentsLoading}
        >
          <Typography variant="body2">
            {t("workspaceNext:rfqDrawer:send")}
          </Typography>
        </CustomButton>
      </Box>
    </div>
  );
};

export default RFQCreationFormLiteFooter;
