// Constants for Redux
import { userOnboardingApi, userOnboardingConstants } from "./index";

// Helper functions
import { history } from "../../helpers/history";
// Urls for redirection
import { frontendUrl } from "../../urls";
import { genericErrorHandler } from "@next/utils/apiUtils";
import snackbarUtils from "@next/utils/snackbarUtils";

export const userOnboardingActions = {
  setActiveStep,
  setUserInformation,
  setCompanyType,
  setCompanyInformation,
  setCompanyReferences,
  setUploadedFiles,
  setRemoveUploadedFiles,
  registerUser,
  setIsUploadingDone,
  resetToInitialState,
  setIsChooseCompanyType
};

/**
 *  Action to dispatch active step for stepper
 * @param {number} activeStep
 * @returns number
 */
function setActiveStep(activeStep) {
  return {
    type: userOnboardingConstants.SET_ACTIVE_STEP,
    activeStep
  };
}

/**
 * Action to dispatch company Information
 * @param {object} userInfos
 * @returns object new user information
 */
function setUserInformation(userInfos) {
  return {
    type: userOnboardingConstants.SET_USER_INFORMATION,
    userInfos
  };
}

/**
 * Action to dispatch type of company
 * @param {string} companyType
 * @returns string new type of company
 */
function setCompanyType(companyType) {
  return { type: userOnboardingConstants.SET_COMPANY_TYPE, companyType };
}

/**
 * Action to dispatch company information
 * @param {object} companyInfos
 * @returns new object
 */
function setCompanyInformation(companyInfos) {
  return {
    type: userOnboardingConstants.SET_COMPANY_INFORMATION,
    companyInfos
  };
}

/**
 * Action to dispatch company references
 * @param {object} references company references
 * @returns
 */
function setCompanyReferences(references) {
  return {
    type: userOnboardingConstants.SET_COMPANY_REFERENCES,
    references
  };
}

/**
 *  Action to dispatch   list of uploaded file
 * @param {array} uploadedFiles arrays of files
 * @returns  arrays of files
 */
function setUploadedFiles(uploadedFiles) {
  return {
    type: userOnboardingConstants.SET_UPLOADED_FILES_SUCCESS,
    uploadedFiles
  };
}

/**
 * Action to remove each file in the list
 * @param {file} fileToRemove
 * @returns new arrays of files
 */
function setRemoveUploadedFiles(fileToRemove) {
  return {
    type: userOnboardingConstants.SET_REMOVE_UPLOADED_FILES_SUCCESS,
    fileToRemove
  };
}

/**
 * Action to control upload progress
 * @param {*} isUploadingDone
 * @returns
 */
function setIsUploadingDone(isUploadingDone) {
  return { type: userOnboardingConstants.IS_UPLOAD_DONE, isUploadingDone };
}
/**
 * Action to choose company type
 * @param {*} isChooseCompanyType
 * @returns
 */
function setIsChooseCompanyType(isChooseCompanyType) {
  return {
    type: userOnboardingConstants.SET_IS_CHOOSE_COMPANY_TYPE,
    isChooseCompanyType
  };
}

/**
 * Reset to initial state after submit
 * @returns
 */
function resetToInitialState() {
  return { type: userOnboardingConstants.RESET_TO_INITIAL_STATE };
}

/**
 *  redux action to register new user
 * @param {object} userInformation
 * @param {function} t  i18n translator
 * @param {array} files Arrays of files
 * @returns  return object
 */
function registerUser(userInformation, t, files = []) {
  return (dispatch) => {
    dispatch(request());

    userOnboardingApi.registerUser(userInformation).then(
      (authenticationsInfos) => {
        if (!authenticationsInfos) {
          snackbarUtils.error(`${t("userOnboarding:alreadyRegister")}`);
          dispatch(resetToInitialState());
          history.push(frontendUrl.register);
          return;
        }

        let userEmail = authenticationsInfos["user_email"];
        let companyToken = authenticationsInfos["company_token"];

        dispatch(success(authenticationsInfos));
        uploadedFiles(files, userEmail, companyToken, dispatch);
      },
      (error) => {
        dispatch(failure(error?.toString()));
        genericErrorHandler(JSON.stringify(error?.response?.data));
      }
    );
  };

  function request() {
    return { type: userOnboardingConstants.REGISTER_USER_REQUEST };
  }
  function success(authenticationsInfos) {
    return {
      type: userOnboardingConstants.REGISTER_USER_SUCCESS,
      authenticationsInfos
    };
  }
  function failure(error) {
    return { type: userOnboardingConstants.REGISTER_USER_FAILURE, error };
  }
}

/**
 * function to upload company images
 * @param {array} uploads list of uploaded file
 * @param {string} userEmail    user email
 * @param {string} companyToken  company authorization token
 * @param {function} dispatch    dispatcher
 * @returns   return array of files
 */
async function uploadedFiles(uploads, userEmail, companyToken, dispatch) {
  const promises = [];
  if (uploads.length === 0) {
    history.push(
      `${frontendUrl.validationEmailSent}?email=${encodeURIComponent(
        userEmail
      )}`
    );
    dispatch(resetToInitialState());
    return;
  }

  Object.keys(uploads).forEach((item) => {
    promises.push(
      userOnboardingApi.uploadsCompanyImages(
        uploads[item].file,
        userEmail,
        companyToken
      )
    );
  });

  await Promise.all(promises)
    .finally(() => {
      dispatch(setIsUploadingDone(true));
      history.push(
        `${frontendUrl.validationEmailSent}?email=${encodeURIComponent(
          userEmail
        )}`
      );
      dispatch(resetToInitialState());
    })
    .catch((error) => {
      genericErrorHandler(error);
      dispatch(setIsUploadingDone(false));
    });
}
