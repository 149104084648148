import React from "react";
import { Box, Divider, Drawer, Theme, Typography } from "@mui/material";
import { batch, useDispatch, useSelector } from "react-redux";
import { getModal, modalsActions } from "@next/redux/modalsSlices";
import { WorkspaceModalTypes } from "./types";
import { SupplierWithContactsList } from "../components/suppliers-section/supplier-with-contacts-list";
import { IconButton } from "@material-ui/core";
import CloseIcon from "@mui/icons-material/Close";
import { createStyles, makeStyles } from "@mui/styles";
import { Topic } from "../components/questions-answers/topic/topic";
import { useQAThreadDetails } from "../components/questions-answers/questions-answers.hooks";
import { useTranslation } from "react-i18next";
import { DistributionForSupplierContacts } from "@next/modals/shared-supplier-contact-lists-modal";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    header: {
      display: "flex",
      padding: theme.spacing(2),
      alignItems: "center",
      gap: theme.spacing(1),
      width: "100%",
    },
    loaderContainer: {
      display: "grid",
      placeItems: "center",
      position: "absolute",
      top: "73px",
      left: 0,
      right: 0,
      bottom: 0,
    },
  })
);

export const RfqCommunicationDrawer: React.FC = () => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const { t } = useTranslation();
  const modal = useSelector(
    getModal(WorkspaceModalTypes.RFQ_COMMUNICATION_DRAWER)
  );

  const data = modal?.data as {
    supplier: any;
    showContactsList?: boolean;
    qaId?: number;
  };

  const { question, questionLoading } = useQAThreadDetails(
    data?.qaId?.toString()
  );

  const onClose = () => {
    batch(() => {
      dispatch(
        modalsActions.closeModal(WorkspaceModalTypes.RFQ_COMMUNICATION_DRAWER)
      );
    });
  };

  return (
    <Drawer
      PaperProps={{ style: { width: 620 } }}
      anchor="right"
      open={!!modal}
      onClose={onClose}
    >
      {questionLoading ? (
        <div className={classes.loaderContainer}>
          <Typography variant="h5">{t("common:pleaseWait")}</Typography>
        </div>
      ) : (
        <>
          <Box className={classes.header}>
            <SupplierWithContactsList
              supplier={data?.supplier}
              showContactsList={data?.showContactsList}
              distributionForSupplierContacts={
                DistributionForSupplierContacts.RFQ
              }
            />
            <IconButton onClick={onClose}>
              <CloseIcon sx={{ color: "black" }} />
            </IconButton>
          </Box>
          <Divider />
          <Box display={"flex"} sx={{ height: "90%" }}>
            {question ? (
              <Topic
                topic={question}
                initiallyOpen
                showToastOnMessage={false}
                showExpandIcon={false}
                TitleComponent={null}
                disableAccordion={true}
                //   isPortal={isPortal}
              />
            ) : null}
          </Box>
        </>
      )}
    </Drawer>
  );
};
