import React from "react";

import { Box, Link, Tooltip, Typography } from "@mui/material";
import {
  GridColumns,
  GridRenderCellParams,
  GridValueGetterParams,
} from "@mui/x-data-grid-pro";
import { t } from "assets/configi18n/i18n";
import { Skeleton } from "@mui/material";
import CurrencyField from "components/common/fields/CurrencyField";
import { MouseOverPopover } from "components/utils/mouse-over-popover/mouse-over-popover.component";
import { useSelector } from "react-redux";
import { getRfqStatuses } from "services/part-configuration/part-configuration.selectors";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import { formatDate } from "helpers/formatDate";
import { expectedDeliveryDate } from "helpers/expectedDeliveryDate";
import {
  OrderField,
  OrderStatus,
} from "@next/modules/project/components/order/types";
import { TargetedSuppliers } from "../../redux";
import LeadTimeCell from "@next/components/table-cells/lead-time-cell";
import { IconButtonCell, RatingCell } from "@next/components/table-cells";

const defaultColumnConfig = {
  disableColumnMenu: true,
  sortable: false,
  hideSortIcons: true,
};

export const RFQDisplayNameCell: React.FC<GridRenderCellParams> = (
  params: GridValueGetterParams
) => {
  return (
    <Typography
      style={{ wordBreak: "break-word", whiteSpace: "break-spaces" }}
      variant="caption"
    >
      <Link href="#">{params.row.rfq_display_name}</Link>
    </Typography>
  );
};

const OrderStatusCell: React.FC<GridRenderCellParams> = (
  params: GridValueGetterParams
) => {
  const rfqStatuses = useSelector(getRfqStatuses);

  let found = false;
  let cls = OrderStatus.COMPLETE;
  const row_order_status: any = params.getValue(params.id, "order_status");

  const rfqProgressElements =
    row_order_status &&
    rfqStatuses?.map(
      (order_status: { slug: string; label: string }, index: number) => {
        if (found) {
          cls = OrderStatus.IDLE;
        }
        if (!found && row_order_status?.slug === order_status.slug) {
          found = true;
        }

        return (
          <Tooltip
            key={`item-${index}}`}
            title={order_status.label}
            aria-label="add"
          >
            <FiberManualRecordIcon
              className={`c-project__table__container__row--status-circle-${cls}`}
            />
          </Tooltip>
        );
      }
    );

  return (
    <>
      {rfqStatuses ? (
        <Box className="c-project__table__container__row--order-status-c">
          {params.row?.status !== OrderStatus.CANCELED && (
            <Box>{rfqProgressElements?.map((el: any) => el)}</Box>
          )}
          <Box>
            <Typography
              variant="caption"
              component="span"
              className="order-status-badge"
            >
              {params.row.status !== OrderStatus.CANCELED
                ? row_order_status?.label
                : t("project:table:row:canceled")}
            </Typography>
          </Box>
        </Box>
      ) : (
        <Skeleton animation="wave" height={40} width="80%" />
      )}
    </>
  );
};

const PartsCell: React.FC<GridRenderCellParams> = (
  params: GridValueGetterParams
) => (
  <Box className="c-project__table__container__row--parts">
    <Box>
      <Typography variant="caption" component="span">
        {t("project:table:header:partWithCount", {
          count: params.row.parts_count,
        })}
      </Typography>
    </Box>
    <Box>
      <Typography variant="caption" component="span">
        {t("project:table:header:unitWithCount", {
          count: params.row.parts_quantity,
        })}
      </Typography>
    </Box>
  </Box>
);

const TargetedSuppliersCell: React.FC<GridRenderCellParams> = (
  params: GridValueGetterParams
) => {
  const privateRecipients = Array.isArray(params.row.private_recipients)
    ? params.row.private_recipients
    : [];
  const targetedSuppliers = Array.isArray(params.row.targeted_suppliers)
    ? params.row.targeted_suppliers.map(
        (supplier: TargetedSuppliers) => supplier.name
      )
    : [];

  return (
    <Box className="c-project__table__container__row--parts">
      <Box>
        <MouseOverPopover
          items={[...privateRecipients, ...targetedSuppliers]}
          itemName="suppliersWithCount"
        />
      </Box>

      <Box>
        <Typography variant="caption" component="span">
          {`${t("project:table:header:quotationWithCount", {
            count: params.row.quotations_count,
          })}`}
          <br />
          <span className="itb-color">
            {params.row.intent_to_bid_count > 0 &&
              ` + ${t("project:table:header:intentToBidWithCount", {
                count: params.row.intent_to_bid_count,
              })}`}
          </span>
        </Typography>
      </Box>
    </Box>
  );
};

const WinningSupplierCell: React.FC<GridRenderCellParams> = (
  params: GridValueGetterParams
) =>
  params.row.winner ? (
    <Typography variant="caption">{params.row.winner}</Typography>
  ) : (
    <Typography variant="caption" className="not-available">
      n/a
    </Typography>
  );

const ExpectedDeliveryDateCell: React.FC<GridRenderCellParams> = (
  params: GridValueGetterParams
) => {
  return params.row.po_file && params.row.delay && params.row.delay_unit ? (
    <Typography variant="body2">
      {`${formatDate(
        expectedDeliveryDate(
          new Date(params.row.po_file.created_at),
          params.row.delay,
          params.row.delay_unit
        )
      )}`}
    </Typography>
  ) : (
    <Typography variant="body2" className="not-available">
      n/a
    </Typography>
  );
};

const UnitPriceCell: React.FC<GridRenderCellParams> = (
  params: GridValueGetterParams
) => {
  return params.row.total_price ? (
    <Typography variant="caption">
      <CurrencyField value={params.row.total_price} />
    </Typography>
  ) : (
    <Typography variant="caption" className="not-available">
      n/a
    </Typography>
  );
};

export const getOrderColumns = () => {
  const _columns: GridColumns = [
    {
      ...defaultColumnConfig,
      field: OrderField.RFQ_DISPLAY_NAME,
      minWidth: 200,
      headerName: t("project:table:header:rfq"),
      renderCell: RFQDisplayNameCell,
      flex: 1,
    },

    {
      ...defaultColumnConfig,
      field: OrderField.ORDER_STATUS,
      headerName: t("project:table:header:rfqStatus"),
      minWidth: 250,
      renderCell: OrderStatusCell,
      flex: 1,
    },
    {
      ...defaultColumnConfig,
      field: OrderField.PARTS,
      headerName: t("project:table:header:parts"),
      minWidth: 150,
      renderCell: PartsCell,
      flex: 1,
    },
    {
      ...defaultColumnConfig,
      field: OrderField.TARGETED_SUPPLIERS,
      minWidth: 150,
      headerName: t("project:table:header:targetedSuppliers"),
      renderCell: TargetedSuppliersCell,
      flex: 1,
    },
    {
      ...defaultColumnConfig,
      field: OrderField.WINNING_SUPPLIER,
      minWidth: 150,
      headerName: t("project:table:header:selectedSupplier"),
      renderCell: WinningSupplierCell,
      flex: 1,
    },
    {
      ...defaultColumnConfig,
      field: OrderField.LEAD_TIME,
      minWidth: 200,
      headerName: t("project:table:header:leadTimeAndPrice"),
      renderCell: (params) => <LeadTimeCell {...params} showPOCell />,
      flex: 1,
    },
    {
      field: OrderField.EXPECTED_DELIVERY_DATE,
      minWidth: 180,
      headerName: t("project:table:header:shippingDate"),
      renderCell: ExpectedDeliveryDateCell,
      flex: 1,
    },
    {
      ...defaultColumnConfig,
      field: OrderField.RATING,
      minWidth: 180,
      headerName: t("project:table:header:rating"),
      renderCell: RatingCell,
      align: "center",
      headerAlign: "center",
      flex: 1,
    },
    {
      ...defaultColumnConfig,
      field: OrderField.UNIT_PRICE,
      align: "right",
      headerAlign: "right",
      flex: 1,
      minWidth: 100,
      headerName: t("project:table:header:totalPrice"),
      renderCell: UnitPriceCell,
    },
    {
      ...defaultColumnConfig,
      field: OrderField.EDIT_BUTTON,
      headerName: " ",
      width: 40,
      align: "center",
      renderCell: IconButtonCell,
    },
  ];

  return _columns;
};
