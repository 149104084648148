import React, { useEffect, useState } from "react";
import {
  Box,
  CircularProgress,
  Container,
  Theme,
  Typography,
} from "@mui/material";
import { Check } from "@mui/icons-material";
import { Trans, useTranslation } from "react-i18next";
import { useQuery } from "@next/hooks/useQuery";
import { useDispatch, useSelector } from "react-redux";
import { workspaceNextActions } from "@next/modules/workspace/redux";
import { selectSendNewVerificationEmailLoading } from "@next/modules/workspace/redux/selectors";
import { useHistory } from "react-router-dom";
import { frontendUrl } from "urls";
import { ContactUsModal } from "@next/modules/workspace/modals/contact-us-modal";
import CustomButton from "@next/components/custom-button";
import { ContactSupportPopover } from "@next/components/contact-support-popover/contact-support-popover";
import { createStyles, makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      background: theme.palette.common.white,
      borderRadius: "24px",
      padding: theme.spacing(6),
      marginTop: theme.spacing(6),
      marginBottom: theme.spacing(6),
    },
    container: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
      gap: theme.spacing(6),
      textAlign: "center",
    },
    checkContainer: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      padding: theme.spacing(2),
      borderRadius: "16px",
      border: `1px solid ${theme.palette.divider}`,
      backgroundColor: theme.palette.background.paper,
    },
    linksContainer: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
      width: "100%",
      gap: theme.spacing(2),
    },
    emailSent: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      gap: theme.spacing(1),
      color: theme.palette.success.main,
    },
  })
);

export const Verification = () => {
  const classes = useStyles();
  const { t } = useTranslation();
  const history = useHistory();
  const [query] = useQuery();
  const dispatch = useDispatch();
  const { email } = query;
  const newEmailSentLoading = useSelector(
    selectSendNewVerificationEmailLoading
  );
  const [newVerificationEmailSent, setNewVerificationEmailSent] =
    useState<boolean>(false);
  const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null);

  const handleSendNewVerificationEmail = () => {
    dispatch(
      workspaceNextActions.sendNewVetificationEmailRequest({
        email: email as string,
        onSuccess: () => {
          setNewVerificationEmailSent(true);
        },
      })
    );
  };

  useEffect(() => {
    if (!email) {
      history.push(frontendUrl.notFound);
    }
  }, [email, history]);

  return (
    <div className={"auth"}>
      <Container maxWidth="sm" className={classes.root}>
        <Box className={classes.container}>
          <Box className={classes.checkContainer}>
            <Check color="secondary" />
          </Box>
          <Typography variant="h4">
            {t("userOnboardingVerifyEmail:title")}
          </Typography>
          <Box>
            <Trans
              i18nKey="userOnboardingVerifyEmail:description"
              values={{ email: email || "" }}
            />
          </Box>
          <Box className={classes.linksContainer}>
            {newVerificationEmailSent ? (
              <Box className={classes.emailSent}>
                <Check />
                <Typography variant="body2">
                  {t("userOnboardingVerifyEmail:emailSent")}
                </Typography>
              </Box>
            ) : newEmailSentLoading ? (
              <CircularProgress />
            ) : (
              <CustomButton
                variant="text"
                onClick={handleSendNewVerificationEmail}
                color="primary"
                fullWidth
                data-pid={"btnNewVerificationEmail"}
              >
                {t("userOnboardingVerifyEmail:sendNewEmail")}
              </CustomButton>
            )}
            <CustomButton
              variant="text"
              onClick={(event) => setAnchorEl(event.currentTarget)}
              color="primary"
              data-pid={"btnContactSupport"}
            >
              {t("userOnboardingVerifyEmail:contactSupport")}
            </CustomButton>
            <ContactSupportPopover
              anchorEl={anchorEl}
              onClose={() => setAnchorEl(null)}
              onRight
            />
          </Box>
        </Box>
        <ContactUsModal />
      </Container>
    </div>
  );
};
