import React, { useEffect } from "react";

import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

import {
  Button,
  Card,
  CardContent,
  CircularProgress,
  Grid,
  Typography,
} from "@mui/material";

// Image Assets
import logoBlack from "assets/img/logoHorizontalBlack.svg";
import { frontendUrl } from "urls";
import { fetchUserRatingInfo } from "services/rating/";
import { IState } from "services/rating/rating.model";
import { history } from "helpers/history";

import UserRatingModal from "./user-rating-modal/user-rating-modal.component";

import { LeadTime } from "@next/components/lead-time/lead-time";
import { getSelectedLanguage } from "@next/utils/browserUtils";

const UserRating: React.FC<{ ratingToken?: string; isPublic?: boolean }> = ({
  ratingToken,
  isPublic,
}: any): React.ReactElement => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const activeLanguage = getSelectedLanguage();
  const language = useSelector((state: IState) =>
    state.profile.user ? state.profile.user.language : activeLanguage
  );

  // get API call to fecth user rating criteria and information
  useEffect(() => {
    dispatch(fetchUserRatingInfo(ratingToken));
  }, []);

  const userRating = useSelector((state: IState) => state.userRating);
  const {
    ratingInfo,
    isRatingInfoLoading,
    isSubmitRatingLoading,
    showRatingSuccess,
  } = userRating;

  const hasEstimatedLeadTime =
    ratingInfo?.po_date && ratingInfo?.estimated_lead_time;

  return (
    <div className="c-user-rating">
      {isPublic ? (
        <Grid
          container
          direction="row"
          justifyContent="flex-start"
          alignItems="center"
        >
          <Grid item xs={1}>
            <img
              src={logoBlack}
              alt="Axya logo"
              className="c-user-rating--logo"
            />
          </Grid>
        </Grid>
      ) : null}

      <Grid
        container
        direction="column"
        justifyContent="center"
        alignItems="center"
      >
        {showRatingSuccess || ratingInfo?.updated_at ? (
          <Grid
            className="c-user-rating--success"
            container
            direction="column"
            justifyContent="center"
            alignItems="center"
          >
            <p>{t("ratingSuccess:reviewSent")}</p>
            {isPublic ? (
              <Button
                variant="contained"
                type="submit"
                size="large"
                className="c-user-rating--btn"
                onClick={() => history.push(frontendUrl.login)}
              >
                {t("ratingSuccess:login")}
              </Button>
            ) : null}
          </Grid>
        ) : (
          <Grid
            item
            container
            xs={11}
            sm={11}
            md={11}
            className="c-user-rating--section"
          >
            <Card className="c-user-rating--card">
              {isRatingInfoLoading || isSubmitRatingLoading ? (
                <Grid
                  container
                  direction="column"
                  justifyContent="center"
                  alignItems="center"
                  className="c-loader"
                >
                  <Grid item>
                    <CircularProgress color="inherit" size={40} />
                  </Grid>
                </Grid>
              ) : (
                <>
                  <CardContent className="c-user-rating--info">
                    <Typography variant="h5">
                      {`${t("rating:dialogTitle")} ${
                        ratingInfo?.ratee_company_name
                      } . `}
                    </Typography>
                    <Typography variant="body2">
                      <strong>{ratingInfo?.rfq_name}</strong>
                      {` · ${language === "en" ? "$ " : ""} ${
                        ratingInfo?.quotation_price
                      } ${language == "fr" ? " $" : ""} · ${
                        hasEstimatedLeadTime
                          ? t("rating:expectedDelivery")
                          : t("workspace:leadTime")
                      } · `}

                      <LeadTime
                        value={
                          hasEstimatedLeadTime
                            ? (ratingInfo?.estimated_lead_time as string)
                            : (ratingInfo?.lead_time as string)
                        }
                      />
                    </Typography>
                  </CardContent>
                  <CardContent className="c-user-rating--container">
                    <UserRatingModal
                      ratingToken={ratingToken}
                      isSubmitRatingLoading={isSubmitRatingLoading}
                    />
                  </CardContent>
                </>
              )}
            </Card>
          </Grid>
        )}
      </Grid>
    </div>
  );
};

export default UserRating;
