import { Box, Typography } from "@mui/material";
import { GridColDef } from "@mui/x-data-grid-pro-v5";
import { CircularAvatarsList } from "@next/components/circular-avatars-list";
import ProfileConnectButton from "@next/modules/marketplace/components/profile-connect-button/profile-connect-button";
import { getUserDisplayName } from "@next/modules/profile/helpers/profile-helpers";
import { t } from "assets/configi18n/i18n";
import { formatDate } from "helpers/formatDate";
import React from "react";
import { Trans } from "react-i18next";

const defaultColumnConfig: Partial<GridColDef> = {
  disableColumnMenu: true,
  sortable: false,
  hideSortIcons: true,
};

const CompanyAndUserInfoCell = (params: any) => {
  const user = params?.row?.viewed_by_user;
  const company = params?.row?.viewed_by_company;

  return (
    <Box
      display="flex"
      flex={1}
      alignItems="center"
      justifyContent={"space-between"}
      gap="1rem"
    >
      <Box display="flex" alignItems={"center"}>
        <CircularAvatarsList
          images={[
            {
              name: getUserDisplayName({ first_name: company?.name } || ""),
              tooltip: company?.name,
              url: company?.picture,
            },
            {
              name: getUserDisplayName(user || ""),
              tooltip: user?.full_name,
              url: user?.picture,
            },
          ]}
          overlapping
          combinedTooltip
          size="large"
        />

        <Box ml="12px">
          <Typography
            variant="body2"
            style={{ whiteSpace: "break-spaces", userSelect: "text" }}
          >
            <Trans
              i18nKey="sellerDashboard:userFromCompany"
              components={[<b />]}
              values={{
                userName: user?.full_name,
                companyName: company?.name,
                email: user?.email,
              }}
            />
          </Typography>

          <Typography variant="body2" color="textSecondary">
            {t("sellerDashboard:viewsAndLastViewed", {
              views: params?.row?.views_count,
              lastViewDate: formatDate(params?.row?.viewed_at, "DD-MM-YYYY"),
            })}
          </Typography>
        </Box>
      </Box>
      <ProfileConnectButton
        state={params?.row?.connection_status}
        buyerProfile={{
          id: company?.id,
          name: company?.name,
          picture: company?.picture,
        }}
        connectionId={params?.row?.connection?.id}
      />
    </Box>
  );
};

export const getSellerDashboardUserViewsTableColumns = () => {
  const _columns: GridColDef[] = [
    {
      ...defaultColumnConfig,
      field: "viewed_by_user",
      headerName: "Name",
      flex: 1,
      renderCell: CompanyAndUserInfoCell,
    },
  ];

  return _columns;
};
