import { useDispatch, useSelector } from "react-redux";
import { ProjectCompanyPart } from "@next/modules/project/redux";
import { GridColumns } from "@mui/x-data-grid-pro-v5";
import { useEffect, useState } from "react";
import { getSupplierInvitationsTableColumns } from "./supplier-invitations-table.columns";
import { profileNextActions } from "@next/modules/profile/redux";
import {
  selectSupplierInvitations,
  selectSupplierInvitationsLoading,
} from "@next/modules/profile/redux/selectors";
import { getSellerDashboardPendingInvitationsTableColumns } from "@next/modules/dashboard/components/seller-dashboard-pending-invitations-table/seller-dashboard-pending-invitations-table.column";

export type GridStatePartData = {
  rows: Partial<ProjectCompanyPart>[];
  columns: GridColumns;
};

export const useSupplierInvitationsTableData = ({
  isBuyerPerspective,
}: {
  isBuyerPerspective?: boolean;
}) => {
  const pageSize = isBuyerPerspective ? 10 : 3;
  const dispatch = useDispatch();
  const [currentPage, setCurrentPage] = useState(1);

  const { results: invitations, count } =
    useSelector(selectSupplierInvitations) || {};
  const invitationsLoading = useSelector(selectSupplierInvitationsLoading);

  useEffect(() => {
    dispatch(
      profileNextActions.fetchSupplierInvitationsRequest({
        query: {
          isApproved: false,
          page: currentPage,
          pageSize,
        },
      })
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPage, pageSize]);

  return {
    currentPage,
    setCurrentPage,
    gridData: {
      rows: invitations || [],
      columns: isBuyerPerspective
        ? getSupplierInvitationsTableColumns()
        : getSellerDashboardPendingInvitationsTableColumns(),
    },
    count: count || 0,
    loading: invitationsLoading,
    pageSize,
  };
};
