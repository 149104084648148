import { axiosApi } from "@next/api";
import { Announcement } from "../redux";
import { backendUrl } from "urls";

export const getAnnouncements = async (): Promise<Announcement[]> => {
  const response = await axiosApi.get<Announcement[]>(
    `${backendUrl.announcements}`
  );
  return response.data;
};

export const getAnnouncementBySlug = async (
  slug: string
): Promise<Announcement> => {
  const response = await axiosApi.get<Announcement>(
    `${backendUrl.announcements}${slug}/`
  );
  return response.data;
};
