import React, { useEffect } from "react";
import { Grid } from "@mui/material";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import ProjectTabPanel from "../components/tab-panel/project-tabpanel";
import { projectActions } from "../redux";
import { modalsActions } from "@next/redux/modalsSlices";
import { ProjectModalTypes } from "@next/modules/project/modals/types";

export const ProjectMain: React.FC = () => {
  const dispatch = useDispatch();

  const params = useParams<{ code: string }>();

  useEffect(() => {
    dispatch(
      projectActions.fetchProjectStatsRequest({ projectCode: params.code })
    );

    return () => {
      dispatch(modalsActions.closeModal(ProjectModalTypes.RFQ_DETAILS_MODAL));
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Grid container xs={12} className="c-project">
      <Grid container xs={12} sx={{ mt: "16px" }}>
        <ProjectTabPanel />
      </Grid>
    </Grid>
  );
};
