import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { ProjectModalTypes } from "./types";
import { Trans, useTranslation } from "react-i18next";
import { getModal, modalsActions } from "@next/redux/modalsSlices";
import Modal from "@next/components/custom-modal";
import { Box, Button } from "@mui/material";
import { projectActions } from "../redux";
import { useHistory } from "react-router-dom";
import { frontendUrl } from "urls";

type ProjectDeleteModalData = {
  projectCode: string;
  display_name: string;
  total_parts_count: number;
  attached_files_count: number;
};

type ProjectDeleteModalProps = {
  gotoProjectsListAfterDelete?: boolean;
};

export const ProjectDeleteModal: React.FC<ProjectDeleteModalProps> = ({
  gotoProjectsListAfterDelete,
}) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const modal = useSelector(getModal(ProjectModalTypes.PROJECT_DELETE_MODAL));
  const history = useHistory();
  const projectStatsData = modal?.data as ProjectDeleteModalData;

  if (!modal) return null;

  const handleClose = () => {
    dispatch(modalsActions.closeModal(ProjectModalTypes.PROJECT_DELETE_MODAL));
  };

  const handleDelete = () => {
    dispatch(
      projectActions.deleteProjectRequest({
        code: projectStatsData?.projectCode,
        onSuccess: () => {
          handleClose();
          if (gotoProjectsListAfterDelete) {
            history.push(frontendUrl.projects);
          }
        },
      })
    );
  };

  return (
    <Modal
      handleModalClose={handleClose}
      modalState={{
        modalHeader: t("project:table:modals:deleteProject"),
        modalBody: (
          <Box
            display={"flex"}
            gap={"24px"}
            flexDirection={"column"}
            width={"100%"}
          >
            <Box>
              <Trans
                i18nKey="project:table:modals:deleteProjectBody"
                values={{
                  projectName: projectStatsData?.display_name,
                  partsWithCount: t("project:table:modals:part", {
                    count: projectStatsData?.total_parts_count,
                  }),
                  filesWithCount: t("project:table:modals:file", {
                    count: projectStatsData?.attached_files_count,
                  }),
                }}
              />
            </Box>
            <Box
              sx={{
                display: "flex",
                gap: "16px",
                justifyContent: "flex-end",
                width: "100%",
              }}
            >
              <Button
                variant="outlined"
                color="secondary"
                onClick={handleClose}
              >
                {t("common:cancel")}
              </Button>
              <Button variant="contained" color="error" onClick={handleDelete}>
                {t("common:delete")}
              </Button>
            </Box>
          </Box>
        ),
      }}
      isModalOpen={true}
    />
  );
};
