import React, { forwardRef, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTracking } from "react-tracking";

//helper function use it to redirect to another page

import {
  AppBar,
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogContent,
  Divider,
  Drawer,
  Grid,
  Skeleton,
  Slide,
  Toolbar,
} from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";

import QuotationView from "components/marketplace/quotation-view/quotation-view.component";

import { TabPanel } from "components/utils/tab-panel/tab-panel";
import { QuotationPageNotFound } from "components/quotation-page-not-found/quotation-page-not-found.component";
import { SupplierNDA } from "components/supplier-NDA/supplier-NDA.component";
import { TabsView } from "components/utils/tabs-view/tabs-view.component";
import BuyerStats from "components/buyer-stats/buyer-stats.component";
import { TabName } from "components/common/table-list/types";

import { frontendUrl } from "urls";
import { modalsActions } from "@next/redux/modalsSlices";
import { MarketplaceIntentToBidModal } from "@next/modules/marketplace/modals/marketplace-intent-to-bid-modal";

import { IntendToNoQuote } from "@next/modules/marketplace/components/intent-to-bid/intent-to-no-quote";
import { WorkspaceModalTypes } from "@next/modules/workspace/modals/types";
import { QuestionsAnswers } from "@next/modules/workspace/components/questions-answers/questions-answers";
import { IntentToBidDueDate } from "@next/modules/marketplace/components/intent-to-bid/intent-to-bid-due-date";
import ForumIcon from "@mui/icons-material/Forum";
import { CustomTypography } from "@next/components/custom-typography";
import { MarketplaceModalTypes } from "@next/modules/marketplace/modals/types";
import JoinPrivateB2BNetworkModal from "@next/modules/marketplace/modals/join-private-b2b-network-modal";
import { historyBack } from "@next/utils/browserUtils";
import moment from "moment";
import { marketplaceActions } from "services/marketplace";
import { useQuery } from "@next/hooks/useQuery";

//  A slide up animation of the component when it is opened.
const TransitionToQuotationView = forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export const QuotationDialog = ({
  open,
  token,
  t,
  activeRfqDetailsError,
  hasNDA,
  activeRfqDetails,
  isRfqDetailsLoading,
  getBuyerStatsByRfqId,
  buyerStatsLoading,
  getQuotationThreadDetails,
  setActiveQuotationThread,
  handleOpenModal,
  handleCloseModal,
  clearActiveRfqDetails,
  setRfqHasNDA,
  setSellerNdaAttachments,
  setRfqDetailsLoading,
  setNDAStepperIndex,
}) => {
  const dispatch = useDispatch();
  const tracking = useTracking();
  const [query] = useQuery();
  const [isInvalidId, setIsInvalidId] = useState(false);
  const initiallyOpenQAId = query?.qa;
  const [activeTabIndex, setActiveTabIndex] = useState(query?.tab || 0);
  const [tabsIndex, setTabsIndex] = useState({
    ndaIdex: 0,
    infosIndex: 0,
    qaIndex: 0,
  });
  const isLockedForPremiumPlan = activeRfqDetails?.is_locked_for_premium_plan;
  const profile = useSelector((state) => state.profile);
  const activeRfqId = activeRfqDetails.pk;

  const isRfqDeatilsLoading = useSelector(
    (state) => state.marketplace.isRfqDeatilsLoading
  );
  // to display tab names
  let tabItemsWithNda = [];
  let tabItems = [
    { tabName: TabName.INFORMATION, length: false },
    {
      tabName: TabName.QUESTION_AND_ANSWER,
      length: activeRfqDetails?.questions_count,
      badgeCount: activeRfqDetails?.unseen_questions_count,
    },
  ];

  const onclickCloseQuotationView = () => {
    // clearing activeRfqDetials state on modal close
    clearActiveRfqDetails();
    setRfqHasNDA(false);
    setSellerNdaAttachments([]);
    setRfqDetailsLoading(true);

    historyBack(frontendUrl.marketplace);
  };

  // adding NDA tab when RFQ has NDA
  if (hasNDA) {
    tabItemsWithNda = [{ tabName: TabName.NDA, length: false }];
  }
  tabItems = [...tabItemsWithNda, ...tabItems];

  // open the modal to ask question
  const onAskQuestionButtonClick = () => {
    dispatch(
      modalsActions.showModal({
        key: WorkspaceModalTypes.ASK_QUESTION_MODAL,
        data: {
          rfqId: activeRfqId,
          buyerName: activeRfqDetails?.company_name,
        },
      })
    );
  };

  useEffect(() => {
    // show loader while adding NDA tab dynamically and 2nd step NDA redirection
    if (Object.keys(activeRfqDetails).length) {
      //  checking if the rfq protected by NDA to add NDA tab in quotation modal
      if (
        (activeRfqDetails.nda && !activeRfqDetails.supplier_signed_nda) ||
        (activeRfqDetails.supplier_signed_nda &&
          !activeRfqDetails.supplier_signed_nda.is_approved)
      ) {
        setRfqHasNDA(true);
      } else {
        setRfqHasNDA(false);
      }
      // redirect to 2nd step of stepper when supplier has already uploaded signed NDA
      if (activeRfqDetails.supplier_signed_nda) {
        setNDAStepperIndex(1);
      } else {
        setNDAStepperIndex(0);
      }

      setRfqDetailsLoading(false);
    }
  }, [activeRfqDetails]);

  // fetch the questions answer list at the first render
  useEffect(() => {
    if (activeRfqId) {
      getBuyerStatsByRfqId(token, activeRfqId);
      // Track when the user opens the RFQ

      tracking.trackEvent({
        scout_category: "rfq_views",
        scout_feature_name: "select_active_rfq",
        scout_company: profile["company"]["name"],
        scout_username: profile["user"]["username"],
        scout_user_type: profile["user"]["type"],
        scout_rfq_pk: Number(activeRfqId),
        scout_rfq_name: activeRfqDetails?.rfq_display_name,
        scout_date: new Date(),
      });
    }
  }, [activeRfqId]);

  // use this useEffect to control invalid url and show access denied page
  useEffect(() => {
    if (activeRfqDetailsError && activeRfqDetailsError.length > 0) {
      setIsInvalidId(true);
    }
    if (activeRfqDetailsError && activeRfqDetailsError.length === 0) {
      setIsInvalidId(false);
    }
  }, [activeRfqDetailsError]);

  //use this useEffect to control the tab index when rfq has nda
  useEffect(() => {
    if (hasNDA) {
      setTabsIndex({
        ...tabsIndex,
        ndaIdex: 0,
        infosIndex: 1,
        qaIndex: 2,
      });
    } else {
      tabsIndex["ndaIdex"] = null;
      delete tabsIndex["ndaIdex"];
      setTabsIndex({ ...tabsIndex, infosIndex: 0, qaIndex: 1 });
    }
  }, [hasNDA]);

  useEffect(() => {
    if (isLockedForPremiumPlan) {
      dispatch(
        modalsActions.showModal({
          key: MarketplaceModalTypes.JOIN_PRIVATE_B2B_NETWORK_MODAL,
          data: {
            handleClose: () => {
              dispatch(marketplaceActions.clearActiveRfqDetails());
              historyBack(frontendUrl.marketplace);
            },
          },
        })
      );
    }
  }, [isLockedForPremiumPlan]);

  // tab handle change with selected value
  const tabHandleChange = (event, value) => {
    setActiveTabIndex(value);
  };

  const onclickRedirectToMarketplace = () => {
    historyBack(frontendUrl.marketplace);
  };

  return (
    <Dialog
      fullScreen
      open={open}
      onClose={onclickCloseQuotationView}
      TransitionComponent={TransitionToQuotationView}
      className={"c-quotation-dialog"}
    >
      {isInvalidId && (
        <QuotationPageNotFound onClick={onclickRedirectToMarketplace} />
      )}
      {!isInvalidId && (
        <div className="c-quotation-dialog--container">
          <AppBar
            elevation={1}
            className={`c-quotation-dialog--app-bar ${
              hasNDA
                ? "c-quotation-dialog--has-nda"
                : "c-quotation-dialog--no-nda"
            }`}
            position="fixed"
          >
            <Box
              display="flex"
              alignItems="center"
              className="c-quotation-dialog--app-bar-content"
              p={0}
            >
              <Box display="flex" pl={0} alignItems="center" p={1} flexGrow={1}>
                <Box>
                  <ArrowBackIcon
                    color="secondary"
                    className="c-quotation-dialog--back-icon"
                    onClick={onclickCloseQuotationView}
                  />
                </Box>
                <Box ml={1} mr={1} flexGrow={1}>
                  <CustomTypography
                    maxWidth="calc(100vw - 608px)"
                    variant="h5"
                    className="c-quotation-dialog--rfq-name"
                  >
                    {activeRfqDetails["rfq_display_name"]}
                  </CustomTypography>
                </Box>

                <Box display="flex" alignItems="center">
                  {
                    <>
                      <IntentToBidDueDate
                        rfqPk={activeRfqDetails.pk}
                        rfqDisplayName={activeRfqDetails?.rfq_display_name}
                        maxDate={moment(
                          activeRfqDetails?.deadline,
                          "YYYY-MM-DD"
                        ).add(1, "days")}
                        tabIndex={activeTabIndex}
                        itnbReason={activeRfqDetails?.itnb_reason}
                        isRecommended={activeRfqDetails?.is_recommended}
                        dueDateToBid={activeRfqDetails?.intent_to_bid_due_date}
                      />
                    </>
                  }

                  <Box ml={1}>
                    <IntendToNoQuote
                      preference={{
                        rfq_pk: Number.parseInt(activeRfqId),
                        type: "hidden_rfqs",
                      }}
                      isRecommended={activeRfqDetails?.is_recommended}
                      itnbReason={activeRfqDetails?.itnb_reason}
                      itnbReasonTitle={activeRfqDetails?.itnb_reason_title}
                      itnbReasonDescription={
                        activeRfqDetails?.itnb_reason_description
                      }
                      rfqDisplayName={activeRfqDetails?.rfq_display_name}
                      tabIndex={activeTabIndex}
                      dueDateToBid={activeRfqDetails.intent_to_bid_due_date}
                    />
                  </Box>
                </Box>
              </Box>

              <Button
                variant="outlined"
                size="large"
                onClick={onAskQuestionButtonClick}
                className="c-ask-question"
              >
                <ForumIcon color="secondary" className="c-ask-question--icon" />
                {`${t("workspace:askTheBuyerAQuestion")}`}
              </Button>
            </Box>
            <Divider />

            <TabsView
              tabItems={tabItems}
              tabHandleChange={tabHandleChange}
              tabValue={activeTabIndex}
            />
          </AppBar>

          {(hasNDA ? activeTabIndex === 1 : activeTabIndex === 0) &&
            !activeRfqDetails.quotation_extra_fields && (
              <Drawer
                className="c-quotation-dialog--drawer"
                variant="permanent"
              >
                <Toolbar />
                {buyerStatsLoading || isRfqDetailsLoading ? (
                  <Skeleton />
                ) : (
                  <BuyerStats />
                )}
              </Drawer>
            )}
          <DialogContent className={"c-quotation-dialog--content"}>
            {isRfqDeatilsLoading &&
            Object.keys(activeRfqDetails).length === 0 ? (
              <Grid
                container
                direction="column"
                alignItems="center"
                justifyContent="center"
                className="c-quotation-loader"
              >
                <Grid item>
                  <CircularProgress />
                </Grid>
              </Grid>
            ) : (
              <div
                className={
                  activeRfqDetails?.itnb_reason
                    ? "c-quotation-dialog--content-disabled"
                    : ""
                }
              >
                {/* render NDA tab when RFQ protected by NDA */}
                {hasNDA ? (
                  <TabPanel value={activeTabIndex} index={tabsIndex["ndaIdex"]}>
                    <SupplierNDA activeRfqDetails={activeRfqDetails} />
                  </TabPanel>
                ) : null}
                {/* changing tab index based on NDA tab render, when there is NDA tab index becomes 1 otherwise 0 */}
                <TabPanel
                  value={activeTabIndex}
                  index={tabsIndex["infosIndex"]}
                >
                  <QuotationView
                    getQuotationThreadDetails={getQuotationThreadDetails}
                    setActiveQuotationThread={setActiveQuotationThread}
                    handleOpenModal={handleOpenModal}
                    onclickCloseQuotationView={onclickCloseQuotationView}
                    token={token}
                    isInvalidId={isInvalidId}
                    activeQuote={activeRfqDetails}
                    isRfqDetailsLoading={isRfqDetailsLoading}
                  />
                </TabPanel>
                <TabPanel value={activeTabIndex} index={tabsIndex["qaIndex"]}>
                  <QuestionsAnswers
                    rfqId={activeRfqDetails?.pk}
                    initiallyOpenQAId={initiallyOpenQAId}
                  />
                </TabPanel>
              </div>
            )}
          </DialogContent>
          {/* intent to bid modal */}
          {<MarketplaceIntentToBidModal />}

          <JoinPrivateB2BNetworkModal />
        </div>
      )}
    </Dialog>
  );
};
