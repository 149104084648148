import {
  Avatar,
  Box,
  Button,
  Divider,
  Grid,
  ListItem,
  ListItemText,
  Paper,
  Theme,
  Typography,
} from "@mui/material";
import React, { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import EventAvailableOutlinedIcon from "@mui/icons-material/EventAvailableOutlined";
import FlagOutlinedIcon from "@mui/icons-material/FlagOutlined";
import { formatDate } from "helpers/formatDate";
import MailOutlineIcon from "@mui/icons-material/MailOutline";
import { useDispatch, useSelector } from "react-redux";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import Create from "@mui/icons-material/Create";
import CreateOutlinedIcon from "@mui/icons-material/CreateOutlined";
import Done from "@mui/icons-material/Done";
import ItbItnbToggle, {
  ItbItnbStatus,
} from "@next/components/itb-itnb-toggle/itb-itnb-toggle";
import { useQuery, useQueryParam } from "@next/hooks/useQuery";
import { modalsActions } from "@next/redux/modalsSlices";
import {
  PortalData,
  workspaceNextActions,
} from "@next/modules/workspace/redux";
import { WorkspaceModalTypes } from "@next/modules/workspace/modals/types";
import { normalizeItbItnbStatus } from "@next/modules/workspace/components/questions-answers/topic/topic-portal/topic-portal.utils";
import {
  selectQATopic,
  selectUpdatePortalQuoteNoQuoteLoading,
} from "@next/modules/workspace/redux/selectors";
import { PortalItnbModal } from "../../modals/portal-itnb-modal";
import { PortalModalTypes } from "../../modals/types";
import { PortalItbModal } from "../../modals/portal-itb-modal";
import DownloadableFileItem from "@next/components/downloadable-file-item/downloadable-file-item";
import { FileData } from "@next/components/attach-file/attach-file-list.types";
import StatusVariantTag from "@next/modules/workspace/components/status-variant-tag/status-variant-tag";
import AddendaItemViewOnly from "@next/modules/marketplace/components/addenda-item-view-only/addenda-item-view-only";
import { RichTextEditor } from "@next/components/rich-text-editor";
import { createStyles, makeStyles } from "@mui/styles";
import SimpleRFQFormView from "../simple-rfq-form/simple-rfq-form";
import { useAxyaTracking } from "@next/hooks/useAxyaTracking";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      width: "100%",
      overflow: "auto",
      maxHeight: "507px",
    },
    dates: {
      display: "flex",
      alignItems: "center",
      marginTop: "8px",
    },
    dateIcon: {
      width: "17px",
      color: theme.palette.text.disabled,
    },
    dateItem: {
      display: "flex",
      alignItems: "center",
    },
    dateText: {
      display: "flex",
      alignItems: "center",
      marginLeft: theme.spacing(0.7),
      whiteSpace: "pre-wrap",
    },
    dateDivider: {
      margin: theme.spacing(0, 1.2),
      color: theme.palette.text.disabled,
    },
    dateGreyText: {
      color: theme.palette.action.active,
    },
    iconGrayText: {
      color: theme.palette.text.disabled,
    },
    buyerInfoHeaderAvatar: {
      width: "44px",
      height: "44px",
    },
    buyerInfoHeaderTextIcon: {
      width: "19px",
    },
    quoteGridFirstCol: {
      width: 140,
    },
    fileSectionGrid: {
      display: "grid",
      gridTemplateColumns: "1fr auto",
      rowGap: 12,
    },
    alignRight: {
      textAlign: "right",
    },
    pointer: {
      cursor: "pointer",
    },
  })
);

type Props = {
  portalData?: PortalData;
};

const SideBarComponent: React.FC<Props> = ({ portalData }) => {
  const axyaTracking = useAxyaTracking();
  const classes = useStyles();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [query] = useQuery();
  const [itbItnbType, setItbItnbType] = useQueryParam("type");
  const updatePortalQuoteNoQuoteLoading = useSelector(
    selectUpdatePortalQuoteNoQuoteLoading
  );
  const question = useSelector(selectQATopic);
  const rfqToken = query.rfq_token as string;
  const isRFI = portalData?.request_type === "rfi";
  const isSimpleRFQ = portalData?.request_type === "simple_rfq";
  const itbStatus = normalizeItbItnbStatus(
    portalData?.has_itb,
    portalData?.has_itnb
  );
  const isQuoteAvailable = !!portalData?.quotation;
  const [showSimpleRFQQuoteForm, setShowSimpleRFQQuoteForm] =
    useState<boolean>(false);

  useEffect(() => {
    if (
      typeof itbItnbType === "string" &&
      ["itb", "itnb"].includes(itbItnbType)
    ) {
      dispatch(
        modalsActions.showModal({
          key:
            itbItnbType === "itb"
              ? PortalModalTypes.ITB_MODAL
              : PortalModalTypes.ITNB_MODAL,
          data: {
            portalData,
            rfqToken,
            onClose: () => {
              setItbItnbType(null);
            },
          },
        })
      );
    }
  }, [itbItnbType]);

  useEffect(() => {
    setShowSimpleRFQQuoteForm(!portalData?.quotation && isSimpleRFQ);
  }, [portalData?.quotation, isSimpleRFQ]);

  const onClickItbItnbToggle = (status: ItbItnbStatus) => {
    if (status === ItbItnbStatus?.NO_SELECTION) {
      dispatch(
        workspaceNextActions.updatePortalQuoteNoQuoteStatusRequest({
          rfqToken,
          type: "no_selection",
        })
      );
    } else {
      setItbItnbType(status === ItbItnbStatus.INTENT_TO_QOUTE ? "itb" : "itnb");
    }
  };

  const trackDownloadEvent = (file: FileData | Partial<FileData>) => {
    if (portalData.pk) {
      axyaTracking({
        scout_category: "download",
        scout_feature_name: "select_active_rfq",
        scout_file_name: file?.file_name,
        scout_rfq_pk: Number(portalData.pk),
        scout_rfq_name: portalData?.rfq_display_name,
      });
    }
  };

  const onClickQuoteButton = useCallback(() => {
    if (isSimpleRFQ) {
      setShowSimpleRFQQuoteForm(true);
      return; //no need to dispatch event
    }

    // In case of RFI and RFQ dispatch action to show repective modal
    dispatch(
      modalsActions.showModal({
        key: WorkspaceModalTypes.TOPIC_PORTAL_SEND_QUOTATION_MODAL,
        data: {
          quote: portalData?.quotation,
          isRFI,
        },
      })
    );
  }, [portalData]);

  const getButtonTitle = () => {
    if (isRFI) {
      // RFI
      return isQuoteAvailable
        ? "workspaceNext:QA:topicPortal:reviseSubmission"
        : "workspaceNext:QA:topicPortal:uploadYourSubmission";
    } else if (isSimpleRFQ) {
      //Simple RFQ
      return isQuoteAvailable
        ? "workspaceNext:QA:topicPortal:reviseSubmission"
        : "workspaceNext:QA:simpleRFQ:submit";
    } else {
      // RFQ
      return isQuoteAvailable
        ? "workspaceNext:QA:topicPortal:reviseQuote"
        : "workspaceNext:QA:topicPortal:uploadQuotationFile";
    }
  };

  const getButtonIcon = () => {
    if (isSimpleRFQ) {
      return isQuoteAvailable ? <Create /> : <Done />;
    }

    // for RFI and RFQ
    return isQuoteAvailable ? <CreateOutlinedIcon /> : <ArrowUpwardIcon />;
  };

  const renderQuoteDetails = () => {
    if (isRFI || showSimpleRFQQuoteForm) return null;

    return (
      <>
        <Grid container>
          <Grid item className={classes.quoteGridFirstCol}>
            <Typography variant="body2" className={classes.dateGreyText}>
              {t("workspaceNext:QA:topicPortal:totalCost")}
            </Typography>
          </Grid>
          <Grid item xs>
            <Typography variant="body2">
              {portalData?.quotation?.total_price
                ? `${portalData?.quotation?.total_price} $
                ${
                  portalData?.quotation?.currency &&
                  portalData.quotation.currency.toUpperCase()
                }`
                : "..."}
            </Typography>
          </Grid>
        </Grid>
        {portalData?.quotation?.lead_time?.split(" ")[0] === "0" ? null : (
          <Grid container>
            <Grid item className={classes.quoteGridFirstCol}>
              <Typography variant="body2" className={classes.dateGreyText}>
                {t("workspaceNext:QA:topicPortal:leadTime")}
              </Typography>
            </Grid>
            <Grid item xs>
              <Typography variant="body2">
                {portalData?.quotation?.lead_time || "..."}
              </Typography>
            </Grid>
          </Grid>
        )}
        {portalData?.quotation?.additional_details ? (
          <Grid container>
            <Grid item className={classes.quoteGridFirstCol}>
              <Typography variant="body2" className={classes.dateGreyText}>
                {t("workspaceNext:QA:topicPortal:note")}
              </Typography>
            </Grid>
            <Grid item xs>
              <Typography variant="body2">
                {portalData?.quotation?.additional_details}
              </Typography>
            </Grid>
          </Grid>
        ) : null}
      </>
    );
  };

  const renderQuoteAttachements = () => {
    if (showSimpleRFQQuoteForm) return null;

    return (
      <Box className={classes.fileSectionGrid}>
        <Typography variant="body2" className={classes.dateGreyText}>
          {t("workspaceNext:QA:topicPortal:file")}
        </Typography>
        <Typography
          variant="body2"
          className={`${classes.dateGreyText} ${classes.alignRight}`}
        >
          {t("workspaceNext:QA:topicPortal:uploadedOn")}
        </Typography>
        {portalData?.quotation?.files?.map((file) => (
          <React.Fragment key={file.pk}>
            <DownloadableFileItem showSize file={file as FileData} />
            <Box mt="2px">
              <Typography variant="body2" className={classes.alignRight}>
                {formatDate(file.date)}
              </Typography>
            </Box>
          </React.Fragment>
        ))}
      </Box>
    );
  };

  return (
    <Box>
      <Paper variant="outlined">
        <ListItem divider>
          <ListItemText
            primary={
              <>
                <Box mb={1} mt="4px">
                  <Typography variant="body2" className={classes.dateGreyText}>
                    {t(
                      isRFI
                        ? "workspaceNext:QA:requestForInformation"
                        : "workspaceNext:QA:requestForQuotes"
                    )}
                  </Typography>
                </Box>

                <Typography variant="body1">
                  {portalData?.rfq_display_name}
                </Typography>

                <div className={classes.dates}>
                  <div className={classes.dateItem}>
                    <EventAvailableOutlinedIcon className={classes.dateIcon} />

                    <div className={classes.dateText}>
                      <Typography
                        variant="body2"
                        className={classes.dateGreyText}
                      >
                        {t("workspaceNext:QA:createdAt")}
                      </Typography>

                      <Typography variant="body2">
                        {` ${formatDate(
                          portalData?.created_at,
                          "DD-MMM-YYYY"
                        )}`}
                      </Typography>
                    </div>
                  </div>

                  <div className={classes.dateDivider}>·</div>

                  <div className={classes.dateItem}>
                    <FlagOutlinedIcon className={classes.dateIcon} />

                    <div className={classes.dateText}>
                      <Typography
                        variant="body2"
                        className={classes.dateGreyText}
                      >
                        {t("workspaceNext:QA:endsOn")}
                      </Typography>

                      <Typography variant="body2">
                        {` ${formatDate(portalData?.deadline, "DD-MMM-YYYY")}`}
                      </Typography>
                    </div>
                  </div>
                </div>
              </>
            }
          />
        </ListItem>

        <ListItem divider>
          <ListItemText
            primary={
              <Box display="flex" alignItems="center" gap="8px">
                <Typography variant="body2" className={classes.dateGreyText}>
                  {t("workspaceNext:QA:createdBy")}
                </Typography>

                <Box>
                  <Avatar
                    className={classes.buyerInfoHeaderAvatar}
                    src={portalData?.created_by?.picture}
                  />
                </Box>

                <Box>
                  <Typography variant="body2">
                    {portalData?.created_by?.full_name}
                  </Typography>
                </Box>

                <Box className={classes.iconGrayText}>
                  <MailOutlineIcon
                    className={classes.buyerInfoHeaderTextIcon}
                  />
                </Box>

                <Box className={classes.dateGreyText}>
                  <Typography variant="body2">
                    {portalData?.created_by?.email}
                  </Typography>
                </Box>
              </Box>
            }
          />
        </ListItem>
        <ListItem>
          <Box display="flex" flexDirection="column" gap="12px" mb="4px">
            <Typography variant="body2" className={classes.dateGreyText}>
              {t("workspaceNext:QA:topicPortal:requestFiles")}
            </Typography>

            <RichTextEditor
              readOnly
              defaultValue={
                portalData?.additional_details_rich_text ||
                portalData?.additional_details
              }
              maxCharsToShow={500}
            />

            {!isRFI &&
              !isSimpleRFQ &&
              portalData?.quotation_template?.file_url && (
                <DownloadableFileItem
                  file={
                    {
                      ...portalData.quotation_template,
                      file_name: t(
                        "workspaceNext:QA:topicPortal:quotationTemplate"
                      ),
                    } as FileData
                  }
                />
              )}
            {portalData?.download_urls?.parts.map((part, index) => (
              <DownloadableFileItem
                showSize={true}
                key={index}
                file={{ ...part }}
              />
            ))}
            {portalData?.download_urls?.attachments.map((attachment, index) => (
              <DownloadableFileItem
                showSize={true}
                key={index}
                file={{ ...attachment }}
              />
            ))}
            {portalData?.bundle_file?.file_url && (
              <DownloadableFileItem
                file={
                  {
                    ...portalData.bundle_file,
                    file_name:
                      isRFI || isSimpleRFQ
                        ? portalData?.rfq_display_name
                        : t("workspaceNext:QA:topicPortal:partFiles"),
                  } as FileData
                }
                onDownload={trackDownloadEvent}
              />
            )}
          </Box>
        </ListItem>
        {(portalData?.addenda || []).map((addenda, i) => (
          <>
            <Box my={1}>
              <Divider />
            </Box>
            <ListItem>
              <AddendaItemViewOnly
                addenda={addenda}
                number={(portalData?.addenda?.length || 1) - i}
                onDownload={trackDownloadEvent}
              />
            </ListItem>
          </>
        ))}
      </Paper>

      <Box mt={3}>
        <Paper variant="outlined">
          {isQuoteAvailable ? null : (
            <ListItem divider>
              <Box>
                <ListItemText
                  primary={
                    <Typography
                      variant="body2"
                      className={classes.dateGreyText}
                    >
                      {t("workspaceNext:QA:topicPortal:yourStatus")}
                    </Typography>
                  }
                />
                <Box mt="12px" mb="8px" display="flex" alignItems="center">
                  <ItbItnbToggle
                    status={itbStatus}
                    setItbItnbStatus={onClickItbItnbToggle}
                    loading={updatePortalQuoteNoQuoteLoading}
                    isRFI={isRFI}
                  />
                </Box>
              </Box>
            </ListItem>
          )}
          <ListItem>
            <Box
              display="flex"
              flexDirection="column"
              width="100%"
              gap="12px"
              mt="8px"
            >
              {portalData?.quotation?.is_revision_requested ? (
                <Box>
                  <StatusVariantTag withBg variant="revision-requested" />
                </Box>
              ) : null}
              <Typography variant="body2" className={classes.dateGreyText}>
                {t(
                  isRFI
                    ? "workspaceNext:QA:topicPortal:yourSubmission"
                    : "workspaceNext:QA:topicPortal:yourQuote"
                )}
              </Typography>

              {showSimpleRFQQuoteForm && (
                <SimpleRFQFormView portalData={portalData} />
              )}

              {renderQuoteDetails()}

              {portalData?.quotation?.files.length > 0 &&
                renderQuoteAttachements()}

              {!showSimpleRFQQuoteForm && (
                <Button
                  startIcon={getButtonIcon()}
                  variant={isQuoteAvailable ? "outlined" : "contained"}
                  onClick={onClickQuoteButton}
                  color={isQuoteAvailable ? "primary" : "secondary"}
                >
                  {t(getButtonTitle())}
                </Button>
              )}
            </Box>
          </ListItem>
        </Paper>
      </Box>

      <PortalItnbModal />
      <PortalItbModal />
    </Box>
  );
};

export default SideBarComponent;
