import React from "react";
import { connect } from "react-redux";
import { marketplaceActions } from "../../services/marketplace";
import { modalsActions } from "../../services/modals/modals.actions";
import MarketplaceRequestsPage from "@next/modules/marketplace/components/marketplace-requests-page/marketplace-requests-page";

const mapDispatchToProps = {
  fetchActiveRFQList: marketplaceActions.fetchActiveRFQList,
  handleOpenModal: modalsActions.showModal,
};

const mapStateToProps = (state) => {
  return {
    token: state.profile.token,
    company: state.profile.company,
    activeRFQList: state.marketplace.activeRFQList,
    selectedFilters: state.partConfiguration.selectedFilters,
    isLoading: state.marketplace.isLoading,
    isQuotationModalOpen: state.modals.isQuotationModalOpen,
  };
};
export default React.memo(
  connect(mapStateToProps, mapDispatchToProps)(MarketplaceRequestsPage)
);
