import { UserRatingActionTypes } from "./rating.constants";
import RatingInfo from "./rating.api";
import { ISendRatingInfo } from ".";
import { ThunkDispatch } from "redux-thunk";
import { AnyAction } from "redux";
import snackbarUtils from "@next/utils/snackbarUtils";

export const fetchUserRatingInfo =
  (token: string) => async (dispatch: ThunkDispatch<{}, {}, AnyAction>) => {
    dispatch({
      type: UserRatingActionTypes.FETCH_USER_RATING_INFO_REQUEST
    });
    RatingInfo.getRatingInfo(token).then(
      (ratingInfo) => {
        dispatch({
          type: UserRatingActionTypes.FETCH_USER_RATING_INFO_SUCCESS,
          ratingInfo
        });
      },
      (error) => {
        dispatch({
          type: UserRatingActionTypes.FETCH_USER_RATING_INFO_FAILURE,
          error: error.toString()
        });
      }
    );
  };

export const sendUserRating =
  (token: string, rating: ISendRatingInfo) =>
  async (dispatch: ThunkDispatch<{}, {}, AnyAction>) => {
    dispatch({
      type: UserRatingActionTypes.SEND_USER_RATING_REQUEST
    });
    RatingInfo.sendUserRating(token, rating).then(
      () => {
        dispatch({
          type: UserRatingActionTypes.SEND_USER_RATING_SUCCESS
        });
      },
      (error) => {
        dispatch({
          type: UserRatingActionTypes.SEND_USER_RATING_FAILURE,
          error: error.toString()
        });
        // FIXME: refactor it to component specific
        snackbarUtils.error(error.toString());
      }
    );
  };
