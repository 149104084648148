import { Box } from "@mui/material";
import { LeadTime } from "@next/components/lead-time/lead-time";
import { checkIsShowCommissionFeeCompany } from "@next/utils/checkCompanyUtils";
import { QuoteTotalPrice } from "components/workspace/buyer-rfqs-view/quote-total-price";
import React from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { getCompany } from "services/profile/profile.selectors";
import LocalShippingOutlinedIcon from "@mui/icons-material/LocalShippingOutlined";
import moment from "moment";
import { createStyles, makeStyles } from "@mui/styles";

interface Props {
  quotation: Record<string, any>;
  showCurrencyRate?: boolean;
}

const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      marginRight: theme.spacing(1.5),
    },
    boxLabel: {
      display: "flex",
      justifyContent: "flex-end",
      alignItems: "center",
      color: theme.palette.text.primary,
      gap: theme.spacing(4),
    },
    textLight: {
      color: theme.palette.text.secondary,
    },
    truckIcon: {
      fontSize: 21,
      color: theme.palette.text.disabled,
    },
  })
);

const QuotationPriceAndLeadTime: React.FC<Props> = (props) => {
  const { quotation } = props;
  const { t } = useTranslation();
  const classes = useStyles();

  const quoteTotalPrice = quotation?.total_price
    ? Number.parseFloat(quotation?.total_price)
    : undefined;

  const company = useSelector(getCompany);

  const isShowCommissionFeeCompany = checkIsShowCommissionFeeCompany(
    company?.id
  );

  if (
    !isShowCommissionFeeCompany &&
    !quotation?.commission_fee &&
    quoteTotalPrice === 0
  )
    return null;

  return (
    <Box className={classes.root}>
      <Box className={classes.boxLabel}>
        <Box textAlign="right" fontWeight="fontWeightMedium" fontSize={18}>
          {quotation?.delay === 0 ? null : (
            <>
              <LeadTime value={quotation?.lead_time} />
              {quotation?.estimated_lead_time ? (
                <Box
                  mt={1}
                  display="flex"
                  fontSize={16}
                  fontWeight="fontWeightRegular"
                  gap="5px"
                >
                  <LocalShippingOutlinedIcon className={classes.truckIcon} />
                  {moment(quotation.estimated_lead_time).format("DD-MMM-YYYY")}
                </Box>
              ) : null}
            </>
          )}
        </Box>

        <Box
          ml={3}
          textAlign="right"
          fontWeight="fontWeightMedium"
          fontSize={20}
        >
          <QuoteTotalPrice
            price={
              isShowCommissionFeeCompany && quotation?.commission_fee
                ? quotation?.commission_fee?.final_total_price
                : quoteTotalPrice
            }
            {...(props.showCurrencyRate &&
              quoteTotalPrice &&
              quoteTotalPrice > 0 && {
                currency: quotation?.currency,
                currencyConversion: quotation?.currency_conversion,
              })}
          />
        </Box>
      </Box>
      {isShowCommissionFeeCompany && quotation?.commission_fee && (
        <Box className={`${classes.boxLabel} ${classes.textLight}`}>
          {t("pay:paymentTerms:commissionIncluded")}
        </Box>
      )}
    </Box>
  );
};

export default QuotationPriceAndLeadTime;
