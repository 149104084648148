/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ordersActions } from "../../redux";
import {
  selectPortalOrder,
  selectPortalOrderLoading,
} from "../../redux/selectors";

export const usePublicOrderPortalData = (orderToken?: string) => {
  const portalOrderData = useSelector(selectPortalOrder);
  const portalOrderLoading = useSelector(selectPortalOrderLoading);

  const dispatch = useDispatch();

  useEffect(() => {
    if (orderToken) {
      dispatch(
        ordersActions.fetchPortalOrderRequest({
          orderToken,
        })
      );
    }
  }, [orderToken]);

  return {
    portalOrderData,
    portalOrderLoading,
  };
};
