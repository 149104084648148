import {
  selectOrder,
  selectOrders,
  selectOrdersCounts,
  selectOrdersLastQuery,
  selectOrdersLoading,
} from "@next/modules/orders/redux/selectors";
import {
  selectProjectCompanyOrders,
  selectProjectCompanyOrdersCounts,
  selectProjectCompanyOrdersLastQuery,
  selectProjectCompanyOrdersLoading,
} from "@next/modules/project/redux/selectors";
import { useDispatch, useSelector } from "react-redux";
import { getRfqDetails } from "services/workspace/workspace.selectors";
import { projectActions } from "@next/modules/project/redux";
import {
  PAGINATION_PAGE_SIZE,
  SHORT_PAGINATION_PAGE_SIZE,
} from "@next/constants";
import { frontendUrl } from "urls";
import { ordersActions } from "@next/modules/orders/redux";

export enum NAVIGATION_FOR {
  RFQ = "rfq",
  ORDER = "order",
}

export const useDataForNavigation = (navigationFor = NAVIGATION_FOR.RFQ) => {
  const dispatch = useDispatch();

  // RFQ selectors
  const companyRequests = useSelector(selectProjectCompanyOrders);
  const companyRequestsLastQuery = useSelector(
    selectProjectCompanyOrdersLastQuery
  );
  const companyRequestsLoading = useSelector(selectProjectCompanyOrdersLoading);
  const companyRequestsCounts = useSelector(selectProjectCompanyOrdersCounts);
  const rfqDetails = useSelector(getRfqDetails);

  // Order selectors
  const companyOrders = useSelector(selectOrders);
  const companyOrdersLastQuery = useSelector(selectOrdersLastQuery);
  const companyOrdersLoading = useSelector(selectOrdersLoading);
  const companyOrdersCounts = useSelector(selectOrdersCounts);
  const orderDetails = useSelector(selectOrder);

  // Determine the relevant data based on the navigationFor parameter
  const data =
    navigationFor === NAVIGATION_FOR.RFQ
      ? {
          items: companyRequests,
          lastQuery: companyRequestsLastQuery,
          loading: companyRequestsLoading,
          counts: companyRequestsCounts,
          details: rfqDetails,
          action: projectActions.fetchProjectCompanyOrdersRequest,
          baseUrl: frontendUrl.bundleView,
          paginationSize: PAGINATION_PAGE_SIZE,
        }
      : {
          items: companyOrders,
          lastQuery: companyOrdersLastQuery,
          loading: companyOrdersLoading,
          counts: companyOrdersCounts,
          details: orderDetails,
          action: ordersActions.fetchOrdersRequest,
          baseUrl: frontendUrl.orders,
          paginationSize: SHORT_PAGINATION_PAGE_SIZE,
        };

  const currentPageNumber =
    parseInt(data.lastQuery?.match(/page=(\d+)/)?.[1]) || 1;
  const currentItemIndex = data.items?.findIndex(
    (item) => item.pk === data.details?.pk
  );
  const currentCount =
    (currentPageNumber - 1) * data.paginationSize + currentItemIndex + 1;
  const totalCount = data.counts?.count;

  const navigateToItem = (itemIndex, pageNumber = currentPageNumber) => {
    const queryString = data.lastQuery.replace(
      /page=\d+/,
      `page=${pageNumber}&`
    );
    dispatch(
      data.action({
        query: queryString,
        onSuccess: (res) => {
          const newItems = res?.results;
          if (newItems?.length > 0) {
            window.location.href = `${data.baseUrl}/${newItems[itemIndex].pk}`;
          }
        },
      })
    );
  };

  const handleNextClick = () => {
    if (currentItemIndex === data.items.length - 1) {
      navigateToItem(0, currentPageNumber + 1);
    } else {
      window.location.href = `${data.baseUrl}/${
        data.items[currentItemIndex + 1].pk
      }`;
    }
  };

  const handlePreviousClick = () => {
    if (currentItemIndex === 0 && currentPageNumber > 1) {
      navigateToItem(data.items.length - 1, currentPageNumber - 1);
    } else {
      window.location.href = `${data.baseUrl}/${
        data.items[currentItemIndex - 1].pk
      }`;
    }
  };

  return {
    currentCount,
    totalCount,
    loading: data.loading,
    handleNextClick,
    handlePreviousClick,
  };
};
