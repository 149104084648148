import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import moment from "moment";
import Button from "@mui/material/Button";
import MuiDialogActions from "@mui/material/DialogActions";
import { withStyles } from "@mui/styles";
import { Grid, Typography } from "@mui/material";

import { marketplaceActions } from "../../services/marketplace/marketplace.actions";

import Modal from "@next/components/custom-modal";
import ConfirmationDialog from "../../@next/components/confirmation-dialog/confirmation-dialog";
import { useTranslation } from "react-i18next";

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions);

const IntentToBidModal = ({
  rfqDetails: {
    pk,
    intent_to_bid_due_date = "",
    deadline,
    rfq_display_name,
    is_recommended,
  },
  isModalOpen,
  handleClose,
  tabIndex = 0,
}) => {
  const { t } = useTranslation();
  return (
    <Modal
      handleModalClose={() => {
        handleClose();
      }}
      modalState={{
        modalHeader: t("workspace:intentToQuote"),
        modalBody: (
          <DeadlineDatePicker
            t={t}
            handleClose={handleClose}
            tabIndex={tabIndex}
            rfqPk={pk}
            dueDateToBid={intent_to_bid_due_date}
            maxDate={deadline}
            rfqDisplayName={rfq_display_name}
            isRecommended={is_recommended}
          />
        ),
      }}
      isModalOpen={isModalOpen}
    />
  );
};

const DeadlineDatePicker = ({
  t,
  handleClose,
  tabIndex,
  rfqPk,
  dueDateToBid,
  maxDate,
  rfqDisplayName,
  isRecommended,
}) => {
  const [dueDate, setDueDate] = useState(
    dueDateToBid ? dueDateToBid : new Date()
  );
  const [isConfirmationDialogOpen, setIsConfirmationDialogOpen] =
    useState(false);
  const language = useSelector((state) => state.profile.user.language);
  const isQuotationModalOpen = useSelector(
    (state) => state.modals.isQuotationModalOpen
  );
  const dispatch = useDispatch();

  const handleDateChange = (date) => {
    setDueDate(date);
  };

  const confirmationYesHandler = () => {
    handleClose();
    const prefs = {
      type: "intent_to_bid_rfqs",
      rfq_pk: rfqPk,
      rfq_display_name: rfqDisplayName,
    };
    dispatch(
      marketplaceActions.setIntentToBidDueDate(
        prefs,
        true,
        tabIndex,
        tabIndex === 1 ? false : true,
        t,
        isRecommended
      )
    );
  };

  const confirmationNoHandler = () => {
    setIsConfirmationDialogOpen(false);
  };

  const handleClear = () => {
    setIsConfirmationDialogOpen(true);
  };

  const handleSubmit = () => {
    handleClose();
    const prefs = {
      type: "intent_to_bid_rfqs",
      rfq_pk: rfqPk,
      due_date: moment(dueDate).format("YYYY-MM-DD"),
      rfq_display_name: rfqDisplayName,
    };
    dispatch(
      marketplaceActions.setIntentToBidDueDate(
        prefs,
        false,
        tabIndex,
        dueDateToBid || isQuotationModalOpen ? true : false,
        t,
        isRecommended
      )
    );
  };

  return (
    <section className="c-intent-to-bid-section">
      <Grid className="c-intent-to-bid">
        <Typography
          variant="body1"
          className="c-intent-to-bid--text"
          color="#000000DE"
        >
          {t("workspace:intentToBidText")}
        </Typography>
      </Grid>
      <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <KeyboardDatePicker
          className="c-intent-to-bid-date"
          margin="normal"
          id="date-picker-dialog"
          fullWidth
          inputVariant={"outlined"}
          format={language === "en" ? "yyyy/MM/dd" : "dd/MM/yyyy"}
          value={dueDate}
          maxDate={maxDate}
          label={t("workspace:intentToBidLabel")}
          onChange={handleDateChange}
          KeyboardButtonProps={{
            "aria-label": "change date",
          }}
          disablePast
          InputProps={{
            disableUnderline: true,
          }}
        />
      </MuiPickersUtilsProvider>
      <DialogActions className="c-intent-to-bid--actions">
        {(tabIndex !== 0 || dueDateToBid) && (
          <Button
            autoFocus
            onClick={handleClear}
            color="default"
            variant="contained"
            className="c-intent-to-bid--clear-date"
          >
            {t("workspace:clearButton")}
          </Button>
        )}
        <Button
          autoFocus
          onClick={handleSubmit}
          color="primary"
          variant="contained"
          className="c-intent-to-bid--submit-date"
        >
          {t("workspace:submit")}
        </Button>
      </DialogActions>

      <ConfirmationDialog
        title={t("workspace:confirmation")}
        content={t("workspace:clearIntentToBidConfirmation")}
        isOpen={isConfirmationDialogOpen}
        handleClose={confirmationNoHandler}
        dialogActions={
          <div>
            <Button autoFocus onClick={confirmationNoHandler} color="primary">
              {t("workspace:cancel")}
            </Button>
            <Button onClick={confirmationYesHandler} color="primary">
              {t("RFQ:yes")}
            </Button>
          </div>
        }
      />
    </section>
  );
};

export default IntentToBidModal;
