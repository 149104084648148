import { GridStateData } from "@next/hooks/useGridData-v5";
import { t } from "assets/configi18n/i18n";
import { ProjectsPartField } from "./types";
import { MyPartsTableField } from "@next/modules/workspace/components/my-parts-table/my-parts-table.types";

export const addTypeAttribute = (data: any) =>
  data.map((option: any) => {
    const byType = option?.type?.toUpperCase();
    return {
      type: /[0-9]/.test(byType)
        ? Array(5).fill("\xa0").join("") + "0-9"
        : Array(5).fill("\xa0").join("") + byType,
      ...option,
    };
  });

export const normalizeMaterialData = (materials: any) => {
  const materialData: any[] = [];
  if (materials) {
    materials.map((material: any) => {
      material.alloys.forEach((alloy: any) => {
        alloy.type = material.name;
        materialData.push(alloy);
      });

      return material;
    });
  }

  return materialData;
};

export const NEW_ROW_ID_KEY = "new-row";

/*
 * This is just a helper to detect that the added row is new row's id
 */
export const isNewRowId = (
  id: string | number,
  key: string = NEW_ROW_ID_KEY
) => {
  return id?.toString().startsWith(key);
};

export const matchKeysAndParseData = (
  results: any,
  gridData: GridStateData
) => {
  try {
    const { rows, columns } = gridData;
    const noRows = rows.length === 0;

    // We have to do this because csv saving with the languaged headerName
    const partNumberLanguageKey = t("project:table:header:partNumber");

    const newRows = results?.data?.reduce((accRows: any, result: any) => {
      // Push row if not exist same partNumber
      const partExist = rows.find((row) => {
        return row.name === result[partNumberLanguageKey];
      });

      if (!partExist || noRows) {
        // We need to find actual object keys from the languaged headerName
        const parsedColumn = Object.keys(result).reduce(
          (accColumn: any, key) => {
            const foundColumn = columns.find(
              (column) => column.headerName === key
            );

            if (
              foundColumn &&
              /^(name|quantity|additional_details)/.test(
                foundColumn?.field as string
              )
            ) {
              return {
                ...accColumn,
                ...{
                  [foundColumn?.field]: result[key],
                },
              };
            }

            return accColumn;
          },
          {}
        );

        accRows.push(parsedColumn);
      }

      return accRows;
    }, []);

    return newRows;
  } catch (error) {
    console.log(error);
  }
};

export const checkIsColumnHidden = (
  pmPartsHiddenColumns: string[],
  columnName: ProjectsPartField | MyPartsTableField
) => {
  return pmPartsHiddenColumns?.includes(columnName);
};
