import { FileData } from "@next/components/attach-file/attach-file-list.types";
import { RfqDetails } from "@next/modules/project/redux";
import { CompanyTypeNext } from "@next/redux/types";
import { GenericQueryParamsType } from "@next/utils/typeUtils";
import { Capability } from "components/user-company/company-capabilities/company-capabilities.types";
// import { CapabilitiesData } from "services/company-capabilities/company-capabilities.selectors";

export type AppLanguage = "en" | "fr";

export enum EventType {
  IS_EMAIL_SUBSCRIBED = "is_email_subscribed",
  IS_USER_SUBSCRIBED = "is_user_subscribed",
  RFQS_RECAP = "RFQS_RECAP",
  CREATE_RFQ = "CREATE_RFQ",
  QUOTATIONS_UPDATES = "QUOTATIONS_UPDATES",
  NEW_MESSAGES = "NEW_MESSAGES",
}
export interface SubscriptionProps {
  subscriptions: Subscription[];
  title?: string;
}

export enum FormLabelText {
  SETTINGS_NOTIFICATIONS_LABEL = "settingsNotificationsLabel",
  PREFERENCES_NOTIFICATIONS_LABEL = "preferencesNotificationsLabel",
}

export type GenericObject = { [key: string]: any };

export type Subscription = {
  pk: number;
  event_type: EventType;
  is_user_subscribed: boolean;
  name: string;
  created_at: string;
};

export type SubscriptionInput = {
  subPk: number;
  name?: string;
  isEnabled: boolean;
  eventType?: EventType;
};
export type Permission = {
  id: number;
  name: string;
};

export type ProfileState = {
  enableSubscriptionLoading: boolean;
  enableSubscriptionError?: Error | undefined;
  subscriptionListLoading: boolean;
  subscriptionListError?: Error | undefined;
  subscriptionList: Subscription[] | undefined;
  companyProfileLoading: boolean;
  companyProfileError?: Error | undefined;
  companyProfile: CompanyProfile | undefined;
  updateCompanyProfileLoading: boolean;
  updateCompanyProfileError?: Error | undefined;
  updateCompanyFileReferencesLoading: boolean;
  updateCompanyFileReferencesError?: Error | undefined;
  deleteCompanyFileReferencesLoading: boolean;
  deleteCompanyFileReferencesError?: Error | undefined;
  reorderCompanyFileReferencesLoading: boolean;
  reorderCompanyFileReferencesError?: Error | undefined;
  updateUserProfileLoading: boolean;
  updateUserProfileError?: Error | undefined;
  updateUserPasswordLoading: boolean;
  updateUserPasswordError?: Error | undefined;
  updateUserLanguageLoading: boolean;
  updateUserLanguageError?: Error | undefined;
  update2FAAuthLoading: boolean;
  update2FAAuthError?: Error | undefined;
  update2FAAuthResult?: any;
  fetchUserActive2FAsLoading: boolean;
  fetchUserActive2FAsError?: Error | undefined;
  fetchUserActive2FAsResult?: any;

  inviteCompanyUserLoading: boolean;
  inviteCompanyUserError?: Error | undefined;

  updateProfilePreferencesLoading: boolean;
  updateProfilePreferencesError?: Error | undefined;

  uploadCompanyFileDocumentsLoading: boolean;
  uploadCompanyFileDocumentsError?: Error | undefined;

  deleteCompanyFileDocumentsLoading: boolean;
  deleteCompanyFileDocumentsError?: Error | undefined;

  publicRequest?: RfqDetails;
  fetchPublicRequestLoading: boolean;
  fetchPublicRequestError?: Error | undefined;
  fetchPublicRequestResult?: any;

  sendDiscoveryLoading: boolean;
  sendDiscoveryError: Error | undefined;

  sendBuyerProfileMessageLoading: boolean;
  sendBuyerProfileMessageError?: Error | undefined;
  companyProfileExternal: CompanyPublicProfile | undefined;

  fetchSupplierInvitationsLoading: boolean;
  fetchSupplierInvitationsError?: Error | undefined;
  fetchSupplierInvitationsResult?: ConnectionRequests;

  fetchInvitationDetailsLoading: boolean;
  fetchInvitationDetailsError?: Error | undefined;
  fetchInvitationDetailsResult?: ConnectionDetails;

  approveInvitationLoading: boolean;
  approveInvitationError?: Error | undefined;

  deleteInvitationLoading: boolean;
  deleteInvitationError?: Error | undefined;

  updateMemberTeamPermissionLoading: number[];
  updateMemberTeamPermissionError?: Error | undefined;
};

export type CompanyRole = "admin" | "normal" | "viewer" | "restricted_viewer";

export type User = {
  username: string;
  first_name: string;
  last_name: string;
  email: string;
  company: number;
  picture: string;
  title: string;
  type: string;
  user_type: CompanyRole;
  is_active: boolean;
  language: string;
  date_joined: string;
  nps_token: null;
  is_getting_started: boolean;
  is_staff?: boolean;
};

export type GenericUser = Partial<User> & {
  id?: number;
  [key: string]: any;
};

export interface Company extends GenericObject {
  id: number;
  name: string;
  phone_number: string;
  website: string;
  description: string;
  picture: string;
  type: string;
  rank_type: string;
  is_email_subscribed: boolean;
  is_active: boolean;
  disallow_marketplace_access: boolean;
  references: null;
  is_pm_feature_enabled: boolean;
  permissions: Permission[];
  users: User[];
  is_marketplace_enabled_buyer?: boolean;
  uuid: string;
  profile_completeness?: profileCompleteness;
  expertise?: Capability[];
  capabilities_comment?: string;
  industry_expertise?: Capability[];
  certificates?: CompanyCertificate[];
  is_rfq_analytics_enabled: boolean;
  is_po_analytics_enabled: boolean;
}

export type CompanyImage = {
  id: string;
  url: string;
};

export type CompanyReference = {
  company?: string;
  email?: string;
  name: string;
  phone?: string;
};

export type CompanyRatingScore = {
  rating_criteria_pk: number;
  avg_score?: number;
  short_name?: string;
  description?: string;
  min_score?: number;
  max_score?: number;
};

export type CompanyStats = {
  quotes?: number;
  contracts_won?: number;
  avg_quoting_time?: string | null;
  external_quotes_number?: number | null;
  external_contracts_number?: number | null;
  external_rating_score?: number | null;
};

export type CompanyTestimonial = {
  author?: string;
  company?: string;
  body?: string;
};

export type CompanyCertificate = {
  name?: string;
  pk?: number;
  file?: Partial<FileData>;
};

type Completeness = {
  weightage: number;
  is_complete: boolean;
};

type profileCompleteness = {
  certifications?: Completeness;
  company_info?: Completeness;
  documents?: Completeness;
  industry_expertise?: Completeness;
  manufacturing_capabilities?: Completeness;
  past_project_pictures?: Completeness;
  shop_pictures?: Completeness;
  total?: number;
};

export type CompanyProfileBase = {
  additional_details?: string;
  additional_details_rich_text?: string;
  id?: number | string;
  picture?: string;
  name?: string;
  users?: GenericUser[];
  address: {
    city?: string;
    country?: string;
    postal_code?: string;
    province?: string;
    street?: string;
    latitude?: number;
    longitude?: number;
  };
  public_email?: string;
  phone_number?: string;
  website?: string;
  email?: string;
  description?: string;
  number_employee?: number;
  shop_floor_area?: string;
  certificates?: CompanyCertificate[];
  expertise?: Capability[];
  past_projects_pictures?: CompanyImage[];
  shop_pictures?: CompanyImage[];
  is_active?: boolean;
  uuid?: string;
  type?: CompanyTypeNext;
  ratings?: {
    overall_score?: number;
    ratings_count?: number;
    rating_scores?: CompanyRatingScore[];
  };
  stats?: CompanyStats;
  testimonials?: CompanyTestimonial[];
  industry_expertise?: Capability[];
  profile_completeness?: profileCompleteness;
  capabilities_comment?: string;
};

export type CompanyProfile = CompanyProfileBase & {
  references?: CompanyReference[];
  subscribed_to_companies?: number[];
  documents?: any[];
};

export type CompanyPublicProfile = CompanyProfileBase & {
  references?: string[];
  documents?: Partial<FileData>[];
  capabilities?: Capability[];
  is_premium?: boolean;
};

export interface CompanyInformationFormBaseProps {}

export enum CompanyProfileFileReferenceType {
  PAST_PROJECT = "PAST_PROJECT",
  SHOP = "SHOP",
}

export enum TwoFAAuthType {
  EMAIL = "email",
  SMS = "sms",
}

export enum TwoFAActionType {
  ACTIVATE = "activate",
  DEACTIVATE = "deactivate",
}

export type Update2FAAuthInput = {
  authType: TwoFAAuthType;
  actionType?: TwoFAActionType;
  isConfirm?: boolean;
  code?: string;
};

export type InviteCompanyUserInput = {
  users: {
    email: string;
    user_type: Exclude<User["user_type"], "admin">;
    language: AppLanguage;
  }[];
  onSuccess?: (users: GenericUser[]) => void;
};

export type UpdateProfilePreferencesInput = {
  language: AppLanguage;
  token: string;
};

export type UpdateUserLanguageInput = {
  language: AppLanguage;
  token?: string;
};

export type UploadCompanyDocumentsInput = {
  attachments: File[];
  pk: number;
  onSuccess?: () => void;
};

export type DeleteCompanyDocumentsInput = {
  fileId: number;
  onSuccess?: () => void;
};

export type SendBuyerProfileMessageInput = {
  buyerProfileId: number;
  message: object;
  files: File[];
};

export type DiscoveryRequestInput = {
  type: "quotation" | "connection";
  message: object;
  files: File[];
};

export type ReorderCompanyFileReferencesInput = {
  type: CompanyProfileFileReferenceType;
  ordering: number[];
};

export type UpdateCompanyFileReferencesInput = {
  files: File[];
  type: CompanyProfileFileReferenceType;
  onSuccess?: () => void;
};

export type DeleteCompanyFileReferencesInput = {
  fileId: string;
  type: CompanyProfileFileReferenceType;
  onSuccess?: () => void;
};

export type FetchCompanyProfileRequestInput = {
  companyId: number | string;
  isPublic?: boolean;
  resetState?: boolean;
};

export type FetchSupplierInvitationsInput = {
  query?: GenericQueryParamsType & { isApproved?: boolean };
};

export type UpdateMemberTeamPermissionInput = {
  email: string;
  user_type: User["user_type"];
  userId: number;
  onSuccess?: () => void;
};

export type ResendMemberInviteInput = {
  email: string;
  language: AppLanguage;
};

export type ConnectionInput = {
  connectionId: number;
  buyerProfileId?: number;
};

export type Connection = {
  id: number;
  is_approved: boolean;
  created_at: string;
  created_by: GenericUser;
  from_company: Partial<Company>;
  to_company: Partial<Company>;
};

export interface ConnectionDetails extends Connection {
  files: FileData[];
  additional_details: string;
  additional_details_rich_text: string;
}

export type ConnectionRequests = {
  count: number;
  results: Connection[];
};
