import { ProjectCompanyPart } from "@next/modules/project/redux";
import { FileData } from "@next/components/attach-file/attach-file-list.types";
import { useMemo } from "react";
import { useTracking } from "react-tracking";
import { useSelector } from "react-redux";
import { RootState } from "store";
import { saveAs } from "file-saver";
import { Addenda } from "@next/modules/workspace/redux";
import { getAddendaName } from "components/buyer-rfqs/buyer-rfqs-information/rfq-addenda";
import JSZip from "jszip";

type PartFileInfoType = {
  partName?: string;
  fileUrl?: string;
};

export const getPartFiles = (parts: ProjectCompanyPart[]) => {
  const files: {
    partName: string;
    fileUrl: string;
  }[] = [];

  // eslint-disable-next-line @typescript-eslint/no-unused-expressions
  parts?.forEach((part) => {
    part.part_uploads_list.forEach((upload) => {
      files.push({
        fileUrl: upload.file_url,
        partName: part.name
      });
    });
  });

  return files;
};

export const getAttachmentFiles = (attachments: FileData[]) => {
  const files: PartFileInfoType[] = [];

  // eslint-disable-next-line @typescript-eslint/no-unused-expressions
  attachments?.forEach((attachment) => {
    files.push({
      fileUrl: attachment.file_url,
      partName: "Attachments"
    });
  });

  return files;
};

// Download all part files on one click
export const useOnClickDownloadAllFE = (rfqFilesInfos: Record<string, any>) => {
  const partUploadsList = useMemo(() => {
    let files = getPartFiles(rfqFilesInfos?.parts);
    if (rfqFilesInfos?.addenda) {
      files = files.concat(
        rfqFilesInfos.addenda.reduce(
          (acc: PartFileInfoType[], item: Addenda, i: number) =>
            acc.concat(
              item.files?.map((file: FileData) => ({
                fileUrl: file.file_url,
                partName: getAddendaName(item, rfqFilesInfos.addenda.length - i)
              })) || []
            ),
          []
        )
      );
    }
    return files;
  }, [rfqFilesInfos?.parts, rfqFilesInfos?.addenda]);
  const attachments = useMemo(
    () => getAttachmentFiles(rfqFilesInfos?.attachments),
    [rfqFilesInfos?.attachments]
  );

  const tracking = useTracking();
  const { company, user } = useSelector((state: RootState) => state.profile);

  return () => {
    const zip = new JSZip();
    const partFilesMap: Record<string, any> = {};

    //return promise with list of files url and part name
    const downloadFile = (partFileInfo: PartFileInfoType) =>
      new Promise((resolve) => {
        if (!partFileInfo?.fileUrl) return resolve(false);

        fetch(partFileInfo.fileUrl)
          .then((resp) => resp.blob())
          .then((blob: any) => {
            let filename = `file`;
            try {
              filename =
                (partFileInfo?.fileUrl || "")
                  .split("/")
                  ?.pop()
                  ?.split("?")?.[0] || "";
            } catch (e) {}
            blob.name = filename;
            if (!partFileInfo?.partName) return resolve(false);

            if (!partFilesMap[partFileInfo.partName]) {
              partFilesMap[partFileInfo.partName] = [];
            }
            partFilesMap[partFileInfo.partName].push({
              filename,
              blob
            });
            resolve(true);
          });
      });

    const promises: Promise<any>[] = [];

    partUploadsList.forEach((partFileInfo) => {
      promises.push(downloadFile(partFileInfo));
    });

    attachments.forEach((attachment) => {
      promises.push(downloadFile(attachment));
    });

    //Return zip file with all folders containing files for each part
    Promise.all(promises).then(() => {
      Object.keys(partFilesMap).map((partName, index) => {
        partFilesMap[partName].forEach(
          (fileInfo: { filename: string; blob: Blob }) => {
            zip.folder(partName).file(fileInfo.filename, fileInfo.blob);
          }
        );
        if (Object.keys(partFilesMap).length - 1 === index) {
          zip.generateAsync({ type: "blob" }).then((content: string | Blob) => {
            saveAs(
              content,
              `${
                rfqFilesInfos["rfq_info"]
                  ? rfqFilesInfos["rfq_info"]["rfq_display_name"]
                  : rfqFilesInfos["rfq_display_name"]
              }.zip`
            );
          });
        }
      });
    });

    tracking.trackEvent({
      scout_category: "download",
      scout_feature_name: "download_all_files_as_ZIP",
      scout_company: company["name"],
      scout_username: user["username"],
      scout_user_type: user["type"],
      scout_rfq_pk: rfqFilesInfos["rfq_info"]
        ? rfqFilesInfos["rfq_info"]["pk"]
        : rfqFilesInfos["pk"],
      scout_rfq_name: rfqFilesInfos["rfq_info"]
        ? rfqFilesInfos["rfq_info"]["name"]
        : rfqFilesInfos["rfq_display_name"],
      scout_part_name: partUploadsList.map((part) => part.partName),
      scout_file_name: partUploadsList.map((part) => part.fileUrl),
      scout_date: new Date()
    });
  };
};

export const useOnClickDownloadAll = (rfqFilesInfos: Record<string, any>) => {
  return () => {
    if (!rfqFilesInfos?.download_all_url) return;
    window.open(rfqFilesInfos?.download_all_url, "_blank", "noreferrer");
  };
};
