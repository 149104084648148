import { Box, Divider, IconButton } from "@mui/material";
import React, { useCallback } from "react";
import { useStyles } from "./rfq-creation.form.styles";
import CloseIcon from "@mui/icons-material/Close";
import { DashboardCardIcon } from "@next/modules/dashboard/components/buyer-dashboard-card/buyer-dashboard-card-icon";
import EditIcon from "@mui/icons-material/Edit";
import { AppTheme } from "layout/AppTheme";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { modalsActions } from "@next/redux/modalsSlices";
import { WorkspaceModalTypes } from "../../modals/types";
import { RFQDeleteConfirmationModal } from "../../modals/rfq-delete-confirmation-modal";
import { selectActiveDraftRFQ } from "../../redux/selectors";
import { AutoSavedStatus } from "@next/components/auto-saved-status/auto-saved-status";
import { Field } from "formik";
import { RequestTypeDropdown } from "./request-type-dropdown";
import CollaboratorsList from "components/workspace/buyer-rfqs-view/collaborators-list";
import { getUser } from "services/profile/profile.selectors";

const RfqFormHeader: React.FC = () => {
  const { t } = useTranslation();
  const classes = useStyles();
  const dispatch = useDispatch();
  const activeDraft = useSelector(selectActiveDraftRFQ);
  const user = useSelector(getUser);

  const onClose = useCallback(() => {
    dispatch(modalsActions.closeModal(WorkspaceModalTypes.RFQ_CREATION_DRAWER));
  }, []);

  return (
    <div className={classes.header}>
      <Box
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        px={2}
        py={1.5}
      >
        <Box className={classes.headerLeftContainer}>
          <Box>
            <DashboardCardIcon iconColor={AppTheme.palette.primary.main}>
              <EditIcon />
            </DashboardCardIcon>
          </Box>
          <Field
            name="request_type"
            component={RequestTypeDropdown}
            size="small"
            label={t("workspaceNext:rfqDrawer:requestType")}
          />
          <CollaboratorsList
            data={{
              collaborators: activeDraft?.collaborators,
              draftId: activeDraft?.id,
              createdBy: activeDraft?.user || user,
            }}
            size="large"
            onRfqForm
            disableShareCollaboratorOnRfqForm={!activeDraft}
            canAddCollaborators={true}
          />
        </Box>

        <Box display="flex" alignItems="center">
          {activeDraft?.updated_at ? (
            <AutoSavedStatus
              updated_at={activeDraft?.updated_at}
              showDate={false}
            />
          ) : null}

          <IconButton onClick={onClose} size="large">
            <CloseIcon className={classes.rfqDeleteIcon} />
          </IconButton>
        </Box>
      </Box>
      <Divider />
      <RFQDeleteConfirmationModal />
    </div>
  );
};

export default RfqFormHeader;
