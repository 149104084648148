import React, { useState } from "react";
import { Button, Menu, MenuItem, Tooltip } from "@mui/material";
import { GetApp } from "@mui/icons-material";
import { useTranslation } from "react-i18next";
import { withStyles } from "@mui/styles";

type Props = {
  onExport: () => void;
  buttonTitle: string;
  exportDisabled?: boolean;
};

const HtmlTooltip = withStyles(() => ({
  tooltip: {
    maxWidth: 300,
    fontSize: 12,
    marginTop: 5,
    textAlign: "center",
  },
}))(Tooltip);

export const CustomGridToolbarExport: React.FC<Props> = ({
  onExport,
  buttonTitle,
  exportDisabled,
}) => {
  const { t } = useTranslation();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <HtmlTooltip
        title={
          exportDisabled
            ? t("rfq:quotations:toolTipExportQuotationsPDFDisabled")
            : ""
        }
      >
        <span>
          <Button
            aria-controls="grid-toolbar-export"
            aria-haspopup="true"
            startIcon={<GetApp style={{ fontSize: "18px" }} />}
            onClick={handleClick}
            disabled={exportDisabled}
          >
            {t("common:export")}
          </Button>
          <Menu
            id="grid-toolbar-export"
            anchorEl={anchorEl}
            keepMounted
            open={Boolean(anchorEl)}
            onClose={handleClose}
          >
            <MenuItem onClick={onExport}>{buttonTitle}</MenuItem>
          </Menu>
        </span>
      </HtmlTooltip>
    </>
  );
};

export default CustomGridToolbarExport;
