import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import Modal from "@next/components/custom-modal";
import { getModal, modalsActions } from "@next/redux/modalsSlices";
import { OrdersModalTypes } from "./types";
import { ordersActions } from "../redux";
import { selectUpdatePortalOrderAcknowledgementLoading } from "../redux/selectors";
import { ReviewOrderForm } from "../components/review-order-form/review-order-form";
import { ReviewOrderFormState } from "../components/review-order-form/review-order-form.definitions";
import { useQueryParam } from "@next/hooks/useQuery";
import { RightPanelTabs } from "../components/order-detail/order-detail-right-tabs/order-detail-right-tabs";

export const AcknowledgeOrderModal = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const updateUpdatePortalOrderAcknowledgementLoading = useSelector(
    selectUpdatePortalOrderAcknowledgementLoading
  );
  const [_rightPanelTab, setRightPanelTab] = useQueryParam("panelTab");
  const modal = useSelector(getModal(OrdersModalTypes.ACKNOWLEDGE_ORDER_MODAL));
  const data = modal?.data as {
    orderToken?: string;
    update_note?: string;
    updated_delivery_dates: any[];
    buyerName?: string;
  };

  if (!modal || !data?.orderToken) return null;

  const onClose = () => {
    dispatch(
      modalsActions.closeModal(OrdersModalTypes.ACKNOWLEDGE_ORDER_MODAL)
    );
  };

  const onClickSave = (values: ReviewOrderFormState) => {
    dispatch(
      ordersActions.updatePortalOrderAcknowledgementRequest({
        orderToken: data?.orderToken,
        update_note: values?.update_note,
        confirmation_file: values?.confirmation_file,
        onSuccess: () => {
          setRightPanelTab(RightPanelTabs.SUPPLIER_CHAT);
        },
      })
    );
  };

  return (
    <Modal
      handleModalClose={onClose}
      modalState={{
        modalHeader: t("orders:orderDetail:acknowledgeOrderModal:header"),
        modalBody: (
          <ReviewOrderForm
            buttonText={t(
              "orders:orderDetail:acknowledgeOrderModal:buttonText"
            )}
            description={
              data?.updated_delivery_dates?.length
                ? t("orders:orderDetail:acknowledgeOrderModal:description")
                : t(
                    "orders:orderDetail:acknowledgeOrderModal:descriptionWithoutChange",
                    {
                      buyer_company_name: data?.buyerName,
                    }
                  )
            }
            onClose={onClose}
            onSubmit={onClickSave}
            previewLineItemChanges={false}
            loading={updateUpdatePortalOrderAcknowledgementLoading}
            isPortal={true}
            asModal={true}
            notePlaceholder={t("orders:addNoteWithApproval")}
          />
        ),
      }}
      isModalOpen={true}
      disableContentPadding
    />
  );
};
