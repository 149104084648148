import React from "react";
import { useParams } from "react-router-dom";

import { usePublicOrderPortalData } from "./order-detail-portal-page.hooks";
import PageLoader from "@next/components/page-loader/page-loader";

import { OrderDetailBasePage } from "../order-detail-base-page/order-detail-base-page";
import { Box } from "@mui/material";
import { useRemoveRootOverflow } from "@next/hooks/useRemoveRootOverflow";

export const OrderDetailPortalPage = () => {
  const params = useParams<{ orderToken?: string; qaToken?: string }>();

  const { portalOrderData, portalOrderLoading } = usePublicOrderPortalData(
    params?.orderToken
  );

  useRemoveRootOverflow({
    skip: false,
  });

  if (portalOrderLoading || !portalOrderData) {
    return <PageLoader />;
  }

  return (
    <Box p="24px" pb="0px">
      {/* Temporarily disabled SupplierPortalLineItemCards component due to ticket # 6422 */}
      {/* <Box display="flex" alignItems="center">
        <SupplierPortalLineItemCards />
      </Box> */}

      <Box mt="64px">
        <OrderDetailBasePage isPortal={true} />
      </Box>
    </Box>
  );
};
