import React from "react";

import Moment from "react-moment";
import { openBuyerProfileNewTab } from "@next/utils/browserUtils";

import { Avatar, Box, CardContent, Link, Typography } from "@mui/material";

import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import SvgIcon from "@mui/material/SvgIcon";
import i18n from "assets/configi18n/i18n";

export const BuyerStatsCompanyInfos = ({
  buyerStats: {
    company_pk,
    company_name,
    company_picture,
    last_seen_date,
    is_online,
  },
  t,
  language,
}) => {
  const onClickOpenProfile = (buyerProfileId) => {
    openBuyerProfileNewTab(buyerProfileId);
  };

  return (
    <CardContent className="c-buyer-stats-company-infos">
      <Box ml={-0.5} mt={-1} mb={1}>
        <Typography color="textSecondary" variant="body1">
          {t("company")}
        </Typography>
      </Box>
      <Box display="flex" alignItems="center">
        {company_picture ? (
          <Box>
            <Avatar
              src={company_picture}
              alt="company picture"
              className="c-buyer-stats-company-infos--logo"
            />
          </Box>
        ) : (
          <Box>
            <Avatar className="c-buyer-stats-company-infos--logo"></Avatar>
          </Box>
        )}
        <Box ml={1.6} className="c-buyer-stats-company-infos--container">
          <Box p={1} display="flex" alignItems="center">
            {company_name && (
              <Box
                display="flex"
                className="c-buyer-stats-company-infos--company-name"
              >
                <Link
                  color="inherit"
                  underline="always"
                  style={{ cursor: "pointer" }}
                  onClick={() => onClickOpenProfile(company_pk)}
                >
                  <Box
                    fontSize="h6.fontSize"
                    fontWeight="fontWeightBold"
                  >{`${company_name}`}</Box>
                </Link>
                <Box mt={0.2}>
                  <SvgIcon className="c-buyer-stats-company-infos--color-blue">
                    <path d="M23,12l-2.44-2.79l0.34-3.69l-3.61-0.82L15.4,1.5L12,2.96L8.6,1.5L6.71,4.69L3.1,5.5L3.44,9.2L1,12l2.44,2.79l-0.34,3.7 l3.61,0.82L8.6,22.5l3.4-1.47l3.4,1.46l1.89-3.19l3.61-0.82l-0.34-3.69L23,12z M10.09,16.72l-3.8-3.81l1.48-1.48l2.32,2.33 l5.85-5.87l1.48,1.48L10.09,16.72z" />
                  </SvgIcon>
                </Box>
              </Box>
            )}
          </Box>
          <Box
            p={1}
            display="flex"
            alignItems="center"
            className="c-buyer-stats-company-infos--online"
          >
            <Box pb={1}>
              <FiberManualRecordIcon
                className={`c-buyer-stats-company-infos--record-icon ${
                  is_online ? "color-green" : "color-grey"
                }`}
              />
            </Box>
            <Box pb={1}>
              {is_online ? (
                <Box className="c-buyer-stats-company-infos--span">
                  {t("online")}
                </Box>
              ) : (
                <Box className="c-buyer-stats-company-infos--span">
                  {`${t("online")}`}{" "}
                  <Moment locale={i18n.language} fromNow>
                    {last_seen_date}
                  </Moment>{" "}
                </Box>
              )}
            </Box>
          </Box>
        </Box>
      </Box>
    </CardContent>
  );
};
