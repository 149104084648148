import React from "react";
import { batch, useDispatch, useSelector } from "react-redux";
import { Formik } from "formik";
import { modalsActions } from "@next/redux/modalsSlices";
import { WorkspaceModalTypes } from "../../modals/types";
import AddSupplierFormBase from "./add-supplier.form.base";
import { projectActions } from "@next/modules/project/redux";
import { getRfqDetails } from "services/workspace/workspace.selectors";
import { workspaceActions } from "services/workspace";
import { FormValues } from "./add-supplier-form.types";
import { workspaceNextActions } from "../../redux";

const AddSuppliersForm: React.FC = () => {
  const rfqDetails = useSelector(getRfqDetails);
  const dispatch = useDispatch();

  const initialValues: FormValues = {
    targeted_suppliers: [],
  };

  const handleContactSuppliers = (values: FormValues) => {
    dispatch(
      projectActions.updateRFQRequest({
        pk: rfqDetails.pk,
        targeted_suppliers: values.targeted_suppliers.map((item) => {
          return {
            id: item.pk,
            target_profiles: item.target_profiles
              ?.map((profile) => profile.id)
              .filter((id): id is number => id !== undefined),
          };
        }),
        onSuccess: (rfq) => {
          batch(() => {
            dispatch(
              modalsActions.closeModal(WorkspaceModalTypes.ADD_SUPPLIERS_MODAL)
            );
            dispatch(workspaceActions.setRFQDetails(rfq));
            dispatch(
              workspaceNextActions.fetchRFQQuestionsStatsRequest({
                rfqId: rfq.pk.toString(),
              })
            );
          });
        },
      })
    );
  };

  return (
    <Formik
      render={(props) => (
        <AddSupplierFormBase {...props} rfqDetails={rfqDetails} />
      )}
      initialValues={initialValues}
      onSubmit={handleContactSuppliers}
    />
  );
};

export default AddSuppliersForm;
