import React from "react";
import { useDispatch } from "react-redux";
import { Formik } from "formik";

import {
  initialValues,
  validationSchema,
} from "./ask-question-form.definitions";

import { AskQuestionFormBase } from "./ask-question-form.base";
import {
  CreateTopicInput,
  workspaceNextActions,
} from "@next/modules/workspace/redux";

type Props = {
  rfqId: string;
  buyerName: string;
};

const AskQuestionForm: React.FC<Props> = ({ rfqId, buyerName }) => {
  const dispatch = useDispatch();

  const onSubmit = (values: Partial<CreateTopicInput>) => {
    dispatch(
      workspaceNextActions.createQATopicRequest({
        ...values,
        rfqId,
      })
    );
  };

  return (
    <div className="c-ask-question-form">
      <Formik
        initialValues={initialValues}
        onSubmit={onSubmit}
        validationSchema={validationSchema}
      >
        {(props) => <AskQuestionFormBase buyerName={buyerName} {...props} />}
      </Formik>
    </div>
  );
};

export default AskQuestionForm;
