import React, { useState } from "react";
import { Box, Link, Theme } from "@mui/material";
import { Trans, useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { createStyles, makeStyles } from "@mui/styles";
import { selectProjectStats } from "../../redux/selectors";
import excelIcon from "@next/assets/excelIcon.png";
import folderIcon from "@next/assets/folderIcon.png";
import { projectActions } from "../../redux";
import MultipleDNDFileUpload from "@next/components/multiple-dnd-file-upload/multiple-dnd-file-upload";
import { fileStateToZip } from "../bulk-file-import/bulk-file-import-upload-files.utils";
import JSZip from "jszip";
import { modalsActions } from "@next/redux/modalsSlices";
import { ProjectModalTypes } from "../../modals/types";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    fileUploadContainer: {
      display: "flex",
      padding: theme.spacing(2),
      gap: theme.spacing(3),
      alignItems: "center",
      width: "100%",
      borderTop: `1px solid ${theme.palette.divider}`,
      borderBottom: `1px solid ${theme.palette.divider}`,
    },
  })
);

type Props = {
  onImport: (fileResults?: any) => void;
};

export const ProjectPartBulkImport: React.FC<Props> = ({ onImport }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const classes = useStyles();
  const projectStats = useSelector(selectProjectStats);

  const [bulkFiles, setBulkFiles] = useState<any[]>([]);
  const [fileItem, setFileItem] = useState<any[]>([]);

  const filesZip = new JSZip();

  const handleBulkFilesImport = (bulkFiles: any) => {
    const files = bulkFiles.map((file) => file?.file);
    fileStateToZip(filesZip, files, (zippedFile: any) => {
      if (projectStats?.pk) {
        dispatch(projectActions.resetBomFilesUploadReport());
        dispatch(
          projectActions.uploadBomFilesRequest({
            file: zippedFile,
            projectId: projectStats?.pk,
            attach_unmatched_files: true,
          })
        );

        dispatch(
          modalsActions.showModal({
            key: ProjectModalTypes.BULK_FILE_IMPORT_MODAL,
            data: {
              filesCount: bulkFiles.length,
            },
          })
        );

        setBulkFiles([]);
      }
    });
  };

  const handleFileUpload = (files: any[]) => {
    onImport(files[0]?.file);
    setFileItem([]);
  };

  return (
    <Box className={classes.fileUploadContainer}>
      <MultipleDNDFileUpload
        filesState={fileItem}
        setFilesState={(files) => handleFileUpload(files)}
        compactMode={true}
        disableDndMargin={true}
        showUploadList={false}
        showSecureInfo={false}
        multiple={false}
        fileFormats={["xlsx", "xls"]}
        centerInnnerContent={false}
        customText={t(
          "workspaceNext:rfqDrawer:partsAndFilesModal:supportedFiles"
        )}
        customDragAndDropText={
          <Trans
            i18nKey="project:bulkFileImport:dragDropItemList"
            components={[<Link style={{ cursor: "pointer" }}>Browse</Link>]}
          />
        }
        customStartIcon={excelIcon}
      />
      <MultipleDNDFileUpload
        filesState={bulkFiles}
        setFilesState={(files) => handleBulkFilesImport(files)}
        compactMode={true}
        disableDndMargin={true}
        showUploadList={false}
        showSecureInfo={false}
        centerInnnerContent={false}
        customText={t("project:bulkFileImport:bulkFilesImportDescription")}
        customStartIcon={folderIcon}
      />
    </Box>
  );
};
