import React, { useEffect, useRef, useState } from "react";
import { Box, Divider, TextField } from "@mui/material";
import { AddOutlined, DeleteOutlined } from "@mui/icons-material";
import { useDispatch, useSelector } from "react-redux";
import { NoteType, workspaceNextActions } from "@next/modules/workspace/redux";
import {
  selectInternalNotesList,
  selectInternalNotesMutateLoading,
} from "@next/modules/workspace/redux/selectors";
import CustomButton from "@next/components/custom-button/custom-button";
import { v4 as uuid, validate } from "uuid";
import { t } from "assets/configi18n/i18n";
import { createStyles, makeStyles } from "@mui/styles";
import { ColorPickerDropDown } from "../color-picker-dropdown/color-picker-dropdown";
import { colorOptions } from "@next/utils/constantUtils";
import { selectOrdersLastQuery } from "@next/modules/orders/redux/selectors";
import { ordersActions } from "@next/modules/orders/redux";
import { selectProjectCompanyOrdersLastQuery } from "@next/modules/project/redux/selectors";
import { projectActions } from "@next/modules/project/redux";
import { workspaceActions } from "services/workspace";
import { modalsActions } from "@next/redux/modalsSlices";
import { SharedModalTypes } from "@next/modals/types";

const useStyles = makeStyles(() =>
  createStyles({
    addTag_listing: {
      maxHeight: "calc(100vh - 25rem)",
      maxWidth: "472px",
      overflow: "auto",
      marginRight: "-20px",
      paddingRight: "24px",
    },
    rowWrapper: {
      display: "flex",
      justifyContent: "center",
      gap: "16px",
      alignItems: "center",
      marginBottom: "24px",
    },
    customDividerStyle: {
      margin: "24px -24px",
    },
  })
);
type Props = {
  moduleType: string;
  isDetailPage: boolean;
  paramID?: number;
};

export const AddInternalNotesModal: React.FC<Props> = ({
  moduleType,
  isDetailPage,
  paramID,
}) => {
  const ordersLastQuery = useSelector(selectOrdersLastQuery);
  const internalNotes = useSelector(selectInternalNotesList);
  const uploadNoteLoading = useSelector(selectInternalNotesMutateLoading);
  const projectCompanyOrdersLastQuery = useSelector(
    selectProjectCompanyOrdersLastQuery
  );

  const dispatch = useDispatch();
  const containerRef = useRef(null);
  const classes = useStyles();

  const newRow = {
    id: uuid(),
    color: colorOptions["lightgrey"],
    title: t("project:table:row:internalNotes:newTag"),
    note: "",
    module: moduleType,
    isNewlyAdded: true,
  };
  const [notesList, setNotesList] = useState<NoteType[]>(internalNotes);
  const [notesToRemove, setNotesToRemove] = useState<number[]>([]);
  const [canSubmit, setCanSubmit] = useState<boolean>(true);
  const [handleNewRowRef, setHandleNewRowRef] = useState<boolean>(true);

  useEffect(() => {
    //this will automatically scroll to the newly added row
    if (containerRef.current) {
      containerRef.current.scrollTop = containerRef.current.scrollHeight;
    }
  }, [handleNewRowRef]);

  const validateUUID = (id: string): boolean => {
    return validate(id);
  };

  const removeNotesRows = (deletedNoteId: string | number) => {
    // update internal note list
    setNotesList((old_notes: NoteType[]) =>
      old_notes.filter((note) => note.id !== deletedNoteId)
    );
    setCanSubmit(false);
    // append internal note id in removed notes id list only if it is an integer value
    // Notes with UUID does not exist on backend yet. These are newly created notes.
    if (
      typeof deletedNoteId !== "string" &&
      !validateUUID(deletedNoteId as unknown as string)
    ) {
      setNotesToRemove((old_ids) => [...old_ids, deletedNoteId]);
    }
  };

  const updateColor = (id: string, color: string) => {
    setNotesList((rows) =>
      rows.map((row) =>
        row.id === id ? { ...row, color, isModified: !validateUUID(id) } : row
      )
    );
    setCanSubmit(false);
  };
  const updateTitle = (id: string, title: string) => {
    setNotesList((rows) =>
      rows.map((row) =>
        row.id === id ? { ...row, title, isModified: !validateUUID(id) } : row
      )
    );
    setCanSubmit(false);
  };

  const handleDeleteNote = (note: NoteType) => {
    if (note.total_usage && note.total_usage > 0) {
      dispatch(
        modalsActions.showModal({
          key: SharedModalTypes.INTERNAL_NOTES_DELETE_TAG_MODAL,
          data: {
            noteTitle: note.title,
            noteId: note.id,
            noteUsageCount: note.total_usage,
            removeNotesRows,
          },
        })
      );
    } else {
      removeNotesRows(note.id);
    }
  };

  const saveInternalNotes = () => {
    const note_payload = notesList
      .filter((item) => item.isModified || item.isNewlyAdded)
      .map((tag) => {
        // Check and remove local uuid else pass the id in payload to modify the tag
        const isUUID = !validate(tag.id);
        const { id, isModified, isNewlyAdded, ...noteData } = tag;
        if (isUUID) {
          return noteData;
        }
        return { ...noteData, id };
      });
    dispatch(
      workspaceNextActions.internalNotesMutateRequest({
        notes: note_payload,
        deleted_notes: notesToRemove,
        moduleType,
        onSuccess: () => {
          const payload = { pk: paramID };
          if (moduleType == "PO") {
            if (isDetailPage) {
              dispatch(ordersActions.fetchOrderRequest(payload as any));
              return;
            } else {
              dispatch(
                ordersActions.fetchOrdersRequest({
                  query: ordersLastQuery,
                })
              );
            }
          }
          // in RFQ Case
          else {
            if (isDetailPage) {
              dispatch(workspaceActions.fetchRFQDetails(paramID, true));
              return;
            }
            dispatch(
              projectActions.fetchProjectCompanyOrdersRequest({
                query: projectCompanyOrdersLastQuery,
              })
            );
          }
        },
      })
    );
  };

  return (
    <>
      <Box className={classes.addTag_listing} ref={containerRef}>
        {notesList?.map((note: NoteType) => (
          <Box id={note.id} className={classes.rowWrapper}>
            <ColorPickerDropDown
              colorOptions={colorOptions}
              selectedColor={note.color}
              setSelectedColor={(color: string) => updateColor(note.id, color)}
            />
            <Box>
              <TextField
                inputProps={{
                  maxLength: 100,
                }}
                sx={{ width: "272px" }}
                size="small"
                onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) => {
                  updateTitle(note.id, e.target.value);
                }}
                value={note.title}
              />
            </Box>
            <DeleteOutlined
              sx={{ cursor: "pointer" }}
              onClick={() => handleDeleteNote(note)}
            />
          </Box>
        ))}
      </Box>
      <Divider className={classes.customDividerStyle} />
      <Box display="flex" justifyContent="space-between">
        <CustomButton
          startIcon={<AddOutlined />}
          variant="outlined"
          onClick={() => {
            setHandleNewRowRef((prevState) => !prevState);
            setNotesList((rows) => [...rows, newRow]);
            setCanSubmit(false);
          }}
        >
          {t("project:table:row:internalNotes:addTag")}
        </CustomButton>
        <CustomButton
          disabled={canSubmit}
          loading={uploadNoteLoading}
          variant="contained"
          color="primary"
          onClick={saveInternalNotes}
        >
          {" "}
          {t("common:save")}
        </CustomButton>
      </Box>
    </>
  );
};
