import { Box, Divider } from "@mui/material";
import CustomButton from "@next/components/custom-button";
import React, { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useStyles } from "./rfq-creation.form.styles";
import {
  selectActiveDraftRFQ,
  selectFileUploading,
} from "../../redux/selectors";
import { modalsActions } from "@next/redux/modalsSlices";
import { WorkspaceModalTypes } from "../../modals/types";
import RfqFollowupButton from "../rfq-followup-button";
import { useFormikContext } from "formik";
import { RFQFormValuesType } from "./rfq-creation.form.types";
import SendIcon from "@mui/icons-material/Send";
import DeleteIcon from "@mui/icons-material/Delete";
import { useHasCompanyRole } from "@next/hooks/useHasCompanyRole";
import { getUser } from "services/profile/profile.selectors";

interface Props {
  onSubmit?: () => void;
}

const Footer: React.FC<Props> = (props) => {
  const { onSubmit } = props;
  const { values, setFieldValue } = useFormikContext<RFQFormValuesType>();
  const hasRestrictedViewerRole = useHasCompanyRole(["restricted_viewer"]);
  const user = useSelector(getUser);

  const { t } = useTranslation();
  const classes = useStyles();
  const rfqCreationLoading = useSelector(
    (state: Record<string, any>) => state.workspace.rfqCreationLoading
  );
  const fileUploading = useSelector(selectFileUploading);
  const activeDraft = useSelector(selectActiveDraftRFQ);
  const dispatch = useDispatch();

  const userCanDeleteDraft =
    activeDraft?.updated_at &&
    (!hasRestrictedViewerRole ||
      (hasRestrictedViewerRole && activeDraft?.user?.id === user?.id));

  const onClickDelete = useCallback(() => {
    dispatch(
      modalsActions.showModal({
        key: WorkspaceModalTypes.RFQ_DELETE_CONFIRMATION_MODAL,
      })
    );
  }, []);

  if (!userCanDeleteDraft) {
    return null;
  }

  return (
    <div className={classes.footer}>
      <Divider />
      <Box
        m={2}
        display="flex"
        justifyContent="space-between"
        alignItems="center"
      >
        {userCanDeleteDraft ? (
          <CustomButton
            variant="outlined"
            color="error"
            startIcon={<DeleteIcon />}
            onClick={onClickDelete}
            size="large"
          >
            {t("workspaceNext:rfqDrafts:deleteDraftRFQ")}
          </CustomButton>
        ) : (
          <Box />
        )}

        <Box display="flex" alignItems="center">
          <Box mr={hasRestrictedViewerRole ? 0 : 1}>
            <RfqFollowupButton
              value={values.followup_date || null}
              onChange={(value) => {
                setFieldValue("followup_date", value || null);
              }}
            />
          </Box>

          {hasRestrictedViewerRole ? null : (
            <CustomButton
              disabled={fileUploading}
              loading={rfqCreationLoading}
              startIcon={<SendIcon />}
              onClick={onSubmit}
              type="submit"
              variant="contained"
              color="primary"
              size="large"
            >
              {t("workspaceNext:rfqDrawer:send")}
            </CustomButton>
          )}
        </Box>
      </Box>
    </div>
  );
};

export default Footer;
