import React from "react";
import {
  GridColDef,
  GridColumnHeaderParams,
  GridRenderCellParams,
} from "@mui/x-data-grid-pro-v5";
import { EditableTemplateCell } from "../table-cells/editable-template-cell";
import { HeaderCell } from "../table-cells/header-cell";

const defaultColumnConfig: Partial<GridColDef> = {
  flex: 0.5,
  sortable: false,
  filterable: false,
  disableColumnMenu: true,
  editable: false,
};

export const getBufferStockStrategyFormTableColumns = () => {
  const columns: GridColDef[] = [
    {
      ...defaultColumnConfig,
      field: "name",
      headerName: "Airbus specification",
    },
    {
      ...defaultColumnConfig,
      field: "dash_ref",
      headerName: "Dash Ref",
      renderCell: (params) => {
        const value = params.value;
        return value === "nan" ? "n/a" : value;
      },
    },
    {
      ...defaultColumnConfig,
      field: "revision",
      headerName: "Revision",
    },
    {
      ...defaultColumnConfig,
      field: "controlled_process",
      headerName: "Controlled process",
    },
    {
      ...defaultColumnConfig,
      field: "description",
      headerName: "Description",
    },
    {
      ...defaultColumnConfig,
      field: "source_of_supply",
      headerName: "Source of supply",
    },
    {
      ...defaultColumnConfig,
      field: "manufacturing_site",
      headerName: "Manufacturing site",
    },
    {
      ...defaultColumnConfig,
      field: "ao_x_stock",
      headerName: "A0x/A2Ms stock",
      headerAlign: "left" as const,
      type: "number",
      editable: true,
      renderHeader: (params: GridColumnHeaderParams) => (
        <HeaderCell {...params} required={true} />
      ),
      renderCell: (params: GridRenderCellParams) => (
        <EditableTemplateCell {...params} placeholderText="Type a number..." />
      ),
    },
    {
      ...defaultColumnConfig,
      field: "bo_x_stock",
      headerName: "B0x/BAMs stock",
      headerAlign: "left" as const,
      type: "number",
      editable: true,
      renderHeader: (params: GridColumnHeaderParams) => (
        <HeaderCell {...params} required={true} />
      ),
      renderCell: (params: GridRenderCellParams) => (
        <EditableTemplateCell {...params} placeholderText="Type a number..." />
      ),
    },
  ];

  return columns;
};
