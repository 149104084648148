import * as yup from "yup";
import { t } from "assets/configi18n/i18n";
import { AnswerTopicInput } from "@next/modules/workspace/redux";

export const initialValues: Partial<AnswerTopicInput> = {
  answer: "",
  firstName: "",
  lastName: "",
};

export const validationSchema = (requiresName: boolean) => {
  const schema: Record<string, yup.Schema<any>> = {
    answer: yup
      .string()
      .required(
        t("project:rfqDrawer:rfqForm:errorMessage:thisFieldIsRequired")
      ),
  };
  if (requiresName) {
    schema.firstName = yup
      .string()
      .required("project:rfqDrawer:rfqForm:errorMessage:thisFieldIsRequired");
    schema.lastName = yup
      .string()
      .required("project:rfqDrawer:rfqForm:errorMessage:thisFieldIsRequired");
  }
  return yup.object(schema);
};
