import React, { useCallback } from "react";
import ConfirmationDialog from "@next/components/confirmation-dialog/confirmation-dialog";
import { useTranslation } from "react-i18next";
import { Box, Button, Theme } from "@mui/material";
import { batch, useDispatch, useSelector } from "react-redux";
import { getModal, modalsActions } from "@next/redux/modalsSlices";
import { WorkspaceModalTypes } from "@next/modules/workspace/modals/types";
import {
  selectActiveDraftRFQ,
  selectDeleteDraftRFQLoading,
} from "../redux/selectors";
import { workspaceNextActions } from "../redux";
import CustomButton from "@next/components/custom-button";
import { useAxyaTracking } from "@next/hooks/useAxyaTracking";
import { createStyles, makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    deleteBtn: {
      backgroundColor: theme.palette.error.main,
      color: theme.palette.common.white,
      "&:hover": {
        backgroundColor: theme.palette.error.dark,
      },
    },
    actionBtn: {
      textTransform: "unset",
    },
  })
);

export const RFQDeleteConfirmationModal: React.FC = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const modal = useSelector(
    getModal(WorkspaceModalTypes.RFQ_DELETE_CONFIRMATION_MODAL)
  );
  const activeDraft = useSelector(selectActiveDraftRFQ);
  const deleteLoading = useSelector(selectDeleteDraftRFQLoading);
  const classes = useStyles();
  const axyaTracking = useAxyaTracking();

  const closeModal = () => {
    batch(() => {
      dispatch(
        modalsActions.closeModal(
          WorkspaceModalTypes.RFQ_DELETE_CONFIRMATION_MODAL
        )
      );
    });
  };

  const onDelete = useCallback(() => {
    if (activeDraft) {
      dispatch(workspaceNextActions.deleteDraftRFQRequest(activeDraft.id));
    }
    axyaTracking({
      scout_category: "axya-buyer-draft-rfqs",
      scout_feature_name: "delete-existing-draft-rfq",
    });
  }, [activeDraft]);

  return (
    <ConfirmationDialog
      title={t("workspaceNext:rfqDrawer:deleteConfirmationModal:title")}
      isOpen={!!modal}
      handleClose={closeModal}
      dialogActions={
        <Box display="flex" mb={2} mr={2}>
          <Button
            className={classes.actionBtn}
            variant="outlined"
            autoFocus
            onClick={closeModal}
          >
            {t("workspaceNext:rfqDrawer:deleteConfirmationModal:cancel")}
          </Button>
          <Box ml={1.25}>
            <CustomButton
              className={`${classes.actionBtn} ${classes.deleteBtn}`}
              onClick={onDelete}
              variant="contained"
              loading={deleteLoading}
            >
              {t("workspaceNext:rfqDrawer:deleteConfirmationModal:delete")}
            </CustomButton>
          </Box>
        </Box>
      }
    />
  );
};
