export const normalizeQuatationFields = (quotationExtraFields: any) => {
  if (quotationExtraFields.data)
    quotationExtraFields = quotationExtraFields.data;

  let normalizedData = [];

  try {
    if (typeof quotationExtraFields === "string") {
      normalizedData = JSON.parse(
        quotationExtraFields
          .replace(/'/g, '"')
          .replace(/True/g, "true")
          .replace(/False/g, "false")
      );
    } else if (Array.isArray(quotationExtraFields))
      normalizedData = quotationExtraFields;
  } catch (error) {
    console.error(error);
    normalizedData = [];
  }

  return normalizedData;
};
