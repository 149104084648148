/*
 *   Online documentation: NO DOCUMENTATION
 *   Online prototype: NO PROTOTYPE
 *
 *   In this file you can find the reset password form which is rendered once the user click
 *   on the link that is sent once your recover an account.
 *   This is currently modeled from the LoginView so it is subject to change.
 */

// General Imports
import React from "react";
import { useDispatch } from "react-redux";
// Image Assets
import logo from "../../../src/assets/img/logo-black.svg";
// Form Validation Import
import { Formik } from "formik";
// MaterialUI specific Import
import { Grid } from "@mui/material";
import { ResetPasswordFormBase } from "./reset-password-form-base";
import {
  TResetPasswordFormProps,
  TResetPasswordFormState,
  resetPasswordFormState,
  resetPasswordValidationSchema,
} from "./reset-password.definitions";

import { profileActions } from "services/profile/profile.actions";

/*
 *   ResetPasswordView:
 *   Composing view for the Reset Password page.
 *   This page simply render the ResetPassword Form.
 */
export const ResetPasswordForm: React.FC<TResetPasswordFormProps> = ({
  params,
}) => {
  const dispatch = useDispatch();

  // Destructuring the parameters passed in the url
  const { uid, token } = params;
  const handleSubmit = (values: TResetPasswordFormState) => {
    dispatch(
      profileActions.resetPassword(
        values.new_pass1,
        values.new_pass1,
        uid,
        token
      )
    );
  };
  return (
    <div className={"auth"}>
      <Grid
        container
        className={"content content--small"}
        direction="column"
        justifyContent="center"
        alignItems="center"
      >
        <Grid
          container
          direction="column"
          justifyContent="center"
          alignItems="center"
        >
          <Grid item>
            <img src={logo} alt={"Axya Logo"} className={"logo"} />
          </Grid>
        </Grid>
        <Grid
          container
          direction="column"
          justifyContent="center"
          alignItems="center"
        >
          <Formik
            render={(props) => <ResetPasswordFormBase {...props} />}
            initialValues={resetPasswordFormState}
            onSubmit={handleSubmit}
            validationSchema={resetPasswordValidationSchema}
          />
        </Grid>
      </Grid>
    </div>
  );
};

export default ResetPasswordForm;
