import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { SelectChangeEvent } from "@mui/material";
import { AddSpecListModalTable } from "../../table/add-spec-list-modal-table/add-spec-list-modal-table";
import { SelectSpecType } from "../../select-spec-type/select-spec-type";
import { SearchTextField } from "../../search-text-field/search-text-field";
import { modalsActions } from "@next/redux/modalsSlices";
import { selectVisibleSteps } from "@next/modules/vendor-management/redux/selectors";
import {
  PaginationSizeForSpecs,
  PosEndUsersFormKeyMapping,
  SelectedRow,
  SpecificationTableRow,
  VendorManagementModalTypes,
  vendorManagementActions,
} from "@next/modules/vendor-management/redux";
import { getActiveStepIndex } from "@next/modules/vendor-management/utils/getActiveStepIndex";
import * as S from "./add-spec-list-modal-body.styled";

type Props = {
  rowsAlreadyAdded: any[];
  handleAddSpecToFormTable?: (row: SpecificationTableRow) => void;
};

export const AddSpecListModalBody: React.FC<Props> = ({
  rowsAlreadyAdded,
  handleAddSpecToFormTable,
}) => {
  const dispatch = useDispatch();
  const [page, setPage] = useState(1);
  const steps = useSelector(selectVisibleSteps);
  const activeFormStepIndex = getActiveStepIndex(steps);
  const isInA2psProcessControlForm =
    steps[activeFormStepIndex].step_name ===
    PosEndUsersFormKeyMapping.A2psProcessControl;
  const a2psSpecTypes = ["A2ERD", "A2TS"];
  const [selectedRows, setSelectedRows] = useState<SelectedRow[]>([]);
  const [specType, setSpecType] = useState(
    isInA2psProcessControlForm ? a2psSpecTypes : ""
  );
  const [searchValue, setSearchValue] = useState("");

  useEffect(() => {
    dispatch(
      vendorManagementActions.fetchAllSpecificationsRequest({
        page: page,
        pageSize: PaginationSizeForSpecs,
        specType:
          isInA2psProcessControlForm && specType.length === 0
            ? a2psSpecTypes
            : specType,
        search: searchValue,
      })
    );
  }, [specType, page, searchValue]);

  const handleClickAddSpecsToListBtn = () => {
    const rows = selectedRows.map((selectedRow) => selectedRow.row);
    if (rows.length > 0) {
      rows.forEach((row) => {
        handleAddSpecToFormTable(row);
      });
    }

    dispatch(
      modalsActions.closeModal(VendorManagementModalTypes.ADD_SPEC_LIST_MODAL)
    );
  };

  const handleSpecTypeChange = (
    event: SelectChangeEvent<string | string[]>
  ) => {
    const value = event.target.value;

    setSpecType(value);
  };

  const clearSearch = () => {
    setSearchValue("");
  };

  return (
    <>
      <S.StyledBox>
        <S.StyledContent>
          <S.StyledRowBox>
            <SelectSpecType
              specType={specType}
              handleChange={handleSpecTypeChange}
              isInA2psProcessControlForm={isInA2psProcessControlForm}
            />

            <SearchTextField
              searchValue={searchValue}
              setSearchValue={setSearchValue}
              clearSearch={clearSearch}
            />
          </S.StyledRowBox>

          <AddSpecListModalTable
            page={page}
            setSelectedRows={setSelectedRows}
            setPage={setPage}
            rowsAlreadyAdded={rowsAlreadyAdded}
          />
        </S.StyledContent>

        <S.StyledFooterBox>
          <S.StyledButtonBox>
            <S.StyledButton
              variant="contained"
              color="primary"
              onClick={handleClickAddSpecsToListBtn}
            >
              Add selected specs to the list ({selectedRows?.length})
            </S.StyledButton>
          </S.StyledButtonBox>
        </S.StyledFooterBox>
      </S.StyledBox>
    </>
  );
};
