// General imports
import React, { useEffect, useState } from "react";

import { useDispatch, useSelector } from "react-redux";

// Material UI
import { Avatar, Box, Button, Grid, Tooltip, Typography } from "@mui/material";

// Components
import { Field } from "formik";
import CurrencyField from "components/common/fields/CurrencyField";

import { FormikGenericTextFieldGreyBorder } from "components/css-text-field/css-text-field.component";

import { LeadTimeAutoComplete } from "./filter-deadline/filter-deadline.component";

// Helpers functions
import { quotationActions } from "services/quotation";
import {
  getIsEditQuotationMode,
  getLeadTimeOptions,
} from "services/quotation/quotation.selectors";
import { PaymentTerms } from "@next/components/payment-terms/payment-terms";

import { useQuotationViewData } from "../quotation-view-hooks";

export const SubmitQuotation = (props) => {
  const {
    t,
    values,
    isSubmitting,
    handleChange,
    nda,
    setFieldValue,
    itnbReason,
    showPaymentManagementLabel,
    activeQuote,
  } = props;
  const dispatch = useDispatch();

  const { isDetailsExtraFieldsAvailable } = useQuotationViewData();

  const leadTimeOptions = useSelector(getLeadTimeOptions);
  const isEditQuotationMode = useSelector(getIsEditQuotationMode);

  const [disabled, setDisabled] = React.useState(false);

  // use this state to set number of days
  const [numberOfDays, setNumberOfDays] = useState(0);

  // Update the number of days' input field change event.
  const _onChangeNumberOfDays = (e) => {
    let number = e.target.value ? parseInt(e.target.value) : 0;
    setNumberOfDays(number);
    setFieldValue("numberOfDays", number ? number : "");
    setFieldValue("leadTime", "");
  };

  useEffect(() => {
    if (values["numberOfDays"]) {
      setNumberOfDays(Number(values["numberOfDays"]));
    }
  }, [values]);

  useEffect(() => {
    if (numberOfDays) {
      isEditQuotationMode && setDisabled(false);
      dispatch(quotationActions.setLeadTimeOptions(numberOfDays));
    }
  }, [numberOfDays]);

  return (
    <Grid item xs={12} className="c-submit-quotation">
      <Grid container direction="row" justifyContent="flex-end">
        <Grid item xs={12} sm={12} md={12} lg={6}>
          <Box display="flex" mb={1.5} ml={-1} alignItems="center">
            <Box
              textAlign="left"
              fontWeight="fontWeightMedium"
              fontSize="h5.fontSize"
              justifyContent="flex-start"
              className="c-submit-quotation--box"
            >
              {t("comment")}
            </Box>
            <Box className="c-submit-quotation--box">
              <Tooltip title={t("writeAdditionalDetailsForYourSubmission")}>
                <Avatar className="c-submit-quotation--avatar">i</Avatar>
              </Tooltip>
            </Box>
          </Box>
          <Grid
            item
            xs={12}
            className={`c-submit-quotation--container ${
              nda ? "nda-form-disable" : ""
            }`}
          >
            <Field
              name="additionalDetails"
              component={FormikGenericTextFieldGreyBorder}
              textFieldProps={{
                value: values["additionalDetails"],
                onChange: handleChange,
                className: "c-submit-quotation--comment",
                variant: "outlined",
                multiline: true,
                rows: "6",
                fullWidth: true,
              }}
            />
          </Grid>
        </Grid>
      </Grid>
      <Grid
        container
        direction="row"
        justifyContent="flex-end"
        className="c-submit-quotation--delay"
      >
        {!isDetailsExtraFieldsAvailable && (
          <Grid item xs={12} sm={12} md={12} lg={6}>
            <Grid container direction="row" justifyContent="space-between">
              <Grid item xs={12}>
                <Box
                  textAlign="left"
                  fontWeight="fontWeightMedium"
                  fontSize="h5.fontSize"
                  mb={3}
                >
                  {t("leadTime")}
                </Box>
                <Grid
                  container
                  direction="row"
                  alignItems="center"
                  justifyContent="center"
                  spacing={2}
                  className={`c-submit-quotation--container ${
                    nda ? "nda-form-disable" : ""
                  }`}
                >
                  <Grid item xs={3}>
                    <Field
                      name="numberOfDays"
                      component={FormikGenericTextFieldGreyBorder}
                      textFieldProps={{
                        label: t("numberOfDay"),
                        value: values["numberOfDays"]
                          ? values["numberOfDays"]
                          : "",
                        onChange: _onChangeNumberOfDays,
                        className: "c-submit-quotation--text-field",
                        variant: "outlined",
                      }}
                    />
                  </Grid>
                  <Grid item xs={9}>
                    <Field
                      name="leadTime"
                      component={LeadTimeAutoComplete}
                      options={leadTimeOptions}
                      disabled={disabled}
                      textFieldProps={{
                        label: t("deadline"),
                        className: `c-submit-quotation--text-field ${
                          nda ? "nda-form-disable" : ""
                        }`,
                        variant: "outlined",
                      }}
                    />
                  </Grid>

                  <Grid item xs={12}>
                    <Box
                      textAlign="left"
                      fontWeight="fontWeightMedium"
                      fontSize="h5.fontSize"
                      mb={3}
                    >
                      {t("pay:paymentTerms:paymentTermsLabel")}
                    </Box>
                    <Field
                      name="netDs"
                      component={PaymentTerms}
                      options={
                        isEditQuotationMode
                          ? Array.isArray(activeQuote?.rfq_info?.net_ds)
                            ? activeQuote.rfq_info.net_ds
                            : []
                          : Array.isArray(activeQuote?.net_ds)
                          ? activeQuote.net_ds
                          : []
                      }
                      label={t("pay:paymentTerms:paymentTermsLabel")}
                      textFieldProps={{
                        className: `c-submit-quotation--text-field`,
                        variant: "outlined",
                      }}
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <Grid
              container
              direction="row"
              justifyContent="space-between"
              className="c-submit-quotation--offer"
            >
              <Grid item xs={3}>
                <Box
                  textAlign="left"
                  fontWeight="fontWeightMedium"
                  fontSize={16}
                >
                  {t("offer")}
                </Box>
              </Grid>
              <Grid item xs={3}>
                <Box
                  textAlign="right"
                  fontWeight="fontWeightMedium"
                  fontSize="h6.fontSize"
                >
                  <CurrencyField value={props.totalPrice} />
                </Box>
              </Grid>
            </Grid>
            {showPaymentManagementLabel && (
              <Grid
                container
                direction="row"
                alignItems="center"
                justifyContent="flex-start"
                style={{ paddingTop: 24 }}
              >
                <Grid item>
                  <Typography variant="subtitle1">
                    {t("pay:paymentTerms:paymentManagementLabel", {
                      company_name: activeQuote?.company_name,
                    })}{" "}
                  </Typography>
                </Grid>
                <Grid item>
                  <Tooltip
                    title={t("pay:paymentTerms:paymentManagementTooltipText")}
                  >
                    <Avatar className="c-submit-quotation--avatar">i</Avatar>
                  </Tooltip>
                </Grid>
              </Grid>
            )}
          </Grid>
        )}

        <Grid
          item
          xs={12}
          className={`c-submit-quotation--btn-container ${
            nda ? "nda-form-disable" : ""
          }`}
        >
          <Grid container direction="row" justifyContent="flex-end">
            <Button
              className="c-submit-quotation--btn"
              variant="contained"
              type="submit"
              color={"primary"}
              disabled={isSubmitting || disabled || itnbReason ? true : false}
            >
              {isEditQuotationMode
                ? t("quotation:saveChange")
                : t("sendMySubmission")}
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};
