import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import {
  Checkbox,
  FormControl,
  FormControlLabel,
  TextField,
  Typography,
} from "@mui/material";
import { useParams } from "react-router-dom";
import { FormFooter } from "../../form-footer/form-footer";
import { FormParentBox } from "../../form-parent-box";
import { FormBoxLg } from "../../form-box/form-box-lg";
import { FormTitle } from "../../form-title/form-title";
import { FormFieldBox } from "../../form-field-box";
import { FormFieldErrorTypography } from "../../form-field-error-typography/form-field-error-typography";
import { SubtiersFormAutoComplete } from "../../subtiers-form-auto-complete/subtiers-form-auto-complete";
import { SubtierCard } from "./subtier-card/subtier-card";
import { AddSpecListModal } from "../../modals/add-spec-list-modal/add-spec-list-modal";
import { modalsActions } from "@next/redux/modalsSlices";
import {
  selectAllSpecsOfSubtierSupppliers,
  selectFormData,
  selectIsAllStepsCompleted,
  selectIsFormCompleted,
  selectIsLastStep,
  selectIsThereUnsavedChangesInFormStep,
  selectSubtierCompanies,
  selectVisibleSteps,
} from "@next/modules/vendor-management/redux/selectors";
import {
  FieldMandatoryText,
  PartialStep,
  PosEndUsersFormKeyMapping,
  SpecificationTableRow,
  SubtierSupplier,
  SubtierTableRow,
  SubtiersFormData,
  VendorManagementModalTypes,
  vendorManagementActions,
} from "@next/modules/vendor-management/redux";
import { showCompletedMessage } from "@next/modules/vendor-management/utils/showCompletedMessage";
import { showInvalidMessage } from "@next/modules/vendor-management/utils/showInvalidMessage";
import { validateSubtiersData } from "@next/modules/vendor-management/utils/validateSubtiersData";
import { getActiveStepIndex } from "@next/modules/vendor-management/utils/getActiveStepIndex";
import { convertToSubtierSupplier } from "@next/modules/vendor-management/utils/convertToSubtierSupplier";
import { showSavedMessage } from "@next/modules/vendor-management/utils/showSavedMessage";
import * as S from "./subtiers-form.styled";
import { showIncompleteStepsMessageForScopeForm } from "@next/modules/vendor-management/utils/showIncompleteStepsMessageForScopeForm";
import { InfoTitle } from "../../form-field-label/form-field-label";

const validatingText =
  "Validating this section, Tier 1 confirm that the communication (BAx B0x to A2x A0x Migration) has been cascaded to all subtiers.";

export const SubtiersForm: React.FC = () => {
  const { companyId } = useParams<{ companyId: string }>();
  const dispatch = useDispatch();
  const isFormCompleted = useSelector(selectIsFormCompleted);
  const isLastStep = useSelector(selectIsLastStep);
  const isAllStepsCompleted = useSelector(selectIsAllStepsCompleted);
  const steps = useSelector(selectVisibleSteps);
  const activeFormStepIndex = getActiveStepIndex(steps);
  const isThereUnsavedChanges = useSelector(
    selectIsThereUnsavedChangesInFormStep
  );
  const formData = useSelector(selectFormData) as SubtiersFormData;
  const selectedSubtierInfos = formData?.subtier_suppliers_info || [];
  const selectedAutoCompleteOptions =
    selectedSubtierInfos?.map((subtier) => subtier.title) || [];

  const subtierCompaniesForAutoCompleteOptions = useSelector(
    selectSubtierCompanies
  );
  const autoCompleteOptions = subtierCompaniesForAutoCompleteOptions || [];
  const allSpecsOfSubtierSuppliers = useSelector(
    selectAllSpecsOfSubtierSupppliers
  );
  const [selectedSubtierForModal, setSelectedSubtierForModal] =
    useState<SubtierSupplier | null>(null);
  const [rowsWillBeDisabledInModal, setRowsWillBeDisabledInModal] = useState<
    SubtierTableRow[]
  >([]);
  const [isShowTableErrors, setIsShowTableErrors] = useState<boolean>(false);
  const [searchValue, setSearchValue] = useState<string>("");
  const subtierOnBoardingName = PosEndUsersFormKeyMapping.SubtiersOnboarding;
  const subtierOnBoardingStep = steps.find(
    (step) => step.step_name === subtierOnBoardingName
  );

  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
    getValues,
    setError,
  } = useForm<SubtiersFormData>({ defaultValues: formData });

  useEffect(() => {
    if (formData) {
      dispatch(
        vendorManagementActions.fetchSubtierCompaniesRequest(searchValue)
      );
    }
  }, [searchValue]);

  const handleAutocompleteChange = (options: string[]) => {
    const subtierInfos = convertToSubtierSupplier(
      subtierCompaniesForAutoCompleteOptions
    );
    // selected subtier can be other option list from api call, so we need to combine selected subtiers and subtiers from new api call
    const combinedSubtierInfos = [...selectedSubtierInfos, ...subtierInfos];
    const uniqueSubtierInfos = Array.from(
      new Map(combinedSubtierInfos.map((item) => [item.title, item])).values()
    );

    const updatedSubtierSupplierInfos = uniqueSubtierInfos.filter((info) =>
      options.includes(info.title)
    );
    const updatedSubtierIds = updatedSubtierSupplierInfos.map(
      (info) => info.id
    );
    if (updatedSubtierSupplierInfos.length > selectedSubtierInfos.length) {
      if (subtierOnBoardingStep?.is_completed) {
        makeStepIncomplete(steps.indexOf(subtierOnBoardingStep));
        showIncompleteStepsMessageForScopeForm([subtierOnBoardingName]);
      }
    }

    saveSuppliersAndSuppliersInfo(
      updatedSubtierIds,
      updatedSubtierSupplierInfos
    );
    changeStatusOfStepAgainstChange();
  };

  const makeStepIncomplete = (stepIndex: number) => {
    if (stepIndex !== -1) {
      updateFieldStatusOfStepForSaveAndCompletion(
        "is_completed",
        false,
        stepIndex
      );
    }
  };

  const saveSuppliersAndSuppliersInfo = (
    subtierSuppliers: string[],
    subtierSuppliersInfo: SubtierSupplier[]
  ) => {
    const body = {
      subtier_suppliers: subtierSuppliers,
      subtier_suppliers_info: subtierSuppliersInfo,
    };

    dispatch(
      vendorManagementActions.updateSubtierFormDataRequest({
        formData: body,
        companyId: companyId,
      })
    );
  };

  const handleAddSpecToSubtier = (row: SpecificationTableRow) => {
    const spec = {
      specification_id: row.specification_id,
      poe: null,
      compliance: null,
    };
    dispatch(
      vendorManagementActions.addSpecToSubtierSupplierRequest({
        companyId: companyId,
        supplierId: selectedSubtierForModal.id,
        spec: spec,
      })
    );
    changeStatusOfStepAgainstChange();
  };

  const handleClickAddSpecsBtn = (subtierSupplier: SubtierSupplier) => {
    setSelectedSubtierForModal(subtierSupplier);
    if (subtierSupplier) {
      const selectedSubtierSupplier = allSpecsOfSubtierSuppliers.find(
        (supplier) => {
          if (supplier.supplier_id === subtierSupplier.id) {
            return supplier;
          }
        }
      );
      if (selectedSubtierSupplier) {
        setRowsWillBeDisabledInModal(selectedSubtierSupplier?.rows || []);
      }
    }

    dispatch(
      modalsActions.showModal({
        key: VendorManagementModalTypes.ADD_SPEC_LIST_MODAL,
      })
    );
  };

  const saveChanges = (isForCompletion: boolean) => {
    const additionalComment = getValues("additional_comment");
    const isValidated = getValues("subtier_validation");
    const body = {
      subtier_validation: isValidated,
      additional_comment: additionalComment,
      subtier_suppliers: formData.subtier_suppliers,
      subtier_suppliers_info: formData.subtier_suppliers_info,
    };
    dispatch(
      vendorManagementActions.updateSubtierFormDataRequest({
        formData: body,
        companyId: companyId,
      })
    );

    const fieldOfStep = isForCompletion
      ? "is_completed"
      : "is_partially_filled";
    updateFieldStatusOfStepForSaveAndCompletion(
      fieldOfStep,
      true,
      activeFormStepIndex
    );
    if (!isForCompletion) {
      showSavedMessage();
    }
    dispatch(vendorManagementActions.setIsThereUnsavedChangesInFormStep(false));
  };

  const confirmStepCompletion: SubmitHandler<SubtiersFormData> = (data) => {
    const isValidatedSubtiersData = validateSubtiersData(
      allSpecsOfSubtierSuppliers
    );
    const isCheckedValidateField = data.subtier_validation;

    if (isValidatedSubtiersData && isCheckedValidateField) {
      makeFormStepCompletionProcess();
    } else {
      if (!isValidatedSubtiersData && !isShowTableErrors) {
        setIsShowTableErrors(true);
      }
      if (!isCheckedValidateField) {
        setError("subtier_validation", { type: "manual" });
      }
      showInvalidMessage();
      return;
    }
  };

  const makeFormStepCompletionProcess = () => {
    saveChanges(true);
    showCompletedMessage();
    if (isAllStepsCompleted) {
      showThankYouModal();
    } else if (!isLastStep) {
      routeNextStep();
    }
  };

  const routeNextStep = () => {
    const newActiveStepIndex = activeFormStepIndex + 1;
    setActiveFormStepIndex(newActiveStepIndex);
  };

  const setActiveFormStepIndex = (index: number) => {
    updateFieldStatusOfStepForSaveAndCompletion("is_active", true, index);
  };

  const showThankYouModal = () => {
    dispatch(
      modalsActions.showModal({
        key: VendorManagementModalTypes.THANK_YOU_MODAL,
      })
    );
  };

  const checkInputChange = () => {
    if (isFormCompleted) {
      updateFieldStatusOfStepForSaveAndCompletion(
        "is_completed",
        false,
        activeFormStepIndex
      );
    } else if (!isThereUnsavedChanges) {
      dispatch(
        vendorManagementActions.setIsThereUnsavedChangesInFormStep(true)
      );
    }
  };

  const changeStatusOfStepAgainstChange = () => {
    if (isFormCompleted) {
      updateFieldStatusOfStepForSaveAndCompletion(
        "is_completed",
        false,
        activeFormStepIndex
      );
    } else {
      updateFieldStatusOfStepForSaveAndCompletion(
        "is_partially_filled",
        true,
        activeFormStepIndex
      );
    }
  };

  const updateFieldStatusOfStepForSaveAndCompletion = (
    key: "is_completed" | "is_partially_filled" | "is_active",
    value: boolean,
    stepIndex: number
  ) => {
    const step = steps[stepIndex];
    const partialStep: PartialStep = {
      [key]: value,
    };
    dispatch(
      vendorManagementActions.updateStepRequest({
        stepId: step.id,
        data: partialStep,
      })
    );
  };

  const onInvalid = () => {
    showInvalidMessage();
  };

  return (
    <>
      <FormParentBox>
        <FormBoxLg isCompleted={isFormCompleted}>
          <FormTitle
            title={"Subtier readiness"}
            isCompleted={isFormCompleted}
          />

          <SubtiersFormAutoComplete
            options={autoCompleteOptions}
            selectedOptions={selectedAutoCompleteOptions}
            handleChange={handleAutocompleteChange}
            searchValue={searchValue}
            setSearchValue={setSearchValue}
            placeholder={"Search through supplier list to add your subtier(s)"}
          />
          <InfoTitle />

          {selectedSubtierInfos?.map((subtierSupplier: SubtierSupplier, i) => (
            <SubtierCard
              key={i}
              subtierSupplier={subtierSupplier}
              handleClickAddSpecsBtn={handleClickAddSpecsBtn}
              isShowTableErrors={isShowTableErrors}
            />
          ))}

          <FormFieldBox>
            <Typography variant="body1">{validatingText}</Typography>

            <FormControl error={errors.subtier_validation ? true : false}>
              <FormControlLabel
                sx={{ width: "120px" }}
                control={
                  <Checkbox
                    defaultChecked={formData?.subtier_validation || null}
                    {...register("subtier_validation", { required: false })}
                  />
                }
                label="I validate"
              />
            </FormControl>
            {errors.subtier_validation ? (
              <FormFieldErrorTypography text={FieldMandatoryText} />
            ) : null}
          </FormFieldBox>

          <FormControl margin="none">
            <Controller
              name="additional_comment"
              control={control}
              defaultValue={formData?.additional_comment || ""}
              render={({ field: { onChange, value } }) => (
                <S.StyledFormFieldBox>
                  <TextField
                    sx={{ width: "100%" }}
                    multiline
                    rows={3}
                    placeholder="Additional comment..."
                    value={value || ""}
                    onChange={(e) => {
                      onChange(e);
                      checkInputChange();
                    }}
                  />
                </S.StyledFormFieldBox>
              )}
            />
          </FormControl>
        </FormBoxLg>
      </FormParentBox>

      <FormFooter
        saveChanges={() => saveChanges(false)}
        confirmStepCompletion={handleSubmit(confirmStepCompletion, onInvalid)}
        isCompleted={isFormCompleted}
      />

      <AddSpecListModal
        title={selectedSubtierForModal?.title + " list"}
        rowsAlreadyAdded={rowsWillBeDisabledInModal}
        handleAddSpecToFormTable={handleAddSpecToSubtier}
      />
    </>
  );
};
