// Services
import { inboxApi, inboxConstants } from "./index";
import snackbarUtils from "@next/utils/snackbarUtils";

/**
 * Get the inbox listing (Quotations)
 */
function getInboxMessages(messagesPage, selectedMessagesFilterRfq) {
  return (dispatch) => {
    dispatch(request(messagesPage, selectedMessagesFilterRfq));
    inboxApi.getInboxMessages().then(
      (data) => {
        dispatch(success(data));
      },
      (error) => {
        dispatch(failure(error?.toString()));
      }
    );
  };

  function request() {
    return {
      type: inboxConstants.FETCH_INBOX_MESSAGES_REQUEST,
      messagesPage,
      selectedMessagesFilterRfq
    };
  }
  function success(messages) {
    return {
      type: inboxConstants.FETCH_INBOX_MESSAGES_SUCCESS,
      messages
    };
  }
  function failure(error) {
    return {
      type: inboxConstants.FETCH_INBOX_MESSAGES_FAILURE,
      error
    };
  }
}

/**
 * Get the quotation thread messages (Discussions)
 *
 * @param {*} token
 * @param {*} quotationId
 */
function getQuotationMessages(token, quotationId) {
  return (dispatch) => {
    dispatch(request());
    inboxApi.getQuotationMessages(token, quotationId).then(
      (data) => {
        dispatch(success(data));
      },
      (error) => {
        dispatch(failure(error?.toString()));
      }
    );
  };

  function request() {
    return {
      type: inboxConstants.FETCH_QUOTATION_MESSAGES_REQUEST
    };
  }
  function success(messages) {
    return {
      type: inboxConstants.FETCH_QUOTATION_MESSAGES_SUCCESS,
      quotationMessages: messages
    };
  }
  function failure(error) {
    return {
      type: inboxConstants.FETCH_QUOTATION_MESSAGES_FAILURE,
      error
    };
  }
}

const getQuotationMessageDetails =
  (token, quotationId, messageId) => (dispatch) => {
    function request() {
      return {
        type: inboxConstants.FETCH_QUOTATION_MESSAGE_DETAILS_REQUEST
      };
    }
    function success(message) {
      return {
        type: inboxConstants.FETCH_QUOTATION_MESSAGE_DETAILS_SUCCESS,
        message
      };
    }
    function failure(error) {
      return {
        type: inboxConstants.FETCH_QUOTATION_MESSAGE_DETAILS_FAILURE,
        error
      };
    }
    return new Promise(function (resolve, reject) {
      dispatch(request());
      inboxApi.getQuotationMessageDetails(token, quotationId, messageId).then(
        (data) => {
          dispatch(success(data));
          resolve(data);
        },
        (error) => {
          dispatch(failure(error?.toString()));
          reject(error);
        }
      );
    });
  };

/**
 * Sends a new message in a quotation thread
 * @param {String} token The token to authenticate the user
 * @param {Intenger} quotationId The quotation id
 * @param {Object} data The message content to post
 */
const sendQuotationMessage = (token, quotationId, data) => (dispatch) => {
  function request() {
    return {
      type: inboxConstants.POST_QUOTATION_MESSAGE_REQUEST
    };
  }
  function success(message) {
    return {
      type: inboxConstants.POST_QUOTATION_MESSAGE_SUCCESS,
      message
    };
  }
  function failure(error) {
    return {
      type: inboxConstants.POST_QUOTATION_MESSAGE_FAILURE,
      error
    };
  }
  return new Promise(function (resolve, reject) {
    dispatch(request());
    inboxApi.sendQuotationMessage(token, quotationId, data).then(
      (data) => {
        dispatch(success(data));
        // clear uploaded file attachments after send button click
        dispatch(clearAttachments());
        resolve(data);
      },
      (error) => {
        dispatch(failure(error?.toString()));
        reject(error);
      }
    );
  });
};

const clearAttachments = () => {
  return (dispatch) => {
    dispatch({ type: inboxConstants.CLEAR_FILE_ATTACHMENTS });
  };
};

/**
 * Publishes a message to the active RFQ Room Exchange messages data
 *
 * @param {*} token
 * @param {*} message
 */
function publishMessage(message) {
  return (dispatch) => {
    dispatch(publishMsg(message));
  };

  function publishMsg(message) {
    return {
      type: inboxConstants.PUBLISH_ROOM_MESSAGE,
      message
    };
  }
}

const getQuotationThreadDetails = (token, quotationPk) => (dispatch) => {
  return new Promise(function (resolve, reject) {
    inboxApi.getQuotationThreadDetails(token, quotationPk).then(
      (data) => {
        resolve(data);
      },
      (error) => {
        reject(error);
      }
    );
  });
};

function setActiveQuotationThread(token, quotationThread) {
  // Make sure to log the user activity on that quotation
  // Logging means that we mark the quotation thread
  // as read,
  if (!quotationThread) {
    return (dispatch) => {
      dispatch(success(null));
    };
  }
  inboxApi.logUserActivity(token, quotationThread.quotation.pk);
  return (dispatch) => {
    dispatch(success({ ...quotationThread, is_locked: true }));
  };

  function success(quotationThread) {
    return {
      type: inboxConstants.SET_ACTIVE_QUOTATION,
      quotationThread
    };
  }
}

const handleInboxUpdates = (data) => (dispatch) => {
  const { type, ...rest } = data;
  switch (type) {
    case "inbox-q-msg-count-update":
      dispatch({
        type: inboxConstants.INBOX_QUOTATION_MESSAGES_COUNT_UPDATE,
        ...rest
      });
      break;

    case "c-is-online":
      dispatch({
        type: inboxConstants.COMPANY_ONLINE_STATUS_UPDATE,
        ...rest
      });
      break;

    case "inbox-quotation-drop":
      dispatch({
        type: inboxConstants.INBOX_MESSAGE_QUOTATION_DROP,
        ...rest
      });
      break;
  }
};

function handleInboxFileUpload(file, t) {
  return (dispatch, getState) => {
    dispatch(request());

    inboxApi.inboxFileUpload(getState().profile.token, file).then(
      (file) => {
        dispatch(success(file));
      },
      (error) => {
        dispatch(failure(error?.toString()));
        snackbarUtils.error(t("fileUploadFailed"));
      }
    );
  };

  function request() {
    return { type: inboxConstants.HANDLE_INBOX_FILE_UPLOAD_REQUEST };
  }
  function success(file) {
    return {
      type: inboxConstants.HANDLE_INBOX_FILE_UPLOAD_SUCCESS,
      file
    };
  }
  function failure(error) {
    return {
      type: inboxConstants.HANDLE_INBOX_FILE_UPLOAD_FAILURE,
      error
    };
  }
}

function deleteFileUpload(pk) {
  return (dispatch, getState) => {
    inboxApi.deleteFileUpload(getState().profile.token, pk).then(
      () => {
        dispatch(success(pk));
      },
      (error) => {
        console.log(error);
      }
    );
  };

  function success(pk) {
    return {
      type: inboxConstants.DELETE_FILE_UPLOAD_SUCCESS,
      pk
    };
  }
}

// set chat window minimize state
function setMinimizeChatWindow(value = false) {
  return (dispatch) => {
    dispatch({
      type: inboxConstants.SET_MINIMIZE_CHAT_WINDOW,
      isMinimized: value
    });
  };
}

export const inboxActions = {
  getQuotationMessages,
  getQuotationMessageDetails,
  publishMessage,
  getInboxMessages,
  sendQuotationMessage,
  setActiveQuotationThread,
  getQuotationThreadDetails,
  handleInboxUpdates,
  handleInboxFileUpload,
  deleteFileUpload,
  clearAttachments,
  setMinimizeChatWindow
};
