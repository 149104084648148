import { Box, Typography } from "@mui/material";
import { AttachFileControlled } from "@next/components/attach-file-controlled";
import { FileData } from "@next/components/attach-file/attach-file-list.types";
import { profileNextActions } from "@next/modules/profile/redux";
import {
  selectCompanyProfile,
  selectDeleteCompanyFileDocumentsLoading,
  selectUploadCompanyFileDocumentsLoading,
} from "@next/modules/profile/redux/selectors";
import React from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";

const CompanyDocuments = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const companyProfile = useSelector(selectCompanyProfile);
  const uploadCompanyFileDocumentsLoading = useSelector(
    selectUploadCompanyFileDocumentsLoading
  );
  const deleteCompanyFileDocumentsLoading = useSelector(
    selectDeleteCompanyFileDocumentsLoading
  );

  const onAddFiles = (newFiles: File[]) => {
    if (!companyProfile?.id) return;

    dispatch(
      profileNextActions.uploadCompanyDocumentsRequest({
        pk: companyProfile?.id as number,
        attachments: newFiles,
        onSuccess: () => {
          dispatch(
            profileNextActions.fetchCompanyProfileRequest({
              companyId: companyProfile?.id as number,
              isPublic: false,
            })
          );
        },
      })
    );
  };

  const onDeleteFiles = (fileName: string) => {
    const foundFile = companyProfile?.documents?.find((f: FileData) =>
      f.file_name.includes(fileName)
    );

    if (!companyProfile?.id || !foundFile?.pk) return;

    dispatch(
      profileNextActions.deleteCompanyDocumentsRequest({
        fileId: foundFile?.pk,
        onSuccess: () => {
          dispatch(
            profileNextActions.fetchCompanyProfileRequest({
              companyId: companyProfile?.id as number,
              isPublic: false,
            })
          );
        },
      })
    );
  };

  return (
    <Box>
      <Box mb={4}>
        <Typography variant="body2" color="textSecondary">
          {t("profile:companyProfile:documentsDescription")}
        </Typography>
      </Box>

      <Box mt={"16px"}>
        <AttachFileControlled
          files={companyProfile?.documents || []}
          onAddFiles={onAddFiles}
          onDeleteFile={onDeleteFiles}
          loading={
            uploadCompanyFileDocumentsLoading ||
            deleteCompanyFileDocumentsLoading
          }
          btnProps={{
            variant: "outlined",
            fullWidth: false,
            size: "medium",
          }}
          multiple={false}
          btnText={t("profile:companyProfile:uploadDocuments")}
        />
      </Box>
    </Box>
  );
};

export default CompanyDocuments;
