import {
  Box,
  Button,
  CircularProgress,
  Grid,
  Paper,
  Theme,
  Typography,
} from "@mui/material";
import React from "react";
import { SellerDashboardActiveBuyerCard } from "./seller-dashboard-active-buyer-card";
import { Skeleton } from "@mui/material";
import { useDispatch } from "react-redux";
import { modalsActions } from "@next/redux/modalsSlices";
import { DashboardModalTypes } from "../../modals/types";
import { useSellerDashboardActiveBuyersData } from "./seller-dashboard-active-buyers.hooks";
import { useTranslation } from "react-i18next";
import { SharedModalTypes } from "@next/modals/types";
import { useCompanyPlan } from "@next/hooks/useIsPremiumCompany";
import { createStyles, makeStyles } from "@mui/styles";

const useStyles = makeStyles<Theme>((theme) =>
  createStyles({
    root: {
      padding: "24px",
      boxShadow: "none",
      border: `1px solid ${theme.palette.divider}`,
    },
    cards: {
      marginTop: "8px",
    },

    pagination: {
      display: "flex",
    },
  })
);

export const SellerDashboardActiveBuyers = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const classes = useStyles();
  const isPremiumCompany = useCompanyPlan("premium");

  const { buyerProfiles, count, loading } =
    useSellerDashboardActiveBuyersData();

  const onClickOpenProfile = (buyerProfileId: number) => {
    dispatch(
      modalsActions.showModal(
        isPremiumCompany
          ? {
              key: DashboardModalTypes.BUYER_PROFILE_DRAWER,
              data: {
                buyerProfileId,
              },
            }
          : { key: SharedModalTypes.UPGRADE_TO_PREMIUM_MODAL }
      )
    );
  };

  return (
    <Paper className={classes.root}>
      <Box>
        <Typography variant="h6">
          {t("dashboard:activeBuyersOnAxya")}
        </Typography>
      </Box>

      <Grid container spacing={3} className={classes.cards}>
        {loading ? (
          !count ? (
            <Box
              display="flex"
              justifyContent="center"
              width="100%"
              mt="12px"
              mb="12px"
            >
              <CircularProgress />
            </Box>
          ) : (
            Array.from(Array(count > 6 ? 6 : count).keys()).map(() => (
              <Grid item xs={2}>
                <Skeleton
                  variant="rectangular"
                  width="100%"
                  height={228}
                  style={{ borderRadius: "16px" }}
                />
              </Grid>
            ))
          )
        ) : (
          buyerProfiles?.map((buyerProfile) => {
            return (
              <Grid item xs={2}>
                <SellerDashboardActiveBuyerCard
                  buyerProfile={buyerProfile}
                  onClickOpenProfile={() =>
                    onClickOpenProfile(buyerProfile?.id)
                  }
                />
              </Grid>
            );
          })
        )}

        <Grid
          item
          xs={12}
          justifyContent="center"
          className={classes.pagination}
        >
          <Button
            href="https://app.axya.co/search/buyers"
            target="_blank"
            rel="noopener noreferrer"
            color="primary"
            variant="contained"
            data-pid="btnSeeAllBuyers"
          >
            {t("dashboard:seeAllBuyers", { count })}
          </Button>
        </Grid>
      </Grid>
    </Paper>
  );
};
