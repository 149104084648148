import {
  GridColDef,
  GridColumnHeaderParams,
  GridColumns,
  GridRenderCellParams
} from "@mui/x-data-grid-pro-v5";
import React, { useEffect, useState } from "react";

import CalendarTodayOutlinedIcon from "@mui/icons-material/CalendarTodayOutlined";
import ContentCopyRoundedIcon from "@mui/icons-material/ContentCopyRounded";
import EditIcon from "@mui/icons-material/Edit";
import { Box, IconButton, Link, TextField, Typography } from "@mui/material";
import { CustomTypography } from "@next/components/custom-typography";
import { MUIDatePicker } from "@next/components/mui-date-picker";
import { EN_DATE_FORMAT, FR_DATE_FORMAT } from "@next/constants";
import { useQueryParam } from "@next/hooks/useQuery";
import {
  ActionPanelType,
  LineItemType,
  OrderLineItemField,
  ordersActions
} from "@next/modules/orders/redux";
import { OrderDetailPartStatusTag } from "../order-detail-part-status-tag/order-detail-part-status-tag";
import { hasValidUrlProtocol } from "@next/utils/stringUtils";
import i18n, { t } from "assets/configi18n/i18n";
import CopyToClipboard from "react-copy-to-clipboard";
import { formatDate } from "helpers/formatDate";
import { store } from "store";
import { selectOrderActionPanelType } from "@next/modules/orders/redux/selectors";
import { CompanyTypeNext } from "@next/redux/types";
import { getSelectedLanguage } from "@next/utils/browserUtils";
import { getUser } from "services/profile/profile.selectors";
import { RightPanelTabs } from "../order-detail-right-tabs/order-detail-right-tabs";
import snackbarUtils from "@next/utils/snackbarUtils";
import { useDispatch, useSelector } from "react-redux";

const defaultColumnConfig: Partial<GridColDef> = {
  disableColumnMenu: true,
  sortable: true,
  hideSortIcons: false,
  editable: false
};

const DateHeaderCellWithFormat = (params: GridColumnHeaderParams) => {
  return (
    <Box
      height={48}
      display="flex"
      flexDirection="column"
      justifyContent="center"
      paddingTop="18px"
    >
      <Typography variant="body2" style={{ fontWeight: "bold", lineHeight: 1 }}>
        {params?.colDef.headerName}
      </Typography>

      <Typography
        variant="caption"
        style={{ lineHeight: 1.5 }}
        color="textSecondary"
      >
        <i>{i18n.language === "en" ? "(YYYY/MM/DD)" : "(JJ/MM/AAAA)"}</i>
      </Typography>
    </Box>
  );
};

export const EditDeliveryDateCell: React.FC<
  GridRenderCellParams & {
    allowChange: boolean;
    onChange: (value: any) => void;
  }
> = ({ value, allowChange, onChange, row }) => {
  const dispatch = useDispatch();
  const user = useSelector(getUser);
  const isBuyer = user?.type === CompanyTypeNext.DO;
  const orderActionPanelType = useSelector(selectOrderActionPanelType);
  const [_rightPanelTab, setRightPanelTab] = useQueryParam("panelTab");
  const editable =
    allowChange &&
    orderActionPanelType !== ActionPanelType.ProposeQtyAndPriceChange &&
    orderActionPanelType !== ActionPanelType.Review &&
    (row.status !== "shipped" || (isBuyer && row.status === "shipped"));

  const handleOnChange = (value: Date) => {
    dispatch(
      ordersActions.setOrderActionPanelType(ActionPanelType.ModifyDeliveryDate)
    );
    onChange(value);
  };

  const handleOnClick = () => {
    if (editable) {
      setRightPanelTab(RightPanelTabs.ACTIONS_PANEL);
      dispatch(
        ordersActions.setOrderActionPanelType(
          ActionPanelType.ModifyDeliveryDate
        )
      );
    }
  };
  return (
    <Box width={"100%"}>
      {editable ? (
        <CustomTypography tooltipTitle={null} sx={{ display: "flex" }}>
          <MUIDatePicker
            value={value as any}
            onChange={(newValue) => {
              const formattedNewValue = formatDate(newValue, "YYYY-MM-DD");
              if (formattedNewValue === value) {
                return;
              }

              handleOnChange(newValue);
            }}
            onClick={handleOnClick}
            InputProps={{
              disableUnderline: true,
              margin: "none",
              style: { fontSize: 12, marginTop: "2px" }
            }}
            inputVariant="standard"
            keyboardIcon={<CalendarTodayOutlinedIcon fontSize="small" />}
            KeyboardButtonProps={{ color: "inherit", size: "small" }}
            disablePast={false}
            variant="inline"
          />
        </CustomTypography>
      ) : (
        <CustomTypography>
          {formatDate(
            value,
            i18n.language === "en" ? EN_DATE_FORMAT : FR_DATE_FORMAT
          )}
        </CustomTypography>
      )}
    </Box>
  );
};

export const EditQtyOrPriceCell: React.FC<
  GridRenderCellParams & {
    allowChange: boolean;
    lineItemType: LineItemType;
    onChange: (value: any) => void;
  }
> = ({ value, lineItemType, allowChange, onChange, row }) => {
  const previousLineItem = row?.previous_line_item;
  const dispatch = useDispatch();
  const orderActionPanelType = useSelector(selectOrderActionPanelType);
  const [_rightPanelTab, setRightPanelTab] = useQueryParam("panelTab");
  const editable =
    allowChange &&
    orderActionPanelType !== ActionPanelType.ModifyDeliveryDate &&
    orderActionPanelType !== ActionPanelType.Review &&
    row.status !== "shipped";

  const [isEditing, setIsEditing] = useState(false);

  const language = getSelectedLanguage();
  const langFMT = language === "fr" ? "fr-FR" : "en-EN";

  const normalizeValue = (value: string) => {
    return value.replace(",", ".");
  };

  useEffect(() => {
    if (isEditing) {
      onChange(
        language === "fr"
          ? value.toString().replace(".", ",")
          : value.toString()
      );
      setRightPanelTab(RightPanelTabs.ACTIONS_PANEL);
      dispatch(
        ordersActions.setOrderActionPanelType(
          ActionPanelType.ProposeQtyAndPriceChange
        )
      );
    }
  }, [isEditing]);

  const handleEditClick = () => {
    setIsEditing(true);
  };

  const handleBlur = (e: React.FocusEvent<HTMLInputElement>) => {
    setIsEditing(false);
    const rawValue = e.target.value.trim();

    const normalizedValue = normalizeValue(rawValue);

    const numericValue =
      normalizedValue === ""
        ? previousLineItem?.[lineItemType] || 0
        : parseFloat(normalizedValue);

    onChange(numericValue);
  };

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Enter") {
      setIsEditing(false);

      const rawValue = (e.target as HTMLInputElement).value.trim();
      const normalizedValue = normalizeValue(rawValue);

      const numericValue =
        normalizedValue === ""
          ? previousLineItem?.[lineItemType] || 0
          : parseFloat(normalizedValue);

      onChange(numericValue);
    }
  };

  const handleDoubleClick = () => {
    setIsEditing(true);
  };

  const handleOnChange = (value: string) => {
    const formattedValue = normalizeValue(value);
    const isValid = /^-?\d*\.?\d*$/.test(formattedValue);

    if (isValid) {
      dispatch(
        ordersActions.setOrderActionPanelType(
          ActionPanelType.ProposeQtyAndPriceChange
        )
      );
      onChange(value);
    }
  };

  return (
    <Box
      width={"100%"}
      display="flex"
      alignItems="center"
      onDoubleClick={handleDoubleClick}
    >
      {editable && isEditing ? (
        <TextField
          value={value}
          onChange={(e) => handleOnChange(e.target.value)}
          onBlur={handleBlur}
          onKeyDown={handleKeyDown}
          type="text"
          variant="standard"
          size="small"
          InputProps={{
            disableUnderline: true,
            style: { fontSize: 12, marginTop: "3px" }
          }}
          inputProps={{ min: 0 }}
          autoFocus
          fullWidth
        />
      ) : (
        <>
          <Typography variant="caption" style={{ flexGrow: 1 }}>
            {new Intl.NumberFormat(langFMT).format(value)}
          </Typography>
          {editable && (
            <IconButton size="small" onClick={handleEditClick}>
              <EditIcon fontSize="small" />
            </IconButton>
          )}
        </>
      )}
    </Box>
  );
};

const renderCell = ({
  params,
  slug,
  isDraftOrder,
  isPortal,
  orderPk,
  fieldType
}: {
  params: GridRenderCellParams;
  slug: string;
  isDraftOrder: boolean;
  isPortal: boolean;
  orderPk: number;
  fieldType?: string | "date" | "text" | "number";
}) => {
  const handleChangeDate = (
    field: "delivery_date" | "updated_delivery_date",
    id: number,
    value: any
  ) => {
    if (isDraftOrder) {
      store.dispatch(
        ordersActions.updateOrderDeliveryDatesRequest({
          orderPk,
          delivery_dates: [
            { id, delivery_date: formatDate(value, "YYYY-MM-DD") }
          ]
        })
      );
    } else {
      store.dispatch(
        ordersActions.setLineItemNewValue({
          id: id as number,
          newValue: formatDate(value, "YYYY-MM-DD"),
          field: field as LineItemType,
          isPortal
        })
      );
    }
  };

  const handleChangeQtyOrPrice = (
    field: LineItemType,
    id: number,
    value: any
  ) => {
    store.dispatch(
      ordersActions.setLineItemNewValue({
        id: id as number,
        newValue: value,
        field: field,
        isPortal
      })
    );
  };

  switch (slug) {
    case "delivery_date":
      return (
        <EditDeliveryDateCell
          allowChange={isDraftOrder}
          onChange={(value) => {
            handleChangeDate("delivery_date", params.row.id, value);
          }}
          {...params}
        />
      );
    case "quotation_price":
      return (
        <EditQtyOrPriceCell
          allowChange={!isDraftOrder}
          lineItemType={LineItemType.PRICE}
          onChange={(value) => {
            handleChangeQtyOrPrice(LineItemType.PRICE, params.row.id, value);
          }}
          {...params}
        />
      );
    case "quantity":
      return (
        <EditQtyOrPriceCell
          allowChange={!isDraftOrder}
          lineItemType={LineItemType.QUANTITY}
          onChange={(value) => {
            handleChangeQtyOrPrice(LineItemType.QUANTITY, params.row.id, value);
          }}
          {...params}
        />
      );
    case "updated_delivery_date":
      return (
        <EditDeliveryDateCell
          allowChange={!isDraftOrder}
          onChange={(value) => {
            handleChangeDate("updated_delivery_date", params.row.id, value);
          }}
          {...params}
        />
      );
    case "status":
      return (
        <OrderDetailPartStatusTag
          orderShipmentStatus={params?.row?.status}
          shipmentConfirmationDate={params?.row?.shipment_confirmation_date}
        />
      );

    case "shipment_note":
      return params?.row?.shipment_note ? (
        <Box
          display="flex"
          alignItems="center"
          justifyContent="space-between"
          width="100%"
        >
          <CustomTypography
            variant="body2"
            style={{ cursor: "pointer", marginRight: "4px" }}
            maxWidth={200}
          >
            {hasValidUrlProtocol(params?.row?.shipment_note) ? (
              <Link target="_blank" href={params?.row?.shipment_note}>
                {t("orders:tracking")}
              </Link>
            ) : (
              params?.row?.shipment_note
            )}
          </CustomTypography>

          <CopyToClipboard
            text={params?.row?.shipment_note}
            onCopy={() => snackbarUtils.toast(t("orders:shippingNoteCopied"))}
          >
            <ContentCopyRoundedIcon
              style={{
                width: "20px",
                color: "black"
              }}
            />
          </CopyToClipboard>
        </Box>
      ) : null;

    default:
      return (
        <CustomTypography>
          {fieldType === "date"
            ? formatDate(
                params.value,
                i18n.language === "en" ? EN_DATE_FORMAT : FR_DATE_FORMAT
              )
            : params.value}
        </CustomTypography>
      );
  }
};

const renderHeader = (params: GridColumnHeaderParams, type: string) => {
  switch (type) {
    case "date":
      return <DateHeaderCellWithFormat {...params} />;

    default:
      break;
  }
};

const draftOrderVisibleSlugs = [
  "name",
  "description",
  "quantity",
  "delivery_date"
];

const defaultRenderOrder = [
  "name",
  "description",
  "quantity",
  "shipped_quantity",
  "quotation_price"
];

export const getOrderDetailLineItemsTableColumns = (
  orderDetailLineItemFields: OrderLineItemField[] = [],
  isDraftOrder: boolean,
  isPortal: boolean,
  orderPk,
  hidden_fields: string[] = []
): GridColumns => {
  let _arrangedColumns: GridColumns = [];
  let _restOfColumns: GridColumns = [];

  // eslint-disable-next-line @typescript-eslint/no-unused-expressions
  orderDetailLineItemFields
    ?.filter((field) =>
      isDraftOrder ? draftOrderVisibleSlugs.includes(field?.slug) : true
    )
    ?.filter((field) => !hidden_fields?.includes(field?.slug))
    ?.forEach((field) => {
      if (field) {
        const slug = field.slug;

        // Determine if the field should be rendered in renderOrder
        const inRenderOrder = defaultRenderOrder.includes(slug);

        // Construct column object
        const column = {
          ...defaultColumnConfig,
          field: slug,
          headerName: field.title,
          type: field.type,
          renderHeader: (params) => renderHeader(params, field.type),
          renderCell: (params) =>
            renderCell({
              params,
              slug,
              isDraftOrder,
              isPortal,
              orderPk,
              fieldType: field.type
            }),
          valueGetter: (params) => {
            const slugParts = slug.split(".");

            if (slugParts[0] === "extra_fields") {
              return params.row.extra_fields?.[slugParts[1]] ?? "";
            }

            return params.row[slug] ?? "";
          }
        };

        // Add to the arranged columns if it should be rendered in renderOrder
        if (inRenderOrder) {
          _arrangedColumns.push(column);
        } else {
          // Push to the rest of the columns
          _restOfColumns.push(column);
        }
      }
    });

  const _columns = _arrangedColumns.concat(_restOfColumns);
  return _columns;
};
