import React from "react";
import CircularProgress from "@mui/material/CircularProgress";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import { makeStyles } from "@mui/styles";

type Props = {
  progress: number;
};
const CircularProgressWithLabel: React.FC<Props> = ({ progress }) => {
  const createCircularStyles = makeStyles((theme) => ({
    root: {
      position: "relative",
      display: "inline-flex",
    },
    textContainer: {
      marginRight: "5px",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
    bottom: {
      color: theme.palette.grey[200],
    },
    top: {
      position: "absolute",
      right: 0,
    },
  }));

  const classes = createCircularStyles();

  const progressBar = [
    { value: 100, className: classes.bottom },
    { value: progress, className: classes.top },
  ];

  return (
    <Box className={classes.root}>
      <Box className={classes.textContainer}>
        <Typography variant="caption" component="div">{`${Math.round(
          progress
        )}%`}</Typography>
      </Box>
      {progressBar.map((progress) => (
        <CircularProgress
          variant="determinate"
          className={progress.className}
          size={30}
          value={progress == undefined ? 0 : progress.value}
        />
      ))}
    </Box>
  );
};

export default CircularProgressWithLabel;
