import React, { useEffect, useState } from "react";
import { backendUrl } from "urls";
import { useTranslation } from "react-i18next";

import { useSelector } from "react-redux";

import { Box, Typography } from "@mui/material";
import { Formik } from "formik";
import CheckIcon from "@mui/icons-material/Check";

import { ExternalAccountForm } from "../components/external-account/external-account.form";
import { BusinessProfileMissing } from "../components/business-profile-missing/business-profile-missing";

import {
  initialValues,
  validationSchema,
} from "../components/external-account/external-account.form.definitions";
import { IExternalAccountFormValues } from "services/pay/pay.model";

import { getToken } from "services/profile/profile.selectors";
import { backendErrorRegex } from "helpers/formRegex";
import { axiosApi } from "@next/api";

export const PayMain: React.FC = () => {
  const [onboardMsgBtn, setOnboardMsgBtn] = useState("");
  const [isBusinessProfileMissing, setIsBusinessProfileMissing] =
    useState(false);
  const [isExternalAccountMissing, setIsExternalAccountMissing] =
    useState(false);
  const token = useSelector(getToken);

  const { t } = useTranslation();

  useEffect(() => {
    axiosApi
      .get(`${backendUrl}axyapay/myAccount`)
      .then(async (response: any) => {
        if (response.status === 200) {
          const data = await response.json();
          setIsBusinessProfileMissing(data.is_business_profile_missing);
          setIsExternalAccountMissing(data.is_external_account_missing);

          setOnboardMsgBtn(t("pay:onboardMsgBtn:completeYouAccount"));
        }
        if (response.status === 404) {
          setOnboardMsgBtn(t("pay:onboardMsgBtn:getStarted"));
          setIsBusinessProfileMissing(true);
          setIsExternalAccountMissing(true);
        }
      })
      .catch((error) => {
        throw new Error(
          `${t("pay:errorMessage:invalidAccount")} ${error.replace(
            backendErrorRegex,
            ""
          )}`
        );
      });
  }, []);

  const createExternalLink = (values: IExternalAccountFormValues) => {
    axiosApi
      .post(`${backendUrl}axyapay/bankAccounts`, {
        account_number: values.accountNumber,
        routing_number: `${values.transitNumber}-${values.institutionNumber}`,
      })
      .then(async (response: any) => {
        if (response.status === 200) {
          const data = await response.json();

          setIsBusinessProfileMissing(false);
          setIsExternalAccountMissing(false);
        }
      })
      .catch((error) => {
        throw new Error(
          `${t("pay:errorMessage:invalidAccount")} ${error.replace(
            backendErrorRegex,
            ""
          )}`
        );
      });
  };

  const onOnboardButtonClick = () => {
    setOnboardMsgBtn("");

    axiosApi
      .post(`${backendUrl}axyapay/myAccount`)
      .then(async (response: any) => {
        if (response.status === 200) {
          const data = await response.json();
          window.location.href = data.url;
        }
      })
      .catch((error) => {
        throw new Error(
          `${t("pay:errorMessage:invalidAccount")} ${error.replace(
            backendErrorRegex,
            ""
          )}`
        );
      });
  };

  return (
    <Box className="c-pay-main">
      <Box className="c-pay-main__body">
        <Typography variant="h3">{t("pay:payMain:title")}</Typography>
        <Box m={2} />
        {isBusinessProfileMissing && (
          <BusinessProfileMissing
            onboardMsgBtn={onboardMsgBtn}
            onOnboardButtonClick={onOnboardButtonClick}
          />
        )}
        {isExternalAccountMissing && !isBusinessProfileMissing && (
          <Formik
            render={(props) => <ExternalAccountForm {...props} />}
            initialValues={initialValues}
            onSubmit={createExternalLink}
            validationSchema={validationSchema}
          />
        )}

        {!isExternalAccountMissing && !isBusinessProfileMissing && (
          <Typography variant="body1">
            <CheckIcon fontSize="large" />{" "}
            {t("pay:payMain:configurationMessage")}
          </Typography>
        )}
      </Box>
    </Box>
  );
};
