import {
  Box,
  Divider,
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
  Theme,
  Typography,
} from "@mui/material";
import { Form, FormikProps } from "formik";
import React from "react";
import { useTranslation } from "react-i18next";
import CustomButton from "@next/components/custom-button/custom-button";
import { GenericTextInput } from "components/utils/input/text";
import { PreviewLineItemChanges } from "../../preview-line-item-changes/preview-line-item-changes";
import { OrderLineItemChanges } from "@next/modules/orders/redux";
import {
  FinalizeReviewOptions,
  OrderDetailFinalizeReviewFormState,
} from "./order-detail-finalize-review-form.definitions";
import InlineFilePickerButton from "@next/components/inline-file-picker-button";
import { createStyles, makeStyles } from "@mui/styles";
import { useSelector } from "react-redux";
import {
  selectReviewRevisionProposalLoading,
  selectUpdateOrderLineItemsLoading,
  selectUploadOrderFileLoading,
} from "@next/modules/orders/redux/selectors";
import { getErpConfiguration } from "services/part-configuration/part-configuration.selectors";
import { ErpConfigurationType } from "@next/modules/app/components/app-bar-next/app-bar-next-erp-configuration";

const useStyles = makeStyles<Theme>((theme: Theme) =>
  createStyles({
    attachmentButton: {
      width: "max-content",
      height: 40,
    },
  })
);

export const OrderDetailFinalizeReviewFormBase: React.FC<
  FormikProps<OrderDetailFinalizeReviewFormState> & {
    onClose: () => void;
    lineItemsChanges?: OrderLineItemChanges[];
    asModal?: boolean;
  }
> = ({
  handleSubmit,
  onClose,
  lineItemsChanges,
  asModal,
  values,
  setFieldValue,
}) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const submitRevisionLoading = useSelector(
    selectReviewRevisionProposalLoading
  );
  const erpConfiguration = useSelector(getErpConfiguration);
  const connectedErpType: ErpConfigurationType =
    erpConfiguration?.erp_type?.value;
  const fileLoading = useSelector(selectUploadOrderFileLoading);
  const updateOrderLineItemsLoading = useSelector(
    selectUpdateOrderLineItemsLoading
  );
  const isLineItemsChanges = lineItemsChanges?.length > 0;

  return (
    <Form onSubmit={handleSubmit}>
      <Box
        display={"flex"}
        flexDirection={"column"}
        gap={"24px"}
        p={asModal ? "24px" : "0px"}
      >
        {!asModal && isLineItemsChanges && (
          <PreviewLineItemChanges
            lineItemsChanges={lineItemsChanges}
            padding="0px"
          />
        )}
        {isLineItemsChanges ? (
          connectedErpType === ErpConfigurationType.GENIUS ? (
            <Typography variant="body2">
              {t("orders:orderDetail:finalizeReviewModal:erpIntegrated")}
            </Typography>
          ) : (
            <FormControl>
              <RadioGroup
                aria-labelledby="demo-controlled-radio-buttons-group"
                name="reviewOptions"
                value={values.reviewOptions}
                onChange={(e) =>
                  setFieldValue(
                    "reviewOptions",
                    e.target.value as FinalizeReviewOptions
                  )
                }
              >
                <FormControlLabel
                  value={FinalizeReviewOptions.ACCEPT_AND_REVISE}
                  control={<Radio />}
                  label={t(
                    "orders:orderDetail:finalizeReviewModal:acceptAndRevise"
                  )}
                />
                <FormControlLabel
                  value={FinalizeReviewOptions.SIMPLY_ACCEPT}
                  control={<Radio />}
                  label={t(
                    "orders:orderDetail:finalizeReviewModal:simplyAccept"
                  )}
                />
              </RadioGroup>
            </FormControl>
          )
        ) : null}
        <GenericTextInput
          name="note"
          size={!asModal ? "small" : "medium"}
          label={
            asModal
              ? t("orders:orderDetail:finalizeReviewModal:addNote")
              : t("orders:orderDetail:finalizeReviewModal:noteLabel")
          }
          rows={asModal ? "2" : "0"}
        />
        {values.reviewOptions === FinalizeReviewOptions.ACCEPT_AND_REVISE && (
          <InlineFilePickerButton
            file={values.file}
            onFileAdded={(file) => setFieldValue("file", file)}
            onFileRemoved={() => setFieldValue("file", null)}
            btnProps={{ variant: "outlined", size: "large" }}
            rootClassName={classes.attachmentButton}
            multiple={false}
            pickerButtonText={t(
              "orders:orderDetail:finalizeReviewModal:poFileButton"
            )}
            accept=".pdf"
          />
        )}
      </Box>

      {asModal && <Divider />}

      {asModal && (
        <PreviewLineItemChanges
          lineItemsChanges={lineItemsChanges}
          lockDecision
        />
      )}
      {asModal && <Divider />}
      <Box
        display="flex"
        justifyContent={asModal ? "flex-end" : "space-between"}
        px={asModal ? 2 : 0}
        py={3}
      >
        <CustomButton
          variant="outlined"
          color="error"
          onClick={onClose}
          sx={{ marginRight: "16px" }}
        >
          {t("common:cancel")}
        </CustomButton>

        <CustomButton
          type="submit"
          variant="contained"
          color="primary"
          disabled={
            (values.reviewOptions === FinalizeReviewOptions.ACCEPT_AND_REVISE &&
              !values.file) ||
            !isLineItemsChanges
          }
          loading={
            submitRevisionLoading || fileLoading || updateOrderLineItemsLoading
          }
        >
          {t("common:submit")}
        </CustomButton>
      </Box>
    </Form>
  );
};
