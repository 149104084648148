import React, { useCallback } from "react";
import {
  GridCellModes,
  GridCellModesModel,
  GridCellParams,
  GridRowModel,
} from "@mui/x-data-grid-pro-v5";
import { v4 as uuidv4 } from "uuid";
import {
  InternationalStandardTableRow,
  PartialStep,
  vendorManagementActions,
} from "@next/modules/vendor-management/redux";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import {
  selectDeleteProcessControlStandardLoading,
  selectIsFormCompleted,
  selectProcessControlStandards,
  selectProcessControlStandardsLoading,
  selectVisibleSteps,
} from "@next/modules/vendor-management/redux/selectors";
import { getInternationalStandardTableColumns } from "./international-standard-table.columns";
import { getActiveStepIndex } from "@next/modules/vendor-management/utils/getActiveStepIndex";
import * as S from "./international-standard-table.styled";

export const InternationalStandardTable: React.FC = () => {
  const { companyId } = useParams<{ companyId: string }>();
  const dispatch = useDispatch();
  const isFormCompleted = useSelector(selectIsFormCompleted);
  const steps = useSelector(selectVisibleSteps);
  const activeFormStepIndex = getActiveStepIndex(steps);
  const columns = getInternationalStandardTableColumns();
  const rows = useSelector(selectProcessControlStandards);
  const isLoadingFetchTableRows = useSelector(
    selectProcessControlStandardsLoading
  );
  const isLoadingDeleteProcessControlStandard = useSelector(
    selectDeleteProcessControlStandardLoading
  );
  const [cellModesModel, setCellModesModel] =
    React.useState<GridCellModesModel>({});

  const handleCellClick = useCallback(
    (params: GridCellParams) => {
      if (params.field === "removeRowButton") {
        const id = params.row.standard_id;
        dispatch(
          vendorManagementActions.deleteProcessControlStandardRequest({
            standardId: id,
            companyId: companyId,
          })
        );
        checkInputChange();
      }

      if (!params.colDef.editable) {
        return;
      }

      setCellModesModel((prevModel) => {
        return {
          // Revert the mode of the other cells from other rows
          ...Object.keys(prevModel).reduce(
            (acc, id) => ({
              ...acc,
              [id]: Object.keys(prevModel[id]).reduce(
                (acc2, field) => ({
                  ...acc2,
                  [field]: { mode: GridCellModes.View },
                }),
                {}
              ),
            }),
            {}
          ),
          [params.id]: {
            // Revert the mode of other cells in the same row
            ...Object.keys(prevModel[params.id] || {}).reduce(
              (acc, field) => ({
                ...acc,
                [field]: { mode: GridCellModes.View },
              }),
              {}
            ),
            [params.field]: { mode: GridCellModes.Edit },
          },
        };
      });
    },
    [isFormCompleted, rows]
  );

  const handleCellModesModelChange = useCallback((newModel) => {
    setCellModesModel(newModel);
  }, []);

  const processRowUpdate = (newRow: GridRowModel, oldRow: GridRowModel) => {
    const standard_id = newRow?.standard_id;
    let updatedField = null;
    let updatedFieldKey = null;
    Object.keys(newRow).forEach((key) => {
      if (newRow[key] !== oldRow[key]) {
        updatedField = { [key]: newRow[key] };
        updatedFieldKey = [key];
      }
    });

    if (updatedField) {
      const standard: InternationalStandardTableRow = {
        ...updatedField,
        standard_id: standard_id,
      };
      dispatch(
        vendorManagementActions.updateProcessControlStandardRequest({
          companyId: companyId,
          standard: standard,
        })
      );
      checkInputChange();
    }

    return newRow;
  };

  const checkInputChange = () => {
    if (isFormCompleted) {
      updateFieldStatusOfStep("is_completed", false);
    } else {
      updateFieldStatusOfStep("is_partially_filled", true);
    }
  };

  const updateFieldStatusOfStep = (
    key: "is_completed" | "is_partially_filled",
    value: boolean
  ) => {
    const step = steps[activeFormStepIndex];
    const partialStep: PartialStep = {
      [key]: value,
    };
    dispatch(
      vendorManagementActions.updateStepRequest({
        stepId: step.id,
        data: partialStep,
      })
    );
  };

  return (
    <S.StyledDataGridPro
      autoHeight
      columns={columns}
      rows={rows || []}
      hideFooter
      headerHeight={48}
      rowHeight={32}
      disableSelectionOnClick
      cellModesModel={cellModesModel}
      onCellModesModelChange={handleCellModesModelChange}
      onCellClick={handleCellClick}
      experimentalFeatures={{ newEditingApi: true }}
      processRowUpdate={processRowUpdate}
      getRowId={(row) => row?.standard_id || uuidv4()}
      loading={isLoadingFetchTableRows || isLoadingDeleteProcessControlStandard}
    />
  );
};
