import React from "react";

import MuiPhoneNumber from "material-ui-phone-number";

import { useField } from "formik";

type colorType = "primary" | "secondary" | undefined;

export interface GenericPhoneNumberInputProps {
  placeholder?: string;
  rows?: string;
  label?: string;
  onChange?: (value: any) => void;
  onBlur?: (event: any) => void;
  disabled?: boolean;
  color?: colorType;
  name?: string;
  value?: string | number;
  className?: string;
  defaultCountry?: string;
  onlyCountries?: string[];
  ignorePasswordManager?: boolean;
  size?: "small" | "medium";
}

export const GenericPhoneNumberInput: React.FC<GenericPhoneNumberInputProps> = (
  props
) => {
  const [field, meta] = useField(props as string);
  const errorText = meta.error && meta.touched ? meta.error : "";
  const {
    color,
    placeholder,
    rows,
    label,
    className,
    disabled,
    name,
    onBlur,
    defaultCountry,
    onlyCountries,
    ignorePasswordManager = true,
    size,
    onChange,
  } = props;

  return (
    <MuiPhoneNumber
      color={color}
      placeholder={placeholder}
      label={label}
      rows={rows}
      fullWidth
      defaultCountry={defaultCountry}
      onlyCountries={onlyCountries}
      multiline={!!rows}
      className={className}
      helperText={errorText}
      error={!!errorText}
      variant="outlined"
      disabled={disabled}
      size={size}
      {...field}
      name={name}
      onBlur={onBlur}
      inputProps={{ "data-lpignore": ignorePasswordManager }}
      onChange={onChange}
    />
  );
};
