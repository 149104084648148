import React from "react";
import {
  getModal,
  modalsActions as modalsActionsNext,
} from "@next/redux/modalsSlices";

import { batch, useDispatch, useSelector } from "react-redux";
import { MarketplaceModalTypes } from "./types";
import Modal from "@next/components/custom-modal";
import { JoinPrivateB2BNetwork } from "../components/join-private-b2b-network/join-private-b2b-network";
import { useTranslation } from "react-i18next";

type Props = {
  handleClose?: () => void;
};

const JoinPrivateB2BNetworkModal: React.FC = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const modal = useSelector(
    getModal(MarketplaceModalTypes.JOIN_PRIVATE_B2B_NETWORK_MODAL)
  );
  const data = modal?.data as Props;

  const onClose = () => {
    batch(() => {
      dispatch(
        modalsActionsNext.closeModal(
          MarketplaceModalTypes.JOIN_PRIVATE_B2B_NETWORK_MODAL
        )
      );
      if (data?.handleClose) data.handleClose();
    });
  };

  if (!modal) return null;

  return (
    <Modal
      handleModalClose={onClose}
      dialogProps={{
        BackdropProps: {
          classes: {
            root: "backdrop-blur",
          },
        },
      }}
      customWidth="572px"
      modalState={{
        modalHeader: t("marketPlace:joinPrivateB2BNetwork:title"),
        modalBody: <JoinPrivateB2BNetwork />,
      }}
      isModalOpen={!!modal}
    />
  );
};

export default JoinPrivateB2BNetworkModal;
