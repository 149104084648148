import * as React from "react";

import { useStyledBadge } from "./badge-wrapper.hooks";

interface BadgeWrapperProps {
  children?: React.ReactNode;
  badgeContent?: number;
  invisible?: boolean;
  color?: "default" | "primary" | "secondary" | "error" | undefined;
  right?: string;
  top?: string;
}

const BadgeWrapper: React.FC<BadgeWrapperProps> = ({
  children,
  badgeContent,
  color,
  invisible,
  right,
  top,
}) => {
  const StyledBadge = useStyledBadge({ right, top });

  return (
    <>
      <StyledBadge
        invisible={invisible}
        badgeContent={badgeContent}
        color={color}
      >
        {children}
      </StyledBadge>
    </>
  );
};

export default BadgeWrapper;
