import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import Modal from "@next/components/custom-modal";
import { getModal, modalsActions } from "@next/redux/modalsSlices";
import { WorkspaceModalTypes } from "../types";
import RFQPartsRevisionForm from "./rfq-parts-revision-form";

import { createStyles, makeStyles } from "@mui/styles";

const useStyles = makeStyles(() =>
  createStyles({
    paper: {
      width: 648,
    },
    dialogContent: {
      overflowX: "hidden",
    },
  })
);

export const RFQPartsRevisionModal = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const classes = useStyles();
  const modal = useSelector(
    getModal(WorkspaceModalTypes.RFQ_PARTS_REVISION_MODAL)
  );

  const { onSuccess } = (modal?.data || {}) as { onSuccess: () => void };

  if (!modal) return null;

  const onClose = () => {
    dispatch(
      modalsActions.closeModal(WorkspaceModalTypes.RFQ_PARTS_REVISION_MODAL)
    );
  };

  return (
    <Modal
      handleModalClose={onClose}
      modalState={{
        modalHeader: t("rfq:partsRevisionModal:title"),
        modalBody: <RFQPartsRevisionForm onSuccess={onSuccess} />,
        dialogContentClassName: classes.dialogContent,
      }}
      dialogProps={{ fullWidth: true, classes: { paper: classes.paper } }}
      isModalOpen={true}
    />
  );
};
