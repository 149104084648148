import { Box, Theme, Tooltip } from "@mui/material";
import { createStyles, makeStyles } from "@mui/styles";
import React, { ReactNode, useMemo } from "react";
import GenericAvatar from "../generic-avatar";
import { GenericAvatarProps } from "../generic-avatar/generic-avatar";
import { VisibilityBadge } from "./visibility-badge";

type StyleProps = {
  overlapping?: boolean;
};

const useStyles = makeStyles<Theme, StyleProps>((theme: Theme) => {
  return createStyles({
    avatar: {
      boxSizing: "content-box",
      marginLeft: ({ overlapping }) => (overlapping ? -8 : 5),
      border: ({ overlapping }) =>
        overlapping ? `2px solid ${theme.palette.common.white}` : "none",
      overflow: "hidden",
    },
  });
});

type NamedAvatarProps = {
  seenDate?: string;
  showVisibilityBadge?: boolean;
};

const NamedAvatar: React.FC<
  Partial<GenericAvatarProps> & StyleProps & NamedAvatarProps
> = ({
  url,
  overlapping,
  seenDate,
  showVisibilityBadge,
  avatarProps,
  ...props
}) => {
  const classes = useStyles({ overlapping });

  return (
    <GenericAvatar
      url={url || ""}
      {...props}
      avatarProps={{ ...avatarProps, className: classes.avatar }}
      badge={showVisibilityBadge}
      badgeProps={{
        anchorOrigin: {
          vertical: "bottom",
          horizontal: overlapping ? "left" : "right",
        },
        badgeContent: showVisibilityBadge ? (
          <VisibilityBadge seenDate={seenDate} />
        ) : null,
      }}
    />
  );
};

function getCombinedTooltipTitle(
  images: Partial<GenericAvatarProps>[],
  combinedTooltipTitle: Props["remainingTooltip"]
) {
  if (!combinedTooltipTitle) return "";

  if (typeof combinedTooltipTitle === "function") {
    return combinedTooltipTitle(images);
  }
  if (typeof combinedTooltipTitle === "string") {
    return combinedTooltipTitle;
  }

  if (images[0].tooltip) {
    return (
      <Box
        alignItems="center"
        display="flex"
        flexDirection="column"
        whiteSpace="pre-line"
      >
        {images.map(({ tooltip }, ix) => (
          <span key={ix}>{tooltip}</span>
        ))}
      </Box>
    );
  }

  return "";
}

type CombinedTooltip =
  | ReactNode
  | ((images: Partial<GenericAvatarProps>[]) => ReactNode)
  | boolean;

type Props = {
  images: Partial<GenericAvatarProps>[];
  size?: GenericAvatarProps["size"];
  maxToShow?: number;
  remainingTooltip?: CombinedTooltip;
  combinedTooltip?: CombinedTooltip;
  views?: { [key: string]: string };
  showVisibilityBadge?: boolean;
} & StyleProps;

const CircularAvatarsList: React.FC<Props> = ({
  images,
  maxToShow = 3,
  overlapping = true,
  size,
  remainingTooltip,
  combinedTooltip,
  views,
  showVisibilityBadge,
}) => {
  const remaining = Math.max(images.length - maxToShow, 0);
  const remainingImages = useMemo(() => {
    return images.slice(maxToShow);
  }, [images, maxToShow]);

  const node = (
    <Box display="flex" ml={overlapping ? "8px" : "-5px"}>
      {images.map(({ url, name, email, tooltip, avatarProps }, ix) => {
        return ix < maxToShow ? (
          <NamedAvatar
            key={ix + (name || "")}
            url={url}
            name={name}
            overlapping={overlapping}
            size={size}
            tooltip={combinedTooltip ? undefined : tooltip}
            seenDate={views && views[`${email}`]}
            showVisibilityBadge={showVisibilityBadge}
            avatarProps={avatarProps}
          />
        ) : null;
      })}

      {remaining ? (
        <NamedAvatar
          url=""
          exactName={remaining.toString()}
          overlapping={overlapping}
          size={size}
          tooltip={
            combinedTooltip
              ? undefined
              : getCombinedTooltipTitle(remainingImages, remainingTooltip)
          }
        />
      ) : null}
    </Box>
  );

  return combinedTooltip && images.length ? (
    <Tooltip title={getCombinedTooltipTitle(images, combinedTooltip)}>
      {node}
    </Tooltip>
  ) : (
    node
  );
};

export default CircularAvatarsList;
