import { GridColDef, GridRenderEditCellParams } from "@mui/x-data-grid-pro-v5";
import { GridApiPro } from "@mui/x-data-grid-pro-v5/models/gridApiPro";
import React, { MutableRefObject } from "react";

import { t } from "assets/configi18n/i18n";
import { AddOrderPartsTableField } from "./add-order-parts-table.types";
import { formatDate } from "helpers/formatDate";
import { RowMenuCell } from "@next/components/table-cells/row-menu-cell-v5";
import MUIDatePicker from "@next/components/mui-date-picker/mui-date-picker";
import NumberCell from "@next/components/table-cells/number-cell-v5";

const defaultColumnConfig: Partial<GridColDef> = {
  disableColumnMenu: true,
  sortable: false,
  hideSortIcons: true,
  editable: true,
};

export const getAddOrderPartsTableColumns = (
  api: MutableRefObject<GridApiPro>
) => {
  const DateEditCell: React.FC<GridRenderEditCellParams> = ({
    id,
    field,
    value,
  }) => {
    const onChange = (date: any) => {
      api.current.setEditCellValue({
        id,
        field,
        value: formatDate(date, "YYYY-MM-DD"),
      });
    };

    return (
      <MUIDatePicker
        value={(value as any) || formatDate(new Date(), "YYYY-MM-DD")}
        onChange={onChange}
        inputVariant="standard"
        InputProps={{
          disableUnderline: true,
          margin: "none",
          style: { fontSize: 14, marginTop: "4px", marginLeft: "9px" },
        }}
        disablePast={false}
        variant="inline"
        disableIgnoreTimezone={true}
      />
    );
  };

  const _columns: GridColDef[] = [
    {
      ...defaultColumnConfig,
      field: AddOrderPartsTableField.PART_NUMBER,
      flex: 1,
      headerName: t("orders:addOrderParts:partNumber"),
    },
    {
      ...defaultColumnConfig,
      field: AddOrderPartsTableField.DESCRIPTION,
      flex: 1,
      headerName: t("orders:addOrderParts:description"),
    },
    {
      ...defaultColumnConfig,
      field: AddOrderPartsTableField.QUANTITY,
      flex: 1,
      headerName: t("orders:addOrderParts:quantity"),
      type: "number",
      renderCell: NumberCell,
      align: "left",
      headerAlign: "left",
    },
    {
      ...defaultColumnConfig,
      field: AddOrderPartsTableField.DELIVERY_DATE,
      width: 200,
      headerName: t("orders:addOrderParts:requiredReceptionDate"),
      type: "date",
      valueFormatter: (params) => formatDate(params?.value),
      renderEditCell: DateEditCell,
    },
    {
      field: "actions",
      headerName: "Actions",
      renderCell: RowMenuCell,
      sortable: false,
      width: 100,
      headerAlign: "center",
      filterable: false,
      align: "center",
      disableColumnMenu: true,
      disableReorder: true,
    },
  ];

  return _columns;
};
