import React from "react";

interface LeadTimeProps {
  value: string;
}

export const LeadTime: React.FC<LeadTimeProps> = ({ value }) => {
  return !value ? (
    <span className="blurred-content"></span>
  ) : (
    <span>{value}</span>
  );
};
