import React from "react";
import { Box, Button, Dialog, DialogActions, DialogTitle } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { getModal, modalsActions } from "@next/redux/modalsSlices";
import { useTranslation } from "react-i18next";
import { WorkspaceModalTypes } from "@next/modules/workspace/modals/types";
import { Typography } from "@material-ui/core";
import { createStyles, makeStyles } from "@mui/styles";

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      height: "200px",
      padding: "10px 12px",
      display: "flex",
      minWidth: "560px",
      flexDirection: "column",
      gap: "5px",
    },
    actionButtonsStyle: {
      padding: "10px",
    },
  })
);

export const ProjectIsEditRFQCancelModal = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const classes = useStyles();

  const modal = useSelector(
    getModal(WorkspaceModalTypes.CANCEL_EDIT_RFQ_MODAL)
  );

  if (!modal) return null;

  const backToModification = () => {
    dispatch(
      modalsActions.closeModal(WorkspaceModalTypes.CANCEL_EDIT_RFQ_MODAL)
    );
  };

  const onCancel = () => {
    dispatch(
      modalsActions.closeModal(
        WorkspaceModalTypes.RFQ_ADD_FILES_AND_PARTS_MODAL
      )
    );
    //if parts modal is close, this nested modal will also be closed
    dispatch(
      modalsActions.closeModal(WorkspaceModalTypes.CANCEL_EDIT_RFQ_MODAL)
    );
  };
  return (
    <Dialog
      open={!!modal}
      onClose={backToModification}
      closeAfterTransition
      maxWidth="md"
    >
      <Box className={classes.root}>
        <DialogTitle display="flex" flexDirection="column" gap="20px">
          <Box flexGrow={1}>{t("project:cancelChanges")}</Box>
          <Typography variant="body2">
            {t("project:cancelChangesLabel")}
          </Typography>
        </DialogTitle>
        <DialogActions>
          <Button
            variant="text"
            className={classes.actionButtonsStyle}
            onClick={backToModification}
          >
            {t("project:actionModification")}
          </Button>
          <Button
            color="primary"
            variant="contained"
            className={classes.actionButtonsStyle}
            onClick={onCancel}
          >
            {t("project:actionCancelChanges")}
          </Button>
        </DialogActions>
      </Box>
    </Dialog>
  );
};
