import React from "react";
import { Avatar, Box } from "@mui/material";
import { GridRenderCellParams } from "@mui/x-data-grid-pro-v5";
import { getInitials } from "@next/utils/stringUtils";
import { CustomTypography } from "../custom-typography";

const BuyerCell: React.FC<GridRenderCellParams> = (params: any) => {
  return (
    <Box display="flex" alignItems="center">
      <Avatar
        src={params?.row?.company_logo}
        style={{ width: "32px", height: "32px" }}
      >
        {getInitials(params.value as string)}
      </Avatar>

      <Box ml="8px">
        <CustomTypography variant="caption">{params?.value}</CustomTypography>
      </Box>
    </Box>
  );
};

export default BuyerCell;
