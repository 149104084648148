import React from "react";
import { makeStyles } from "@mui/styles";
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import { LeadTime } from "@next/components/lead-time/lead-time";

const useStyles = makeStyles({
  table: {
    minWidth: 650,
  },
});

export default function StateDelayTable({ data, t, language }) {
  const classes = useStyles();
  const formtDate = (date) => date.toISOString().slice(0, 10);
  return (
    <TableContainer component={Paper}>
      <Table className={classes.table} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell>{t("ranking")}</TableCell>
            <TableCell align="center">{t("supplier")}</TableCell>
            <TableCell align="center">{t("productionDelay")}</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {data &&
            data.map((row, i) => (
              <TableRow key={row.company_name}>
                <TableCell component="th" scope="row">
                  #{i + 1}
                </TableCell>
                <TableCell
                  align="center"
                  className={`${row.mine ? "c-my-company" : ""} ${
                    row.company_name.includes("Winner") ||
                    row.company_name.includes("Gagnant")
                      ? "c-winner-company"
                      : ""
                  }`}
                >
                  {row.company_name}
                </TableCell>
                <TableCell align="center">
                  <LeadTime value={row?.lead_time} />
                </TableCell>
              </TableRow>
            ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
