/* eslint-disable react-hooks/exhaustive-deps */
import { useGridApiRef } from "@mui/x-data-grid-pro-v5";
import { getOrderDetailLineItemsTableColumns } from "./order-detail-line-items-table.columns";
import { useSelector } from "react-redux";
import { selectOrderByIsPortal } from "@next/modules/orders/redux/selectors";
import { PO_PART_GRID_STATE } from "@next/constants/data-grid-state-constants";
import useLocalStorageGridState from "@next/hooks/useLocalStorageGridState-v5";

export const ORDER_DETAIL_LINE_ITEMS_TABLE_STATE_KEY =
  "ORDER_DETAIL_LINE_ITEMS_TABLE_STATE";

export const useOrderDetailLineItemsTableData = (isPortal: boolean) => {
  const apiRef = useGridApiRef();
  const orderDetail = useSelector(selectOrderByIsPortal(isPortal));
  const hidden_fields = orderDetail.hidden_fields;
  const orderDetailLineItems = orderDetail?.line_items || [];
  const orderDetailLineItemFields = orderDetail?.line_item_fields || [];
  const isDraftOrder = orderDetail?.is_sent === false;
  const { restoreState } = useLocalStorageGridState(PO_PART_GRID_STATE);

  const orderDetailLineItemsTableColumns = restoreState(
    getOrderDetailLineItemsTableColumns(
      orderDetailLineItemFields,
      isDraftOrder,
      isPortal,
      orderDetail?.pk,
      hidden_fields
    )
  );

  return {
    gridData: {
      rows: orderDetailLineItems,
      columns: orderDetailLineItemsTableColumns,
    },
    apiRef,
    orderDetail,
  };
};
