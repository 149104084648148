import { call, put, select, take, takeLatest } from "redux-saga/effects";
import { getUser } from "services/profile/profile.selectors";
import { modalsActions } from "@next/redux/modalsSlices";
import { SharedModalTypes } from "@next/modals/types";
import { profileActions, profileConstants } from "services/profile";
import { appActions } from "./slices";
import { PayloadAction } from "@reduxjs/toolkit";
import {
  CheckUserEmailInput,
  FetchNotificationsInput,
  MarkAsSeenNotificationInput
} from "./types";
import { safeCall } from "@next/redux/safeCall";
import { appService } from "../services/appService";
import snackbarUtils from "@next/utils/snackbarUtils";

function* handleProjectIntroductionModalRequest(): any {
  try {
    // Wait for user to be loaded
    yield take(profileConstants.USER_PROFILE_SUCCESS);

    const currentUser = yield select(getUser);

    if (currentUser) {
      const storageKey = `ProjectIntroductionModalSeen_${currentUser.username}`;
      const isSeen = localStorage.getItem(storageKey);
      if (isSeen === "true") {
        yield put(appActions.verifyProjectIntroductionModalSuccess());
      } else {
        yield put(
          modalsActions.showModal({
            key: SharedModalTypes.PROJECT_INTRODUCTION_MODAL
          })
        );

        localStorage.setItem(storageKey, "true");
        yield put(appActions.verifyProjectIntroductionModalSuccess());
      }
    } else {
      yield put(
        appActions.verifyProjectIntroductionModalFailure(
          new Error("User not found")
        )
      );
    }
  } catch (e) {
    yield put(appActions.verifyProjectIntroductionModalFailure(e as Error));
  }
}

function* handleCheckUserEmailRequest({
  payload
}: PayloadAction<CheckUserEmailInput>) {
  const { response, error } = yield safeCall(
    appService.checkUserEmail,
    payload
  );

  if (error) {
    yield put(appActions.checkUserEmailFailure(error));
  } else {
    const responseData = response?.data;

    if (responseData?.exists && responseData?.redirect) {
      window.location.href = responseData?.redirect;
    }

    yield put(appActions.checkUserEmailSuccess());
  }
}

function* handleFetchNotificationsRequest({
  payload
}: PayloadAction<FetchNotificationsInput>) {
  const { response, error } = yield safeCall(
    appService.fetchNotifications,
    payload
  );

  if (error) {
    yield put(appActions.fetchNotificationsFailure(error));
  } else {
    yield put(
      appActions.fetchNotificationsSuccess({
        results: response?.data?.results,
        count: response?.data?.count,
        next: response?.data?.next,
        page: payload.page
      })
    );
  }
}

function* handleMarkAsSeenNotificationRequest({
  payload
}: PayloadAction<MarkAsSeenNotificationInput>) {
  const { response, error } = yield safeCall(
    appService.markAsSeenNotification,
    payload
  );

  if (error) {
    yield put(appActions.markAsSeenNotificationFailure(error));
  } else {
    const callFetchUserProfile: ReturnType<
      typeof profileActions.fetchUserProfile
    > = yield call(profileActions.fetchUserProfile);

    yield put(callFetchUserProfile as any);

    yield put(
      appActions.markAsSeenNotificationSuccess({
        notificationId: payload.notificationId
      })
    );
  }
}

function* handlMarkAllAsSeenNotificationRequest() {
  const { response, error } = yield safeCall(
    appService.markAllAsSeenNotification
  );

  if (error || response?.data !== "OK") {
    yield put(appActions.markAllAsSeenNotificationFailure(error));
  } else {
    const callFetchUserProfile: ReturnType<
      typeof profileActions.fetchUserProfile
    > = yield call(profileActions.fetchUserProfile);

    yield put(callFetchUserProfile as any);

    yield snackbarUtils.toast("Marked all as seen");

    yield put(appActions.markAllAsSeenNotificationSuccess());
  }
}

export function* appSaga() {
  yield takeLatest(
    appActions.verifyProjectIntroductionModalRequest,
    handleProjectIntroductionModalRequest
  );

  yield takeLatest(
    appActions.checkUserEmailRequest,
    handleCheckUserEmailRequest
  );

  yield takeLatest(
    appActions.fetchNotificationsRequest,
    handleFetchNotificationsRequest
  );

  yield takeLatest(
    appActions.markAsSeenNotificationRequest,
    handleMarkAsSeenNotificationRequest
  );

  yield takeLatest(
    appActions.markAllAsSeenNotificationRequest,
    handlMarkAllAsSeenNotificationRequest
  );
}
