import React from "react";

import { alpha } from "@mui/material";
import { OrderShipmentStatus } from "@next/modules/orders/redux";
import { AppThemeV5 } from "layout/AppTheme";
import {
  Autorenew,
  Error as ErrorIcon,
  LocalShipping,
  PlayCircleFilled,
  WatchLater,
} from "@mui/icons-material";
import { t } from "assets/configi18n/i18n";
import { formatDate } from "helpers/formatDate";

const baseStyle: React.CSSProperties = {
  cursor: "unset",
  textOverflow: "ellipsis",
  overflow: "hidden",
};

export const getOrderDetailShipmentPartsTableTagStyle = (
  stateSlug?: OrderShipmentStatus
): React.CSSProperties | undefined => {
  switch (stateSlug) {
    case "shipped":
      return {
        ...baseStyle,
        color: AppThemeV5.palette.success.dark,
        backgroundColor: alpha(AppThemeV5.palette.success.main, 0.1),
      };
    case "late":
      return {
        ...baseStyle,
        color: AppThemeV5.palette.error.dark,
        backgroundColor: alpha(AppThemeV5.palette.error.main, 0.1),
      };
    case "on_track":
      return {
        ...baseStyle,
        color: AppThemeV5.palette.primary.dark,
        backgroundColor: alpha(AppThemeV5.palette.primary.main, 0.1),
      };
    case "updated":
      return {
        ...baseStyle,
        color: AppThemeV5.palette.warning.dark,
        backgroundColor: alpha(AppThemeV5.palette.warning.main, 0.1),
      };
    case "at_risk":
      return {
        ...baseStyle,
        color: AppThemeV5.palette.warning.dark,
        backgroundColor: alpha(AppThemeV5.palette.warning.light, 0.1),
      };
    case "cancelled":
      return {
        ...baseStyle,
        color: AppThemeV5.palette.warning.dark,
        backgroundColor: alpha(AppThemeV5.palette.warning.light, 0.1),
      };
    case "waiting_for_acknowledgment":
      return {
        ...baseStyle,
        color: AppThemeV5.palette.primary.dark,
        backgroundColor: alpha(AppThemeV5.palette.primary.main, 0.1),
      };
    default:
      return undefined;
  }
};

export const getOrderDetailShipmentPartsTableTagIcon = (
  stateSlug?: OrderShipmentStatus
): React.ReactElement | undefined => {
  switch (stateSlug) {
    case "shipped":
      return (
        <LocalShipping style={{ color: AppThemeV5.palette.success.main }} />
      );
    case "late":
      return <WatchLater style={{ color: AppThemeV5.palette.error.main }} />;
    case "on_track":
      return (
        <PlayCircleFilled style={{ color: AppThemeV5.palette.primary.main }} />
      );
    case "updated":
      return <Autorenew style={{ color: AppThemeV5.palette.warning.main }} />;
    case "at_risk":
      return <ErrorIcon style={{ color: AppThemeV5.palette.warning.main }} />;
    case "cancelled":
      return <ErrorIcon style={{ color: AppThemeV5.palette.warning.main }} />;
    case "waiting_for_acknowledgment":
      return <WatchLater style={{ color: AppThemeV5.palette.primary.main }} />;
    default:
      return undefined;
  }
};

export const getOrderDetailShipmentPartsTableTagLabel = (
  stateSlug?: OrderShipmentStatus,
  shipmentConfirmationDate?: string
): React.ReactElement | undefined => {
  switch (stateSlug) {
    case "shipped":
      return shipmentConfirmationDate
        ? t("orders:orderDetail:tag:shippedOn", {
            date: formatDate(shipmentConfirmationDate),
          })
        : t("orders:orderDetail:tag:shipped");
    case "late":
      return t("orders:orderDetail:tag:late");
    case "on_track":
      return t("orders:orderDetail:tag:onTrack");
    case "updated":
      return t("orders:orderDetail:tag:waitingReview");
    case "at_risk":
      return t("orders:orderDetail:tag:atRisk");
    case "cancelled":
      return t("orders:orderDetail:tag:cancelled");
    case "waiting_for_acknowledgment":
      return t("orders:orderDetail:tag:waitingForOrderConfirmation");
    default:
      return undefined;
  }
};
