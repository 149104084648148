import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import {
  AppState,
  CheckUserEmailInput,
  FetchNotificationsInput,
  MarkAsSeenNotificationInput,
  Notification,
} from "./types";
import { PURGE } from "redux-persist";
import { frontendUrl } from "urls";

export const initialState: AppState = {
  isInitialized: false,

  checkUserEmailLoading: false,
  checkUserEmailError: undefined,

  notifications: [],
  notificationsLoading: false,
  notificationsError: undefined,
  notificationsCount: 0,
  notificationsPage: 0,
  notificationIsNextPageAvailable: false,

  markAsSeenNotificationLoading: false,
  markAsSeenNotificationError: undefined,

  markAllAsSeenNotificationLoading: false,
  markAllAsSeenNotificationError: undefined,
};

const appSlice = createSlice({
  name: "appNext",
  initialState,
  reducers: {
    onAppInit: (state) => {
      state.isInitialized = true;
    },
    verifyProjectIntroductionModalRequest: (state) => state,
    verifyProjectIntroductionModalFailure: (
      state,
      _action: PayloadAction<Error>
    ) => state,
    verifyProjectIntroductionModalSuccess: (state) => state,
    checkUserEmailRequest: (
      state,
      _action: PayloadAction<CheckUserEmailInput>
    ) => {
      state.checkUserEmailLoading = true;
      state.checkUserEmailError = undefined;
    },
    checkUserEmailFailure: (state, action: PayloadAction<Error>) => {
      state.checkUserEmailLoading = false;
      state.checkUserEmailError = action.payload;
    },
    checkUserEmailSuccess: (state) => {
      state.checkUserEmailLoading = false;
      state.checkUserEmailError = undefined;
    },
    fetchNotificationsRequest: (
      state,
      action: PayloadAction<FetchNotificationsInput & { reset?: boolean }>
    ) => {
      state.notificationsLoading = true;
      state.notificationsError = undefined;
      if (action.payload.reset) {
        state.notifications = [];
      }
    },
    fetchNotificationsFailure: (state, action: PayloadAction<Error>) => {
      state.notificationsLoading = false;
      state.notificationsError = action.payload;
      state.notifications = undefined;
    },
    fetchNotificationsSuccess: (
      state,
      action: PayloadAction<{
        results: Notification[];
        count: number;
        page: number;
        next: string;
      }>
    ) => {
      state.notificationsLoading = false;
      state.notificationsError = undefined;
      state.notifications = state.notifications!.concat(action.payload.results);
      state.notificationsCount = action.payload.count;
      state.notificationsPage = action.payload.page;
      state.notificationIsNextPageAvailable = !!action.payload.next;
    },
    markAsSeenNotificationRequest: (
      state,
      _action: PayloadAction<MarkAsSeenNotificationInput>
    ) => {
      state.markAsSeenNotificationLoading = true;
      state.markAsSeenNotificationError = undefined;
    },
    markAsSeenNotificationFailure: (state, action: PayloadAction<Error>) => {
      state.markAsSeenNotificationLoading = false;
      state.markAsSeenNotificationError = action.payload;
    },
    markAsSeenNotificationSuccess: (
      state,
      action: PayloadAction<{ notificationId: number }>
    ) => {
      state.markAsSeenNotificationLoading = false;
      state.markAsSeenNotificationError = undefined;
      state.notifications = state.notifications!.map((item) => {
        if (item?.id === action.payload.notificationId) {
          return { ...item, is_seen: true };
        }

        return item;
      });
    },
    markAllAsSeenNotificationRequest: (state) => {
      state.markAllAsSeenNotificationLoading = true;
      state.markAllAsSeenNotificationError = undefined;
    },
    markAllAsSeenNotificationFailure: (state, action: PayloadAction<Error>) => {
      state.markAllAsSeenNotificationLoading = false;
      state.markAllAsSeenNotificationError = action.payload;
    },
    markAllAsSeenNotificationSuccess: (state) => {
      state.markAllAsSeenNotificationLoading = false;
      state.markAllAsSeenNotificationError = undefined;
      state.notifications = state.notifications!.map((item) => {
        return { ...item, is_seen: true };
      });
    },
  },
  extraReducers: (builder) => {
    builder.addCase(PURGE, () => {
      localStorage.removeItem("persist:root");
      window.location.href = frontendUrl.logout;
      return initialState;
    });

    builder.addDefaultCase((state) => {
      state.markAllAsSeenNotificationLoading = false;
      state.markAsSeenNotificationLoading = false;
    });
  },
});

export const appActions = appSlice.actions;
export default appSlice.reducer;
