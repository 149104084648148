/**
 * Use this component to display characteristics of parts
 */

import React from "react";

import { useSelector } from "react-redux";
import { useTracking } from "react-tracking";

import { Box, CardContent, Typography } from "@mui/material";

import { RenderUploads } from "components/utils/render-uploads/render-uploads.component";

import PartThumbnail from "components/part-dialog/thumbnail/thumbnail.component";
import PartTags from "components/part-dialog/tags/tags.component";

export const PartTilesDetails = (props) => {
  const {
    part,
    clickOpen,
    quotation: { rfqPk, rfqName },
    nda,
    t,
  } = props;

  const tracking = useTracking();
  const profile = useSelector((state) => state.profile);
  const onPartThumbClick = (e) => {
    clickOpen(e);
    /**
     * Feature tracking
     */

    tracking.trackEvent({
      scout_category: "feature",
      scout_feature_name: "quotation_file_viewer_modal",
      scout_company: profile.company.name,
      scout_username: profile.user.username,
      scout_user_type: profile.user.type,
      scout_open_state: true,
      scout_date: new Date(),
    });
  };

  return (
    <CardContent
      className={`c-part-tiles-details ${nda ? "nda-form-disable" : ""}`}
    >
      <Box>
        <Box display="flex" alignItems="center">
          <Box>
            <PartThumbnail part={part} onClick={onPartThumbClick} />
          </Box>
          <Box
            textAlign="left"
            fontWeight="fontWeightMedium"
            fontSize={20}
            className="c-part-tiles-details--name c-box"
          >
            {part.name}
          </Box>
        </Box>
      </Box>
      <Box mt={2}>
        <PartTags part={part} t={t} />
      </Box>

      <Box display="flex" justifyContent="space-between" mt="12px">
        <Box>Description</Box>

        <Typography variant="body2" style={{ fontWeight: 500 }}>
          {part.additional_details ? part.additional_details : "-"}
        </Typography>
      </Box>

      {part.part_uploads_list && (
        <Box mt={2}>
          {part.part_uploads_list.map((partUpload) => {
            return (
              <RenderUploads
                file={partUpload}
                rfqPk={rfqPk}
                rfqName={rfqName}
                category="download"
                featureName="download_rfq"
              />
            );
          })}
        </Box>
      )}
    </CardContent>
  );
};
