import { Box, DialogTitle, IconButton, Typography } from "@mui/material";
import React from "react";
import CloseIcon from "@mui/icons-material/Close";
import GavelOutlinedIcon from "@mui/icons-material/GavelOutlined";
import { useTranslation } from "react-i18next";

export const QuoteComparisonAwardDrawerHeader = ({
  onClose,
}: {
  onClose: () => void;
}) => {
  const { t } = useTranslation();

  return (
    <DialogTitle style={{ height: "72px", paddingTop: "12px" }}>
      <Box display="flex" alignItems="center">
        <Box flexGrow={1} display="flex" alignItems="center">
          <GavelOutlinedIcon style={{ width: "22px", height: "22px" }} />

          <Box ml="8px">
            <Typography variant="h6">
              {t("workspaceNext:quoteComparison:awardSelection")}
            </Typography>
          </Box>
        </Box>

        <Box pl="5px">
          <IconButton onClick={onClose} size="large">
            <CloseIcon style={{ color: "black" }} />
          </IconButton>
        </Box>
      </Box>
    </DialogTitle>
  );
};
