import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTracking } from "react-tracking";
import { Box, Button } from "@mui/material";
import { modalsActions } from "../../services/modals/modals.actions";
import { getCompany, getUser } from "../../services/profile/profile.selectors";
import snackbarUtils from "@next/utils/snackbarUtils";

interface IUserSurveyProps {
  name?: string;
  questionLabel: string;
  yesLabel: string;
  maybeLabel: string;
  noLabel: string;
  yesToastMessage: string;
  noToastMessage: string;
  maybeToastMessage: string;
}

const UserSurvey: React.FC<IUserSurveyProps> = ({
  questionLabel,
  noLabel,
  maybeLabel,
  yesLabel,
  yesToastMessage,
  noToastMessage,
  maybeToastMessage,
  name
}) => {
  const dispatch = useDispatch();
  const tracking = useTracking();
  const user = useSelector(getUser);
  const company = useSelector(getCompany);
  const trackingInfo = {
    scout_category: name,
    scout_feature_name: "user-survey",
    scout_company: company["name"],
    scout_username: user["username"],
    scout_user_type: user["type"],
    scout_date: new Date()
  };

  const onclickYes = () => {
    dispatch(modalsActions.hideModal("isUserSurveyModalOpen"));
    tracking.trackEvent({ ...trackingInfo, scout_survey_answer: "Yes" });
    snackbarUtils.success(yesToastMessage);
    localStorage.setItem("hasOpenSurveyCapacitySurvey", "yes");
  };
  const onclickNo = () => {
    dispatch(modalsActions.hideModal("isUserSurveyModalOpen"));
    tracking.trackEvent({ ...trackingInfo, scout_survey_answer: "No" });
    snackbarUtils.success(noToastMessage);
    localStorage.setItem("hasOpenSurveyCapacitySurvey", "yes");
  };
  const onclickMaybe = () => {
    dispatch(modalsActions.hideModal("isUserSurveyModalOpen"));
    tracking.trackEvent({ ...trackingInfo, scout_survey_answer: "Maybe" });
    snackbarUtils.success(maybeToastMessage);
    localStorage.setItem("hasOpenSurveyCapacitySurvey", "yes");
  };

  return (
    <Box className="c-user-survey" display="flex" flexDirection="column">
      <Box className="c-user-survey__question" padding={1}>
        {questionLabel}
      </Box>
      <Box
        className="c-user-survey__choices"
        display="flex"
        flex={1}
        justifyContent="center"
      >
        <Button
          variant="contained"
          size="small"
          color="secondary"
          onClick={onclickYes}
        >
          {yesLabel}
        </Button>

        <Button
          variant="outlined"
          size="small"
          color="secondary"
          onClick={onclickMaybe}
        >
          {maybeLabel}
        </Button>

        <Button
          variant="outlined"
          color="primary"
          size="small"
          onClick={onclickNo}
        >
          {noLabel}
        </Button>
      </Box>
    </Box>
  );
};

export default UserSurvey;
