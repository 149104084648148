import React from "react";
import { Typography } from "@mui/material";
import moment from "moment";
import { openProfileInNewTab } from "@next/utils/browserUtils";

type Props = {
  name: string;
  time: string;
  companyUUID: string;
};

export const InstigatorName: React.FC<Props> = ({
  name,
  time,
  companyUUID,
}) => {
  const onClickSupplierName = () => {
    openProfileInNewTab(name, companyUUID);
  };

  return (
    <Typography variant="body2" onClick={onClickSupplierName}>
      <span className="c-cursor-pointer c-text-underline">{name}</span>
      {" · "}
      <span className="c-message--created-time">
        {moment(time).format("hh:mm a")}
      </span>
    </Typography>
  );
};
