import React from "react";
import { withTranslation } from "react-i18next";
// Routes
import { frontendUrl } from "urls";
// Layout
import AppLayout from "layout/AppLayout";
// Legacy Components
import CompanyProfile from "components/user-company/user-company.container";
import InviteMemberForm from "profiles/companyProfile/components/InviteMemberForm";
import { CompanyRegistrationContainer } from "components/company/registration-page/registration.container";
// Types
import { CustomRoute } from "./types";
const COMPANY_ROUTES: CustomRoute[] = [
  {
    // /company/register
    layout: (props: any) => {
      return <AppLayout {...props} isHideNav={true} />;
    },
    path: frontendUrl.joinOrRegisterCompany,
    key: "companyRegistrationContainer",
    exact: false,
    component: withTranslation()((props) => (
      <CompanyRegistrationContainer {...props} />
    )),
  },
  {
    // /company
    layout: AppLayout,
    path: frontendUrl.companyProfile,
    key: "company",
    exact: true,
    component: withTranslation()((props) => <CompanyProfile {...props} />),
    allowedRoles: ["admin", "normal"],
  },
  // {
  //   // /company/create
  //   layout: AppLayout,
  //   path: "/company/createOld",
  //   key: "createCompany",
  //   exact: true,
  //   component: (props: any) => <CreateCompanyForm {...props} />,
  // },

  {
    // /company/send-invite
    layout: AppLayout,
    path: frontendUrl.inviteMember,
    key: "inviteMember",
    exact: true,
    component: (props: any) => <InviteMemberForm {...props} />,
  },
];

export default COMPANY_ROUTES;
