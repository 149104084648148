import { makeStyles } from "@mui/styles";
import { MenuProps as MenuPropsType } from "@mui/material/Menu";
import { Option } from "./types";
import { GenericObject } from "@next/modules/profile/redux";

const useStyles = makeStyles((theme) => ({
  formControl: {
    width: "100%",
    "& .MuiOutlinedInput-root": {
      "&.Mui-focused fieldset": {
        borderColor: "black",
      },
    },
  },

  selectAllText: {
    fontWeight: 500,
  },

  selectedAll: {
    width: "100%",
    "&:hover": {
      backgroundColor: "rgba(0, 0, 0, 0.08)",
    },
  },

  indeterminate: {
    color: theme.palette.primary.main,
  },

  checkbox: {
    "&.MuiCheckbox-colorSecondary.Mui-checked": {
      color: theme.palette.primary.main,
    },
  },

  box: {
    display: "flex",
    flexWrap: "wrap",
    gap: "4px",
  },
  titleContainer: {
    position: "sticky",
    background: "#ffffff",
    padding: "16px 24px",
    justifyContent: "flex-start",
    flexDirection: "column",
  },
  titlePosition: {
    top: 0,
    zIndex: 1,
  },
  titleFontWeight: {
    fontWeight: "bold",
  },
  entireNetworkTitle: {
    borderTop: "1px solid #E0E0E0",
    bottom: 52,
    color: "#000",
  },
  appBarContainer: {
    paddingBottom: 16,
    background: "#ffffff",
    position: "sticky",
    bottom: -1,
  },

  listItemText: {
    color: "#000",
  },

  select: {
    "& .MuiSelect-select": {
      paddingTop: theme.spacing(1),
      paddingBottom: theme.spacing(1),
    },
  },

  placeholder: {
    color: theme.palette.action.active,
  },
}));

const MenuProps: Partial<MenuPropsType> = {
  PaperProps: {
    style: {
      height: "fit-content",
      maxHeight: 665,
      width: 250,
    },
  },

  anchorOrigin: {
    vertical: "bottom",
    horizontal: "center",
  },

  transformOrigin: {
    vertical: "top",
    horizontal: "center",
  },

  variant: "menu",
};

const getOptionsName = (options: Option[]) => {
  return options && options.length > 0
    ? options?.map((option: Option) => option.name)
    : [];
};

const getSelectedOptionsName = (selectedValues: string[]) => {
  let result: GenericObject = {};
  result = selectedValues.reduce((obj, cur) => ({ ...obj, [cur]: cur }), {});
  return result;
};

export { MenuProps, useStyles, getSelectedOptionsName, getOptionsName };
