import React from "react";
import { ErrorMessage, Field, Form, Formik } from "formik";
import { GenericTextInput } from "components/utils/input/text";
import { Grid, TextField } from "@mui/material";
import { Typography } from "@material-ui/core";
import { FormikAttachFile } from "@next/components/formik-attach-file";
import {
  BundleFile,
  CreatePortalQuoteInput,
  UpdatePortalQuoteInput,
  workspaceNextActions,
} from "@next/modules/workspace/redux";
import { useDispatch, useSelector } from "react-redux";
import { Done } from "@mui/icons-material";
import { createStyles, makeStyles } from "@mui/styles";
import { useTranslation } from "react-i18next";
import { useQuery } from "@next/hooks/useQuery";
import {
  initialValues,
  simpleRFQFormValidationSchema,
} from "./simple-rfq-form.definitions";
import { currencyLabels, workTimeOptions } from "./simple-rfq-constants";
import {
  selectCreatePortalQuoteLoading,
  selectUpdatePortalQuoteLoading,
} from "@next/modules/workspace/redux/selectors";
import CustomButton from "@next/components/custom-button";
import AutocompleteOptions from "components/autocomplete-options/autocomplete-options.component";
import { CustomFileObject } from "@next/components/multiple-dnd-file-upload/multiple-dnd-file-upload.types";
import { FileData } from "@next/components/attach-file/attach-file-list.types";

const useStyles = makeStyles((theme) =>
  createStyles({
    error: {
      color: theme.palette.error.main,
      marginLeft: "14px",
      marginTop: "4px",
      fontSize: "12px",
    },
    fieldsStyle: {
      display: "flex",
      gap: "12px",
      flexDirection: "row",
      alignItems: "center",
      marginBottom: "12px",
    },
    textLabelColor: {
      color: theme.palette.text.secondary,
    },
    textLabelStyle: {
      minWidth: "135px",
      color: theme.palette.text.secondary,
    },
    boxStyles: {
      display: "flex",
      alignItems: "baseline",
      marginBottom: "15px",
    },
    reviseBtnStyle: {
      display: "flex",
      gap: "5px",
      padding: "8px 12px",
    },
  })
);

const SimpleRFQFormView = ({ portalData }) => {
  const { t } = useTranslation();
  const isCreatingQuote = useSelector(selectCreatePortalQuoteLoading);
  const isUpdatingQuote = useSelector(selectUpdatePortalQuoteLoading);
  const classes = useStyles();
  const [query] = useQuery();
  const rfqToken = query.rfq_token as string;
  const dispatch = useDispatch();

  const onAttachementDelete = (filesName: string) => {
    dispatch(
      workspaceNextActions.removeFileUploadProgress({
        filesName,
      })
    );
  };

  const onAttachementChange = (
    files: (File | FileData)[],
    value,
    setFieldValue
  ) => {
    const uploadedFiles: FileData[] = files.filter(
      (file: File | FileData) => !(file instanceof File) && file?.pk
    ) as FileData[];

    const filesToUpload = [...files.filter((file) => file instanceof File)];

    if (value?.files) {
      filesToUpload.push(
        ...value["files"]?.filter((file) => file instanceof File)
      );
    }
    setFieldValue("files", [...uploadedFiles, ...filesToUpload]);
  };

  const handleFormSubmit = (values) => {
    const {
      delay,
      total_cost,
      delay_unit,
      currency,
      files,
      additional_details,
    } = values;
    const customFiles: CustomFileObject[] = files?.map((file: BundleFile) => ({
      file,
      pk: file?.pk || -1,
    }));

    const payload: any = {
      delay: parseFloat(delay),
      delay_unit: delay_unit.label,
      total_cost: parseFloat(total_cost),
      currency: currency.label,
      additional_details: additional_details,
      files: customFiles,
      rfqToken,
      isSimpleRFQ: true,
    };
    if (portalData?.quotation?.pk) {
      dispatch(
        workspaceNextActions.updatePortalQuoteRequest({
          ...payload,
          quotation_id: portalData?.quotation?.pk,
        } as UpdatePortalQuoteInput)
      );
    } else {
      dispatch(
        workspaceNextActions.createPortalQuoteRequest(
          payload as CreatePortalQuoteInput
        )
      );
    }
  };

  return (
    <div>
      <Formik
        initialValues={initialValues(portalData)}
        validationSchema={simpleRFQFormValidationSchema}
        onSubmit={(values) => handleFormSubmit(values)}
      >
        {({ values, errors, touched, setFieldValue }) => (
          <Form>
            {/* Total cost field */}
            <Grid className={classes.fieldsStyle} xs={12}>
              <Grid item xs={1.6}>
                <Typography variant="body2" className={classes.textLabelColor}>
                  {t("workspaceNext:QA:simpleRFQ:totalCost")}
                </Typography>
              </Grid>
              <Grid
                item
                xs={5.2}
                style={
                  touched.currency &&
                  errors.currency && { marginBottom: "20px" }
                }
              >
                <Field
                  sx={
                    touched.total_cost &&
                    errors.total_cost && {
                      "& .MuiOutlinedInput-notchedOutline": {
                        borderColor: "red",
                      },
                    }
                  }
                  name="total_cost"
                  component={TextField}
                  fullWidth
                  type="number"
                  placeholder="0"
                  value={values.total_cost}
                  onChange={(e: any) => {
                    setFieldValue("total_cost", e.target.value);
                  }}
                />
                <ErrorMessage
                  component="p"
                  name="total_cost"
                  render={() => (
                    <p className={classes.error}>{t("common:required")}</p>
                  )}
                />
              </Grid>
              <Grid
                item
                xs={5.2}
                style={
                  touched.total_cost &&
                  errors.total_cost && { marginBottom: "20px" }
                }
              >
                <Field
                  sx={
                    touched.currency &&
                    errors.currency && {
                      "& .MuiOutlinedInput-notchedOutline": {
                        borderColor: "red",
                      },
                    }
                  }
                  name="currency"
                  component={AutocompleteOptions}
                  label="Currency"
                  value={values.currency}
                  staticsData={currencyLabels}
                />
                <ErrorMessage
                  component="p"
                  name="currency"
                  render={() => (
                    <p className={classes.error}>{t("common:required")}</p>
                  )}
                  className={classes.error}
                />
              </Grid>
            </Grid>
            {/* Lead time field */}
            <Grid xs={12} className={classes.fieldsStyle}>
              <Grid item xs={1.6}>
                <Typography variant="body2" className={classes.textLabelColor}>
                  {t("workspaceNext:QA:simpleRFQ:leadTime")}
                </Typography>
              </Grid>
              <Grid
                item
                xs={5.2}
                style={
                  touched.delay_unit &&
                  errors.delay_unit && { marginBottom: "20px" }
                }
              >
                <Field
                  sx={
                    touched.delay &&
                    errors.delay && {
                      "& .MuiOutlinedInput-notchedOutline": {
                        borderColor: "red",
                      },
                    }
                  }
                  name="delay"
                  component={TextField}
                  fullWidth
                  type="number"
                  placeholder="0"
                  value={values.delay}
                  onChange={(e: any) => {
                    setFieldValue("delay", e.target.value);
                  }}
                />
                <ErrorMessage
                  component="p"
                  name="delay"
                  render={() => (
                    <p className={classes.error}>{t("common:required")}</p>
                  )}
                />
              </Grid>
              <Grid
                item
                xs={5.2}
                style={
                  touched.delay && errors.delay && { marginBottom: "20px" }
                }
              >
                {/* LeadTimeAutoComplete */}
                <Field
                  sx={
                    touched.delay_unit &&
                    errors.delay_unit && {
                      "& .MuiOutlinedInput-notchedOutline": {
                        borderColor: "red",
                      },
                    }
                  }
                  name="delay_unit"
                  component={AutocompleteOptions}
                  label="Lead Time"
                  value={values.delay_unit}
                  staticsData={workTimeOptions}
                />
                <ErrorMessage
                  component="p"
                  name="delay_unit"
                  render={() => (
                    <p className={classes.error}>{t("common:required")}</p>
                  )}
                  className={classes.error}
                />
              </Grid>
            </Grid>
            <Grid
              display="flex"
              gap="12px"
              flexDirection="row"
              xs={12}
              alignItems="center"
              marginBottom="12px"
            >
              <Grid item xs={1.6}>
                <Typography variant="body2" className={classes.textLabelColor}>
                  Note
                </Typography>
              </Grid>
              <Grid item xs={10.4}>
                <GenericTextInput
                  name="additional_details"
                  label={t("workspaceNext:QA:simpleRFQ:addNote")}
                  multiline
                  rows={"2"}
                  defaultValue={
                    portalData?.quotation?.additional_details || null
                  }
                />
              </Grid>
            </Grid>
            {/*file attachment*/}
            <Grid className={classes.fieldsStyle} xs={12}>
              <Grid item xs={1.6}>
                <Typography variant="body2" className={classes.textLabelColor}>
                  {t("workspaceNext:QA:simpleRFQ:files")}
                </Typography>
              </Grid>
              <Grid item xs={10.4}>
                <Field
                  name="files"
                  disableAcceptSpecificTypes
                  component={FormikAttachFile}
                  onChangeFiles={(files: (File | FileData)[]) =>
                    onAttachementChange(files, values, setFieldValue)
                  }
                  onDeleteFiles={onAttachementDelete}
                />
              </Grid>
            </Grid>
            <Grid xs={12}>
              <CustomButton
                fullWidth
                type="submit"
                disabled={isCreatingQuote || isUpdatingQuote}
                variant="contained"
                loading={isCreatingQuote || isUpdatingQuote}
              >
                <Done /> {t("workspaceNext:QA:simpleRFQ:submit")}
              </CustomButton>
            </Grid>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default SimpleRFQFormView;
