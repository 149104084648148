import React, { useEffect } from "react";
import {
  Box,
  CircularProgress,
  Container,
  Theme,
  Typography,
} from "@mui/material";
import { Trans, useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { createStyles, makeStyles } from "@mui/styles";
import { workspaceNextActions } from "@next/modules/workspace/redux";
import airbusBg from "assets/img/airbus-bg.svg";
import airbusLogo from "assets/img/airbus-logo.svg";
import checkIcon from "assets/img/check-icon-green.png";
import { selectSendAirbusConfirmationLoading } from "../../redux/selectors";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      height: "100vh",
      width: "100%",
      backgroundImage: `url(${airbusBg})`,
    },
    rootContainer: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
      textAlign: "center",
      gap: theme.spacing(6),
      background: theme.palette.common.white,
      borderRadius: "24px",
      padding: theme.spacing(6),
      marginTop: theme.spacing(6),
      marginBottom: theme.spacing(6),
    },
  })
);

export const AirbusProcurementConfirmation = () => {
  const params = useParams<{ token?: string }>();
  const publicToken = params?.token;
  const classes = useStyles();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const airbusConfirmationloading = useSelector(
    selectSendAirbusConfirmationLoading
  );
  const [isAirbusConfirmed, setIsAirbusConfirmed] = React.useState(false);

  useEffect(() => {
    if (publicToken) {
      dispatch(
        workspaceNextActions.sendAirbusConfirmationRequest({
          token: publicToken,
          onSuccess: () => {
            setIsAirbusConfirmed(true);
          },
        })
      );
    }
  }, [publicToken]);

  return (
    <Box className={classes.root}>
      {!isAirbusConfirmed || airbusConfirmationloading ? (
        <CircularProgress sx={{ color: "white" }} />
      ) : (
        <Container maxWidth="sm" className={classes.rootContainer}>
          <img src={checkIcon} alt="check icon" />
          <Typography variant="h5">
            {t("customRoutesForCompanies:airbusProcurement:title")}
          </Typography>
          <Box>
            <Trans i18nKey="customRoutesForCompanies:airbusProcurement:description" />
          </Box>
          <img src={airbusLogo} alt="axya logo" />
        </Container>
      )}
    </Box>
  );
};
