import { useMemo } from "react";
import queryString from "query-string";
import { useHistory } from "react-router-dom";

type HistoryType = ReturnType<typeof useHistory>;
type NavigationMethod = "push" | "replace";

function getQueryUpdater(
  history: HistoryType,
  navigationMethod: NavigationMethod
) {
  return (newQueryObj: Record<string, any>) => {
    const prevQueryObj = queryString.parse(window.location.search, {
      parseNumbers: true,
    });
    const updatedQueryObj = { ...prevQueryObj };

    for (const key in newQueryObj) {
      const value = newQueryObj[key];
      const falsyValues = [undefined, null, ""];
      if (falsyValues.includes(value)) {
        delete updatedQueryObj[key];
      } else updatedQueryObj[key] = value;
    }

    history[navigationMethod]({
      search: "?" + queryString.stringify(updatedQueryObj),
    });
  };
}

export function useQuery(
  navigationMethod: NavigationMethod = "push"
): [
  queryString.ParsedQuery<string | number>,
  ReturnType<typeof getQueryUpdater>
] {
  const history = useHistory();

  const query = useMemo(
    () => queryString.parse(window.location.search, { parseNumbers: true }),
    [window.location.search]
  );
  const queryUpdater = useMemo(
    () => getQueryUpdater(history, navigationMethod),
    [history, navigationMethod]
  );

  return [query, queryUpdater];
}

export function useQueryParam(
  paramName: string,
  defaultValue?: string | number,
  method: NavigationMethod = "push"
): [queryString.ParsedQuery<string | number>[string], (val: any) => void] {
  const [query, updateQuery] = useQuery(method);
  const value = query[paramName] ?? defaultValue;

  const setValue = (val: any) => {
    updateQuery({ [paramName]: val });
  };

  return [value as queryString.ParsedQuery<string | number>[string], setValue];
}
