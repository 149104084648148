import React from "react";

import {
  GridColDef,
  GridColumns,
  GridRenderCellParams,
} from "@mui/x-data-grid-pro-v5";
import TextCell from "@next/components/table-cells/text-cell-v5";
import LeadTimeCell from "@next/components/table-cells/lead-time-cell-v5";
import { t } from "assets/configi18n/i18n";
import { SupplierQuotesField } from "./supplier-quotes-table.types";
import { formatDate } from "helpers/formatDate";
import { Avatar, Box, Chip, Tooltip, alpha } from "@mui/material";
import { getInitials } from "@next/utils/stringUtils";
import { CustomTypography } from "@next/components/custom-typography";
import { QuoteStatus } from "components/common/table-list/types";
import { AppTheme } from "layout/AppTheme";

const defaultColumnConfig: Partial<GridColDef> = {
  disableColumnMenu: true,
  sortable: false,
  hideSortIcons: true,
};

const BuyerCell: React.FC<GridRenderCellParams> = (params) => {
  return (
    <Box display="flex" alignItems="center">
      <Avatar
        src={params?.row?.company_info?.company_picture}
        style={{ width: "32px", height: "32px" }}
      >
        {getInitials(params?.row?.company_info?.company_name)}
      </Avatar>

      <Box ml="8px">
        <CustomTypography variant="caption">
          {params?.row?.company_info?.company_name}
        </CustomTypography>
      </Box>
    </Box>
  );
};

const baseStyle: React.CSSProperties = {
  cursor: "unset",
  textOverflow: "ellipsis",
  overflow: "hidden",
};

export const getStatusTagStyle = (
  quotationStatus: QuoteStatus
): React.CSSProperties | undefined => {
  switch (quotationStatus) {
    case QuoteStatus.IN_PROGRESS:
      return {
        ...baseStyle,
        color: AppTheme.palette.primary.main,
        backgroundColor: alpha(AppTheme.palette.primary.main, 0.1),
      };
    case QuoteStatus.WAITING:
      return {
        ...baseStyle,
        color: AppTheme.palette.warning.main,
        backgroundColor: alpha(AppTheme.palette.warning.main, 0.1),
      };

    default:
      return baseStyle;
  }
};

const StatusCell: React.FC<GridRenderCellParams> = (params) => {
  const cancellationReason = params?.row?.rfq_info?.cancellation_reason?.reason;

  return (
    <Tooltip title={cancellationReason || ""}>
      <Chip
        size="small"
        label={`${t(
          `workspaceNext:supplierQuotes:status:${params?.row?.quotation_status}`
        )}${cancellationReason ? `: ${cancellationReason}` : ""}`}
        style={getStatusTagStyle(params?.row?.quotation_status)}
      />
    </Tooltip>
  );
};

export const getSupplierQuotesTableColumns = () => {
  const _columns: GridColumns = [
    {
      ...defaultColumnConfig,
      field: SupplierQuotesField.BUYER,
      width: 180,
      headerName: t("workspaceNext:supplierQuotes:table:header:buyer"),
      renderCell: BuyerCell,
    },
    {
      ...defaultColumnConfig,
      field: SupplierQuotesField.REQUEST,
      width: 200,
      headerName: t("workspaceNext:supplierQuotes:table:header:name"),
      renderCell: TextCell,
      valueGetter: (params) => params.row?.rfq_info?.rfq_display_name,
    },
    {
      ...defaultColumnConfig,
      field: SupplierQuotesField.PARTS,
      width: 80,
      headerName: t("workspaceNext:supplierQuotes:table:header:items"),
      renderCell: TextCell,
      align: "right",
      headerAlign: "right",
    },
    // {
    //   ...defaultColumnConfig,
    //   field: SupplierQuotesField.REQUEST_CREATED_BY,
    //   width: 160,
    //   headerName: t("workspaceNext:supplierQuotes:table:header:createdBy"),
    //   renderCell: TextCell,
    //   valueGetter: (params) => params.row?.rfq_info?.created_by?.display_name,
    // },
    {
      ...defaultColumnConfig,
      field: SupplierQuotesField.REQUEST_CREATED_ON,
      width: 160,
      headerName: t("workspaceNext:supplierQuotes:table:header:createdOn"),
      renderCell: TextCell,
      valueGetter: (params) =>
        formatDate(params.row?.rfq_info?.created_at, "LL"),
    },
    {
      ...defaultColumnConfig,
      field: SupplierQuotesField.QUOTE_SUBMITTED_ON,
      width: 160,
      headerName: t("workspaceNext:supplierQuotes:table:header:youSubmittedOn"),
      renderCell: TextCell,
      valueGetter: (params) => formatDate(params.row?.created_at, "LL"),
    },
    {
      ...defaultColumnConfig,
      field: SupplierQuotesField.REQUEST_ENDS_ON,
      width: 160,
      headerName: t("workspaceNext:supplierQuotes:table:header:endsOn"),
      renderCell: TextCell,
      valueGetter: (params) => formatDate(params.row?.rfq_info?.deadline, "LL"),
    },
    {
      ...defaultColumnConfig,
      field: SupplierQuotesField.COST_AND_LEAD_TIME,
      width: 160,
      headerName: t(
        "workspaceNext:supplierQuotes:table:header:costAndLeadTime"
      ),
      renderCell: LeadTimeCell,
    },
    {
      ...defaultColumnConfig,
      field: SupplierQuotesField.STATUS,
      width: 180,
      headerName: t("workspaceNext:supplierQuotes:table:header:status"),
      renderCell: StatusCell,
    },
  ];

  return _columns;
};
