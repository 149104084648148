import { Box, styled } from "@mui/material";

export const StyledBox = styled(Box)({
  display: "flex",
  flexDirection: "column",
  gap: "36px",
});

export const StyledBtnBox = styled(Box)({
  width: "218px",
});
