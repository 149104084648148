import { FormHelperText, RadioGroup, styled } from "@mui/material";

export const StyledRadioGroup = styled(RadioGroup)({
  width: "59px",
  marginTop: "-16px",
});

export const StyledFormHelperText = styled(FormHelperText)({
  marginTop: "-14px",
});
