export const getSnapshot = (activeViewer) => {
  return new Promise((resolve, reject) => {
    try {
      setTimeout(() => {
        try {
          resolve(activeViewer.toDataURL("image/jpg"));
        } catch {
          console.log("error while getting snapshot");
        }
      }, 10);
    } catch (e) {
      reject(e);
    }
  });
};

export const goFullscreen = (canvas, viewSection) => {
  if (canvas.requestFullScreen) {
    canvas.requestFullScreen();
  } else if (canvas.webkitRequestFullScreen) {
    /* Chrome, Safari & Opera */
    canvas.webkitRequestFullScreen();
  } else if (canvas.mozRequestFullScreen) {
    /* Firefox */
    canvas.mozRequestFullScreen();
  } else if (canvas.msRequestFullscreen) {
    /* IE/Edge */
    canvas.msRequestFullscreen();
  }
  // Listener to exiting fullscreen
  if (document.addEventListener) {
    document.addEventListener("mozfullscreenchange", exitHandler, false); // Firefox
    document.addEventListener("MSFullscreenChange", exitHandler, false); // IE/Edge
    document.addEventListener("webkitfullscreenchange", exitHandler, false); // Chrome, Safari & Opera
    document.addEventListener("fullscreenchange", exitHandler, false); // Other
  }
  function exitHandler() {
    if (
      document.webkitIsFullScreen ||
      document.mozFullScreen ||
      document.msFullscreenElement === null
    ) {
      viewSection.style.display = "block";
    } else {
      viewSection.style.display = "none";
    }
  }
};

export const exitFullscreen = (canvas) => {
  if (canvas.exitFullscreen) {
    canvas.exitFullscreen();
  } else if (canvas.mozCancelFullScreen) {
    /* Firefox */
    canvas.mozCancelFullScreen();
  } else if (canvas.webkitExitFullscreen) {
    /* Chrome, Safari & Opera */
    canvas.webkitExitFullscreen();
  } else if (canvas.msExitFullscreen) {
    /* IE/Edge */
    canvas.msExitFullscreen();
  }
  let viewSection = document.getElementById("view-section");
  if (Document.fullScreen) {
    viewSection.style.display = "none";
  } else {
    viewSection.style.display = "block";
  }
};

export const fitToView = () => {
  // cameraFlight is used for zooming into a freshly uploaded model
  let cameraFlight = new window.xeogl.CameraFlightAnimation({
    fit: true, // Default
    fitFOV: 45, // Default, degrees
    duration: 0, // Default, seconds
  });
  cameraFlight.flyTo(window.currentModel);
};

export const setIsometric = () => {
  let camera = window.scene.camera;
  camera.eye = [45, 45, 45];
};

export const setSolid = () => {
  window.currentModel.ghosted = false;
};

export const setWired = () => {
  // Customize the default ghost material
  let ghostMaterial = window.scene.ghostMaterial;
  ghostMaterial.edges = true;
  ghostMaterial.edgeAlpha = 0.8;
  ghostMaterial.edgeColor = [0.2, 0.2, 0.2];
  ghostMaterial.edgeWidth = 1;
  ghostMaterial.vertices = false;
  ghostMaterial.vertexAlpha = 1.0;
  ghostMaterial.vertexColor = [0.4, 1.0, 0.4];
  ghostMaterial.vertexSize = 4;
  ghostMaterial.fill = true;
  ghostMaterial.fillColor = [0.4, 0.4, 0.4];
  ghostMaterial.fillAlpha = 0.4;

  window.currentModel.ghosted = true;
};

export const goToView = (projection) => {
  let camera = window.scene.camera;
  switch (projection) {
    case 1:
      camera.eye = camera.worldUp;
      camera.look = [0, 0, 180];
      break;
    case 2:
      camera.eye = -camera.worldUp;
      camera.look = [0, 0, -180];
      break;
    case 3:
      camera.eye = -camera.worldRight;
      camera.look = [180, 0, 0];
      break;
    case 4:
      camera.eye = camera.worldRight;
      camera.look = [-180, 0, 0];
      break;
    case 5:
      camera.eye = camera.worldForward;
      camera.look = [0, -180, 0];
      break;
    case 6:
      camera.eye = -camera.worldForward;
      camera.look = [0, 180, 0];
      break;
    default:
      break;
  }
  fitToView();
};

export const getProjectionName = (projection) => {
  const projectionName = {
    1: "TOP",
    2: "BOTTOM",
    3: "LEFT",
    4: "RIGHT",
    5: "FRONT",
    6: "BACK",
  };

  return projectionName[projection];
};
export const getBoundingBox = () => {
  if (window.currentModel) {
    // getting exact dimensions
    let aabbCoordinates = window.currentModel.aabb;
    let xyz = [];
    for (let i = 0; i < 3; i++) {
      xyz.push(aabbCoordinates[i + 3] - aabbCoordinates[i]);
      // Rounding the length, width, and height to 2 decimals, and taking its absolute value
      xyz[i] = Math.abs(Math.round(xyz[i] * 100) / 100);
    }
    return xyz;
  } else {
    return [0, 0, 0];
  }
};

export const mmToIn = (mm) => {
  return Math.round((mm / 25.4) * 100) / 100;
};

export function loadScript(src, id, callback) {
  let script = document.createElement("script");
  script.setAttribute("id", id);
  script.src = src;
  script.onload = function () {
    if (!callback) {
      return;
    }
    callback();
  };
  document.getElementsByTagName("head")[0].appendChild(script);
}
// Utility used to setup and load xeogl model for the 3d viewer
export function loadViewersLibs({ on3DViewerReady }) {
  loadScript(
    "https://cdn.jsdelivr.net/npm/xeogl@0.9.0/build/xeogl.min.js",
    "xeogl",
    () => {
      loadScript(
        "https://cdn.jsdelivr.net/npm/xeogl@0.9.0/examples/js/models/STLModel.js",
        "xeogl-stl",
        () => {
          loadScript(
            "https://cdnjs.cloudflare.com/ajax/libs/fabric.js/2.4.1/fabric.min.js",
            "fabric-js",
            () => {
              // run callback
              on3DViewerReady();
            }
          );
        }
      );
    }
  );

  loadScript(
    "https://cdnjs.cloudflare.com/ajax/libs/pdfobject/2.1.1/pdfobject.js",
    "pdf-obj"
  );
  loadScript(
    "https://cdn.jsdelivr.net/npm/pdfjs-dist@2.0.385/build/pdf.min.js",
    "pdf-js"
  );
}

export function setupCanvas2D() {
  const STATE_IDLE = "idle";
  const STATE_PANNING = "panning";
  window.fabric.Canvas.prototype.toggleDragMode = function (dragMode) {
    // Remember the previous X and Y coordinates for delta calculations
    let lastClientX;
    let lastClientY;
    // Keep track of the state
    let state = STATE_IDLE;
    // We're entering dragmode
    if (dragMode) {
      // Discard any active object
      this.discardActiveObject();
      // Set the cursor to 'grabbing'
      this.defaultCursor = "grabbing";
      // Loop over all objects and disable events / selectable. We remember its value in a temp variable stored on each object
      this.forEachObject(function (object) {
        object.prevEvented = object.evented;
        object.prevSelectable = object.selectable;
        object.evented = false;
        object.selectable = false;
      });
      // Remove selection ability on the canvas
      this.selection = false;
      // When MouseUp fires, we set the state to idle
      this.on("mouse:up", function (e) {
        if (window._viewer2D.getActiveObject()) {
          return;
        }
        state = STATE_IDLE;
      });
      // When MouseDown fires, we set the state to panning
      this.on("mouse:down", (e) => {
        if (window._viewer2D.getActiveObject()) {
          return;
        }
        state = STATE_PANNING;
        lastClientX = e.e.clientX;
        lastClientY = e.e.clientY;
      });
      // When the mouse moves, and we're panning (mouse down), we continue
      this.on("mouse:move", (e) => {
        if (window._viewer2D.getActiveObject()) {
          return;
        }
        if (state === STATE_PANNING && e && e.e) {
          // let delta = new fabric.Point(e.e.movementX, e.e.movementY); // No Safari support for movementX and movementY
          // For cross-browser compatibility, I had to manually keep track of the delta

          // Calculate deltas
          let deltaX = 0;
          let deltaY = 0;
          if (lastClientX) {
            deltaX = e.e.clientX - lastClientX;
          }
          if (lastClientY) {
            deltaY = e.e.clientY - lastClientY;
          }
          // Update the last X and Y values
          lastClientX = e.e.clientX;
          lastClientY = e.e.clientY;

          let delta = new window.fabric.Point(deltaX, deltaY);
          this.relativePan(delta);
          this.trigger("moved");
        }
      });
    } else {
      // When we exit dragmode, we restore the previous values on all objects
      this.forEachObject(function (object) {
        object.evented =
          object.prevEvented !== undefined
            ? object.prevEvented
            : object.evented;
        object.selectable =
          object.prevSelectable !== undefined
            ? object.prevSelectable
            : object.selectable;
      });
      // Reset the cursor
      this.defaultCursor = "default";
      // Remove the event listeners
      this.off("mouse:up");
      this.off("mouse:down");
      this.off("mouse:move");
      // Restore selection ability on the canvas
      this.selection = true;
    }
  };

  const canvasWrapper = document.querySelector(".canvas-wrapper");
  window._viewer2D = new window.fabric.Canvas("2d-viewer", {
    backgroundColor: "#ffffff",
    width: canvasWrapper ? canvasWrapper.offsetWidth : null,
    height: canvasWrapper ? canvasWrapper.offsetHeight : null,
  });

  window._viewer2D.selection = true;
  window._viewer2D.toggleDragMode(true);

  window._viewer2D.on("mouse:wheel", (opt) => {
    let delta = opt.e.deltaY;
    let pointer = window._viewer2D.getPointer(opt.e);
    let zoom = window._viewer2D.getZoom();
    zoom *= 0.999 ** delta;
    if (zoom > 20) zoom = 20;
    if (zoom < 0.01) zoom = 0.01;
    window._viewer2D.zoomToPoint({ x: opt.e.offsetX, y: opt.e.offsetY }, zoom);
    opt.e.preventDefault();
    opt.e.stopPropagation();
  });
}

export function setup3DViewer(viewer) {
  // Eagerly create the default scene so we can bind it to our canvas.
  // Setting the scene to be default makes every further
  // scene-related commands refer to this scene.
  window.scene = new window.xeogl.Scene({
    canvas: viewer ? viewer.id : null,
    transparent: true,
    contextAttr: {
      preserveDrawingBuffer: true,
    },
  });
  window.xeogl.setDefaultScene(window.scene);
  window.xeogl.getDefaultScene().canvas.spinner.processes = 0;
  new window.xeogl.CameraControl();
  // Set up the view camera
  let camera = window.scene.camera;
  camera.eye = [37.24, 45.17, -15.02];
  camera.look = [10.9, 10.9, 8];
  camera.up = [-0.58, 0.72, 0.35];
}
export async function loadVisibilityDetectionSTL(stl, vd_stl) {
  window.original_model = await loadSTLScene(stl);
  window.original_model.opacity = 0.5;

  window.vd_region_model = await loadSTLScene(vd_stl, true);
  window.vd_region_model.colorize = [1, 1, 0];
}

export function loadSTLScene(file, append) {
  return new Promise((resolve, reject) => {
    let reader = new FileReader();
    // Closure to capture the file information.
    reader.onload = (function (data) {
      return function (e) {
        // Append another model without destroying the current loaded models
        if (window.currentModel && !append) {
          window.currentModel.destroy();
        }
        window.currentModel = new window.xeogl.STLModel({
          src: e.target.result,
          smoothNormals: true,
        });

        // cameraFlight is used for zooming into a freshly uploaded model
        let cameraFlight = new window.xeogl.CameraFlightAnimation({
          fit: true, // Default
          fitFOV: 45, // Default, degrees
          duration: 0, // Default, seconds
        });
        // Hold until the model is loaded
        window.currentModel.on("loaded", function () {
          if (!append) {
            cameraFlight.flyTo(window.currentModel);
          }

          // The CameraFlightAnimation callback does not work for a reason
          // ref; https://xeogl.org/docs/files/_home_lindsay_xeolabs_xeogl-next_xeogl_src_animation_cameraFlightAnimation.js.html#l1
          // Defer calling the callback until the camera stops flying.
          setTimeout(() => {
            resolve(window.currentModel);
          }, 500);
        });
      };
    })(file);

    // Read in the image file as a data URL.
    reader.readAsDataURL(file);
  });
}

export function loadFile(
  viewer,
  { file, ext, fileUrl, disableSnapshot, vdRegionFile }
) {
  if (ext) {
    ext = ext.split("?")[0].toLowerCase();
  }

  _clear2DViewer();

  return new Promise((resolve, reject) => {
    // Reset the zoom
    window._viewer2D.setViewportTransform([1, 0, 0, 1, 0, 0]);
    // At th e moment, we support ony STL files that can be previewed
    // in the viewer. We also support PDF embedding via a third party library.
    // Note that the PDF won't load if loaded from another domain du to cross
    // origins. JPEG  files are drawn directly in a 2d canvas. Unknown files
    // will have their extensions drawn.
    if (ext && ext.startsWith("stl")) {
      if (window.original_model) {
        window.original_model.destroy();
      }
      if (window.vd_region_model) {
        window.vd_region_model.destroy();
      }
      if (vdRegionFile) {
        loadVisibilityDetectionSTL(file, vdRegionFile).then(resolve);
      } else {
        loadSTLScene(file).then(resolve);
      }
    } else if (ext.startsWith("pdf")) {
      // Use PDFObject to embed the pdf file
      const options = {
        pdfOpenParams: {
          navpanes: 0,
        },
      };
      if (disableSnapshot) {
        window.PDFObject.embed(fileUrl, `#pdf-viewer`, options);
        resolve();
      } else {
        var canvas = document.getElementById("buffer-canvas"), // single off-screen canvas
          ctx = canvas.getContext("2d"), // to render to
          pages = [],
          currentPage = 1;

        function iterate(pdf) {
          // init parsing of first page
          if (currentPage <= pdf.numPages) getPage();
          // main entry point/function for loop
          function getPage() {
            // when promise is returned do as usual
            pdf.getPage(currentPage).then(function (page) {
              var scale = 1;
              var viewport = page.getViewport(scale);

              canvas.height = viewport.height;
              canvas.width = viewport.width;

              var renderContext = {
                canvasContext: ctx,
                viewport: viewport,
              };
              // now, tap into the returned promise from render:
              page.render(renderContext).then(function () {
                // store compressed image data in array
                pages.push(canvas.toDataURL());

                if (currentPage < pdf.numPages) {
                  currentPage++;
                  getPage(); // get next page
                } else {
                  drawPages();
                }
              });
            });
          }
        }

        function drawPages() {
          pages.forEach((p, index) => {
            const img = new Image();
            img.onload = function () {
              _drawImage(
                img,
                {
                  top: 400 * index,
                },
                /* append */ true
              );
              if (index === pages.length - 1) {
                resolve();
              }
            };
            img.width = "400px";
            img.height = "400px";
            img.src = p;
          });
        }

        let fileReader = new FileReader();
        let base64;
        // Onload of file read the file content
        fileReader.onload = function (fileLoadedEvent) {
          base64 = fileLoadedEvent.target.result;

          // atob() is used to convert base64 encoded PDF to binary-like data.
          // (See also https://developer.mozilla.org/en-US/docs/Web/API/WindowBase64/
          // Base64_encoding_and_decoding.)
          window.PDFJS.disableWorker = true;
          let src;
          try {
            src = {
              data: atob(
                base64.substring("data:application/pdf;base64,".length)
              ),
            };
          } catch (err) {
            src = fileUrl;
          } finally {
            window.PDFJS.getDocument(src).then(iterate);
          }
        };
        // Convert data to base64
        // https://stackoverflow.com/a/37699333
        fileReader.readAsDataURL(file);
      }
    } else {
      const img = new Image();
      img.onload = function () {
        _drawImage(img);
        resolve();
      };

      img.onerror = () => {
        const canvasWrapper = document.querySelector(".canvas-wrapper");
        const w = canvasWrapper.offsetWidth;
        const h = canvasWrapper.offsetHeight;
        window._viewer2D.add(
          new window.fabric.Text(`.${ext.toUpperCase()}`, {
            left: w / 2 - 50,
            top: h / 2 + 5,
            fill: "black",
          })
        );

        resolve();
      };

      img.src = URL.createObjectURL(file);
    }
  });
}

/**
 * Clears the viewer
 * @param {C} viewer
 */
export function clearViewer(viewer) {
  if (!viewer) {
    return;
  }

  if (viewer instanceof HTMLCanvasElement) {
    const twoDContext = viewer.getContext("2d");
    if (twoDContext) {
      _clear2DViewer();
    }

    if (window.currentModel) {
      window.currentModel.destroy();
    }
  }
}

function _drawImage(img, config = {}, append = false) {
  if (!append) {
    _clear2DViewer();
  }
  const obj = new window.fabric.Image(img, config);
  window._viewer2D.add(obj);
}

function _clear2DViewer() {
  window._viewer2D.getObjects().forEach((obj) => {
    window._viewer2D.remove(obj);
  });
  window._viewer2D.clear();
}

/**
 * Gets the extension of file resource
 *
 * @param {*} src Could be a file URL or a File object
 * @returns {string} the file extension
 */
export function getFileExtensionFromSource(src) {
  let ext = false;
  const isFileUrl = typeof src === "string";

  if (isFileUrl) {
    const matches = src.split("?")[0].match(/.*\.(.*)$/);
    ext = matches && matches.length > 0 ? matches[1] : false;
  }

  // if an object file
  if (src instanceof File) {
    const matches = src.name.toLowerCase().split(".");
    ext = matches && matches.length > 0 ? matches[1] : false;
  }
  return ext;
}

export function clearFetchSTEPFileSTLPreviewInterval() {
  if (window._fetchSTLPreview) {
    clearInterval(window._fetchSTLPreview);
    window._fetchSTLPreview = null;
  }
  if (window._fetchSTLPreviewTimeout) {
    clearInterval(window._fetchSTLPreviewTimeout);
    window._fetchSTLPreviewTimeout = null;
  }
}

export function timeOutFetchSTEPFileSTLPreviewInterval(callback) {
  clearFetchSTEPFileSTLPreviewInterval();
  if (callback) {
    callback();
  }
}
