import React, { useState } from "react";
import { GridSelectionModel } from "@mui/x-data-grid-pro-v5";
import { useSelector } from "react-redux";
import {
  selectAllSpecifications,
  selectAllSpecificationsLoading,
} from "@next/modules/vendor-management/redux/selectors";
import { getAddSpecListModalTableColumns } from "./add-spec-list-modal-table.columns";
import {
  PaginationSizeForSpecs,
  SelectedRow,
} from "@next/modules/vendor-management/redux";
import * as S from "./add-spec-list-modal-table.styled";

type Props = {
  rowsAlreadyAdded: any[];
  page: number;
  setPage: React.Dispatch<React.SetStateAction<number>>;
  setSelectedRows: React.Dispatch<React.SetStateAction<SelectedRow[]>>;
};

export const AddSpecListModalTable: React.FC<Props> = ({
  rowsAlreadyAdded,
  page,
  setPage,
  setSelectedRows,
}) => {
  const columns = getAddSpecListModalTableColumns();
  const modalTableData = useSelector(selectAllSpecifications);
  const rows = modalTableData ? modalTableData?.results : [];
  const disabledRowIds = rowsAlreadyAdded?.map((row) => row.specification_id);
  const [selectionModel, setSelectionModel] = useState<GridSelectionModel>([]);
  const isLoading = useSelector(selectAllSpecificationsLoading);

  const handleRowSelectionModelChange = (
    newSelectionModel: GridSelectionModel
  ) => {
    setSelectionModel(newSelectionModel);
    setSelectedRows((prevSelectedRows) => {
      const currentPageSelectedRows = rows
        .filter((row) => newSelectionModel.includes(row.specification_id))
        .map((row) => ({
          row,
          page: page,
        }));
      const updatedSelectedRows = prevSelectedRows.filter(
        (selectedRow) => selectedRow.page !== page
      );

      return [...updatedSelectedRows, ...currentPageSelectedRows];
    });
  };

  const handlePageChange = (newPage: number) => {
    setPage(newPage + 1);
  };

  return (
    <S.StyledGridBox>
      <S.StyledDataGridPro
        columns={columns}
        rows={rows}
        pagination
        paginationMode="server"
        pageSize={PaginationSizeForSpecs}
        rowCount={modalTableData?.count ? modalTableData.count : 0}
        rowsPerPageOptions={[PaginationSizeForSpecs]}
        onPageChange={handlePageChange}
        autoHeight
        headerHeight={48}
        rowHeight={32}
        disableSelectionOnClick
        selectionModel={selectionModel}
        onSelectionModelChange={handleRowSelectionModelChange}
        isRowSelectable={(params) => !disabledRowIds.includes(params.id)}
        checkboxSelection={true}
        loading={isLoading}
        getRowId={(row) => row.specification_id}
        keepNonExistentRowsSelected
      />
    </S.StyledGridBox>
  );
};
