import {
  Box,
  Checkbox,
  Divider,
  FormControlLabel,
  FormGroup,
  Radio,
  RadioGroup,
  Typography,
} from "@mui/material";
import { quoteComparisonContext } from "@next/modules/workspace/components/quote-comparison/quote-comparison.context";
import { Form, FormikProps } from "formik";
import { formatDateAndTime } from "helpers/formatDate";
import React, { useContext } from "react";
import { useTranslation } from "react-i18next";

const QuoteComparisonCustomizeViewFormBase: React.FC<FormikProps<any>> = ({
  values,
  handleSubmit,
  setFieldValue,
}) => {
  const { t } = useTranslation();
  const {
    gridData: { config },
  } = useContext(quoteComparisonContext);

  const handleChangeCheckbox = (
    e: React.ChangeEvent<HTMLInputElement>,
    field: string
  ) => {
    const { checked, name } = e.target;

    if (checked) {
      setFieldValue(field, [...values?.[field], name]);
    } else {
      setFieldValue(
        field,
        values?.[field].filter((v: any) => v !== name)
      );
    }
  };

  const handleChangeRadioButton = (event: React.ChangeEvent<any>) => {
    setFieldValue(event?.target?.name, event?.target?.value);
  };

  return (
    <Form onSubmit={handleSubmit} id="QuoteComparisonCustomizeViewForm">
      <Box>
        <Typography variant="h6">
          {t("workspaceNext:quoteComparison:currency")}
        </Typography>

        <RadioGroup
          aria-label="currency"
          name="currency"
          value={values?.currency}
        >
          {config?.options?.currencies?.map((item: any) => {
            return (
              <FormControlLabel
                value={item}
                control={<Radio color="primary" />}
                label={item}
                onChange={handleChangeRadioButton}
              />
            );
          })}
        </RadioGroup>

        <Typography variant="body2" color="textSecondary">
          {config?.metadata?.conversion_rates?.map((item) => {
            return (
              <Typography>
                {t("workspaceNext:quoteComparison:estimatedConversion", {
                  ...item,
                  date: formatDateAndTime(item?.date, "LL"),
                })}
              </Typography>
            );
          })}
        </Typography>
      </Box>

      <Divider style={{ marginTop: "16px", marginBottom: "16px" }} />

      <Box>
        <Typography variant="h6">
          {t("workspaceNext:quoteComparison:quoteColumns")}
        </Typography>

        <FormGroup>
          {config?.options?.quotation_fields?.map((item: any) => (
            <FormControlLabel
              control={
                <Checkbox
                  name={item?.slug}
                  color="primary"
                  checked={values?.quotation_fields?.includes(item?.slug)}
                  onChange={(e) => handleChangeCheckbox(e, "quotation_fields")}
                  disabled={
                    values?.quotation_fields?.includes(item?.slug) &&
                    values?.quotation_fields?.length < 2
                  }
                />
              }
              label={item?.name}
              value={item?.slug}
            />
          ))}
        </FormGroup>
      </Box>

      <Divider style={{ marginTop: "16px", marginBottom: "16px" }} />

      <Box>
        <Typography variant="h6">
          {t("workspaceNext:quoteComparison:itemColumns")}
        </Typography>

        <FormGroup>
          {config?.options?.part_fields?.map((item: any) => (
            <FormControlLabel
              control={
                <Checkbox
                  name={item?.slug}
                  color="primary"
                  checked={values?.part_fields?.includes(item?.slug)}
                  onChange={(e) => handleChangeCheckbox(e, "part_fields")}
                  disabled={
                    values?.part_fields?.includes(item?.slug) &&
                    values?.part_fields?.length < 2
                  }
                />
              }
              label={item?.name}
              value={item?.slug}
            />
          ))}
        </FormGroup>
      </Box>
    </Form>
  );
};

export default QuoteComparisonCustomizeViewFormBase;
