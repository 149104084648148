import { frontendUrl } from "urls";
import { AppBarItemType } from "./app-bar-next-dropdown";
import { t } from "assets/configi18n/i18n";
import { RootState, store } from "store";
import { checkHasCompanyRole } from "@next/hooks/useHasCompanyRole";
import { getSupplierDirectoryLink } from "@next/utils/constantUtils";
import { selectMarketplaceCounts } from "@next/modules/workspace/redux/selectors";
import HomeIcon from "@mui/icons-material/CottageOutlined";
import AnalyticsIcon from "@mui/icons-material/TrendingUpOutlined";
import RequestsIcon from "@mui/icons-material/FileOpenOutlined";
import OrdersIcon from "@mui/icons-material/LocalShippingOutlined";
import ProjectsIcon from "@mui/icons-material/FolderOutlined";
import CompanyProfileIcon from "@mui/icons-material/StoreOutlined";
import SupplierDirectoryIcon from "@mui/icons-material/TravelExploreOutlined";
import PartsIcon from "@mui/icons-material/Inventory2Outlined";
import SupplierIcon from "@mui/icons-material/PrecisionManufacturingOutlined";
import AwardedRequestsIcon from "@mui/icons-material/WorkspacePremiumOutlined";
import SubmissionsIcon from "@mui/icons-material/RequestQuoteOutlined";

export enum AppBarItemKey {
  DASHBOARD = "DASHBOARD",
  ANALYTICS = "ANALYTICS",
  PARTS = "PARTS",
  REQUESTS = "REQUESTS",
  ORDERS = "ORDERS",
  SUPPLIER_ORDERS = "SUPPLIER_ORDERS",
  SUPPLIERS = "SUPPLIERS",
  PROJECTS = "PROJECTS",
  DISCOVER_SUPPLIERS = "DISCOVER_SUPPLIERS",
  AXYA_NETWORK = "AXYA_NETWORK",
  SUBMISSIONS = "SUBMISSIONS",
  AWARDED_REQUESTS = "AWARDED_REQUESTS",
  NEWSFEED = "NEWSFEED",
  COMPANY_PROFILE = "COMPANY_PROFILE"
}

export const getBuyerAppBarItems = (): AppBarItemType[] => {
  const state: RootState = store.getState();
  const user = state?.profile?.user;
  const profileCompany = state?.profile?.company;
  const company = state?.profile?.company || user?.company;
  const isPmFeatureEnabled = company?.is_pm_feature_enabled;
  const isRfqAnalyticsFeatureEnabled = company?.is_rfq_analytics_enabled;
  const isPoAnalyticsFeatureEnabled = company?.is_po_analytics_enabled;
  const isOrderManagementEnabled = company?.is_order_management_enabled;
  const isRequestsEnabled = company?.is_requests_enabled;
  const hasViewerRole = checkHasCompanyRole(user, ["viewer"], false);
  const hasRestrictedViewerRole = checkHasCompanyRole(
    user,
    ["restricted_viewer"],
    false
  );
  const supplierDirectoryLink = getSupplierDirectoryLink();
  const isCatalogActivated = profileCompany?.catalog_view;
  const pendingConnections = user?.company_stats?.pending_connections;
  const newNewsfeedPosts = user?.company_stats?.newsfeed_new_posts;

  const requestTabItem = {
    label: t("mainNavigationSidebar:requests"),
    path: frontendUrl.buyerQuotes,
    hide: !isRequestsEnabled,
    itemKey: AppBarItemKey.REQUESTS,
    icon: RequestsIcon as unknown as React.ReactNode
  };

  const projectsTabItem = {
    label: t("mainNavigationSidebar:projects"),
    path: frontendUrl.projects,
    hide: !isPmFeatureEnabled,
    itemKey: AppBarItemKey.PROJECTS,
    icon: ProjectsIcon as unknown as React.ReactNode
  };

  const orderTabItem = {
    label: t("mainNavigationSidebar:orders"),
    path: frontendUrl.orders,
    hide: !isOrderManagementEnabled,
    itemKey: AppBarItemKey.ORDERS,
    icon: OrdersIcon as unknown as React.ReactNode
  };

  const companyProfileTabItem = {
    label: t("mainNavigationSidebar:companyProfile"),
    path: frontendUrl.companyProfile,
    itemKey: AppBarItemKey.COMPANY_PROFILE,
    icon: CompanyProfileIcon as unknown as React.ReactNode
  };

  if (!company?.is_active) return [];

  if (hasViewerRole || hasRestrictedViewerRole) {
    return [
      {
        index: 0,
        ...requestTabItem
      },
      ...(hasRestrictedViewerRole ? [{ index: 1, ...projectsTabItem }] : []),
      {
        index: hasRestrictedViewerRole ? 2 : 1,
        ...orderTabItem
      },
      {
        index: hasRestrictedViewerRole ? 3 : 2,
        ...companyProfileTabItem
      }
    ];
  }

  return [
    {
      index: 0,
      label: t("mainNavigationSidebar:dashboard"),
      path: frontendUrl.dashboard,
      itemKey: AppBarItemKey.DASHBOARD,
      icon: HomeIcon as unknown as React.ReactNode
    },
    {
      index: 1,
      label: t("mainNavigationSidebar:analytics"),
      path: frontendUrl.analytics,
      itemKey: AppBarItemKey.ANALYTICS,
      isBeta: true,
      hide: !(isRfqAnalyticsFeatureEnabled || isPoAnalyticsFeatureEnabled),
      icon: AnalyticsIcon as unknown as React.ReactNode
    },
    {
      index: 2,
      label: t("mainNavigationSidebar:parts"),
      path: isCatalogActivated ? frontendUrl.catalog : frontendUrl.myParts,
      itemKey: AppBarItemKey.PARTS,
      icon: PartsIcon as unknown as React.ReactNode
    },
    {
      index: 3,
      ...requestTabItem
    },
    {
      index: 4,
      ...orderTabItem
    },
    {
      index: 5,
      label: t("mainNavigationSidebar:suppliers"),
      path: frontendUrl.mySuppliers,
      badgeCount: pendingConnections,
      itemKey: AppBarItemKey.SUPPLIERS,
      icon: SupplierIcon as unknown as React.ReactNode
    },
    {
      index: 6,
      ...projectsTabItem
    },
    // {
    //   index: 7,
    //   label: t("mainNavigationSidebar:newsfeed"),
    //   path: frontendUrl.newsfeed,
    //   badgeCount: newNewsfeedPosts,
    //   itemKey: AppBarItemKey.NEWSFEED,
    //   icon: NewsfeedIcon,
    // },
    {
      index: 7,
      ...companyProfileTabItem
    },
    {
      index: 8,
      label: t("workspaceNext:supplierTable:buttons:discoverSuppliers"),
      path: supplierDirectoryLink || "",
      external: true,
      itemKey: AppBarItemKey.DISCOVER_SUPPLIERS,
      icon: SupplierDirectoryIcon as unknown as React.ReactNode
    }
  ];
};

export const getFreemiumBuyerAppBarItems = (): AppBarItemType[] => {
  const state = store.getState();
  const user = state?.profile?.user;
  const newNewsfeedPosts = user?.company_stats?.newsfeed_new_posts;
  const supplierDirectoryLink = getSupplierDirectoryLink();

  return [
    {
      index: 0,
      label: t("mainNavigationSidebar:dashboard"),
      path: frontendUrl.dashboard,
      itemKey: AppBarItemKey.DASHBOARD,
      icon: HomeIcon as unknown as React.ReactNode
    },
    {
      index: 1,
      label: t("mainNavigationSidebar:requests"),
      path: frontendUrl.buyerQuotes,
      itemKey: AppBarItemKey.REQUESTS,
      icon: RequestsIcon as unknown as React.ReactNode
    },
    {
      index: 2,
      label: t("mainNavigationSidebar:suppliers"),
      path: frontendUrl.mySuppliers,
      itemKey: AppBarItemKey.SUPPLIERS,
      icon: SupplierIcon as unknown as React.ReactNode
    },
    // {
    //   index: 3,
    //   label: t("mainNavigationSidebar:newsfeed"),
    //   path: frontendUrl.newsfeed,
    //   badgeCount: newNewsfeedPosts,
    //   itemKey: AppBarItemKey.NEWSFEED,
    //   icon: NewsfeedIcon,
    // },
    {
      index: 3,
      label: t("mainNavigationSidebar:companyProfile"),
      path: frontendUrl.companyProfile,
      itemKey: AppBarItemKey.COMPANY_PROFILE,
      icon: CompanyProfileIcon as unknown as React.ReactNode
    },
    {
      index: 4,
      label: t("workspaceNext:supplierTable:buttons:discoverSuppliers"),
      path: supplierDirectoryLink || "",
      external: true,
      itemKey: AppBarItemKey.DISCOVER_SUPPLIERS,
      icon: SupplierDirectoryIcon as unknown as React.ReactNode
    }
  ];
};

export const getSellerAppBarItems = (): AppBarItemType[] => {
  const state = store.getState();
  const company = state.profile.company;
  const counts = selectMarketplaceCounts(state);
  const totalUnseenCounts =
    (counts.private_unseen || 0) +
    (counts.public_unseen || 0) +
    (counts.tenders_unseen || 0);

  const user = state?.profile?.user;
  const newNewsfeedPosts = user?.company_stats?.newsfeed_new_posts;

  if (!company?.is_active) return [];

  return [
    {
      index: 0,
      label: t("mainNavigationSidebar:dashboard"),
      path: frontendUrl.dashboard,
      itemKey: AppBarItemKey.DASHBOARD,
      icon: HomeIcon as unknown as React.ReactNode
    },
    {
      index: 1,
      label: t("mainNavigationSidebar:requests"),
      path: frontendUrl.marketplace,
      itemKey: AppBarItemKey.REQUESTS,
      badgeCount: totalUnseenCounts,
      icon: RequestsIcon as unknown as React.ReactNode
    },
    {
      index: 2,
      label: t("mainNavigationSidebar:submissions"),
      path: frontendUrl.supplierQuotes,
      itemKey: AppBarItemKey.SUBMISSIONS,
      icon: SubmissionsIcon as unknown as React.ReactNode
    },
    {
      index: 3,
      label: t("mainNavigationSidebar:awardedRequests"),
      path: frontendUrl.supplierContracts,
      itemKey: AppBarItemKey.AWARDED_REQUESTS,
      icon: AwardedRequestsIcon as unknown as React.ReactNode
    },
    {
      index: 4,
      label: t("mainNavigationSidebar:orders"),
      path: frontendUrl.supplierLineItems,
      itemKey: AppBarItemKey.SUPPLIER_ORDERS,
      icon: OrdersIcon as unknown as React.ReactNode
    },
    {
      index: 5,
      label: t("workspaceNext:supplierTable:buttons:axyaNetwork"),
      path: "https://app.axya.co/search",
      itemKey: AppBarItemKey.AXYA_NETWORK,
      navigateWithHref: true,
      icon: SupplierDirectoryIcon as unknown as React.ReactNode
    },
    // {
    //   index: 6,
    //   label: t("mainNavigationSidebar:newsfeed"),
    //   path: frontendUrl.newsfeed,
    //   badgeCount: newNewsfeedPosts,
    //   itemKey: AppBarItemKey.NEWSFEED,
    //   icon: NewsfeedIcon,
    // },
    {
      index: 6,
      label: t("mainNavigationSidebar:companyProfile"),
      path: frontendUrl.companyProfile,
      itemKey: AppBarItemKey.COMPANY_PROFILE,
      icon: CompanyProfileIcon as unknown as React.ReactNode
    }
  ];
};
