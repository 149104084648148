import { GridRowParams } from "@mui/x-data-grid-pro-v5";
import { SHORT_PAGINATION_PAGE_SIZE } from "@next/constants";
import React from "react";
import { useSupplierQuotesTableData } from "./supplier-quotes-table.hooks";
import { getRowIdToPk } from "@next/utils/dataGridUtils-v5";
import { history } from "helpers/history";
import { frontendUrl } from "urls";
import { useDispatch } from "react-redux";
import { quotationActions } from "services/quotation";
import { DataGridProV5 } from "@next/components/data-grid-pro-v5";

type Props = {
  query: string;
};

export const SupplierQuotesTable: React.FC<Props> = ({ query }) => {
  const dispatch = useDispatch();
  const { setCurrentPage, count, gridData, loading } =
    useSupplierQuotesTableData({
      query,
    });

  const onRowClick = (params: GridRowParams) => {
    dispatch(quotationActions.setIsEditQuotationMode(true));
    history.push(`${frontendUrl.supplierQuoteView}/${params?.id}`);
  };

  return (
    <DataGridProV5
      autoHeight
      autoPageSize
      pagination
      paginationMode="server"
      pageSize={SHORT_PAGINATION_PAGE_SIZE}
      rowCount={count}
      onPageChange={(newPage) => setCurrentPage(newPage + 1)}
      getRowId={getRowIdToPk}
      getRowClassName={() => "c-cursor-pointer"}
      disableSelectionOnClick
      rowHeight={60}
      loading={loading}
      rows={gridData?.rows || []}
      columns={gridData?.columns || []}
      onRowClick={onRowClick}
    />
  );
};
