/* eslint-disable no-use-before-define */
import _ from "lodash";
import React, { FC, useEffect } from "react";
import { styled } from "@mui/material/styles";
import TextField from "@mui/material/TextField";
import {
  Autocomplete,
  AutocompleteRenderInputParams,
  AutocompleteRenderOptionState,
  Avatar,
  Box,
  Checkbox,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import { OrderSupplier } from "../../redux";
import { CustomTypography } from "@next/components/custom-typography";
import { createStyles, makeStyles } from "@mui/styles";
import { profileActions } from "services/profile";
import { useDispatch, useSelector } from "react-redux";
import { getProfilePreferences } from "services/profile/profile.selectors";
import { PO_GRID_SUPPLIER_FILTER } from "@next/constants/data-grid-state-constants";

const useStyles = makeStyles(() =>
  createStyles({
    paper: {
      boxShadow: `0px 1px 2px rgba(0, 0, 0, 0.3), 0px 2px 6px 2px rgba(0, 0, 0, 0.15)`,
    },
  })
);

const Container = styled(Box)({
  width: "268px",
});

const OptionName = styled(CustomTypography)({
  marginLeft: "8px",
  width: "140px",
});

type Props = {
  suppliers: OrderSupplier[];
  selectedSuppliers: OrderSupplier[];
  setSelectedSuppliers: (value: OrderSupplier[]) => void;
  language?: string;
};

const OrdersSuppliersAutocompleteDropdown: FC<Props> = ({
  suppliers,
  selectedSuppliers,
  setSelectedSuppliers,
  language,
}) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const profilePreferences = useSelector(getProfilePreferences);
  const { editProfilePreferences } = profileActions;

  useEffect(() => {
    const storedSupplierFilter = profilePreferences[PO_GRID_SUPPLIER_FILTER];
    if (
      storedSupplierFilter &&
      !_.isEqual(storedSupplierFilter?.data, selectedSuppliers)
    ) {
      setSelectedSuppliers(storedSupplierFilter?.data);
    }
  }, []);

  const renderOption = (
    props: React.HTMLAttributes<HTMLLIElement>,
    option: OrderSupplier,
    { selected }: AutocompleteRenderOptionState
  ) => {
    return (
      <li {...props}>
        <Box
          display="flex"
          alignItems="center"
          justifyContent="space-between"
          width="100%"
        >
          <Box display="flex" alignItems="center">
            <Avatar src={option?.picture}> </Avatar>

            <OptionName tooltipTitle={option?.name}>{option?.name}</OptionName>
          </Box>

          <Checkbox checked={selected} />
        </Box>
      </li>
    );
  };

  const renderTags = (tags: OrderSupplier[]) => {
    return (
      <Box pl={1} maxWidth="100px">
        {tags.length > 1 ? (
          <CustomTypography variant="body1">
            {t("orders:filterItems", { count: tags.length })}
          </CustomTypography>
        ) : (
          <CustomTypography variant="body1">{tags?.[0]?.name}</CustomTypography>
        )}
      </Box>
    );
  };

  const renderInput = (params: AutocompleteRenderInputParams) => {
    return (
      <TextField
        {...params}
        variant="outlined"
        label={null}
        placeholder={
          selectedSuppliers?.length
            ? t("orders:selectSupplier")
            : t("orders:allSuppliers")
        }
      />
    );
  };

  const onChange = (_event: any, newSupplierSelection: OrderSupplier[]) => {
    setSelectedSuppliers(newSupplierSelection);
    dispatch(
      editProfilePreferences({
        type: PO_GRID_SUPPLIER_FILTER,
        data: newSupplierSelection,
      })
    );
  };

  return (
    <Container>
      <Autocomplete
        multiple
        size="small"
        options={suppliers}
        disableCloseOnSelect
        getOptionLabel={(option) => option.name || ""}
        isOptionEqualToValue={(option, values) => option.id === values.id}
        renderOption={renderOption}
        renderTags={renderTags}
        renderInput={renderInput}
        sx={{ width: language == "en" ? "290px" : "378px" }}
        value={selectedSuppliers}
        onChange={onChange}
        classes={{ paper: classes.paper }}
      />
    </Container>
  );
};

export default OrdersSuppliersAutocompleteDropdown;
