import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import { useTranslation } from "react-i18next";

import InboxMessages from "./thread/quotation-thread.component";
import { AppBar, Box, IconButton, Toolbar, Typography } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import HelpIcon from "@mui/icons-material/Help";

import { FilterQuotationByRfq } from "./filter-quotations-by-rfq/filter-quotations-by-rfq.component";
import { AboutFeatureModal } from "./about-feature-modal/about-feature-modal.component";
import { modalsActions } from "@next/redux/modalsSlices";
import { WorkspaceModalTypes } from "@next/modules/workspace/modals/types";
import { useAxyaTracking } from "@next/hooks/useAxyaTracking";
import { getCompany } from "services/profile/profile.selectors";
import { checkIsSellerCompany } from "@next/utils/checkCompanyUtils";

export const Inbox = ({
  modalState,
  setActiveQuotationThread,
  fetchCompanyRFQList,
  token,
  RFQList,
  isMessagesLoading,
  setContactUsType,
  setContactUsDialogTile,
  userType,
  isAboutFeatureModalOpen,
  ...rest
}) => {
  const { t } = useTranslation("workspace");
  const axyaTracking = useAxyaTracking();
  const company = useSelector(getCompany);
  const isSeller = checkIsSellerCompany(company?.type);
  const dispatch = useDispatch();

  const activeQuotationThread = useSelector(
    (state) => state.inbox.activeQuotationThread
  );

  const handleFeedbackClick = () => {
    dispatch(
      modalsActions.showModal({
        key: WorkspaceModalTypes.CONTACT_US_MODAL,
        data: {
          modalTitle: t("contactUs:giveYouFeedback"),
          hasSubjectAndMsg: false,
          hasFormTitle: true,
          formTitle: t("contactUs:giveYouFeedbackFormTitle"),
          label: t("contactUs:giveYouFeedbackInputLabel"),
        },
      })
    );
    axyaTracking({
      scout_category: "manufacturer_messenger",
      scout_feature_name: "give_feedback_link",
    });
  };

  const handleLinkToVideoClick = () => {
    rest.handleOpenModal(true, "isAboutFeatureModalOpen");
    /**
     * Feature tracking
     */
    axyaTracking({
      scout_category: "manufacturer_messenger",
      scout_feature_name: "messenger_open_link_to_video",
    });
  };

  useEffect(() => {
    if (!isSeller) {
      fetchCompanyRFQList(token, "InProgress&status=Waiting&status=Contract");
    }
    // Auto open the chat box if we had a discussion active in previous session.
    if (activeQuotationThread) {
      rest.handleOpenModal(true, "isChatOpen");
    }
  }, []);

  return (
    <>
      <AppBar
        position="static"
        color="transparent"
        className="c-inbox-drawer--appbar"
      >
        <Toolbar>
          <Box display="flex" flexGrow={1}>
            <Typography variant="h6">{t("messages")}</Typography>
            <p className="c-link-to-video">
              <u
                className="c-link-to-video-link"
                onClick={() => handleLinkToVideoClick()}
              >
                <HelpIcon color="#378EF0" />
              </u>
            </p>
          </Box>
          <Box
            className="c-inbox-drawer--feedback"
            display="flex"
            flexGrow={1}
            alignItems="center"
            color="#378ef0"
          >
            <Typography variant="body2" onClick={() => handleFeedbackClick()}>
              {t("feedbackMsg")}
            </Typography>
          </Box>
          <IconButton
            edge="start"
            color="inherit"
            aria-label="header"
            onClick={() => {
              rest.handleOpenModal(false, "isInboxOpen");
            }}
            size="large"
          >
            <CloseIcon />
          </IconButton>
        </Toolbar>
      </AppBar>
      {/* filtering on buyer side */}
      {rest.user.type === "DO" && (
        <FilterQuotationByRfq RFQList={RFQList} token={token} {...rest} t={t} />
      )}

      <InboxMessages
        {...rest}
        token={token}
        setActiveQuotationThread={setActiveQuotationThread}
        isMessagesLoading={isMessagesLoading}
        getQuotationThreadDetails={rest.getQuotationThreadDetails}
      />
      {isAboutFeatureModalOpen && <AboutFeatureModal />}
    </>
  );
};
