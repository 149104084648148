import React from "react";
import { CustomTypography } from "@next/components/custom-typography";
import { ModifiedTooltip } from "../analytic-tooltip/analytic-tooltip";

type Props = {
  text: string;
  tooltipTitle?: string;
};

export const HeaderCell = ({ text, tooltipTitle }: Props) => {
  return (
    <CustomTypography
      fontWeight={600}
      tooltipTitle={null}
      color="textSecondary"
      variant="body2"
    >
      {text}
      <span style={{ marginLeft: "4px" }}>
        <ModifiedTooltip tooltipTitle={tooltipTitle} />
      </span>
    </CustomTypography>
  );
};
