import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { getModal, modalsActions } from "@next/redux/modalsSlices";
import { WorkspaceModalTypes } from "../types";
import {
  Box,
  Dialog,
  DialogContent,
  DialogTitle,
  Divider,
  IconButton,
} from "@mui/material";
import { CustomTypography } from "@next/components/custom-typography";
import CloseIcon from "@mui/icons-material/Close";
import { QuoteComparisonCustomizeViewFooter } from "./quote-comparison-customize-view-footer";
import { QuoteComparisonCustomizeViewForm } from "./quote-comparison-customize-view-form/quote-comparison-customize-view-form";
import { useTranslation } from "react-i18next";

export const QuoteComparisonCustomizeViewModal = () => {
  const dispatch = useDispatch();
  const modal = useSelector(
    getModal(WorkspaceModalTypes.QUOTE_COMPARISON_CUSTOMIZE_VIEW_MODAL)
  );
  const { t } = useTranslation();

  if (!modal) return null;

  const onClickCloseModal = () => {
    dispatch(
      modalsActions.closeModal(
        WorkspaceModalTypes.QUOTE_COMPARISON_CUSTOMIZE_VIEW_MODAL
      )
    );
  };

  return (
    <Dialog
      open={!!modal}
      onClose={onClickCloseModal}
      closeAfterTransition
      fullWidth={true}
    >
      <DialogTitle>
        <Box display="flex" alignItems="center">
          <Box flexGrow={1}>
            <CustomTypography variant="h6">
              {t("workspaceNext:quoteComparison:customizeView")}
            </CustomTypography>
          </Box>

          <Box>
            <IconButton onClick={onClickCloseModal} size="large">
              <CloseIcon />
            </IconButton>
          </Box>
        </Box>
      </DialogTitle>

      <Divider />

      <DialogContent>
        <QuoteComparisonCustomizeViewForm />
      </DialogContent>

      <QuoteComparisonCustomizeViewFooter onClose={onClickCloseModal} />
    </Dialog>
  );
};
