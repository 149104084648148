import { RootState } from "store";

export const selectNotifications = (state: RootState) =>
  state.appNext.notifications;
export const selectNotificationsLoading = (state: RootState) =>
  state.appNext.notificationsLoading;
export const selectNotificationsPage = (state: RootState) =>
  state.appNext.notificationsPage;
export const selectNotificationIsNextPageAvailable = (state: RootState) =>
  state.appNext.notificationIsNextPageAvailable;

export const selectMarkAllAsSeenNotificationLoading = (state: RootState) =>
  state.appNext.markAllAsSeenNotificationLoading;

export const appSelectors = {
  selectNotifications,
  selectNotificationsLoading,
  selectNotificationsPage,
  selectMarkAllAsSeenNotificationLoading,
  selectNotificationIsNextPageAvailable,
};
