import { Avatar, Box, Divider, List, Typography } from "@mui/material";
import React, { useContext } from "react";
import { getInitials } from "@next/utils/stringUtils";
import { quoteComparisonContext } from "../../components/quote-comparison/quote-comparison.context";
import { countBy, uniqBy } from "lodash";
import { QuoteComparisonCurrencyField } from "../../components/quote-comparison/quote-comparison-currency-field";

export const QCSupplierList = () => {
  const {
    selectedData: { selectedSuppliersParts },
  } = useContext(quoteComparisonContext);
  const selectedSuppliersPartCounts = countBy(selectedSuppliersParts, "id");
  const selectedSuppliersGroup = uniqBy(selectedSuppliersParts, "id");

  return (
    <List disablePadding>
      {selectedSuppliersGroup?.map((supplier) => {
        return (
          <QCSupplierListItem
            supplier={supplier}
            selectedPartCount={selectedSuppliersPartCounts[supplier.id]}
            key={supplier.id}
          />
        );
      })}
    </List>
  );
};

type QCSupplierListItemProps = {
  supplier?: any;
  selectedPartCount?: number;
};

const QCSupplierListItem: React.FC<QCSupplierListItemProps> = ({
  supplier,
  selectedPartCount,
}) => {
  const {
    getSelectedTotalSumBySupplier,
    gridData: { config },
  } = useContext(quoteComparisonContext);
  const supplierConfig = config?.options?.suppliers?.find(
    (item: any) => item?.id === supplier?.id
  );

  if (!supplierConfig) return null;

  const fixedFees = supplierConfig?.fixed_fees || 0;

  return (
    <>
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        height="96px"
      >
        <Box display="flex" justifyContent="space-between" width="100%">
          <Box display="flex">
            <Avatar variant="rounded" src={supplierConfig?.picture_url}>
              {getInitials(supplierConfig?.name || "")}
            </Avatar>

            <Box ml="12px">
              <Typography variant="body1">{supplierConfig?.name}</Typography>
              <Typography variant="body2" color="textSecondary">
                {selectedPartCount} items
              </Typography>
            </Box>
          </Box>

          <Box textAlign="right">
            <Typography variant="body1" style={{ fontWeight: 700 }}>
              <QuoteComparisonCurrencyField
                currency={supplierConfig?.quoted_currency}
                value={getSelectedTotalSumBySupplier(supplier.id)}
              />
            </Typography>

            {fixedFees > 0 ? (
              <Typography variant="body2" color="textSecondary">
                +{" "}
                <QuoteComparisonCurrencyField
                  value={fixedFees}
                  currency={supplierConfig?.quoted_currency}
                />{" "}
                fixed fees
              </Typography>
            ) : null}
          </Box>
        </Box>
      </Box>

      <Divider style={{ marginLeft: "-24px", marginRight: "-24px" }} />
    </>
  );
};
