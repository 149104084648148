import React, { PropsWithChildren, useCallback, useRef, useState } from "react";
import {
  Chip,
  IconButton,
  Menu,
  PaperProps,
  Theme,
  Tooltip,
} from "@mui/material";
import CancelIcon from "@mui/icons-material/Cancel";
import { CustomTooltip } from "./intent-to-bid.definitions";
import { createStyles, makeStyles } from "@mui/styles";

interface IntentToBidMenuPopupProps {
  anchorEl: HTMLElement | null;
  open: boolean;
  handleShowPopupMenu: (event: React.MouseEvent<HTMLButtonElement>) => void;
  label?: string;
  tooltipTitle?: string;
  deleteTooltipTitle?: string;
  paperProps?: Partial<PaperProps> | undefined;
  onDelete?: () => void;
  onClose?: () => void;
  className?: string;
  icon?: React.ReactElement;
  status?: "success" | "error";
  iconOnly?: boolean;
  disabled?: boolean;
}

type StyleProps = {
  status?: IntentToBidMenuPopupProps["status"];
  onDelete?: IntentToBidMenuPopupProps["onDelete"];
};

const useStyles = makeStyles<Theme, StyleProps>((theme) => {
  const getChipBgColor = ({ status }: StyleProps) => {
    return status === "success"
      ? theme.palette.success.dark
      : status === "error"
      ? theme.palette.error.main
      : theme.palette.grey[300];
  };

  return createStyles({
    menu: {
      marginTop: theme.spacing(1),
    },
    chip: {
      backgroundColor: getChipBgColor,
      color: ({ status }) =>
        status ? theme.palette.common.white : theme.palette.text.primary,
      borderColor: theme.palette.text.primary,
      maxWidth: 400,
      "&:hover": {
        backgroundColor: getChipBgColor,
      },
      "&:focus": {
        backgroundColor: getChipBgColor,
      },
      "& .MuiChip-icon": {
        color: "inherit",
        fontSize: 18,
      },
      "& .MuiChip-deleteIcon": {
        color: "inherit",
      },
    },
    iconButton: {
      border: ({ onDelete }) =>
        onDelete ? "none" : `1px solid ${theme.palette.action.disabled}`,
      width: 32,
      height: 32,
      padding: 5,
      backgroundColor: ({ onDelete, status }) =>
        onDelete ? getChipBgColor({ status }) : "transparent",
      "& .MuiSvgIcon-root": {
        fontSize: 20,
        color: (props: StyleProps) =>
          props.onDelete
            ? theme.palette.background.default
            : theme.palette.text.secondary,
      },
      "&:hover": {
        "& .MuiSvgIcon-root": {
          color: (props: StyleProps) =>
            props.onDelete ? theme.palette.text.secondary : undefined,
        },
      },
    },
  });
});

export const IntentToBidMenuPopup: React.FC<
  PropsWithChildren<IntentToBidMenuPopupProps>
> = ({
  label,
  icon,
  children,
  paperProps,
  handleShowPopupMenu,
  onClose,
  onDelete,
  open,
  anchorEl,
  tooltipTitle,
  deleteTooltipTitle,
  className,
  status,
  disabled,
  iconOnly = false,
}) => {
  const classes = useStyles({ status, onDelete });
  const [tooltipVisible, setTooltipVisible] = useState(false);
  const isDeleteHovered = useRef(false);

  //prevent overlapping of tooltips
  const handleClose = useCallback(() => {
    setTooltipVisible(false);
  }, []);

  const handleOpen = useCallback(() => {
    if (!isDeleteHovered.current) setTooltipVisible(true);
  }, [isDeleteHovered.current]);

  const onDeleteTooltipOpen = useCallback(() => {
    isDeleteHovered.current = true;
    handleClose();
  }, []);

  const onDeleteTooltipClosed = useCallback(() => {
    isDeleteHovered.current = false;
  }, []);

  const handleIconClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();
    if (onDelete) onDelete();
    else if (handleShowPopupMenu) handleShowPopupMenu(event);
    return true;
  };

  return (
    <>
      <CustomTooltip
        open={tooltipVisible}
        onOpen={handleOpen}
        onClose={handleClose}
        title={tooltipTitle as string}
      >
        {iconOnly ? (
          <IconButton
            onClick={handleIconClick}
            className={classes.iconButton}
            disabled={disabled}
            size="large"
          >
            {icon}
          </IconButton>
        ) : (
          <Chip
            size="medium"
            icon={icon}
            label={label}
            onClick={(event: React.MouseEvent) =>
              handleShowPopupMenu(event as React.MouseEvent<HTMLButtonElement>)
            }
            disabled={disabled}
            onDelete={onDelete}
            deleteIcon={
              <Tooltip
                onOpen={onDeleteTooltipOpen}
                onClose={onDeleteTooltipClosed}
                title={deleteTooltipTitle || ""}
              >
                <CancelIcon />
              </Tooltip>
            }
            className={`${className} ${classes.chip}`}
          />
        )}
      </CustomTooltip>

      <Menu
        className={classes.menu}
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={open}
        onClose={onClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        PaperProps={paperProps}
      >
        {children}
      </Menu>
    </>
  );
};
