import * as yup from "yup";
import { t } from "assets/configi18n/i18n";
import { CreateTopicInput } from "@next/modules/workspace/redux";

export const initialValues: Partial<CreateTopicInput> = {
  body: "",
};

export const validationSchema = yup.object({
  body: yup
    .string()
    .required(t("project:rfqDrawer:rfqForm:errorMessage:thisFieldIsRequired")),
});
