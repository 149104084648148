// general imports
import React from "react";

import {
  Box,
  Button,
  Grid,
  Paper,
  Typography,
  useMediaQuery,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import { makeStyles } from "@mui/styles";

import PartDialog from "../../part-dialog/part-dialog.component";
import FilterPartTags from "../../filter-part-tags/filter-part-tags.component";
import { AppTheme } from "layout/AppTheme";

const useStyles = makeStyles((theme) => ({
  stickybar: {
    padding: "24px 0 13px 0",
  },
  button: {
    textTransform: "none",
  },
}));

// the header of the workspace, with buttons to  create a new part or ask for an estimate
export const WorkspaceHeader = (props) => {
  const {
    handleCloseModal,
    handleOpenModal,
    openModal,
    isValid,
    handleClickOpen,
    checked,
    t,
  } = props;
  const isMobile = useMediaQuery(AppTheme.breakpoints.down("lg"));

  const classes = useStyles();

  const countNumberOfSelectedParts = (checked) => {
    return (
      <Typography
        className={
          checked.length
            ? "c-workspace-cards--selected"
            : "c-workspace-cards--none-selected"
        }
        style={{ lineHeight: "inherit" }}
      >
        {checked.length
          ? `${t("workspace:partSelectedWithCount", {
              count: checked.length,
            })}`
          : t("noPartsSelected")}
      </Typography>
    );
  };
  return (
    <Paper elevation={0}>
      <Grid
        container
        direction="row"
        justifyContent="space-between"
        className={classes.stickybar}
      >
        <Grid item xs={12} sm={12} md={6}>
          <Box display="flex" alignItems="center">
            <Box>
              <Button
                data-tut="reactour__newPart"
                size="large"
                data-test="newPartButton"
                variant="outlined"
                color="secondary"
                startIcon={<AddIcon />}
                onClick={handleOpenModal}
                style={{
                  whiteSpace: "nowrap",
                  minWidth: "auto",
                }}
              >
                {t("workspace:newPart")}
              </Button>
            </Box>
            <Box ml={1}>
              <FilterPartTags t={t} />
            </Box>
          </Box>
          <PartDialog isOpen={openModal} handleCloseModal={handleCloseModal} />
        </Grid>

        <Grid
          item
          xs={12}
          sm={12}
          md={6}
          container
          direction="row"
          justifyContent="flex-end"
          alignItems="center"
          style={{ marginTop: isMobile ? "16px" : "0px" }}
        >
          <Grid item>{countNumberOfSelectedParts(checked)}</Grid>
          <Grid item>
            <Button
              data-tut="reactour__createRfq"
              data-test="createRfqButton"
              variant="contained"
              size="large"
              color="primary"
              onClick={handleClickOpen}
              className={classes.button}
            >
              {t("BUNDLE:create_quote")}
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </Paper>
  );
};
