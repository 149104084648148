import React from "react";
import Modal from "@next/components/custom-modal";
import { useDispatch, useSelector } from "react-redux";
import { SharedModalTypes } from "./types";
import { getModal, modalsActions } from "@next/redux/modalsSlices";
import CustomButton from "@next/components/custom-button";
import { Box, Typography } from "@mui/material";
import { createStyles, makeStyles } from "@mui/styles";
import { t } from "assets/configi18n/i18n";

const useStyles = makeStyles(() =>
  createStyles({
    customButtonStyle: {
      width: "76px",
      height: "40px",
    },
  })
);

type Props = {
  moduleType: string;
};
export const SharedDeleteInternalNotesModal: React.FC<Props> = ({
  moduleType,
}) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const modal = useSelector(
    getModal(SharedModalTypes.INTERNAL_NOTES_DELETE_TAG_MODAL)
  );

  const data = modal?.data as {
    noteTitle: string;
    noteId: string | number;
    noteUsageCount: number;
    removeNotesRows: (deletedNoteId: string | number) => void;
  };
  const handleModalClose = () => {
    dispatch(
      modalsActions.closeModal(SharedModalTypes.INTERNAL_NOTES_DELETE_TAG_MODAL)
    );
  };
  const handleDeleteTag = () => {
    if (typeof data?.removeNotesRows == "function") {
      data.removeNotesRows(data?.noteId);
      dispatch(
        modalsActions.closeModal(
          SharedModalTypes.INTERNAL_NOTES_DELETE_TAG_MODAL
        )
      );
    }
  };
  const ModalBody = () => {
    return (
      <div>
        <Typography variant="body1" marginBottom="16px">
          {t("project:table:row:deleteInternalNotes:delTagHeading", {
            tag: data?.noteTitle,
          })}
        </Typography>
        <Typography variant="body1" marginBottom="16px">
          {t("project:table:row:deleteInternalNotes:delTagWithCount", {
            tag: data?.noteTitle,
          })}{" "}
          : <span style={{ fontWeight: "500" }}>{data?.noteUsageCount}</span>
        </Typography>
        <Box
          display="flex"
          flexDirection="row"
          justifyContent="flex-end"
          gap="16px"
        >
          <CustomButton
            className={classes.customButtonStyle}
            variant="outlined"
            color="primary"
            onClick={handleModalClose}
          >
            {t("common:cancel")}
          </CustomButton>
          <CustomButton
            className={classes.customButtonStyle}
            variant="contained"
            color="error"
            onClick={handleDeleteTag}
          >
            {t("common:delete")}
          </CustomButton>
        </Box>
      </div>
    );
  };

  return (
    <Modal
      customWidth="472px"
      isModalOpen={!!modal}
      handleModalClose={handleModalClose}
      modalState={{
        modalHeader: t("project:table:row:internalNotes:deleteTag"),
        modalBody: ModalBody(),
      }}
    />
  );
};
