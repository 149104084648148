import { Box, styled } from "@mui/material";
import { CustomTypography } from "@next/components/custom-typography";

export const StyledInnerBox = styled(Box)({
  width: "100%",
  display: "flex",
  flexDirection: "column",
  gap: "24px",
});

export const StyledTypography = styled(CustomTypography)({
  letterSpacing: "0.5px",
  color: "rgba(0, 0, 0, 0.87)",
});

export const StyledFormFieldBox = styled(Box)({
  width: "48%",
  display: "flex",
});
