import React, { useMemo, useState } from "react";
import { Box, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { TabsView } from "@next/components/tabs-view";
import { TabPanel } from "@next/components/tab-panel";
import { SellerDashboardBuyerViewsTable } from "../seller-dashboard-buyer-views-table/seller-dashboard-buyer-views-table";
import { SellerDashboardPendingInvitationsTable } from "../seller-dashboard-pending-invitations-table/seller-dashboard-pending-invitations-table";

export const SellerDashboardMyNetworkTabs = () => {
  const { t } = useTranslation();
  const [selectedTab, setSelectedTab] = useState(0);

  const tabItems = useMemo(
    () => [
      {
        tabName: (
          <Box data-pid={"tabDashboardViewConnections"}>
            <Typography>{t("sellerDashboard:connections")}</Typography>
          </Box>
        ),
      },
      {
        tabName: (
          <Box data-pid={"tabDashboardPendingInvitations"}>
            <Typography>{t("sellerDashboard:pendingInvitations")}</Typography>
          </Box>
        ),
      },
    ],
    []
  );

  const tabHandleChange = (_: React.ChangeEvent<{}>, value: any) =>
    setSelectedTab(value);

  return (
    <Box width={"100%"}>
      <Box>
        <TabsView
          value={selectedTab}
          tabItems={tabItems}
          handleChange={tabHandleChange}
        />

        <Box>
          <TabPanel value={selectedTab} index={0}>
            <SellerDashboardBuyerViewsTable />
          </TabPanel>
          <TabPanel value={selectedTab} index={1}>
            <Box>
              <SellerDashboardPendingInvitationsTable />
            </Box>
          </TabPanel>
        </Box>
      </Box>
    </Box>
  );
};
