import React from "react";
import { t } from "assets/configi18n/i18n";
import { Trans } from "react-i18next";

import { Box, Tooltip, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import CompareArrowsIcon from "@mui/icons-material/CompareArrows";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { getLeadTimeDelayUnit } from "@next/components/lead-time/lead-time.definitions";
import CurrencyField from "components/common/fields/CurrencyField";

const useStyles = makeStyles({
  reportBox: {
    background:
      "linear-gradient(135deg, rgba(31, 127, 255, 0.20) 0%, rgba(38, 130, 253, 0.03) 100%)",
    borderRadius: 16,
    padding: 24,
    margin: "20px 0",
  },
  insightBox: {
    padding: 8,
    marginRight: 24,
    borderRadius: 5,
    background: "white",
  },
  insightIcon: {
    fontSize: 30,
  },
  infoIcon: {
    margin: "0 3px",
    fontSize: 18,
  },
  descriptionBox: {
    flexGrow: 1,
  },
});

interface Props {
  delay?: number;
  delayUnit?: string;
  totalPrice?: number;
  report: {
    winner_lead_time_percentage: number;
    winner_total_price_percentage: number;
    average_lead_time_quartile?: "Q1" | "Q2" | "Q3" | "Q4";
    average_total_price_quartile?: "Q1" | "Q2" | "Q3" | "Q4";
  };
}
const competitivenessTranslations = {
  Q1: t("quotation:competitivenessInsights:firstQuartile"),
  Q2: t("quotation:competitivenessInsights:secondQuartile"),
  Q3: t("quotation:competitivenessInsights:thirdQuartile"),
  Q4: t("quotation:competitivenessInsights:fourthQuartile"),
};

function getQuartileText(quarter: "Q1" | "Q2" | "Q3" | "Q4"): string {
  const translations = competitivenessTranslations;
  return translations[quarter] || translations.Q4;
}

export const CompetitivenessInsights: React.FC<Props> = ({
  delay,
  delayUnit,
  totalPrice,
  report,
}) => {
  const classes = useStyles();

  const winnerPricePercent = Math.abs(report.winner_total_price_percentage);
  const winnerPricePercentPosition =
    report.winner_total_price_percentage > 0
      ? t("quotation:competitivenessInsights:higher")
      : t("quotation:competitivenessInsights:lower");
  const winnerLeadTimePercent = Math.abs(report.winner_lead_time_percentage);
  const winnerLeadTimePercentPosition =
    report.winner_lead_time_percentage > 0
      ? t("quotation:competitivenessInsights:longer")
      : t("quotation:competitivenessInsights:shorter");

  return (
    <Box
      className={classes.reportBox}
      display="flex"
      alignItems="flex-start"
      justifyContent="space-between"
    >
      <Box className={classes.insightBox}>
        <CompareArrowsIcon color="primary" className={classes.insightIcon} />
      </Box>
      <Box className={classes.descriptionBox}>
        <Typography color="textSecondary" variant="body2">
          {t("quotation:competitivenessInsights:title")}
          <Tooltip
            title={t("quotation:competitivenessInsights:titleTooltip") || ""}
          >
            <InfoOutlinedIcon color="primary" className={classes.infoIcon} />
          </Tooltip>
        </Typography>
        <Box>
          <Typography style={{ marginTop: 2 }}>
            <Trans
              i18nKey="quotation:competitivenessInsights:winnerDescription"
              values={{
                winnerPricePercent,
                winnerPricePercentPosition,
                winnerLeadTimePercent,
                winnerLeadTimePercentPosition,
              }}
            />
          </Typography>
          {report.average_lead_time_quartile &&
            report.average_total_price_quartile && (
              <Typography style={{ marginTop: 8 }}>
                <Trans
                  i18nKey="quotation:competitivenessInsights:averageDescription"
                  values={{
                    averagePriceQuartile: getQuartileText(
                      report.average_total_price_quartile
                    ),
                    averageLeadTimeQuartile: getQuartileText(
                      report.average_lead_time_quartile
                    ),
                  }}
                />
              </Typography>
            )}
        </Box>
      </Box>
      <Box display="flex" alignItems="flex-start">
        {totalPrice && (
          <Box marginX={2}>
            <Typography color="textSecondary" variant="body2">
              {t("quotation:competitivenessInsights:myQuote")}
            </Typography>
            <Typography>
              <CurrencyField value={totalPrice} />
            </Typography>
          </Box>
        )}
        {delay && delayUnit && (
          <Box marginX={2}>
            <Typography color="textSecondary" variant="body2">
              {t("quotation:competitivenessInsights:myLeadTime")}
            </Typography>
            <Typography>
              {delay} {getLeadTimeDelayUnit(delay, delayUnit)}
            </Typography>
          </Box>
        )}
      </Box>
    </Box>
  );
};
