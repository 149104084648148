// Update if exists or add new element to the array with immutable way
export const addOrUpdateField = (arr: any = [], item: any) => {
  const index = arr.findIndex((f: any) => item.pk === f.pk);

  if (-1 === index) {
    return [item, ...arr];
  }

  return [
    ...arr.slice(0, index),
    { ...arr[index], ...item },
    ...arr.slice(index + 1),
  ];
};

export const addOrRemove = (
  array: any,
  item: any,
  comparisonKey: string = "pk"
) => {
  const exists = array?.find(
    (data: any) => data?.[comparisonKey] === item?.[comparisonKey]
  );

  if (exists) {
    return array?.filter(
      (data: any) => data?.[comparisonKey] !== item?.[comparisonKey]
    );
  } else {
    return [...array, item];
  }
};
