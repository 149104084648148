import React from "react";
import {
  GridColumns,
  GridRenderCellParams,
  GridValueGetterParams,
} from "@mui/x-data-grid-pro-v5";
import { t } from "assets/configi18n/i18n";
import { formatDate } from "helpers/formatDate";
import { OrderField } from "@next/modules/project/components/order/types";
import { RFQDisplayNameCell } from "@next/modules/project/components/order/project-order-table-v4.columns";
import { DashboardFetchType } from "./dashboard-rfqs-modal.types";
import LeadTimeCell from "@next/components/table-cells/lead-time-cell-v5";
import RatingCell from "@next/components/table-cells/rating-cell-v5";
import IconButtonCell from "@next/components/table-cells/icon-button-cell-v5";
import TextCell from "@next/components/table-cells/text-cell-v5";

const defaultColumnConfig = {
  disableColumnMenu: true,
  sortable: false,
  hideSortIcons: true,
};

export const getTableColumnsForDashboardFetchType = (
  fetchType: DashboardFetchType
) => {
  const _columns: GridColumns = [
    {
      ...defaultColumnConfig,
      field: OrderField.RFQ_DISPLAY_NAME,
      minWidth: 200,
      headerName: t("project:table:header:name"),
      renderCell: RFQDisplayNameCell as any,
      flex: 1,
    },
    ...(((fetchType === DashboardFetchType.ACTIVE_RFQS ||
      fetchType === DashboardFetchType.WAITING_RFQS) && [
      {
        ...defaultColumnConfig,
        field: OrderField.QUOTATIONS_COUNT,
        minWidth: 200,
        headerName: t("project:table:header:quotations"),
        renderCell: TextCell,
        flex: 1,
      },

      {
        field: OrderField.DEADLİNE,
        minWidth: 180,
        headerName: t("project:table:header:deadline"),
        valueGetter: (params: GridValueGetterParams) =>
          formatDate(params.value),
        renderCell: TextCell,
        flex: 1,
      },
    ]) ||
      []),
    ...(((fetchType === DashboardFetchType.PO_PENDING_CONTRACTS ||
      fetchType === DashboardFetchType.WAITING_RATE) && [
      {
        ...defaultColumnConfig,
        field: OrderField.WINNER,
        minWidth: 200,
        headerName: t("project:table:header:supplier"),
        renderCell: TextCell,
        flex: 1,
      },
      {
        ...defaultColumnConfig,
        field: OrderField.LEAD_TIME,
        minWidth: 200,
        headerName: t("project:table:header:leadTimeAndPrice"),
        renderCell: (params: GridRenderCellParams) => (
          <LeadTimeCell {...params} showPOCell />
        ),
        flex: 1,
      },
    ]) ||
      []),
    ...((fetchType === DashboardFetchType.WAITING_RATE && [
      {
        ...defaultColumnConfig,
        field: OrderField.RATING_SCORE,
        minWidth: 200,
        headerName: t("project:table:header:rating"),
        renderCell: RatingCell,
        flex: 1,
      },
    ]) ||
      []),
    {
      ...defaultColumnConfig,
      field: OrderField.EDIT_BUTTON,
      headerName: " ",
      width: 40,
      align: "center",
      renderCell: IconButtonCell,
      hide:
        fetchType === DashboardFetchType.PO_PENDING_CONTRACTS ||
        fetchType === DashboardFetchType.WAITING_RATE,
    },
  ];

  return _columns;
};
