import {
  GridColDef,
  GridColumns,
  GridLinkOperator,
  useGridApiRef,
} from "@mui/x-data-grid-pro-v5";
import { debounce, isEqual } from "lodash";
import { useEffect, useState } from "react";
import { getProfilePreferences } from "services/profile/profile.selectors";
import { profileActions } from "services/profile/profile.actions";
import { useDispatch, useSelector } from "react-redux";

export type ColumnState = {
  [columnName: string]: {
    width: number | undefined;
    computedWidth: number | undefined;
    minWidth?: number | undefined;
  };
};

export type TableColumsState = {
  [tableName: string]: {
    order: Array<string>;
    state: ColumnState;
    sort: string;
    filters: string;
  };
};

const useSavedGridState = (preferenceKey: string, tableName: string) => {
  const dispatch = useDispatch();
  const profilePreferences = useSelector(getProfilePreferences);
  const { editProfilePreferences } = profileActions;
  const INITIAL_STATE: TableColumsState = {
    [tableName]: {
      order: [],
      state: {},
      sort: "[]",
      filters: "[]",
    },
  };

  const apiRef = useGridApiRef();
  const [tableState, setTableState] = useState<TableColumsState>(INITIAL_STATE);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (apiRef.current && apiRef.current.getAllColumns && !loading) {
      const columns = apiRef.current.getAllColumns();
      apiRef.current.updateColumns(restoreState(columns));
      restoreTableSortState();
      restoreTableFilterState();
    }
  }, [tableState, loading]);

  useEffect(() => {
    if (apiRef.current && apiRef.current.subscribeEvent) {
      // Null check for subscribeEvent function
      apiRef.current.subscribeEvent("columnOrderChange", handleOnStateChange);
      apiRef.current.subscribeEvent("columnWidthChange", saveTableState);
      apiRef.current.subscribeEvent("sortModelChange", handleSortChange);
      apiRef.current.subscribeEvent("filterModelChange", handleFilterChange);
    }

    return () => {
      if (apiRef.current && apiRef.current.unstable_eventManager) {
        apiRef.current.unstable_eventManager.removeListener(
          "columnOrderChange",
          handleOnStateChange
        );
        apiRef.current.unstable_eventManager.removeListener(
          "columnWidthChange",
          saveTableState
        );
        apiRef.current.unstable_eventManager.removeListener(
          "sortModelChange",
          handleSortChange
        );
        apiRef.current.unstable_eventManager.removeListener(
          "filterModelChange",
          handleFilterChange
        );
      }
    };
  }, [apiRef]);

  useEffect(() => {
    if (profilePreferences) {
      const tableState = profilePreferences[preferenceKey];
      if (tableState && tableState?.data) {
        setTableState(tableState.data);
        setLoading(false);
      }
    }
  }, [profilePreferences]);

  const saveTableState = () => {
    const [columnOrder, columnState] = getGridColumnState();
    const filterState = getGridFilterState();
    const sortState = getGridSortState();

    const newState = {
      order: columnOrder,
      state: columnState,
      sort: sortState,
      filters: filterState,
    };

    dispatch(
      editProfilePreferences({
        type: preferenceKey,
        data: {
          ...tableState,
          [tableName]: newState,
        },
      })
    );
    setLoading(true);
  };

  const getGridColumnState = () => {
    //Get column order and width
    const { columns } = apiRef.current.state;
    const { all, lookup } = columns;

    const updatedColumnState: ColumnState = {};
    Object.keys(lookup).forEach((column) => {
      const currentColumn = lookup[column];
      updatedColumnState[column] = {
        width: currentColumn.width,
        computedWidth: currentColumn.computedWidth,
        minWidth: currentColumn.minWidth,
      };
    });
    return [all, updatedColumnState];
  };

  const getGridFilterState = (): string => {
    const { filter } = apiRef.current.state;
    return JSON.stringify(filter.filterModel.items);
  };

  const getGridSortState = (): string => {
    const { sorting } = apiRef.current.state;
    return JSON.stringify(sorting.sortModel);
  };

  const handleOnStateChange = debounce(saveTableState, 1000);

  const handleSortChange = () => {
    handleOnStateChange();
  };

  const handleFilterChange = () => {
    handleOnStateChange();
  };

  const columnSort = (columnA: GridColDef, columnB: GridColDef) => {
    const { order } = tableState[tableName];
    const indexA = order.indexOf(columnA.field);
    const indexB = order.indexOf(columnB.field);

    // If both names are found in orderArray, compare their indices
    if (indexA !== -1 && indexB !== -1) {
      return indexA - indexB;
    }

    // If one name is found and the other is not, prioritize the one found
    if (indexA !== -1) return -1;
    if (indexB !== -1) return 1;

    // If neither name is found, maintain the original order
    return 0;
  };

  const restoreTableSortState = () => {
    if (
      apiRef.current &&
      apiRef.current.setSortModel &&
      apiRef.current.getSortModel
    ) {
      const savedSortState = tableState[tableName]?.sort;
      const currentState = apiRef.current?.getSortModel();
      if (
        savedSortState &&
        !isEqual(JSON.parse(savedSortState), currentState)
      ) {
        apiRef.current.setSortModel(JSON.parse(tableState[tableName]?.sort));
      }
    }
  };

  const restoreTableFilterState = () => {
    if (
      apiRef.current &&
      apiRef.current.setFilterModel &&
      apiRef.current.state
    ) {
      const savedFilters = tableState[tableName]?.filters;
      const { filter } = apiRef.current.state;
      if (
        savedFilters &&
        savedFilters !== JSON.stringify(filter.filterModel.items)
      ) {
        apiRef.current.setFilterModel({
          items: JSON.parse(savedFilters),
          linkOperator: GridLinkOperator.And,
        });
      }
    }
  };

  const restoreState = (columns: GridColumns): GridColumns => {
    const columnState = tableState[tableName]?.state;
    // update column properties like width etc
    if (!columnState) return columns;
    columns = columns.map((column) => {
      if (column?.minWidth && columnState[column.field]) {
        columnState[column.field].minWidth = column?.minWidth;
      }
      return {
        ...column,
        ...columnState[column.field],
      };
    });

    // restore column order
    columns = columns.sort(columnSort);
    return columns;
  };

  return { apiRef, restoreState, loadingSavedGridState: loading };
};

export default useSavedGridState;
