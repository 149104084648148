import {
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Theme,
} from "@mui/material";
import React from "react";
import EmailIcon from "@mui/icons-material/Email";
import { useTranslation } from "react-i18next";
import { createStyles, makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    listItemIcon: {
      minWidth: "32px",
    },
    icon: {
      width: "20px",
      height: "20px",
      color: theme.palette.text.primary,
    },
  })
);

type Props = {
  anchorEl: null | Element;
  setAnchorEl: (anchorEl: null | Element) => void;
  onClickResendInvite: () => void;
};

export const CompanyMembersTableActionMenu: React.FC<Props> = ({
  anchorEl,
  setAnchorEl,
  onClickResendInvite,
}) => {
  const { t } = useTranslation();
  const classes = useStyles();

  return (
    <Menu
      keepMounted
      open={Boolean(anchorEl)}
      anchorEl={anchorEl}
      onClose={() => setAnchorEl(null)}
    >
      <MenuItem onClick={onClickResendInvite}>
        <ListItemIcon className={classes.listItemIcon}>
          <EmailIcon className={classes.icon} />
        </ListItemIcon>
        <ListItemText primary={t("profile:members:resendInvite")} />
      </MenuItem>
    </Menu>
  );
};
