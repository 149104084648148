import { Box, Theme, Typography } from "@mui/material";
import CustomButton from "@next/components/custom-button";
import React from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { selectUpdateQuoteComparisonConfigLoading } from "../../redux/selectors";
import { createStyles, makeStyles } from "@mui/styles";

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    footer: {
      position: "sticky",
      bottom: 0,
      backgroundColor: theme.palette.common.white,
      display: "flex",
      flexDirection: "column",
      zIndex: 10,
    },
    button: {
      height: 40,
    },
  })
);

interface Props {
  onSubmit?: () => void;
  onClose?: () => void;
}

export const QuoteComparisonCustomizeViewFooter: React.FC<Props> = ({
  onSubmit,
  onClose,
}) => {
  const updateQuoteComparisonConfigLoading = useSelector(
    selectUpdateQuoteComparisonConfigLoading
  );
  const { t } = useTranslation();
  const classes = useStyles();

  return (
    <div className={classes.footer}>
      <Box m={2} display="flex" justifyContent="flex-end" alignItems="center">
        <Box display="flex">
          <Box mr="8px">
            <CustomButton
              onClick={onClose}
              type="submit"
              variant="outlined"
              className={classes.button}
              fullWidth
            >
              <Typography variant="body2">{t("common:cancel")}</Typography>
            </CustomButton>
          </Box>

          <CustomButton
            onClick={onSubmit}
            type="submit"
            variant="contained"
            color="primary"
            className={classes.button}
            fullWidth
            form="QuoteComparisonCustomizeViewForm"
            loading={updateQuoteComparisonConfigLoading}
          >
            <Typography variant="body2">{t("common:save")}</Typography>
          </CustomButton>
        </Box>
      </Box>
    </div>
  );
};
