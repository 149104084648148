import React from "react";
import {
  GridCloseIcon,
  GridColDef,
  GridColumnHeaderParams,
  GridRenderCellParams,
} from "@mui/x-data-grid-pro-v5";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { EditableDateTemplateCell } from "../table-cells/editable-date-template-cell";
import { EditableTemplateCell } from "../table-cells/editable-template-cell";
import { HeaderCell } from "../table-cells/header-cell";
import IconButtonCell from "@next/components/table-cells/icon-button-cell-v5";

export const selectComplianceMenuItems = [
  { key: "fully_compliant", value: "Fully compliant" },
  { key: "partially_compliant", value: "Partially compliant" },
  { key: "not_compliant", value: "Not compliant" },
];
const sinceOptionsForDateTemplateCell = ["fully_compliant"];
const byOptionsForDateTemplateCell = ["partially_compliant", "not_compliant"];

const defaultColumnConfig: Partial<GridColDef> = {
  flex: 0.5,
  sortable: false,
  filterable: false,
  disableColumnMenu: true,
};

export const getSubtierReadinessFormTableColumns = () => {
  const columns: GridColDef[] = [
    {
      ...defaultColumnConfig,
      field: "name",
      headerName: "Spec",
    },
    {
      ...defaultColumnConfig,
      field: "dash_ref",
      headerName: "Dash Ref",
      renderCell: (params) => {
        const value = params.value;
        return value === "nan" ? "n/a" : value;
      },
    },
    {
      ...defaultColumnConfig,
      field: "revision",
      headerName: "Revision",
    },
    {
      ...defaultColumnConfig,
      field: "controlled_process",
      headerName: "Controlled process",
    },
    {
      ...defaultColumnConfig,
      field: "description",
      headerName: "Description",
    },
    {
      ...defaultColumnConfig,
      field: "compliance",
      headerName: "Compliance",
      renderHeader: (params: GridColumnHeaderParams) => (
        <HeaderCell {...params} required={true} />
      ),
      renderCell: (params: GridRenderCellParams) => {
        const complianceText =
          selectComplianceMenuItems.find((item) => item.key === params.value)
            ?.value || params.value;

        return (
          <EditableTemplateCell
            {...params}
            icon={<KeyboardArrowDownIcon />}
            placeholderText="Select an option"
            value={complianceText}
          />
        );
      },
    },
    {
      ...defaultColumnConfig,
      field: "poe",
      headerName: "POE (Point of Embodiment)",
      renderHeader: (params: GridColumnHeaderParams) => (
        <HeaderCell {...params} required={true} />
      ),
      renderCell: (params: GridRenderCellParams) => (
        <EditableDateTemplateCell
          checkField={"compliance"}
          sinceOptions={sinceOptionsForDateTemplateCell}
          byOptions={byOptionsForDateTemplateCell}
          {...params}
        />
      ),
    },
    {
      ...defaultColumnConfig,
      field: "removeRowButton",
      headerName: " ",
      align: "center",
      renderCell: (params) => (
        <IconButtonCell {...params} Icon={GridCloseIcon} />
      ),
      flex: 0.1,
    },
  ];

  return columns;
};
