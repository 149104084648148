import React from "react";
import { Grid } from "@mui/material";
import Skeleton from "@mui/material/Skeleton";

export const ContentLoader = ({ isChatWindow = false, count }) =>
  new Array(count).fill("").map((_, i) => {
    return (
      <Grid
        key={i}
        container
        item
        display="flex"
        className="c-quotation-loader-section"
      >
        <Skeleton variant="circular" width={40} height={40} />
        <div className="c-quotation-loader">
          <Skeleton variant="text" />
          <Skeleton variant="text" width={isChatWindow ? "50%" : "100%"} />
          {isChatWindow && <Skeleton variant="text" width="70%" />}
        </div>
      </Grid>
    );
  });
