import { Box, TextField, Typography } from "@mui/material";
import React, { useCallback } from "react";
import { ProjectTemplate } from "../../redux";
import { useTranslation } from "react-i18next";
import PaginatedAutoComplete from "@next/components/paginated-autocomplete/paginated-autocomplete";
interface Props {
  options: ProjectTemplate[];
  value?: ProjectTemplate;
  onChange: (value: ProjectTemplate) => void;
  disabled?: boolean;
  showDescription?: boolean;
  addMargins?: boolean;
  customWidth?: number;
  asProjectOverlay?: boolean;
}

const TemplateSelector: React.FC<Props> = ({
  options,
  value,
  onChange,
  disabled,
  showDescription = true,
  addMargins = true,
  customWidth,
  asProjectOverlay,
}) => {
  const { t } = useTranslation();

  const handleChange = useCallback(
    (option) => {
      onChange(option);
    },
    [onChange]
  );

  return (
    <Box
      display="flex"
      flexDirection="column"
      alignItems="center"
      mt={addMargins ? 10 : 0}
      mb={addMargins ? 5 : 0}
      sx={
        asProjectOverlay
          ? {
              zIndex: 200,
              position: "absolute",
              left: "50%",
              transform: "translate(-50%, 0%)",
            }
          : {}
      }
    >
      {showDescription && (
        <Typography variant="body1">
          {t("project:templateSelector:description")}
        </Typography>
      )}

      <Box my={addMargins ? 3 : 0}>
        <PaginatedAutoComplete
          style={{ width: customWidth || 360 }}
          disableClearable
          value={value}
          inputValue={undefined}
          onSelectionChange={handleChange}
          getOptionSelected={(
            option: ProjectTemplate,
            value?: ProjectTemplate
          ) => option.id === value?.id}
          renderOption={(option) => option.name}
          getOptionLabel={(option: ProjectTemplate) => option.name || ""}
          options={options || []}
          size="small"
          disabled={disabled}
          placeholder={t("project:templateSelector:placeholder")}
          multiple={false}
          disableCloseOnSelect={false}
          renderInput={(params: any) => (
            <TextField
              {...params}
              disabled={disabled}
              placeholder={t("project:templateSelector:placeholder")}
              fullWidth
              variant="outlined"
              className="form-text-field"
              color="secondary"
            />
          )}
        />
      </Box>
    </Box>
  );
};

export default TemplateSelector;
