import React, { useState } from "react";
import { useTranslation } from "react-i18next";

import { batch, useDispatch, useSelector } from "react-redux";

import { Button, MenuItem } from "@mui/material";
import ClearIcon from "@mui/icons-material/Clear";

import ConfirmationDialog from "@next/components/confirmation-dialog/confirmation-dialog";
import { getToken } from "services/profile/profile.selectors";
import { marketplaceActions } from "services/marketplace/marketplace.actions";
import { getRfqIntentToNoQuoteReasons } from "services/part-configuration/part-configuration.selectors";
import { useAxyaTracking } from "@next/hooks/useAxyaTracking";
import { marketplaceActions as marketplaceNextActions } from "../../redux";
import { selectIsIntentToNoQuote } from "../../redux/selectors";
import { IntentToBidMenuPopup } from "../intent-to-bid/intent-to-bid-menu-popup";
import {
  PaperProps,
  useStyles,
} from "../intent-to-bid/intent-to-bid.definitions";
import {
  IntentToNoQuoteProps,
  IntentToNoQuoteReasons,
} from "./intent-to-bid.types";
import IntentToNoQuoteReasonModal from "../../modals/intent-to-no-quote-reason-modal";
import { modalsActions } from "@next/redux/modalsSlices";
import { MarketplaceModalTypes } from "../../modals/types";
import { OTHER_REASON_SLUG, getITNBReasonLabel } from "./intent-to-bid.helpers";

export const IntendToNoQuote: React.FC<IntentToNoQuoteProps> = ({
  preference,
  isRecommended,
  itnbReason,
  itnbReasonTitle,
  itnbReasonDescription,
  tabIndex,
  rfqDisplayName,
  dueDateToBid,
  disabled,
  iconOnly,
}) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const dispatch = useDispatch();
  const axyaTrack = useAxyaTracking();
  const token = useSelector(getToken);
  const intendToNoQuoteReasons = useSelector(getRfqIntentToNoQuoteReasons);
  const isIntentToNoQuote = useSelector(selectIsIntentToNoQuote);
  const [isConfirmationDialogOpen, setIsConfirmationDialogOpen] =
    useState(false);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const prefs = {
    type: "intent_to_bid_rfqs",
    rfq_pk: preference.rfq_pk,
    rfq_display_name: rfqDisplayName,
  };

  const handleShowIntentToNoQuoteMenu = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    if (itnbReason) {
      setAnchorEl(null);
    } else {
      setAnchorEl(event.currentTarget);
    }
    axyaTrack({
      scout_category: "quotation",
      scout_feature_name: `${
        itnbReason ? "no_quote" : "open_intent_to_not_quote_menu"
      }`,
    });
  };

  const onOtherClicked = (
    reason: Parameters<typeof handleSendIntentToNoQuoteReason>[0]
  ) => {
    dispatch(
      modalsActions.showModal({
        key: MarketplaceModalTypes.INTENT_TO_NO_QUOTE_MODAL,
        data: {
          onSubmit: (reasonDescription: string) =>
            handleSendIntentToNoQuoteReason({ ...reason, reasonDescription })(),
          onSkip: handleSendIntentToNoQuoteReason(reason),
        },
      })
    );
  };

  const handleSendIntentToNoQuoteReason =
    ({
      slug,
      title,
      reasonDescription,
    }: IntentToNoQuoteReasons & { reasonDescription?: string }) =>
    () => {
      const itnbRequest = () => {
        batch(() => {
          dispatch(
            marketplaceNextActions.intentToNoQuoteRequest({
              rfqId: preference.rfq_pk,
              noQuoteReason: slug,
              reasonDescription,
              onSuccess: () =>
                axyaTrack({
                  scout_category: "usage_metric",
                  scout_rfq_pk: preference.rfq_pk,
                  scout_action: "itnb",
                }),
            })
          );

          dispatch(
            marketplaceActions.setIntentToNoBidReasonData({
              rfqPk: preference.rfq_pk,
              slug,
              title,
              description: reasonDescription,
            })
          );
        });
      };

      if (dueDateToBid) {
        dispatch(
          marketplaceActions.setIntentToBidDueDate(
            prefs,
            true,
            tabIndex,
            tabIndex === 2 ? false : true,
            t,
            isRecommended,
            () => {
              itnbRequest();
              axyaTrack({
                scout_category: "usage_metric",
                scout_rfq_pk: preference.rfq_pk,
                scout_action: "revert-itb",
              });
            }
          )
        );
      } else {
        itnbRequest();
      }

      dispatch(
        marketplaceActions.marketplacePreferences(
          token,
          preference,
          isIntentToNoQuote,
          isRecommended
        )
      );
      setAnchorEl(null);

      axyaTrack({
        scout_category: "quotation",
        scout_feature_name: `sent-intent_to_not_quote_reason: ${title}`,
      });
    };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleClear = () => {
    setIsConfirmationDialogOpen(true);
  };

  const confirmationNoHandler = () => {
    setAnchorEl(null);
    setIsConfirmationDialogOpen(false);
  };

  const confirmationYesHandler = () => {
    batch(() => {
      dispatch(
        marketplaceNextActions.intentToNoQuoteRequest({
          rfqId: preference.rfq_pk,
          onSuccess: () => {
            axyaTrack({
              scout_category: "usage_metric",
              scout_rfq_pk: preference.rfq_pk,
              scout_action: "revert-itnb",
            });
          },
        })
      );
      dispatch(
        marketplaceActions.setIntentToNoBidReasonData({
          rfqPk: preference.rfq_pk,
          slug: null,
          title: null,
        })
      );
    });
    setIsConfirmationDialogOpen(false);
  };
  return (
    <>
      <IntentToBidMenuPopup
        label={`${t("quotation:notIntentToQuote")} ${getITNBReasonLabel({
          slug: itnbReason,
          title: itnbReasonTitle,
          description: itnbReasonDescription,
        })}`}
        icon={<ClearIcon />}
        tooltipTitle={
          itnbReason
            ? itnbReasonDescription || itnbReasonTitle || ""
            : t("quotation:noIntentToQuoteTooltip")
        }
        deleteTooltipTitle={t("quotation:putBackInTheMarket")}
        paperProps={{
          style: PaperProps,
        }}
        status={dueDateToBid ? undefined : "error"}
        open={open}
        anchorEl={anchorEl}
        handleShowPopupMenu={handleShowIntentToNoQuoteMenu}
        onClose={handleClose}
        onDelete={itnbReason ? handleClear : undefined}
        disabled={disabled}
        iconOnly={iconOnly}
      >
        {intendToNoQuoteReasons?.map((reason: IntentToNoQuoteReasons) => (
          <MenuItem
            key={reason.slug}
            className={classes.menuItem}
            onClick={
              reason.slug === OTHER_REASON_SLUG
                ? () => onOtherClicked(reason)
                : handleSendIntentToNoQuoteReason(reason)
            }
          >
            {reason.title}
          </MenuItem>
        ))}
      </IntentToBidMenuPopup>
      <ConfirmationDialog
        title={t("workspace:confirmation")}
        content={t("quotation:cancelIntentToNoQuoteConfirmation")}
        isOpen={isConfirmationDialogOpen}
        handleClose={confirmationNoHandler}
        dialogActions={
          <div>
            <Button autoFocus onClick={confirmationNoHandler} color="primary">
              {t("workspace:cancel")}
            </Button>
            <Button onClick={confirmationYesHandler} color="primary">
              {t("RFQ:yes")}
            </Button>
          </div>
        }
      />
      <IntentToNoQuoteReasonModal />
    </>
  );
};
