import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import AddIcon from "@mui/icons-material/Add";
import { SubtierReadinessFormTable } from "../../../table/subtier-readiness-form-table/subtier-readiness-form-table";
import { FormFieldErrorTypography } from "../../../form-field-error-typography/form-field-error-typography";
import {
  FieldMandatoryText,
  SubtierSupplier,
  vendorManagementActions,
} from "@next/modules/vendor-management/redux";
import { selectSpecsOfSubtierSupplier } from "@next/modules/vendor-management/redux/selectors";
import * as S from "./subtier-card.styled";
import { Box } from "@mui/material";

type Props = {
  subtierSupplier: SubtierSupplier;
  handleClickAddSpecsBtn: (subtierSupplier: SubtierSupplier) => void;
  isShowTableErrors: boolean;
};

const text =
  "Thanks to declare if any qualified specifications are missing from the list above, " +
  "click 'Add Spec' and search through the complete list to include them.(CP / Not controlled process / NDT, Test ...).";

export const SubtierCard: React.FC<Props> = ({
  subtierSupplier,
  handleClickAddSpecsBtn,
  isShowTableErrors,
}) => {
  const { companyId } = useParams<{ companyId: string }>();
  const rows = useSelector(selectSpecsOfSubtierSupplier(subtierSupplier.id));
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(
      vendorManagementActions.fetchSpecsOfSubtierSupplierRequest({
        companyId: companyId,
        supplierId: subtierSupplier.id,
      })
    );
  }, []);

  return (
    <S.StyledBox>
      <S.StyledTitle tooltipTitle={null}>{subtierSupplier.title}</S.StyledTitle>

      <SubtierReadinessFormTable subtierSupplierId={subtierSupplier.id} />

      <S.StyledButtonBox>
        <Box>
          <S.StyledButton
            startIcon={<AddIcon fontSize="large" />}
            variant="contained"
            disableElevation
            onClick={() => handleClickAddSpecsBtn(subtierSupplier)}
            fullWidth={false}
          >
            <S.StyledButtonTypography variant="body2">
              {`Add ${subtierSupplier.title} specs`}
            </S.StyledButtonTypography>
          </S.StyledButton>
        </Box>

        <S.StyledTypography variant="body2">{text}</S.StyledTypography>
      </S.StyledButtonBox>

      {isShowTableErrors && rows.length === 0 ? (
        <FormFieldErrorTypography text={FieldMandatoryText} />
      ) : null}
    </S.StyledBox>
  );
};
