import React from "react";
import { Box, Typography } from "@material-ui/core";
import { Theme } from "@mui/material";
import { createStyles, makeStyles } from "@mui/styles";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";
import { useTranslation } from "react-i18next";
import { RFQActivity } from "@next/modules/workspace/redux";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    viewHistoryWrapper: {
      color: "white",
      padding: 10,
      display: "flex",
      flexDirection: "column",
      gap: 12,
    },
    downloadedBy: {
      display: "flex",
      alignItems: "center",
      gap: 4,
    },
    flexItems: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
    },
    textStyle: {
      fontFamily: "Roboto",
      fontWeight: "normal",
      letterSpacing: "0.25px",
      color: theme.palette.chip.contrastText,
    },
    iconStyle: {
      fontSize: theme.spacing(2),
      color: theme.palette.chip.contrastText,
    },
  })
);

type RFQActivityPropsType = {
  isActivityTypeView: boolean;
  activityData: RFQActivity[];
};

const RfqFileViewHistory: React.FC<RFQActivityPropsType> = ({
  isActivityTypeView,
  activityData,
}) => {
  const { t } = useTranslation();
  const classes = useStyles();

  return (
    <div className={classes.viewHistoryWrapper}>
      <Box>
        {isActivityTypeView ? (
          <Box className={classes.flexItems}>
            <Box className={classes.downloadedBy}>
              <VisibilityOutlinedIcon className={classes.iconStyle} />
              <Typography variant="body2" className={classes.textStyle}>
                {t("workspaceNext:buyerInformations:viewedBy")}
              </Typography>
            </Box>
            <Typography variant="body2" className={classes.textStyle}>
              {t("workspaceNext:buyerInformations:lastViewed")}
            </Typography>
          </Box>
        ) : (
          <Box className={classes.flexItems}>
            <Box className={classes.downloadedBy}>
              <ArrowDownwardIcon className={classes.iconStyle} />
              <Typography variant="body2" className={classes.textStyle}>
                {t("workspaceNext:buyerInformations:downloadedBy")}
              </Typography>
            </Box>
            <Typography variant="body2" className={classes.textStyle}>
              {t("workspaceNext:buyerInformations:lastDownloaded")}
            </Typography>
          </Box>
        )}
      </Box>
      {activityData?.map((activity) => (
        <Box key={activity.name} className={classes.flexItems}>
          <Typography
            variant="body2"
            className={classes.textStyle}
            style={{ color: "white" }}
          >
            {activity.name}
          </Typography>
          <Typography variant="body2" className={classes.textStyle}>
            {activity.viewed_at}
          </Typography>
        </Box>
      ))}
    </div>
  );
};
export default RfqFileViewHistory;
