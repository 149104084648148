import React from "react";
import { useTranslation } from "react-i18next";
import {
  AppBar,
  Avatar,
  Box,
  Checkbox,
  Chip,
  FormControl,
  FormHelperText,
  ListItemAvatar,
  ListItemIcon,
  ListItemText,
  MenuItem,
  Select,
  SelectChangeEvent,
  Typography,
} from "@mui/material";

import { fieldToTextField } from "formik-material-ui";
import { FieldProps, useField } from "formik";
import { TextFieldProps } from "@mui/material";

import grad4Logo from "assets/img/grad4-network-logo.png";

import {
  MenuProps,
  getOptionsName,
  getSelectedOptionsName,
  useStyles,
} from "./select-suppliers.utils";
import { AxyaSuppliers, Option, SelectAllOptionProps } from "./types";
import { isEmpty } from "lodash";
import { Skeleton } from "@mui/material";
import SupplierStatus from "@next/modules/workspace/components/supplier-status/supplier-status";

export const SelectSuppliers: React.FC<
  SelectAllOptionProps & FieldProps & TextFieldProps
> = (props) => {
  const classes = useStyles();
  const { t } = useTranslation();

  const {
    options,
    placeholder,
    hideEntireNetworkOption,
    isSmall,
    form: { setFieldValue },
    loading,
    showStatus,
    disabled,
  } = props;

  const { ...field } = fieldToTextField(props as any);
  const { name, value } = field;
  const [_, meta] = useField(name as string);
  const errorText = meta?.touched && meta?.error ? meta.error : "";

  const hasNoFavoriteSuppliers = isEmpty(options);

  const selectedValues = value
    ? (value as string[])?.filter((item: any) => item)
    : [];

  const isAllSelected =
    selectedValues?.filter(
      (item) => item !== t(`recipients:${AxyaSuppliers.ENTIRE_NETWORK}`)
    )?.length === options?.length;

  const selectedOptionsName = getSelectedOptionsName(selectedValues);

  const isEntireAxyaNetworkSelected = selectedValues?.includes(
    t(`recipients:${AxyaSuppliers.ENTIRE_NETWORK}`)
  );
  const handleChange = (event: SelectChangeEvent<any>) => {
    event.stopPropagation();
    const targetValue = event?.target?.value || [];

    if (!targetValue?.includes(AxyaSuppliers.FAVORITE_SUPPLIERS)) {
      setFieldValue(name as string, targetValue);
    }
  };

  const onClickEntireAxyaNetwork = () => {
    setFieldValue(
      name as string,
      isEntireAxyaNetworkSelected
        ? selectedValues?.filter(
            (item) => item !== t(`recipients:${AxyaSuppliers.ENTIRE_NETWORK}`)
          )
        : [...selectedValues, t(`recipients:${AxyaSuppliers.ENTIRE_NETWORK}`)]
    );
  };

  const onSelectAll = () => {
    const newOptions = [];
    if (isEntireAxyaNetworkSelected)
      newOptions.push(t(`recipients:${AxyaSuppliers.ENTIRE_NETWORK}`));
    if (!isAllSelected) newOptions.push(...getOptionsName(options));

    setFieldValue(name as string, newOptions);
  };

  return loading ? (
    <Skeleton variant="rectangular" width="100%" height="40px" />
  ) : (
    <FormControl className={classes.formControl}>
      <Select
        error={!!errorText}
        className={isSmall ? classes.select : ""}
        labelId="mutiple-select-label"
        variant="outlined"
        displayEmpty
        multiple
        value={selectedValues}
        onChange={handleChange}
        renderValue={(selected: any) => (
          <Box className={classes.box}>
            {selected?.length === 0 ? (
              <Typography variant="body1" className={classes.placeholder}>
                {placeholder}
              </Typography>
            ) : (
              <>
                {selected?.map((select: string) => (
                  <Chip
                    style={isSmall ? { height: 24 } : undefined}
                    key={select}
                    label={select}
                    disabled={disabled}
                  />
                ))}
              </>
            )}
          </Box>
        )}
        disabled={disabled}
        MenuProps={MenuProps}
      >
        {!hasNoFavoriteSuppliers ? (
          <Box className={`${classes.titlePosition} ${classes.titleContainer}`}>
            <Typography variant="body1" className={classes.titleFontWeight}>
              {t("recipients:myFavoriteSupplierWithCount", {
                count: options.length,
              })}
            </Typography>
          </Box>
        ) : null}

        {!hasNoFavoriteSuppliers ? (
          <MenuItem
            value={AxyaSuppliers.FAVORITE_SUPPLIERS}
            classes={{
              root: isAllSelected ? classes.selectedAll : "",
            }}
            onClick={onSelectAll}
          >
            <ListItemIcon>
              <Checkbox
                classes={{
                  root: classes.checkbox,
                  indeterminate: classes.indeterminate,
                }}
                checked={isAllSelected}
              />
            </ListItemIcon>
            <ListItemText
              classes={{ primary: classes.selectAllText }}
              primary={t("recipients:selectAll")}
            />
          </MenuItem>
        ) : null}

        {options?.map((option: Option) => {
          return (
            <MenuItem key={option?.name} value={option?.name}>
              <ListItemIcon>
                <Checkbox
                  classes={{ root: classes.checkbox }}
                  checked={selectedOptionsName[option?.name] === option?.name}
                />
              </ListItemIcon>
              <ListItemAvatar>
                <Avatar alt={`Avatar ${option?.name}`} src={option?.picture} />
              </ListItemAvatar>
              {showStatus && option?.status_info ? (
                <Box>
                  <Typography variant="body2">{option?.name}</Typography>
                  <SupplierStatus statusInfo={option.status_info} />
                </Box>
              ) : (
                <ListItemText primary={option?.name} />
              )}
            </MenuItem>
          );
        })}

        {hideEntireNetworkOption ? null : (
          <>
            <AppBar elevation={0} className={classes.appBarContainer}>
              <Box
                className={`${classes.entireNetworkTitle} ${classes.titleContainer}`}
              >
                <Typography variant="body1" className={classes.titleFontWeight}>
                  {t("recipients:entireNetworkTitle")}
                </Typography>
              </Box>
              <MenuItem
                value={t(`recipients:${AxyaSuppliers.ENTIRE_NETWORK}`)}
                classes={{
                  root: isAllSelected ? "" : classes.selectedAll,
                }}
                onClick={onClickEntireAxyaNetwork}
              >
                <ListItemIcon>
                  <Checkbox
                    classes={{
                      root: classes.checkbox,
                    }}
                    checked={isEntireAxyaNetworkSelected}
                  />
                </ListItemIcon>
                <ListItemAvatar>
                  <Avatar alt={`grad4Logo`} src={grad4Logo} />
                </ListItemAvatar>
                <ListItemText
                  className={classes.listItemText}
                  primary={t("recipients:entireNetwork")}
                />
              </MenuItem>
            </AppBar>
          </>
        )}

        <Box pb={1} />
      </Select>

      {!!errorText && <FormHelperText error>{errorText}</FormHelperText>}
    </FormControl>
  );
};
