import {
  Link,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Popover,
  Theme,
} from "@mui/material";
import React from "react";
import PhoneForwardedOutlinedIcon from "@mui/icons-material/PhoneForwardedOutlined";
import MailOutlinedIcon from "@mui/icons-material/MailOutlined";
import LanguageIcon from "@mui/icons-material/Language";
import { useTranslation } from "react-i18next";
import { getSelectedLanguage } from "@next/utils/browserUtils";
import { createStyles, makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    popoverList: {
      padding: "12px",
    },
    popoverListItem: {
      paddingBottom: "0px",
    },
    popoverListItemIcon: {
      color: theme.palette.primary.main,
    },
  })
);
interface Props {
  anchorEl: HTMLElement | null;
  onClose: () => void;
  onRight?: boolean;
}

export const ContactSupportPopover: React.FC<Props> = ({
  anchorEl,
  onClose,
  onRight,
}) => {
  const language = getSelectedLanguage();
  const classes = useStyles();
  const { t } = useTranslation();

  const open = Boolean(anchorEl);
  const id = open ? "help-toggle-popover" : undefined;

  return (
    <Popover
      id={id}
      open={open}
      anchorEl={anchorEl}
      onClose={onClose}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: onRight ? "right" : "left",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: onRight ? "left" : "right",
      }}
    >
      <div>
        <List disablePadding={true} className={classes.popoverList}>
          <ListItem classes={{ root: classes.popoverListItem }}>
            <ListItemText primary={t("appTour:helpTitle")} />
          </ListItem>

          <ListItem classes={{ root: classes.popoverListItem }}>
            <ListItemIcon>
              <PhoneForwardedOutlinedIcon
                className={classes.popoverListItemIcon}
              />
            </ListItemIcon>
            <ListItemText
              primary={
                <Link
                  href={`tel:+1 438 600-8933`}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  +1 438 600-8933
                </Link>
              }
            />
          </ListItem>

          <ListItem classes={{ root: classes.popoverListItem }}>
            <ListItemIcon>
              <MailOutlinedIcon className={classes.popoverListItemIcon} />
            </ListItemIcon>
            <ListItemText
              primary={
                <Link
                  href={`mailto:question@axya.co`}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  question@axya.co
                </Link>
              }
            />
          </ListItem>

          <ListItem classes={{ root: classes.popoverListItem }}>
            <ListItemIcon>
              <LanguageIcon className={classes.popoverListItemIcon} />
            </ListItemIcon>
            <ListItemText
              primary={
                <Link
                  href={`https://axya.co/${language}/company/contact-axya`}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  axya.co
                </Link>
              }
            />
          </ListItem>
        </List>
      </div>
    </Popover>
  );
};
