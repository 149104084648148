import { useQuery } from "@next/hooks/useQuery";
import useLocalStorage from "hooks/useLocalStorage";
import { useEffect } from "react";
import {
  checkIsEmptyOrSetDefault,
  checkIsNaNOrSetDefault,
} from "@next/utils/numberUtils";
import { TAB_QUERY_PARAM } from "@next/utils/browserUtils";

type TableSavedQueryPaginationData = {
  p?: number | typeof NaN;
  lsr?: string | typeof NaN;
  tab?: number | typeof NaN;
};

type TableSavedQueryPagination = {
  savedTableQueryPagination: TableSavedQueryPaginationData;
  onPageChangeTable: (newPage: number) => void;
  setSavedTableQueryPagination: (
    savedTableQueryPagination: TableSavedQueryPaginationData
  ) => void;
};

const LSR_DEFAULT = undefined;
const P_DEFAULT = 1;
const TAB_DEFAULT = 0;

const initialState = { p: P_DEFAULT, lsr: LSR_DEFAULT, tab: TAB_DEFAULT };

export const useTableSavedQueryPagination = (
  tableKey: string
): TableSavedQueryPagination => {
  const [query, setQuery] = useQuery("replace");

  const [savedTableQueryPagination, setSavedTableQueryPagination] =
    useLocalStorage<TableSavedQueryPaginationData>(tableKey, initialState);

  useEffect(() => {
    if (savedTableQueryPagination !== initialState) {
      // If there is data in localstorage, change the url.
      setQuery({ ...savedTableQueryPagination });
    } else {
      const parsedTab = checkIsNaNOrSetDefault(
        query?.[TAB_QUERY_PARAM],
        TAB_DEFAULT
      );
      const parsedPage = checkIsNaNOrSetDefault(query?.p, P_DEFAULT);
      const parsedLastSelectedRow = checkIsEmptyOrSetDefault(
        query?.lsr,
        LSR_DEFAULT
      );
      // If localstorage is empty, write the data in the url to localstorage
      setSavedTableQueryPagination({
        p: parsedPage,
        lsr: parsedLastSelectedRow,
        tab: parsedTab,
      });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [savedTableQueryPagination, query]);

  const onPageChangeTable = (newPage: number) => {
    setSavedTableQueryPagination({
      ...savedTableQueryPagination,
      p: newPage + 1,
    });
  };

  return {
    savedTableQueryPagination,
    setSavedTableQueryPagination,
    onPageChangeTable,
  };
};
