import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { getModal, modalsActions } from "../redux/modalsSlices";
import { SharedModalTypes } from "./types";
import Modal from "@next/components/custom-modal";
import { useTranslation } from "react-i18next";
import { AddCollaboratorForm } from "./components/add-collaborator/add-collaborator.form";
import { GenericUser } from "@next/modules/profile/redux";
import { createStyles, makeStyles } from "@mui/styles";

const useStyles = makeStyles(() =>
  createStyles({
    paper: {
      width: 800,
    },
    dialogContent: {
      overflowX: "hidden",
    },
  })
);

export type SharedAddCollaboratorModalProps = {
  collaborators?: GenericUser[];
  rfqPk: number;
  poId?: number;
  createdBy?: GenericUser;
  refetchData: boolean;
  draftId?: number;
};

export const SharedAddCollaboratorModal = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const modal = useSelector(getModal(SharedModalTypes.ADD_COLLABORATOR_MODAL));
  const data = modal?.data as SharedAddCollaboratorModalProps;
  const classes = useStyles();

  if (!modal || !data) return null;

  return (
    <Modal
      handleModalClose={() =>
        dispatch(
          modalsActions.closeModal(SharedModalTypes.ADD_COLLABORATOR_MODAL)
        )
      }
      modalState={{
        modalHeader: t("project:table:row:addCollaborator"),
        modalBody: <AddCollaboratorForm {...data} />,
        dialogContentClassName: classes.dialogContent,
      }}
      dialogProps={{ fullWidth: true, classes: { paper: classes.paper } }}
      isModalOpen={true}
    />
  );
};
