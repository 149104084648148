import { Box, CircularProgress, Typography } from "@mui/material";
import { AppTheme } from "layout/AppTheme";
import React from "react";
import LoopIcon from "@mui/icons-material/Loop";
import { formatDateAndTime } from "helpers/formatDate";
import { useTranslation } from "react-i18next";

type Props = {
  updated_at: string;
  loading?: boolean;
  showDate?: boolean;
};

export const AutoSavedStatus: React.FC<Props> = ({
  updated_at,
  loading,
  showDate = true,
}) => {
  const { t } = useTranslation();

  return (
    <>
      <Box mr={1}>
        {loading ? (
          <CircularProgress size={12} />
        ) : (
          <LoopIcon style={{ color: AppTheme.palette.text.secondary }} />
        )}
      </Box>
      <Box mr={1}>
        <Typography color="textSecondary" variant="body2">
          {t("workspaceNext:rfqDrafts:autoSaved")}
          {showDate ? `, ${formatDateAndTime(updated_at)}` : ""}
        </Typography>
      </Box>
    </>
  );
};
