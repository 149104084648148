import { Box, Grid } from "@mui/material";
import {
  GridCellParams,
  GridRowParams,
  MuiEvent,
} from "@mui/x-data-grid-pro-v5";
import {
  INTERNAL_NOTES_MODULE_TYPE,
  SHORT_PAGINATION_PAGE_SIZE,
} from "@next/constants";
import useSavedGridState from "@next/hooks/useSavedGridState-v5";
import { useTableSavedQueryPagination } from "@next/hooks/useTableSavedQueryPagination";
import { getRowIdToPk } from "@next/utils/dataGridUtils-v5";
import { history } from "helpers/history";
import useLocalStorage from "hooks/useLocalStorage";
import React, { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { frontendUrl } from "urls";
import { Order, OrderSupplier } from "../../redux";
import {
  selectOrdersCounts,
  selectOrdersStatusCounts,
} from "../../redux/selectors";
import { OrderDetailMenu } from "../order-detail/order-detail.menu";
import OrdersSuppliersAutocompleteDropdown from "../orders-suppliers-autocomplete-dropdown/orders-suppliers-autocomplete-dropdown";
import { useOrdersTableData } from "./orders-table.hooks";
import { OrdersTableField } from "./orders-table.types";
import {
  SelectOrderStateCards,
  initialCardStateOrder,
} from "./select-order-state-cards/select-order-state-cards";
import { CardOrderState } from "./select-order-state-cards/select-order-state.cards.types";
import {
  ORDERS_GRID_COLUMN_STATE,
  ORDERS_GRID_TABLE_NAME,
} from "@next/constants/data-grid-state-constants";
import useTableFilterAndSort from "@next/hooks/useTableFilterAndSort-v5";
import { generateFilterQuery, getSortQueryString } from "./order-table.helpers";
import {
  CustomDateColumnType,
  CustomNumberColumnTypeV2,
  CustomStringColumnType,
} from "@next/constants/data-grid-pro-constants-v5";
import { DataGridProV5 } from "@next/components/data-grid-pro-v5";
import { SharedInternalNotesModal } from "@next/modals/shared-internal-notes-modal";
import {
  GlobalSearchResult,
  NoteType,
  workspaceNextActions,
} from "@next/modules/workspace/redux";
import OrdersNotesAutocompleteDropdown from "../order-notes-autocomplete-dropdown/order-notes-autocomplete-dropdown";
import { selectInternalNotesList } from "@next/modules/workspace/redux/selectors";
import { LOCAL_STOTAGE_RECENT_SEARCHES_KEY } from "@next/modals/components/global-search/global-search";
import i18n from "assets/configi18n/i18n";

type Props = {
  isCompletedOrders: boolean;
  disableFiltering?: boolean;
};

export const ORDERS_TABLE_NAVIGATION_KEY = "ORDERS_TABLE_NAVIGATION";

export const OrdersTable: React.FC<Props> = ({
  isCompletedOrders,
  disableFiltering,
}) => {
  const language = i18n.language;
  const {
    handleSortChange,
    handleFilterChange,
    sortQueryString,
    sortModel,
    filterModel,
    filterQueryString,
  } = useTableFilterAndSort(getSortQueryString, generateFilterQuery);

  const { apiRef } = useSavedGridState(
    ORDERS_GRID_COLUMN_STATE,
    ORDERS_GRID_TABLE_NAME
  );

  const [selectedSuppliers, setSelectedSuppliers] = useState<OrderSupplier[]>(
    []
  );
  const [selectedNote, setSelectedNote] = useState<NoteType[]>([]);

  const {
    savedTableQueryPagination,
    setSavedTableQueryPagination,
    onPageChangeTable,
  } = useTableSavedQueryPagination(ORDERS_TABLE_NAVIGATION_KEY);
  const ordersStatusCounts = useSelector(selectOrdersStatusCounts);
  const internalNotes = useSelector(selectInternalNotesList);

  const [selectedStateFilter, setSelectedStateFilter] =
    useLocalStorage<CardOrderState>(
      "SAVED_SELECTED_STATE_FILTER",
      initialCardStateOrder(ordersStatusCounts?.total_count)
    );

  const [recentSearches, setRecentSearches] = useLocalStorage<
    GlobalSearchResult[]
  >(LOCAL_STOTAGE_RECENT_SEARCHES_KEY, []);

  const { gridData, ordersSuppliers } = useOrdersTableData({
    isCompletedOrders,
    currentPage: savedTableQueryPagination?.p || 1,
    selectedStateFilter,
    selectedSuppliers,
    selectedNote,
    sortQueryString,
    filterQueryString,
    moduleType: INTERNAL_NOTES_MODULE_TYPE.PO,
  });
  const dispatch = useDispatch();
  const ordersCounts = useSelector(selectOrdersCounts);
  const [menuAnchorEl, setMenuAnchorEl] = useState<null | Element>(null);
  const [menuActiveData, setMenuActiveData] = useState<Order | null>(null);

  useEffect(() => {
    // Reset page to 1 when filter changes
    setSavedTableQueryPagination({
      ...savedTableQueryPagination,
      p: 1,
    });
  }, [selectedStateFilter?.slug]);

  useEffect(() => {
    //fetch notes when table loads
    dispatch(
      workspaceNextActions.getInternalNotesRequest(
        INTERNAL_NOTES_MODULE_TYPE.PO
      )
    );
  }, []);

  const onCellClick = (
    params: GridCellParams,
    event: MuiEvent<React.MouseEvent<Element, MouseEvent>>
  ) => {
    switch (params?.field) {
      case OrdersTableField.EDIT_BUTTON:
        const buttonElement = event.currentTarget.querySelector("button");
        setMenuAnchorEl(buttonElement);
        setMenuActiveData(params.row as Order);
        break;
      case OrdersTableField.SHIPPING_NOTE:
        return;

      default:
        setSavedTableQueryPagination({
          ...savedTableQueryPagination,
          lsr: params?.row?.pk,
        });
        setRecentSearches([
          {
            id: params?.row?.pk,
            result_type: "PURCHASE_ORDER",
            name: params?.row?.name,
          },
          ...recentSearches
            .filter((item) => item.id !== params?.row?.pk)
            .slice(0, 5),
        ]);

        history.push(`${frontendUrl.orders}/${params.row.pk}`);
        break;
    }
  };

  const getRowClassName = (params: GridRowParams) =>
    `c-cursor-pointer c-highlighted-row--${
      params.id === savedTableQueryPagination?.lsr
    }`;

  // To fix flickeing when resizing column width
  const renderDataGrid = useMemo(
    () => (
      <DataGridProV5
        apiRef={apiRef}
        autoHeight
        autoPageSize
        pagination
        paginationMode="server"
        filterModel={filterModel}
        onFilterModelChange={handleFilterChange}
        sortingMode="server"
        filterMode="server"
        pageSize={SHORT_PAGINATION_PAGE_SIZE}
        rowCount={ordersCounts?.count}
        onSortModelChange={handleSortChange}
        sortModel={sortModel}
        onPageChange={onPageChangeTable}
        getRowId={getRowIdToPk}
        disableSelectionOnClick
        rowHeight={60}
        loading={false}
        onCellClick={onCellClick}
        rows={gridData?.rows}
        columns={gridData?.columns}
        getRowClassName={getRowClassName}
        columnTypes={{
          string: CustomStringColumnType,
          date: CustomDateColumnType,
          number: CustomNumberColumnTypeV2,
        }}
      />
    ),
    [gridData?.rows, gridData?.columns, ordersCounts?.count, filterModel]
  );

  return (
    <div>
      <Box mt="16px" mb="16px">
        <Grid container spacing={1.5}>
          {!disableFiltering ? (
            <SelectOrderStateCards
              selectedFilterState={selectedStateFilter}
              setSelectedFilterState={setSelectedStateFilter}
            />
          ) : null}

          <Grid item>
            <Box pr={language == "en" ? "22px" : "110px"}>
              <OrdersSuppliersAutocompleteDropdown
                suppliers={ordersSuppliers || []}
                selectedSuppliers={selectedSuppliers}
                setSelectedSuppliers={setSelectedSuppliers}
                language={language}
              />
            </Box>
          </Grid>
          <Grid item>
            <Box pr="12px">
              <OrdersNotesAutocompleteDropdown
                internalNotes={internalNotes || []}
                selectedNote={selectedNote}
                setSelectedNote={setSelectedNote}
                language={language}
              />
            </Box>
          </Grid>
        </Grid>
      </Box>
      {renderDataGrid}
      {/* This menu appears only 
          when OrdersTableField.EDIT_BUTTON is in columns. 
      */}
      <OrderDetailMenu
        anchorEl={menuAnchorEl}
        setAnchorEl={setMenuAnchorEl}
        menuActiveData={menuActiveData}
        refetchOrdersAfterAction={true}
      />
      <SharedInternalNotesModal moduleType={INTERNAL_NOTES_MODULE_TYPE.PO} />
    </div>
  );
};
