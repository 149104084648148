// General Imports
import React from "react";

// MaterialUI specific Import
import { Button, Grid } from "@mui/material";

// Library Components
import { useTranslation } from "react-i18next";
import { FormHeader } from "../utils/form-header/form-header";
import { FormikProps } from "formik";
import { TResetPasswordFormState } from "./reset-password.definitions";
import { GenericPasswordField } from "components/generic-password-field/generic-password-field.component";

/*
 *   ResetPasswordForm:
 *   The form controlled by Formik and validated by Yup to reset the password
 *   for a given user
 */
export const ResetPasswordFormBase: React.FC<
  FormikProps<TResetPasswordFormState>
> = (props) => {
  // Deconstruct the values from formik
  const { values, errors, touched, handleSubmit, handleChange } = props;
  const { t } = useTranslation();
  const { new_pass1 } = values;
  return (
    <form onSubmit={handleSubmit} style={{ width: "100%" }}>
      <Grid
        container
        direction="column"
        justifyContent="center"
        alignItems="center"
      >
        <Grid
          container
          item
          xs={8}
          style={{
            margin: "0.2em",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <FormHeader
            title={t("auth:resetPasswordForm:resetPasswordTitle")}
            body={t("auth:resetPasswordForm:resetPasswordBody")}
          />
        </Grid>
        <Grid container item xs={8} style={{ marginTop: "2rem" }}>
          <GenericPasswordField
            name="new_pass1"
            value={new_pass1}
            label={t("auth:resetPasswordForm:password1Label")}
            placeholder={t("auth:login:password")}
            helperText={touched.new_pass1 ? errors.new_pass1 : ""}
            error={touched.new_pass1 && Boolean(errors.new_pass1)}
            handleChange={handleChange}
          />
        </Grid>
        <Grid container item xs={8} style={{ marginTop: "1rem" }}>
          <Button
            color="primary"
            variant="contained"
            onClick={() => handleSubmit()}
            fullWidth={true}
          >
            {t("auth:actionsMenu:resetPasswordButton")}
          </Button>
        </Grid>
      </Grid>
    </form>
  );
};
