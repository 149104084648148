// URL

import { backendUrl } from "urls";
// Helper
import { handleResponse } from "helpers/responseHandling";

import {
  DelayUnitType,
  getLeadTimeDelayUnit,
} from "@next/components/lead-time/lead-time.definitions";
import { axiosApi } from "@next/api";

// Services Exported
export const quotationApi = {
  getQuotationDetails,
  editQuotation,
  setLeadTimeOptions,
};

function getQuotationDetails(token, quotationId, isEditQuotationDialogOpen) {
  let url = backendUrl.quotation + "/" + quotationId + "?status=all";
  if (isEditQuotationDialogOpen) {
    url += "&include_new_parts=true";
  }
  return axiosApi.get(url).then(handleResponse);
}

/**
 * Backend Query for edit Quotation
 * @param {*} token - Token for User Authentication
 * @returns {Objects} -  Objects representing quotes
 */
function editQuotation(token, quote, quoteId) {
  return axiosApi
    .patch(`${backendUrl.quotation}/${quoteId}`, quote)
    .then(handleResponse);
}

/**
 *
 * @param {number} numberOfDay
 * @param {function} t
 * @returns Array of lead time
 */
function setLeadTimeOptions(numberOfDay) {
  const leadTimeOptions = [
    {
      name: getLeadTimeDelayUnit(numberOfDay, DelayUnitType.DAY),
      delay: numberOfDay,
      delay_unit: DelayUnitType.DAY,
    },
    {
      name: getLeadTimeDelayUnit(numberOfDay, DelayUnitType.WEEK),
      delay: numberOfDay,
      delay_unit: DelayUnitType.WEEK,
    },
    {
      name: getLeadTimeDelayUnit(numberOfDay, DelayUnitType.MOUTH),
      delay: numberOfDay,
      delay_unit: DelayUnitType.MOUTH,
    },
  ];

  return new Promise((resolve, reject) => {
    return setTimeout(() => resolve(leadTimeOptions), 600);
  });
}
