import React, { useEffect, useState } from "react";
import { Box, CircularProgress, Link, Theme } from "@mui/material";
import { FileList } from "@next/modules/project/components/file/file-list/file-list";
import PartViewer from "components/part-dialog/viewer/viewer.component";
import { useDispatch, useSelector } from "react-redux";
import { PartUpload } from "@next/modules/project/redux";
import { modalsActions } from "@next/redux/modalsSlices";
import { selectUploadQuotationFilesAsBuyerLoading } from "@next/modules/workspace/redux/selectors";
import { makeStyles } from "@mui/styles";
import { selectFileUploadProgress } from "@next/modules/workspace/redux/selectors";
import { workspaceNextActions } from "@next/modules/workspace/redux";
import MultipleDNDFileUpload from "@next/components/multiple-dnd-file-upload/multiple-dnd-file-upload";
import { FileData } from "@next/components/attach-file/attach-file-list.types";
import { SharedModalTypes } from "@next/modals/types";
import { CustomFileObject } from "@next/components/multiple-dnd-file-upload/multiple-dnd-file-upload.types";
import { Trans } from "react-i18next";

export const useStyles = makeStyles((theme: Theme) => ({
  mainContainer: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    width: "100%",
    height: "100%",
  },
  content: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    padding: "16px",
    width: "100%",
  },
  fileListContainer: {
    width: "100%",
    height: "6.5rem",
    overflowY: "scroll",
    marginTop: "12px",
  },
  title: { color: theme.palette.text.primary, whiteSpace: "pre-line" },
}));

type Props = {
  quotationFiles: FileData[];
  setFieldValue: (field: string, value: any) => void;
};

export const UploadQuotationFile: React.FC<Props> = ({
  quotationFiles,
  setFieldValue,
}) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const fileUploadProgress = useSelector(selectFileUploadProgress);
  const fileUploadingLoading = useSelector(
    selectUploadQuotationFilesAsBuyerLoading
  );
  const [selectedFile, setSelectedFile] = useState<FileData>(quotationFiles[0]);

  useEffect(() => {
    setSelectedFile(quotationFiles[0]);
  }, [quotationFiles]);

  const onDelete = (file: FileData) => {
    dispatch(
      workspaceNextActions.removeFileUploadProgress({
        filesName: `${file?.file_name}.${file?.file_extension}`,
      })
    );
    setFieldValue(
      "files",
      quotationFiles.filter((f) => f.pk !== file.pk)
    );
  };

  const onDeleteFile = (file: FileData) => {
    dispatch(
      modalsActions.showModal({
        key: SharedModalTypes.ATTACH_FILE_DELETE_CONFIRMATION_MODAL,
        data: () => {
          onDelete && onDelete(file);
        },
      })
    );
  };

  const onAttachmentsChange = (files: CustomFileObject[]) => {
    dispatch(
      workspaceNextActions.uploadQuotationFileAsBuyerRequest({
        files: files.map((file) => file.file),
        onSuccess: (response) => {
          setFieldValue("files", [
            ...response,
            ...quotationFiles,
          ] as FileData[]);
        },
      })
    );
  };

  return (
    <>
      <Box className={classes.mainContainer}>
        <>
          {quotationFiles?.length && selectedFile?.file_url ? (
            <PartViewer
              src={selectedFile?.file_url}
              activeFilePk={selectedFile?.pk}
              disableSnapshot={true}
            />
          ) : fileUploadingLoading ? (
            <CircularProgress />
          ) : (
            <Box sx={{ padding: "16px", width: "100%", height: "100%" }}>
              <MultipleDNDFileUpload
                filesState={[]}
                setFilesState={onAttachmentsChange}
                centerInnnerContent
                enlargeRootContainer
                disableDndMargin
                showSecureInfo={false}
                showUploadList={false}
                compactMode
                customDragAndDropText={
                  <Trans
                    i18nKey="rfq:quotations:quoteAsBuyerModal:dropFileText"
                    components={[
                      <Link style={{ cursor: "pointer" }}>browse</Link>,
                    ]}
                  />
                }
              />
            </Box>
          )}
        </>

        {quotationFiles?.length ? (
          <Box className={classes.content}>
            <MultipleDNDFileUpload
              filesState={[]}
              setFilesState={onAttachmentsChange}
              centerInnnerContent
              disableDndMargin
              showSecureInfo={false}
              showUploadList={false}
              compactMode
              customDragAndDropText={
                <Trans
                  i18nKey="rfq:quotations:quoteAsBuyerModal:dropFileText"
                  components={[
                    <Link style={{ cursor: "pointer" }}>browse</Link>,
                  ]}
                />
              }
            />
            {fileUploadingLoading ? (
              <Box sx={{ padding: "16px" }}>
                <CircularProgress />
              </Box>
            ) : null}

            <Box className={classes.fileListContainer}>
              <FileList
                files={quotationFiles as PartUpload[]}
                filesUploading={fileUploadProgress}
                selectedFile={selectedFile as PartUpload}
                setSelectedFile={setSelectedFile}
                onDelete={onDeleteFile}
              />
            </Box>
          </Box>
        ) : null}
      </Box>
    </>
  );
};
