import React from "react";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogTitle,
  Divider,
  IconButton,
  Typography,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { getModal, modalsActions } from "@next/redux/modalsSlices";
import CloseIcon from "@mui/icons-material/Close";
import { SharedModalTypes } from "./types";
import { history } from "helpers/history";
import { frontendUrl } from "urls";

export const SharedProjectIntroductionModal = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const modal = useSelector(
    getModal(SharedModalTypes.PROJECT_INTRODUCTION_MODAL)
  );

  if (!modal) return null;

  const onClose = () => {
    dispatch(
      modalsActions.closeModal(SharedModalTypes.PROJECT_INTRODUCTION_MODAL)
    );
  };

  const onClickGetStarted = () => {
    history.push(frontendUrl.projects);
    dispatch(
      modalsActions.closeModal(SharedModalTypes.PROJECT_INTRODUCTION_MODAL)
    );
  };

  return (
    <Dialog
      open={!!modal}
      onClose={onClose}
      closeAfterTransition
      fullWidth={true}
    >
      <DialogTitle>
        <Box display="flex" alignItems="center">
          <Box flexGrow={1}> {t("project:introductionDialog:title")}</Box>
          <Box pl="5px">
            <IconButton onClick={onClose} size="large">
              <CloseIcon />
            </IconButton>
          </Box>
        </Box>
      </DialogTitle>
      <Divider />

      <Box padding="24px" paddingBottom="16px">
        <Typography>{t("project:introductionDialog:body")}</Typography>
        <Box mt="24px">
          <iframe
            width="552"
            height="320"
            src={`https://www.youtube.com/embed/XK0350aBpHM?modestbranding=1`}
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
            title="Embedded youtube"
          />
        </Box>
      </Box>

      <DialogActions>
        <Box paddingRight="15px" paddingBottom="15px">
          <Button
            color="primary"
            variant="contained"
            onClick={onClickGetStarted}
          >
            {t("project:introductionDialog:getStarted")}
          </Button>
        </Box>
      </DialogActions>
    </Dialog>
  );
};
