export const popupIframe = (iframeProps) => {
  let windowLeft =
    window.top.outerWidth / 2 - iframeProps["windowScreenWidth"] / 2;
  let windowTop =
    window.top.outerHeigh / 2 - iframeProps["windowScreenHeight"] / 2;

  window.open(
    iframeProps["url"],
    "_blank",
    `toolbar=no, location=no, directories=no, status=no, menubar=no, scrollbars=no, resizable=no, copyhistory=no, width=
      ${iframeProps["windowScreenWidth"]} , height=
      ${iframeProps["windowScreenHeight"]}, top=
      ${windowTop}, left=
      ${windowLeft}`
  );
};
