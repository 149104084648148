import React, { useRef } from "react";
import {
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Typography,
} from "@mui/material";

import CloseIcon from "@mui/icons-material/Close";
import { useDispatch, useSelector } from "react-redux";
import { getModal, modalsActions } from "@next/redux/modalsSlices";
import { WorkspaceModalTypes } from "./types";
import { PartForm } from "../components/part-form";
import { confirmDialog } from "@next/modals/shared-confirm-dialog";
import { useTranslation } from "react-i18next";
import { workspaceNextActions } from "../redux";
import { FormikProps } from "formik";
import CustomButton from "@next/components/custom-button";
import { selectUpdatePartLoading } from "../redux/selectors";
import { EditFormValues } from "../components/part-form/part-form.types";
import { normalizePartData } from "../components/part-form/part-form.utils";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles(() => ({
  dialogContent: {
    overflow: "auto",
    maxHeight: "400px",
  },
}));

export const PartModal: React.FC = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const modal = useSelector(getModal(WorkspaceModalTypes.PART_MODAL));
  const updatePartLoading = useSelector(selectUpdatePartLoading);
  const currentPartData = modal?.data;
  const isEditPart = !!currentPartData;
  const formRef = useRef<FormikProps<{}>>(null);

  if (!modal) return null;

  const onClickCloseModal = () => {
    dispatch(modalsActions.closeModal(WorkspaceModalTypes.PART_MODAL));
    dispatch(workspaceNextActions.uploadMyPartsAttachmentsReset());
  };

  const onClickDeleteItem = () => {
    if (currentPartData) {
      confirmDialog(
        t("workspace:confirmation"),
        t("workspaceNext:myParts:modals:areYouSureDeleteThisPart"),
        () => {
          dispatch(workspaceNextActions.deletePartRequest(currentPartData?.pk));
          onClickCloseModal();
        },
        t("common:delete")
      );
    }
  };

  const onSubmit = (values: EditFormValues) => {
    const partData = normalizePartData(values);

    dispatch(
      isEditPart
        ? workspaceNextActions.updatePartRequest({
            pk: currentPartData?.pk,
            part: partData,
          })
        : workspaceNextActions.createPartRequest({
            part: partData,
          })
    );
  };

  return (
    <Dialog
      open={!!modal}
      onClose={onClickCloseModal}
      closeAfterTransition
      fullWidth={true}
    >
      <DialogTitle>
        <Box display="flex" alignItems="center">
          <Box flexGrow={1}>
            <Typography variant="h6">
              {t("workspaceNext:myParts:modals:partDetails")}
            </Typography>
          </Box>
          <Box>
            <IconButton onClick={onClickCloseModal} size="large">
              <CloseIcon />
            </IconButton>
          </Box>
        </Box>
      </DialogTitle>

      <DialogContent dividers className={classes.dialogContent}>
        <PartForm
          initialValues={currentPartData}
          ref={formRef}
          onSubmit={onSubmit}
        />
      </DialogContent>

      <Box
        display={"flex"}
        justifyContent={isEditPart ? "space-between" : "flex-end"}
        p="16px"
      >
        {isEditPart ? (
          <Button onClick={onClickDeleteItem}>
            {t("workspaceNext:myParts:buttons:deletePart")}
          </Button>
        ) : null}

        <CustomButton
          variant="contained"
          color="primary"
          type="submit"
          onClick={() => formRef?.current?.submitForm()}
          loading={updatePartLoading}
        >
          {t("workspaceNext:myParts:buttons:saveAndClose")}
        </CustomButton>
      </Box>
    </Dialog>
  );
};
