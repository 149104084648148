import PageLoader from "@next/components/page-loader/page-loader";
import PortalPageLayout from "@next/components/portal-page-layout/portal-page-layout";
import { useQuery } from "@next/hooks/useQuery";
import {
  usePublicPortalData,
  usePublicQuestionAnswerData,
} from "@next/modules/workspace/pages/public-question-answer/public-question-answer.hooks";
import React, { useMemo } from "react";
import { useParams } from "react-router-dom";
import SideBarComponent from "../components/side-bar-componenet/side-bar-component";
import TopicTitle from "../components/topic-title/topic-title";
import { GenericUser } from "@next/modules/profile/redux";
import { Box } from "@mui/material";
import { TopicPortalSendQuotationModal } from "@next/modules/workspace/modals/topic-portal-send-quotation-modal";
import { createStyles, makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) =>
  createStyles({
    accordionRoot: {
      width: "100%",
      borderBottom: `1px solid ${theme.palette.divider}`,
      boxSizing: "content-box",
      margin: theme.spacing(2, -2),
      padding: theme.spacing(0, 2),
      marginBottom: 0,
      paddingBottom: theme.spacing(2),
    },
  })
);

const SupplierPortalPage: React.FC = () => {
  const params = useParams<{ token?: string }>();
  const [query] = useQuery();
  const classes = useStyles();

  const rfqToken = query.rfq_token as string;
  const { portalDataLoading, portalData } = usePublicPortalData(rfqToken);

  const publicToken = params?.token;
  const { question } = usePublicQuestionAnswerData(publicToken);

  const collaborators = useMemo(() => {
    const res = [];
    if (portalData?.created_by) {
      res.push(portalData?.created_by);
    }
    if (question?.rfq?.collaborators) {
      res.push(...question?.rfq?.collaborators);
    }
    return res;
  }, [question?.rfq?.collaborators, portalData?.created_by]);

  if (portalDataLoading) {
    return <PageLoader />;
  }

  return (
    <>
      <PortalPageLayout
        SideBarComponent={<SideBarComponent portalData={portalData} />}
        TitleComponent={
          <TopicTitle company={portalData?.company} height={40} />
        }
        AccordionTitleComponent={
          <Box className={classes.accordionRoot}>
            <TopicTitle
              users={collaborators as GenericUser[]}
              company={portalData?.company}
              height={44}
            />
          </Box>
        }
        question={question}
        qaToken={publicToken || ""}
      />
      <TopicPortalSendQuotationModal />
    </>
  );
};

export default SupplierPortalPage;
