import React from "react";
import { Box, Typography } from "@mui/material";
import {
  GridColDef,
  GridColumns,
  GridRenderCellParams,
  GridValueGetterParams,
  useGridApiContext,
} from "@mui/x-data-grid-pro-v5";
import { t } from "assets/configi18n/i18n";
import { ProjectsField } from "./types";
import { isNewProjectRow } from "./projects-table.utils";
import { Link as RouterLink } from "react-router-dom";
import { CustomTypography } from "@next/components/custom-typography";
import { PropsWithChildren } from "react";
import TextCell from "@next/components/table-cells/text-cell-v5";
import IconButtonCell from "@next/components/table-cells/icon-button-cell-v5";

const RedirectProjectLink: React.FC<
  PropsWithChildren<{
    code: string;
  }>
> = ({ children, code }) => {
  return (
    <RouterLink
      to={`/projects/${code}`}
      className={"c-projects-table__customLink"}
    >
      {children}
    </RouterLink>
  );
};

const DisplayNameCell: React.FC<
  GridRenderCellParams & {
    lastSelectedRow: string | number | undefined;
  }
> = ({ value, id, field, row, lastSelectedRow }) => {
  const apiRef = useGridApiContext();
  if (isNewProjectRow(id, field) && apiRef) {
    apiRef.current.setCellMode(id, "display_name", "edit");
  }

  const showLastOpened = lastSelectedRow === id;

  return (
    <Box display="flex" alignItems="center" width="100%">
      <CustomTypography
        variant="caption"
        tooltipTitle={(value || "") as string}
        maxWidth={showLastOpened ? "calc(100% - 76px)" : "100%"}
      >
        <RedirectProjectLink code={row?.code}>{value}</RedirectProjectLink>
      </CustomTypography>

      {showLastOpened ? (
        <Box ml="10px">
          <Typography variant="caption" color="textSecondary">
            {t("project:table:row:lastOpened")}
          </Typography>
        </Box>
      ) : null}
    </Box>
  );
};

const CountCellWithTotalParts: React.FC<GridRenderCellParams> = ({
  value,
  row,
}) => {
  return (
    <Typography variant="caption" style={{ display: "flex" }}>
      {value || 0}
      <Typography variant="caption" color="textSecondary">
        /{row?.total_parts_count || 0}
      </Typography>
    </Typography>
  );
};

const defaultColumnConfig: Partial<GridColDef> = {
  disableColumnMenu: true,
  sortable: false,
  hideSortIcons: true,
};

export const getProjectsColumns = (
  lastSelectedRow?: string | number | undefined
) => {
  const _columns: GridColumns = [
    {
      ...defaultColumnConfig,
      field: ProjectsField.DISPLAY_NAME,
      flex: 0.35,
      headerName: t("project:table:header:name"),
      renderCell: (params) => (
        <DisplayNameCell {...params} lastSelectedRow={lastSelectedRow} />
      ),
    },
    {
      ...defaultColumnConfig,
      field: ProjectsField.TOTAL_PARTS_COUNT,
      flex: 0.15,
      align: "right",
      headerAlign: "right",
      headerName: t("project:table:header:parts"),
      renderCell: TextCell,
      valueGetter: (params: GridValueGetterParams) => {
        return params?.value || 0;
      },
    },
    {
      ...defaultColumnConfig,
      field: ProjectsField.PARTS_IN_RFQ_COUNT,
      flex: 0.25,
      align: "right",
      headerAlign: "right",
      headerName: t("project:table:header:sent"),
      renderCell: CountCellWithTotalParts,
    },
    {
      ...defaultColumnConfig,
      field: ProjectsField.PARTS_CONTRACTED_COUNT,
      flex: 0.25,
      align: "right",
      headerAlign: "right",
      headerName: t("project:table:header:partsContracted"),
      renderCell: CountCellWithTotalParts,
    },
    {
      ...defaultColumnConfig,
      field: ProjectsField.PARTS_CANCELLED_COUNT,
      flex: 0.25,
      align: "right",
      headerAlign: "right",
      headerName: t("project:table:header:cancelled"),
      renderCell: CountCellWithTotalParts,
    },
    {
      ...defaultColumnConfig,
      field: ProjectsField.EDIT_BUTTON,
      headerName: " ",
      width: 40,
      align: "center",
      renderCell: IconButtonCell,
    },
  ];

  return _columns;
};
