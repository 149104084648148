import React, { useEffect, useState } from "react";

import { Field, Form, FormikProps } from "formik";
import {
  Box,
  CircularProgress,
  IconButton,
  Theme,
  Tooltip,
} from "@mui/material";
import { GenericTextInput } from "components/utils/input/text";
import { useSelector } from "react-redux";
import { selectAnswerQATopicLoading } from "@next/modules/workspace/redux/selectors";
import { useTranslation } from "react-i18next";
import { AnswerTopicInput, TopicType } from "@next/modules/workspace/redux";
import { FormikAttachFile } from "@next/components/formik-attach-file";
import { getSupportedQnAFileTypes } from "@next/utils/constantUtils";
import GenericAvatar from "@next/components/generic-avatar/generic-avatar";
import AttachmentOutlinedIcon from "@mui/icons-material/AttachmentOutlined";
import SendIcon from "@mui/icons-material/Send";
import useUpdateEffect from "@next/hooks/useUpdateEffect";
import { useElementSize } from "@next/hooks/useElementSize";
import { createStyles, makeStyles } from "@mui/styles";
import { useEnterKeyListener } from "@next/hooks/useEnterKeyListener";
import { getUser } from "services/profile/profile.selectors";

// used to get the height of the answer field
const answerFieldId = "answer-field-container";

function getFormHeightWithAttachments(formHeight: number, len: number) {
  return formHeight + (len ? 50 * len : 0);
}

type StyleProps = {
  portalFormHeight?: number;
};

const useStyles = makeStyles<Theme, StyleProps>((theme) =>
  createStyles({
    root: {
      display: "flex",
      flexDirection: "column",
      position: "relative",
    },
    namesRow: {
      display: "flex",
      gap: theme.spacing(2),
      marginBottom: theme.spacing(2),
    },
    fileListContainer: {
      position: "absolute",
      top: ({ portalFormHeight }) => (portalFormHeight || 0) + 16,
      left: 0,
      marginTop: 0,
      width: "100%",
    },
    iconButton: {
      color: theme.palette.grey[900],
      padding: theme.spacing(1),
    },
  })
);

export const TopicMessageFormBase = ({
  handleSubmit,
  isValid,
  requiresName,
  setFieldValue,
  values,
  topic,
  droppedFiles,
}: FormikProps<Partial<AnswerTopicInput>> & {
  requiresName: boolean;
  topic?: TopicType;
  droppedFiles?: File[];
}) => {
  const { t } = useTranslation();
  const loading = useSelector(selectAnswerQATopicLoading);
  const [portalFormHeight, setPortalFormHeight] = useState(40);
  const classes = useStyles({ portalFormHeight });
  const [ipRef, { height: ipHeight }] = useElementSize();
  const user = useSelector(getUser);

  const answerInputRef = React.useRef<HTMLInputElement>(null);

  useEnterKeyListener(handleSubmit, {
    element: answerInputRef.current,
    isCtrlEnter: true,
  });

  // pass answer field ref to get it's height
  useEffect(() => {
    ipRef(document.querySelector(`#${answerFieldId}`));
  }, []);

  // update portal form height when answer field height changes
  useUpdateEffect(() => {
    setPortalFormHeight(ipHeight);
  }, [ipHeight]);

  useEffect(() => {
    if (droppedFiles && droppedFiles.length) {
      setFieldValue("attachments", [
        ...(values?.attachments || []),
        ...droppedFiles,
      ]);
    }
  }, [droppedFiles]);

  return (
    <Box>
      <Form
        onSubmit={handleSubmit}
        className={classes.root}
        style={{
          height: getFormHeightWithAttachments(
            portalFormHeight,
            values?.attachments?.length || 0
          ),
        }}
      >
        {requiresName ? (
          <Box className={classes.namesRow} gap="2px">
            <GenericTextInput
              name="firstName"
              label={t("workspaceNext:QA:firstName")}
            />
            <GenericTextInput
              name="lastName"
              label={t("workspaceNext:QA:lastName")}
            />
          </Box>
        ) : null}

        <Box display="flex">
          <Box mr={2} mt="4px">
            <GenericAvatar
              size="medium"
              url={topic?.current_user?.picture || user?.picture || ""}
              name={
                topic?.current_user?.full_name ||
                topic?.current_user?.email ||
                `${user?.first_name}" "${user?.last_name}` ||
                ""
              }
            />
          </Box>
          <Box mr={1} flex={1} id={answerFieldId}>
            <GenericTextInput
              name="answer"
              label={t("workspaceNext:QA:topicPortal:answerPlaceholder")}
              multiline
              maxRows={10}
              size="small"
              inputRef={answerInputRef}
            />
          </Box>

          <Box>
            <Field
              name="attachments"
              filesOnTop={false}
              accept={`${getSupportedQnAFileTypes()}`}
              component={FormikAttachFile}
              fileListContainerClassName={classes.fileListContainer}
              buttonComponent={({ onClick }: { onClick: () => void }) => (
                <Tooltip title={t("workspaceNext:rfqDrawer:attachFiles")}>
                  <IconButton
                    className={classes.iconButton}
                    onClick={onClick}
                    size="large"
                  >
                    <AttachmentOutlinedIcon />
                  </IconButton>
                </Tooltip>
              )}
            />
          </Box>
          <Box ml="2px">
            {loading ? (
              <Box ml={1} mt={1}>
                <CircularProgress size="24px" />
              </Box>
            ) : (
              <Tooltip title={t("workspaceNext:QA:topicPortal:sendTooltip")}>
                <IconButton
                  className={classes.iconButton}
                  type="submit"
                  disabled={!isValid}
                  size="large"
                >
                  <SendIcon />
                </IconButton>
              </Tooltip>
            )}
          </Box>
        </Box>
      </Form>
    </Box>
  );
};
