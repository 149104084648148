import * as Yup from "yup";
import { currencyLabels, workTimeOptions } from "./simple-rfq-constants";

export const initialValues = (portalData) => {
  const setInitialValues = (dropDownOptionName) => {
    if (dropDownOptionName == portalData?.quotation?.delay_unit) {
      return workTimeOptions.find(
        (workTime) => workTime.label == dropDownOptionName
      );
    } else if (dropDownOptionName == portalData?.quotation?.currency) {
      return currencyLabels.find(
        (currency) => currency.label == dropDownOptionName
      );
    }
  };
  return {
    total_cost: portalData?.quotation?.total_price
      ? portalData?.quotation?.total_price
      : null,
    delay: portalData?.quotation?.delay ? portalData?.quotation?.delay : null,
    delay_unit: portalData?.quotation?.delay_unit
      ? setInitialValues(portalData?.quotation?.delay_unit)
      : null,
    currency: portalData?.quotation?.currency
      ? setInitialValues(portalData?.quotation?.currency)
      : null,
    additional_details: portalData?.quotation?.additional_details
      ? portalData?.quotation?.additional_details
      : null,
    files: portalData?.quotation?.files
      ? portalData?.quotation?.files
      : undefined,
  };
};

export const simpleRFQFormValidationSchema = Yup.object({
  total_cost: Yup.number().required("Required"),
  delay: Yup.number().required("Required"),
  currency: Yup.string().required("Required"),
  delay_unit: Yup.string().required("Required"),
  additional_details: Yup.string().notRequired().nullable(),
  files: Yup.mixed().notRequired(),
});
