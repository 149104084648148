import React, { useCallback, useEffect, useState } from "react";
import { debounce } from "lodash";
import SearchIcon from "@mui/icons-material/Search";
import CloseIcon from "@mui/icons-material/Close";
import { IconButton, ListItem } from "@mui/material";
import { useSelector } from "react-redux";
import { selectSubtierCompaniesLoading } from "../../redux/selectors";
import { SubtierSupplierResponse } from "../../redux";
import * as S from "./subtiers-form-auto-complete.styled";

type Props = {
  options: SubtierSupplierResponse[];
  selectedOptions: string[];
  handleChange: (options: any) => void;
  searchValue: string;
  setSearchValue: (value: string) => void;
  placeholder: string;
};

export const SubtiersFormAutoComplete: React.FC<Props> = ({
  options,
  selectedOptions,
  handleChange,
  searchValue,
  setSearchValue,
  placeholder
}) => {
  const [localSearchValue, setLocalSearchValue] = useState(searchValue);
  const isOptionsLoading = useSelector(selectSubtierCompaniesLoading);
  const autoCompleteOptions = options.map((option) => option.title);
  const autoCompleteOptionAddresses = options.map((option) => option.address);

  const debouncedSetSearchValue = useCallback(
    debounce((value: string) => {
      setSearchValue(value);
    }, 300),
    [setSearchValue]
  );

  useEffect(() => {
    return () => {
      debouncedSetSearchValue.cancel();
    };
  }, [debouncedSetSearchValue]);

  const handleSearchInputChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const value = event.target.value;
    setLocalSearchValue(value);
    debouncedSetSearchValue(value);
  };

  const handleClickRemoveAll = () => {
    if (selectedOptions.length !== 0) {
      handleChange([]);
      setLocalSearchValue("");
      setSearchValue("");
    }
  };

  return (
    <S.StyledAutoComplete
      multiple
      id="tags-standard"
      options={autoCompleteOptions}
      value={selectedOptions}
      loading={isOptionsLoading}
      inputValue={localSearchValue}
      onChange={(_, value) => {
        handleChange(value);
      }}
      disableCloseOnSelect
      isOptionEqualToValue={(option, value) => option === value}
      renderInput={(params) => (
        <S.StyledInputBox>
          <SearchIcon />
          <S.StyledTextField
            {...params}
            variant="standard"
            placeholder={placeholder}
            InputProps={{
              ...params.InputProps,
              disableUnderline: true,
              onChange: handleSearchInputChange
            }}
          />
          <IconButton onClick={handleClickRemoveAll}>
            <CloseIcon />
          </IconButton>
        </S.StyledInputBox>
      )}
      renderOption={(props, option) => (
        <ListItem {...props}>
          <S.StyledTypography variant="body1">
            {option as React.ReactNode}
            <S.StyledSecondTypography>
              {
                autoCompleteOptionAddresses[
                  autoCompleteOptions.indexOf(option as string)
                ]
              }
            </S.StyledSecondTypography>
          </S.StyledTypography>
        </ListItem>
      )}
      PopperComponent={(props) => (
        <S.StyledPopper {...props} placement="bottom-start" />
      )}
    />
  );
};
