import { Box, IconButton, styled } from "@mui/material";
import { CustomTypography } from "@next/components/custom-typography";

export const RootBox = styled(Box)({
  display: "flex",
  flexDirection: "row",
  justifyContent: "space-between",
  width: "100%",
});

export const StyledAttachedBox = styled(Box)({
  display: "flex",
  flexDirection: "row",
  gap: "8px",
  alignItems: "center",
});

export const StyledAttachmentTypography = styled(CustomTypography)(
  ({ theme }) => ({
    color: `${theme.palette.text.secondary}`,
    letterSpacing: "0.25px",
  })
);

export const StyledFileBox = styled(Box)(({ theme }) => ({
  height: "24px",
  backgroundColor: `${theme.palette.divider}`,
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  borderRadius: "15px",
  paddingLeft: "8px",
  paddingRight: "8px",
  paddingTop: "4px",
  paddingBottom: "4px",
}));

export const StyledFileTypography = styled(CustomTypography)(({ theme }) => ({
  color: `${theme.palette.text.primary}`,
  letterSpacing: "0.4px",
}));

export const StyledFileNameTypography = styled(CustomTypography)(
  ({ theme }) => ({
    color: `${theme.palette.primary.main}`,
    maxWidth: "150px",
  })
);

export const StyledIconButton = styled(IconButton)({
  color: "black",
  width: "24px",
  height: "24px",
  right: 0,
  marginRight: "10px",
});
