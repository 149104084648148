/*
 *   Validation Page
 *   Documentation Reference: https://grad4.atlassian.net/wiki/spaces/FDS/pages/243171343/Validation+page
 *   This component is used whenever we want to re-assure the user that something was in fact done
 *   for instance sending a RFQ to the marketplace or sending a confirmation email.
 *   This component is doing the routing to one (or optionally two) different part of the application.
 */

// General IMport
import React from "react";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

// Material UI Import
import { Button, Grid, Typography } from "@mui/material";

// TODO change the actions of the services by those of the project
import { modalsActions } from "services/modals/modals.actions";
import { workspaceActions } from "services/workspace";
import { modalsActions as _modalActions } from "@next/redux/modalsSlices";
import { CustomCircularProgress } from "@next/components/custom-circular-progress";
import { SharedModalTypes } from "@next/modals/types";
import { useTranslation } from "react-i18next";
import { getCompany } from "services/profile/profile.selectors";
import { checkIsBRPCompany } from "@next/utils/checkCompanyUtils";
import { workspaceNextActions } from "@next/modules/workspace/redux";
import { getSelectExportBRPFilesLoading } from "@next/modules/workspace/redux/selectors";
import CustomButton from "@next/components/custom-button";
import { useAxyaTracking } from "@next/hooks/useAxyaTracking";

interface IValidationPageProps {
  title?: string;
  body?: string;
  primaryText?: string;
  primaryUrl?: string;
  handleSubmitPrimaryUrl?: () => void;
  secondaryText?: string;
  secondaryUrl?: string;
  buttonsTheme?: string;
  image?: string;
  showUploadPurchaseOrder?: () => void;
  isPurchaseOrder?: boolean;
  isRFQCreationSuccess?: boolean;
  className?: string;
  rfqPk?: number;
  rfqDisplayName?: string;
  winnerQuotePk?: number;
  isProject?: boolean;
  primaryLoading?: boolean;
}

const ValidationPage: React.FC<IValidationPageProps> = ({
  title,
  body,
  primaryText,
  primaryUrl,
  handleSubmitPrimaryUrl = null,
  secondaryText,
  secondaryUrl,
  image,
  rfqPk,
  rfqDisplayName,
  winnerQuotePk,
  isPurchaseOrder = false,
  isRFQCreationSuccess = false,
  className = "validation-page",
  isProject,
  primaryLoading,
}) => {
  const dispatch = useDispatch();
  // This component use the history hook to redirect the user where it needs to be
  let history = useHistory();
  const { t } = useTranslation();
  const company = useSelector(getCompany);
  const axyaTracking = useAxyaTracking();
  const isBRPCompany = checkIsBRPCompany(company?.id);
  const exportBRPFilesLoading = useSelector(
    getSelectExportBRPFilesLoading(rfqPk ? rfqPk.toString() : "")
  );
  const isSupplierDirectory = history?.location?.pathname?.includes(
    "suppliers_directory"
  );

  const onClickExport = () => {
    if (!rfqPk || !rfqDisplayName || !winnerQuotePk) return;
    axyaTracking({
      scout_category: "usage_metric",
      scout_rfq_pk: rfqPk,
      scout_action: "export",
      data: { from: "validation_page" },
    });
    dispatch(
      workspaceNextActions.exportBRPFilesRequest({
        rfqId: rfqPk.toString(),
        rfqDisplayName,
        quoteId: +winnerQuotePk,
      })
    );
  };

  const handleSecondaryBtnClick = () => {
    history.push(secondaryUrl!);
    dispatch(workspaceActions.setCongratulationViewDetails({}));
    // hide RFQ creation and success modals on return to workspace btn click
    if (isRFQCreationSuccess) {
      dispatch(modalsActions.hideModal("isCreateRfqModalOpen"));
      dispatch(modalsActions.hideModal("isRFQCreationSuccessModalOpen"));
    }
  };

  const primaryBtnClick = () => {
    history.push(primaryUrl!);
    if (isRFQCreationSuccess) {
      dispatch(modalsActions.hideModal("isCreateRfqModalOpen"));
      dispatch(modalsActions.hideModal("isRFQCreationSuccessModalOpen"));
    }
  };

  const handlePrimaryBtnClick = () => {
    if (isPurchaseOrder && rfqPk) {
      dispatch(
        _modalActions.showModal({
          key: SharedModalTypes.ORDER_PURCHASE_MODAL,
          data: { contractPk: rfqPk, isProject },
        })
      );
    } else {
      if (handleSubmitPrimaryUrl) {
        handleSubmitPrimaryUrl();
      } else {
        primaryBtnClick();
      }
    }
  };

  return (
    <Grid
      container
      direction="row"
      justifyContent="center"
      alignItems="center"
      alignContent="center"
      className={className}
      xs={12}
      style={{ minHeight: "100vh", height: "100%" }}
    >
      <Grid
        item
        container
        direction="row"
        justifyContent="center"
        alignItems="center"
      >
        <Grid item>
          <img src={image} alt="congrats" style={{ width: "100%" }} />
        </Grid>
      </Grid>
      <Grid
        item
        container
        direction="row"
        justifyContent="center"
        alignItems="center"
      >
        <Typography variant="h2" gutterBottom>
          {title}
        </Typography>
      </Grid>
      <Grid
        item
        container
        xs={6}
        direction="row"
        justifyContent="center"
        alignItems="center"
        style={{ marginBottom: "3.5em" }}
      >
        <Typography
          align="center"
          gutterBottom
          style={{ whiteSpace: "pre-line" }}
        >
          {body}
        </Typography>
      </Grid>
      <Grid
        container
        xs={12}
        direction="row"
        justifyContent="center"
        alignItems="center"
        spacing={3}
      >
        {secondaryText && (
          <Grid item>
            <Button
              onClick={() => handleSecondaryBtnClick()}
              variant="contained"
              color="primary"
            >
              {secondaryText!}
            </Button>
          </Grid>
        )}
        <Grid item>
          {primaryLoading ? (
            <CustomCircularProgress />
          ) : (
            !isSupplierDirectory && (
              <Button
                onClick={() => handlePrimaryBtnClick()}
                variant="contained"
                color="primary"
                disabled={history?.location?.pathname?.includes(
                  "suppliers_directory"
                )}
              >
                {primaryText!}
              </Button>
            )
          )}
        </Grid>
        {isBRPCompany && isPurchaseOrder && isProject && rfqPk ? (
          <Grid item>
            <CustomButton
              loading={exportBRPFilesLoading}
              onClick={onClickExport}
              variant="contained"
              color="primary"
            >
              {t("workspace:supplierSelectionExport")}
            </CustomButton>
          </Grid>
        ) : null}
      </Grid>
    </Grid>
  );
};

export default ValidationPage;
