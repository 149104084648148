import React, { useCallback } from "react";
import { Formik } from "formik";

import {
  getTopicPortalQuotationFormInitialValues,
  validationSchema,
} from "./topic-portal-send-quotation-form.definitions";

import { TopicPortalSendQuotationFormBase } from "./topic-portal-send-quotation-form.base";
import { useDispatch, useSelector } from "react-redux";
import { Quotation, workspaceNextActions } from "@next/modules/workspace/redux";
import { useQuery } from "@next/hooks/useQuery";
import { selectPortalData } from "@next/modules/workspace/redux/selectors";

const TopicPortalSendQuotationForm: React.FC = () => {
  const dispatch = useDispatch();
  const portalData = useSelector(selectPortalData);
  const isQuoteAvailable = !!portalData?.quotation;
  const [query] = useQuery();
  const rfqToken = query.rfq_token as string;
  const isRFI = portalData?.request_type === "rfi";

  const handleSubmit = useCallback((values) => {
    dispatch(
      isQuoteAvailable
        ? workspaceNextActions.updatePortalQuoteRequest({
            ...values,
            quotation_id: portalData?.quotation?.pk,
            rfqToken,
            isRFI,
          })
        : workspaceNextActions.createPortalQuoteRequest({
            ...values,
            rfqToken,
            isRFI,
          })
    );
  }, []);

  return (
    <Formik
      validateOnMount
      initialValues={getTopicPortalQuotationFormInitialValues(
        portalData?.quotation as Quotation
      )}
      onSubmit={handleSubmit}
      validationSchema={validationSchema()}
    >
      {(props) => <TopicPortalSendQuotationFormBase isRFI={isRFI} {...props} />}
    </Formik>
  );
};

export default TopicPortalSendQuotationForm;
