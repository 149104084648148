/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useMemo, useState } from "react";
import {
  getQuoteComparisonColumns,
  getQuoteComparisonRecapColumns,
} from "./quote-comparison.columns";
import { Config, QCPartData } from "./types";
import {
  makeCellSelectedById,
  markLowestPriceSuppliers,
  markSelectAllBySupplierId,
} from "./quote-comparison.utils";
import { useSelector } from "react-redux";
import {
  selectQuoteComparison,
  selectQuoteComparisonLoading,
} from "../../redux/selectors";
import { uniqBy } from "lodash";

type GridDataState = {
  rows: QCPartData[];
  columns: any;
};

export const useQuoteComparisonData = ({ isAwardedRfq }) => {
  const quoteComparison = useSelector(selectQuoteComparison);
  const quoteComparisonLoading = useSelector(selectQuoteComparisonLoading);

  const [gridData, setGridData] = useState<
    GridDataState & { config?: Config | any }
  >({
    rows: [],
    columns: [],
    config: undefined,
  });
  const [columnView, setColumnView] = useState<Boolean>(true);

  useEffect(() => {
    if (quoteComparison?.data) {
      setGridData({
        rows: (quoteComparison?.data as QCPartData[]) || [],
        columns: getQuoteComparisonColumns({
          suppliers: quoteComparison?.config?.options?.suppliers,
        }),
        config: quoteComparison?.config || {},
      });
    }
  }, [quoteComparison]);

  const selectedSuppliersParts = useMemo(
    () =>
      gridData?.rows.flatMap((part) =>
        part.suppliers
          .filter((supplier) => supplier.selected)
          .map((item) => {
            return { ...item, partId: part.id };
          })
      ),
    [gridData?.rows]
  );

  const convertedTotalPriceSum = useMemo(() => {
    const sumOfFixedFees = uniqBy(selectedSuppliersParts, "id").reduce(
      (sum, supplier) => {
        return (
          sum +
          (gridData?.config?.options?.suppliers?.find(
            (item) => item.id === supplier?.id
          )?.converted_price?.fixed_fees || 0)
        );
      },
      0
    );

    const sumOfTotalPrices = selectedSuppliersParts.reduce((sum, supplier) => {
      return (
        sum + (supplier.quotation_fields.converted_price?.total_price || 0)
      );
    }, 0);

    return sumOfFixedFees + sumOfTotalPrices;
  }, [selectedSuppliersParts]);

  const getSelectedTotalSumBySupplier = (supplierId: number) => {
    const selectedSupplierTotalPriceSum = selectedSuppliersParts
      ?.filter((item) => item?.id === supplierId)
      ?.reduce(
        (sum, supplier) => sum + (supplier.quotation_fields?.total_price || 0),
        0
      );

    return selectedSupplierTotalPriceSum;
  };

  const autoSelectLowestPrice = () => {
    setGridData({
      ...gridData,
      rows: markLowestPriceSuppliers(gridData?.rows) as any,
    });
  };

  const selectAllPartsBySupplier = (supplierId: number) => {
    setGridData({
      ...gridData,
      rows: markSelectAllBySupplierId(gridData?.rows, supplierId) as any,
    });
  };

  const onChangeHandler = (event: any, row: any) => {
    if (!event?.target?.value || isAwardedRfq) {
      return null;
    }
    let selected_index = event?.target?.value?.slice(-1)?.pop();

    setGridData((oldGridData) => ({
      ...oldGridData,
      rows: makeCellSelectedById(oldGridData?.rows, row?.id, selected_index),
    }));
  };

  const switchQuoteComparisonView = () => {
    setColumnView(!columnView);
    if (!columnView) {
      setGridData({
        ...gridData,
        columns: getQuoteComparisonColumns({
          suppliers: quoteComparison?.config?.options?.suppliers,
        }),
      });
    } else {
      setGridData({
        ...gridData,
        columns: getQuoteComparisonRecapColumns(
          onChangeHandler,
          quoteComparison?.config
        ),
      });
    }
  };

  return {
    columnView,
    gridData,
    setGridData,
    selectedData: {
      selectedSuppliersParts,
      convertedTotalPriceSum,
    },
    switchQuoteComparisonView,
    getSelectedTotalSumBySupplier,
    autoSelectLowestPrice,
    loading: quoteComparisonLoading,
    selectAllPartsBySupplier,
  };
};
