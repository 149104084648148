import React, { useState } from "react";
import { Box, IconButton, Menu, MenuItem, Tooltip } from "@mui/material";
import { Language } from "@mui/icons-material";
import { useLocation } from "react-router-dom";
import { createStyles, makeStyles } from "@mui/styles";
import { Theme } from "@mui/material";
import { LANGUAGE, useLanguageToggle } from "@next/hooks/useLanguageToggle";

const useStyles = makeStyles<Theme, { disablePadding?: boolean }>(
  (theme: Theme) =>
    createStyles({
      iconButton: {
        backgroundColor: theme.palette.grey[300],
        borderRadius: "100%",
        padding: ({ disablePadding }) => (disablePadding ? "0px" : "8px"),
        cursor: "pointer",
      },
      menuPaper: { border: `solid 1px ${theme.palette.divider}` },
      menuItem: {
        textTransform: "uppercase",
      },
    })
);

type Props = {
  disablePadding?: boolean;
  noBg?: boolean;
  iconColor?: "white" | "black";
  customNode?: React.ReactComponentElement<any>;
};

const LanguageToggle: React.FC<Props> = ({
  disablePadding,
  noBg,
  iconColor = "white",
  customNode,
}) => {
  const classes = useStyles({ disablePadding });
  const { t, activeLanguage, handleChange } = useLanguageToggle();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const location = useLocation();
  const isAuthPage = location.pathname.includes("/auth");

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const buttonJSX = (
    <IconButton onClick={noBg ? handleClick : undefined} size="large">
      <Language style={{ color: iconColor }} />
    </IconButton>
  );

  return (
    <div>
      <Tooltip title={Boolean(anchorEl) ? "" : t("appTour:language")}>
        {customNode ? (
          <Box onClick={handleClick}>{customNode}</Box>
        ) : noBg ? (
          buttonJSX
        ) : (
          <div className={classes.iconButton} onClick={handleClick}>
            {buttonJSX}
          </div>
        )}
      </Tooltip>

      <Menu
        id="language-toggle-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
        elevation={0}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        PaperProps={{
          className: classes.menuPaper,
        }}
      >
        <MenuItem
          onClick={handleChange}
          selected={activeLanguage === LANGUAGE.FR}
          className={classes.menuItem}
          data-value={LANGUAGE.FR}
        >
          {LANGUAGE.FR}
        </MenuItem>
        <MenuItem
          onClick={handleChange}
          selected={activeLanguage === LANGUAGE.EN}
          className={classes.menuItem}
          data-value={LANGUAGE.EN}
        >
          {LANGUAGE.EN}
        </MenuItem>
      </Menu>
    </div>
  );
};

export default LanguageToggle;
