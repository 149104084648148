import { Box } from "@mui/material";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { Topic } from "./topic/topic";
import { useQuestionAnswersData } from "./questions-answers.hooks";
import { CustomCircularProgress } from "@next/components/custom-circular-progress";
import InviteTechnicalFocal from "../invite-technical-focal";
import { useDispatch, useSelector } from "react-redux";
import { workspaceActions } from "services/workspace";
import { selectRfqUpdatePartialStatus } from "services/workspace/workspace.selectors";
import { ACTION_STATUS } from "@next/constants";
import { createStyles, makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      display: "flex",
      justifyContent: "center",
      paddingTop: theme.spacing(3),
      flexDirection: "column",
      alignItems: "center",
      width: "100%",
    },
    topic: { paddingBottom: theme.spacing(2) },
    topicContainer: {
      width: "629px",
    },
  })
);

export const QuestionsAnswers: React.FC<{
  rfqId: string;
  showTechFocal?: boolean;
  email?: string | null;
  showToastOnMessage?: boolean;
  initiallyOpenQAId?: number;
}> = ({
  rfqId,
  showTechFocal,
  email,
  showToastOnMessage = true,
  initiallyOpenQAId,
}) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { questions, questionsLoading } = useQuestionAnswersData(rfqId);

  const requestInitiated = useRef(false);
  const [value, setValue] = useState(email || "");
  const rfqUpdatePartialStatus = useSelector(selectRfqUpdatePartialStatus);

  const onAdd = useCallback((supporter) => {
    requestInitiated.current = true;
    dispatch(workspaceActions.rfqUpdatePartial(rfqId, { supporter }));
    setValue(supporter);
  }, []);

  const onRemove = useCallback(() => {
    requestInitiated.current = true;
    dispatch(workspaceActions.rfqUpdatePartial(rfqId, { supporter: null }));
    setValue("");
  }, []);

  useEffect(() => {
    //if update rquest failed then revert to initial state
    if (
      requestInitiated.current &&
      rfqUpdatePartialStatus === ACTION_STATUS.FAILED
    ) {
      setValue(email || "");
    }
  }, [rfqUpdatePartialStatus, requestInitiated.current]);

  if (questionsLoading) return <CustomCircularProgress />;

  return (
    <div className={classes.root}>
      <div className={classes.topicContainer}>
        {showTechFocal ? (
          <Box mb={2}>
            <InviteTechnicalFocal
              value={value}
              onAdd={onAdd}
              onRemove={onRemove}
            />
          </Box>
        ) : null}
        {questions?.map((topic) => {
          return (
            <div className={classes.topic}>
              <Topic
                replyDisabled={false}
                topic={topic}
                showToastOnMessage={showToastOnMessage}
                initiallyOpen={initiallyOpenQAId === topic?.pk}
              />
            </div>
          );
        })}
      </div>
    </div>
  );
};
