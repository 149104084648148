import { Box, IconButton } from "@mui/material";
import React from "react";
import ClearIcon from "@mui/icons-material/Clear";
import {
  OnboardingSteps,
  SellerDashboardOnboardingPremium,
} from "@next/modules/dashboard/components/seller-dashboard-onboarding-premium/seller-dashboard-onboarding-premium";
import { history } from "helpers/history";
import { frontendUrl } from "urls";
import { useDispatch, useSelector } from "react-redux";
import { selectSupplierOnboardingStatus } from "@next/modules/dashboard/redux/selectors";
import { SharedModalTypes } from "@next/modals/types";
import { getModal, modalsActions } from "@next/redux/modalsSlices";
import { createStyles, makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      position: "fixed",
      minWidth: "500px",
      right: theme.spacing(2),
      bottom: theme.spacing(2),
      padding: theme.spacing(3),
      zIndex: 9999,
      backgroundColor: theme.palette.background.default,
      boxShadow: theme.shadows[2],
      borderRadius: "16px",
      transition: "all 0.3s ease-in-out",
    },
    clearIcon: {
      position: "absolute",
      top: theme.spacing(3),
      right: theme.spacing(3),
    },
  })
);

export const OnboardingPopup: React.FC = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const isOnboardingPopupOpen = useSelector(
    getModal(SharedModalTypes.SUPPLIER_ONBOARDING_MODAL)
  );
  const supplierOnboardingStatus = useSelector(selectSupplierOnboardingStatus);

  const handleStepClick = (step: OnboardingSteps) => {
    history.push(`${frontendUrl.dashboard}?onboardingStep=${step}`);
  };

  const handleClose = () => {
    dispatch(
      modalsActions.closeModal(SharedModalTypes.SUPPLIER_ONBOARDING_MODAL)
    );
  };

  return (
    <Box
      className={classes.root}
      display={isOnboardingPopupOpen ? "block" : "none"}
    >
      <SellerDashboardOnboardingPremium
        onboardingStatus={supplierOnboardingStatus}
        showDescription={false}
        onClickStep={handleStepClick}
      />
      <IconButton
        onClick={handleClose}
        className={classes.clearIcon}
        size="large"
      >
        <ClearIcon />
      </IconButton>
    </Box>
  );
};
