import { Box, Grid, Link, Typography } from "@mui/material";
import { GenericTextInput } from "components/utils/input/text";
import { ErrorMessage, Field, Form, FormikProps } from "formik";
import React, { ComponentProps } from "react";
import { Trans, useTranslation } from "react-i18next";
import { PublicRFQFormValuesType } from "./public-rfq-creation-form.types";
import { FormikAttachFile } from "@next/components/formik-attach-file";
import PublicRfqCreationFormButton from "../public-rfq-creation-form-button";
import { OrangeOutlinedButton } from "../public-rfq-creation-form-button/public-rfq-creation-form-button";
import { createStyles, makeStyles, withStyles } from "@mui/styles";

export const amqCompanyPrimaryColor = "#E8581F";
const amqCompanySecondaryColor = "#0366A4";

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      overflowY: "auto",

      // MUI issue: https://github.com/mui/material-ui/issues/7466
      "& .MuiGrid-root": {
        width: "100% !important",
        margin: "0 !important",
      },
    },
    label: {
      fontSize: 20,
    },
  })
);

const OrangeGenericTextInput = withStyles({
  root: {
    fontSize: 20,
    "& label.Mui-focused": {
      color: amqCompanySecondaryColor,
    },
    "& .MuiInput-underline:hover:not(.Mui-disabled):before": {
      borderBottomColor: amqCompanyPrimaryColor,
    },
    "& .MuiInput-underline:before": {
      borderBottomColor: amqCompanyPrimaryColor,
    },
    "& .MuiInput-underline:after": {
      borderBottomColor: amqCompanyPrimaryColor,
    },
  },
})((props: ComponentProps<typeof GenericTextInput>) => (
  <GenericTextInput
    {...props}
    InputLabelProps={{
      shrink: true,
      className: useStyles().label,
    }}
  />
));

export const PublicRfqCreationFormBase: React.FC<
  FormikProps<PublicRFQFormValuesType>
> = ({ handleSubmit, errors, touched }) => {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <Form onSubmit={handleSubmit} className={classes.root}>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <OrangeGenericTextInput
            name="first_name"
            label={t("workspaceNext:publicRfq:form:firstName")}
            size="small"
            error={touched.first_name && !!errors.first_name}
            variant="standard"
          />
        </Grid>

        <Grid item xs={12}>
          <OrangeGenericTextInput
            name="last_name"
            label={t("workspaceNext:publicRfq:form:lastName")}
            size="small"
            error={touched.last_name && !!errors.last_name}
            variant="standard"
          />
        </Grid>

        <Grid item xs={12}>
          <OrangeGenericTextInput
            name="company_name"
            label={t("workspaceNext:publicRfq:form:company")}
            size="small"
            error={touched.company_name && !!errors.company_name}
            variant="standard"
          />
        </Grid>

        <Grid item xs={12}>
          <OrangeGenericTextInput
            name="email"
            label={t("workspaceNext:publicRfq:form:email")}
            size="small"
            type="email"
            error={touched.email && !!errors.email}
            variant="standard"
          />
        </Grid>

        <Grid item xs={12}>
          <OrangeGenericTextInput
            name="additional_details"
            label={t("workspaceNext:publicRfq:form:descriptionOfYourRequest")}
            multiline
            rows="8"
            error={touched.additional_details && !!errors.additional_details}
            variant="standard"
          />
        </Grid>

        <Grid item xs={12}>
          <Field
            name="attachments"
            component={FormikAttachFile}
            captionText={t("workspaceNext:publicRfq:form:attachFilesCaption")}
            customAttachButton={OrangeOutlinedButton}
            btnProps={{
              startIcon: null,
              variant: "outlined",
            }}
            btnText={t("workspaceNext:rfqDrawer:attachFiles")}
            skipDeleteConfirmationModal={true}
          />
          <Box pt={"5px"} pl={"5px"}>
            <Typography color={"error"} variant="caption">
              <ErrorMessage component="div" name="attachments" />
            </Typography>
          </Box>
        </Grid>

        <Grid item xs={12}>
          <Box mt="-12px">
            <Typography variant="body2" style={{ fontSize: 16 }}>
              <Trans
                i18nKey="workspaceNext:publicRfq:form:agreeToTheTerms"
                components={[
                  <Link
                    href="https://axya.co/en/buyers-terms-of-services"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Terms of Services
                  </Link>,
                  <Link
                    href="https://axya.co/en/privacy-policy"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Privacy Policy
                  </Link>,
                ]}
              />
            </Typography>
          </Box>
        </Grid>
      </Grid>

      <Box mt="12px">
        <PublicRfqCreationFormButton>
          {t("common:submit")}
        </PublicRfqCreationFormButton>
      </Box>
    </Form>
  );
};
