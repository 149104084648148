// General imports
import React, { useEffect } from "react";
import { t } from "assets/configi18n/i18n";

// Hooks
import { useDispatch, useSelector } from "react-redux";

// Material UI
import { Box, Typography, alpha } from "@mui/material";

import CurrencyField from "components/common/fields/CurrencyField";
// Actions
import {
  selectAxyaRFQStatsData,
  selectAxyaRFQStatsLoading,
} from "@next/modules/workspace/redux/selectors";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import ShowChartOutlinedIcon from "@mui/icons-material/ShowChartOutlined";
import { AppTheme } from "layout/AppTheme";
import { workspaceNextActions } from "@next/modules/workspace/redux";
import { RequestType } from "@next/modules/marketplace/redux";

export function RFQsStats({ type }) {
  const stats = useSelector(selectAxyaRFQStatsData);
  const statsLoading = useSelector(selectAxyaRFQStatsLoading);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(workspaceNextActions.fetchAxyaRFQStatsRequest());
  }, []);

  return (
    <Box
      p={2}
      borderRadius="8px"
      bgcolor={alpha(AppTheme.palette.primary.main, 0.1)}
      display="flex"
      alignItems="center"
      gap="16px"
    >
      {type === RequestType.TENDERS ? (
        <InfoOutlinedIcon color="primary" />
      ) : (
        <ShowChartOutlinedIcon color="primary" />
      )}

      {type === RequestType.TENDERS ? (
        <Typography variant="body2" color="primary">
          {t("tender:tableDescription")}
        </Typography>
      ) : statsLoading ? null : (
        <>
          <Typography variant="body2" color="primary">
            {t("workspace:recentActivitiesStats")}
          </Typography>
          <Typography variant="body2" color="primary">
            <b>{stats?.rfqs_count}</b>{" "}
            {t("workspace:requestsForQuotationsCreated")}
          </Typography>
          <Typography variant="body2" color="primary">
            |
          </Typography>
          <Typography variant="body2" color="primary">
            <b>{stats?.parts_count}</b> {t("workspace:partsAwarded")}
          </Typography>
          <Typography variant="body2" color="primary">
            |
          </Typography>
          <Typography variant="body2" color="primary">
            <b>
              <CurrencyField value={stats?.total_gmv} />
            </b>{" "}
            {t("workspace:contractedOnGrad4Prl")}
          </Typography>{" "}
        </>
      )}
    </Box>
  );
}
