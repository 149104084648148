import React from "react";

import { Form, FormikProps } from "formik";
import { Button, Grid, Theme, Typography } from "@mui/material";
import { GenericTextInput } from "components/utils/input/text";
import { useSelector } from "react-redux";
import { selectCreateQATopicLoading } from "@next/modules/workspace/redux/selectors";
import { useTranslation } from "react-i18next";
import { createStyles, makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: { padding: 24 },
    isPrivateSelect: { width: "100%" },
    button: { paddingTop: theme.spacing(2) },
  })
);

type Props = FormikProps<any> & {
  buyerName: string;
};

export const AskQuestionFormBase = ({
  handleSubmit,
  buyerName,
  handleChange,
}: Props) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const loading = useSelector(selectCreateQATopicLoading);

  return (
    <Form onSubmit={handleSubmit} className={classes.root}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Typography variant="body1" color="textSecondary">
            {t("workspaceNext:askQuestion:subtitle")}
          </Typography>
        </Grid>

        <Grid item xs={12}>
          <GenericTextInput
            name="body"
            label={t("workspaceNext:description")}
            multiline
            rows="6"
          />
        </Grid>
      </Grid>

      <Grid container justifyContent="flex-end" className={classes?.button}>
        <Button
          type="submit"
          variant="contained"
          color="primary"
          disabled={loading}
        >
          {t("common:send")}
        </Button>
      </Grid>
    </Form>
  );
};
