import React from "react";
import {
  CreateMultiContractState,
  SupplierKeyValue,
} from "./create-multi-contract-table.types";
import CancelIcon from "@mui/icons-material/Cancel";
import WatchLaterIcon from "@mui/icons-material/WatchLater";
import { Avatar } from "@mui/material";
import { getFirstCharacter } from "@next/utils/stringUtils";
import HelpIcon from "@mui/icons-material/Help";
import { Trans } from "react-i18next";

export const getSummaryItemTitleIcons = (
  supplier?: SupplierKeyValue,
  size: string = "24px"
) => {
  const iconStyle = {
    width: size,
    height: size,
    color: "grey",
  };

  switch (supplier?.value) {
    case CreateMultiContractState.LEAVE_IN_RFQ:
      return <WatchLaterIcon style={{ ...iconStyle }} />;
    case CreateMultiContractState.CANCEL:
      return <CancelIcon style={{ ...iconStyle }} />;

    case CreateMultiContractState.ERROR:
      return <HelpIcon style={{ ...iconStyle }} />;

    default:
      return (
        <Avatar
          alt={`Avatar ${supplier?.label}`}
          src={supplier?.picture}
          style={{
            ...iconStyle,
            backgroundColor: "lightgray",
            color: "white",
            fontSize: "14px",
          }}
        >
          {getFirstCharacter(supplier?.label)}
        </Avatar>
      );
  }
};

export const getSummaryItemTitleText = (
  supplier: SupplierKeyValue,
  partCount: number
) => {
  const translatedComponent = (transKey: string) => (
    <Trans
      i18nKey={transKey}
      components={[<b />]}
      values={{ count: partCount, supplierName: supplier?.label }}
    />
  );

  switch (supplier?.value) {
    case CreateMultiContractState.LEAVE_IN_RFQ:
      return translatedComponent("project:multiContract:partsLeftInRFQ");

    case CreateMultiContractState.CANCEL:
      return translatedComponent("project:multiContract:partsCancelled");

    case CreateMultiContractState.ERROR:
      return translatedComponent(
        "project:multiContract:notRecognizedErrorMessage"
      );

    default:
      return translatedComponent(
        "project:multiContract:partsAttributedToSupplier"
      );
  }
};
