import React, { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import Modal from "@next/components/custom-modal";
import { getModal, modalsActions } from "@next/redux/modalsSlices";

import { WorkspaceModalTypes } from "../types";
import RFQAddAddendaForm from "./rfq-add-addenda-form";
import { formatDate } from "helpers/formatDate";
import { createStyles, makeStyles } from "@mui/styles";

const useStyles = makeStyles(() =>
  createStyles({
    paper: {
      width: 648,
    },
    dialogContent: {
      overflowX: "hidden",
    },
  })
);

export const RFQAddAddendaModal = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const classes = useStyles();
  const modal = useSelector(
    getModal(WorkspaceModalTypes.RFQ_ADD_ADDENDA_MODAL)
  );

  const { index } = (modal?.data || {}) as { index: number };

  const onClose = useCallback(() => {
    dispatch(
      modalsActions.closeModal(WorkspaceModalTypes.RFQ_ADD_ADDENDA_MODAL)
    );
  }, []);

  if (!modal) return null;

  return (
    <Modal
      handleModalClose={onClose}
      modalState={{
        modalHeader: `${t("rfqAddenda:addenda")} ${
          (index || 0) + 1
        } - ${formatDate(new Date(), "DD-MMM-YYYY")}`,
        modalBody: <RFQAddAddendaForm />,
        dialogContentClassName: classes.dialogContent,
      }}
      dialogProps={{ fullWidth: true, classes: { paper: classes.paper } }}
      isModalOpen={true}
    />
  );
};
