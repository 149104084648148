import { Box, Typography } from "@mui/material";
import React from "react";
import { NotificationsTable } from "./notifications-table/notifications-table";
import { useDispatch, useSelector } from "react-redux";
import { selectMarkAllAsSeenNotificationLoading } from "@next/modules/app/redux/selectors";
import { appActions } from "@next/modules/app/redux";
import CustomButton from "@next/components/custom-button/custom-button";
import { useTranslation } from "react-i18next";

export const Notifications = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const markAllAsSeenNotificationLoading = useSelector(
    selectMarkAllAsSeenNotificationLoading
  );

  const onClickMarkAllAsRead = () => {
    dispatch(appActions.markAllAsSeenNotificationRequest());
  };

  return (
    <Box
      style={{
        width: "583px",
        height: "80vh",
      }}
    >
      <Box p="24px" display="flex" justifyContent="space-between">
        <Typography variant="h6">{t("notification:title")}</Typography>

        <CustomButton
          variant="text"
          color="primary"
          onClick={onClickMarkAllAsRead}
          loading={markAllAsSeenNotificationLoading}
        >
          {t("notification:markAllAsRead")}
        </CustomButton>
      </Box>

      <Box height="calc(100% - 90px)">
        <NotificationsTable />
      </Box>
    </Box>
  );
};
