import i18next from "i18next";
import moment from "moment";
import * as yup from "yup";
import { addDays, format } from "date-fns";
import { RFQFormLiteValuesType } from "./rfq-creation-form-lite.types";

export const getInitialValues = (values: Partial<RFQFormLiteValuesType>) => {
  return {
    rfq_name: values?.rfq_name || "",
    additional_details: values?.additional_details || "",
    additional_details_rich_text:
      values?.additional_details_rich_text || values?.additional_details || "",
    deadline: values?.deadline || format(addDays(new Date(), 7), "yyyy-MM-dd"),
    attachments: values?.attachments || [],
  };
};

export const getValidationSchema = (t: i18next.TFunction) =>
  yup.object().shape(
    {
      rfq_name: yup
        .string()
        .required(t("workspaceNext:rfqDrawer:error:titleIsReqd"))
        .max(128, t("common:validation:maxCharacters", { limit: 128 })),
      deadline: yup
        .string()
        .required(t("workspaceNext:rfqDrawer:error:endDateIsReqd"))
        .test(
          "is-future-date",
          t("workspaceNext:rfqDrawer:error:pastDate"),
          (val) => moment(val).diff(moment(), "days") >= 0
        ),
      attachments: yup
        .array()
        .required(t("workspaceNext:rfqDrawer:error:rfiAttachmentRequired")),
    },
    [
      ["invited_suppliers", "targeted_suppliers"],
      ["is_public", "targeted_suppliers"],
    ]
  );
