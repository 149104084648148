import { Box, Button, Typography } from "@mui/material";
import { GenericTextInput } from "components/utils/input/text";
import { Form, Formik } from "formik";
import React from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { createStyles, makeStyles } from "@mui/styles";

export interface ITNQReasonFormProps {
  onSubmit: (reason: string) => void;
  onSkip?: () => void;
}

const useStyles = makeStyles((theme) =>
  createStyles({
    title: {
      fontWeight: 700
    },
    skipBtn: {
      marginRight: theme.spacing(1)
    },
    btn: {
      textTransform: "unset"
    }
  })
);

export const IntentToNoQuoteReasonForm: React.FC<ITNQReasonFormProps> = (
  props
) => {
  const { onSkip, onSubmit } = props;
  const classes = useStyles();
  const { t } = useTranslation();
  const rfq = useSelector(
    (state: Record<string, any>) => state.marketplace.activeRfqDetails
  );

  return (
    <Box p={1} pt={0.5}>
      <Typography className={classes.title} variant="subtitle1">
        {t("quotation:notIntentToQuoteReasonModal:provideAdditionalInfo", {
          userName: rfq?.created_by?.full_name || "User",
          companyName: rfq?.company_name || "Company"
        })}
      </Typography>
      <Formik
        initialValues={{ reason: "" }}
        onSubmit={({ reason }) => onSubmit(reason)}
      >
        {({ handleSubmit, values }) => (
          <Form>
            <Box mt={2}>
              <GenericTextInput
                maxLength={50}
                label={t("quotation:notIntentToQuoteReasonModal:addAReason")}
                name="reason"
              />
            </Box>
            <Box mt={2} display="flex" justifyContent="flex-end">
              <Button
                className={`${classes.skipBtn} ${classes.btn}`}
                onClick={onSkip}
              >
                {t("quotation:notIntentToQuoteReasonModal:skip")}
              </Button>
              <Button
                disabled={!values.reason}
                variant="contained"
                onClick={() => handleSubmit()}
                color="primary"
                className={classes.btn}
              >
                {t("quotation:notIntentToQuoteReasonModal:send")}
              </Button>
            </Box>
          </Form>
        )}
      </Formik>
    </Box>
  );
};
