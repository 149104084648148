import React from "react";
import CheckIcon from "@mui/icons-material/Check";
import PlayIcon from "@mui/icons-material/OndemandVideo";
import { useDispatch, useSelector } from "react-redux";
import { FormTitle } from "../form-title/form-title";
import { useLocation } from "react-router-dom";

import {
  selectQuestionnaireName,
  selectStepsLoading,
  selectVisibleSteps,
} from "../../redux/selectors";

import { useHistory } from "react-router-dom";

import {
  PartialStep,
  vendorManagementActions,
} from "@next/modules/vendor-management/redux";
import * as S from "./greeting-form.styled";

const firstLineOfTextForPosEndUserQuest =
  "• You are about to complete End Users (Aerostructure & Detail Parts) : Tiers 1 & Subtiers questionnaire.";
const firstLineOfTextForPomManufactureQuest =
  "• You are about to complete Manufacturer questionnaire.";
const firstLineOfTextForPomDistributorQuest =
  "• You are about to complete Distributor questionnaire.";

const firstLineOfText = {
  posEndUsersQuestionnaire: firstLineOfTextForPosEndUserQuest,
  pomManufacturerQuestionnaire: firstLineOfTextForPomManufactureQuest,
  pomDistributorQuestionnaire: firstLineOfTextForPomDistributorQuest,
};

const textList = [
  "• You can navigate through each section independently.",
  "• All saved changes can be done on a section per section basis.",
  "• We will consider your input final when you confirm step completion.",
  "• Once all sections have been completed, you shall receive a confirmation screen; meaning the questionnaire is complete.",
  "• The questionnaire can not be shared as it is a public secure link only accessible to you.",
  "• Press “Start” to begin questionnaire completion.",
  "• By fulfilling this questionnaire, you validate that all the information declared is under your own responsibility.",
];

export const GreetingForm: React.FC = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();
  const requestStepsLoading = useSelector(selectStepsLoading);
  const steps = useSelector(selectVisibleSteps);
  const questionnaireName = useSelector(selectQuestionnaireName);
  const modalText = [firstLineOfText[questionnaireName], ...textList];

  const handleClick = () => {
    if (!requestStepsLoading) {
      setActiveFormStepIndex(0);
    }
  };

  const setActiveFormStepIndex = (index: number) => {
    const step = steps[index];
    const partialStep: PartialStep = {
      is_active: true,
    };
    dispatch(
      vendorManagementActions.updateStepRequest({
        stepId: step.id,
        data: partialStep,
      })
    );
  };
  const handleWatchWebinarClick = () => {
    const searchParams = new URLSearchParams(location.search);
    searchParams.set("webinar", "true");

    // Push the new URL with the ?webinar=true query parameter
    history.push({
      pathname: location.pathname,
      search: searchParams.toString(),
    });
  };
  return (
    <>
      <S.ImageBox>
        <S.StyledFormBox>
          <FormTitle title={"Information"} isCompleted={null} />

          <S.StyledTypography variant="body1">
            {modalText.map((item, i) => (
              <li key={i}>{item}</li>
            ))}
          </S.StyledTypography>

          <S.StyledButtonContainer>
            <S.StyledWatchWebinarButton
              variant="outlined"
              startIcon={<PlayIcon sx={{ width: "24px", height: "24px" }} />}
              onClick={handleWatchWebinarClick}
            >
              <S.ButtonText variant="body2" tooltipTitle={null}>
                Watch Webinar
              </S.ButtonText>
            </S.StyledWatchWebinarButton>

            <S.StyledButton
              variant="contained"
              startIcon={<CheckIcon sx={{ width: "24px", height: "24px" }} />}
              onClick={handleClick}
            >
              <S.ButtonText variant="body2" tooltipTitle={null}>
                Start
              </S.ButtonText>
            </S.StyledButton>
          </S.StyledButtonContainer>
        </S.StyledFormBox>
      </S.ImageBox>
    </>
  );
};
