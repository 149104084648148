import { Box, Divider, TextField, styled } from "@mui/material";
import { CustomTypography } from "@next/components/custom-typography";

export const RootBox = styled(Box)`
  width: 100%;
  height: 752px;
  border-radius: 24px;
  border: 1px solid ${({ theme }) => theme.palette.divider};
  padding: 24px;
  margin-top: 36px;
  background-color: white;
`;

export const HeaderDiv = styled(Box)`
  display: flex;
  flex-direction: column;
  margin-top: 24px;
  margin-left: 24px;
`;

export const InnerDiv = styled("div")`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const PopupDiv = styled("div")`
  width: 680px;
  min-height: 252px !important;
  background-color: white;
  border: 1px solid ${({ theme }) => theme.palette.divider};
  border-radius: 10px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  position: absolute;
  margin-left: 5rem;
  margin-top: -30px;
`;

export const CustomTextField = styled(TextField)(({ theme }) => ({
  "& .MuiFormHelperText-root": {
    position: "absolute",
    right: "0px",
    width: "138px",
    height: "24px",
    fontSize: "16px",
    textAlign: "right",
    marginTop: "7px",
  },
}));

export const PopupCardDiv = styled("div")`
  width: 340px;
  max-height: 200px;
  min-height: 180px;
  border-bottom: 1px solid ${({ theme }) => theme.palette.divider};
  display: flex;
  flex-direction: column;
  padding: 16px;
  gap: 12px;
`;

export const OverallStats = styled(Box)`
  height: 72px;
  width: 100%;
  display: flex;
  flex-direction: row;
  margin-top: 48px;
  margin-left: 24px;
`;

export const StyledTypography = styled(CustomTypography)`
  color: rgba(0, 0, 0, 0.54);
  lineheight: 24px;
  letter-spacing: 0.5px;
`;

export const PercentageDiv = styled(Box)`
  margin-top: 12px;
`;

export const BoldTypography = styled(CustomTypography)`
  font-family: "Space Grotesk";
  font-size: 34px;
  line-height: 36px;
  font-weight: 400px;
`;

export const StyledDivider = styled(Divider)`
  color: ${({ theme }) => theme.palette.divider};
  width: 1px;
  height: 72px;
  margin-left: 48px;
  margin-right: 48px;
  margin-top: 0px;
`;

export const StyledGraphBox = styled(Box)`
  margin-top: 24px;
  margin-left: 24px;
`;

export const StyledLabel = styled("label")`
  color: ${({ theme }) => theme.palette.primary.main};
  font-weight: 700;
`;
