import React from "react";
import Modal from "@next/components/custom-modal";
import { useDispatch, useSelector } from "react-redux";
import { getModal, modalsActions } from "@next/redux/modalsSlices";
import { OrdersModalTypes } from "./types";
import { useTranslation } from "react-i18next";
import { OrderLineItemChanges } from "../redux";
import { OrderDetailFinalizeReviewForm } from "../components/order-detail/order-detail-finalize-review-form/order-detail-finalize-review-form";

export const FinalizeProposalReviewModal = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const modal = useSelector(
    getModal(OrdersModalTypes.FINALIZE_PROPOSAL_REVIEW_MODAL)
  );
  const data = modal?.data as {
    lineItemsChanges?: OrderLineItemChanges[];
    makeChangesFromBuyer?: boolean;
    asModal?: boolean;
  };

  if (!modal) return null;

  const onClose = () => {
    dispatch(
      modalsActions.closeModal(OrdersModalTypes.FINALIZE_PROPOSAL_REVIEW_MODAL)
    );
  };

  return (
    <Modal
      handleModalClose={onClose}
      modalState={{
        modalHeader: t("orders:orderDetail:finalizeReviewModal:header"),
        modalBody: (
          <OrderDetailFinalizeReviewForm
            onClose={onClose}
            lineItemsChanges={data?.lineItemsChanges}
            makeChangesFromBuyer={data?.makeChangesFromBuyer}
            asModal={data?.asModal}
            onSubmitted={() => {}}
          />
        ),
      }}
      isModalOpen={true}
      customWidth="520px"
      disableContentPadding
    />
  );
};
