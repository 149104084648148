import * as yup from "yup";

export type OrderDetailMarkAsShippedFormState = {
  shippedDate: Date;
  shippingNote: string;
};

export const orderDetailMarkAsShippedFormState: OrderDetailMarkAsShippedFormState =
  {
    shippedDate: new Date(),
    shippingNote: "",
  };

export const orderDetailMarkAsShippedFormValidationSchema = yup.object({
  shippedDate: yup.date(),
  shippingNote: yup.string(),
});
