import React from "react";
import { PortalItnbFormValues } from "./portal-itnb-form.types";
import { Form, FormikProps } from "formik";
import { useTranslation } from "react-i18next";
import {
  Box,
  FormControlLabel,
  Radio,
  RadioGroup,
  Typography,
} from "@mui/material";
import CustomButton from "@next/components/custom-button/custom-button";
import InfoCard from "@next/modules/marketplace/pages/public-itb-itnb/info-card";
import { PortalData } from "@next/modules/workspace/redux";
import { GenericTextInput } from "components/utils/input/text";
import { OTHER_REASON_SLUG } from "@next/modules/marketplace/components/intent-to-bid/intent-to-bid.helpers";
import { useSelector } from "react-redux";
import { selectUpdatePortalQuoteNoQuoteLoading } from "@next/modules/workspace/redux/selectors";
import { createStyles, makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) =>
  createStyles({
    card: {
      boxShadow: "none",
      padding: theme.spacing(1),
      maxWidth: "unset",
      paddingBottom: theme.spacing(1.5),
    },
  })
);

const PortalItnbFormBase: React.FC<
  FormikProps<PortalItnbFormValues> & { portalData?: PortalData }
> = ({ handleSubmit, values, handleChange, portalData }) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const loading = useSelector(selectUpdatePortalQuoteNoQuoteLoading);
  const { created_by, company, rfq_itnb_reasons } = portalData || {};

  return (
    <Form onSubmit={handleSubmit}>
      <InfoCard
        variant="success"
        title={t("publicItbItnb:title")}
        status="error"
        statusText={t("publicItbItnb:noQuote")}
        bodyParas={[
          t("publicItbItnb:itnbDescription"),
          t("publicItbItnb:regards"),
        ]}
        footerNote={`${created_by?.full_name}\n${company?.name}`}
        cardClassName={classes.card}
      >
        <Box mt="-12px">
          <RadioGroup
            name="reason"
            value={values.reason}
            onChange={handleChange}
          >
            {(rfq_itnb_reasons || []).map((reason) => (
              <FormControlLabel
                value={reason.slug}
                control={<Radio size="small" />}
                label={<Typography variant="body2">{reason.title}</Typography>}
              />
            ))}
          </RadioGroup>
        </Box>

        {values.reason === OTHER_REASON_SLUG && (
          <Box mt={-2}>
            <GenericTextInput
              name="description"
              label={t("publicItbItnb:reason")}
              size="small"
            />
          </Box>
        )}
        <Box mt={-1} display="flex" justifyContent="flex-end">
          <CustomButton
            type="submit"
            color="primary"
            variant="contained"
            loading={loading}
          >
            {t("publicItbItnb:save")}
          </CustomButton>
        </Box>
      </InfoCard>
    </Form>
  );
};

export default PortalItnbFormBase;
