import React from "react";

import { t } from "assets/configi18n/i18n";
import { QuotationExtraFieldsParams } from "./quotation-extra-fields-table.types";
import { TextareaAutosize, Tooltip, Typography } from "@mui/material";
import { CustomTypography } from "@next/components/custom-typography";
import moment from "moment";
import { EN_DATE_FORMAT } from "@next/constants";
import {
  GridCellParams,
  GridColDef,
  GridRenderEditCellParams,
  GridValueGetterParams
} from "@mui/x-data-grid-pro";

const AdditionalDetailsCell: React.FC<GridCellParams> = ({ value }) => (
  <Tooltip title={value as string} aria-label={value as string}>
    <Typography variant="caption">{value as string}</Typography>
  </Tooltip>
);

const AdditionalDetailsEditCell: React.FC<GridRenderEditCellParams> = ({
  id,
  getValue,
  api,
  field
}) => {
  const handleChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    if (api) {
      api.setEditCellValue({ id, field, value: event.target.value }, event);
    }
  };

  const handleRef = (element: HTMLTextAreaElement) => {
    if (element) {
      element.focus();
    }
  };

  return (
    <TextareaAutosize
      ref={handleRef}
      onChange={handleChange}
      value={getValue(id, field) as string}
      style={{
        width: 400,
        lineHeight: 1.4,
        minHeight: 50,
        position: "absolute"
      }}
      aria-label="empty textarea"
      placeholder="Empty"
    />
  );
};

const GenericTooltipTextCell: React.FC<GridCellParams> = ({ value }) => (
  <CustomTypography>{value as string}</CustomTypography>
);

const dateValueGetter = (params: GridValueGetterParams) => {
  if (!params.value) return "";
  return moment(params.value as string)
    .endOf("day")
    .format(EN_DATE_FORMAT);
};

export const getExtraFieldsTableColumns = (
  quotationExtraFields: QuotationExtraFieldsParams[],
  partExtraFields: any[],
  isEditable: boolean,
  hideQuotationSensitiveData: boolean
) => {
  const defaultColumnConfig: Omit<GridColDef, "field"> = {
    disableColumnMenu: true,
    sortable: false,
    hideSortIcons: true,
    width: 120,
    headerClassName: "quotation-column-header",
    editable: isEditable,
    renderCell: GenericTooltipTextCell
  };

  const _columns: GridColDef[] = [
    {
      ...defaultColumnConfig,
      headerName: t("project:table:header:partNumber"),
      field: "name",
      editable: false
    },
    {
      ...defaultColumnConfig,
      headerName: t("project:table:header:quantity"),
      field: "quantity",
      editable: false
    },

    // Added for export csv part information
    {
      ...defaultColumnConfig,
      headerName: t("project:table:header:description"),
      field: "additional_details",
      hide: true,
      editable: false,
      renderCell: AdditionalDetailsCell,
      renderEditCell: AdditionalDetailsEditCell,
      minWidth: 150,
      valueGetter: (_params: GridValueGetterParams) => {
        return _params?.value?.toString()?.replace(/[\n\r]/g, "");
      }
    },
    ...(partExtraFields || [])?.map((field: any) => ({
      ...defaultColumnConfig,
      field: field.mapped_to || field.slug,
      headerName: field.display_name["en"],
      type: field.type,
      valueOptions: field.valueOptions,
      hide: true,
      valueGetter: field.type === "date" ? dateValueGetter : undefined
    })),
    // Added for export csv

    ...(quotationExtraFields || [])?.map((field) => ({
      ...defaultColumnConfig,
      field: field.mapped_to || field.slug,
      headerName: field.display_name["en"],
      type: field.type,
      valueOptions: field.valueOptions,
      valueGetter: field.type === "date" ? dateValueGetter : undefined,
      ...(hideQuotationSensitiveData
        ? {
            renderCell: () => <span className="blurred-content"></span>
          }
        : null)
    }))
  ];

  return _columns;
};
