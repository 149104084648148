import { Theme } from "@mui/material";
import { TabsView } from "@next/components/tabs-view";
import { TabPanel } from "components/utils/tab-panel/tab-panel";
import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { checkCountAndReturn } from "../buyer-rfqs/buyer-rfqs.utils";
import { SupplierQuotesTable } from "../../components/supplier-quotes-table/supplier-quotes-table";
import { selectSupplierQuotesCounts } from "../../redux/selectors";
import { useSelector } from "react-redux";
import useLocalStorage from "hooks/useLocalStorage";
import { createStyles, makeStyles } from "@mui/styles";

export enum ESupplierQuotesTabType {
  ON_GOING = 0,
  WAITING = 1,
  ON_HOLD = 2,
  ARCHIVED = 3,
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      //TODO: This is temp solution due to parent scss class
      marginLeft: -theme.spacing(3),
      marginRight: -theme.spacing(4),
      marginTop: -theme.spacing(5),
    },
    header: {
      display: "flex",
      flexDirection: "column",
    },
    titleContainer: { display: "flex", alignItems: "center" },
    subtitle: { width: "50%", marginTop: theme.spacing(1.5) },
    content: { marginTop: theme.spacing(0.5) },
    tabPanels: {
      marginTop: theme.spacing(2.5),
    },
    button: {
      marginLeft: theme.spacing(1),
    },
  })
);

export const SupplierQuotes = () => {
  const classes = useStyles();
  const { t } = useTranslation();
  const supplierQuotesCounts = useSelector(selectSupplierQuotesCounts);

  const [tabIndex, setTabIndex] = useLocalStorage<ESupplierQuotesTabType>(
    "lastSupplierQuotesTabIndex",
    ESupplierQuotesTabType.ON_GOING
  );

  const tabItems = useMemo(
    () => [
      {
        tabName: t("workspaceNext:supplierQuotes:ongoing", {
          count: checkCountAndReturn(supplierQuotesCounts?.ongoing_count),
        }),
      },
      {
        tabName: t("workspaceNext:supplierQuotes:waiting", {
          count: checkCountAndReturn(supplierQuotesCounts?.waiting_count),
        }),
      },
      {
        tabName: t("workspaceNext:supplierQuotes:onHold", {
          count: checkCountAndReturn(supplierQuotesCounts?.on_hold_count),
        }),
      },
      {
        tabName: t("workspaceNext:supplierQuotes:archived", {
          count: checkCountAndReturn(supplierQuotesCounts?.archived_count),
        }),
      },
    ],
    [supplierQuotesCounts]
  );

  const tabHandleChange = (_: React.ChangeEvent<{}>, value: any) => {
    setTabIndex(value);
  };

  return (
    <div className={classes.root}>
      <div className={classes.content}>
        <TabsView
          value={tabIndex}
          tabItems={tabItems}
          handleChange={tabHandleChange}
        />

        <div className={classes.tabPanels}>
          <TabPanel value={tabIndex} index={ESupplierQuotesTabType.ON_GOING}>
            <SupplierQuotesTable query="status=inProgress" />
          </TabPanel>

          <TabPanel value={tabIndex} index={ESupplierQuotesTabType.WAITING}>
            <SupplierQuotesTable query="status=waiting" />
          </TabPanel>

          <TabPanel value={tabIndex} index={ESupplierQuotesTabType.ON_HOLD}>
            <SupplierQuotesTable query="status=onHold" />
          </TabPanel>

          <TabPanel value={tabIndex} index={ESupplierQuotesTabType.ARCHIVED}>
            <SupplierQuotesTable query="status=archive" />
          </TabPanel>
        </div>
      </div>
    </div>
  );
};
