import React from "react";
import { Avatar, Box, Typography } from "@mui/material";
import { GridRenderCellParams } from "@mui/x-data-grid-pro-v5";

export const NameCell: React.FC<GridRenderCellParams> = ({ value, row }) => {
  return (
    <Box display="flex" justifyContent="center" alignItems="center">
      <Avatar
        src={row?.picture}
        variant="square"
        style={{ width: "32px", height: "32px" }}
      />

      <Box paddingLeft="8px" display="flex" flexDirection="column">
        <Box mb={0.4}>
          <Typography variant="body2" height="20px">
            {value}
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};
