import { Box, styled } from "@mui/material";

type Props = {
  isCompleted: boolean;
};

const FormBoxLg = styled(Box, {
  shouldForwardProp: (prop) => prop !== "isCompleted",
})<Props>(({ theme, isCompleted }) => ({
  width: "95%",
  display: "flex",
  flexDirection: "column",
  gap: "48px",
  padding: "48px",
  border: isCompleted
    ? `2px solid ${theme.palette.success.main}`
    : `1px solid ${theme.palette.divider}`,
  borderRadius: "16px",
  zIndex: 1,
  backgroundColor: "#ffffff",
}));
export default FormBoxLg;
