import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";
import {
  Box,
  Card,
  CardContent,
  CardMedia,
  Divider,
  Grid,
  Typography,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

// Components
import PartViewer from "../viewer/viewer.component";
import PartAttachments from "../attachments/attachments.component";
import PartTags from "../tags/tags.component";

PartDetails.propTypes = {
  /**
   * Part info object
   */
  part: PropTypes.object.isRequired,
};

function PartDetails({ part, downloadable, onClose, ...rest }) {
  const [activeAttachment, setActiveAttachment] = useState(null);
  const [activeFilePk, setActiveFilePk] = useState(null);
  const onUploadSelectionChange = (attachment, attachments) => {
    if (!attachment) {
      return;
    }

    setActiveAttachment(attachment.file_url);
    setActiveFilePk(attachment.pk);
  };

  const { t } = useTranslation("");

  return (
    <Card className="part-details" style={{ flex: 1 }}>
      <div className="viewer-container">
        <CardMedia className="card-media" style={{ height: "100%" }}>
          <PartViewer
            disableSnapshot={true}
            src={activeAttachment}
            activeFilePk={activeFilePk}
          />
        </CardMedia>
      </div>
      <div
        className="part-infos-container"
        style={{ display: "flex", flexDirection: "column" }}
      >
        <CardContent>
          <Grid container direction="row">
            <Grid item container xs={12} className="part-infos-title">
              <Box
                textAlign="left"
                fontWeight="fontWeightMedium"
                fontSize="h6.fontSize"
                className="details-text"
              >
                {part.name}
              </Box>
              <Box>
                <CloseIcon
                  className="part-details--close-icon"
                  onClick={() => onClose()}
                />
              </Box>
            </Grid>
            <Divider className="part-infos--divider"></Divider>

            <Grid item xs={12}>
              <PartTags part={part} />
            </Grid>
            <Divider className="part-infos--divider"></Divider>
            <Grid item xs={12} className="additional-details-container">
              {part.additional_details ? (
                <Box
                  textAlign="left"
                  fontWeight="fontWeightRegular"
                  fontSize={14}
                  className="details-text"
                >
                  <Box
                    textAlign="left"
                    fontWeight="fontWeightMedium"
                    fontSize={14}
                    className="part-details--comment-section"
                  >
                    {t("workspace:comment")}
                  </Box>
                  <Typography variant="body2">
                    {part.additional_details}
                  </Typography>
                  <Divider className="part-infos--divider"></Divider>
                </Box>
              ) : (
                <></>
              )}
              <Grid item xs={12} className="additional-details-container">
                <Box
                  textAlign="left"
                  fontWeight="fontWeightMedium"
                  fontSize={14}
                  className="part-details--files-section"
                >
                  {t("RFQ:files")}
                </Box>
              </Grid>
            </Grid>
          </Grid>
        </CardContent>
        <CardContent
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            flex: "1 1",
          }}
        >
          <PartAttachments
            {...rest}
            downloadable={downloadable}
            attachments={part.part_uploads_list}
            onAttachmentSelectionChange={onUploadSelectionChange}
            hideUploadZone={true}
            scoutCategory="attachments"
            scoutFeatureName="select_part_details_attachment"
            scoutDeleteFeatureName="delete_part_details_attachment"
          />
        </CardContent>
      </div>
    </Card>
  );
}

export default PartDetails;
