import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useIsLocationPath } from "@next/hooks/useIsPath";
import { useQuery } from "@next/hooks/useQuery";
import { profileNextActions } from "@next/modules/profile/redux";
import { checkIsPublicPage } from "@next/utils/apiUtils";
import {
  LOCAL_STORAGE_LANGUAGE_KEY,
  getBrowserLanguage
} from "@next/utils/browserUtils";
import i18n from "assets/configi18n/i18n";
import useLocalStorage from "hooks/useLocalStorage";
import moment from "moment";
import "moment/locale/en-ca"; // Explicitly import moment locales
import "moment/locale/fr"; // Explicitly import moment locales
import { getUser, getUserLanguage } from "services/profile/profile.selectors";
import { frontendUrl } from "urls";

export enum LANGUAGE {
  EN = "en",
  FR = "fr"
}

export const useLanguageToggle = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const location = useLocation();
  const isAuthPage = location.pathname.includes("/auth");
  const userLanguage = useSelector(getUserLanguage);
  const user = useSelector(getUser);
  const isPublicPage = checkIsPublicPage();
  const isPortalPage = useIsLocationPath(frontendUrl.portal);

  const [query, setQuery] = useQuery();
  const queryLanguage = query?.lang as LANGUAGE;

  const browserLanguage = getBrowserLanguage();
  const [activeLanguage, setActiveLanguage] = useLocalStorage<LANGUAGE>(
    LOCAL_STORAGE_LANGUAGE_KEY,
    userLanguage || browserLanguage
  );

  useEffect(() => {
    i18n.changeLanguage(activeLanguage);

    // Manually switch between the pre-loaded locales
    if (activeLanguage === "en") {
      moment.locale("en-ca");
    } else if (activeLanguage === "fr") {
      moment.locale("fr");
    }

    if (
      user !== null &&
      activeLanguage !== userLanguage &&
      (!isPublicPage || (isPortalPage && query.rfq_token)) &&
      !isAuthPage
    ) {
      dispatch(
        profileNextActions.updateUserLanguageRequest({
          language: activeLanguage,
          token: isPortalPage
            ? query.rfq_token?.toString().split(".")[1] || ""
            : ""
        })
      );
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeLanguage]);

  useEffect(() => {
    if (
      queryLanguage &&
      (queryLanguage === LANGUAGE.EN || queryLanguage === LANGUAGE.FR) &&
      (isAuthPage || isPortalPage)
    ) {
      setActiveLanguage(queryLanguage);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [queryLanguage]);

  const handleChange = (event: React.MouseEvent<HTMLLIElement>) => {
    const newLanguage = event.currentTarget?.dataset?.value as LANGUAGE;

    setQuery({ lang: newLanguage });

    setActiveLanguage(newLanguage);

    window.location.reload();
  };

  return {
    t,
    activeLanguage,
    handleChange
  };
};
