/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import i18n from "assets/configi18n/i18n";
import { getSupplierLineItemsTableColumns } from "./supplier-line-items-table.columns";
import { getLoadingRows } from "helpers/data-view.helper-v5";
import {
  selectSupplierLineItems,
  selectSupplierLineItemsLoading,
} from "../../redux/selectors";
import { OrderState, ordersActions } from "../../redux";
import { generatePaginationAndSearchQuery } from "@next/utils/paginationUtils";
import { SHORT_PAGINATION_PAGE_SIZE } from "../../../../constants";
import { CardOrderState } from "../orders-table/select-order-state-cards/select-order-state.cards.types";
import { useTableSavedQueryPagination } from "@next/hooks/useTableSavedQueryPagination";
import { SUPPLIER_LINE_ITEMS_TABLE_NAVIGATION_KEY } from "./supplier-line-items-table";

type Props = {
  isShippedOrders: boolean;
  isCanceledOrders: boolean;
  currentPage: number;
  selectedStateFilter?: CardOrderState;
  searchTerm?: string;
};

export const useSupplierLineItemsTableData = ({
  isShippedOrders,
  isCanceledOrders,
  currentPage,
  selectedStateFilter,
  searchTerm,
}: Props) => {
  const { savedTableQueryPagination } = useTableSavedQueryPagination(
    SUPPLIER_LINE_ITEMS_TABLE_NAVIGATION_KEY
  );

  const dispatch = useDispatch();
  const orders = useSelector(selectSupplierLineItems);
  const ordersLoading = useSelector(selectSupplierLineItemsLoading);
  const ordersColumns = useMemo(
    () => getSupplierLineItemsTableColumns(),
    [i18n?.language, savedTableQueryPagination?.lsr, ordersLoading]
  );

  const [gridData, setGridData] = useState<any>({
    rows: [],
    columns: [],
  });

  useEffect(() => {
    if (ordersLoading) {
      const loadingGridData = getLoadingRows(ordersColumns) as any;
      setGridData(loadingGridData);
    } else {
      setGridData({ rows: orders, columns: ordersColumns });
    }
  }, [orders, ordersLoading, ordersColumns]);

  useEffect(() => {
    const baseQuery = generatePaginationAndSearchQuery({
      currentPage,
      pageSize: SHORT_PAGINATION_PAGE_SIZE,
    });

    const orderFilterQuery = isShippedOrders
      ? `is_shipped=true&is_cancelled=false&`
      : isCanceledOrders
      ? `is_cancelled=true&`
      : `is_shipped=false&is_cancelled=false&`;

    const stateFilterQuery =
      selectedStateFilter &&
      selectedStateFilter.slug !== OrderState.ALL &&
      !isShippedOrders &&
      !isCanceledOrders
        ? `status=${selectedStateFilter.query_slugs?.join(",")}&`
        : "";

    const searchTermQuery = searchTerm ? `search=${searchTerm}&` : "";

    const query = `${baseQuery}${orderFilterQuery}${stateFilterQuery}${searchTermQuery}`;

    dispatch(ordersActions.fetchSupplierLineItemsRequest({ query }));
  }, [
    isShippedOrders,
    isCanceledOrders,
    currentPage,
    selectedStateFilter?.slug,
    searchTerm,
  ]);

  return {
    gridData,
  };
};
