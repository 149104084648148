import React from "react";
import { useDispatch, useSelector } from "react-redux";
import Modal from "@next/components/custom-modal";
import { getModal, modalsActions } from "@next/redux/modalsSlices";
import { PortalModalTypes } from "./types";
import PortalItnbForm from "../components/portal-itnb-form/portal-itnb-form";
import { PortalData } from "@next/modules/workspace/redux";

export type PortalItbItnbModalProps = {
  portalData: PortalData;
  rfqToken: string;
  onClose?: () => void;
};

export const PortalItnbModal = () => {
  const dispatch = useDispatch();
  const modal = useSelector(getModal(PortalModalTypes.ITNB_MODAL));

  const { portalData, rfqToken, onClose } = (modal?.data ||
    {}) as PortalItbItnbModalProps;

  if (!modal || !portalData || !rfqToken) return null;

  const _onClose = () => {
    dispatch(modalsActions.closeModal(PortalModalTypes.ITNB_MODAL));
    if (typeof onClose === "function") onClose();
  };

  return (
    <Modal
      handleModalClose={_onClose}
      modalState={{
        modalBody: (
          <PortalItnbForm
            portalData={portalData}
            rfqToken={rfqToken}
            onClose={_onClose}
          />
        ),
      }}
      isModalOpen={true}
    />
  );
};
