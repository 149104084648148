import { useTranslation } from "react-i18next";
import React, { useCallback, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { getRowIdToPk } from "@next/utils/dataGridUtils";
import { SHORT_PAGINATION_PAGE_SIZE } from "@next/constants";
import {
  selectSupplierLineItemsCounts,
  selectSupplierLineItemsStatusCounts,
} from "../../redux/selectors";
import { useTableSavedQueryPagination } from "@next/hooks/useTableSavedQueryPagination";
import { useSupplierLineItemsTableData } from "./supplier-line-items-table.hooks";
import { initialCardStateOrder } from "@next/modules/orders/components/orders-table/select-order-state-cards/select-order-state-cards";
import { SelectSupplierLineItemStateCards } from "./select-supplier-line-items-cards/select-supplier-line-items-state-cards";
import useLocalStorage from "hooks/useLocalStorage";
import { CardOrderState } from "@next/modules/orders/components/orders-table/select-order-state-cards/select-order-state.cards.types";
import { debounce } from "lodash";
import { Box, Grid, InputAdornment, TextField, Theme } from "@mui/material";
import SearchOutlinedIcon from "@mui/icons-material/SearchOutlined";
import { createStyles, makeStyles } from "@mui/styles";
import { DataGridProV5 } from "@next/components/data-grid-pro-v5";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    search: {
      width: "290px",
      "& input": {
        paddingTop: 11.5,
        paddingBottom: 11.75,
      },
    },
    table: {
      "& .MuiDataGrid-virtualScrollerContent": {
        borderRight: "1.5px solid #eeeeee",
      },
      "& .MuiDataGrid-row:first-child .MuiDataGrid-cell": {
        borderTop: "0px",
      },
      "& .MuiDataGrid-cell:first-of-type": {
        borderLeft: "0px",
      },
      "& .MuiDataGrid-cell:last-of-type": {
        borderRight: "0px",
      },
    },
  })
);

type Props = {
  isShippedOrders: boolean;
  isCanceledOrders: boolean;
  disableFiltering?: boolean;
};

export const SUPPLIER_LINE_ITEMS_TABLE_NAVIGATION_KEY =
  "SUPPLIER_ORDERS_TABLE_NAVIGATION";

export const SupplierLineItemsTable: React.FC<Props> = ({
  isShippedOrders,
  isCanceledOrders,
  disableFiltering,
}) => {
  const classes = useStyles();
  const { t } = useTranslation();

  const {
    savedTableQueryPagination,
    setSavedTableQueryPagination,
    onPageChangeTable,
  } = useTableSavedQueryPagination(SUPPLIER_LINE_ITEMS_TABLE_NAVIGATION_KEY);
  const ordersStatusCounts = useSelector(selectSupplierLineItemsStatusCounts);

  const [searchTerm, setSearchTerm] = useState<string>("");

  const [selectedStateFilter, setSelectedStateFilter] =
    useLocalStorage<CardOrderState>(
      "SAVED_SELECTED_SUPPLIER_PO_STATE_FILTER",
      initialCardStateOrder(ordersStatusCounts?.active_count)
    );

  const { gridData } = useSupplierLineItemsTableData({
    isShippedOrders,
    isCanceledOrders,
    currentPage: savedTableQueryPagination?.p || 1,
    selectedStateFilter,
    searchTerm,
  });

  const ordersCounts = useSelector(selectSupplierLineItemsCounts);

  const handleSearch = useCallback(
    debounce(
      (term: string) => {
        if (term.length === 0 || term.length >= 3) setSearchTerm(term);
      },
      800,
      {
        leading: false,
        trailing: true,
      }
    ),
    []
  );

  useEffect(() => {
    // Reset page to 1 when filter changes
    setSavedTableQueryPagination({
      ...savedTableQueryPagination,
      p: 1,
    });
  }, [selectedStateFilter?.slug]);

  return (
    <div>
      <Box mt="16px" mb="16px">
        <Grid container spacing={1} direction="row" alignItems="center">
          <Grid item data-pid={"supplierLineItems:searchBar"}>
            <TextField
              className={classes.search}
              size="small"
              variant="outlined"
              placeholder={t("orders:supplierLineItemTable:search:placeholder")}
              type="search"
              onChange={(e) => handleSearch(e.target.value)}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchOutlinedIcon />
                  </InputAdornment>
                ),
              }}
            />
          </Grid>
          {!disableFiltering ? (
            <Grid item>
              <SelectSupplierLineItemStateCards
                selectedFilterState={selectedStateFilter}
                setSelectedFilterState={setSelectedStateFilter}
                searchTerm={searchTerm}
              />
            </Grid>
          ) : null}
        </Grid>
      </Box>

      <DataGridProV5
        autoHeight
        autoPageSize
        className={classes.table}
        pagination
        paginationMode="server"
        pageSize={SHORT_PAGINATION_PAGE_SIZE}
        rowCount={ordersCounts?.count}
        onPageChange={onPageChangeTable}
        getRowId={getRowIdToPk}
        disableSelectionOnClick
        rowHeight={40}
        loading={false}
        rows={gridData?.rows}
        columns={gridData?.columns}
      />
    </div>
  );
};
