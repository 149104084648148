import { Button } from "@mui/material";
import { MUIDatePicker } from "@next/components/mui-date-picker";
import React, { useState } from "react";

type Props = {
  disabled?: boolean;
  label: string;
  onSelectedDateChange: (date: Date) => void;
};

export const OrderDetailEditLineItemChangeDate: React.FC<Props> = ({
  disabled,
  label,
  onSelectedDateChange,
}) => {
  const [selectedDate, setSelectedDate] = useState(null);
  const [open, setOpen] = useState(false);

  const handleDateChange = (date) => {
    setSelectedDate(date);
    onSelectedDateChange(date);
    setOpen(false);
  };

  const handleButtonClick = () => {
    setOpen(true);
  };

  return (
    <div>
      <Button
        variant="outlined"
        onClick={handleButtonClick}
        disabled={disabled}
      >
        {label}
      </Button>

      <MUIDatePicker
        open={open}
        onClose={() => {
          setOpen(false);
        }}
        value={selectedDate}
        onChange={handleDateChange}
        TextFieldComponent={() => null}
      />
    </div>
  );
};
