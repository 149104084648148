import React from "react";
import * as S from "./form-title.styled";

type Props = {
  title: string;
  isCompleted?: boolean;
};

export const FormTitle: React.FC<Props> = ({ title, isCompleted }) => {
  return (
    <S.StyledBox>
      <S.StyledTitle>{title}</S.StyledTitle>
      {isCompleted ? <S.StyledChip label="Completed" /> : null}
    </S.StyledBox>
  );
};
