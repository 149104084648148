import { profileConstants } from "./profile.constants";

// Initial state of the app + architecture of what we want to have in our store inside authentification
const initialState = {
  refresh: "",
  token: "",
  user: null,
  preferences: null,
  company: null,
  notification: null,
  company: {
    isSavingInProgress: false,
  },
  nps_token: null,
  // Misc Parameters,
  isLoggedIn: false,
  isLoading: false,
  isRegisterLoading: false,
};

export function profile(state = initialState, action) {
  switch (action.type) {
    //User Reducer
    case profileConstants.REGISTER_REQUEST:
      return {
        ...state,
        error: "",
        isRegisterLoading: true,
      };
    case profileConstants.REGISTER_SUCCESS:
      return Object.assign({}, state, {
        isLoading: false,
        isRegisterLoading: action?.keepLoading ? true : false,
        user: action.user,
      });
    case profileConstants.EDIT_PROFILE_SUCCESS:
      return Object.assign({}, state, {
        isLoading: false,
        user: action.user,
      });

    case profileConstants.REGISTER_FAILURE:
      return {
        ...state,
        error: action.error,
        isRegisterLoading: false,
        isLoading: false,
      };
    case profileConstants.LOGIN_REQUEST:
      return {
        ...state,
        user: action.user,
        isLoading: true,
      };

    case profileConstants.LOGIN_FAILURE:
    case profileConstants.USER_PROFILE_FAILURE:
    case profileConstants.RESET_LOGIN_STATE:
      return {
        ...state,
        isLoading: false,
      };

    case profileConstants.USER_PROFILE_SUCCESS:
      let newUser = { ...state.user, ...action.user };
      return {
        ...state,
        user: newUser,
        nps_token: action.nps_token,
        isLoading: false,
      };
    case profileConstants.USER_PROFILE_REQUEST:
    case profileConstants.LOGOUT_REQUEST:
      return {
        ...state,
        isLoading: true,
      };

    case profileConstants.LOGIN_SUCCESS:
      if (action.company === undefined) {
        action.company = state.company;
      }

      return Object.assign({}, state, {
        refresh: action.refresh,
        token: action.token,
        user: action.user,
        company: {
          ...action.company,
          isSavingInProgress: true,
        },
        isLoggedIn: true,
        isLoading: false,
      });

    case profileConstants.LOGOUT_SUCCESS:
      return { ...initialState };
    case profileConstants.LOGOUT_FAILURE:
      return { ...initialState };

    /**REFRESH_REQUEST */
    case profileConstants.REFRESH_REQUEST:
      return { ...state };
    case profileConstants.REFRESH_SUCCESS:
      return {
        ...state,
        refresh: action.refresh,
        token: action.access,
      };

    case profileConstants.EDIT_COMPANY_REQUEST:
      state.company.isSavingInProgress = true;
      return {
        ...state,
      };
    case profileConstants.EDIT_COMPANY_SUCCESS:
      // FIXME:
      // The company's permissions returned by the `PUT company`endpoint
      // is different from the one returned from `GET user/company`.
      // The first returns an array of objects while the other returns an
      // array of nu mbers..  KB (03/06/2020)
      const permissions = state.company.permissions;
      return {
        ...state,
        company: {
          ...state.company,
          ...action.company,
          permissions: permissions,
          isSavingInProgress: false,
        },
      };
    case profileConstants.EDIT_COMPANY_FAILURE:
      return {
        ...state,
        company: {
          ...state.company,
          isSavingInProgress: false,
        },
      };
    case profileConstants.CREATE_COMPANY_REQUEST:
      return {
        ...state,
        company: {
          isSavingInProgress: true,
        },
      };
    case profileConstants.CREATE_COMPANY_FAILURE:
      return {
        ...state,
        company: {
          isSavingInProgress: false,
        },
      };
    // Preferences Reducers
    case profileConstants.PROFILE_PREFERENCE_REQUEST:
      return {
        ...state,
      };
    case profileConstants.PROFILE_PREFERENCE_SUCCESS:
      return {
        ...state,
        preferences: {
          ...action.preferences,
        },
      };
    case profileConstants.PROFILE_PREFERENCE_FAILURE:
      return Object.assign({}, state, {
        preferences: null,
      });
    // Company Reducers
    case profileConstants.COMPANY_FETCH_REQUEST:
      return {
        ...state,
      };
    case profileConstants.COMPANY_FETCH_SUCCESS:
      return {
        ...state,
        isLoading: false,
        company: {
          ...action.company,
          isSavingInProgress: false,
        },
      };
    case profileConstants.COMPANY_FETCH_FAILURE:
      return Object.assign({}, state, {
        company: null,
      });

    case profileConstants.CREATE_PROCESS_REQUEST:
      return {
        ...state,
        company: {
          ...action.company,
          isSavingInProgress: true,
        },
      };

    case profileConstants.CREATE_COMPANY_SUCCESS:
    case profileConstants.CREATE_PROCESS_SUCCESS:
    case profileConstants.CREATE_PROCESS_FAILURE:
      return {
        ...state,
        company: {
          ...action.company,
          isSavingInProgress: false,
        },
      };

    case profileConstants.CREATE_PROCESS_FAILURE:
      return Object.assign({}, state, {
        isLoading: false,
      });

    case profileConstants.CREATE_STANDARD_REQUEST:
    case profileConstants.CREATE_RATING_REQUEST:
    case profileConstants.AGGREGATION_FETCH_REQUEST:
    case profileConstants.IS_EMAIL_SUBSCRIBED_REQUEST:
    case profileConstants.NOTIFICATION_FETCH_REQUEST:
    case profileConstants.NOTIFICATION_READ_REQUEST:
    case profileConstants.ADD_COMPANY_TO_PROFILE_REQUEST:
    case profileConstants.EDIT_PROFILE_REQUEST:
      return Object.assign({}, state, {
        isLoading: true,
      });

    case profileConstants.CREATE_STANDARD_SUCCESS:
    case profileConstants.CREATE_RATING_SUCCESS:
    case profileConstants.AGGREGATION_FETCH_SUCCESS:
    case profileConstants.IS_EMAIL_SUBSCRIBED_SUCCESS:
      return Object.assign({}, state, {
        isLoading: false,
        company: action.company,
      });

    case profileConstants.CREATE_STANDARD_FAILURE:
    case profileConstants.CREATE_RATING_FAILURE:
    case profileConstants.AGGREGATION_FETCH_FAILURE:
    case profileConstants.IS_EMAIL_SUBSCRIBED_FAILURE:
    case profileConstants.NOTIFICATION_FETCH_FAILURE:
    case profileConstants.NOTIFICATION_READ_FAILURE:
    case profileConstants.ADD_COMPANY_TO_PROFILE_FAILURE:
    case profileConstants.ADD_COMPANY_TO_PROFILE_SUCCESS:
      return Object.assign({}, state, {
        isLoading: false,
      });

    case profileConstants.NOTIFICATION_FETCH_SUCCESS:
    case profileConstants.NOTIFICATION_READ_SUCCESS:
      return Object.assign({}, state, {
        notification: action.notificationData,
        isLoading: false,
      });

    // set selected language
    case profileConstants.SWITCH_LANGUAGE_REQUEST:
      return Object.assign({}, state, {
        isLoading: true,
      });
    case profileConstants.SWITCH_LANGUAGE_SUCCESS:
      if (state.user) {
        return Object.assign({}, state, {
          isLoading: false,
          user: {
            ...state.user,
            language: action.language,
          },
        });
      }
    case profileConstants.SWITCH_LANGUAGE_FAILURE:
      return Object.assign({}, state, {
        isLoading: false,
      });
    case profileConstants.DECREMENT_COMPANY_STATS_NEW_QA_MSG_COUNT:
      return {
        ...state,
        user: {
          ...state.user,
          company_stats: {
            ...state.user.company_stats,
            new_qa_messages:
              state.user.company_stats.new_qa_messages - action.count,
          },
        },
      };

    case profileConstants.UPDATE_USER_STATE:
      return Object.assign({}, state, {
        user: {
          ...state.user,
          ...action.user,
        },
      });

    case profileConstants.SET_TOKENS:
      return {
        ...state,
        refresh: action.refresh,
        token: action.access,
      };

    case profileConstants.ADD_USER_IN_COMPANY:
      return {
        ...state,
        company: {
          ...state.company,
          users: [...state.company.users, ...action.users],
        },
      };

    case profileConstants.DECREMENT_COMPANY_STATS_PENDING_CONNECTIONS_COUNT:
      return {
        ...state,
        user: {
          ...state.user,
          company_stats: {
            ...state.user.company_stats,
            pending_connections:
              state.user.company_stats.pending_connections - action.count,
          },
        },
      };

    default:
      return state;
  }
}
