import React from "react";
import { Badge, Box, Divider, Tab, Tabs, Theme } from "@mui/material";
import { createStyles, makeStyles } from "@mui/styles";

export type TabItem = {
  tabName: string | React.ReactNode;
  value?: any;
  hide?: boolean;
  isNew?: boolean;
  badgeContent?: number | string;
  badgeMarginLeft?: string;
  badgeMarginRight?: string;
  pid?: string;
};

type Props = {
  value?: number | string;
  handleChange: (event: React.ChangeEvent<{}>, value: any) => void;
  tabItems: TabItem[];
  centered?: boolean;
  dividerMarginLeft?: string;
  width?: string;
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    tab: {
      textTransform: "none",
      maxWidth: "235px",
      marginRight: theme.spacing(1),
      width: "fit-content",
      minWidth: "max-content",
      opacity: 1,
      color: theme.palette.secondary.main,
      fontWeight: 400,
      "&.Mui-selected": {
        opacity: 1,
        fontWeight: 500,
      },
      paddingLeft: "20px",
      paddingRight: "20px",
    },
    indicator: {
      display: "flex",
      justifyContent: "center",
      backgroundColor: "transparent",
      height: "4px",
      "& > span": {
        maxWidth: 40,
        width: "100%",
        backgroundColor: theme.palette.primary.main,
      },
    },
  })
);

const TabsView: React.FC<Props> = ({
  tabItems,
  value,
  handleChange,
  centered,
  dividerMarginLeft = "-36px",
  width,
}) => {
  const classes = useStyles();

  return (
    <Box width={width}>
      <Tabs
        classes={{ indicator: classes.indicator }}
        value={value}
        onChange={handleChange}
        TabIndicatorProps={{ children: <span /> }}
        centered={centered}
      >
        {tabItems?.map((item, index) => {
          if (item?.hide) {
            return null;
          }

          return (
            <Tab
              value={item.value}
              disableRipple
              key={`${item.value}-${index}`}
              label={
                <Box display="flex">
                  <Box>{item.tabName}</Box>

                  {item?.badgeContent ? (
                    <Box
                      ml={item?.badgeMarginLeft || "18px"}
                      mr={item.badgeMarginRight && item.badgeMarginRight}
                    >
                      <Badge
                        badgeContent={item?.badgeContent}
                        color="primary"
                      />
                    </Box>
                  ) : null}
                </Box>
              }
              className={classes.tab}
              data-pid={item?.pid ? item.pid : null}
            />
          );
        })}
      </Tabs>

      <Divider
        style={{ marginLeft: dividerMarginLeft, marginRight: "-36px" }}
      />
    </Box>
  );
};

export default TabsView;
