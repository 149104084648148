import { RootState } from "store";

export const selectNewsfeed = (state: RootState) => state.newsfeedNext.newsfeed;
export const selectNewsfeedLoading = (state: RootState) =>
  state.newsfeedNext.newsfeedLoading;
export const selectNewsfeedPage = (state: RootState) =>
  state.newsfeedNext.newsfeedPage;
export const selectNewsfeedIsNextPageAvailable = (state: RootState) =>
  state.newsfeedNext.newsfeedIsNextPageAvailable;

export const newsfeedSelectors = {
  selectNewsfeed,
  selectNewsfeedLoading,
  selectNewsfeedPage,
  selectNewsfeedIsNextPageAvailable,
};
