import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { Form, FormikProps } from "formik";
import { createStyles, makeStyles } from "@mui/styles";
import * as yup from "yup";
import {
  Avatar,
  CircularProgress,
  Grid,
  List,
  ListItemButton,
  ListItemText,
  TextField,
  Theme,
  Typography,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";

import { ASLInvitedSupplier } from "./add-asl.types";
import AddASLDetailsForm from "./add-asl-details.form";
import { SearchedCompany, workspaceNextActions } from "../../redux";
import { CircularAvatarsList } from "@next/components/circular-avatars-list";
import {
  selectSearchSuppliersLoading,
  selectSearchSuppliersResults,
  selectSearchSuppliersType,
} from "../../redux/selectors";
import { getModal, modalsActions } from "@next/redux/modalsSlices";
import { WorkspaceModalTypes } from "../../modals/types";
import { emailRegex } from "./add-asl.definitions";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    loading: {
      display: "flex",
      alignItems: "center",
      gap: "8px",
      color: theme.palette.text.secondary,
    },
    loadingText: {
      fontSize: "12px",
    },
    plusIcon: {
      height: 40,
      width: 40,
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      color: "white",
      borderRadius: 4,
      marginRight: 16,
      backgroundColor: theme.palette.primary.main,
    },
    listItem: {
      borderRadius: 8,
    },
    resultsText: {
      color: theme.palette.text.secondary,
    },
  })
);

const AddASLFormBase: React.FC<FormikProps<ASLInvitedSupplier>> = ({
  values,
  setValues,
  handleSubmit,
  ...rest
}) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const { results: searchResults, user: matchedUser } = useSelector(
    selectSearchSuppliersResults
  );
  const searchType = useSelector(selectSearchSuppliersType);
  const searchLoading = useSelector(selectSearchSuppliersLoading);

  const [email, setEmail] = useState("");
  const [error, setError] = useState("");
  const [showDetailsForm, setShowDetailsForm] = useState(false);

  const emailSchema = yup
    .string()
    .matches(emailRegex, t("userOnboarding:emailAddressIsInvalid"))
    .required(t("common:required"));

  const modal = useSelector(getModal(WorkspaceModalTypes.ADD_ASL_MODAL));
  const { onSuccess } = modal?.data;

  const validateEmail = async (email: string) => {
    try {
      await emailSchema.validate(email);
      return true;
    } catch {
      return false;
    }
  };

  const handleEmailChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
    const newEmail = e.target.value;
    setEmail(newEmail);
    const isValid = await validateEmail(newEmail);
    setError(isValid ? "" : t("auth:error:errorEmailInvalid"));
  };

  useEffect(() => {
    if (searchType === "user_buyer") {
      setError(t("workspaceNext:inviteSupplier:error:userAssociatedWithBuyer"));
    }
  }, [searchType]);

  useEffect(() => {
    const fetchSuppliers = async () => {
      if (email) {
        const isValid = await validateEmail(email);
        if (isValid) {
          dispatch(workspaceNextActions.fetchSuppliersRequest(email));
        }
      }
    };
    fetchSuppliers();
  }, [email, dispatch]);

  const isAlreadyInASL = (supplier) =>
    searchType === "user_found" && supplier.is_buyer_approved;

  const handleSupplierSelect = async (supplier: SearchedCompany) => {
    const isEmailValid = await validateEmail(email);
    if (!isEmailValid) {
      return;
    }

    if (isAlreadyInASL(supplier)) {
      dispatch(modalsActions.closeModal(WorkspaceModalTypes.ADD_ASL_MODAL));

      if (typeof onSuccess === "function") {
        onSuccess(searchResults[0].name);
      }
      return;
    }

    let firstName = "";
    let lastName = "";
    let language = "";
    if (searchType === "user_found" || searchType === "non_company_user") {
      firstName = matchedUser.first_name;
      lastName = matchedUser.last_name;
      language = matchedUser.language.toUpperCase();
    }

    setValues({
      email,
      firstName,
      lastName,
      language,
      companyName: supplier.name,
      vendorId: "",
      currency: "",
    });
    setShowDetailsForm(true);
  };

  const handleAddNewSupplier = async () => {
    const isEmailValid = await validateEmail(email);
    if (!isEmailValid) {
      return;
    }

    setValues({
      email,
      firstName: "",
      lastName: "",
      language: "",
      companyName: "",
      vendorId: "",
      currency: "",
    });
    setShowDetailsForm(true);
  };

  const getResultText = () => {
    switch (searchType) {
      case "no_user":
        return t("workspaceNext:inviteSupplier:foundNone");
      case "companies":
        return t("workspaceNext:inviteSupplier:chooseOption");
      case "user_found":
        return t("workspaceNext:inviteSupplier:exactMatch");
      case "non_company_user":
        return t("workspaceNext:inviteSupplier:chooseOption");

      default:
        return "";
    }
  };

  const showCreateNewButton = () =>
    searchType === "no_user" || searchType === "companies";

  return (
    <Form onSubmit={handleSubmit}>
      <Grid container spacing={2}>
        {!showDetailsForm && (
          <>
            <Grid item xs={12}>
              <Typography variant="body2">
                {t("workspaceNext:inviteSupplier:title")}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <TextField
                name="email"
                value={email}
                label={t("workspaceNext:inviteSupplier:labelEmail")}
                onChange={handleEmailChange}
                error={!!error}
                helperText={!!error ? error : ""}
                fullWidth
              />
            </Grid>
            {email && !error && (
              <Grid item xs={12}>
                {searchLoading ? (
                  <div className={classes.loading}>
                    <CircularProgress size={16} />
                    <span className={classes.loadingText}>
                      {t("workspaceNext:inviteSupplier:loading")}
                    </span>
                  </div>
                ) : (
                  <>
                    <Typography variant="body2" className={classes.resultsText}>
                      {getResultText()}
                    </Typography>
                    <List>
                      {searchResults
                        ?.filter(Boolean)
                        .map((supplier: SearchedCompany) => (
                          <ListItemButton
                            key={supplier.pk}
                            className={classes.listItem}
                            onClick={() => handleSupplierSelect(supplier)}
                          >
                            <Avatar
                              src={supplier.picture}
                              sx={{ marginRight: 2 }}
                              variant="rounded"
                            />
                            <ListItemText
                              primary={supplier.name}
                              secondary={
                                supplier.is_buyer_approved
                                  ? t(
                                      "workspaceNext:inviteSupplier:alreadyInList"
                                    )
                                  : ""
                              }
                            />
                            <CircularAvatarsList
                              images={supplier.profiles.map(
                                ({ email, picture }) => ({
                                  name: email,
                                  tooltip: `${email}`,
                                  url: picture,
                                })
                              )}
                              overlapping
                              combinedTooltip
                            />
                          </ListItemButton>
                        ))}
                      {showCreateNewButton() && (
                        <ListItemButton
                          className={classes.listItem}
                          onClick={handleAddNewSupplier}
                        >
                          <div className={classes.plusIcon}>
                            <AddIcon style={{ fontSize: 24 }} />
                          </div>
                          <ListItemText
                            primary={t(
                              "workspaceNext:inviteSupplier:createNew"
                            )}
                          />
                        </ListItemButton>
                      )}
                    </List>
                  </>
                )}
              </Grid>
            )}
          </>
        )}
        {showDetailsForm && (
          <AddASLDetailsForm
            values={values}
            setValues={setValues}
            handleSubmit={handleSubmit}
            searchType={searchType}
            {...rest}
          />
        )}
      </Grid>
    </Form>
  );
};

export default AddASLFormBase;
