import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { ProfileModalTypes } from "./types";
import { useTranslation } from "react-i18next";
import Modal from "@next/components/custom-modal";
import { getModal, modalsActions } from "@next/redux/modalsSlices";
import { TwoFAActionType } from "../redux";
import { TwoFactorManageForm } from "../components/user-profile/user-profile-settings/authentication/two-factor-auth-settings/two-factor-manage-form/two-factor-manage-form";

export const UserProfileTwoFactorManageModal = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const modal = useSelector(
    getModal(ProfileModalTypes.USER_PROFILE_TWO_FACTOR_MANAGE_MODAL)
  );
  const data = modal?.data as {
    actionType?: TwoFAActionType;
  };

  if (!modal) return null;

  const onClose = () => {
    dispatch(
      modalsActions.closeModal(
        ProfileModalTypes.USER_PROFILE_TWO_FACTOR_MANAGE_MODAL
      )
    );
  };

  return (
    <Modal
      handleModalClose={onClose}
      modalState={{
        modalHeader:
          data?.actionType === TwoFAActionType.ACTIVATE
            ? t("profile:userProfile:activateTwoFactorAuthentication")
            : t("profile:userProfile:disableTwoFactorAuthentication"),
        modalBody: (
          <TwoFactorManageForm
            onClose={onClose}
            actionType={data?.actionType}
          />
        ),
      }}
      isModalOpen={true}
    />
  );
};
