/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useMemo, useState } from "react";

import { getSuppliersColumns } from "./suppliers-section-table.columns";

import i18n from "assets/configi18n/i18n";
import { Supplier } from "../suppliers-table/suppliers-table-v5.types";
import { GridColumns, GridRowModel } from "@mui/x-data-grid-pro-v5";

type GridDataState = {
  rows: GridRowModel[];
  columns: GridColumns;
};

export const useSuppliersTableData = (
  suppliers: Supplier[] = [],
  showContactsList?: boolean
) => {
  const [gridData, setGridData] = useState<GridDataState>({
    rows: [],
    columns: [],
  });

  const suppliersColumns = useMemo(
    () => getSuppliersColumns(showContactsList || false),
    [i18n?.language, showContactsList]
  );

  useEffect(() => {
    setGridData({ rows: suppliers, columns: suppliersColumns });
  }, [suppliers, suppliersColumns]);

  return {
    gridData,
    setGridData,
  };
};
