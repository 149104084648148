import {
  Box,
  IconButton,
  Step,
  StepConnector,
  StepIconProps,
  StepLabel,
  Stepper,
  Theme,
  Tooltip,
  Typography,
} from "@mui/material";
import React, { useMemo } from "react";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CheckIcon from "@mui/icons-material/Check";
import ScheduleIcon from "@mui/icons-material/Schedule";
import RadioButtonUncheckedIcon from "@mui/icons-material/RadioButtonUnchecked";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { confirmDialog } from "@next/modals/shared-confirm-dialog";
import { Order, ordersActions } from "../redux";
import { createStyles, makeStyles, withStyles } from "@mui/styles";

const useStyles = makeStyles<Theme>((theme) =>
  createStyles({
    root: {
      padding: "0px",
      width: "100%",
    },
    stepRoot: {
      "&:first-child": {
        paddingLeft: 0,
      },
      display: "flex",
      alignItems: "center",
      "&:last-child": {
        paddingRight: 0,
      },
    },
    completed: {
      color: theme?.palette?.success?.main,
      zIndex: 1,
      fontSize: 24,
    },
    active: {
      color: theme?.palette?.primary?.main,
    },
    next: {
      color: theme?.palette?.text?.disabled,
    },
    iconButton: {
      padding: "8px",
      color: "black",
    },
    stepConnector: {
      width: "80px",
      flex: "unset",
    },
  })
);

const OrderStepperConnector = withStyles(({ palette }) => ({
  active: {
    "& $line": {
      borderColor: palette?.primary?.main,
    },
  },
  completed: {
    "& $line": {
      borderColor: palette?.success?.main,
    },
  },
  line: {
    borderTopWidth: 1,
  },
}))(StepConnector);

const OrderStepperStepIcon = ({ active, completed }: StepIconProps) => {
  const classes = useStyles();

  return completed ? (
    <CheckCircleIcon className={classes.completed} />
  ) : active ? (
    <ScheduleIcon className={classes.active} />
  ) : (
    <RadioButtonUncheckedIcon className={classes.next} />
  );
};

type Props = {
  activeStep: number;
  order?: Order;
  allowStepChange?: boolean;
  lineItemsCount?: number;
  shippedLineItemsCount?: number;
};

export const OrderStepper: React.FC<Props> = ({
  activeStep = -1,
  order,
  allowStepChange,
  lineItemsCount,
  shippedLineItemsCount,
}) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const steps = useMemo(
    () => [
      t("orders:stepper:sent"),
      t("orders:stepper:acknowledged"),
      t(
        "orders:stepper:shipped",
        shippedLineItemsCount && lineItemsCount
          ? {
              count: `${shippedLineItemsCount}/${lineItemsCount}`,
            }
          : undefined
      ),
    ],
    [shippedLineItemsCount, lineItemsCount]
  );

  const onStepComplete = () => {
    confirmDialog(
      t(
        activeStep === 1
          ? "orders:confirmationModal:titleOrder"
          : "orders:confirmationModal:titleShipping",
        { supplierName: order?.supplier?.name }
      ),
      t("orders:confirmationModal:body"),
      () => {
        dispatch(
          ordersActions.updateOrderRequest({
            pk: order?.pk,
            change_state_to: activeStep === 1 ? "acknowledged" : "shipped",
            qa_token: order?.qa_token,
          })
        );
      },
      t("common:confirm"),
      undefined,
      t("common:back")
    );
  };

  return (
    <Stepper
      activeStep={activeStep}
      classes={{ root: classes.root }}
      connector={<OrderStepperConnector className={classes.stepConnector} />}
    >
      {steps?.map((label, ix) => (
        <Step key={label} classes={{ root: classes.stepRoot }}>
          <StepLabel StepIconComponent={OrderStepperStepIcon}>
            <Typography variant="body2">{label}</Typography>
          </StepLabel>

          {allowStepChange && ix === activeStep ? (
            <Box ml={1}>
              <Tooltip title={t("orders:orderDetail:tooltip:completedStep")}>
                <IconButton
                  className={classes.iconButton}
                  onClick={onStepComplete}
                  size="large"
                >
                  <CheckIcon sx={{ width: "21px", height: "21px" }} />
                </IconButton>
              </Tooltip>
            </Box>
          ) : null}
        </Step>
      ))}
    </Stepper>
  );
};
