import { RequestStatus } from "@next/modules/marketplace/redux";
import { marketplaceConstants } from "./index";

const initialState = {
  isLoading: false,
  activeRFQList: [],
  activeRFQListCount: {
    total: 0,
    itb: 0,
    itnb: 0,
  },
  activeRFQListHasNext: false,
  recommendedRFQList: [],
  quoteListOfCompany: null,
  subscriptionsList: null,
  quote: null,
  isRfqDetailsLoading: false,
  activeRfqDetails: {},
  hiddenRFQList: [],
  isHiddenRFQsLoading: false,
  statsList: {},
  statsLoading: false,
  statsError: [],
  activeRfqDetailsError: [],
  selectedTab: 1,
  sellerNdaAttachments: [],
  rfqHasNda: false,
  isRfqDeatilsLoading: true,
  NDAStepperIndex: 0,
  buyerStats: {},
  buyerStatsLoading: false,
  intentToBidRFQList: [],
  isIntentToBidRFQsLoading: false,
  supplierActiveStep: 0,
  supplierStepper: {
    companyInformation: {},
  },
};

export function marketplace(state = initialState, action) {
  switch (action.type) {
    /**FETCH_ACTIVE_RFQLIST_REQUEST */
    case marketplaceConstants.FETCH_ACTIVE_RFQLIST_REQUEST:
      return { ...state, isLoading: true };

    case marketplaceConstants.FETCH_ACTIVE_RFQLIST_SUCCESS:
      return {
        ...state,
        isLoading: false,
        activeRFQList: [...action.activeRFQList],
        activeRFQListCount: action.count
          ? action.count
          : state.activeRFQListCount,
        activeRFQListHasNext: !!action.next,
      };

    case marketplaceConstants.FETCH_ACTIVE_RFQLIST_FAILURE:
      return { ...state, isLoading: false };

    /**FETCH_RECOMMENDED_RFQLIST_REQUEST */
    case marketplaceConstants.FETCH_RECOMMENDED_RFQLIST_REQUEST:
      return { ...state, isLoading: true };

    case marketplaceConstants.FETCH_RECOMMENDED_RFQLIST_SUCCESS:
      return {
        ...state,
        isLoading: false,
        recommendedRFQList: action.recommendedRFQList,
      };

    case marketplaceConstants.FETCH_RECOMMENDED_RFQLIST_FAILURE:
      return { ...state, isLoading: false };

    /** CREATE_QUOTATION_REQUEST*/
    case marketplaceConstants.CREATE_QUOTATION_REQUEST:
      return { ...state, isLoading: true };

    case marketplaceConstants.CREATE_QUOTATION_SUCCESS:
      return { ...state, isLoading: false, quote: action.quote };

    case marketplaceConstants.CREATE_QUOTATION_FAILURE:
      return { ...state, isLoading: false };

    /** FETCH_COMPANY_QUOTATIONS_REQUEST*/
    case marketplaceConstants.FETCH_COMPANY_QUOTATIONS_REQUEST:
      return {
        ...state,
        isLoading: true,
      };

    case marketplaceConstants.FETCH_COMPANY_QUOTATIONS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        quoteListOfCompany: {
          ...state.quoteListOfCompany,
          [action.quoteListOfCompany.status]: action.quoteListOfCompany.data,
        },
      };

    case marketplaceConstants.FETCH_COMPANY_QUOTATIONS_FAILURE:
      return { ...state, isLoading: false };

    /** FETCH_COMPANY_CONTRACTS_REQUEST*/
    case marketplaceConstants.FETCH_COMPANY_CONTRACTS_REQUEST:
      return { ...state, isLoading: true };

    case marketplaceConstants.FETCH_COMPANY_CONTRACTS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        contractListOfCompany: action.contractListOfCompany,
      };

    case marketplaceConstants.FETCH_COMPANY_CONTRACTS_FAILURE:
      return { ...state, isLoading: false };

    /** FETCH_SUBSCRIPTIONS_REQUEST*/
    case marketplaceConstants.FETCH_SUBSCRIPTIONS_REQUEST:
      return { ...state, isLoading: true };

    case marketplaceConstants.FETCH_SUBSCRIPTIONS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        subscriptionsList: action.subscriptionsList,
      };

    case marketplaceConstants.FETCH_SUBSCRIPTIONS_FAILURE:
      return { ...state, isLoading: false };

    /** TOGGLE_USER_SUBSCRIPTION_REQUEST*/
    case marketplaceConstants.TOGGLED_SUB_INFO_REQUEST:
      return { ...state, isLoading: true };

    case marketplaceConstants.TOGGLED_SUB_INFO_SUCCESS:
      // find the subscription to post or delete in the subscriptions list
      const toggleSub = state.subscriptionsList.find(
        (sub) => sub.pk === action.toggledSubInfo.subPk.sub_pk
      );

      // get index of subscription to post or  delete
      const index = state.subscriptionsList.indexOf(toggleSub);

      //update the subscriptions list
      state.subscriptionsList[index]["is_user_subscribed"] =
        action.toggledSubInfo.isUserSubscribed;

      return {
        ...state,
        isLoading: false,
      };

    case marketplaceConstants.TOGGLED_SUB_INFO_FAILURE:
      return { ...state, isLoading: false };

    /**FETCH_ACTIVE_RFQ_DETAILS_REQUEST */
    case marketplaceConstants.FETCH_ACTIVE_RFQ_DETAILS_REQUEST:
      return { ...state, isRfqDetailsLoading: true, activeRfqDetailsError: [] };

    case marketplaceConstants.FETCH_ACTIVE_RFQ_DETAILS_SUCCESS:
      return {
        ...state,
        isRfqDetailsLoading: false,
        activeRfqDetails: action.activeRfqDetails,
        activeRfqDetailsError: [],
      };

    case marketplaceConstants.FETCH_ACTIVE_RFQ_DETAILS_FAILURE:
      return {
        ...state,
        isRfqDetailsLoading: false,
        activeRfqDetailsError: action.error,
      };

    // clear activeRfqDetails modal close
    case marketplaceConstants.CLEAR_ACTIVE_RFQ_DETAILS:
      return {
        ...state,
        isRfqDetailsLoading: false,
        activeRfqDetails: [],
        activeRfqDetailsError: [],
      };

    // fetch hidden rfq list
    case marketplaceConstants.FETCH_HIDDEN_RFQLIST_REQUEST:
      return { ...state, isHiddenRFQsLoading: true };

    case marketplaceConstants.FETCH_HIDDEN_RFQLIST_SUCCESS:
      return {
        ...state,
        isHiddenRFQsLoading: false,
        hiddenRFQList: [...action.hiddenRFQList],
      };

    case marketplaceConstants.FETCH_HIDDEN_RFQLIST_FAILURE:
      return { ...state, isHiddenRFQsLoading: false };

    /**MARKETPLACE_PREFERENCES_REQUEST*/
    case marketplaceConstants.MARKETPLACE_HIDE_SHOW_PREFERENCES_REQUEST:
      return { ...state };

    case marketplaceConstants.MARKETPLACE_HIDE_SHOW_PREFERENCES_FAILURE:
      return { ...state, isLoading: false };

    /**MARKETPLACE_LIKE_DISLIKE_RECOMMENDED_REQUEST*/
    case marketplaceConstants.MARKETPLACE_LIKE_DISLIKE_RECOMMENDED_REQUEST:
      return { ...state };

    case marketplaceConstants.MARKETPLACE_LIKE_DISLIKE_RECOMMENDED_SUCCESS:
      return {
        ...state,
        recommendedRFQList: state.recommendedRFQList.map((recommendedRFQ) =>
          recommendedRFQ?.pk === action.payload?.rfqId
            ? {
                ...recommendedRFQ,
                liked_recommendation: action.payload?.liked_recommendation,
              }
            : recommendedRFQ
        ),
      };

    case marketplaceConstants.MARKETPLACE_LIKE_DISLIKE_RECOMMENDED_FAILURE:
      return { ...state, isLoading: false };

    /* FETCH_STATS_REQUEST */
    case marketplaceConstants.FETCH_STATS_REQUEST:
      return { ...state, statsLoading: true };

    case marketplaceConstants.FETCH_STATS_SUCCESS:
      return {
        ...state,
        statsLoading: false,
        statsList: action.stats,
        statsError: [],
      };

    case marketplaceConstants.FETCH_STATS_FAILURE:
      return {
        ...state,
        statsLoading: false,
        statsError: action.error,
        statsList: {},
      };

    case marketplaceConstants.SET_SELECTED_TAB_IN_SUBMISSIONS:
      return {
        ...state,
        selectedTab: action.selectedTab,
      };

    case marketplaceConstants.SET_SELLER_NDA_ATTACHMENTS:
      return {
        ...state,
        sellerNdaAttachments: [...action.attachments],
      };

    case marketplaceConstants.SET_RFQ_HAS_NDA:
      return { ...state, rfqHasNda: action.hasNda };

    case marketplaceConstants.SET_RFQ_DETAILS_LOADING:
      return { ...state, isRfqDeatilsLoading: action.isRfqDeatilsLoading };

    case marketplaceConstants.SET_NDA_STEPPER_INDEX:
      return { ...state, NDAStepperIndex: action.stepIndex };

    /** FETCH_SUBSCRIPTIONS_REQUEST*/
    case marketplaceConstants.GET_BUYER_STATS_BY_RFQ_ID_REQUEST:
      return { ...state, buyerStatsLoading: true };

    case marketplaceConstants.GET_BUYER_STATS_BY_RFQ_ID_SUCCESS:
      return {
        ...state,
        buyerStatsLoading: false,
        buyerStats: action.buyerStats,
      };

    case marketplaceConstants.GET_BUYER_STATS_BY_RFQ_ID_FAILURE:
      return { ...state, buyerStatsLoading: false };

    // fetch intent to bid rfq list
    case marketplaceConstants.FETCH_INTENT_TO_BID_RFQLIST_REQUEST:
      return { ...state, isIntentToBidRFQsLoading: true };

    case marketplaceConstants.FETCH_INTENT_TO_BID_RFQLIST_SUCCESS:
      return {
        ...state,
        isIntentToBidRFQsLoading: false,
        intentToBidRFQList: [...action.intentToBidRFQList],
      };

    case marketplaceConstants.FETCH_INTENT_TO_BID_RFQLIST_FAILURE:
      return { ...state, isIntentToBidRFQsLoading: false };

    case marketplaceConstants.SET_ITNB_REASON_DATA:
      if (state.activeRfqDetails.pk === action.noQuoteData.rfqPk) {
        state.activeRfqDetails = {
          ...state.activeRfqDetails,
          itnb_reason: action.noQuoteData.slug,
          itnb_reason_title: action.noQuoteData.title,
          itnb_reason_description: action.noQuoteData.description,
        };
      }
      return { ...state };

    case marketplaceConstants.INCREMENT_RFQ_QUESTIONS_COUNT:
      return {
        ...state,
        activeRfqDetails: {
          ...state.activeRfqDetails,
          questions_count: state.activeRfqDetails.questions_count + 1,
        },
      };

    case marketplaceConstants.DECREMENT_RFQ_NEW_QA_MSG_COUNT:
      return {
        ...state,
        activeRfqDetails: {
          ...state.activeRfqDetails,
          unseen_questions_count:
            (state.activeRfqDetails?.unseen_questions_count || action.count) -
            action.count,
        },
      };

    case marketplaceConstants.ITN_ITNB_SUCCESS:
      const { rfqId, itbItnbType, deletion, status } = action.payload;

      if (deletion) {
        return {
          ...state,
          activeRFQList:
            status === RequestStatus.ALL
              ? state.activeRFQList.map((rfq) => {
                  if (rfq.pk === rfqId) {
                    return {
                      ...rfq,
                      itnb_reason:
                        itbItnbType === "itb" ? rfq.itnb_reason : null,
                      intent_to_bid_due_date:
                        itbItnbType === "itb"
                          ? null
                          : rfq.intent_to_bid_due_date,
                    };
                  } else {
                    return rfq;
                  }
                })
              : state.activeRFQList.filter((rfq) => rfq.pk !== rfqId),
          activeRFQListCount: {
            ...state.activeRFQListCount,
            itb:
              itbItnbType === "itb"
                ? state.activeRFQListCount.itb - 1
                : state.activeRFQListCount.itb,
            itnb:
              itbItnbType === "itnb"
                ? state.activeRFQListCount.itnb - 1
                : state.activeRFQListCount.itnb,
          },
        };
      } else {
        return {
          ...state,
          activeRFQList: state.activeRFQList.map((rfq) => {
            if (rfq.pk === rfqId) {
              return {
                ...rfq,
                itnb_reason: itbItnbType === "itb" ? rfq.itnb_reason : true,
                intent_to_bid_due_date:
                  itbItnbType === "itb" ? true : rfq.intent_to_bid_due_date,
              };
            } else {
              return rfq;
            }
          }),
          activeRFQListCount: {
            ...state.activeRFQListCount,
            itb:
              itbItnbType === "itb"
                ? state.activeRFQListCount.itb + 1
                : state.activeRFQListCount.itb,
            itnb:
              itbItnbType === "itnb"
                ? state.activeRFQListCount.itnb + 1
                : state.activeRFQListCount.itnb,
          },
        };
      }

    default:
      return state;
  }
}
