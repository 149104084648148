import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { GridColumns } from "@mui/x-data-grid-pro";
import { getRfqStatuses } from "services/part-configuration/part-configuration.selectors";
import { projectActions } from "@next/modules/project/redux";
import {
  selectProjectCompanyOrders,
  selectProjectCompanyOrdersLoading,
} from "@next/modules/project/redux/selectors";
import { useGridData } from "@next/hooks/useGridData";

export const useOrderTableData = (
  _columns: GridColumns,
  paginatedQuery: string
) => {
  const dispatch = useDispatch();
  const companyOrders = useSelector(selectProjectCompanyOrders, shallowEqual);
  const companyOrdersLoading = useSelector(
    selectProjectCompanyOrdersLoading,
    shallowEqual
  );
  const rfqStatuses = useSelector(getRfqStatuses);

  const { gridData } = useGridData({
    rows: companyOrders,
    columns: _columns,
    loading: companyOrdersLoading || !rfqStatuses,
  });

  useEffect(() => {
    dispatch(
      projectActions.fetchProjectCompanyOrdersRequest({
        query: paginatedQuery,
      })
    );

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [paginatedQuery]);

  return {
    gridData,
  };
};
