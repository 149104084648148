import {
  GridCellParams,
  GridSortItem,
  GridSortModel,
} from "@mui/x-data-grid-pro-v5";
import { DataGridProV5 } from "@next/components/data-grid-pro-v5";

import { PAGINATION_PAGE_SIZE } from "@next/constants";
import { getRowIdToPk } from "@next/utils/dataGridUtils-v5";
import { generatePaginationAndSearchQuery } from "@next/utils/paginationUtils-v5";
import { history } from "helpers/history";
import React, { useState } from "react";
import { useSelector } from "react-redux";
import { frontendUrl } from "urls";
import {
  selectContractsCount,
  selectContractsLoading,
} from "../../redux/selectors";
import { useContractsTableData } from "./contracts-table.hooks";
import {
  ContractsTableField,
  ContractsTableSortField,
} from "./contracts-table.types";

interface ContractsTableProps {
  isCompleted: boolean;
}

const ContractsTable: React.FC<ContractsTableProps> = ({ isCompleted }) => {
  const [currentPage, setCurrentPage] = useState(1);
  const [sortQueryString, setSortQueryString] = useState<string>("");
  const [sortModel, setSortModel] = useState<GridSortModel>([
    {
      field: ContractsTableField.AWARDED_ON,
      sort: "desc",
    },
    {
      field: ContractsTableField.BUYER,
      sort: null,
    },
  ]);
  const contractsLoading = useSelector(selectContractsLoading);
  const contractsCount = useSelector(selectContractsCount);
  const { gridData } = useContractsTableData({
    paginatedQuery: `is_contract_completed=${isCompleted}&${generatePaginationAndSearchQuery(
      {
        currentPage,
        sortQueryString,
      }
    )}`,
  });

  const onCellClick = (params: GridCellParams) => {
    if (
      params?.field !== ContractsTableField.PURCHASE_ORDER &&
      params?.field !== ContractsTableField.RATING &&
      params?.field !== ContractsTableField.COST_AND_LEAD_TIME
    ) {
      history.push(`${frontendUrl.supplierContracts}/view/${params.row.pk}`);
    }
  };

  const handleSortChange = (model: GridSortModel) => {
    if (JSON.stringify(model) !== JSON.stringify(sortModel)) {
      // Use `-` for asc ordering and no operator for desc order
      // The final querystring will look like the following:
      // ordering=<sortOperator><fieldName>,<sortOperator><fieldName>,etc...
      // e.g: ordering=-winner,winner_date which sorts
      // by reverse order (Asc) and winner_date ascending.
      const sortOptions = model.map((m: GridSortItem) => {
        const sortByOperator = m.sort === "asc" ? "-" : "";
        let _field = m.field;
        // For winner, we need to use winner__name in order to filter by winner company name
        if (m.field === ContractsTableField.BUYER) {
          _field = ContractsTableSortField.BUYER_NAME;
        }
        return `${sortByOperator}${_field}`;
      });
      setSortModel(model);
      setSortQueryString(`ordering=${sortOptions.join(",")}`);
    }
  };

  return (
    <div>
      <DataGridProV5
        autoHeight
        autoPageSize
        pagination
        paginationMode="server"
        // sortingMode="server"
        // sortModel={sortModel}
        // onSortModelChange={handleSortChange}
        pageSize={PAGINATION_PAGE_SIZE}
        rowCount={contractsCount}
        onPageChange={(newPage) => setCurrentPage(newPage + 1)}
        getRowId={getRowIdToPk}
        disableSelectionOnClick
        rowHeight={60}
        loading={contractsLoading}
        onCellClick={onCellClick}
        getRowClassName={() => "c-cursor-pointer"}
        rows={gridData?.rows || []}
        columns={gridData?.columns || []}
      />
    </div>
  );
};

export default ContractsTable;
