import {
  Box,
  FormControlLabel,
  Radio,
  RadioGroup,
  Typography,
} from "@mui/material";
import { useQuery } from "@next/hooks/useQuery";
import React, { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import { profileNextActions } from "../../redux";
import { createStyles, makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      position: "absolute",
      top: "50vh",
      left: "50vw",
      transform: "translate(-50%, -50%)",
      padding: theme.spacing(4),
      backgroundColor: theme.palette.common.white,
      borderRadius: theme.spacing(3),
      boxShadow: theme.shadows[3],
      minWidth: 420,
    },
  })
);

const PublicPreferences: React.FC = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { token } = useParams<{ token?: string }>();
  const [query] = useQuery();
  const { email } = query;

  const handleChange = useCallback(
    (_, val) => {
      if (token)
        dispatch(
          profileNextActions.updateProfilePreferencesRequest({
            token,
            language: val,
          })
        );
    },
    [token]
  );

  return (
    <Box className={classes.root}>
      {email ? (
        <Box mb={2}>
          <Typography variant="body2">{email}</Typography>
        </Box>
      ) : null}
      <Box mb="9px">
        <Typography variant="h6">{t("publicPreferences:title")}</Typography>
      </Box>
      <Box mb="-9px">
        <RadioGroup onChange={handleChange}>
          <FormControlLabel
            value={"en"}
            control={<Radio size="small" color="primary" />}
            label={
              <Typography variant="body2">
                {t("publicPreferences:english")}
              </Typography>
            }
          />
          <FormControlLabel
            value={"fr"}
            control={<Radio size="small" color="primary" />}
            label={
              <Typography variant="body2">
                {t("publicPreferences:french")}
              </Typography>
            }
          />
        </RadioGroup>
      </Box>
    </Box>
  );
};

export default PublicPreferences;
