export const profileConstants = {
  // Authentication
  REGISTER_REQUEST: "USER_REGISTER_REQUEST",
  REGISTER_SUCCESS: "USER_REGISTER_SUCCESS",
  REGISTER_FAILURE: "USER_REGISTER_FAILURE",

  LOGIN_REQUEST: "USER_LOGIN_REQUEST",
  LOGIN_SUCCESS: "USER_LOGIN_SUCCESS",
  LOGIN_FAILURE: "USER_LOGIN_FAILURE",

  RESET_LOGIN_STATE: "RESET_LOGIN_STATE",

  RECOVER_REQUEST: "USER_RECOVER_REQUEST",
  RECOVER_SUCCESS: "USER_RECOVER_SUCCESS",
  RECOVER_FAILURE: "USER_RECOVER_FAILURE",

  RESET_REQUEST: "USER_RESET_REQUEST",
  RESET_SUCCESS: "USER_RESET_SUCCESS",
  RESET_FAILURE: "USER_RESET_FAILURE",

  REFRESH_REQUEST: "USER_REFRESH_REQUEST",
  REFRESH_SUCCESS: "USER_REFRESH_SUCCESS",
  REFRESH_FAILURE: "USER_REFRESH_FAILURE",

  RESEND_VERIFICATION_REQUEST: "RESEND_VERIFICATION_REQUEST",
  RESEND_VERIFICATION_SUCCESS: "RESEND_VERIFICATION_SUCCESS",
  RESEND_VERIFICATION_FAILURE: "RESEND_VERIFICATION_FAILURE",

  LOGOUT_REQUEST: "USER_LOGOUT_REQUEST",
  LOGOUT_SUCCESS: "USER_LOGOUT_SUCCESS",
  LOGOUT_FAILURE: "USER_LOGOUT_FAILURE",

  USER_PROFILE_REQUEST: "USER_PROFILE_REQUEST",
  USER_PROFILE_SUCCESS: "USER_PROFILE_SUCCESS",
  USER_PROFILE_FAILURE: "USER_PROFILE_FAILURE",

  // Profile Preference management
  PROFILE_PREFERENCE_REQUEST: "PROFILE_PREFERENCE_REQUEST",
  PROFILE_PREFERENCE_SUCCESS: "PROFILE_PREFERENCE_SUCCESS",
  PROFILE_PREFERENCE_FAILURE: "PROFILE_PREFERENCE_FAILURE",

  // Profile Management
  EDIT_PROFILE_REQUEST: "EDIT_PROFILE_REQUEST",
  EDIT_PROFILE_SUCCESS: "EDIT_PROFILE_SUCCESS",
  EDIT_PROFILE_FAILURE: "EDIT_PROFILE_FAILURE",

  CHANGE_PASSWORD_REQUEST: "CHANGE_PASSWORD_REQUEST",
  CHANGE_PASSWORD_SUCCESS: "CHANGE_PASSWORD_SUCCESS",
  CHANGE_PASSWORD_FAILURE: "CHANGE_PASSWORD_FAILURE",

  // Company related
  CREATE_COMPANY_REQUEST: "CREATE_COMPANY_REQUEST",
  CREATE_COMPANY_SUCCESS: "CREATE_COMPANY_SUCCESS",
  CREATE_COMPANY_FAILURE: "CREATE_COMPANY_FAILURE",

  EDIT_COMPANY_REQUEST: "EDIT_COMPANY_REQUEST",
  EDIT_COMPANY_SUCCESS: "EDIT_COMPANY_SUCCESS",
  EDIT_COMPANY_FAILURE: "EDIT_COMPANY_FAILURE",

  COMPANY_FETCH_REQUEST: "COMPANY_FETCH_REQUEST",
  COMPANY_FETCH_SUCCESS: "COMPANY_FETCH_SUCCESS",
  COMPANY_FETCH_FAILURE: "COMPANY_FETCH_FAILURE",

  //Add process to company
  CREATE_PROCESS_REQUEST: "CREATE_PROCESS_REQUEST",
  CREATE_PROCESS_SUCCESS: "CREATE_PROCESS_SUCCESS",
  CREATE_PROCESS_FAILURE: "CREATE_PROCESS_FAILURE",

  CREATE_STANDARD_REQUEST: "CREATE_STANDARD_REQUEST",
  CREATE_STANDARD_SUCCESS: "CREATE_STANDARD_SUCCESS",
  CREATE_STANDARD_FAILURE: "CREATE_STANDARD_FAILURE",

  //Add rating to company
  CREATE_RATING_REQUEST: "CREATE_RATING_REQUEST",
  CREATE_RATING_SUCCESS: "CREATE_RATING_SUCCESS",
  CREATE_RATING_FAILURE: "CREATE_RATING_FAILURE",
  //Fetch aggregation to company
  AGGREGATION_FETCH_REQUEST: "AGGREGATION_FETCH_REQUEST",
  AGGREGATION_FETCH_SUCCESS: "AGGREGATION_FETCH_SUCCESS",
  AGGREGATION_FETCH_FAILURE: "AGGREGATION_FETCH_FAILURE",

  IS_EMAIL_SUBSCRIBED_REQUEST: "IS_EMAIL_SUBSCRIBED_REQUEST",
  IS_EMAIL_SUBSCRIBED_SUCCESS: "IS_EMAIL_SUBSCRIBED_SUCCESS",
  IS_EMAIL_SUBSCRIBED_FAILURE: "IS_EMAIL_SUBSCRIBED_FAILURE",

  NOTIFICATION_FETCH_REQUEST: "NOTIFICATION_FETCH_REQUEST",
  NOTIFICATION_FETCH_SUCCESS: "NOTIFICATION_FETCH_SUCCESS",
  NOTIFICATION_FETCH_FAILURE: "NOTIFICATION_FETCH_FAILURE",

  NOTIFICATION_READ_REQUEST: "NOTIFICATION_FETCH_REQUEST",
  NOTIFICATION_READ_SUCCESS: "NOTIFICATION_FETCH_SUCCESS",
  NOTIFICATION_READ_FAILURE: "NOTIFICATION_FETCH_FAILURE",

  ADD_FILE_TO_WORKSPACE_REQUEST: "ADD_FILE_TO_WORKSPACE_REQUEST",
  ADD_FILE_TO_WORKSPACE_SUCCESS: "ADD_FILE_TO_WORKSPACE_SUCCESS",
  ADD_FILE_TO_WORKSPACE_FAILURE: "ADD_FILE_TO_WORKSPACE_FAILURE",

  DELETE_FILE_FROM_WORKSPACE_REQUEST: "DELETE_FILE_FROM_WORKSPACE_REQUEST",
  DELETE_FILE_FROM_WORKSPACE_SUCCESS: "DELETE_FILE_FROM_WORKSPACE_SUCCESS",
  DELETE_FILE_FROM_WORKSPACE_FAILURE: "DELETE_FILE_FROM_WORKSPACE_FAILURE",

  ADD_COMPANY_TO_PROFILE_REQUEST: "ADD_COMPANY_TO_PROFILE_REQUEST",
  ADD_COMPANY_TO_PROFILE_FAILURE: "ADD_COMPANY_TO_PROFILE_FAILURE",
  ADD_COMPANY_TO_PROFILE_SUCCESS: "ADD_COMPANY_TO_PROFILE_SUCCESS",

  // language switch
  SWITCH_LANGUAGE_REQUEST: "SWITCH_LANGUAGE_REQUEST",
  SWITCH_LANGUAGE_SUCCESS: "SWITCH_LANGUAGE_SUCCESS",
  SWITCH_LANGUAGE_FAILURE: "SWITCH_LANGUAGE_FAILURE",

  DECREMENT_COMPANY_STATS_NEW_QA_MSG_COUNT:
    "DECREMENT_COMPANY_STATS_NEW_QA_MSG_COUNT",

  UPDATE_USER_STATE: "UPDATE_USER_STATE",

  SET_TOKENS: "SET_TOKENS",

  ADD_USER_IN_COMPANY: "ADD_USER_IN_COMPANY",

  DECREMENT_COMPANY_STATS_PENDING_CONNECTIONS_COUNT:
    "DECREMENT_COMPANY_STATS_PENDING_CONNECTIONS_COUNT",
};
