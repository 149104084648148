import { Box, Chip, Theme, Typography } from "@mui/material";
import React from "react";
import { TopicMessageType } from "@next/modules/workspace/redux";
import { useTranslation } from "react-i18next";
import QuestionAnswerIcon from "@mui/icons-material/QuestionAnswer";
import { createStyles, makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    bold: {
      fontWeight: "bold",
    },
    name: {},
  })
);

type Props = {
  createdByFullName: TopicMessageType["created_by"]["full_name"];
  companyName: TopicMessageType["company"]["name"];
  isPrivate?: boolean;
  messagesCount?: number;
  isEventMessage?: boolean;
  messageBody?: TopicMessageType["body"];
};

const TopicSubTitle: React.FC<Props> = ({
  createdByFullName,
  companyName,
  isPrivate,
  messagesCount,
  isEventMessage,
  messageBody,
}) => {
  const { t } = useTranslation();
  const classes = useStyles();

  return (
    <div>
      {isEventMessage ? (
        <Box ml={"8px"}>
          <Typography className={classes.bold} variant="caption">
            {`${createdByFullName} ${messageBody}`}
          </Typography>
        </Box>
      ) : (
        <>
          <Typography className={classes.name} variant="caption">
            {createdByFullName}
          </Typography>
          {companyName ? " · " : null}
        </>
      )}

      {isEventMessage ? null : (
        <Typography
          className={classes.name}
          variant="caption"
          color="textSecondary"
        >
          {companyName}
        </Typography>
      )}

      {isPrivate !== undefined ? (
        <Box ml="8px" display="inline">
          <Chip
            label={
              isPrivate
                ? t("workspaceNext:QA:private")
                : t("workspaceNext:QA:public")
            }
            size="small"
            variant="outlined"
          />
        </Box>
      ) : null}

      {messagesCount !== undefined ? (
        <Box ml="8px" display="inline">
          <Chip
            label={messagesCount}
            size="small"
            variant="outlined"
            icon={<QuestionAnswerIcon style={{ marginLeft: 8 }} />}
          />
        </Box>
      ) : null}
    </div>
  );
};

export default TopicSubTitle;
