import React from "react";

// Material ui imports
import IconButton from "@mui/material/IconButton";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import MenuItem from "@mui/material/MenuItem";
import { CustomizedMenus } from "../../../utils/customized-menus/customized-menus.component";

export const PartActions = ({
  onClickDeletePart,
  onClickOpenPartDialog,
  handleClick,
  open,
  anchorEl,
  t,
  onClickClonePart,
  onClickAddTagHandler,
  hideAddTagMenuItem = false,
  hideEditPartMenuItem = false,
  hideClonePartMenuItem = false,
  hideDeletePartMenuItem = false,
}) => {
  return (
    <div>
      <CustomizedMenus
        anchorEl={anchorEl}
        isMenuOpen={open}
        handleMenuClose={!open}
        anchorElement={
          <IconButton
            aria-label="more"
            aria-controls="long-menu"
            aria-haspopup="true"
            onClick={handleClick}
            size="large"
          >
            <MoreHorizIcon />
          </IconButton>
        }
        menuItems={
          <div>
            {!hideAddTagMenuItem && (
              <MenuItem onClick={onClickAddTagHandler}>
                {" "}
                {t("addTag")}{" "}
              </MenuItem>
            )}
            {!hideEditPartMenuItem && (
              <MenuItem onClick={onClickOpenPartDialog}>{t("edit")}</MenuItem>
            )}
            {!hideClonePartMenuItem && (
              <MenuItem onClick={onClickClonePart}>{t("duplicate")}</MenuItem>
            )}
            {!hideDeletePartMenuItem && (
              <MenuItem onClick={onClickDeletePart}>{t("delete")}</MenuItem>
            )}
          </div>
        }
      />
    </div>
  );
};
