import React from "react";

import { useTranslation } from "react-i18next";
import {
  Box,
  Grid,
  Grow,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Typography,
} from "@mui/material";
import { useQuotationViewData } from "components/marketplace/quotation-view/quotation-view-hooks";
import { QuotationExtraFieldsTable } from "@next/modules/marketplace/components/quotation/quotation-extra-fields-table";
import { LeadTime } from "@next/components/lead-time/lead-time";

import { SupplierAvatar } from "components/inbox/supplier-avatar";
import { InstigatorName } from "./instigator-name";

const QuotationUpdateMessage = ({
  activeQuotationThread,
  quotationCreatedAt,
  message,
  id,
}) => {
  const { t } = useTranslation();

  const { quotationViewData, isQuotationExtraFieldsAvailable } =
    useQuotationViewData({ isMessenger: true });

  function createData(name, oldValue, newValue) {
    return { name, oldValue, newValue };
  }

  const content = message.content || "{}";

  const messageContent = JSON.parse(content);

  const oldLeadTime =
    messageContent?.lead_time && messageContent?.lead_time?.old_value;

  const newLeadTime =
    messageContent?.lead_time && messageContent?.lead_time?.new_value;

  const oldToTalPrice =
    messageContent?.total_price && messageContent?.total_price?.old_value;
  const newToTalPrice =
    messageContent?.total_price && messageContent?.total_price?.new_value;
  const oldFixedFees =
    messageContent?.fixed_charge && messageContent?.fixed_charge?.old_value;
  const newFixedFees =
    messageContent?.fixed_charge && messageContent?.fixed_charge?.new_value;

  const rows = [
    createData(t("inbox:fixedFees"), oldFixedFees, newFixedFees),
    createData(t("inbox:total"), oldToTalPrice, newToTalPrice),
    createData(t("inbox:productionDelay"), oldLeadTime, newLeadTime),
  ];

  return (
    <>
      {
        <Grow in>
          <Box
            id={id}
            flex="0 0 auto"
            my={1}
            display="flex"
            flexDirection="column"
            className="quotation_update"
          >
            <Box flexGrow={1} display="flex" p={2}>
              {activeQuotationThread && (
                <>
                  <SupplierAvatar
                    instigator={activeQuotationThread?.instigator}
                  />

                  <Grid
                    className="c-chat-dialog--instigator-name"
                    container
                    direction="column"
                  >
                    <InstigatorName
                      name={message?.company?.name}
                      time={quotationCreatedAt}
                      companyUUID={activeQuotationThread?.instigator?.uuid}
                    />
                    <Typography
                      variant="body2"
                      className="c-chat-dialog--instigator-status"
                    >
                      {t("inbox:quotationUpdate")}
                    </Typography>
                    {isQuotationExtraFieldsAvailable ? (
                      <Box className="c-message--quotation-details">
                        <QuotationExtraFieldsTable
                          activeQuote={{
                            parts: activeQuotationThread?.quotation?.parts,
                            quotation_extra_fields:
                              quotationViewData?.quotationExtraFields,
                          }}
                          partExtraFields={quotationViewData?.partExtraFields}
                        />
                      </Box>
                    ) : (
                      <Table
                        size="small"
                        aria-label="a dense table"
                        className="c-quotation-update-message"
                      >
                        <TableBody>
                          {rows.map((row) => (
                            <>
                              {row.oldValue && row.newValue && (
                                <TableRow
                                  key={row.name}
                                  className="update-message-table-row"
                                >
                                  <TableCell component="th" scope="row">
                                    {row?.name}
                                  </TableCell>
                                  <TableCell align="center">
                                    {row?.name ===
                                    t("inbox:productionDelay") ? (
                                      <s>
                                        <LeadTime value={row?.oldValue} />
                                      </s>
                                    ) : (
                                      <s>{row?.oldValue}</s>
                                    )}
                                  </TableCell>
                                  <TableCell align="right">
                                    {row.name === t("inbox:productionDelay") ? (
                                      <LeadTime value={row?.newValue} />
                                    ) : (
                                      <span>{row?.newValue}</span>
                                    )}
                                  </TableCell>
                                </TableRow>
                              )}
                            </>
                          ))}
                        </TableBody>
                      </Table>
                    )}
                  </Grid>
                </>
              )}
            </Box>
          </Box>
        </Grow>
      }
    </>
  );
};

export default QuotationUpdateMessage;
