import {
  FormControl,
  MenuItem,
  Select,
  SelectChangeEvent,
} from "@mui/material";
import React from "react";

type Props = {
  specType: string | string[];
  isInA2psProcessControlForm?: boolean;
  handleChange: (event: SelectChangeEvent<string | string[]>) => void;
};

const menuItemValues = ["A2ERD", "A2LS", "A2MS", "A2NDE", "A2PS", "A2TS", "A0"];
const menuItemValuesForA2psProcessControlForm = ["A2ERD", "A2TS"];

export const SelectSpecType: React.FC<Props> = ({
  specType,
  isInA2psProcessControlForm,
  handleChange,
}) => {
  return (
    <FormControl>
      <Select
        id="spec-type-select"
        style={{ height: "40px" }}
        value={specType}
        multiple={isInA2psProcessControlForm}
        displayEmpty
        renderValue={(selected) => {
          if (!selected || selected === "") {
            return <span style={{ color: "#aaa" }}>Filter by spec type</span>;
          }
          if (Array.isArray(selected)) {
            return selected.join(", ");
          } else {
            return selected;
          }
        }}
        onChange={handleChange}
        placeholder="Filter by spec type"
        sx={{ width: "260px", height: "37px" }}
      >
        {!isInA2psProcessControlForm ? (
          <MenuItem key={"All"} value={""}>
            All
          </MenuItem>
        ) : null}

        {isInA2psProcessControlForm
          ? menuItemValuesForA2psProcessControlForm.map((value) => (
              <MenuItem key={value} value={value}>
                {value}
              </MenuItem>
            ))
          : menuItemValues.map((value) => (
              <MenuItem key={value} value={value}>
                {value}
              </MenuItem>
            ))}
      </Select>
    </FormControl>
  );
};
