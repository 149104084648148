/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { GridColumns } from "@mui/x-data-grid-pro-v5";
import { selectMyParts, selectMyPartsLoading } from "../../redux/selectors";
import { getMyPartsTableColumns } from "./my-parts-table.columns";
import { workspaceNextActions } from "../../redux";
import { useGridData } from "@next/hooks/useGridData-v5";
import i18n from "assets/configi18n/i18n";
import { generatePaginationAndSearchQuery } from "@next/utils/paginationUtils-v5";

import {
  getPMPartsHiddenColumns,
  getPartExtraFields,
  getQuotationExtraFields,
} from "services/part-configuration/part-configuration.selectors";

export type GridStatePartData = {
  rows: Partial<any>[];
  columns: GridColumns;
};

type Props = {
  currentPage: number;
  debouncedSearchValue: string;
  isReadOnly: boolean;
};

export const useMyPartsTableData = ({
  currentPage,
  debouncedSearchValue,
  isReadOnly,
}: Props) => {
  const dispatch = useDispatch();

  const myParts = useSelector(selectMyParts);
  const myPartsLoading = useSelector(selectMyPartsLoading);
  const partExtraFields = useSelector(getPartExtraFields);
  const pmPartsHiddenColumns = useSelector(getPMPartsHiddenColumns);
  const quotationExtraFields = useSelector(getQuotationExtraFields);

  const myPartsTableColumns = useMemo(
    () =>
      getMyPartsTableColumns(
        partExtraFields,
        quotationExtraFields,
        pmPartsHiddenColumns,
        isReadOnly
      ),
    [myPartsLoading, i18n?.language, isReadOnly]
  );

  const { gridData, setGridData } = useGridData({
    rows: myParts,
    columns: myPartsTableColumns,
    loading: myPartsLoading,
  });

  useEffect(() => {
    dispatch(
      workspaceNextActions.fetchMyPartsRequest({
        query: `${generatePaginationAndSearchQuery({
          currentPage,
          searchKey: "search",
          searchValue: debouncedSearchValue,
        })}`,
      })
    );
  }, [currentPage, debouncedSearchValue]);

  return {
    gridData,
    setGridData,
  };
};
