import { Divider, Theme } from "@mui/material";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { fileStateToZip } from "./bulk-file-import-upload-files.utils";
import CustomButton from "@next/components/custom-button";
import JSZip from "jszip";
import MultipleDndFileUpload from "@next/components/multiple-dnd-file-upload";
import { CustomFileObject } from "@next/components/multiple-dnd-file-upload/multiple-dnd-file-upload.types";
import { createStyles, makeStyles } from "@mui/styles";

const useStyles = makeStyles<Theme>(() =>
  createStyles({
    root: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      flexDirection: "column",
    },
    importButton: {
      marginTop: "16px",
      marginBottom: "16px",
    },
    divider: {
      width: "100%",
    },
  })
);

type Props = {
  onUpload: (zippedFile: File, fileCount: number) => void;
};

export const BulkFileImportUploadFile: React.FC<Props> = ({ onUpload }) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const [filesState, setFilesState] = useState<CustomFileObject[]>([]);

  const [isZipping, setIsZipping] = useState<boolean>(false);
  const filesZip = new JSZip();

  const onClickStartImport = () => {
    setIsZipping(true);

    const files = filesState.map((file) => file?.file);

    fileStateToZip(filesZip, files, (content: any) => {
      setIsZipping(false);
      onUpload(content, files?.length);
    });
  };

  return (
    <>
      <div className={classes.root}>
        <MultipleDndFileUpload
          filesState={filesState}
          setFilesState={setFilesState}
          showSecureInfo={false}
        />

        <Divider className={classes.divider} />

        <CustomButton
          variant={"contained"}
          color="primary"
          onClick={onClickStartImport}
          disabled={filesState.length === 0}
          className={classes.importButton}
          loading={isZipping}
        >
          {t("common:import")}
        </CustomButton>
      </div>
    </>
  );
};
