import React, { useEffect, useMemo } from "react";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useMediaQuery } from "@mui/material";
import { A2psProcessControlForm } from "../forms/a2ps-process-control-form/a2ps-process-control-form";
import { DesignAndBuiltForm } from "../forms/design-and-built-form/design-and-built-form";
import { IdentificationForm } from "../forms/identification-form/identification-form";
import { LeadTimeForImplementationForm } from "../forms/lead-time-for-implementation-form/lead-time-for-implementation-form";
import { OnboardingForm } from "../forms/onboarding-form/onboarding-form";
import { ScopeForm } from "../forms/scope-form/scope-form";
import { SubtiersOnboardingForm } from "../forms/subtiers-onboarding-form/subtiers-onboarding-form";
import { SubtiersForm } from "../forms/subtiers-form/subtiers-form";
import { ApprovalForm } from "../forms/approval-form/approval-form";
import { LoadingScreen } from "../loading-screen/loading-screen";
import { ErrorScreen } from "../error-screen/error-screen";
import { ScreenBanner } from "../screen-banner/screen-banner";
import {
  selectActiveFormStepIndex,
  selectFormDataError,
  selectFormDataLoading,
  selectSteps,
  selectVisibleSteps,
} from "../../redux/selectors";
import {
  PosEndUsersFormKeyMapping,
  PosEndUsersQuestionnaireForms,
  QuestionnaireForms,
  vendorManagementActions,
} from "../../redux";
import { LeadTimeForProductionForm } from "../forms/lead-time-for-production-form/lead-time-for-production-form";

const availableForms = {
  Identification: IdentificationForm,
  Approval: ApprovalForm,
  Onboarding: OnboardingForm,
  Scope: ScopeForm,
  "A2PS’ Tests & Methods": A2psProcessControlForm,
  "Lead time for implementation": LeadTimeForImplementationForm,
  "Lead time for production": LeadTimeForProductionForm,
  "Design and build": DesignAndBuiltForm,
  Subtiers: SubtiersForm,
  "Subtiers onboarding": SubtiersOnboardingForm,
};

export const PosEndUsersQuestionnaire: React.FC = () => {
  const isSmallScreen = useMediaQuery("(max-width:600px)");
  const dispatch = useDispatch();
  const { companyId } = useParams<{ companyId: string }>();
  const requestFormDataLoading = useSelector(selectFormDataLoading);
  const requestFormDataError = useSelector(selectFormDataError);
  const visibleSteps = useSelector(selectVisibleSteps);
  const activeFormStepIndexState = useSelector(selectActiveFormStepIndex); // useEffect not working with static index number, need to use this state to trigger useEffect
  const activeFormStepIndex = visibleSteps.findIndex((step) => step.is_active);
  const steps = useSelector(selectSteps);

  const { forms, formNames } = useMemo(() => {
    const visibleForms: React.ComponentType<any>[] = [];
    const visibleFormNames: PosEndUsersQuestionnaireForms[] = [];

    Object.entries(PosEndUsersFormKeyMapping).forEach(([enumKey, formKey]) => {
      const step = steps.find((step) => step.step_name === formKey);
      if (step?.is_visible) {
        visibleForms.push(availableForms[formKey]);
        visibleFormNames.push(
          PosEndUsersQuestionnaireForms[
            enumKey as keyof typeof PosEndUsersQuestionnaireForms
          ]
        );
      }
    });

    return { forms: visibleForms, formNames: visibleFormNames };
  }, [steps]);

  const ActiveFormComponent = forms[activeFormStepIndex];

  useEffect(() => {
    if (companyId && activeFormStepIndex !== -1) {
      dispatch(
        vendorManagementActions.fetchFormDataRequest({
          formName: formNames[activeFormStepIndex],
          companyId: companyId,
          onSuccess: () => {
            if (formNames[activeFormStepIndex] == "scopeForm") {
              dispatch(
                vendorManagementActions.fetchFormDataRequest({
                  formName: QuestionnaireForms.A2psProcessControl,
                  companyId: companyId,
                  fetcha2psRequest: true,
                })
              );
            }
          },
        })
      );
      dispatch(
        vendorManagementActions.setIsThereUnsavedChangesInFormStep(false)
      );
    }
  }, [activeFormStepIndexState]);

  if (requestFormDataError) {
    return <ErrorScreen message={requestFormDataError.message} />;
  }

  return (
    <>
      {requestFormDataLoading ? (
        <LoadingScreen />
      ) : ActiveFormComponent ? (
        isSmallScreen ? (
          <>
            <ScreenBanner />
            <ActiveFormComponent />
          </>
        ) : (
          <ActiveFormComponent />
        )
      ) : null}
    </>
  );
};
