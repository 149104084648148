import { RootState } from "store";

export const selectRequestsReport = (state: RootState) =>
  state.analyticsNext.requestsReport;
export const selectRequestsReportLoading = (state: RootState) =>
  state.analyticsNext.requestsReportLoading;
export const selectRequestsReportError = (state: RootState) =>
  state.analyticsNext.requestsReportError;
export const selectOrdersReport = (state: RootState) =>
  state.analyticsNext.ordersReport;
export const selectOrdersReportLoading = (state: RootState) =>
  state.analyticsNext.ordersReportLoading;
export const selectSupplierReport = (state: RootState) =>
  state.analyticsNext.supplierReport;
export const selectSupplierReportLoading = (state: RootState) =>
  state.analyticsNext.supplierReportLoading;

export const selectAnalyticFilters = (state: RootState) =>
  state.analyticsNext.analyticFilters;

export const analyticsSelectors = {
  selectRequestsReport,
  selectRequestsReportLoading,
  selectOrdersReport,
  selectOrdersReportLoading,
  selectSupplierReport,
  selectAnalyticFilters,
  selectSupplierReportLoading,
};
