// general imports
import React from "react";
import { Grid } from "@mui/material";

import { withStyles } from "@mui/styles";

import PartDetails from "./details/details.component";

import PropTypes from "prop-types";

import Dialog from "@mui/material/Dialog";
import MuiDialogContent from "@mui/material/DialogContent";

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

PartDetailsDialog.propTypes = {
  part: PropTypes.object.isRequired,
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
};

function PartDetailsDialog({ part, isOpen, onClose = () => {}, ...rest }) {
  const dialogHeightInPercent = 90;
  const dialogHeightInPx =
    (document.body.offsetHeight * dialogHeightInPercent) / 100;

  return (
    <Dialog
      autoDetectWindowHeight={true}
      autoScrollBodyContent={true}
      open={isOpen}
      onClose={onClose}
      closeAfterTransition
      aria-labelledby="customized-dialog-title"
      fullWidth={true}
      maxWidth={"xl"}
    >
      <DialogContent
        dividers
        style={{ padding: 0, height: dialogHeightInPx + "px", display: "flex" }}
      >
        <Grid
          container
          xs={12}
          direction="column"
          justifyContent="center"
          alignItems="center"
        >
          <PartDetails part={part} onClose={onClose} downloadable {...rest} />
        </Grid>
      </DialogContent>
    </Dialog>
  );
}

export default PartDetailsDialog;
