import { Box, Button, Theme } from "@mui/material";
import React from "react";
import { OrderDetailDraftHeader } from "./order-detail-draft-header";
import { useDispatch } from "react-redux";
import { SendOrderDrawer } from "@next/modules/orders/modals/send-order-drawer/send-order-drawer";
import AddIcon from "@mui/icons-material/Add";
import { AddOrderPartsModal } from "@next/modules/orders/modals/add-order-parts-modal";
import { modalsActions } from "@next/redux/modalsSlices";
import { OrdersModalTypes } from "@next/modules/orders/modals/types";
import { useTranslation } from "react-i18next";
import { createStyles, makeStyles } from "@mui/styles";
import { OrderDetailLineItemsTable } from "../order-detail-line-items-table/order-detail-line-items-table";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: "flex",
      alignItems: "center",
      flexDirection: "column",
      width: "100%",
    },
    paper: {
      padding: "24px",
      boxShadow: "none",
      border: `1px solid ${theme.palette.divider}`,
      marginTop: "24px",
    },
  })
);

export const OrderDetailDraft = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const classes = useStyles();

  const onClickAddItems = () => {
    dispatch(
      modalsActions.showModal({
        key: OrdersModalTypes.ADD_ORDER_PARTS,
      })
    );
  };

  return (
    <Box className={classes.root}>
      <OrderDetailDraftHeader />

      <Box mt="24px" alignSelf="flex-start">
        <Button
          startIcon={<AddIcon />}
          variant="outlined"
          color="primary"
          onClick={onClickAddItems}
        >
          {t("orders:addOrderParts:title")}
        </Button>
      </Box>

      <Box width="100%">
        <OrderDetailLineItemsTable isDraft={true} />
      </Box>

      <SendOrderDrawer />
      <AddOrderPartsModal />
    </Box>
  );
};
