import React, { useState } from "react";
import { Box, Button, TextField, Typography } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import {
  IState,
  IUserRatingModalProps,
} from "../../../services/rating/rating.model";
import { sendUserRating } from "../../../services/rating";
import StarRating from "../../utils/star-rating/star-rating.component";
import { useTranslation } from "react-i18next";

const UserRatingModal: React.FC<IUserRatingModalProps> = ({
  ratingToken,
  isSubmitRatingLoading,
}: IUserRatingModalProps): React.ReactElement => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  // selected rating value on star click for each rating criteria
  const [ratingValue, setRatingValue] = useState<any>({});

  // rating feedback value
  const [ratingFeedback, setRatingFeedback] = useState<string>("");

  // get rating criteria with pk and description from redux state
  const ratingInfo = useSelector(
    (state: IState) => state.userRating.ratingInfo
  );

  // set star rating selection on stars click
  const handleOnChange = (newValue: number, id: number) => {
    setRatingValue({ ...ratingValue, [id]: newValue });
  };

  // submit rating form and sending ratingInfo to POST endpoint
  const handleRatingSubmit = () => {
    const ratingScores = [];
    for (let rating in ratingValue) {
      ratingScores.push({
        rating_criteria_pk: Number(rating),
        score: Number(ratingValue[rating]),
      });
    }
    // construct ratingInfo object with rating value and feedback comments
    const ratingInfo = {
      rating_scores: ratingScores,
      rating_feedback: ratingFeedback,
    };
    dispatch(sendUserRating(ratingToken, ratingInfo));
  };

  return (
    <>
      <Box className="c-user-rating--stars-container">
        {ratingInfo?.rating_criteria?.map((criteria) => {
          return (
            <>
              <Typography component="legend">{criteria.short_name}</Typography>
              <Typography variant="body2">{criteria.description}</Typography>
              <StarRating
                handleOnChange={(newValue: number, id: number) =>
                  handleOnChange(newValue, id)
                }
                value={ratingValue[criteria.pk]}
                id={criteria.pk}
              />
            </>
          );
        })}
        <TextField
          className="c-user-rating-field"
          placeholder={t("rating:ratingFeedback")}
          fullWidth
          rows={4}
          variant="outlined"
          multiline
          name="ratingFeedback"
          value={ratingFeedback}
          onChange={(e) => setRatingFeedback(e.target.value)}
        />
        <Box mt={3} display="flex" justifyContent="flex-end">
          <Button
            variant="contained"
            type="submit"
            size="large"
            className="c-user-rating--btn"
            onClick={() => handleRatingSubmit()}
            disabled={
              !(ratingValue && Object.keys(ratingValue).length === 3) ||
              isSubmitRatingLoading
            }
          >
            {t("rating:submit")}
          </Button>
        </Box>
      </Box>
    </>
  );
};
export default UserRatingModal;
