import React from "react";
import { Box, Typography, styled } from "@mui/material";
import { useAxyaTracking } from "@next/hooks/useAxyaTracking";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { modalsActions } from "@next/redux/modalsSlices";
import { WorkspaceModalTypes } from "@next/modules/workspace/modals/types";

const StyledBox = styled(Box)(({ theme }) => ({
  width: "100%",
  display: "flex",
  flexDirection: "row",
  height: "124px",
  borderRadius: "24px",
  border: `1px solid ${theme.palette.success.main}`,
  backgroundColor: "white",
}));

export const AnalyticCardBeta = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const axyaTracking = useAxyaTracking();

  const onClickOpenGiveYouFeedbackModal = () => {
    dispatch(
      modalsActions.showModal({
        key: WorkspaceModalTypes.CONTACT_US_MODAL,
        data: {
          modalTitle: t("contactUs:giveYouFeedback"),
          hasFormTitle: true,
          formTitle: t("contactUs:giveYouFeedbackFormTitle"),
          label: t("contactUs:giveYouFeedbackInputLabel"),
        },
      })
    );
    axyaTracking({
      scout_category: "contact-us",
      scout_feature_name: "open-give-you-feedback-modal-from-app-bar",
    });
  };

  return (
    <StyledBox>
      <Box style={{ margin: "24px", marginBottom: "52px" }}>
        <Typography variant="body2">
          {t("analytics:betaCard:analyticsIn") + " "}
          <Typography
            component="span"
            variant="body2"
            color="success.dark"
            fontWeight={"700"}
          >
            {t("analytics:betaCard:openBeta")}
          </Typography>
          {t("analytics:betaCard:hearYourFeedback") + " "}
          <Typography
            style={{ cursor: "pointer" }}
            color="primary"
            component={"span"}
            variant="body2"
            onClick={onClickOpenGiveYouFeedbackModal}
          >
            {t("analytics:betaCard:shareYourFeedback")}
          </Typography>
        </Typography>
      </Box>
    </StyledBox>
  );
};
