import { PortalData } from "@next/modules/workspace/redux";
import { convertObjectToFiles } from "@next/utils/fileUtils";
import * as yup from "yup";

export const getTopicPortalQuotationFormInitialValues = (
  values: PortalData["quotation"]
) => {
  return {
    additional_details: values?.additional_details || "",
    files: convertObjectToFiles(values?.files as any) || [],
  };
};

export const validationSchema = () => {
  const schema: Record<string, yup.Schema<any>> = {
    additional_details: yup.string(),
    file: yup.array().of(yup.mixed()),
  };

  return yup.object(schema);
};
