/**
 * Use this component to display upload part and fixed fees
 */

import React from "react";

import {
  Avatar,
  Box,
  CardContent,
  Chip,
  Grid,
  Link,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Tooltip,
} from "@mui/material";

import { downloadFile } from "helpers/download";

import CurrencyField from "components/common/fields/CurrencyField";
import { useQuotationViewData } from "components/marketplace/quotation-view/quotation-view-hooks";
import { useSelector } from "react-redux";
import { getCompany } from "services/profile/profile.selectors";
import { checkIsShowCommissionFeeCompany } from "@next/utils/checkCompanyUtils";

export const DetailsSummaryTotalFees = (props) => {
  const { subTotal, fixedFees, totalPrice, uploadList, t, commissionFee } =
    props;
  const company = useSelector(getCompany);
  const isShowCommissionFeeCompany = checkIsShowCommissionFeeCompany(
    company?.id
  );

  const { isQuotationExtraFieldsAvailable } = useQuotationViewData({
    isContract: props.isContract,
  });

  const handleDownloadClick = (e) => {
    downloadFile(e);
  };

  return (
    <Grid item xs={12} className="c-details-summary-total-fees">
      <Box display="flex">
        <Box className="c-details-summary-total-fees--upload-content">
          <CardContent>
            <Box display="flex">
              <Box
                p={1}
                textAlign="left"
                fontWeight="fontWeightRegular"
                fontSize="h5.fontSize"
                className="c-box"
              >
                {t("submissionFile")}
              </Box>
              <Box className="c-details-summary-total-fees--upload-avatar">
                <Tooltip title={t("listOfPdfFilesUploadedByTheManufacturer")}>
                  <Avatar className="c-details-summary-total-fees--upload-avatar--width">
                    i
                  </Avatar>
                </Tooltip>
              </Box>
            </Box>
            {uploadList.length === 0
              ? ""
              : uploadList.map((fileUpload) => {
                  return (
                    <List disablePadding>
                      <ListItem key={fileUpload.pk}>
                        <ListItemIcon>
                          <Chip label={fileUpload.file_extension} />
                        </ListItemIcon>
                        <ListItemText
                          key={fileUpload.pk}
                          primary={
                            <Box
                              textAlign="left"
                              fontWeight="fontWeightRegular"
                              fontSize={16}
                              className="c-download-pdf"
                            >
                              <Link
                                download-link={fileUpload.file_url}
                                download
                                onClick={(e) => handleDownloadClick(e)}
                              >
                                {fileUpload.file_name}
                              </Link>
                            </Box>
                          }
                        />
                      </ListItem>
                    </List>
                  );
                })}
          </CardContent>
        </Box>
        {!isQuotationExtraFieldsAvailable && (
          <Box className="c-details-summary-total-fees--fees-content">
            <CardContent className="c-details-summary-total-fees--sub-total">
              <Box display="flex" p={1} m={1}>
                <Box
                  p={1}
                  flexGrow={1}
                  textAlign="left"
                  fontWeight="fontWeightRegular"
                  fontSize={16}
                  className="c-box"
                >
                  {t("subTotal")}
                </Box>
                <Box
                  p={1}
                  textAlign="right"
                  fontWeight="fontWeightMedium"
                  fontSize="h6.fontSize"
                  className="c-box"
                >
                  <CurrencyField value={subTotal} />
                </Box>
              </Box>
            </CardContent>
            <CardContent>
              <Box display="flex" p={1} m={1}>
                <Box display="flex" p={1} flexGrow={1}>
                  <Box
                    textAlign="left"
                    fontWeight="fontWeightRegular"
                    fontSize={16}
                    className="c-box"
                  >
                    {t("inbox:fixedFees")}
                  </Box>
                  <Box className="c-details-summary-total-fees--avatar-fees">
                    <Tooltip title={t("costsAssociatedWithProgrammingTime")}>
                      <Avatar className="c-details-summary-total-fees--avatar-fees--width">
                        i
                      </Avatar>
                    </Tooltip>
                  </Box>
                </Box>

                <Box
                  p={1}
                  textAlign="right"
                  fontWeight="fontWeightMedium"
                  fontSize="h6.fontSize"
                  className="c-box"
                >
                  <CurrencyField value={fixedFees} />
                </Box>
              </Box>
              {isShowCommissionFeeCompany && commissionFee ? (
                <Box display="flex" p={1} m={1}>
                  <Box display="flex" p={1} flexGrow={1}>
                    <Box
                      textAlign="left"
                      fontWeight="fontWeightRegular"
                      fontSize={16}
                      className="c-box"
                    >
                      {`${t("pay:paymentTerms:commissionFees")} `}
                      {commissionFee?.percentage ? (
                        <span className="s-span">
                          {`(${commissionFee?.percentage}%)`}
                        </span>
                      ) : (
                        <span className="blurred-content"></span>
                      )}
                    </Box>
                    <Box className="c-details-summary-total-fees--avatar-fees">
                      <Tooltip
                        title={`${t("pay:paymentTerms:commissionFeesInfo", {
                          buyer_name: company?.name,
                        })}`}
                      >
                        <Avatar className="c-details-summary-total-fees--avatar-fees--width">
                          i
                        </Avatar>
                      </Tooltip>
                    </Box>
                  </Box>

                  <Box
                    p={1}
                    textAlign="right"
                    fontWeight="fontWeightMedium"
                    fontSize="h6.fontSize"
                    className="c-box"
                  >
                    <CurrencyField value={commissionFee?.amount} />
                  </Box>
                </Box>
              ) : null}
            </CardContent>
          </Box>
        )}
      </Box>
    </Grid>
  );
};
