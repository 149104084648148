// General imports
import React from "react";
import ValidationPage from "../ValidationPage";
import { frontendUrl } from "../../urls";

// Assets
import notFound from "../../assets/img/oops-not-found.png";
import { useSelector } from "react-redux";

function NotFoundPage(props) {
  const profile = useSelector((state) => state.profile);

  return (
    <ValidationPage
      title={props.t("error404Title")}
      body={props.t("error404Body")}
      primaryText={props.t(
        profile?.isLoggedIn ? "back_to_main_page" : "error404Redirection"
      )}
      primaryUrl={profile?.isLoggedIn ? frontendUrl.home : frontendUrl.logout}
      image={notFound}
    />
  );
}

export default NotFoundPage;
