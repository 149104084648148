import {
  Box,
  IconButton,
  InputAdornment,
  List,
  ListItem,
  ListItemText,
  TextField,
  alpha,
} from "@mui/material";
import {
  GlobalSearchResult,
  workspaceNextActions,
} from "@next/modules/workspace/redux";
import {
  selectGlobalSearchLoading,
  selectGlobalSearchNotFound,
  selectGlobalSearchResults,
} from "@next/modules/workspace/redux/selectors";
import { debounce } from "lodash";
import React, { useCallback, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import SearchOutlinedIcon from "@mui/icons-material/SearchOutlined";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import { Skeleton } from "@mui/material";
import {
  getGlobalSearchEntryUrl,
  getGlobalSearchTypeText,
} from "@next/modals/components/global-search/global-search.utils";
import { useHasCompanyRole } from "@next/hooks/useHasCompanyRole";

import { createStyles, makeStyles } from "@mui/styles";
import useLocalStorage from "hooks/useLocalStorage";
import { Typography } from "@material-ui/core";

const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      color: theme.palette.common.white,
    },
    list: {
      paddingTop: 0,
      paddingBottom: 0,
      overflowY: "auto",
      overflowX: "hidden",
    },
    text: {
      "& span, & p": {
        overflow: "hidden",
        whiteSpace: "nowrap",
        textOverflow: "ellipsis",
        display: "inline",
        marginRight: "8px",
      },
      "& p": {
        color: "lightgray",
      },
    },
    input: {
      width: "100%",

      "& fieldset": {
        border: "none",
      },

      "& input": {
        color: theme.palette.common.white,
      },

      "& ::placeholder": {
        color: alpha(theme.palette.common.white, 0.7),
        opacity: 1,
      },
      "& ::-ms-input-placeholder": {
        color: alpha(theme.palette.common.white, 0.7),
      },
    },

    recentlyVisited: {
      color: theme.palette.common.white,
      padding: "0px 16px",
      textAlign: "right",
    },

    icon: {
      color: theme.palette.common.white,
    },

    skeleton: {
      backgroundColor: alpha(theme.palette.common.white, 0.5),
      borderRadius: 4,
    },
  })
);

export const LOCAL_STOTAGE_RECENT_SEARCHES_KEY = "recentSearches";

type Props = {
  handleClose: () => void;
};

const GlobalSearch: React.FC<Props> = ({ handleClose }) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const dispatch = useDispatch();
  const loading = useSelector(selectGlobalSearchLoading);
  const hasViewerRole = useHasCompanyRole(["viewer"]);
  const searchResults = useSelector(selectGlobalSearchResults);
  const [recentSearches, setRecentSearches] = useLocalStorage<
    GlobalSearchResult[]
  >(LOCAL_STOTAGE_RECENT_SEARCHES_KEY, []);

  const filteredSearchResults = useMemo(
    () =>
      searchResults?.filter((item) =>
        hasViewerRole ? item?.result_type === "RFQ" : item
      ) || [],
    [searchResults, hasViewerRole]
  );
  const notFound = useSelector(selectGlobalSearchNotFound);
  const [input, setInput] = useState("");

  const handleSearch = useCallback(
    debounce(
      (term) => {
        dispatch(workspaceNextActions.globalSearchRequest({ term }));
      },
      800,
      {
        leading: false,
        trailing: true,
      }
    ),
    []
  );

  const handleChange = useCallback(({ target }) => {
    setInput(target.value);
    if (target.value?.length >= 3) handleSearch(target.value);
    else dispatch(workspaceNextActions.clearGlobalSearch());
  }, []);

  const handleClear = useCallback(() => {
    setInput("");
    dispatch(workspaceNextActions.clearGlobalSearch());
  }, []);

  const loader = useMemo(() => {
    return Array(5)
      .fill(true)
      .map((_, ix) => (
        <ListItem key={ix}>
          <Skeleton
            className={classes.skeleton}
            width="100%"
            height={24}
            variant="rectangular"
          />
        </ListItem>
      ));
  }, []);

  const handleSetRecentSearches = (result: GlobalSearchResult) => {
    handleClose();
    setRecentSearches([
      result,
      ...recentSearches.filter((r) => r.id !== result.id).slice(0, 5),
    ]);
  };

  return (
    <Box className={classes.root}>
      <TextField
        className={classes.input}
        placeholder={t(
          hasViewerRole
            ? "globalSearch:placeholderViewer"
            : "globalSearch:placeholder"
        )}
        variant="outlined"
        onChange={handleChange}
        value={input}
        autoFocus
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <SearchOutlinedIcon className={classes.icon} />
            </InputAdornment>
          ),
          endAdornment: input.length ? (
            <InputAdornment position="end">
              <IconButton onClick={handleClear} size="large">
                <CloseOutlinedIcon className={classes.icon} />
              </IconButton>
            </InputAdornment>
          ) : null,
        }}
      />
      <List className={classes.list}>
        {!loading &&
        filteredSearchResults?.length === 0 &&
        !notFound &&
        recentSearches?.length !== 0 ? (
          <>
            <Typography variant="body2" className={classes.recentlyVisited}>
              {t("globalSearch:recentlyVisited")}
            </Typography>
            {recentSearches.map((result) => (
              <ListItem
                key={result.id}
                button
                component="a"
                href={getGlobalSearchEntryUrl(result)}
                onClick={() => handleSetRecentSearches(result)}
              >
                <ListItemText
                  className={classes.text}
                  primary={result.name}
                  secondary={getGlobalSearchTypeText(result)}
                />
              </ListItem>
            ))}
          </>
        ) : loading ? (
          <>{loader}</>
        ) : notFound ? (
          <ListItem>
            <ListItemText
              primary={`${t("globalSearch:noResult")} "${input}"`}
            />
          </ListItem>
        ) : (
          filteredSearchResults?.map((result) => (
            <ListItem
              key={result.id}
              button
              component="a"
              href={getGlobalSearchEntryUrl(result)}
              onClick={() => handleSetRecentSearches(result)}
            >
              <ListItemText
                className={classes.text}
                primary={result.name}
                secondary={getGlobalSearchTypeText(result)}
              />
            </ListItem>
          ))
        )}
      </List>
    </Box>
  );
};

export default GlobalSearch;
