import { Box, Theme, Typography } from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";
import PublicRfqCreationFormButton from "../public-rfq-creation-form-button";
import { createStyles, makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: "flex",
      flexDirection: "column",
      height: "100%",
      justifyContent: "space-between",
      overflow: "auto",
      maxHeight: "calc(100vh - 170px)",
      alignItems: "center",
      width: "100%",
      marginTop: "48px",
    },
    title: {
      width: "100%",
      textAlign: "center",
      fontSize: "65px",
      fontWeight: 275,
      letterSpacing: "0.15px",
    },
    avatar: {
      marginRight: "8px",
      backgroundColor: theme.palette.success.main,
      width: 24,
      height: 24,
    },
    icon: {
      color: "white",
      width: 16,
      height: 16,
    },
  })
);

type Props = {
  name?: string;
  onClickSendAnotherOne: () => void;
};

const PublicRfqCreationSuccess: React.FC<Props> = ({
  name,
  onClickSendAnotherOne,
}) => {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <div className={classes.root}>
      <div>
        <Typography variant="h6" className={classes.title}>
          {t("workspaceNext:publicRfq:form:requestForQuotes")}
        </Typography>
        <br />

        <Typography variant="body1" paragraph>
          {t("workspaceNext:publicRfq:successBody")}
        </Typography>
      </div>

      <Box mt="24px">
        <PublicRfqCreationFormButton onClick={onClickSendAnotherOne}>
          {t("workspaceNext:publicRfq:form:sendAnotherOne")}
        </PublicRfqCreationFormButton>
      </Box>
    </div>
  );
};

export default PublicRfqCreationSuccess;
