import React, { useState } from "react";
import {
  Box,
  Button,
  CircularProgress,
  Fade,
  Menu,
  MenuItem,
  Typography,
} from "@mui/material";
import { Publish } from "@mui/icons-material";
import { useTranslation } from "react-i18next";
import FolderOutlinedIcon from "@mui/icons-material/FolderOutlined";
import DescriptionOutlinedIcon from "@mui/icons-material/DescriptionOutlined";
import { useSelector } from "react-redux";
import { selectProjectBulkInsertPartLoading } from "@next/modules/project/redux/selectors";

type Props = {
  onImport: (fileResults?: any) => void;
  importButtonTitle?: string;
  bulkImportButtonTitle?: string;
  onClickOnBulkImport?: () => void;
  renderButton?: (props: any) => React.ReactNode;
};

export const CustomGridToolbarImport: React.FC<Props> = ({
  onImport,
  importButtonTitle,
  bulkImportButtonTitle,
  onClickOnBulkImport,
  renderButton,
}) => {
  const { t } = useTranslation();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const projectBulkInsertPartLoading = useSelector(
    selectProjectBulkInsertPartLoading
  );

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const onImportChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event?.target?.files && event?.target?.files[0];

    if (file) {
      onImport(file);
    }
  };

  return (
    <>
      {renderButton ? (
        renderButton({ onClick: handleClick })
      ) : (
        <Button
          aria-controls="grid-toolbar-import"
          aria-haspopup="true"
          startIcon={<Publish style={{ fontSize: "18px" }} />}
          onClick={handleClick}
        >
          {t("common:import")}
        </Button>
      )}
      <Menu
        id="grid-toolbar-import"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "fade-button",
        }}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        TransitionComponent={Fade}
      >
        <MenuItem component="label" htmlFor="fileUpload">
          <DescriptionOutlinedIcon />

          <Box ml="10px">
            <Typography variant="body1">{importButtonTitle}</Typography>
          </Box>

          <input
            type="file"
            hidden
            id="fileUpload"
            onChange={onImportChange}
            accept=".xlsx, .xls"
          />
        </MenuItem>
        {bulkImportButtonTitle && onClickOnBulkImport ? (
          <MenuItem component="label" onClick={onClickOnBulkImport}>
            <FolderOutlinedIcon />

            <Box ml="10px">
              <Typography variant="body1">{bulkImportButtonTitle}</Typography>
            </Box>
          </MenuItem>
        ) : null}
      </Menu>

      {projectBulkInsertPartLoading && <CircularProgress size={20} />}
    </>
  );
};

export default CustomGridToolbarImport;
