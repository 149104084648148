import { Avatar, Badge, Button, Tooltip } from "@mui/material";
import React from "react";
import { useSelector } from "react-redux";
import { makeStyles } from "@mui/styles";
import { styled } from "@mui/material/styles";
import { getErpConfiguration } from "services/part-configuration/part-configuration.selectors";
import inforErpIcon from "@next/assets/inforErp.png";
import geniusErpIcon from "@next/assets/geniusErp.png";
import { ErpConfigurationDetailPopover } from "../erp-configuration-detail-popover";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles(() => ({
  avatar: {
    "& > img": {
      width: "40px",
      height: "40px",
      objectFit: "contain",
    },
    borderRadius: "8px",
    cursor: "pointer",
    backgroundColor: "transparent",
    width: "fit-content",
  },
  popover: {
    marginTop: "20px",
  },
}));

interface StyledBadgeProps {
  erpstatus: boolean;
}

const StyledBadge = styled(Badge)<StyledBadgeProps>(({ theme, erpstatus }) => ({
  "& .MuiBadge-badge": {
    backgroundColor: erpstatus
      ? theme.palette.success.main
      : theme.palette.text.disabled,
    color: erpstatus ? theme.palette.success.main : theme.palette.text.disabled,
    left: 10,
    marginLeft: "25px",
    "&::after": {
      position: "absolute",
      top: 0,
      left: 0,
      width: "100%",
      height: "100%",
      borderRadius: "50%",
      animation: erpstatus ? "ripple 1.2s infinite ease-in-out" : "none",
      border: erpstatus ? `1px solid ${theme.palette.success.main}` : "none",
      content: '""',
    },
  },
  "@keyframes ripple": {
    "0%": {
      transform: "scale(.8)",
      opacity: 1,
    },
    "100%": {
      transform: "scale(2.4)",
      opacity: 0,
    },
  },
}));

export enum ErpConfigurationType {
  INFOR = "InforSyteline",
  GENIUS = "GeniusERP",
}

export const getErpConfigurationIcon = (
  erpConfiguration: ErpConfigurationType
) => {
  switch (erpConfiguration) {
    case ErpConfigurationType.INFOR:
      return inforErpIcon;
    case ErpConfigurationType.GENIUS:
      return geniusErpIcon;
    default:
      return null;
  }
};

export const getErpConfigurationName = (
  erpConfiguration: ErpConfigurationType
) => {
  switch (erpConfiguration) {
    case ErpConfigurationType.INFOR:
      return "Infor";
    case ErpConfigurationType.GENIUS:
      return "Genius ERP";
    default:
      return null;
  }
};

export const AppBarNextErpConfiguration = () => {
  const erpConfiguration = useSelector(getErpConfiguration);
  const classes = useStyles();
  const { t } = useTranslation();

  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(
    null
  );

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "erp-configuration-popover" : undefined;

  const onClickErpConfiguraion = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    setAnchorEl(event.currentTarget);
  };

  const connectedErpType: ErpConfigurationType =
    erpConfiguration?.erp_type?.value;

  const erpStatus = erpConfiguration?.erp_connection_status?.value;

  return (
    <>
      <Button
        onClick={onClickErpConfiguraion}
        aria-describedby={id}
        sx={{
          padding: 0,
          minWidth: 0,
          background: "white",
          ":hover": { background: "white" },
        }}
      >
        <Tooltip title={t("erpConfiguration:title")}>
          <StyledBadge
            overlap="circular"
            anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
            variant="dot"
            erpstatus={erpStatus}
          >
            <Avatar
              alt="Remy Sharp"
              src={getErpConfigurationIcon(connectedErpType)}
              className={classes.avatar}
            />
          </StyledBadge>
        </Tooltip>
      </Button>
      <ErpConfigurationDetailPopover
        id={id}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        className={classes.popover}
      />
    </>
  );
};
