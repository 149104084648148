import React from "react";
import { Avatar, Box, Theme, Typography } from "@mui/material";
import {
  TopicMessageEventType,
  TopicMessageType,
} from "@next/modules/workspace/redux/types";
import TopicSubTitle from "../topic-sub-title";
import PoAcknowledgementFailureEvent from "./po-acknowledgement-failure-event";
import { formatDateAndTime } from "helpers/formatDate";
import { AttachFileList } from "@next/components/attach-file/attach-file-list";
import { renderIconByEventType } from "./topic-message.utils";
import { getInitials } from "@next/utils/stringUtils";
import { createStyles, makeStyles } from "@mui/styles";
import { PreviewLineItemChanges } from "@next/modules/orders/components/preview-line-item-changes/preview-line-item-changes";
import { convertQAMessageDataToOrderLineItemChanges } from "@next/modules/orders/redux/utils";
import { PreviewLineItemStatusChange } from "@next/modules/orders/components/order-detail/order-detail-mark-as-shipped-form/preview-item-shipment-status";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      padding: theme.spacing(3),
      paddingTop: "0px",
    },
    header: { display: "flex", flexDirection: "column" },
    avatar: {
      width: theme.spacing(4),
      height: theme.spacing(4),
    },
    avatarAndText: {
      display: "flex",
      alignItems: "center",
    },
    name: {
      paddingLeft: theme.spacing(1.5),
    },
    date: {
      marginLeft: "8px",
      minWidth: "95px",
    },
    message: {
      marginTop: theme.spacing(1.5),
      whiteSpace: "pre-line",
    },
    attachments: {
      marginTop: theme.spacing(1.5),
      marginBottom: theme.spacing(-1.5),
    },
  })
);

type Props = {
  message: TopicMessageType;
  index: number;
};

const TopicMessage: React.FC<Props> = ({ message }) => {
  const classes = useStyles();
  const isEventMessage =
    message?.event_type !== TopicMessageEventType.REGULAR &&
    message?.event_type !== undefined;

  return (
    <div className={classes.root}>
      <div className={classes.header}>
        <div className={classes.avatarAndText}>
          <Box
            display="flex"
            position="relative"
            style={{
              marginRight:
                isEventMessage &&
                message?.event_type !== TopicMessageEventType.ADDENDUM_ADDED
                  ? 10
                  : 0,
            }}
          >
            <Avatar
              className={classes.avatar}
              src={message?.created_by?.picture || ""}
            >
              {getInitials(message?.created_by?.full_name)}
            </Avatar>

            {isEventMessage ? renderIconByEventType(message?.event_type) : null}
          </Box>

          <div className={classes.name}>
            <TopicSubTitle
              createdByFullName={message?.created_by?.full_name}
              companyName={message?.company?.name}
              isEventMessage={isEventMessage}
              messageBody={message?.event_message}
            />
          </div>

          <div className={classes.date}>
            <Typography variant="caption" color="textSecondary">
              {formatDateAndTime(message?.created_at, "DD-MMM-YYYY")}
            </Typography>
          </div>
        </div>
      </div>

      {message?.body ? (
        <div className={classes.message}>
          <Typography variant="body2">{message?.body}</Typography>
        </div>
      ) : null}

      {isEventMessage ? (
        <>
          {(message?.event_type === TopicMessageEventType.PO_ACKNOWLEDGED ||
            message?.event_type ===
              TopicMessageEventType.PO_DELIVERY_DATE_UPDATED ||
            message?.event_type === TopicMessageEventType.PO_REVIEWED_CHANGES ||
            message?.event_type === TopicMessageEventType.PO_REVISION ||
            message?.event_type ===
              TopicMessageEventType.PO_PROPOSED_CHANGES) &&
          message?.data?.length ? (
            <div className={classes.message}>
              <PreviewLineItemChanges
                lineItemsChanges={convertQAMessageDataToOrderLineItemChanges(
                  message.data
                )}
                lockDecision
                padding="0px"
              />
            </div>
          ) : message?.event_type ===
            TopicMessageEventType.PO_SHIPPING_BATCH_CONFIRMED ? (
            <PreviewLineItemStatusChange
              lineItemChanges={convertQAMessageDataToOrderLineItemChanges(
                message?.data?.states
              )}
            />
          ) : null}

          {message?.event_type ===
            TopicMessageEventType.PO_ACKNOWLEDGEMENT_FAILURE && (
            <PoAcknowledgementFailureEvent
              files={message?.files}
              data={message?.data}
            />
          )}
        </>
      ) : null}

      {message?.event_type !==
        TopicMessageEventType.PO_ACKNOWLEDGEMENT_FAILURE &&
      Array.isArray(message.files) &&
      message.files.length > 0 ? (
        <div className={classes.attachments}>
          <AttachFileList files={message.files} />
        </div>
      ) : null}
    </div>
  );
};

export default TopicMessage;
