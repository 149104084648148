import React from "react";
import { useTranslation } from "react-i18next";

import { Form, FormikProps } from "formik";
import { Box, Button, Typography } from "@mui/material";
import { IExternalAccountFormValues } from "services/pay/pay.model";
import { GenericTextInput } from "components/utils/input/text";

export const ExternalAccountForm = ({
  values,
  handleSubmit,
}: FormikProps<IExternalAccountFormValues>) => {
  const { t } = useTranslation();
  return (
    <Box display="flex" flexDirection="column">
      <Box m={1} />
      <Box p={1}>
        <Typography variant="h5">
          {t("pay:externalAccountForm:title")}
        </Typography>
      </Box>
      <Box m={1}>
        <Typography variant="body1">
          {t("pay:externalAccountForm:subtitle")}
        </Typography>
      </Box>
      <Box m={2} />
      <Form onSubmit={handleSubmit}>
        <Box p={1}>
          <GenericTextInput
            name="transitNumber"
            label={t("pay:externalAccountForm:transitNumberLabel")}
            value={values.transitNumber}
            placeholder="12345"
          />
        </Box>
        <Box p={1}>
          <GenericTextInput
            name="institutionNumber"
            label={t("pay:externalAccountForm:institutionNumberLabel")}
            value={values.institutionNumber}
            placeholder="678"
          />
        </Box>
        <Box p={1}>
          <GenericTextInput
            name="accountNumber"
            label={t("pay:externalAccountForm:accountNumberLabel")}
            placeholder={t("pay:externalAccountForm:accountNumberPlaceholder")}
            value={values.accountNumber}
          />
        </Box>
        <Box p={1}>
          <Button
            variant="contained"
            color="secondary"
            fullWidth={true}
            onClick={() => handleSubmit()}
          >
            {t("pay:externalAccountForm:saveBankInformation")}
          </Button>
        </Box>
      </Form>
    </Box>
  );
};
