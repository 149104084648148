import { GridColDef } from "@mui/x-data-grid-pro-v5";
import { ChecboxCell } from "../table-cells/checkbox-cell";
import { SubtierOnboardingColumnFieldsWithHeader } from "@next/modules/vendor-management/redux";
import TextCell from "@next/components/table-cells/text-cell-v5";

const defaultColumnConfig: Partial<GridColDef> = {
  sortable: false,
  filterable: false,
  disableColumnMenu: true,
  editable: false,
  align: "center",
  headerAlign: "center",
  minWidth: 150,
};

export const getSubtierOnboardingFormTableColumns = () => {
  const columns: GridColDef[] = [
    {
      ...defaultColumnConfig,
      field: "supplier_title",
      headerName: "Subtier",
      align: "left",
      headerAlign: "left",
      renderCell: TextCell,
    },
    ...Object.entries(SubtierOnboardingColumnFieldsWithHeader).map(
      ([field, headerName]) => ({
        ...defaultColumnConfig,
        field,
        headerName,
        renderCell: ChecboxCell,
      })
    ),
  ];

  return columns;
};
