import { Box, Button, Typography } from "@mui/material";
import {
  GridColDef,
  GridColumns,
  GridRenderCellParams,
} from "@mui/x-data-grid-pro-v5";
import { CircularAvatarsList } from "@next/components/circular-avatars-list";
import { getUserDisplayName } from "@next/modules/profile/helpers/profile-helpers";
import { t } from "assets/configi18n/i18n";
import { formatDate } from "helpers/formatDate";
import React from "react";
import { Trans } from "react-i18next";
import { useDispatch } from "react-redux";
import { modalsActions } from "@next/redux/modalsSlices";
import { WorkspaceModalTypes } from "@next/modules/workspace/modals/types";

const defaultColumnConfig: Partial<GridColDef> = {
  disableColumnMenu: true,
  sortable: false,
  hideSortIcons: true,
};

const CompanyAndUserInfoCell: React.FC<GridRenderCellParams> = (
  params: any
) => {
  const user = params?.row?.created_by;
  const company = params?.row?.from_company;
  const dispatch = useDispatch();

  const onClickOpenConnectionRequestsModal = (connectionId: number) => {
    dispatch(
      modalsActions.showModal({
        key: WorkspaceModalTypes.CONNECTION_REQUESTS_MODAL,
        data: {
          connectionId,
        },
      })
    );
  };

  return (
    <Box
      display="flex"
      flex={1}
      alignItems="center"
      justifyContent={"space-between"}
      gap="1rem"
    >
      <Box display="flex" alignItems={"center"}>
        <CircularAvatarsList
          images={[
            {
              name: getUserDisplayName({ company: company?.name } || ""),
              tooltip: company?.name,
              url: company?.picture,
            },
            {
              name: getUserDisplayName({ first_name: user?.full_name } || ""),
              tooltip: user?.full_name,
              url: user?.picture,
            },
          ]}
          overlapping
          combinedTooltip
          size="large"
        />

        <Box ml="12px">
          <Typography
            variant="body2"
            style={{ whiteSpace: "break-spaces", userSelect: "text" }}
          >
            <Trans
              i18nKey="workspaceNext:invitationsTab:userFromCompany"
              components={[<b />]}
              values={{
                userName: user?.full_name,
                companyName: company?.name,
                email: user?.email,
              }}
            />
          </Typography>

          <Typography variant="body2" color="textSecondary">
            {t("workspaceNext:invitationsTab:invitationSentOn", {
              invitationSentOn: formatDate(
                params?.row?.created_at,
                "MMMM DD,YYYY, HH:MM A"
              ),
            })}
          </Typography>
        </Box>
      </Box>
      <Button
        variant="outlined"
        color="primary"
        onClick={() => onClickOpenConnectionRequestsModal(params?.row?.id)}
      >
        {t("workspaceNext:invitationsTab:openInvitationButton")}
      </Button>
    </Box>
  );
};

export const getSupplierInvitationsTableColumns = (): GridColumns => {
  const _columns: GridColumns = [
    {
      ...defaultColumnConfig,
      field: "invitations",
      headerName: "Name",
      flex: 1,
      renderCell: CompanyAndUserInfoCell,
    },
  ];

  return _columns;
};
