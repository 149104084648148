import React from "react";

import { useTranslation } from "react-i18next";

import { Box, Grid, Grow, Typography } from "@mui/material";
import CurrencyField from "components/common/fields/CurrencyField";
import { useQuotationViewData } from "components/marketplace/quotation-view/quotation-view-hooks";
import { QuotationExtraFieldsTable } from "@next/modules/marketplace/components/quotation/quotation-extra-fields-table";

import { LeadTime } from "@next/components/lead-time/lead-time";
import { SupplierAvatar } from "components/inbox/supplier-avatar";
import { InstigatorName } from "./instigator-name";

const QuotationDropMessage = ({
  id,
  quotationCreatedAt,
  message,
  activeQuotationThread,
}) => {
  const { t } = useTranslation();

  const { quotationViewData, isQuotationExtraFieldsAvailable } =
    useQuotationViewData({ isMessenger: true });

  return (
    <Grow in>
      <Box
        id={id}
        flex="0 0 auto"
        my={1}
        display="flex"
        flexDirection="column"
        className="quotation_drop"
      >
        <Box flexGrow={1} display="flex" p={2}>
          {activeQuotationThread && (
            <>
              <SupplierAvatar instigator={activeQuotationThread?.instigator} />

              <Grid
                className="c-chat-dialog--instigator-name"
                container
                direction="column"
              >
                <InstigatorName
                  name={message?.company?.name}
                  time={quotationCreatedAt}
                  companyUUID={activeQuotationThread?.instigator?.uuid}
                />
                <Typography
                  variant="body2"
                  className="c-chat-dialog--instigator-status"
                >
                  {t("inbox:quotationDrop")}
                </Typography>

                {isQuotationExtraFieldsAvailable ? (
                  <Box className="c-message--quotation-details">
                    <QuotationExtraFieldsTable
                      activeQuote={{
                        parts: activeQuotationThread?.quotation?.parts,
                        quotation_extra_fields:
                          quotationViewData?.quotationExtraFields,
                      }}
                      partExtraFields={quotationViewData?.partExtraFields}
                    />
                  </Box>
                ) : (
                  <ul className="c-message--quotation-details">
                    {activeQuotationThread?.quotation?.parts.map((part) => {
                      return (
                        <li>
                          <span>
                            {part.name}{" "}
                            <span className="c-message--part-quantity">
                              x {part.quantity}
                            </span>
                          </span>
                          {part?.price ? (
                            <CurrencyField value={part.quantity * part.price} />
                          ) : (
                            <>
                              <span className="blurred-content"></span>
                            </>
                          )}
                        </li>
                      );
                    })}
                    <li>
                      <span>{t("inbox:fixedFees")}</span>
                      {activeQuotationThread?.quotation?.fixed_charge ? (
                        <CurrencyField
                          value={activeQuotationThread?.quotation?.fixed_charge}
                        />
                      ) : (
                        <>
                          <span className="blurred-content"></span>
                        </>
                      )}
                    </li>
                    <li>
                      <span>{t("inbox:total")}</span>
                      {activeQuotationThread?.quotation?.total_price ? (
                        <CurrencyField
                          value={activeQuotationThread?.quotation?.total_price}
                        />
                      ) : (
                        <>
                          <span className="blurred-content"></span>
                        </>
                      )}
                    </li>
                    <li>
                      <span>{t("inbox:productionDelay")}</span>
                      <LeadTime
                        value={activeQuotationThread?.quotation?.lead_time}
                      />
                    </li>
                  </ul>
                )}
              </Grid>
            </>
          )}
        </Box>
      </Box>
    </Grow>
  );
};

export default QuotationDropMessage;
