import { ListItemIcon, ListItemText, Menu, MenuItem } from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";
import PanToolOutlinedIcon from "@mui/icons-material/PanToolOutlined";
import CalendarTodayOutlinedIcon from "@mui/icons-material/CalendarTodayOutlined";
import PersonAddIcon from "@mui/icons-material/PersonAdd";
import CancelOutlinedIcon from "@mui/icons-material/CancelOutlined";
import UpdateOutlinedIcon from "@mui/icons-material/UpdateOutlined";

export enum ProjectOrderTableMenuSelection {
  PUT_ON_HOLD_REACTIVE = "PUT_ON_HOLD_REACTIVE",
  EXTEND = "EXTEND",
  ADD_COLLABORATORS = "ADD_COLLABORATORS",
  CANCEL = "CANCEL",
}

type Props = {
  anchorEl: Element | null;
  setAnchorEl: (value: Element | null) => void;
  onSelection: (selection: ProjectOrderTableMenuSelection) => () => void;
  disabled: boolean;
  isSelectedRfqOnHold: boolean;
};

export const ProjectOrderTableMenu: React.FC<Props> = ({
  anchorEl,
  setAnchorEl,
  onSelection,
  disabled,
  isSelectedRfqOnHold,
}) => {
  const { t } = useTranslation();

  return (
    <Menu
      keepMounted
      open={Boolean(anchorEl)}
      anchorEl={anchorEl}
      onClose={() => setAnchorEl(null)}
    >
      <MenuItem
        onClick={onSelection(
          ProjectOrderTableMenuSelection.PUT_ON_HOLD_REACTIVE
        )}
        disabled={disabled}
      >
        <ListItemIcon>
          {isSelectedRfqOnHold ? (
            <UpdateOutlinedIcon />
          ) : (
            <PanToolOutlinedIcon />
          )}
        </ListItemIcon>

        <ListItemText
          primary={
            isSelectedRfqOnHold
              ? t("project:table:row:reactive")
              : t("project:table:row:putOnHold")
          }
        />
      </MenuItem>

      <MenuItem
        onClick={onSelection(ProjectOrderTableMenuSelection.EXTEND)}
        disabled={disabled}
      >
        <ListItemIcon>
          <CalendarTodayOutlinedIcon />
        </ListItemIcon>

        <ListItemText primary={t("project:table:row:extendRfq")} />
      </MenuItem>

      <MenuItem
        onClick={onSelection(ProjectOrderTableMenuSelection.ADD_COLLABORATORS)}
        disabled={disabled}
      >
        <ListItemIcon>
          <PersonAddIcon />
        </ListItemIcon>
        <ListItemText primary={t("project:table:row:addCollaborator")} />
      </MenuItem>

      <MenuItem
        onClick={onSelection(ProjectOrderTableMenuSelection.CANCEL)}
        disabled={disabled}
      >
        <ListItemIcon>
          <CancelOutlinedIcon />
        </ListItemIcon>

        <ListItemText primary={t("project:table:row:cancelRfq")} />
      </MenuItem>
    </Menu>
  );
};
