import { Button, styled } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import { CustomTypography } from "@next/components/custom-typography";

export const StyledButton = styled(Button)(({ theme }) => ({
  height: "40px",
  borderRadius: "8px",
  "&.Mui-disabled": {
    color: "white",
    backgroundColor: theme.palette.primary.main,
    opacity: 0.7,
  },
}));

export const StyledAddIcon = styled(AddIcon)({
  width: "24px",
  height: "24px",
});

export const StyledButtonTypography = styled(CustomTypography)({
  lineHeight: "20px",
  letterSpacing: "0.25px",
});
