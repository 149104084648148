import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import { useTracking } from "react-tracking";

import querystring from "query-string";

// Material UI imports
import Checkbox from "@mui/material/Checkbox";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";

// Actions
import { workspaceActions } from "../../services/workspace/workspace.actions";

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

const FilterPartTags = ({ t }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();
  const tracking = useTracking();
  const tags = useSelector((state) => state.workspace.tags);
  const profile = useSelector((state) => state.profile);
  const selectedTags = useSelector((state) => state.workspace.selectedTags);
  const [filterInput, setFilterInput] = useState(false);

  // get selected tags from url and set selelcted chips in the filter on page reload
  const setSelectedTagsFromUrl = () => {
    const queryParams = location.search.replace("?", "");

    // API call to get filtered parts with selected tags from url
    if (queryParams) {
      dispatch(
        workspaceActions.fetchCompanyParts(
          profile.token,
          queryParams.toString()
        )
      );
      const selectedTagsObject = querystring.parse(queryParams);
      const selectedTagsArray = Array.isArray(selectedTagsObject.tag)
        ? selectedTagsObject.tag
        : [selectedTagsObject.tag];
      const tagNames = [];
      selectedTagsArray.forEach((tag) => tagNames.push({ name: tag }));
      dispatch(workspaceActions.setSelectedTags(tagNames));
    }
  };

  // get list of last 5 created tags
  useEffect(() => {
    dispatch(workspaceActions.getTagsList());
  }, [dispatch]);

  // get selected tags from url
  useEffect(() => {
    if (location.search) {
      setSelectedTagsFromUrl();
    } else {
      dispatch(workspaceActions.setSelectedTags([]));
    }
  }, [location.pathname]);

  // API call to get tags list based on user input
  const getTagsChangeHandler = (value) => {
    dispatch(workspaceActions.getTagsList(value));
  };

  // filter parts on tags selection/unselection and change URL
  const handleOnChange = (tagOptions) => {
    dispatch(workspaceActions.setSelectedTags([...tagOptions]));
    const tagNames = [];
    tagOptions.forEach((tag) => {
      tagNames.push(tag.name);
    });
    const queryParams = querystring.stringify({ tag: tagNames });
    dispatch(
      workspaceActions.fetchCompanyParts(profile.token, queryParams.toString())
    );
    history.push(`${location.pathname}?${queryParams}`);
  };

  // concatenate tags with selected tags and remove duplicated item to show in the dropdown
  let tagsWithSelectedTags = [];
  if ((tags && tags.length > 0) || (selectedTags && selectedTags.length > 0)) {
    tagsWithSelectedTags = [...tags, ...selectedTags].filter(
      (v, i, a) => a.findIndex((t) => t.name === v.name) === i
    );
  }

  const filterTagsFocusHandle = () => {
    tracking.trackEvent({
      scout_category: "part_tags",
      scout_feature_name: "filter_part_tags",
      scout_company: profile["company"]["name"],
      scout_username: profile["user"]["username"],
      scout_user_type: profile["user"]["type"],
      scout_date: new Date()
    });
  };

  return (
    <section className="c-filter-part-tags">
      <Autocomplete
        renderTags={() => {
          // remove selected tags count when user start typing in the field to avoid text overlap
          if (!filterInput) {
            // selected tags count
            return selectedTags && selectedTags.length > 1
              ? `${selectedTags.length} ${t("activeTags")}`
              : `${selectedTags.length} ${t("activeTag")}`;
          }
        }}
        value={selectedTags}
        onChange={(event, newValue) => {
          handleOnChange(newValue);
        }}
        onInputChange={(event, newInputValue) => {
          getTagsChangeHandler(newInputValue);
          if (newInputValue) setFilterInput(true);
          else setFilterInput(false);
        }}
        multiple
        id="checkboxes-tags-demo"
        options={tagsWithSelectedTags}
        disableCloseOnSelect
        getOptionLabel={(option) => option.name || ""}
        isOptionEqualToValue={(option, current) => option.name === current.name}
        renderOption={(props, option) => (
          <li {...props}>
            <Checkbox
              icon={icon}
              checkedIcon={checkedIcon}
              style={{ marginLeft: "4px" }}
              checked={selectedTags.some((tag) => tag.name === option.name)}
              color="primary"
            />
            {option.name}
          </li>
        )}
        onFocus={() => filterTagsFocusHandle()}
        style={{ width: "100%" }}
        renderInput={(params) => (
          <TextField {...params} variant="outlined" label={t("filter")} />
        )}
      />
    </section>
  );
};

export default FilterPartTags;
