import React, { useCallback, useState } from "react";
import { Box, Button, IconButton, Typography } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import * as yup from "yup";
import { SimpleTextField } from "components/utils/text-field/text-field.component";
import { useTranslation } from "react-i18next";
import { createStyles, makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) =>
  createStyles({
    subtitle: {
      color: theme.palette.text.primary,
      marginBottom: theme.spacing(3),
    },
    caption: {
      color: theme.palette.text.secondary,
    },
    text: {
      color: theme.palette.text.primary,
      flex: 1,
    },
    button: {
      height: 40,
      marginLeft: theme.spacing(2),
    },
    closeIcon: {
      color: theme.palette.grey[600],
    },
  })
);

type Props = {
  value?: string;
  onAdd?: (value: string) => void;
  onRemove?: (value: string) => void;
};

const InviteTechnicalFocal: React.FC<Props> = ({ value, onAdd, onRemove }) => {
  const classes = useStyles();
  const [text, setText] = useState("");
  const [errorText, setErrorText] = useState("");
  const { t } = useTranslation();
  const validationSchema = yup
    .string()
    .email(t("workspaceNext:inviteTechFocal:error:emailIsInvalid"));

  const _onRemove = () => {
    onRemove && onRemove(value || "");
  };

  const _onAdd = () => {
    validationSchema
      .validate(text)
      .then(() => {
        setErrorText("");
        onAdd && onAdd(text);
      })
      .catch((error) => setErrorText(error.message || ""));
  };

  const handleChange = useCallback((e) => {
    setText(e.target.value);
  }, []);

  return (
    <Box>
      <Typography className={classes.subtitle} variant="subtitle2">
        {t("workspaceNext:inviteTechFocal:subtitleText")}
      </Typography>
      <Typography className={classes.caption} variant="caption">
        {t("workspaceNext:inviteTechFocal:captionText")}
      </Typography>
      <Box mt={3} display="flex" alignItems={value ? "center" : "flex-start"}>
        {!value ? (
          <>
            <SimpleTextField
              onChange={handleChange}
              label={t("workspaceNext:inviteTechFocal:labelEmail")}
              size="small"
              error={!!errorText.length}
              helperText={errorText}
            />
            <Button
              onClick={_onAdd}
              className={classes.button}
              variant="contained"
            >
              {t("workspaceNext:inviteTechFocal:add")}
            </Button>
          </>
        ) : (
          <>
            <Typography className={classes.text} variant="body2">
              {value}
            </Typography>
            <IconButton onClick={_onRemove} size="large">
              <CloseIcon />
            </IconButton>
          </>
        )}
      </Box>
    </Box>
  );
};

export default InviteTechnicalFocal;
