import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { batch, useDispatch, useSelector } from "react-redux";
import moment from "moment";
import { Box, Button, Typography } from "@mui/material";
import CheckIcon from "@mui/icons-material/Check";
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import { getUserLanguage } from "services/profile/profile.selectors";
import ConfirmationDialog from "@next/components/confirmation-dialog/confirmation-dialog";
import { IntentToBidMenuPopup } from "../intent-to-bid/intent-to-bid-menu-popup";
import { PaperProps } from "../intent-to-bid/intent-to-bid.definitions";
import { marketplaceActions } from "services/marketplace";
import { marketplaceActions as marketplaceNextActions } from "../../redux";

import { formatDate } from "helpers/formatDate";
import { useAxyaTracking } from "@next/hooks/useAxyaTracking";
import { IntentToQuoteDueDateProps } from "./intent-to-bid.types";

export const IntentToBidDueDate: React.FC<IntentToQuoteDueDateProps> = ({
  dueDateToBid,
  maxDate,
  rfqDisplayName,
  rfqPk,
  tabIndex,
  isRecommended,
  itnbReason,
  disabled,
  iconOnly,
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const axyaTrack = useAxyaTracking();
  const language = useSelector(getUserLanguage);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [dueDate, setDueDate] = useState<Date | null>(
    dueDateToBid ? dueDateToBid : new Date()
  );
  const isValidDueDate = moment(dueDate).isValid();

  const [isConfirmationDialogOpen, setIsConfirmationDialogOpen] =
    useState(false);
  const open = Boolean(anchorEl);

  const handleDateChange = (date: Date | null) => {
    setDueDate(date);
  };

  const handleClear = () => {
    setIsConfirmationDialogOpen(true);
    setAnchorEl(null);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const confirmationNoHandler = () => {
    setIsConfirmationDialogOpen(false);
    setAnchorEl(null);
  };

  const handleShowPopupMenu = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
    axyaTrack({
      scout_category: "quotation",
      scout_feature_name: "open-due-date-form",
    });
  };

  const handleSubmit = () => {
    setAnchorEl(null);
    const prefs = {
      type: "intent_to_bid_rfqs",
      rfq_pk: rfqPk,
      due_date: moment(dueDate).format("YYYY-MM-DD"),
      rfq_display_name: rfqDisplayName,
    };

    const itbRequest = () => {
      dispatch(
        marketplaceActions.setIntentToBidDueDate(
          prefs,
          false,
          tabIndex,
          dueDateToBid || open ? true : false,
          t,
          isRecommended,
          () => {
            axyaTrack({
              scout_category: "usage_metric",
              scout_rfq_pk: rfqPk,
              scout_action: "itb",
            });
          }
        )
      );
    };

    if (itnbReason) {
      batch(() => {
        dispatch(
          marketplaceNextActions.intentToNoQuoteRequest({
            rfqId: prefs.rfq_pk as number,
            onSuccess: () => {
              itbRequest();
              axyaTrack({
                scout_category: "usage_metric",
                scout_rfq_pk: rfqPk,
                scout_action: "revert-itnb",
              });
            },
          })
        );
        dispatch(
          marketplaceActions.setIntentToNoBidReasonData({
            rfqPk: prefs.rfq_pk,
            slug: null,
            title: null,
          })
        );
      });
    } else {
      itbRequest();
    }
  };

  const confirmationYesHandler = () => {
    const prefs = {
      type: "intent_to_bid_rfqs",
      rfq_pk: rfqPk,
      rfq_display_name: rfqDisplayName,
    };
    dispatch(
      marketplaceActions.setIntentToBidDueDate(
        prefs,
        true,
        tabIndex,
        tabIndex === 1 ? false : true,
        t,
        isRecommended,
        () => {
          axyaTrack({
            scout_category: "usage_metric",
            scout_rfq_pk: rfqPk,
            scout_action: "revert-itb",
          });
        }
      )
    );
    setAnchorEl(null);
    setIsConfirmationDialogOpen(false);
  };

  return (
    <>
      <IntentToBidMenuPopup
        label={
          dueDateToBid ? (
            <>{`${t("workspace:ongoingQuotation")} ${formatDate(
              dueDateToBid
            )}`}</>
          ) : (
            t("quotation:interestedToQuote")
          )
        }
        icon={<CheckIcon />}
        disabled={disabled}
        tooltipTitle={
          dueDateToBid
            ? t("workspace:editDueDate")
            : t("workspace:intendBibHelperText")
        }
        paperProps={{
          style: { ...PaperProps, padding: 24 },
        }}
        status={itnbReason ? undefined : "success"}
        anchorEl={anchorEl}
        open={open}
        handleShowPopupMenu={handleShowPopupMenu}
        onClose={handleClose}
        onDelete={dueDateToBid ? handleClear : undefined}
        iconOnly={iconOnly}
      >
        <Typography variant="body1">
          {t("quotation:intentToBidTitle")}
        </Typography>

        <Box mt={2}>
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <KeyboardDatePicker
              margin="normal"
              id="date-picker-dialog"
              fullWidth
              inputVariant={"outlined"}
              format={language === "en" ? "yyyy/MM/dd" : "dd/MM/yyyy"}
              value={dueDate}
              maxDate={maxDate}
              label={t("workspace:intentToBidLabel")}
              onChange={handleDateChange}
              KeyboardButtonProps={{
                "aria-label": "change date",
              }}
              disablePast
              InputProps={{
                disableUnderline: true,
              }}
            />
          </MuiPickersUtilsProvider>
        </Box>
        <Box
          display="flex"
          alignItems="center"
          justifyContent="flex-end"
          mt={2}
        >
          <Box>
            {(tabIndex !== 0 || dueDateToBid) && (
              <Button
                autoFocus
                onClick={handleClear}
                color="primary"
                variant="outlined"
                className="c-intent-to-bid--clear-date"
              >
                {t("workspace:clearButton")}
              </Button>
            )}
          </Box>
          <Box ml={1}>
            <Button
              autoFocus
              onClick={handleSubmit}
              color="primary"
              variant="contained"
              disabled={!isValidDueDate}
            >
              {t("workspace:submit")}
            </Button>
          </Box>
        </Box>
      </IntentToBidMenuPopup>

      <ConfirmationDialog
        title={t("workspace:confirmation")}
        content={t("workspace:clearIntentToBidConfirmation")}
        isOpen={isConfirmationDialogOpen}
        handleClose={confirmationNoHandler}
        dialogActions={
          <div>
            <Button autoFocus onClick={confirmationNoHandler} color="primary">
              {t("workspace:cancel")}
            </Button>
            <Button onClick={confirmationYesHandler} color="primary">
              {t("RFQ:yes")}
            </Button>
          </div>
        }
      />
    </>
  );
};
