// URL
import { backendUrl } from "../../urls";

// Helper
import { handleResponse } from "../../helpers/responseHandling";
import { axiosApi } from "@next/api";

// Services Exported
export const profileService = {
  createCompany,
  editCompany,
  register,
  recover,
  resetPassword,
  refreshToken,
  login,
  loginCode,
  logout,
  fetchUserProfile,
  fetchCompany,
  resendVerification,
  createProcess,
  createStandard,
  createRating,
  isEmailSubscribed,
  fetchNotifications,
  markNotificationAsRead,
  addFileToCompanyStorage,
  deleteFileFromStorage,
  addCompanyToProfile,
  fetchAddressByPostalCode,
  fetchProfilePreferences,
  editProfilePreferences,
  fetchPostalCode,
};

// Authentification Services
function register(user) {
  return axiosApi.patch(backendUrl.register, user).then(() => {
    return user;
  });
}

function login(email, password) {
  return axiosApi
    .post(backendUrl.login, { email: email?.toLowerCase(), password })
    .then(handleResponse);
}

function loginCode(code, ephemeral_token) {
  return axiosApi
    .post(backendUrl.loginCode, { code, ephemeral_token })
    .then(handleResponse);
}

/*
 *	Logout:
 *	This backend call uses a POST because we are sending the refreshToken in the payload.
 *	This refreshToken coupled with the accesToken sent in the header will allow the blacklisting
 *	of these token to improve the security of the authentication.
 *	@param {string} accessToken: The access token (in the token variable)
 *	@param {string} refreshToken: The token for refreshing the access token
 */
async function logout(refreshToken) {
  return await axiosApi
    .post(backendUrl.logout, { refresh: refreshToken })
    .then(handleResponse);
}

function fetchUserProfile() {
  return axiosApi.get(backendUrl.profileV2).then(handleResponse);
}

function recover(email) {
  return axiosApi
    .post(backendUrl.passwordRecovery, { email })
    .then(handleResponse);
}

function resetPassword(new_password1, new_password2, uid, token) {
  return axiosApi
    .post(backendUrl.passwordReset, {
      new_password1,
      new_password2,
      uid,
      token,
    })
    .then(handleResponse);
}

// Used to refresh the token before it runs out of time
function refreshToken(token) {
  return axiosApi
    .post(backendUrl.refreshToken, { refresh: token })
    .then(handleResponse);
}

function addCompanyToProfile(token, data) {
  return axiosApi.put(backendUrl.profile, data).then(handleResponse);
}

// Company Management Services
function createCompany(token, company) {
  return axiosApi.post(`${backendUrl.company}`, company).then(handleResponse);
}

// Used to fetch the company given a user
function fetchCompany() {
  return axiosApi.get(backendUrl.fetchUserCompany).then(handleResponse);
}

function editCompany(companyPk, token, data) {
  return axiosApi
    .put(`${backendUrl.company}/${companyPk}`, data)
    .then(handleResponse);
}

function resendVerification(email) {
  return axiosApi
    .post(backendUrl.passwordRecoveryConfirmation, { email })
    .then(handleResponse);
}

function createProcess(token, data_process) {
  return axiosApi
    .post(backendUrl.createProcess, data_process)
    .then(handleResponse);
}
function createStandard(token, data_standard) {
  return axiosApi
    .post(backendUrl.createStandard, data_standard)
    .then(handleResponse);
}
function createRating(token, data_rating) {
  return axiosApi
    .post(backendUrl.rateCompany, data_rating)
    .then(handleResponse);
}

function isEmailSubscribed(token) {
  return axiosApi.post(backendUrl.emailSubscribed).then(handleResponse);
}

function fetchNotifications(token) {
  return axiosApi.get(backendUrl.notification).then(handleResponse);
}

function markNotificationAsRead(token, notification_id) {
  return axiosApi
    .post(backendUrl.notificationRead, { notification_id })
    .then(handleResponse);
}

function addFileToCompanyStorage(token, data) {
  return axiosApi.post(backendUrl.workspace, data).then(handleResponse);
}

function deleteFileFromStorage(token, data) {
  return axiosApi
    .delete(backendUrl.workspace, { data: data })
    .then(handleResponse);
}

function fetchAddressByPostalCode(countryCode = "", postalCode = "") {
  return axiosApi
    .post(backendUrl.fetchAddress, {
      country_code: countryCode,
      postal_code: postalCode,
    })
    .then(handleResponse);
}

function fetchPostalCode(countryCode = "", postalCode = "") {
  return axiosApi
    .post(backendUrl.fetchPostalCode, {
      country_code: countryCode,
      postal_code: postalCode,
    })
    .then(handleResponse);
}

function fetchProfilePreferences() {
  return axiosApi.get(backendUrl.profilePreferences).then(handleResponse);
}

function editProfilePreferences(payload) {
  return axiosApi.post(backendUrl.profilePreferences, payload);
}
