import React from "react";
import ConfirmationDialog from "@next/components/confirmation-dialog/confirmation-dialog";
import { useTranslation } from "react-i18next";
import { Button } from "@mui/material";
import { batch, useDispatch, useSelector } from "react-redux";
import { getModal, modalsActions } from "@next/redux/modalsSlices";
import { SharedModalTypes } from "./types";
import { projectActions } from "@next/modules/project/redux";
import { TCancelRfqFormState } from "./components/cancel-rfq-form/shared-cancel-rfq-form.definitions";

export const SharedCancelRfqConfirmationModal: React.FC = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const modal = useSelector(
    getModal(SharedModalTypes.CANCEL_RFQ_CONFIRMATION_MODAL)
  );

  const values = modal?.data as TCancelRfqFormState & {
    rfqPk: number;
    refetchData: boolean;
  };

  const closeModal = () => {
    dispatch(
      modalsActions.closeModal(SharedModalTypes.CANCEL_RFQ_CONFIRMATION_MODAL)
    );
  };

  const onClickYesButton = () => {
    batch(() => {
      dispatch(
        projectActions.updateRFQRequest({
          pk: values?.rfqPk,
          cancellation_note: values?.cancellationNote,
          rfq_cancellation_reason_pk: values?.rfqCancellationReason?.pk,
          refetchData: values?.refetchData,
        })
      );

      closeModal();

      dispatch(modalsActions.closeModal(SharedModalTypes.CANCEL_RFQ_MODAL));
    });
  };

  return (
    <ConfirmationDialog
      title={t("workspace:confirmation")}
      content={t("workspace:cancelRFQConfirmation")}
      isOpen={!!modal}
      handleClose={closeModal}
      dialogActions={
        <div>
          <Button autoFocus onClick={closeModal} color="primary">
            {t("workspace:cancel")}
          </Button>
          <Button onClick={onClickYesButton} color="primary">
            {t("RFQ:yes")}
          </Button>
        </div>
      }
    />
  );
};
