import React, { useCallback, useEffect, useState } from "react";
import { GridCellParams } from "@mui/x-data-grid-pro-v5";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import {
  PartialStep,
  Questionnaire,
  SubtiersFormData,
  vendorManagementActions
} from "@next/modules/vendor-management/redux";
import {
  selectAddSpecToScopeLoading,
  selectFormData,
  selectIsFormCompleted,
  selectQuestionnaireName,
  selectRemoveSpecFromScopeLoading,
  selectSpecsOfScope,
  selectSpecsOfScopeLoading,
  selectVisibleSteps
} from "@next/modules/vendor-management/redux/selectors";
import { getScopeFormTableColumns } from "./scope-form-table.columns";
import { getActiveStepIndex } from "@next/modules/vendor-management/utils/getActiveStepIndex";
import * as S from "./scope-form-table.styled";

type Props = {
  hideCompletedTableLines: boolean;
  page: number;
  setPage: React.Dispatch<React.SetStateAction<number>>;
  pageSize: number;
};

export const ScopeFormTable: React.FC<Props> = ({
  hideCompletedTableLines,
  page,
  setPage,
  pageSize
}) => {
  const { companyId } = useParams<{ companyId: string }>();
  const dispatch = useDispatch();
  const steps = useSelector(selectVisibleSteps);
  const isFormCompleted = useSelector(selectIsFormCompleted);
  const activeFormStepIndex = getActiveStepIndex(steps);
  const step = steps[activeFormStepIndex];
  const specsOfScopeData = useSelector(selectSpecsOfScope);
  const questionnaireName = useSelector(selectQuestionnaireName);
  const isTableInPomDistributorQuestionnaire =
    questionnaireName === Questionnaire.PomDistributor;
  const rows = specsOfScopeData?.results?.data;
  const isLoadingFetchSpecs = useSelector(selectSpecsOfScopeLoading);
  const isLoadingRowDelete = useSelector(selectRemoveSpecFromScopeLoading);
  const isLoadingAddSpecToScopeTable = useSelector(selectAddSpecToScopeLoading);

  const [searchValue, setSearchValue] = useState<string>("");
  const formData = useSelector(selectFormData) as SubtiersFormData;
  useEffect(() => {
    if (formData) {
      dispatch(
        vendorManagementActions.fetchSubtierCompaniesRequest(searchValue)
      );
    }
  }, [searchValue]);

  const columns = getScopeFormTableColumns(
    isTableInPomDistributorQuestionnaire,
    searchValue,
    setSearchValue
  );
  const handleCellClick = useCallback(
    (params: GridCellParams) => {
      if (params.field === "removeRowButton") {
        const id = params.row.specification_id;
        dispatch(
          vendorManagementActions.removeSpecFromScopeRequest({
            companyId: companyId,
            specification_id: id,
            onSuccess: onRemoveSuccess
          })
        );
        return;
      }
    },
    [isFormCompleted, rows]
  );

  const onRemoveSuccess = () => {
    reFetchTableDataToGetUpdatedCount();
    checkInputChange();
  };

  const reFetchTableDataToGetUpdatedCount = () => {
    dispatch(
      vendorManagementActions.fetchSpecsOfScopeRequest({
        companyId,
        pagination: { page: page, pageSize: pageSize },
        isInPomDistributor: isTableInPomDistributorQuestionnaire,
        stepId: step.step,
        filter_completed: hideCompletedTableLines,
        isForFetchingToGetCountData: true
      })
    );
  };

  const checkInputChange = () => {
    if (isFormCompleted) {
      updateFieldStatusOfStep("is_completed", false, activeFormStepIndex);
    } else {
      updateFieldStatusOfStep("is_partially_filled", true, activeFormStepIndex);
    }
  };

  const updateFieldStatusOfStep = (
    key: "is_completed" | "is_partially_filled",
    value: boolean,
    stepIndex: number
  ) => {
    const step = steps[stepIndex];
    const partialStep: PartialStep = {
      [key]: value
    };
    dispatch(
      vendorManagementActions.updateStepRequest({
        stepId: step.id,
        data: partialStep
      })
    );
  };

  return (
    <>
      <S.StyledDataGridPro
        columns={columns}
        rows={rows || []}
        headerHeight={48}
        rowHeight={32}
        disableSelectionOnClick
        onCellClick={handleCellClick}
        getRowId={(row) => row.specification_id}
        loading={
          isLoadingFetchSpecs ||
          isLoadingRowDelete ||
          isLoadingAddSpecToScopeTable
        }
        pagination
        paginationMode="server"
        pageSize={pageSize}
        page={page - 1}
        rowCount={specsOfScopeData?.count ? specsOfScopeData.count : 0}
        rowsPerPageOptions={[pageSize]}
        onPageChange={(newPage) => setPage(newPage + 1)}
        autoHeight
      />
    </>
  );
};
