import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import {
  AclpClassification,
  AddOrUpdateProcessControlStandard,
  AddSpecToScopeRequest,
  AddSpecToSubtierSupplier,
  AddSpecToSubtierSupplierSuccess,
  ApprovalFormData,
  BufferStockStrategyTableRow,
  DeleteProcessControlStandard,
  DeleteSpecOfSubtierSupplier,
  FetchDesignAndBuiltDrawings,
  FetchDesignAndBuiltDrawingsResponseWithPayload,
  FetchFormDataRequest,
  FetchSpecsOfBufferStockInput,
  FetchSpecsOfBufferStockResponseWithPayload,
  FetchSpecsOfLeadTimeProductionInput,
  FetchSpecsOfLeadTimeProductionResponseWithPayload,
  FetchSpecsOfScopeResponseWithPayload,
  FetchSpecsOfSubtierSupplier,
  FetchStepsRequest,
  FetchSubtierCompaniesResponse,
  FileObj,
  IdentificationFormData,
  InternationalStandardTableRow,
  LeadTimeForImplementationFormData,
  LeadTimeForProductionTableRow,
  PaginationAndFilterForScopeSpecs,
  PaginationAndFilterForSpecification,
  Questionnaire,
  RemoveSpecFromScopeRequest,
  SaveAdditionalComment,
  SaveSpecOfScopeRequest,
  SaveSpecOfScopeSuccess,
  SendSupportMessageInput,
  SetQuestionnaireFormStepData,
  SpecOfSubtierSupplier,
  Specification,
  SpecificationTableRow,
  Step,
  SubtierOnboardingTableRow,
  SubtiersFormData,
  SupplierCategory,
  UpdateApprovalFormFile,
  UpdateDesignAndBuildDrawing,
  UpdateDesignAndBuildDrawingSuccess,
  UpdateFormDataRequest,
  UpdateIdentificationDataBody,
  UpdateLeadTimeImplFormData,
  UpdateSpecOfBufferStockStrategy,
  UpdateSpecOfLeadTimeForProduction,
  UpdateSpecOfSubtierSupplier,
  UpdateSpecOfSubtierSupplierSuccess,
  UpdateStepRequest,
  UpdateStepRequestSuccess,
  UpdateSubtierFormData,
  UpdateSubtierOnboardingTableRow,
  UpdateSubtiersOnboardingFormData,
  VendorManagementState,
} from "./types";
import { orderSteps } from "../utils/orderSteps";

export const initialState: VendorManagementState = {
  formDataLoading: false,
  formDataError: undefined,
  formData: undefined,

  updateFormDataLoading: false,
  updateFormDataError: undefined,

  fetchStepsLoading: false,
  fetchStepsError: undefined,

  updateStepLoading: false,
  updateStepError: undefined,

  processControlStandardsLoading: false,
  processControlStandardsError: undefined,
  processControlStandardsData: undefined,

  allSpecificationsLoading: false,
  allSpecificationsError: undefined,
  allSpecificationsData: undefined,

  deleteProcessControlStandardLoading: false,
  deleteProcessControlStandardError: undefined,

  addProcessControlStandardLoading: false,
  addProcessControlStandardError: undefined,

  updateProcessControlStandardLoading: false,
  updateProcessControlStandardError: undefined,

  updateApprovalFormFileLoading: false,
  updateApprovalFormFileError: undefined,

  deleteApprovalFormFileLoading: false,
  deleteApprovalFormFileError: undefined,

  aclpClassificationsLoading: false,
  aclpClassificationsError: undefined,
  aclpClassificationsData: undefined,

  supplierCategoriesLoading: false,
  supplierCategoriesError: undefined,
  supplierCategoriesData: undefined,

  specsOfScopeLoading: false,
  specsOfScopeError: undefined,
  specsOfScopeData: undefined,

  removeSpecFromScopeLoading: false,
  removeSpecFromScopeError: undefined,

  saveAdditionalCommentLoading: false,
  saveAdditionalCommentError: undefined,

  addSpecToScopeLoading: false,
  addSpecToScopeError: undefined,

  saveSpecOfScopeLoading: false,
  saveSpecOfScopeError: undefined,

  updateLeadTimeImplFormDataLoading: false,
  updateLeadTimeImplFormDataError: undefined,

  updateSubtierFormDataLoading: false,
  updateSubtierFormDataError: undefined,

  subtierCompaniesLoading: false,
  subtierCompaniesError: undefined,
  subtierCompaniesData: undefined,

  specsOfSubtierSupplierLoading: false,
  specsOfSubtierSupplierError: undefined,
  specsOfSubtierSuppliersData: undefined,

  updateSpecOfSubtierSupplierLoading: false,
  updateSpecOfSubtierSupplierError: undefined,

  deleteSpecOfSubtierSupplierLoading: false,
  deleteSpecOfSubtierSupplierError: undefined,

  addSpecToSubtierSupplierLoading: false,
  addSpecToSubtierSupplierError: undefined,

  updateSubtiersOnboardingFormDataLoading: false,
  updateSubtiersOnboardingFormDataError: undefined,

  subtierOnboardingTableDataLoading: false,
  subtierOnboardingTableDataError: undefined,
  subtierOnboardingTableData: undefined,

  updateSubtierOnboardingTableRowLoading: false,
  updateSubtierOnboardingTableRowError: undefined,

  designAndBuiltDrawingsLoading: false,
  designAndBuiltDrawingsError: undefined,
  designAndBuiltDrawingsData: undefined,

  udpateDesignAndBuiltDrawingLoading: false,
  udpateDesignAndBuiltDrawingError: undefined,

  specsOfLeadTimeForProductionLoading: false,
  specsOfLeadTimeForProductionError: undefined,
  specsOfLeadTimeForProductionData: undefined,

  updateSpecOfLeadTimeForProductionLoading: false,
  updateSpecOfLeadTimeForProductionError: undefined,

  specsOfBufferStockStrategyLoading: false,
  specsOfBufferStockStrategyError: undefined,
  specsOfBufferStockStrategyData: undefined,

  updateSpecOfBufferStockStrategyLoading: false,
  updateSpecOfBufferStockStrategyError: undefined,

  suppliersLoading: false,
  suppliersError: undefined,
  suppliersData: undefined,

  suppliersSearchByCompanyIdLoading: false,
  suppliersSearchByCompanyIdError: undefined,
  supplierCompanyDetails: undefined,

  sendingSupportMessageLoading: false,
  sendingSupportMessageError: undefined,

  identificationDataLoading: false,
  identificationDataError: undefined,
  identificationData: undefined,

  updateIdentificationDataLoading: false,
  updateIdentificationDataError: undefined,

  posEndUsersQuestionnaire: {
    name: Questionnaire.PosEndUsers,
    steps: [],

    lastSavedDate: "",
    activeFormStepIndex: -1,
  },
  pomManufacturerQuestionnaire: {
    name: Questionnaire.PomManufacturer,
    steps: [],
    lastSavedDate: "",
    activeFormStepIndex: -1,
  },
  pomDistributorQuestionnaire: {
    name: Questionnaire.PomDistributor,
    steps: [],
    lastSavedDate: "",
    activeFormStepIndex: -1,
  },
  currentQuestionnaireName: Questionnaire.PosEndUsers,
  isThereUnsavedChangesInFormStep: false,
  isSupplierApproved: null,
};

export const vendorManagementSlice = createSlice({
  name: "vendorManagement",
  initialState,
  reducers: {
    sendSupportMessageRequest: (
      state,
      action: PayloadAction<SendSupportMessageInput>
    ) => {
      state.sendingSupportMessageLoading = true;
    },
    sendSupportMessageFailure: (state, action: PayloadAction<Error>) => {
      state.sendingSupportMessageLoading = false;
      state.sendingSupportMessageError = action.payload;
    },
    sendSupportMessageSuccess: (state) => {
      state.sendingSupportMessageLoading = false;
      state.sendingSupportMessageError = undefined;
    },
    fetchFormDataRequest: (
      state,
      action: PayloadAction<FetchFormDataRequest>
    ) => {
      state.formDataLoading = true;
      state.formDataError = undefined;
      if (!action.payload.fetcha2psRequest) {
        state.formData = undefined;
      }
    },
    fetchFormDataFailure: (state, action: PayloadAction<Error>) => {
      state.formDataLoading = false;
      state.formDataError = action.payload;
    },
    fetchFormDataSuccess: (
      state,
      action: PayloadAction<SetQuestionnaireFormStepData>
    ) => {
      const { data } = action.payload;
      if (action.payload?.fetcha2psRequest) {
        // There is a check on Scope step for A2P process.
        // Need to load formdata for A2P process also to implement that validation
        state.formData = {
          ...data,
          ...state.formData,
        };
      } else {
        state.formData = data;
      }
      state.formDataLoading = false;
      state.formDataError = undefined;
    },
    makeFormDataUndefined: (state) => {
      state.formData = undefined;
    },
    updateFormDataRequest: (
      state,
      action: PayloadAction<UpdateFormDataRequest>
    ) => {
      state.updateFormDataLoading = true;
      state.updateFormDataError = undefined;
    },
    updateFormDataFailure: (state, action: PayloadAction<Error>) => {
      state.updateFormDataLoading = false;
      state.updateFormDataError = action.payload;
    },
    updateFormDataSuccess: (
      state,
      action: PayloadAction<SetQuestionnaireFormStepData>
    ) => {
      const { data } = action.payload;

      state.formData = data;
      state.updateFormDataLoading = false;
      state.updateFormDataError = undefined;
      state.isThereUnsavedChangesInFormStep = false;
    },
    fetchStepsRequest: (state, action: PayloadAction<FetchStepsRequest>) => {
      state.fetchStepsLoading = true;
      state.fetchStepsError = undefined;
    },
    fetchStepsFailure: (state, action: PayloadAction<Error>) => {
      state.fetchStepsLoading = false;
      state.fetchStepsError = action.payload;
    },
    fetchStepsSuccess: (state, action: PayloadAction<Step[]>) => {
      const currentQuestionnaire = state[state.currentQuestionnaireName];
      state.fetchStepsLoading = false;
      state.fetchStepsError = undefined;
      currentQuestionnaire.steps = orderSteps(action.payload);
    },
    updateStepRequest: (state, action: PayloadAction<UpdateStepRequest>) => {
      state.updateStepLoading = true;
      state.updateStepError = undefined;
    },
    updateStepFailure: (state, action: PayloadAction<Error>) => {
      state.updateStepLoading = false;
      state.updateStepError = action.payload;
    },
    updateStepSuccess: (
      state,
      action: PayloadAction<UpdateStepRequestSuccess>
    ) => {
      const currentQuestionnaire = state[state.currentQuestionnaireName];
      let updatedSteps;
      if (action.payload.isForActivenessChange) {
        updatedSteps = currentQuestionnaire.steps.map((step) => {
          if (step.id === action.payload.stepId) {
            return { ...step, ...action.payload.data };
          }
          return { ...step, is_active: false };
        });
      } else {
        updatedSteps = currentQuestionnaire.steps.map((step) => {
          if (step.id === action.payload.stepId) {
            return { ...step, ...action.payload.data };
          }
          return step;
        });
      }
      const orderedSteps = orderSteps(updatedSteps);
      currentQuestionnaire.steps = orderedSteps;
      // active step changed, so update activeFormStepIndex
      if (action.payload.isForActivenessChange) {
        const visibleSteps = orderedSteps.filter((step) => step.is_visible);
        if (action.payload.data.is_active) {
          const activeFormStepIndex = visibleSteps.findIndex(
            (step) => step.is_active
          );
          currentQuestionnaire.activeFormStepIndex = activeFormStepIndex;
        }
      }
      state.updateStepLoading = false;
      state.updateStepError = undefined;
    },
    fetchProcessControlStandardsRequest: (
      state,
      action: PayloadAction<string>
    ) => {
      state.processControlStandardsLoading = true;
      state.processControlStandardsError = undefined;
    },
    fetchProcessControlStandardsFailure: (
      state,
      action: PayloadAction<Error>
    ) => {
      state.processControlStandardsLoading = false;
      state.processControlStandardsError = action.payload;
    },
    fetchProcessControlStandardsSuccess: (
      state,
      action: PayloadAction<InternationalStandardTableRow[]>
    ) => {
      state.processControlStandardsLoading = false;
      state.processControlStandardsError = undefined;
      state.processControlStandardsData = action.payload;
    },
    fetchAllSpecificationsRequest: (
      state,
      action: PayloadAction<PaginationAndFilterForSpecification>
    ) => {
      state.allSpecificationsLoading = true;
      state.allSpecificationsError = undefined;
    },
    fetchAllSpecificationsFailure: (state, action: PayloadAction<Error>) => {
      state.allSpecificationsLoading = false;
      state.allSpecificationsError = action.payload;
    },
    fetchAllSpecificationsSuccess: (
      state,
      action: PayloadAction<Specification>
    ) => {
      state.allSpecificationsLoading = false;
      state.allSpecificationsError = undefined;
      state.allSpecificationsData = action.payload;
    },
    deleteProcessControlStandardRequest: (
      state,
      action: PayloadAction<DeleteProcessControlStandard>
    ) => {
      state.deleteProcessControlStandardLoading = true;
      state.deleteProcessControlStandardError = undefined;
    },
    deleteProcessControlStandardFailure: (
      state,
      action: PayloadAction<Error>
    ) => {
      state.deleteProcessControlStandardLoading = false;
      state.deleteProcessControlStandardError = action.payload;
    },
    deleteProcessControlStandardSuccess: (
      state,
      action: PayloadAction<DeleteProcessControlStandard>
    ) => {
      state.deleteProcessControlStandardLoading = false;
      state.deleteProcessControlStandardError = undefined;
      state.processControlStandardsData =
        state.processControlStandardsData?.filter(
          (standard) => standard.standard_id !== action.payload.standardId
        );
    },
    addProcessControlStandardRequest: (
      state,
      action: PayloadAction<AddOrUpdateProcessControlStandard>
    ) => {
      state.addProcessControlStandardLoading = true;
      state.addProcessControlStandardError = undefined;
    },
    addProcessControlStandardFailure: (state, action: PayloadAction<Error>) => {
      state.addProcessControlStandardLoading = false;
      state.addProcessControlStandardError = action.payload;
    },
    addProcessControlStandardSuccess: (
      state,
      action: PayloadAction<InternationalStandardTableRow>
    ) => {
      state.addProcessControlStandardLoading = false;
      state.addProcessControlStandardError = undefined;
      state.processControlStandardsData = [
        ...state.processControlStandardsData,
        action.payload,
      ];
    },
    updateProcessControlStandardRequest: (
      state,
      action: PayloadAction<AddOrUpdateProcessControlStandard>
    ) => {
      state.updateProcessControlStandardLoading = true;
      state.updateProcessControlStandardError = undefined;
    },
    updateProcessControlStandardFailure: (
      state,
      action: PayloadAction<Error>
    ) => {
      state.updateProcessControlStandardLoading = false;
      state.updateProcessControlStandardError = action.payload;
    },
    updateProcessControlStandardSuccess: (
      state,
      action: PayloadAction<InternationalStandardTableRow>
    ) => {
      state.updateProcessControlStandardLoading = false;
      state.updateProcessControlStandardError = undefined;
      state.processControlStandardsData =
        state.processControlStandardsData?.map((standard) => {
          if (standard.standard_id === action.payload.standard_id) {
            return action.payload;
          }
          return standard;
        });
    },
    updateApprovalFormFileRequest: (
      state,
      action: PayloadAction<UpdateApprovalFormFile>
    ) => {
      state.updateApprovalFormFileLoading = true;
      state.updateApprovalFormFileError = undefined;
    },
    updateApprovalFormFileFailure: (state, action: PayloadAction<Error>) => {
      state.updateApprovalFormFileLoading = false;
      state.updateApprovalFormFileError = action.payload;
    },
    updateApprovalFormFileSuccess: (state, action: PayloadAction<FileObj>) => {
      state.formData = (state.formData as ApprovalFormData)?.map((fileObj) => {
        if (fileObj.field_name === action.payload.field_name) {
          return action.payload;
        }
        return fileObj;
      });
      state.updateApprovalFormFileLoading = false;
      state.updateApprovalFormFileError = undefined;
    },
    deleteApprovalFormFileRequest: (state, action: PayloadAction<string>) => {
      state.deleteApprovalFormFileLoading = true;
      state.deleteApprovalFormFileError = undefined;
    },
    deleteApprovalFormFileFailure: (state, action: PayloadAction<Error>) => {
      state.deleteApprovalFormFileLoading = false;
      state.deleteApprovalFormFileError = action.payload;
    },
    deleteApprovalFormFileSuccess: (state, action: PayloadAction<string>) => {
      state.formData = (state.formData as ApprovalFormData)?.map((fileObj) => {
        if (fileObj.file_id === action.payload) {
          return {
            ...fileObj,
            file_url: null,
            file_id: null,
            file_name: null,
            file_type: null,
            file_size: null,
          };
        }

        return fileObj;
      });
      state.deleteApprovalFormFileLoading = false;
      state.deleteApprovalFormFileError = undefined;
    },
    fetchAclpClassificationsRequest: (state) => {
      state.aclpClassificationsLoading = true;
      state.aclpClassificationsError = undefined;
    },
    fetchAclpClassificationsFailure: (state, action: PayloadAction<Error>) => {
      state.aclpClassificationsLoading = false;
      state.aclpClassificationsError = action.payload;
    },
    fetchAclpClassificationsSuccess: (
      state,
      action: PayloadAction<AclpClassification[]>
    ) => {
      state.aclpClassificationsLoading = false;
      state.aclpClassificationsError = undefined;
      state.aclpClassificationsData = action.payload;
    },
    fetchSupplierCategoriesRequest: (state) => {
      state.supplierCategoriesLoading = true;
      state.supplierCategoriesError = undefined;
    },
    fetchSupplierCategoriesFailure: (state, action: PayloadAction<Error>) => {
      state.supplierCategoriesLoading = false;
      state.supplierCategoriesError = action.payload;
    },
    fetchSupplierCategoriesSuccess: (
      state,
      action: PayloadAction<SupplierCategory[]>
    ) => {
      state.supplierCategoriesLoading = false;
      state.supplierCategoriesError = undefined;
      state.supplierCategoriesData = action.payload;
    },
    fetchSpecsOfScopeRequest: (
      state,
      action: PayloadAction<PaginationAndFilterForScopeSpecs>
    ) => {
      if (!action.payload.isForFetchingToGetCountData) {
        state.specsOfScopeLoading = true;
        state.specsOfScopeError = undefined;
        state.specsOfScopeData = {
          count: 0,
          next: null,
          previous: null,
          results: {
            data: [],
            total_count: 0,
            completed_count: 0,
            uncompleted_count: 0,
          },
        };
      }
    },
    fetchSpecsOfScopeFailure: (state, action: PayloadAction<Error>) => {
      state.specsOfScopeLoading = false;
      state.specsOfScopeError = action.payload;
    },
    fetchSpecsOfScopeSuccess: (
      state,
      action: PayloadAction<FetchSpecsOfScopeResponseWithPayload>
    ) => {
      state.specsOfScopeLoading = false;
      state.specsOfScopeError = undefined;
      if (action.payload.isForFetchingToGetCountData) {
        state.specsOfScopeData = {
          ...state.specsOfScopeData,
          count: action.payload.response.count,
          results: {
            ...state.specsOfScopeData.results,
            total_count: action.payload.response.results.total_count,
            completed_count: action.payload.response.results.completed_count,
            uncompleted_count:
              action.payload.response.results.uncompleted_count,
          },
        };
      } else {
        state.specsOfScopeData = action.payload.response;
      }
    },
    removeSpecFromScopeRequest: (
      state,
      action: PayloadAction<RemoveSpecFromScopeRequest>
    ) => {
      state.removeSpecFromScopeLoading = true;
      state.removeSpecFromScopeError = undefined;
    },
    removeSpecFromScopeFailure: (state, action: PayloadAction<Error>) => {
      state.removeSpecFromScopeLoading = false;
      state.removeSpecFromScopeError = action.payload;
    },
    removeSpecFromScopeSuccess: (state, action: PayloadAction<number>) => {
      state.removeSpecFromScopeLoading = false;
      state.removeSpecFromScopeError = undefined;
      state.specsOfScopeData = {
        ...state.specsOfScopeData,
        count: state.specsOfScopeData.count - 1,
        results: {
          ...state.specsOfScopeData.results,
          data: state.specsOfScopeData?.results?.data.filter(
            (spec) => spec.specification_id !== action.payload
          ),
        },
      };
    },
    saveAdditionalCommentFieldOfFormRequest: (
      state,
      action: PayloadAction<SaveAdditionalComment>
    ) => {
      state.saveAdditionalCommentLoading = true;
      state.saveAdditionalCommentError = undefined;
    },
    saveAdditionalCommentFieldOfFormFailure: (
      state,
      action: PayloadAction<Error>
    ) => {
      state.saveAdditionalCommentLoading = false;
      state.saveAdditionalCommentError = action.payload;
    },
    addSpecToScopeRequest: (
      state,
      action: PayloadAction<AddSpecToScopeRequest>
    ) => {
      state.addSpecToScopeLoading = true;
      state.addSpecToScopeError = undefined;
    },
    addSpecToScopeFailure: (state, action: PayloadAction<Error>) => {
      state.addSpecToScopeLoading = false;
      state.addSpecToScopeError = action.payload;
    },
    addSpecToScopeSuccess: (
      state,
      action: PayloadAction<SpecificationTableRow>
    ) => {
      state.addSpecToScopeLoading = false;
      state.addSpecToScopeError = undefined;
      state.specsOfScopeData = {
        ...state.specsOfScopeData,
        results: {
          ...state.specsOfScopeData.results,
          data: [...state.specsOfScopeData?.results?.data, action.payload],
        },
      };
    },
    saveSpecOfScopeRequest: (
      state,
      action: PayloadAction<SaveSpecOfScopeRequest>
    ) => {
      state.saveSpecOfScopeLoading = true;
      state.saveSpecOfScopeError = undefined;
    },
    saveSpecOfScopeFailure: (state, action: PayloadAction<Error>) => {
      state.saveSpecOfScopeLoading = false;
      state.saveSpecOfScopeError = action.payload;
    },
    saveSpecOfScopeSuccess: (
      state,
      action: PayloadAction<SaveSpecOfScopeSuccess>
    ) => {
      state.saveSpecOfScopeLoading = false;
      state.saveSpecOfScopeError = undefined;
      state.specsOfScopeData = {
        ...state.specsOfScopeData,
        results: {
          ...state.specsOfScopeData.results,
          data: state.specsOfScopeData?.results?.data.map((spec) =>
            spec.specification_id === action.payload.specificationId
              ? { ...spec, ...action.payload.spec }
              : spec
          ),
        },
      };
    },
    updateLeadTimeImplFormDataRequest: (
      state,
      action: PayloadAction<UpdateLeadTimeImplFormData>
    ) => {
      state.saveSpecOfScopeLoading = true;
      state.saveSpecOfScopeError = undefined;
    },
    updateLeadTimeImplFormDataFailure: (
      state,
      action: PayloadAction<Error>
    ) => {
      state.saveSpecOfScopeLoading = false;
      state.saveSpecOfScopeError = action.payload;
    },
    updateLeadTimeImplFormDataSuccess: (
      state,
      action: PayloadAction<LeadTimeForImplementationFormData>
    ) => {
      state.saveSpecOfScopeLoading = false;
      state.saveSpecOfScopeError = undefined;
      state.formData = action.payload;
    },
    updateSubtierFormDataRequest: (
      state,
      action: PayloadAction<UpdateSubtierFormData>
    ) => {
      state.updateSubtierFormDataLoading = true;
      state.updateSubtierFormDataError = undefined;
    },
    updateSubtierFormDataFailure: (state, action: PayloadAction<Error>) => {
      state.updateSubtierFormDataLoading = false;
      state.updateSubtierFormDataError = action.payload;
    },
    updateSubtierFormDataSuccess: (
      state,
      action: PayloadAction<SubtiersFormData>
    ) => {
      state.updateSubtierFormDataLoading = false;
      state.updateSubtierFormDataError = undefined;
      state.formData = action.payload;

      const subtierIds = (state.formData as SubtiersFormData).subtier_suppliers;
      if (state.specsOfSubtierSuppliersData) {
        state.specsOfSubtierSuppliersData =
          state.specsOfSubtierSuppliersData.filter((spec) =>
            subtierIds.includes(spec.supplier_id)
          );
      }
    },
    fetchSubtierCompaniesRequest: (state, action: PayloadAction<string>) => {
      state.subtierCompaniesLoading = true;
      state.subtierCompaniesError = undefined;
    },
    fetchSubtierCompaniesFailure: (state, action: PayloadAction<Error>) => {
      state.subtierCompaniesLoading = false;
      state.subtierCompaniesError = action.payload;
    },
    fetchSubtierCompaniesSuccess: (
      state,
      action: PayloadAction<FetchSubtierCompaniesResponse>
    ) => {
      state.subtierCompaniesLoading = false;
      state.subtierCompaniesError = undefined;
      state.subtierCompaniesData = action.payload;
    },
    fetchSpecsOfSubtierSupplierRequest: (
      state,
      action: PayloadAction<FetchSpecsOfSubtierSupplier>
    ) => {
      state.specsOfSubtierSupplierLoading = true;
      state.specsOfSubtierSupplierError = undefined;
    },
    fetchSpecsOfSubtierSupplierFailure: (
      state,
      action: PayloadAction<Error>
    ) => {
      state.specsOfSubtierSupplierLoading = false;
      state.specsOfSubtierSupplierError = action.payload;
    },
    fetchSpecsOfSubtierSupplierSuccess: (
      state,
      action: PayloadAction<SpecOfSubtierSupplier>
    ) => {
      state.specsOfSubtierSupplierLoading = false;
      state.specsOfSubtierSupplierError = undefined;

      const newSubtierSupplier = action.payload;
      const existingIndex = state.specsOfSubtierSuppliersData?.findIndex(
        (supplier) => supplier.supplier_id === newSubtierSupplier.supplier_id
      );

      if (existingIndex !== -1 && existingIndex !== undefined) {
        state.specsOfSubtierSuppliersData![existingIndex] = newSubtierSupplier;
      } else {
        if (state.specsOfSubtierSuppliersData) {
          state.specsOfSubtierSuppliersData.push(newSubtierSupplier);
        } else {
          state.specsOfSubtierSuppliersData = [newSubtierSupplier];
        }
      }
    },
    updateSubtiersOnboardingFormDataRequest: (
      state,
      action: PayloadAction<UpdateSubtiersOnboardingFormData>
    ) => {
      state.updateSubtiersOnboardingFormDataLoading = true;
      state.updateSubtiersOnboardingFormDataError = undefined;
    },
    updateSubtiersOnboardingFormDataFailure: (
      state,
      action: PayloadAction<Error>
    ) => {
      state.updateSubtiersOnboardingFormDataLoading = false;
      state.updateSubtiersOnboardingFormDataError = action.payload;
    },
    updateSpecOfSubtierSupplierRequest: (
      state,
      action: PayloadAction<UpdateSpecOfSubtierSupplier>
    ) => {
      state.updateSpecOfSubtierSupplierLoading = true;
      state.updateSpecOfSubtierSupplierError = undefined;
    },
    updateSpecOfSubtierSupplierFailure: (
      state,
      action: PayloadAction<Error>
    ) => {
      state.updateSpecOfSubtierSupplierLoading = false;
      state.updateSpecOfSubtierSupplierError = action.payload;
    },
    updateSpecOfSubtierSupplierSuccess: (
      state,
      action: PayloadAction<UpdateSpecOfSubtierSupplierSuccess>
    ) => {
      state.updateSpecOfSubtierSupplierLoading = false;
      state.updateSpecOfSubtierSupplierError = undefined;
      const { supplierId, specificationId, spec } = action.payload;
      state.specsOfSubtierSuppliersData = state.specsOfSubtierSuppliersData.map(
        (supplier) => {
          if (supplier.supplier_id === supplierId) {
            return {
              ...supplier,
              rows: supplier.rows.map((row) => {
                if (row.specification_id === specificationId) {
                  return {
                    ...row,
                    ...spec,
                  };
                }
                return row;
              }),
            };
          }
          return supplier;
        }
      );
    },
    deleteSpecOfSubtierSupplierRequest: (
      state,
      action: PayloadAction<DeleteSpecOfSubtierSupplier>
    ) => {
      state.deleteSpecOfSubtierSupplierLoading = true;
      state.deleteSpecOfSubtierSupplierError = undefined;
    },
    deleteSpecOfSubtierSupplierFailure: (
      state,
      action: PayloadAction<Error>
    ) => {
      state.deleteSpecOfSubtierSupplierLoading = false;
      state.deleteSpecOfSubtierSupplierError = action.payload;
    },
    deleteSpecOfSubtierSupplierSuccess: (
      state,
      action: PayloadAction<DeleteSpecOfSubtierSupplier>
    ) => {
      state.deleteSpecOfSubtierSupplierLoading = false;
      state.deleteSpecOfSubtierSupplierError = undefined;
      state.specsOfSubtierSuppliersData = state.specsOfSubtierSuppliersData.map(
        (supplier) => {
          if (supplier.supplier_id === action.payload.supplierId) {
            return {
              ...supplier,
              rows: supplier.rows.filter(
                (row) => row.id !== action.payload.specificationId
              ),
            };
          }
          return supplier;
        }
      );
    },
    addSpecToSubtierSupplierRequest: (
      state,
      action: PayloadAction<AddSpecToSubtierSupplier>
    ) => {
      state.addSpecToSubtierSupplierLoading = true;
      state.addSpecToSubtierSupplierError = undefined;
    },
    addSpecToSubtierSupplierFailure: (state, action: PayloadAction<Error>) => {
      state.addSpecToSubtierSupplierLoading = false;
      state.addSpecToSubtierSupplierError = action.payload;
    },
    addSpecToSubtierSupplierSuccess: (
      state,
      action: PayloadAction<AddSpecToSubtierSupplierSuccess>
    ) => {
      state.addSpecToSubtierSupplierLoading = false;
      state.addSpecToSubtierSupplierError = undefined;
      const { supplierId, spec } = action.payload;
      state.specsOfSubtierSuppliersData = state.specsOfSubtierSuppliersData.map(
        (supplier) => {
          if (supplier.supplier_id === supplierId) {
            return {
              ...supplier,
              rows: [...supplier.rows, spec],
            };
          }
          return supplier;
        }
      );
    },
    fetchSubtierOnboardingTableDataRequest: (
      state,
      action: PayloadAction<string>
    ) => {
      state.subtierOnboardingTableDataLoading = true;
      state.subtierOnboardingTableDataError = undefined;
    },
    fetchSubtierOnboardingTableDataFailure: (
      state,
      action: PayloadAction<Error>
    ) => {
      state.subtierOnboardingTableDataLoading = false;
      state.subtierOnboardingTableDataError = action.payload;
    },
    fetchSubtierOnboardingTableDataSuccess: (
      state,
      action: PayloadAction<SubtierOnboardingTableRow[]>
    ) => {
      state.subtierOnboardingTableDataLoading = false;
      state.subtierOnboardingTableDataError = undefined;
      state.subtierOnboardingTableData = action.payload;
    },
    updateSubtiersOnboardingFormDataSuccess: (
      state,
      action: PayloadAction<any>
    ) => {
      state.updateSubtiersOnboardingFormDataLoading = false;
      state.updateSubtiersOnboardingFormDataError = undefined;
    },
    updateSubtierOnboardingTableRowRequest: (
      state,
      action: PayloadAction<UpdateSubtierOnboardingTableRow>
    ) => {
      state.updateSubtierOnboardingTableRowLoading = true;
      state.updateSubtierOnboardingTableRowError = undefined;
    },
    updateSubtierOnboardingTableRowFailure: (
      state,
      action: PayloadAction<Error>
    ) => {
      state.updateSubtierOnboardingTableRowLoading = false;
      state.updateSubtierOnboardingTableRowError = action.payload;
    },
    updateSubtierOnboardingTableRowSuccess: (
      state,
      action: PayloadAction<UpdateSubtierOnboardingTableRow>
    ) => {
      state.updateSubtierOnboardingTableRowLoading = false;
      state.updateSubtierOnboardingTableRowError = undefined;
      state.subtierOnboardingTableData = state.subtierOnboardingTableData?.map(
        (row) => {
          if (row.supplier_uuid === action.payload.supplierId) {
            return { ...row, ...action.payload.data };
          }
          return row;
        }
      );
    },
    fetchDesignAndBuiltDrawingsRequest: (
      state,
      action: PayloadAction<FetchDesignAndBuiltDrawings>
    ) => {
      if (!action.payload.isForFetchingToGetCountData) {
        state.designAndBuiltDrawingsLoading = true;
        state.designAndBuiltDrawingsError = undefined;
      }
    },
    fetchDesignAndBuiltDrawingsFailure: (
      state,
      action: PayloadAction<Error>
    ) => {
      state.designAndBuiltDrawingsLoading = false;
      state.designAndBuiltDrawingsError = action.payload;
    },
    fetchDesignAndBuiltDrawingsSuccess: (
      state,
      action: PayloadAction<FetchDesignAndBuiltDrawingsResponseWithPayload>
    ) => {
      state.designAndBuiltDrawingsLoading = false;
      state.designAndBuiltDrawingsError = undefined;
      if (action.payload.isForFetchingToGetCountData) {
        state.designAndBuiltDrawingsData = {
          ...state.designAndBuiltDrawingsData,
          results: {
            ...state.designAndBuiltDrawingsData.results,
            total_count: action.payload.response.results.total_count,
            completed_count: action.payload.response.results.completed_count,
            uncompleted_count:
              action.payload.response.results.uncompleted_count,
          },
        };
      } else {
        state.designAndBuiltDrawingsData = action.payload.response;
      }
    },
    updateDesignAndBuiltDrawingRequest: (
      state,
      action: PayloadAction<UpdateDesignAndBuildDrawing>
    ) => {
      state.udpateDesignAndBuiltDrawingLoading = true;
      state.udpateDesignAndBuiltDrawingError = undefined;
    },
    updateDesignAndBuiltDrawingFailure: (
      state,
      action: PayloadAction<Error>
    ) => {
      state.udpateDesignAndBuiltDrawingLoading = false;
      state.udpateDesignAndBuiltDrawingError = action.payload;
    },
    updateDesignAndBuiltDrawingSuccess: (
      state,
      action: PayloadAction<UpdateDesignAndBuildDrawingSuccess>
    ) => {
      state.udpateDesignAndBuiltDrawingLoading = false;
      state.udpateDesignAndBuiltDrawingError = undefined;
      state.designAndBuiltDrawingsData.results = {
        ...state.designAndBuiltDrawingsData.results,
        data: state.designAndBuiltDrawingsData.results.data.map((drawing) => {
          if (drawing.drawing_id === action.payload.drawingId) {
            return { ...drawing, ...action.payload.partOfRow };
          }
          return drawing;
        }),
      };
    },
    fetchSpecsOfLeadTimeForProductionRequest: (
      state,
      action: PayloadAction<FetchSpecsOfLeadTimeProductionInput>
    ) => {
      if (!action.payload.isForFetchingToGetCountData) {
        state.specsOfLeadTimeForProductionLoading = true;
        state.specsOfLeadTimeForProductionError = undefined;
      }
    },
    fetchSpecsOfLeadTimeForProductionFailure: (
      state,
      action: PayloadAction<Error>
    ) => {
      state.specsOfLeadTimeForProductionLoading = false;
      state.specsOfLeadTimeForProductionError = action.payload;
    },
    fetchSpecsOfLeadTimeForProductionSuccess: (
      state,
      action: PayloadAction<FetchSpecsOfLeadTimeProductionResponseWithPayload>
    ) => {
      state.specsOfLeadTimeForProductionLoading = false;
      state.specsOfLeadTimeForProductionError = undefined;
      if (action.payload.isForFetchingToGetCountData) {
        state.specsOfLeadTimeForProductionData = {
          ...state.specsOfLeadTimeForProductionData,
          results: {
            ...state.specsOfLeadTimeForProductionData.results,
            total_count: action.payload.response.results.total_count,
            completed_count: action.payload.response.results.completed_count,
            uncompleted_count:
              action.payload.response.results.uncompleted_count,
          },
        };
      } else {
        state.specsOfLeadTimeForProductionData = action.payload.response;
      }
    },
    updateSpecOfLeadTimeForProductionRequest: (
      state,
      action: PayloadAction<UpdateSpecOfLeadTimeForProduction>
    ) => {
      state.updateSpecOfLeadTimeForProductionLoading = true;
      state.updateSpecOfLeadTimeForProductionError = undefined;
    },
    updateSpecOfLeadTimeForProductionFailure: (
      state,
      action: PayloadAction<Error>
    ) => {
      state.updateSpecOfLeadTimeForProductionLoading = false;
      state.updateSpecOfLeadTimeForProductionError = action.payload;
    },
    updateSpecOfLeadTimeForProductionSuccess: (
      state,
      action: PayloadAction<LeadTimeForProductionTableRow>
    ) => {
      state.updateSpecOfLeadTimeForProductionLoading = false;
      state.updateSpecOfLeadTimeForProductionError = undefined;
      state.specsOfLeadTimeForProductionData.results = {
        ...state.specsOfLeadTimeForProductionData.results,
        data: state.specsOfLeadTimeForProductionData.results.data.map(
          (spec) => {
            if (spec.specification_id === action.payload.specification_id) {
              return action.payload;
            }
            return spec;
          }
        ),
      };
    },
    fetchSpecsOfBufferStockStrategyRequest: (
      state,
      action: PayloadAction<FetchSpecsOfBufferStockInput>
    ) => {
      if (!action.payload.isForFetchingToGetCountData) {
        state.specsOfBufferStockStrategyLoading = true;
        state.specsOfBufferStockStrategyError = undefined;
      }
    },
    fetchSpecsOfBufferStockStrategyFailure: (
      state,
      action: PayloadAction<Error>
    ) => {
      state.specsOfBufferStockStrategyLoading = false;
      state.specsOfBufferStockStrategyError = action.payload;
    },
    fetchSpecsOfBufferStockStrategySuccess: (
      state,
      action: PayloadAction<FetchSpecsOfBufferStockResponseWithPayload>
    ) => {
      state.specsOfBufferStockStrategyLoading = false;
      state.specsOfBufferStockStrategyError = undefined;
      if (action.payload.isForFetchingToGetCountData) {
        state.specsOfBufferStockStrategyData = {
          ...state.specsOfBufferStockStrategyData,
          results: {
            ...state.specsOfBufferStockStrategyData.results,
            total_count: action.payload.response.results.total_count,
            completed_count: action.payload.response.results.completed_count,
            uncompleted_count:
              action.payload.response.results.uncompleted_count,
          },
        };
      } else {
        state.specsOfBufferStockStrategyData = action.payload.response;
      }
    },
    updateSpecOfBufferStockStrategyRequest: (
      state,
      action: PayloadAction<UpdateSpecOfBufferStockStrategy>
    ) => {
      state.updateSpecOfBufferStockStrategyLoading = true;
      state.updateSpecOfBufferStockStrategyError = undefined;
    },
    updateSpecOfBufferStockStrategyFailure: (
      state,
      action: PayloadAction<Error>
    ) => {
      state.updateSpecOfBufferStockStrategyLoading = false;
      state.updateSpecOfBufferStockStrategyError = action.payload;
    },
    updateSpecOfBufferStockStrategySuccess: (
      state,
      action: PayloadAction<BufferStockStrategyTableRow>
    ) => {
      state.updateSpecOfBufferStockStrategyLoading = false;
      state.updateSpecOfBufferStockStrategyError = undefined;
      state.specsOfBufferStockStrategyData.results = {
        ...state.specsOfBufferStockStrategyData.results,
        data: state.specsOfBufferStockStrategyData.results.data.map((spec) => {
          if (spec.specification_id === action.payload.specification_id) {
            return action.payload;
          }
          return spec;
        }),
      };
    },
    fetchSuppliersRequest: (state, action: PayloadAction<string>) => {
      state.suppliersLoading = true;
      state.suppliersError = undefined;
    },
    fetchSuppliersFailure: (state, action: PayloadAction<Error>) => {
      state.suppliersLoading = false;
      state.suppliersError = action.payload;
    },
    fetchSuppliersSuccess: (
      state,
      action: PayloadAction<IdentificationFormData[]>
    ) => {
      state.suppliersLoading = false;
      state.suppliersError = undefined;
      state.suppliersData = action.payload;
    },
    fetchSupplierByCompanyIdRequest: (
      state,
      _action: PayloadAction<string>
    ) => {
      state.suppliersSearchByCompanyIdLoading = true;
      state.suppliersSearchByCompanyIdError = undefined;
    },
    fetchSupplierByCompanyIdFailure: (state, action: PayloadAction<Error>) => {
      state.suppliersSearchByCompanyIdLoading = false;
      state.suppliersSearchByCompanyIdError = action.payload;
    },
    fetchSupplierByCompanyIdSuccess: (
      state,
      action: PayloadAction<IdentificationFormData>
    ) => {
      state.suppliersSearchByCompanyIdLoading = false;
      state.suppliersSearchByCompanyIdError = undefined;
      state.supplierCompanyDetails = action.payload;
    },
    fetchIdentificationDataRequest: (state, action: PayloadAction<string>) => {
      state.identificationDataLoading = true;
      state.identificationDataError = undefined;
    },
    fetchIdentificationDataFailure: (state, action: PayloadAction<Error>) => {
      state.identificationDataLoading = false;
      state.identificationDataError = action.payload;
    },
    fetchIdentificationDataSuccess: (
      state,
      action: PayloadAction<IdentificationFormData>
    ) => {
      state.identificationDataLoading = false;
      state.identificationDataError = undefined;
      state.identificationData = action.payload;
    },
    updateIdentificationDataRequest: (
      state,
      action: PayloadAction<UpdateIdentificationDataBody>
    ) => {
      state.updateIdentificationDataLoading = true;
      state.updateIdentificationDataError = undefined;
    },
    updateIdentificationDataFailure: (state, action: PayloadAction<Error>) => {
      state.updateIdentificationDataLoading = false;
      state.updateIdentificationDataError = action.payload;
    },
    updateIdentificationDataSuccess: (
      state,
      action: PayloadAction<IdentificationFormData>
    ) => {
      state.updateIdentificationDataLoading = false;
      state.updateIdentificationDataError = undefined;
      state.identificationData = action.payload;
    },
    setIsFormValidated: (state, action: PayloadAction<boolean>) => {
      state.identificationData = {
        ...state.identificationData,
        is_form_validated: action.payload,
      };
    },
    setQuestionnaireName: (state, action: PayloadAction<Questionnaire>) => {
      state.currentQuestionnaireName = action.payload;
    },
    setActiveFormStepIndex: (
      state,
      action: PayloadAction<{
        index: number;
        questionnaireName:
          | Questionnaire.PomDistributor
          | Questionnaire.PomManufacturer
          | Questionnaire.PosEndUsers;
      }>
    ) => {
      const { index, questionnaireName } = action.payload;
      state[questionnaireName].activeFormStepIndex = index;
    },
    setIsThereUnsavedChangesInFormStep: (
      state,
      action: PayloadAction<boolean>
    ) => {
      state.isThereUnsavedChangesInFormStep = action.payload;
    },
  },
});

export const vendorManagementActions = vendorManagementSlice.actions;

export default vendorManagementSlice.reducer;
