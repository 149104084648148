import React, { useEffect, useState } from "react";
import { Box } from "@mui/material";

import {
  DataGridProProps,
  GridCellParams,
  GridFilterItem,
  GridRowParams,
  MuiEvent
} from "@mui/x-data-grid-pro-v5";
import { useOrderTableData } from "./project-order-table.hooks";
import { useDispatch, useSelector } from "react-redux";

import {
  selectProjectCompanyOrdersCounts,
  selectProjectCompanyOrdersLoading
} from "@next/modules/project/redux/selectors";
import { modalsActions } from "@next/redux/modalsSlices";
import { ProjectModalTypes } from "@next/modules/project/modals/types";
import {
  ProjectCompanyOrder,
  RFQStatus,
  Rfq,
  projectActions
} from "@next/modules/project/redux";
import { SharedOrderPurchaseDialogModal } from "@next/modals/shared-order-purchase-modal";
import { SharedModalTypes } from "@next/modals/types";
import { PAGINATION_PAGE_SIZE } from "@next/constants";
import { generatePaginationAndSearchQuery } from "@next/utils/paginationUtils-v5";
import { history } from "helpers/history";
import { OrderField } from "./types";
import { getRowIdToPk } from "@next/utils/dataGridUtils-v5";
import {
  ProjectOrderTableMenu,
  ProjectOrderTableMenuSelection
} from "./project-order-table.menu";
import { frontendUrl } from "urls";
import { useTableSavedQueryPagination } from "@next/hooks/useTableSavedQueryPagination";
import { DataGridProV5 } from "@next/components/data-grid-pro-v5";
import useLocalStorage from "hooks/useLocalStorage";
import { GlobalSearchResult } from "@next/modules/workspace/redux";
import { LOCAL_STOTAGE_RECENT_SEARCHES_KEY } from "@next/modals/components/global-search/global-search";

type Props = {
  query: string;
  rfqDetailOpenVariant: "modal" | "inline" | "new-tab";
  refetchDataAfterCancelRfq?: boolean;
  refetchDataAfterExtendRfq?: boolean;
  dataGridProProps: Partial<DataGridProProps>;
  sortQueryString?: string;
  filterValues?: GridFilterItem[];
  savedTableQueryKey?: string;
};

export const ProjectOrderTable: React.FC<Props> = ({
  query,
  rfqDetailOpenVariant,
  refetchDataAfterCancelRfq,
  refetchDataAfterExtendRfq,
  dataGridProProps = {},
  sortQueryString,
  filterValues,
  savedTableQueryKey
}) => {
  const {
    savedTableQueryPagination,
    setSavedTableQueryPagination,
    onPageChangeTable
  } = useTableSavedQueryPagination(
    savedTableQueryKey || "DEFAULT_PROJECT_ORDER_TABLE"
  );
  const { columns = [], ...restDataGridProProps } = dataGridProProps;
  const dispatch = useDispatch();

  const [anchorEl, setAnchorEl] = useState<null | Element>(null);
  const [contextMenuActiveRFQ, setContextMenuActiveRFQ] = useState<
    ProjectCompanyOrder & { deadline?: string }
  >();
  const [recentSearches, setRecentSearches] = useLocalStorage<
    GlobalSearchResult[]
  >(LOCAL_STOTAGE_RECENT_SEARCHES_KEY, []);

  const projectCompanyOrdersLoading = useSelector(
    selectProjectCompanyOrdersLoading
  );

  const projectCompanyOrdersCounts = useSelector(
    selectProjectCompanyOrdersCounts
  );

  const { gridData } = useOrderTableData(
    columns,
    `${query}&${generatePaginationAndSearchQuery({
      currentPage: savedTableQueryPagination?.p || 1,
      sortQueryString,
      filterValues
    })}`
  );

  useEffect(() => {
    // Reset page to 1 when filter changes
    setSavedTableQueryPagination({
      ...savedTableQueryPagination,
      p: 1
    });
  }, [filterValues]);

  const redirectToRFQ = (pk: number, rfqDisplayName: string) => {
    switch (rfqDetailOpenVariant) {
      case "modal":
        dispatch(
          modalsActions.showModal({
            key: ProjectModalTypes.RFQ_DETAILS_MODAL,
            data: {
              display_name: rfqDisplayName,
              pk: pk
            } as Rfq
          })
        );
        break;
      case "inline":
        history.push(`${frontendUrl.bundleView}/${pk}`);
        break;
      case "new-tab":
        window.open(`${frontendUrl.bundleView}/${pk}`, "_blank", "noreferrer");
        break;
      default:
        break;
    }
  };

  const onCellClick = (
    params: GridCellParams,
    event: MuiEvent<React.MouseEvent<Element, MouseEvent>>
  ) => {
    if (
      params?.field !== OrderField.LEAD_TIME &&
      params?.field !== OrderField.RATING_SCORE &&
      params?.field !== OrderField.RATING &&
      params?.field !== OrderField.PURCHASE_ORDER &&
      params?.field !== OrderField.COST_AND_LEAD_TIME
    ) {
      if (params?.field === OrderField.EDIT_BUTTON) {
        setAnchorEl(event.currentTarget);
        setContextMenuActiveRFQ(params.row as ProjectCompanyOrder);
      } else {
        setSavedTableQueryPagination({
          ...savedTableQueryPagination,
          lsr: params?.row?.pk
        });
        setRecentSearches([
          {
            id: params?.row?.pk,
            result_type: "RFQ",
            name: params?.row?.rfq_display_name
          },
          ...recentSearches
            .filter((item) => item.id !== params?.row?.pk)
            .slice(0, 5)
        ]);

        redirectToRFQ(params.row.pk, params.row.rfq_display_name);
      }
    }
  };

  const onMenuSelection = (selection: ProjectOrderTableMenuSelection) => () => {
    switch (selection) {
      case ProjectOrderTableMenuSelection.PUT_ON_HOLD_REACTIVE:
        if (contextMenuActiveRFQ?.status === RFQStatus.ON_HOLD) {
          dispatch(
            projectActions.updateRFQRequest({
              pk: contextMenuActiveRFQ.pk,
              status: RFQStatus.ACTIVE,
              refetchData: true,
              isDetailPage: false
            })
          );
        } else {
          dispatch(
            modalsActions.showModal({
              key: SharedModalTypes.ADD_ON_HOLD_NOTE_MODAL,
              data: {
                rfqPk: contextMenuActiveRFQ?.pk,
                isDetailPage: false
              }
            })
          );
        }

        break;
      case ProjectOrderTableMenuSelection.EXTEND:
        dispatch(
          modalsActions.showModal({
            key: SharedModalTypes.EXTEND_RFQ_MODAL,
            data: {
              activeRfq: contextMenuActiveRFQ,
              refetchData:
                refetchDataAfterCancelRfq || refetchDataAfterExtendRfq
            }
          })
        );
        break;
      case ProjectOrderTableMenuSelection.ADD_COLLABORATORS:
        dispatch(
          modalsActions.showModal({
            key: SharedModalTypes.ADD_COLLABORATOR_MODAL,
            data: {
              rfqPk: contextMenuActiveRFQ.pk,
              createdBy: contextMenuActiveRFQ.created_by,
              collaborators: contextMenuActiveRFQ.collaborators,
              refetchData: true
            }
          })
        );
        break;
      case ProjectOrderTableMenuSelection.CANCEL:
        dispatch(
          modalsActions.showModal({
            key: SharedModalTypes.CANCEL_RFQ_MODAL,
            data: {
              activeRfqPk: contextMenuActiveRFQ?.pk,
              refetchData:
                refetchDataAfterCancelRfq || refetchDataAfterExtendRfq
            }
          })
        );
        break;
    }

    setAnchorEl(null);
  };

  const getRowClassName = (params: GridRowParams) =>
    `c-cursor-pointer c-highlighted-row--${
      params.id === savedTableQueryPagination?.lsr
    }`;

  return (
    <Box className="c-project__table">
      <Box className="c-project__table__container">
        <DataGridProV5
          autoHeight
          disableSelectionOnClick
          pagination
          pageSize={PAGINATION_PAGE_SIZE}
          rowCount={projectCompanyOrdersCounts?.count}
          paginationMode="server"
          onPageChange={onPageChangeTable}
          isRowSelectable={(params: any) => !params.row.rfq}
          getRowId={getRowIdToPk}
          onCellClick={onCellClick}
          rowHeight={90}
          {...restDataGridProProps}
          rows={gridData?.rows || []}
          columns={gridData?.columns || []}
          loading={projectCompanyOrdersLoading}
          getRowClassName={getRowClassName}
        />

        <ProjectOrderTableMenu
          anchorEl={anchorEl}
          setAnchorEl={setAnchorEl}
          onSelection={onMenuSelection}
          disabled={
            contextMenuActiveRFQ?.status === RFQStatus.CANCELED ||
            contextMenuActiveRFQ?.status === RFQStatus.CONTRACT
          }
          isSelectedRfqOnHold={
            contextMenuActiveRFQ?.status === RFQStatus.ON_HOLD
          }
        />
      </Box>

      {/* MODALS */}
      <SharedOrderPurchaseDialogModal />
    </Box>
  );
};
