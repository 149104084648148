import { axiosApi } from "@next/api";
import { backendUrl } from "urls";
import {
  ClonePartInput,
  DownloadAxyaServtechInput,
  FetchBomFilesUploadReportInput,
  PoFileInput,
  ProjectBulkDeletePartInput,
  ProjectBulkInsertPartInput,
  ProjectCodes,
  ProjectCompanyOrder,
  ProjectCompanyOrderInput,
  ProjectCompanyPart,
  ProjectCompanyPartInput,
  ProjectCreateInput,
  ProjectCreateMultiContractRFQInput,
  ProjectCreatePartInput,
  ProjectDeleteInput,
  ProjectExportMultiContractRFQExcelInput,
  ProjectFetchInput,
  ProjectImportMultiContractRFQExcelInput,
  ProjectStatsInput,
  ProjectType,
  ProjectUpdateInput,
  ProjectUpdatePartInput,
  Rfq,
  UpdateProjectTemplateInput,
  UpdateRfqInput,
  UploadBomFilesInput,
} from "../redux";

const getProjectStats = ({ projectCode, rfqId }: ProjectStatsInput) =>
  axiosApi.get<ProjectType>(
    `${backendUrl.projects}/${projectCode}${rfqId ? `?rfq_id=${rfqId}` : ""}`
  );

const getCompanyParts = ({ projectCode, query }: ProjectCompanyPartInput) =>
  axiosApi.get<ProjectCompanyPart[]>(
    `${backendUrl.parts}?project=${projectCode}&${query}`
  );

const getCompanyOrders = ({ query }: ProjectCompanyOrderInput) =>
  axiosApi.get<ProjectCompanyOrder[]>(
    `${backendUrl.requestForQuotation}?${query}`
  );

const createProjectPart = (newPart: ProjectCreatePartInput) =>
  axiosApi.post<Partial<ProjectCompanyPart>>(
    `${backendUrl.parts}`,
    newPart?.part
  );

const updateProjectPart = ({ pk: partId, part }: ProjectUpdatePartInput) => {
  return axiosApi.patch<Partial<ProjectCompanyPart>>(
    `${backendUrl.parts}${partId}/`,
    part
  );
};

const deleteProjectPart = (partId: number) =>
  axiosApi.delete(`${backendUrl.parts}${partId}`);

const bulkDeleteProjectPart = ({
  partIds,
  selectAll,
  projectCode,
}: ProjectBulkDeletePartInput) =>
  axiosApi.delete(
    `${backendUrl.parts}?project=${projectCode}&ids=[${partIds}]&selectAll=${selectAll}`
  );

const cloneProjectPart = (payload: ClonePartInput) => {
  const { partPk, projectId } = payload;
  let payloadToPost;
  if (projectId) {
    payloadToPost = {
      project_id: projectId,
    };
  } else {
    payloadToPost = {
      id: partPk,
    };
  }
  return axiosApi.post(`${backendUrl.part}${partPk}/clone`, payloadToPost);
};

const createRFQ = (rfqInfo: Rfq) => axiosApi.post(`${backendUrl.rfq}`, rfqInfo);

const updateRFQ = (updateRFQInput: UpdateRfqInput) =>
  axiosApi.patch(`${backendUrl.rfq}${updateRFQInput?.pk}/`, {
    ...updateRFQInput,
  });

const uploadPartFile = (file: File) => {
  const formData = new FormData();

  formData.append("file", file);

  return axiosApi.post(`${backendUrl.uploadPart}`, formData);
};

const deletePartFile = (partId: number) =>
  axiosApi.delete(`${backendUrl.uploadPart}${partId}`);

const attachPurchaseOrder = ({ contractPk, poFilePk }: PoFileInput) =>
  axiosApi.patch(`${backendUrl.contracts}/${contractPk}/`, {
    po_file_pk: poFilePk,
  });

const downloadAxyaServtech = ({ rfqId }: DownloadAxyaServtechInput) =>
  axiosApi.get(`${backendUrl.rfqs}/${rfqId}/brp/dataExport`, {
    responseType: "blob",
  });

const downloadWinnerQuotationFile = ({ projectCodes }: ProjectCodes) => {
  const codesAsString = projectCodes.join(",");
  return axiosApi.get(
    `${backendUrl.projectsAwarded}/?project_codes=${codesAsString}`,
    {
      responseType: "blob",
    }
  );
};

const getProjects = ({ query }: ProjectFetchInput) =>
  axiosApi.get<ProjectCompanyPart[]>(`${backendUrl.projects}/?${query}`);

const createProject = ({ display_name, code }: ProjectCreateInput) =>
  axiosApi.post<Partial<ProjectType>>(`${backendUrl.projects}/`, {
    display_name,
    code,
  });

const updateProject = ({ project, code }: ProjectUpdateInput) =>
  axiosApi.patch<Partial<ProjectType>>(
    `${backendUrl.projects}/${code}`,
    project
  );

const deleteProject = ({ code }: ProjectDeleteInput) =>
  axiosApi.delete<Partial<ProjectType>>(`${backendUrl.projects}/${code}`);

const bulkInsertPartProject = ({ file, code }: ProjectBulkInsertPartInput) => {
  const formData = new FormData();

  formData.append("file", file);

  return axiosApi.post(`${backendUrl.projects}/bomImport/${code}`, formData);
};

const createMultiContractRFQ = (
  multiContractInput: ProjectCreateMultiContractRFQInput
) => {
  const { rfq_pk, contractData } = multiContractInput;
  return axiosApi.post(`${backendUrl.rfq}${rfq_pk}/split`, contractData);
};

const exportMultiContractRFQExcel = (
  multiContractInput: ProjectExportMultiContractRFQExcelInput
) => {
  const { rfq_pk } = multiContractInput;
  return axiosApi.get(`${backendUrl.rfq}${rfq_pk}/splitExcelExport`, {
    responseType: "blob",
  });
};

const importMultiContractRFQExcel = (
  multiContractInput: ProjectImportMultiContractRFQExcelInput
) => {
  const { rfq_pk, file } = multiContractInput;

  const formData = new FormData();

  formData.append("file", file);

  return axiosApi.post(`${backendUrl.rfq}${rfq_pk}/splitExcelImport`, formData);
};

const uploadBomFiles = ({
  file,
  projectId,
  attach_unmatched_files,
}: UploadBomFilesInput) => {
  const formData = new FormData();
  formData.append("file", file);
  if (attach_unmatched_files) formData.append("attach_unmatched_files", "true");
  return axiosApi.post(
    `${backendUrl.project}/${projectId}/bomFilesUpload`,
    formData
  );
};

const fetchBomFilesUploadReport = ({
  uuid,
  projectId,
}: FetchBomFilesUploadReportInput) => {
  return axiosApi.get(
    `${backendUrl.project}/${projectId}/bomFilesUploadReports/${uuid}`
  );
};

const fetchBulkPartUploadReport = ({
  uuid,
  projectId,
}: FetchBomFilesUploadReportInput) => {
  return axiosApi.get(
    `${backendUrl.project}/${projectId}/bomImportReports/${uuid}`
  );
};

const fetchProjectTemplates = async () => {
  return axiosApi.get(backendUrl.projectTemplates);
};

const updateProjectTemplate = ({
  projectCode,
  template,
}: UpdateProjectTemplateInput) => {
  return axiosApi.patch(`${backendUrl.projects}/${projectCode}`, { template });
};

export const projectService = {
  getProjectStats,
  getCompanyParts,
  getCompanyOrders,
  createProjectPart,
  updateProjectPart,
  deleteProjectPart,
  bulkDeleteProjectPart,
  cloneProjectPart,
  createRFQ,
  updateRFQ,
  uploadPartFile,
  deletePartFile,
  attachPurchaseOrder,
  downloadAxyaServtech,
  getProjects,
  createProject,
  updateProject,
  deleteProject,
  downloadWinnerQuotationFile,
  bulkInsertPartProject,
  createMultiContractRFQ,
  exportMultiContractRFQExcel,
  importMultiContractRFQExcel,
  uploadBomFiles,
  fetchBomFilesUploadReport,
  fetchBulkPartUploadReport,
  fetchProjectTemplates,
  updateProjectTemplate,
};
