import { IconButton, TextFieldProps } from "@mui/material";
import React, { useEffect, useState } from "react";
import ClearIcon from "@mui/icons-material/Clear";
import SearchIcon from "@mui/icons-material/Search";
import * as S from "./search-text-field.styled";

type Props = {
  clearSearch: () => void;
  setSearchValue: (searchValue: string) => void;
  searchValue: string;
  disabled?: TextFieldProps["disabled"];
};

export const SearchTextField: React.FC<Props> = ({
  searchValue,
  setSearchValue,
  clearSearch,
  disabled,
}) => {
  const [inputValue, setInputValue] = useState(searchValue);

  useEffect(() => {
    const handler = setTimeout(() => {
      setSearchValue(inputValue);
    }, 300);

    return () => {
      clearTimeout(handler);
    };
  }, [inputValue, setSearchValue]);

  useEffect(() => {
    setInputValue(searchValue);
  }, [searchValue]);

  return (
    <S.StyledTextField
      variant="outlined"
      size="small"
      value={inputValue}
      onChange={(event: any) => {
        setInputValue(event.target.value);
      }}
      placeholder={"Search"}
      disabled={disabled}
      InputProps={{
        startAdornment: (
          <SearchIcon fontSize="small" style={{ marginRight: 4 }} />
        ),
        endAdornment: (
          <IconButton
            title="Clear"
            aria-label="Clear"
            size="small"
            style={{
              visibility: inputValue ? "visible" : "hidden",
            }}
            onClick={() => {
              setInputValue("");
              clearSearch();
            }}
            disabled={disabled}
          >
            <ClearIcon fontSize="small" />
          </IconButton>
        ),
      }}
    />
  );
};
