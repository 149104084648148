import { styled } from "@mui/material";
import { DataGridProV5 } from "@next/components/data-grid-pro-v5";

export const StyledDataGridPro = styled(DataGridProV5)(({ theme }) => ({
  width: "100%",
  height: "100%",
  "& .MuiDataGrid-columnSeparator": {
    display: "none",
  },
  "& .MuiDataGrid-row.Mui-selected": {
    backgroundColor: `${theme.palette.background.default} !important`,
  },
  "& .MuiDataGrid-columnHeaderTitleContainerContent": {
    "& .MuiCheckbox-root": {
      display: "none",
    },
  },
  "& .MuiDataGrid-cell": {
    borderRight: `1px solid ${theme.palette.divider}`,
  },
  "& .MuiDataGrid-cell:last-child": {
    borderRight: "none",
  },
  "& .checkbox-align-left": {
    display: "flex",
    justifyContent: "flex-start !important",
    alignItems: "center",
    paddingLeft: theme.spacing(1),
  },
  "& .checkbox-align-left .MuiCheckbox-root": {
    marginLeft: "0 !important",
  },
  "& .MuiDataGrid-selectedRowCount": {
    visibility: "hidden",
  },
}));
