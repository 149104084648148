import { TMUIRichTextEditorProps } from "mui-rte";
import {
  ContentState,
  EditorState,
  convertFromRaw,
  convertToRaw,
} from "draft-js";

const defaultControls: TMUIRichTextEditorProps["controls"] = [
  "title",
  "bold",
  "italic",
  "underline",
  "strikethrough",
  "highlight",
  "link",
  "numberList",
  "bulletList",
  "quote",
];

const isRawDraftState = (value: Record<string, any>) => {
  return (
    value?.entityMap &&
    Array.isArray(value?.blocks) &&
    typeof value?.entityMap === "object"
  );
};

const getRawDraftStateAsString = (value: string | Record<string, any>) => {
  let result = JSON.stringify(
    convertToRaw(EditorState.createEmpty().getCurrentContent())
  );

  if (typeof value !== "string") {
    if (isRawDraftState(value)) return JSON.stringify(value);
    return result;
  }

  try {
    const rawState = JSON.parse(value);
    //if string is parseable then check if represents raw draft state
    if (isRawDraftState(rawState)) {
      result = JSON.stringify(rawState);
    }
  } catch (err) {
    result = JSON.stringify(convertToRaw(ContentState.createFromText(value)));
  }

  return result;
};

const getPlainText = (value: string | Record<string, any>) => {
  return convertFromRaw(
    JSON.parse(getRawDraftStateAsString(value))
  ).getPlainText();
};

const getPlainAndRichTextAsString = (value: string | Record<string, any>) => {
  const richText = getRawDraftStateAsString(value);
  return { richText, plainText: getPlainText(richText) };
};

const truncate = (
  rawState: string | Record<string, any>,
  charCount: number
) => {
  const stateString = getRawDraftStateAsString(rawState);
  const parsed = JSON.parse(stateString);
  const blocks = parsed.blocks;

  let index = 0;
  let currentLength = 0;
  let isTruncated = false;
  const truncatedBlocks = [];

  while (!isTruncated && blocks[index]) {
    const block = blocks[index];
    const length = block.text.length;
    if (currentLength + length > charCount) {
      isTruncated = true;
      const truncatedText = block.text.slice(0, charCount - currentLength);
      truncatedBlocks.push({ ...block, text: `${truncatedText}...` });
    } else {
      truncatedBlocks.push(block);
    }
    currentLength += length + 1;
    index++;
  }

  let res = stateString;
  if (isTruncated) {
    res = JSON.stringify({
      blocks: truncatedBlocks,
      entityMap: parsed.entityMap,
    });
  }

  return [res, isTruncated];
};

export {
  defaultControls,
  isRawDraftState,
  getRawDraftStateAsString,
  getPlainText,
  getPlainAndRichTextAsString,
  truncate,
};
