import { Menu } from "@mui/material";
import { useAxyaTracking } from "@next/hooks/useAxyaTracking";
import { modalsActions } from "@next/redux/modalsSlices";
import React, { useCallback, useEffect, useState } from "react";
import { batch, useDispatch, useSelector } from "react-redux";
import { WorkspaceModalTypes } from "../../modals/types";
import { workspaceNextActions } from "../../redux";
import {
  selectDraftRFQsCount,
  selectFetchDraftRFQsLoading,
} from "../../redux/selectors";
import InsertDriveFileOutlinedIcon from "@mui/icons-material/InsertDriveFileOutlined";
import { useTranslation } from "react-i18next";
import { DraftRFQsList } from "./draft-rfqs-list";
import CustomButton from "@next/components/custom-button";
interface DraftRFQsMenuProps {
  projectCode?: string;
}

const DraftRFQsMenu: React.FC<DraftRFQsMenuProps> = ({ projectCode }) => {
  const draftsCount = useSelector(selectDraftRFQsCount);
  const [anchorEl, setAnchorEl] = useState<Element | null>(null);
  const axyaTracking = useAxyaTracking();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const loading = useSelector(selectFetchDraftRFQsLoading);

  const onOpen = useCallback((e) => {
    setAnchorEl(e.currentTarget);
  }, []);

  const onClose = useCallback(() => {
    setAnchorEl(null);
  }, []);

  const onDraftClick = useCallback((item) => {
    batch(() => {
      dispatch(
        modalsActions.showModal({
          key: WorkspaceModalTypes.RFQ_CREATION_DRAWER,
          data: { loading: true },
        })
      );
      dispatch(
        workspaceNextActions.loadDraftRFQRequest({
          id: item.id,
        })
      );
    });
    onClose();
    axyaTracking({
      scout_category: "axya-buyer-draft-rfqs",
      scout_feature_name: "load-existing-draft-rfq",
    });
  }, []);

  useEffect(() => {
    dispatch(
      workspaceNextActions.fetchDraftRFQsRequest({
        query: { page: 1, project: projectCode },
      })
    );
  }, [projectCode]);

  return (
    <>
      <CustomButton
        variant="outlined"
        size="medium"
        startIcon={<InsertDriveFileOutlinedIcon />}
        onClick={onOpen}
        loading={loading}
      >
        {t(
          "workspaceNext:rfqDrafts:" +
            (loading ? "draftsWithoutCount" : "draft"),
          {
            count: draftsCount,
          }
        )}
      </CustomButton>

      <Menu
        anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={onClose}
      >
        <DraftRFQsList onItemClick={onDraftClick} projectCode={projectCode} />
      </Menu>
    </>
  );
};

export default DraftRFQsMenu;
