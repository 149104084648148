import { Box, Grid, Theme, Typography } from "@mui/material";
import React, { useMemo } from "react";
import logoBlack from "assets/img/logoHorizontalBlack.svg";
import { useTranslation } from "react-i18next";
import { SingleQuotationViewer } from "./single-quotation-viewer";
import { formatDate } from "helpers/formatDate";
import { createStyles, makeStyles } from "@mui/styles";

interface Props {
  quoteIndex?: number;
  quotations: [];
  title: string;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      padding: theme.spacing(2),
    },
    logo: {
      width: 60,
    },
    lightText: {
      color: theme.palette.action.active,
    },
  })
);

const QuotationPDFViewer = React.forwardRef((props: Props, ref) => {
  const { quoteIndex, quotations, title } = props;
  const classes = useStyles();
  const { t } = useTranslation();
  const exportDate = useMemo(() => formatDate(Date.now()), []);

  const printCss = `
    @media print {
      @page { size: 8.5in 11in; }
    }  
  `;

  return (
    <div ref={ref as React.LegacyRef<HTMLDivElement>}>
      <style>{printCss}</style>
      <Grid className={classes.container} container direction="column">
        <Grid item direction="column">
          <img className={classes.logo} src={logoBlack} />
          <Typography variant="caption">
            <Box mt={0.5}>+ 514 792 9224</Box>
            <Box>Montréal, Québec H3B 2V6, CA</Box>
          </Typography>
        </Grid>

        <Box mt={2} mb={2}>
          <Typography variant="subtitle1">{title}</Typography>
          <Typography variant="caption" className={classes.lightText}>
            <Box mt={0.5}>
              {t("rfq:quotePDFViewer:exportedFromAxya", { exportDate })}
            </Box>
            {quoteIndex !== undefined ? null : (
              <Box mt={0.5}>
                {quotations.length} {t("workspace:numberOfOffers")}{" "}
              </Box>
            )}
          </Typography>
        </Box>

        {quoteIndex !== undefined ? (
          <SingleQuotationViewer quotation={quotations[quoteIndex]} />
        ) : (
          quotations.map((quotation: Record<string, any>, ix: number) => (
            <Box mb={ix === quotations.length - 1 ? 0 : 2}>
              <SingleQuotationViewer quotation={quotation} />
            </Box>
          ))
        )}
      </Grid>
    </div>
  );
});

export default QuotationPDFViewer;
