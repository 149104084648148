import React from "react";
import { Box, CircularProgress, Dialog } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { ProjectModalTypes } from "./types";
import { getModal, modalsActions } from "@next/redux/modalsSlices";
import { ProjectFile } from "../components/file/project-file";
import { selectBuyerProfilePartLoading } from "@next/modules/dashboard/redux/selectors";

type Props = {
  isRFQPart?: boolean;
};

export const ProjectFileDialogModal = ({ isRFQPart }: Props) => {
  const dispatch = useDispatch();
  const modal = useSelector(getModal(ProjectModalTypes.FILE_DIALOG_MODAL));
  const buyerProfilePartLoading = useSelector(selectBuyerProfilePartLoading);
  const data = modal?.data as {
    part?: any;
    readOnly?: boolean;
    isBuyerProfilePart?: boolean;
    isRFQEditMode?: boolean;
    preSelectedFileId?: number;
  };

  if (!modal || !data) return null;

  const onClose = () => {
    dispatch(modalsActions.closeModal(ProjectModalTypes.FILE_DIALOG_MODAL));
  };

  return (
    <Dialog
      open={!!modal}
      onClose={onClose}
      closeAfterTransition
      fullWidth={true}
      sx={{
        "& .MuiPaper-root": {
          maxWidth: "100%",
        },
      }}
    >
      {data?.isBuyerProfilePart && buyerProfilePartLoading ? (
        <Box
          height="100vh"
          display="flex"
          justifyContent="center"
          alignItems="center"
        >
          <CircularProgress />
        </Box>
      ) : (
        <ProjectFile
          isRFQPart={isRFQPart}
          isRFQEditMode={data?.isRFQEditMode}
          part={data?.part}
          readOnly={data?.readOnly}
          onClose={onClose}
          isBuyerProfilePart={data?.isBuyerProfilePart}
          preSelectedFileId={data?.preSelectedFileId}
        />
      )}
    </Dialog>
  );
};
