import React, { useMemo } from "react";
import { SelectOrderStateCard } from "@next/modules/orders/components/orders-table/select-order-state-cards/select-order-state-card";
import { CardOrderState } from "@next/modules/orders/components/orders-table/select-order-state-cards/select-order-state.cards.types";
import { Box, Grid } from "@mui/material";
import { OrderState } from "@next/modules/orders/redux";
import { useSelector } from "react-redux";
import { selectSupplierLineItemsStatusCounts } from "@next/modules/orders/redux/selectors";
import { t } from "assets/configi18n/i18n";
import i18n from "assets/configi18n/i18n";

type Props = {
  selectedFilterState: CardOrderState;
  setSelectedFilterState: (newSelectedState: CardOrderState) => void;
  searchTerm?: string;
};

export const initialCardStateOrder = (total_count: number) => {
  return {
    slug: OrderState.ALL,
    query_slugs: [OrderState.ALL],
    name: t("orders:cardStates:allOrders"),
    count: total_count || 0,
    pid: "supplierLineItems:allState",
  };
};

export const SelectSupplierLineItemStateCards: React.FC<Props> = ({
  selectedFilterState,
  setSelectedFilterState,
  searchTerm,
}) => {
  const ordersStatusCounts = useSelector(selectSupplierLineItemsStatusCounts);

  const cardStates: CardOrderState[] = useMemo(
    () => [
      initialCardStateOrder(ordersStatusCounts?.active_count),
      {
        slug: OrderState.UPDATED,
        query_slugs: [OrderState.UPDATED],
        name: t("orders:cardStates:waitingReview"),
        count: ordersStatusCounts?.updated_count || 0,
        pid: "supplierLineItems:updatedState",
      },
      {
        slug: OrderState.ON_TRACK,
        query_slugs: [OrderState.ON_TRACK],
        name: t("orders:cardStates:onTrack"),
        count: ordersStatusCounts?.on_track_count || 0,
        pid: "supplierLineItems:onTrackState",
      },

      {
        slug: OrderState.AT_RISK,
        query_slugs: [OrderState.AT_RISK],
        name: t("orders:cardStates:atRisk"),
        count: ordersStatusCounts?.at_risk_count || 0,
        pid: "supplierLineItems:atRiskState",
      },
      {
        slug: OrderState.LATE,
        query_slugs: [OrderState.LATE],
        name: t("orders:cardStates:late"),
        count: ordersStatusCounts?.late_count || 0,
        pid: "supplierLineItems:lateState",
      },
    ],
    [ordersStatusCounts, searchTerm, i18n?.language]
  );

  return (
    <Box>
      <Grid container spacing={1}>
        {cardStates?.map((cardState) => {
          if (cardState?.hide) return null;

          return (
            <Grid item data-pid={cardState.pid}>
              <SelectOrderStateCard
                state={cardState}
                selected={selectedFilterState?.name === cardState?.name}
                onClick={(newSelectedFilterState) => {
                  if (
                    selectedFilterState?.name !== newSelectedFilterState?.name
                  ) {
                    setSelectedFilterState(newSelectedFilterState);
                  }
                }}
              />
            </Grid>
          );
        })}
      </Grid>
    </Box>
  );
};
