import React from "react";
import { PublicLayout } from "layout/PublicLayout";
import { VendorManagement } from "@next/modules/vendor-management/pages/vendor-management";
import { frontendUrl } from "urls";
import { CustomRoute } from "./types";
import { Questionnaire } from "@next/modules/vendor-management/redux";

const VendorManagementRoutes: CustomRoute[] = [
  {
    layout: (props) => <PublicLayout {...props} showAppBar={false} />,
    path: `${frontendUrl.publicVendorManagementPosEndUsersQuestionnaire}/:companyId`,
    key: "publicVendorManagement",
    exact: true,
    component: () => (
      <VendorManagement questionnaireName={Questionnaire.PosEndUsers} />
    ),
  },
  {
    layout: (props) => <PublicLayout {...props} showAppBar={false} />,
    path: `${frontendUrl.publicVendorManagementPomManufacturerQuestionnaire}/:companyId`,
    key: "publicVendorManagement",
    exact: true,
    component: () => (
      <VendorManagement questionnaireName={Questionnaire.PomManufacturer} />
    ),
  },
  {
    layout: (props) => <PublicLayout {...props} showAppBar={false} />,
    path: `${frontendUrl.publicVendorManagementPomDistributorQuestionnaire}/:companyId`,
    key: "publicVendorManagement",
    exact: true,
    component: () => (
      <VendorManagement questionnaireName={Questionnaire.PomDistributor} />
    ),
  },
];

export default VendorManagementRoutes;
