import React, { useEffect, useState } from "react";
import { Box, Grid } from "@mui/material";
import { AnalyticCard } from "../components/analytic-card/analytic-card";
import { AnalyticCardBeta } from "../components/analytic-card-beta/analytic-card-beta";
import { AnalyticGraphCard } from "../components/analytic-graph-card/analytic-graph-card";
import { styled } from "@material-ui/core";
import { OrdersReportCumulativeColumnChart } from "../components/orders-report/orders-report-cumulative-column-chart/orders-report-cumulative-column-chart";
import { OrdersReportStackedColumnChart } from "../components/orders-report/orders-report-stacked-column-chart/orders-report-stacked-column-chart";
import { useDispatch, useSelector } from "react-redux";
import { OrdersReportTable } from "../components/orders-report/orders-report-table/orders-report-table";
import {
  selectAnalyticFilters,
  selectOrdersReport,
  selectOrdersReportLoading,
} from "../redux/selectors";
import { analyticsActions } from "../redux";
import { formatNumber } from "helpers/formatNumber";
import { AnalyticFilters } from "../components/analytic-filters/analytic-filters";
import { LoadingScreen } from "../components/loading-screen/loading-screen";
import { useTranslation } from "react-i18next";

const RootDiv = styled("div")({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  width: "100%",
});

export const OrdersReport = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const ordersReport = useSelector(selectOrdersReport);
  const ordersReportLoading = useSelector(selectOrdersReportLoading);
  const analyticFilters = useSelector(selectAnalyticFilters);
  const [graphEditInputs, setGraphEditInputs] = useState({
    firstInput: 15,
    secondInput: 3,
  });

  useEffect(() => {
    dispatch(analyticsActions.fetchOrdersReportRequest(analyticFilters));
  }, [analyticFilters]);

  if (ordersReportLoading || !ordersReport) {
    return <LoadingScreen />;
  }

  return (
    <RootDiv>
      <AnalyticFilters isInSupplierReport={false} />

      <Box p="36px" pt="24px" sx={{ marginTop: "24px", width: "100%" }}>
        <Grid container spacing={2}>
          <Grid item container spacing={2}>
            <Grid item xs={12} sm={6} md={4} lg={4}>
              <AnalyticCard
                title={t("analytics:ordersReport:cards:targetedSupplier:text")}
                tooltipTitle={t(
                  "analytics:ordersReport:cards:targetedSupplier:tooltipTitle"
                )}
                value={ordersReport.suppliers_count}
              />
            </Grid>

            <Grid item xs={12} sm={6} md={4} lg={4}>
              <AnalyticCard
                title={t("analytics:ordersReport:cards:rfqsCount")}
                value={ordersReport.items_count}
              />
            </Grid>

            <Grid item xs={12} sm={6} md={4} lg={4}>
              <AnalyticCard
                title={t("analytics:ordersReport:cards:totalValue")}
                value={formatNumber(ordersReport.total_price)}
              />
            </Grid>

            <Grid item xs={12} sm={6} md={4} lg={4}>
              <AnalyticCard
                title={t("analytics:ordersReport:cards:avgTime:text")}
                tooltipTitle={t(
                  "analytics:ordersReport:cards:avgTime:tooltipTitle"
                )}
                value={ordersReport.avg_acknowledgment_time}
              />
            </Grid>

            <Grid item xs={12} sm={6} md={4} lg={4}>
              <AnalyticCard
                title={t("analytics:ordersReport:cards:delivery")}
                value={ordersReport.on_time_delivery_percentage + "%"}
              />
            </Grid>

            <Grid item xs={12} sm={6} md={4} lg={4}>
              <AnalyticCardBeta />
            </Grid>

            <Grid item xs={12}>
              <AnalyticGraphCard
                content={{
                  mainTitle: t("analytics:ordersReport:charts:volume:title"),
                  cardContents: [
                    {
                      title: t("analytics:ordersReport:cards:rfqsCount"),
                      boldTypographyContent: formatNumber(
                        ordersReport.items_count
                      ),
                    },
                    {
                      title: t("analytics:ordersReport:cards:totalValue"),
                      boldTypographyContent: formatNumber(
                        ordersReport.total_price
                      ),
                    },
                  ],
                }}
                GraphComponent={
                  <OrdersReportCumulativeColumnChart
                    id={ordersReport?.charts[0].id}
                    x_axis={ordersReport?.charts[0].x_axis}
                    y_axes={ordersReport?.charts[0].y_axes}
                  />
                }
              />
            </Grid>

            <Grid item xs={12}>
              <AnalyticGraphCard
                content={{
                  mainTitle: t("analytics:ordersReport:charts:shipment:title"),
                  description: t(
                    "analytics:ordersReport:charts:shipment:description"
                  ),
                  cardContents: [
                    {
                      title: t(
                        "analytics:ordersReport:charts:shipment:cards:onTime"
                      ),
                      boldTypographyContent: `${ordersReport?.on_time_delivery_percentage}%`,
                      smallTypographyContent: `${
                        ordersReport?.on_time_count
                      } ${t("analytics:global:items")}`,
                    },
                    {
                      title: t(
                        "analytics:ordersReport:charts:shipment:cards:late"
                      ),
                      boldTypographyContent: `${
                        100 - ordersReport?.on_time_delivery_percentage
                      }%`,
                      smallTypographyContent: `${ordersReport?.late_count} ${t(
                        "analytics:global:items"
                      )}`,
                    },
                  ],
                }}
                GraphComponent={
                  <OrdersReportStackedColumnChart
                    id={ordersReport.charts[1].id}
                    x_axis={ordersReport.charts[1].x_axis}
                    y_axes={ordersReport.charts[1].y_axes}
                  />
                }
              />
            </Grid>

            <Grid item xs={12}>
              <OrdersReportTable
                id={ordersReport.tables[0].id}
                rows={ordersReport.tables[0].rows}
              />
            </Grid>
          </Grid>
        </Grid>
      </Box>
    </RootDiv>
  );
};
