import { Box, Theme, Typography } from "@mui/material";
import { GridOverlay } from "@mui/x-data-grid-pro-v5";
import React from "react";
import InboxIcon from "@mui/icons-material/Inbox";
import { useTranslation } from "react-i18next";
import { useSupplierInvitationsTableData } from "@next/modules/workspace/pages/my-suppliers/supplier-invitations-table/supplier-invitations-table.hooks";
import { createStyles, makeStyles } from "@mui/styles";
import { DataGridProV5 } from "@next/components/data-grid-pro-v5";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    dataGrid: {
      "& .MuiDataGrid-columnsContainer": {
        display: "none",
      },
    },
    noRowContainer: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",
      gap: theme.spacing(2),
      marginTop: "120px",
    },
    inboxContainer: {
      padding: theme.spacing(2),
      borderRadius: 8,
      border: `1px solid ${theme.palette.divider}`,
    },
  })
);

export const SellerDashboardPendingInvitationsTable = () => {
  const { gridData, pageSize, loading, setCurrentPage } =
    useSupplierInvitationsTableData({ isBuyerPerspective: false });
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <Box width={"100%"}>
      <DataGridProV5
        autoHeight
        pageSize={pageSize}
        pagination={gridData?.rows.length > 0}
        paginationMode="server"
        onPageChange={(newPage) => setCurrentPage(newPage + 1)}
        rows={gridData?.rows || []}
        columns={gridData?.columns || []}
        disableSelectionOnClick
        disableColumnResize
        headerHeight={0}
        rowHeight={60}
        loading={loading}
        className={classes.dataGrid}
        components={{
          NoRowsOverlay: () => {
            return (
              <GridOverlay>
                <Box className={classes.noRowContainer}>
                  <Box className={classes.inboxContainer}>
                    <InboxIcon />
                  </Box>
                  <Typography variant="h6">
                    {t("sellerDashboard:noInvitationsSent")}
                  </Typography>
                </Box>
              </GridOverlay>
            );
          },
        }}
      />
    </Box>
  );
};
