import { useLocation } from "react-router-dom";

export const useIsLocationPath = (
  frontendUrl: string,
  exact: boolean = false
) => {
  const location = useLocation();

  return exact
    ? location.pathname === frontendUrl
    : location.pathname.includes(frontendUrl);
};
