import React from "react";
// Routes
import { frontendUrl } from "urls";
// Layout
import AppLayout from "layout/AppLayout";

// Components
import SupplierContracts from "components/marketplace/supplier-contracts/supplier-contracts.container";
import SupplierContractView from "components/marketplace/supplier-contract-view/supplier-contract-view.container";
import Marketplace from "components/marketplace/marketplace.container";
import SupplierQuoteView from "components/marketplace/supplier-quote-view/supplier-quote-view.container";
// Types
import { CustomRoute } from "./types";
import { SupplierQuotes } from "@next/modules/workspace/pages/supplier-quotes/supplier-quotes";

const MARKETPLACE_ROUTES: CustomRoute[] = [
  {
    // /marketplace
    layout: AppLayout,
    path: frontendUrl.marketplace,
    key: "marketplace",
    exact: true,
    component: (props: any) => <Marketplace {...props} />,
  },
  {
    // /marketplace
    layout: AppLayout,
    path: frontendUrl.requestForQuotations,
    key: "quotations",
    exact: false,
    component: (props: any) => <Marketplace {...props} />,
  },
  {
    // /supplier-quotes
    layout: AppLayout,
    path: frontendUrl.supplierQuotes,
    key: "marketplaceSupplierQuotes",
    exact: true,
    component: () => <SupplierQuotes />,
  },
  {
    // /supplier-contracts
    layout: AppLayout,
    path: frontendUrl.supplierContracts,
    key: "marketPlaceSupplierContracts",
    exact: true,
    component: (props: any) => <SupplierContracts {...props} />,
  },
  {
    // /contract-view
    layout: AppLayout,
    path: `${frontendUrl.supplierContractView}/:id`,
    key: "marketPlaceSupplierContractView",
    exact: true,
    component: (props: any) => (
      <SupplierContractView params={props.match.params} {...props} />
    ),
  },
  {
    layout: AppLayout,
    path: `${frontendUrl.supplierQuoteView}/:id`,
    key: "marketPlaceSupplierQuoteView",
    exact: true,
    component: (props: any) => (
      <SupplierQuoteView params={props.match.params} {...props} />
    ),
  },
  {
    layout: AppLayout,
    path: `${frontendUrl.reviseQuotation}/:id`,
    key: "marketPlaceSupplierQuoteView",
    exact: false,
    component: (props: any) => (
      <SupplierQuoteView params={props.match.params} {...props} />
    ),
  },
];

export default MARKETPLACE_ROUTES;
