import { Box, Chip, Typography, styled } from "@mui/material";

export const StyledTitle = styled(Typography)({
  fontFamily: "Space Grotesk",
  fontSize: "24px",
  lineHeight: "24px",
  letterSpacing: "0.18px",
});

export const StyledChip = styled(Chip)(({ theme }) => ({
  marginLeft: "12px",
  backgroundColor: theme.palette.success.dark,
  "& .MuiChip-label": {
    color: "white",
  },
}));

export const StyledBox = styled(Box)({
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
});
