import React from "react";

import { useDispatch, useSelector } from "react-redux";
import { getModal, modalsActions } from "../redux/modalsSlices";
import { SharedModalTypes } from "./types";
import Modal from "@next/components/custom-modal";
import { useTranslation } from "react-i18next";
import SharedCancelRfqForm from "@next/modals/components/cancel-rfq-form/shared-cancel-rfq-form";

export const SharedCancelRfqModal = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const modal = useSelector(getModal(SharedModalTypes.CANCEL_RFQ_MODAL));
  const data = modal?.data as {
    activeRfqPk: number;
    refetchData: boolean;
  };

  if (!modal || !data) return null;

  return (
    <Modal
      handleModalClose={() =>
        dispatch(modalsActions.closeModal(SharedModalTypes.CANCEL_RFQ_MODAL))
      }
      modalState={{
        modalHeader: t("project:table:row:cancelRfq"),
        modalBody: (
          <SharedCancelRfqForm
            activeRfqPk={data?.activeRfqPk}
            refetchData={data?.refetchData}
          />
        ),
      }}
      isModalOpen={true}
    />
  );
};
