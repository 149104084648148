import React from "react";

import { TextField } from "@mui/material";

export const GenericAutoCompleteTextField = ({
  placeholder,
  rows,
  label,
  ...props
}) => {
  // const [field, meta] = useField(props);
  // const errorText = meta.error && meta.touched ? meta.error : "";

  return (
    <TextField
      inputProps={{ tabIndex: "0", readonly: true }}
      readonly={true}
      placeholder={placeholder}
      fullWidth
      label={label}
      rows={rows}
      multiline={!!rows}
      // helperText={errorText}
      // error={!!errorText}
      id="outlined-basic"
      variant="outlined"
      className="form-text-field"
      color="secondary" /** The focus color */
      {...props}
    />
  );
};
