import { CompanyRole, User } from "@next/modules/profile/redux";
import { CompanyTypeNext } from "@next/redux/types";
import { checkIsSellerCompany } from "@next/utils/checkCompanyUtils";
import { useSelector } from "react-redux";
import { getCompany, getUser } from "services/profile/profile.selectors";

export const useHasCompanyRole = (roleIp: CompanyRole | CompanyRole[]) => {
  const user: User = useSelector(getUser);
  const company = useSelector(getCompany);
  const isSeller = checkIsSellerCompany(company?.type as CompanyTypeNext);

  return checkHasCompanyRole(user, roleIp, isSeller);
};

export const checkHasCompanyRole = (
  user: User,
  roleIp: CompanyRole | CompanyRole[],
  isSeller?: boolean
) => {
  if (isSeller) return false;

  const role = user?.user_type;

  const hasCompanyRole = Array.isArray(roleIp)
    ? roleIp.includes(role)
    : role === roleIp;

  return hasCompanyRole;
};
