import React from "react";
import { useDispatch, useSelector } from "react-redux";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import SaveIcon from "@mui/icons-material/Save";
import CheckIcon from "@mui/icons-material/Check";
import { PartialStep, vendorManagementActions } from "../../redux";
import {
  selectIsLastStep,
  selectIsThereUnsavedChangesInFormStep,
  selectVisibleSteps,
} from "../../redux/selectors";
import { formatTime } from "../../utils/formatTime";
import { getActiveStepIndex } from "../../utils/getActiveStepIndex";
import * as S from "./form-footer.styled";
import { VendorContactSupportItem } from "@next/modules/app/components/app-bar-next/app-bar-next-action-items/components/vendor-contact-support-action-item";

interface FormFooterProps {
  saveChanges: () => void; // api call to just save
  confirmStepCompletion: () => void; // call to save and validate
  isCompleted: boolean;
}

export const FormFooter: React.FC<FormFooterProps> = ({
  saveChanges,
  confirmStepCompletion,
  isCompleted,
}) => {
  const dispatch = useDispatch();
  const steps = useSelector(selectVisibleSteps);
  const isLastStep = useSelector(selectIsLastStep);
  const activeFormStepIndex = getActiveStepIndex(steps);
  const isFirstStep = activeFormStepIndex === 0;
  const lastUpdatedDate = steps[activeFormStepIndex]?.last_updated;
  const lastSavedAt = formatTime(lastUpdatedDate);
  const isThereUnsavedChanges = useSelector(
    selectIsThereUnsavedChangesInFormStep
  );

  const handleNext = () => {
    if (isThereUnsavedChanges) {
      saveChanges();
    }
    routeNextStep();
  };

  const routeNextStep = () => {
    const newActiveStepIndex = activeFormStepIndex + 1;
    makeFormStepActiveByIndex(newActiveStepIndex);
  };

  const handleBack = () => {
    if (isThereUnsavedChanges) {
      saveChanges();
    }
    routePreviousStep();
  };

  const routePreviousStep = () => {
    const newActiveStepIndex = activeFormStepIndex - 1;
    makeFormStepActiveByIndex(newActiveStepIndex);
  };

  const makeFormStepActiveByIndex = (index: number) => {
    const step = steps[index];
    const partialStep: PartialStep = {
      is_active: true,
    };
    dispatch(
      vendorManagementActions.updateStepRequest({
        stepId: step.id,
        data: partialStep,
      })
    );
    dispatch(vendorManagementActions.makeFormDataUndefined()); // to reset form data
  };

  return (
    <S.StyledFooterBox>
      <S.StyledInnerBox>
        {!isFirstStep ? (
          <S.StyledDirectionButton onClick={handleBack}>
            <ArrowBackIcon />
          </S.StyledDirectionButton>
        ) : (
          <S.BlankBox />
        )}

        <S.StyledButton
          startIcon={<SaveIcon sx={{ width: "24px", height: "24px" }} />}
          variant="contained"
          color="primary"
          onClick={saveChanges}
          disabled={isCompleted}
        >
          <S.StyledTypography variant="body2" tooltipTitle={null}>
            Save changes
          </S.StyledTypography>
        </S.StyledButton>

        <S.SavedBox>
          <S.StyledSavedTypography variant="body2">
            Last saved at {lastSavedAt}
          </S.StyledSavedTypography>
        </S.SavedBox>

        <S.StyledButton
          startIcon={<CheckIcon sx={{ width: "24px", height: "24px" }} />}
          variant="contained"
          color="success"
          onClick={confirmStepCompletion}
          disableElevation
          disabled={isCompleted}
        >
          <S.StyledTypography variant="body2" tooltipTitle={null}>
            Confirm step completion
          </S.StyledTypography>
        </S.StyledButton>

        <VendorContactSupportItem />
        {!isLastStep ? (
          <S.StyledDirectionButton onClick={handleNext}>
            <ArrowForwardIcon />
          </S.StyledDirectionButton>
        ) : (
          <S.BlankBox />
        )}
      </S.StyledInnerBox>
    </S.StyledFooterBox>
  );
};
