import React from "react";
import { Button, Link } from "@mui/material";
import { GridRenderCellParams } from "@mui/x-data-grid-pro-v5";
import { OrderStatus } from "@next/modules/project/components/order/types";
import { useDispatch } from "react-redux";
import { modalsActions } from "@next/redux/modalsSlices";
import { downloadFile } from "helpers/download";
import { t } from "assets/configi18n/i18n";
import { SharedModalTypes } from "@next/modals/types";
import { CustomTypography } from "../custom-typography";
import { RequestType } from "@next/modules/workspace/components/rfq-creation-form/rfq-creation.form.definitions";

type Props = GridRenderCellParams & { readOnly?: boolean };

const PurchaseOrderCell: React.FC<Props> = (params) => {
  const dispatch = useDispatch();
  const row_order_status: any = params.row.order_status;
  const isPoFileExist = params.row.po_file;

  const onClick = (e: React.SyntheticEvent) => {
    e.preventDefault();
    dispatch(
      modalsActions.showModal({
        key: SharedModalTypes.ORDER_PURCHASE_MODAL,
        data: { contractPk: params.row.pk, isProject: true },
      })
    );
  };

  return row_order_status?.slug === OrderStatus.CONTRACTED ||
    row_order_status?.slug === OrderStatus.PO_SENT ? (
    <CustomTypography
      variant="caption"
      tooltipTitle={
        !isPoFileExist && !params?.readOnly
          ? t("project:table:row:addPurchaseOrder")
          : isPoFileExist
          ? `${params.row.po_file?.file_name}.${params.row.po_file?.file_extension}`
          : null
      }
    >
      {!isPoFileExist ? (
        !params?.readOnly && (
          <Button
            size="small"
            variant="outlined"
            onClick={onClick}
            disabled={params.row.request_type === RequestType.RFI}
          >
            {t("project:table:row:addPurchaseOrder")}
          </Button>
        )
      ) : (
        <Link
          href="#"
          onClick={(e: React.SyntheticEvent) => {
            e.preventDefault();
            downloadFile(e, params.row.po_file.file_url);
          }}
        >
          {`${params.row.po_file?.file_name}.${params.row.po_file?.file_extension}`}
        </Link>
      )}
    </CustomTypography>
  ) : (
    <CustomTypography variant="body2" className="not-available">
      n/a
    </CustomTypography>
  );
};

export default PurchaseOrderCell;
