// General IMport
import React from "react";
import { Redirect } from "react-router-dom";

import ValidationPage from "../../ValidationPage";
import { useLocation } from "react-router-dom";
import { frontendUrl } from "../../../urls";
import {
  handleCloseCreateDialog,
  handleCloseJoinDialog,
} from "./registration.helpers";

// Assets
import accessDeniedImg from "../../../assets/img/access-denied.png";
import JoinCompanyForm from "../../../profiles/companyProfile/components/JoinCompanyForm";

import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Typography,
} from "@mui/material";

import CloseIcon from "@mui/icons-material/Close";
import CompanyDialogForm from "../../user-company/company-form/company-form.component";

const CompanyRegistrationComponent = (props) => {
  const {
    isCreateCompanyModalOpen,
    isJoinCompanyModalOpen,
    handleOpenModal,
    handleCloseModal,
    token,
    user,
  } = props;
  const location = useLocation();
  const currentRoute = location.pathname;

  if (
    currentRoute === frontendUrl.registerCompany &&
    !isCreateCompanyModalOpen
  ) {
    handleOpenModal(true, "isCreateCompanyModalOpen");
  }

  if (currentRoute === frontendUrl.joinCompany && !isJoinCompanyModalOpen) {
    handleOpenModal(true, "isJoinCompanyModalOpen");
  }

  if (props.profile.company && props.profile.company.id) {
    return <Redirect to={frontendUrl.login} />;
  }

  const joinCompany = ({ validation_code }) => {
    const data = { secret_code: validation_code };
    props.addCompanyToProfile(user, token, data, props.t);
  };

  return (
    <>
      <Dialog
        open={isJoinCompanyModalOpen}
        onClose={() => handleCloseJoinDialog(props)}
        aria-labelledby="alert-dialog-title-join"
        aria-describedby="alert-dialog-description-join"
        fullWidth={true}
        maxWidth={"md"}
      >
        <DialogTitle id="simple-dialog-title">
          <div>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <Grid
                  container
                  spacing={3}
                  direction="row"
                  justifyContent="space-around"
                  alignItems="center"
                >
                  <Grid item xs={11}>
                    <Grid
                      container
                      direction="row"
                      justifyContent="flex-start"
                      alignItems="center"
                    >
                      <Grid item>
                        <Typography variant="h6">
                          {props.t("PROFILE:join_company")}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid
                    item
                    style={{ cursor: "pointer" }}
                    onClick={() => handleCloseJoinDialog(props)}
                  >
                    <CloseIcon />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </div>
        </DialogTitle>

        <DialogContent fullWidth={true} maxWidth={"md"}>
          <JoinCompanyForm {...props} joinCompany={joinCompany} t={props.t} />
        </DialogContent>
        <DialogActions />
      </Dialog>

      <CompanyDialogForm
        {...props}
        open={isCreateCompanyModalOpen}
        onClose={() => handleCloseCreateDialog(props)}
      />

      <ValidationPage
        title={props.t("PROFILE:company_register_landing_page_welcome_title")}
        body={props.t(
          "PROFILE:company_register_landing_page_welcome_description"
        )}
        primaryText={props.t(
          "PROFILE:company_register_landing_page_welcome_join_action"
        )}
        primaryUrl={frontendUrl.joinCompany}
        secondaryText={props.t(
          "PROFILE:company_register_landing_page_welcome_register_action"
        )}
        secondaryUrl={frontendUrl.registerCompany}
        image={accessDeniedImg}
      />
    </>
  );
};

export default CompanyRegistrationComponent;
