import { Box, CircularProgress, Typography } from "@mui/material";
import React, { ComponentProps, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import {
  selectFetchPublicRequestLoading,
  selectPublicRequest,
} from "../../redux/selectors";
import { profileNextActions } from "../../redux";
import CompanyAvatar from "../../components/company-profile-full/company-avatar/company-avatar";
import { RichTextEditor } from "@next/components/rich-text-editor";
import { SellerDashboardBuyerProfilePastJobsTable } from "@next/modules/dashboard/components/seller-dashboard-buyer-profile/seller-dashboard-buyer-profile-past-jobs-table/seller-dashboard-buyer-profile-past-jobs-table";
import { useTranslation } from "react-i18next";
import { createStyles, makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      padding: theme.spacing(4),
      backgroundColor: theme.palette.common.white,
      border: `1px solid ${theme.palette.divider}`,
      borderRadius: theme.spacing(2),
    },
    loader: {
      position: "absolute",
      left: 0,
      right: 0,
      bottom: 0,
      top: 0,
      display: "grid",
      placeItems: "center",
    },
  })
);

const RequestDetailView: React.FC = () => {
  const classes = useStyles();
  const { id } = useParams<{ id: string }>();
  const loading = useSelector(selectFetchPublicRequestLoading) || !id;
  const rfq = useSelector(selectPublicRequest);
  const dispatch = useDispatch();
  const { t } = useTranslation();

  useEffect(() => {
    if (id) dispatch(profileNextActions.fetchPublicRequestRequest(+id));
  }, [id]);

  return (
    <>
      {loading || !rfq ? (
        <Box className={classes.loader}>
          <CircularProgress />
        </Box>
      ) : (
        <div className={classes.root}>
          <Typography variant="h4">{rfq.rfq_display_name}</Typography>
          <Box mt={2} display="flex" alignItems="center" gap="12px">
            <CompanyAvatar
              avatarSrc={rfq.company_logo?.[0]}
              companyName={rfq.company_name}
              showName={false}
              bordered={false}
              height={48}
            />
            <Typography variant="body1">{rfq.company_name}</Typography>
          </Box>

          {rfq.additional_details && (
            <>
              <Box mt={6}>
                <Typography variant="h6">
                  {t("profile:requestDetail:requestDescription")}
                </Typography>
                <Box mt={2}>
                  <RichTextEditor
                    readOnly
                    defaultValue={
                      rfq.additional_details_rich_text || rfq.additional_details
                    }
                    maxCharsToShow={500}
                  />
                </Box>
              </Box>
            </>
          )}

          {rfq.parts?.length ? (
            <Box mt={5}>
              <Typography variant="h6">
                {t("profile:requestDetail:parts")}
              </Typography>
              <Box mt={2}>
                <SellerDashboardBuyerProfilePastJobsTable
                  parts={
                    rfq.parts as ComponentProps<
                      typeof SellerDashboardBuyerProfilePastJobsTable
                    >["parts"]
                  }
                />
              </Box>
            </Box>
          ) : null}
        </div>
      )}
    </>
  );
};

export default RequestDetailView;
