import React from "react";
import { useTranslation } from "react-i18next";
import { FieldArray, Form, FormikProps } from "formik";
import { Box, Button, Grid, IconButton, Typography } from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import { GenericTextInput } from "components/utils/input/text";
import { confirmDialog } from "@next/modals/shared-confirm-dialog";
import {
  InviteSupplierFormValues,
  InvitedSupplier,
} from "./invite-supplier.types";

const InviteSupplierFormBase: React.FC<
  FormikProps<InviteSupplierFormValues>
> = ({ values, handleSubmit }) => {
  const { t } = useTranslation();

  const onRemoveSupplier = (
    index: number,
    removeCallback: (index: number) => void
  ) => {
    confirmDialog(
      t("workspace:confirmation"),
      t("workspaceNext:inviteSupplier:areYouSureWantToDelete"),
      () => {
        removeCallback(index);
      }
    );
  };

  return (
    <Form onSubmit={handleSubmit}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Typography variant="body1" color="textSecondary">
            {t("workspaceNext:inviteSupplier:subtitle")}
          </Typography>
        </Grid>
        <FieldArray
          name="suppliers"
          render={(arrayHelpers) => (
            <Grid item xs={12}>
              {Array.isArray(values?.suppliers) &&
                values?.suppliers?.map(
                  (supplier: InvitedSupplier, index: number) => {
                    return (
                      <Grid container spacing={4} key={index}>
                        <Grid item xs={11} container spacing={2}>
                          <Grid item xs={4}>
                            <GenericTextInput
                              name={`suppliers[${index}].name`}
                              label={t(
                                "workspaceNext:inviteSupplier:labelCompanyName"
                              )}
                              value={supplier?.name}
                            />
                          </Grid>
                          <Grid item xs={4}>
                            <GenericTextInput
                              name={`suppliers[${index}].email`}
                              label={t(
                                "workspaceNext:inviteSupplier:labelEmail"
                              )}
                              value={supplier?.email}
                            />
                          </Grid>
                          <Grid item xs={4}>
                            <GenericTextInput
                              name={`suppliers[${index}].contact_name`}
                              label={t(
                                "workspaceNext:inviteSupplier:labelContactName"
                              )}
                              value={supplier?.contact_name}
                            />
                          </Grid>
                        </Grid>
                        <Grid item xs={1}>
                          <IconButton
                            onClick={() =>
                              onRemoveSupplier(index, arrayHelpers.remove)
                            }
                            size="large"
                          >
                            <DeleteIcon />
                          </IconButton>
                        </Grid>
                      </Grid>
                    );
                  }
                )}
              <Box mt={1}>
                <Button
                  startIcon={<AddCircleIcon />}
                  onClick={(event) => {
                    event.preventDefault();
                    arrayHelpers.push({
                      name: "",
                      email: "",
                      contact_name: "",
                    });
                  }}
                >
                  {t("workspaceNext:inviteSupplier:addAnotherSupplier")}
                </Button>
              </Box>
            </Grid>
          )}
        />

        <Grid item container justifyContent="flex-end">
          <Button
            variant="contained"
            color="primary"
            disabled={values?.suppliers.length > 0 ? false : true}
            type="submit"
          >
            {t("workspaceNext:inviteSupplier:invite")}
          </Button>
        </Grid>
      </Grid>
    </Form>
  );
};

export default InviteSupplierFormBase;
