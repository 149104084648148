import { Link } from "@mui/material";
import { createStyles, makeStyles } from "@mui/styles";
import React from "react";

const useStyles = makeStyles((theme) =>
  createStyles({
    link: {
      cursor: "pointer",
      color: theme.palette.primary.main,
      "&:hover": {
        color: theme.palette.primary.main,
        textDecoration: "none",
      },
      fontSize: theme.typography.body2.fontSize,
    },
    disabled: {
      cursor: "not-allowed",
      "&:hover": {
        color: theme.palette.primary.main,
        textDecoration: "none",
      },
      pointerEvents: "none",
    },
  })
);

type Props = React.ComponentProps<typeof Link> & { disabled?: boolean };

const LinkButton: React.FC<Props> = ({ children, disabled, ...props }) => {
  const classes = useStyles();

  return (
    <Link
      {...props}
      className={`${classes.link} ${props.className || ""} ${
        disabled ? classes.disabled : ""
      }`}
    >
      {children}
    </Link>
  );
};

export default LinkButton;
