import React from "react";
import { Box, Chip, Theme, Typography, alpha } from "@mui/material";
import AccordionWrapper, {
  AccordionWrapperProps,
} from "@next/components/accordion-wrapper/accordion-wrapper";
import { AppTheme } from "layout/AppTheme";
import CheckIcon from "@mui/icons-material/Check";
import { useTranslation } from "react-i18next";
import { makeStyles } from "@mui/styles";

export const useStyles = makeStyles((theme: Theme) => ({
  accordionRoot: {
    width: "100%",
    border: `1px solid ${theme.palette.divider}`,
    borderRadius: 24,
    padding: "24px 24px",
    "&:before": {
      display: "none",
    },

    "&.Mui-expanded": {
      margin: 0,
    },
    "&.MuiAccordion-root": {
      borderTopLeftRadius: 24,
      borderTopRightRadius: 24,
      borderBottomLeftRadius: 24,
      borderBottomRightRadius: 24,
    },
  },
  accordionSummaryRoot: {
    border: "none",
    flexDirection: "row",
    paddingLeft: theme.spacing(0),
  },
  accordionDetailsRoot: {
    width: "100%",
    flexDirection: "column",
    border: "none",
    paddingLeft: theme.spacing(0),
  },
}));

type Props = {
  completenessPercentage?: number;
  isCompleted?: boolean;
  isOptional?: boolean;
  titleTag?: string;
};

const CompanyProfileAccordionWrapper: React.FC<
  AccordionWrapperProps & Props
> = ({
  completenessPercentage,
  isCompleted,
  isOptional,
  titleTag,
  ...props
}) => {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <AccordionWrapper
      {...props}
      title={
        <Box display="flex" alignItems="center" gap="16px">
          <>
            {completenessPercentage && !isCompleted ? (
              <Box
                width={8}
                height={8}
                borderRadius="100%"
                bgcolor={AppTheme.palette.primary.main}
              />
            ) : null}

            <Typography variant="h5">{props.title}</Typography>

            {(completenessPercentage || isOptional) && (
              <Chip
                label={
                  isCompleted
                    ? t("profile:companyProfile:completed")
                    : isOptional
                    ? t("profile:companyProfile:optional")
                    : `+ ${completenessPercentage}%`
                }
                variant={isOptional ? "outlined" : undefined}
                color={completenessPercentage ? "primary" : undefined}
                icon={
                  isCompleted ? (
                    <CheckIcon
                      style={{ color: AppTheme.palette.success.dark }}
                    />
                  ) : undefined
                }
                style={
                  isCompleted
                    ? {
                        color: AppTheme.palette.success.dark,
                        backgroundColor: alpha(
                          AppTheme.palette.success.dark,
                          0.1
                        ),
                      }
                    : undefined
                }
              />
            )}

            {completenessPercentage && !isCompleted && titleTag && (
              <Typography variant="body2" color="primary">
                {titleTag}
              </Typography>
            )}
          </>
        </Box>
      }
      accordionClasses={{ root: classes.accordionRoot }}
      accordionSummaryClasses={{
        root: classes.accordionSummaryRoot,
      }}
      accordionDetailsClasses={{ root: classes.accordionDetailsRoot }}
    >
      {props.children}
    </AccordionWrapper>
  );
};

export default CompanyProfileAccordionWrapper;
