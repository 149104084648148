export const getQuotationDetails = (state: any) =>
  state.quotation.quotationDetails;
export const getIsEditQuotationMode = (state: any) =>
  state.quotation.isEditQuotationMode;
export const getLeadTimeOptions = (state: any) =>
  state.quotation.leadTimeOptions;

export const quotationSelectors = {
  getQuotationDetails,
  getIsEditQuotationMode,
  getLeadTimeOptions,
};
