import { Button, darken } from "@mui/material";
import React, { PropsWithChildren } from "react";
import { createStyles, makeStyles } from "@mui/styles";

const companyColor = "#E85C2C";

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      display: "flex",
      justifyContent: "flex-end",
    },
    button: {
      color: "#fff",
      backgroundColor: companyColor,
      "&:hover": { backgroundColor: darken(companyColor, 0.1) },
    },
  })
);

type Props = {
  onClick?: () => void;
};

const PublicRfqCreationFormButton: React.FC<PropsWithChildren<Props>> = ({
  onClick,
  children,
}) => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Button
        type="submit"
        variant="contained"
        className={classes.button}
        onClick={onClick}
      >
        {children}
      </Button>
    </div>
  );
};

export default PublicRfqCreationFormButton;
