import slugify from "slugify";

export const slugifyString = (str: string) => {
  try {
    return slugify(str, {
      replacement: "-", // replace spaces with replacement character, defaults to `-`
      lower: true, // convert to lower case, defaults to `false`
    });
  } catch (error) {
    console.log("Error slugify using base string", str);
    return str;
  }
};

export const buildStringWithComma = (data: any[]) => {
  let text = "";
  try {
    text = data
      ?.filter((x) => typeof x === "string" && x.length > 0)
      ?.join(", ");
  } catch (error) {
    console.log("Error using buildStringWithComma", error);
  } finally {
    return text;
  }
};

export const getFirstCharacter = (str?: string) => {
  if (typeof str !== "string") {
    return "";
  }

  var matches = str?.match(/\b(\w)/g); // ['J','S','O','N']
  var acronym = matches?.join(""); // JSON

  if (!acronym) {
    return "";
  }

  return `${acronym[0]?.toUpperCase()}${(acronym[1] || "").toUpperCase()}`;
};

export const getInitials = (str: string, limit = 2) => {
  let initialText = "";

  try {
    initialText = str
      ?.split(" ")
      .slice(0, limit)
      ?.reduce((acc, curr) => acc + (curr[0] || "")?.toUpperCase(), "");
  } catch (error) {
    console.log("Error using getInitials", error);
  }

  return initialText;
};

export const hasValidUrlProtocol = (url = "") =>
  ["http://", "https://", "ftp://"].some((protocol) =>
    url.startsWith(protocol)
  );
