import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { FormFooter } from "../../form-footer/form-footer";
import { FormParentBox } from "../../form-parent-box";
import { FormTitle } from "../../form-title/form-title";
import { FormHeaderContent } from "./form-header-content/form-header-content";
import { LeadTimeForImplementationFormTable } from "../../table/lead-time-for-implementation-form-table/lead-time-for-implementation-form-table";
import { FormBoxLg } from "../../form-box/form-box-lg";
import {
  Box,
  FormControl,
  FormControlLabel,
  Radio,
  TextField,
} from "@mui/material";
import { useParams } from "react-router-dom";
import { FormFieldBox } from "../../form-field-box";
import {
  FormFieldLabel,
  InfoTitle,
} from "../../form-field-label/form-field-label";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import { FormFieldErrorTypography } from "../../form-field-error-typography/form-field-error-typography";
import { FormFileAttachmentButton } from "../../form-file-attachment-button/form-file-attachment-button";
import { FormAttachedFileBox } from "../../form-attached-file-box/form-attached-file-box";
import { TableLinesBox } from "../../table-lines-box/table-lines-box";
import {
  selectFormData,
  selectIsAllStepsCompleted,
  selectIsFormCompleted,
  selectIsLastStep,
  selectIsThereUnsavedChangesInFormStep,
  selectQuestionnaireName,
  selectSpecsOfScope,
  selectVisibleSteps,
} from "@next/modules/vendor-management/redux/selectors";
import {
  FieldMandatoryText,
  LeadTimeForImplementationFormData,
  PaginationSizeForFormTable,
  PartialStep,
  Questionnaire,
  VendorManagementModalTypes,
  vendorManagementActions,
} from "@next/modules/vendor-management/redux";
import { modalsActions } from "@next/redux/modalsSlices";
import { showInvalidMessage } from "@next/modules/vendor-management/utils/showInvalidMessage";
import { showCompletedMessage } from "@next/modules/vendor-management/utils/showCompletedMessage";
import { getActiveStepIndex } from "@next/modules/vendor-management/utils/getActiveStepIndex";
import { showSavedMessage } from "@next/modules/vendor-management/utils/showSavedMessage";
import * as S from "./lead-time-for-implementation-form.styled";

export const LeadTimeForImplementationForm: React.FC = () => {
  const { companyId } = useParams<{ companyId: string }>();
  const dispatch = useDispatch();
  const questionnaireName = useSelector(selectQuestionnaireName);
  const isFormCompleted = useSelector(selectIsFormCompleted);
  const isLastStep = useSelector(selectIsLastStep);
  const isAllStepsCompleted = useSelector(selectIsAllStepsCompleted);
  const steps = useSelector(selectVisibleSteps);
  const activeFormStepIndex = getActiveStepIndex(steps);
  const step = steps[activeFormStepIndex];
  const isThereUnsavedChanges = useSelector(
    selectIsThereUnsavedChangesInFormStep
  );
  const leadTimeForImplementationFormData = useSelector(
    selectFormData
  ) as LeadTimeForImplementationFormData;
  const file = leadTimeForImplementationFormData?.road_map_file || null;
  const fileInputRef = useRef<HTMLInputElement | null>(null);
  const [isShowErrorForFileAttachment, setIsShowErrorFileAttachment] =
    useState(false);
  const specsOfScopeData = useSelector(selectSpecsOfScope);
  const [page, setPage] = useState(1);
  const [hideCompletedTableLines, setHideCompletedTableLines] = useState(false);
  const {
    handleSubmit,
    formState: { errors },
    control,
    setError,
    watch,
  } = useForm<LeadTimeForImplementationFormData>({
    defaultValues: leadTimeForImplementationFormData || {},
  });

  useEffect(() => {
    if (leadTimeForImplementationFormData) {
      dispatch(
        vendorManagementActions.fetchSpecsOfScopeRequest({
          companyId: companyId,
          pagination: { page: page, pageSize: PaginationSizeForFormTable },
          stepId: step.step,
          filter_completed: hideCompletedTableLines,
        })
      );
    }
  }, [page, hideCompletedTableLines]);

  const saveChanges = (isForCompletion: boolean) => {
    const additionalComment = watch("additional_comment");
    const isIntegratedOfSpec = watch("specification_integration");
    let formData: any;
    if (
      questionnaireName === Questionnaire.PomDistributor ||
      questionnaireName === Questionnaire.PomManufacturer
    ) {
      formData = {
        additional_comment: additionalComment,
      };
    } else {
      formData = {
        specification_integration: isIntegratedOfSpec,
        additional_comment: additionalComment,
      };
    }

    dispatch(
      vendorManagementActions.updateLeadTimeImplFormDataRequest({
        companyId: companyId,
        formData,
      })
    );
    const fieldOfStep = isForCompletion
      ? "is_completed"
      : "is_partially_filled";

    updateFieldStatusOfStepForSaveAndCompletion(
      fieldOfStep,
      true,
      activeFormStepIndex
    );
    if (!isForCompletion) {
      showSavedMessage();
    }
    dispatch(vendorManagementActions.setIsThereUnsavedChangesInFormStep(false));
  };

  const confirmStepCompletion: SubmitHandler<
    LeadTimeForImplementationFormData
  > = (data) => {
    const isTableDataValidated =
      specsOfScopeData?.results?.uncompleted_count === 0;
    if (questionnaireName === Questionnaire.PosEndUsers) {
      const isAttachmentValidated = validateFileAttachmentAndSpecField();
      if (isTableDataValidated && isAttachmentValidated) {
        makeFormStepCompletionProcess();
      } else {
        showInvalidMessage();
      }
    } else {
      if (isTableDataValidated) {
        makeFormStepCompletionProcess();
      } else {
        showInvalidMessage();
      }
    }
  };

  const validateFileAttachmentAndSpecField = () => {
    const isIntegratedOfSpec = watch("specification_integration");
    if (isIntegratedOfSpec === null || isIntegratedOfSpec === undefined) {
      setError("specification_integration", { type: "manual" });
      return false;
    }
    if (isIntegratedOfSpec && !file) {
      setIsShowErrorFileAttachment(true);
      return false;
    }

    return true;
  };

  const makeFormStepCompletionProcess = () => {
    saveChanges(true);
    showCompletedMessage();
    if (isAllStepsCompleted) {
      showThankYouModal();
    } else if (!isLastStep) {
      routeNextStep();
    }
  };

  const routeNextStep = () => {
    const newActiveStepIndex = activeFormStepIndex + 1;
    setActiveFormStepIndex(newActiveStepIndex);
  };

  const setActiveFormStepIndex = (index: number) => {
    updateFieldStatusOfStepForSaveAndCompletion("is_active", true, index);
  };

  const showThankYouModal = () => {
    dispatch(
      modalsActions.showModal({
        key: VendorManagementModalTypes.THANK_YOU_MODAL,
      })
    );
  };

  const onInvalid = () => {
    showInvalidMessage();
  };

  const checkInputChange = () => {
    if (isFormCompleted) {
      updateFieldStatusOfStepForSaveAndCompletion(
        "is_completed",
        false,
        activeFormStepIndex
      );
      dispatch(
        vendorManagementActions.setIsThereUnsavedChangesInFormStep(true)
      );
    } else if (!isThereUnsavedChanges) {
      dispatch(
        vendorManagementActions.setIsThereUnsavedChangesInFormStep(true)
      );
    }
  };

  const handleRadioBtnChange = (value: boolean) => {
    if (!value) {
      if (!file && isShowErrorForFileAttachment) {
        setIsShowErrorFileAttachment(false);
      }
    }
    checkInputChange();
  };

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const selectedFile = event.target.files?.[0];
    if (selectedFile) {
      saveFileAndSpecificationIntegration(selectedFile);
      if (isShowErrorForFileAttachment) {
        setIsShowErrorFileAttachment(false);
      }
    }
    removeCompletionStatusOfStep();
  };

  const handleClickRemoveFile = () => {
    saveFileAndSpecificationIntegration("");
    removeCompletionStatusOfStep();
  };

  const removeCompletionStatusOfStep = () => {
    if (isFormCompleted) {
      updateFieldStatusOfStepForSaveAndCompletion(
        "is_completed",
        false,
        activeFormStepIndex
      );
    }
  };

  const saveFileAndSpecificationIntegration = (file: File | "") => {
    const formData = new FormData();
    formData.append("road_map", file as File);
    formData.append(
      "specification_integration",
      watch("specification_integration").toString()
    );
    dispatch(
      vendorManagementActions.updateLeadTimeImplFormDataRequest({
        companyId: companyId,
        formData,
      })
    );
  };

  const handleAttachButtonClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  const updateFieldStatusOfStepForSaveAndCompletion = (
    key: "is_completed" | "is_partially_filled" | "is_active",
    value: boolean,
    stepIndex: number
  ) => {
    const step = steps[stepIndex];
    const partialStep: PartialStep = {
      [key]: value,
    };
    dispatch(
      vendorManagementActions.updateStepRequest({
        stepId: step.id,
        data: partialStep,
      })
    );
  };

  return (
    <>
      <FormParentBox>
        <FormBoxLg isCompleted={isFormCompleted}>
          <FormTitle
            title={"Lead time for implementation"}
            isCompleted={isFormCompleted}
          />
          <S.StyledInnerBox>
            <FormHeaderContent />

            <TableLinesBox
              completedCount={specsOfScopeData?.results?.completed_count}
              totalCount={specsOfScopeData?.results?.total_count}
              hideCompletedTableLines={hideCompletedTableLines}
              setHideCompletedTableLines={setHideCompletedTableLines}
              setPage={setPage}
            />
            <InfoTitle />

            <LeadTimeForImplementationFormTable
              hideCompletedTableLines={hideCompletedTableLines}
              page={page}
              pageSize={PaginationSizeForFormTable}
              setPage={setPage}
            />
          </S.StyledInnerBox>

          {questionnaireName === Questionnaire.PosEndUsers ? (
            <S.StyledBox>
              <FormControl
                fullWidth
                margin="none"
                error={errors.specification_integration ? true : false}
              >
                <FormFieldBox>
                  <Box>
                    <FormFieldLabel label="Integration into your ecosystem: Do you have integrated Airbus specification into all your workbooks, ERP?" />
                    <FormFieldLabel label="Roadmap for implementation required if answer is Yes. If no roadmap to be uploaded on previous question on column “add action plan”." />
                  </Box>

                  <Controller
                    name="specification_integration"
                    control={control}
                    render={({ field }) => (
                      <>
                        <S.StyledRadioGroup
                          {...field}
                          value={field?.value === null ? "" : field.value}
                          onChange={(e) => {
                            const value =
                              e.target.value === "true" ? true : false;
                            handleRadioBtnChange(value);
                            field.onChange(value);
                          }}
                        >
                          <FormControlLabel
                            value="false"
                            control={<Radio />}
                            label="No"
                          />
                          <FormControlLabel
                            value="true"
                            control={<Radio />}
                            label="Yes"
                          />
                        </S.StyledRadioGroup>
                        {errors.specification_integration ? (
                          <FormFieldErrorTypography text={FieldMandatoryText} />
                        ) : null}
                      </>
                    )}
                  />
                </FormFieldBox>
              </FormControl>

              <S.StyledBox>
                <Box>
                  <FormFileAttachmentButton
                    handleAttachButtonClick={handleAttachButtonClick}
                    handleFileChange={handleFileChange}
                    fileInputRef={fileInputRef}
                    btnText={"Roadmap for implementation"}
                    disabled={!watch("specification_integration")}
                  />
                </Box>

                {file && watch("specification_integration") ? (
                  <S.AttachmentBox>
                    <FormAttachedFileBox
                      file={file as any}
                      handleClickRemove={handleClickRemoveFile}
                    />
                  </S.AttachmentBox>
                ) : null}

                {isShowErrorForFileAttachment ? (
                  <Box mt={"-5px"}>
                    <FormFieldErrorTypography text={FieldMandatoryText} />
                  </Box>
                ) : null}
              </S.StyledBox>
            </S.StyledBox>
          ) : null}

          <FormControl margin="none">
            <Controller
              name="additional_comment"
              control={control}
              defaultValue={
                leadTimeForImplementationFormData?.additional_comment || ""
              }
              render={({ field: { onChange, value } }) => (
                <S.StyledFormFieldBox>
                  <TextField
                    sx={{ width: "100%" }}
                    multiline
                    rows={3}
                    placeholder="Additional comment..."
                    value={value || ""}
                    onChange={(e) => {
                      onChange(e);
                      checkInputChange();
                    }}
                  />
                </S.StyledFormFieldBox>
              )}
            />
          </FormControl>
        </FormBoxLg>
      </FormParentBox>

      <FormFooter
        saveChanges={() => saveChanges(false)}
        confirmStepCompletion={handleSubmit(confirmStepCompletion, onInvalid)}
        isCompleted={isFormCompleted}
      />
    </>
  );
};
