import i18n from "assets/configi18n/i18n";
import moment from "moment";

export const humanizedDuration = (durationStr: string) => {
  const momentObj = moment.utc(durationStr);
  const duration = moment.duration(momentObj.diff(moment.utc()));
  return duration.humanize();
};

export const formatTimeFromNow = (date: moment.MomentInput) => {
  return moment(date).fromNow();
};

export const formatDateTimeAsCalender = (date: string) => {
  return moment(date).calendar(null, {
    sameElse:
      i18n.language === "en"
        ? "DD/MM/YYYY [at] h:mm a"
        : "DD/MM/YYYY [à] h:mm a",
  });
};
