import { Formik } from "formik";
import React, { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import {
  getInitialValues,
  getValidationSchema,
} from "./rfq-creation-form-lite.definitions";
import { RFQFormLiteValuesType } from "./rfq-creation-form-lite.types";
import RFQCreationFormLiteBase from "./rfq-creation-form-lite.base";
import { useStyles } from "./rfq-creation-form-lite.styles";
import { workspaceNextActions } from "../../redux";
import { FileData } from "@next/components/attach-file/attach-file-list.types";
import RFQCreationFormLiteHeader from "./rfq-creation-form-lite-header";

interface Props {
  initialValues?: Partial<RFQFormLiteValuesType>;
  fromDashboard?: boolean;
}

const RFQCreationFormLite: React.FC<Props> = (props) => {
  const { initialValues } = props;
  const { t } = useTranslation();
  const classes = useStyles();
  const dispatch = useDispatch();

  const onSubmit = (values: RFQFormLiteValuesType) => {
    dispatch(
      workspaceNextActions.createEasyRfqRequest({
        ...values,
        attachments:
          (values.attachments.map((item) => item.pk) as Partial<FileData>[]) ||
          [],
        fromDashboard: props?.fromDashboard,
      })
    );
  };

  const renderForm = useCallback((formikProps) => {
    return <RFQCreationFormLiteBase {...formikProps} />;
  }, []);

  return (
    <div className={classes.root}>
      <RFQCreationFormLiteHeader />

      <Formik
        render={renderForm}
        initialValues={getInitialValues(initialValues)}
        onSubmit={onSubmit}
        validationSchema={getValidationSchema(t)}
        enableReinitialize
      />
    </div>
  );
};

export default RFQCreationFormLite;
