import { Box, Button } from "@mui/material";
import React from "react";
import WatchLaterIcon from "@mui/icons-material/WatchLater";
import CheckIcon from "@mui/icons-material/Check";
import PersonAddIcon from "@mui/icons-material/PersonAdd";
import { useDispatch } from "react-redux";
import { modalsActions } from "@next/redux/modalsSlices";
import { ProfileModalTypes } from "@next/modules/profile/modals/types";
import { useTranslation } from "react-i18next";
import { confirmDialog } from "@next/modals/shared-confirm-dialog";
import { profileNextActions } from "@next/modules/profile/redux";

export enum ProfileConnectionState {
  CONNECTED = "CONNECTED",
  NOT_CONNECTED = "NOT_CONNECTED",
  PENDING = "PENDING",
}

interface Props {
  state: ProfileConnectionState;
  buyerProfile: {
    id?: number;
    name?: string;
    picture?: string;
  };
  connectionId?: number;
}

const ProfileConnectButton: React.FC<Props> = ({
  state,
  buyerProfile,
  connectionId,
}) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const onClickSendConnectionRequest = () => {
    dispatch(
      modalsActions.showModal({
        key: ProfileModalTypes.BUYER_PROFILE_SEND_MESSAGE_DRAWER,
        data: {
          buyerProfileId: buyerProfile?.id,
          buyerName: buyerProfile?.name,
          buyerPicture: buyerProfile?.picture,
        },
      })
    );
  };

  const onClickRemoveRequest = () => {
    if (!connectionId) return;

    confirmDialog(
      t("marketPlace:profile:removeInvitationModal:title"),
      "",
      () => {
        dispatch(
          profileNextActions.deleteInvitationRequest({
            connectionId,
            buyerProfileId: buyerProfile?.id,
          })
        );
      },
      t("marketPlace:profile:removeInvitationModal:removeText")
    );
  };

  switch (state) {
    case ProfileConnectionState.CONNECTED:
      return (
        <Box display={"flex"} alignItems={"center"} gap={"8px"}>
          <CheckIcon />
          {t("marketPlace:profile:connected")}
        </Box>
      );
    case ProfileConnectionState.NOT_CONNECTED:
      return (
        <Button
          color="primary"
          startIcon={<PersonAddIcon />}
          onClick={onClickSendConnectionRequest}
          variant="outlined"
          data-pid={"btnConnnectionRequestConnect"}
        >
          {t("marketPlace:profile:connect")}
        </Button>
      );
    case ProfileConnectionState.PENDING:
    default:
      return (
        <Button
          startIcon={<WatchLaterIcon />}
          onClick={onClickRemoveRequest}
          data-pid={"btnConnnectionRequestPending"}
        >
          {t("marketPlace:profile:pending")}
        </Button>
      );
  }
};

export default ProfileConnectButton;
