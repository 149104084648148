import { GridFilterItem } from "@mui/x-data-grid-pro";
import { PAGINATION_PAGE_SIZE } from "@next/constants";
import { BuyerRFQSTableField } from "@next/modules/workspace/components/buyer-rfqs-table/buyer-rfqs-table.types";

type Options = {
  currentPage: number;
  searchKey?: string | string[];
  searchValue?: string | number | (string | number)[];
  pageSize?: number;
  sortQueryString?: string;
  filterValues?: GridFilterItem[];
  filterQueryString?: string;
};

export const generatePaginationAndSearchQuery = ({
  currentPage,
  searchKey,
  searchValue,
  pageSize = PAGINATION_PAGE_SIZE,
  sortQueryString = "",
  filterValues,
  filterQueryString = "",
}: Options) => {
  let filterQuery = generateFilterQuery(filterValues) || filterQueryString;

  const paginationQuery = `page=${currentPage}&pageSize=${pageSize}${filterQuery}&${sortQueryString}`;
  let searchQuery = "";

  if (Array.isArray(searchKey) && Array.isArray(searchValue)) {
    searchQuery = searchKey
      .map((key, index) => `${key}=${encodeURIComponent(searchValue[index])}`)
      .join("&");
  } else if (searchKey && searchValue && !Array.isArray(searchValue)) {
    searchQuery = `${searchKey}=${encodeURIComponent(searchValue)}`;
  }
  return searchQuery ? `${searchQuery}&${paginationQuery}` : paginationQuery;
};

const generateFilterQuery = (filterValues?: GridFilterItem[]) => {
  if (!filterValues || filterValues.length < 1) {
    return "";
  }

  let query = "";
  filterValues.forEach((filterValue) => {
    if (["", null, undefined].includes(filterValue.value)) return;
    // remove extra_fields. from columnField
    let columnField = filterValue.columnField?.replace("extra_fields.", "");
    if (columnField === BuyerRFQSTableField.RFQ_DISPLAY_NAME)
      columnField = "name";
    else if (columnField === BuyerRFQSTableField.STATE_AND_STATUS)
      columnField = "state";
    else if (columnField === BuyerRFQSTableField.SUPPLIERS)
      columnField = "private_suppliers";

    switch (filterValue.operatorValue) {
      case "contains":
        query += `&${columnField}__icontains=${filterValue.value}`;
        break;
      case "equals":
      case "=":
        query += `&${columnField}__iexact=${filterValue.value}`;
        break;
      case "is":
        query += `&${columnField}=${filterValue.value}`;
        break;
      case "onOrAfter":
        query += `&${columnField}__gte=${filterValue.value}`;
        break;
      case "onOrBefore":
        query += `&${columnField}__lte=${filterValue.value}`;
        break;
    }
  });

  return query;
};
