import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { GridColDef } from "@mui/x-data-grid-pro";
import { ProjectCompanyPart } from "@next/modules/project/redux";
import {
  selectCompanyProfileAnalytics,
  selectCompanyProfileAnalyticsError,
  selectCompanyProfileAnalyticsLoading,
  selectSupplierOnboardingStatus,
  selectSupplierOnboardingStatusLoading,
} from "../../redux/selectors";
import { dashboardActions } from "../../redux";

export type GridStatePartData = {
  rows: Partial<ProjectCompanyPart>[];
  columns: GridColDef[];
};

export const useSellerDashboardData = () => {
  const dispatch = useDispatch();

  const companyProfileAnalytics = useSelector(selectCompanyProfileAnalytics);
  const companyProfileAnalyticsLoading = useSelector(
    selectCompanyProfileAnalyticsLoading
  );
  const companyProfileAnalyticsError = useSelector(
    selectCompanyProfileAnalyticsError
  );
  const supplierOnboardingStatus = useSelector(selectSupplierOnboardingStatus);
  const supplierOnboardingStatusLoading = useSelector(
    selectSupplierOnboardingStatusLoading
  );

  useEffect(() => {
    dispatch(dashboardActions.fetchCompanyProfileAnalyticsRequest());
    dispatch(dashboardActions.fetchSupplierOnboardingStatusRequest());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    companyProfileAnalytics,
    companyProfileAnalyticsLoading:
      companyProfileAnalyticsLoading || supplierOnboardingStatusLoading,
    companyProfileAnalyticsError,
    supplierOnboardingStatus,
  };
};
