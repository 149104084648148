import {
  Box,
  Chip,
  CircularProgress,
  DialogTitle,
  IconButton,
  Theme,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { BulkFileImportUploadFile } from "./bulk-file-import-upload-file";
import { BulkFileImportSummary } from "./bulk-file-import-summary";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import {
  selectBomFilesUploadReport,
  selectProjectStats,
  selectUploadBomFilesLoading,
} from "../../redux/selectors";
import { getTitleByStep } from "./bulk-file-import.utils";
import { projectActions } from "../../redux";
import CloseIcon from "@mui/icons-material/Close";
import { createStyles, makeStyles } from "@mui/styles";
import { FileData } from "@next/components/attach-file/attach-file-list.types";
import LocalCafeOutlinedIcon from "@mui/icons-material/LocalCafeOutlined";

export enum BulkFileImportStep {
  UPLOAD_FILE,
  SUMMARY,
}

type StyleProps = {
  step: BulkFileImportStep;
};

const useStyles = makeStyles<Theme, StyleProps>(() =>
  createStyles({
    root: {
      width: ({ step }) =>
        step === BulkFileImportStep.SUMMARY ? "900px" : "500px",
      height: ({ step }) =>
        step === BulkFileImportStep.UPLOAD_FILE ? "unset" : "596px",
    },
    loading: {
      width: "600px",
      height: "480px",
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
    },
    loadingTitle: {
      marginTop: "24px",
    },
    loadingSubtitle: {
      marginTop: "12px",
    },
  })
);

type Props = {
  onCloseModal: () => void;
  filesCount?: number;
  onAttachmentsChange?: (files: FileData[], filePkToDelete?: number) => void;
};

export const BulkFileImport: React.FC<Props> = ({
  onCloseModal,
  filesCount,
  onAttachmentsChange,
}) => {
  const { t } = useTranslation();
  const [step, setStep] = useState(BulkFileImportStep.UPLOAD_FILE);
  const classes = useStyles({ step });
  const projectStats = useSelector(selectProjectStats);
  const [totalFileCount, setTotalFileCount] = useState(filesCount || 0);

  const dispatch = useDispatch();
  const uploadBomFilesLoading = useSelector(selectUploadBomFilesLoading);
  const bomFilesUploadReport = useSelector(selectBomFilesUploadReport);

  useEffect(() => {
    if (bomFilesUploadReport?.status === "COMPLETED") {
      setStep(BulkFileImportStep.SUMMARY);
    }
  }, [bomFilesUploadReport]);

  const onUpload = (zippedFile: File, fileCount: number) => {
    if (projectStats?.pk) {
      setTotalFileCount(fileCount);
      dispatch(
        projectActions.uploadBomFilesRequest({
          file: zippedFile,
          projectId: projectStats?.pk,
        })
      );
    }
  };

  const renderStep = () => {
    switch (step) {
      case BulkFileImportStep.UPLOAD_FILE:
        return <BulkFileImportUploadFile onUpload={onUpload} />;
      case BulkFileImportStep.SUMMARY:
        return (
          <BulkFileImportSummary
            onClickCloseReport={onCloseModal}
            onAttachmentsChange={onAttachmentsChange}
          />
        );

      default:
        return null;
    }
  };

  return uploadBomFilesLoading ? (
    <div className={classes.loading}>
      <CircularProgress />

      <Typography className={classes.loadingTitle} variant="h6">
        {t("project:bulkFileImport:loadingTitle", { count: totalFileCount })}
      </Typography>
      <Box className={classes.loadingSubtitle}>
        <Chip
          icon={<LocalCafeOutlinedIcon className={classes.cafeIcon} />}
          label={
            <Typography variant="caption">
              {t("project:bulkPartImportLoader:loadingSubtitle")}
            </Typography>
          }
        />
      </Box>
    </div>
  ) : (
    <Box className={classes.root}>
      <DialogTitle>
        <Box display="flex" alignItems="center">
          <Box flexGrow={1}> {getTitleByStep(step)}</Box>

          <Box>
            <IconButton onClick={onCloseModal} size="large">
              <CloseIcon />
            </IconButton>
          </Box>
        </Box>

        {step === BulkFileImportStep.UPLOAD_FILE ? (
          <Box pr="32px" mt={"8px"}>
            <Typography variant="body2">
              {t("project:bulkFileImport:subtitle")}
            </Typography>
          </Box>
        ) : null}
      </DialogTitle>

      {renderStep()}
    </Box>
  );
};
