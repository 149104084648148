import React, { useEffect, useMemo, useState } from "react";
import { Box, IconButton, Theme, Typography } from "@mui/material";
import { FileDropzone } from "@next/modules/project/components/file/file-dropzone";
import { FileList } from "@next/modules/project/components/file/file-list/file-list";
import PartViewer from "components/part-dialog/viewer/viewer.component";
import { useDispatch, useSelector } from "react-redux";
import { PartUpload, ProjectCompanyPart } from "@next/modules/project/redux";
import {
  selectProjectCompanyPart,
  selectProjectUpdatePartLoading,
  selectSelectedPartPk,
} from "@next/modules/project/redux/selectors";
import { ProjectModalTypes } from "@next/modules/project/modals/types";
import { modalsActions } from "@next/redux/modalsSlices";
import { ProjectFileDeleteConfirmationModal } from "@next/modules/project/modals/project-file-delete-confirmation-modal";
import { CustomCircularProgress } from "@next/components/custom-circular-progress";
import {
  selectRFQPart,
  selectRFQPartForEdit,
} from "services/workspace/workspace.selectors";
import CloseIcon from "@mui/icons-material/Close";
import { selectUploadMultiplePartFiles } from "@next/modules/workspace/redux/selectors";
import { selectBuyerProfilePart } from "@next/modules/dashboard/redux/selectors";
import { useTranslation } from "react-i18next";
import { makeStyles } from "@mui/styles";
import { selectFileUploadProgress } from "@next/modules/workspace/redux/selectors";
import { workspaceNextActions } from "@next/modules/workspace/redux";
import { workspaceActions } from "services/workspace/workspace.actions";

export const useStyles = makeStyles((theme: Theme) => ({
  container: {
    display: "flex",
    height: "calc(100vh - 100px)",
    position: "relative",
    overflow: "hidden",
  },
  mainContainer: {
    display: "flex",
    width: "100%",
  },
  leftBox: {
    width: "75%",
    borderRight: `1px solid ${theme.palette.divider}`,
  },
  rightBox: {
    width: "25%",
  },
  header: {
    borderBottom: `1px solid ${theme.palette.divider}`,
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    padding: "16px",
  },
  closeButton: {
    color: "inherit",
  },
  closeIcon: {
    width: "24px",
    height: "24px",
  },
  content: {
    padding: "24px",
    paddingBottom: "0px",
    maxHeight: "calc(100vh - 190px)",
    overflowY: "auto",
  },
  item: {
    display: "flex",
    justifyContent: "space-between",
    marginBottom: "12px",
  },
  itemLabel: {
    marginRight: "12px",
    color: theme.palette.text.secondary,
  },
  itemDescription: {
    marginTop: "12px",
  },
  itemDescriptionText: {
    display: "inherit",
    whiteSpace: "break-spaces",
    wordBreak: "break-all",
  },
  uploadBox: {
    marginTop: "12px",
  },
  fileListContainer: {
    marginTop: "12px",
  },
  viewerPlaceholder: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    height: "100%",
  },
}));

type Props = {
  isRFQPart?: boolean;
  isRFQEditMode?: boolean;
  readOnly?: boolean;
  part?: ProjectCompanyPart;
  onClose?: () => void;
  isBuyerProfilePart?: boolean;
  preSelectedFileId?: number;
};

export const ProjectFile: React.FC<Props> = ({
  isRFQPart,
  isRFQEditMode,
  readOnly,
  part,
  onClose,
  isBuyerProfilePart,
  preSelectedFileId,
}) => {
  const classes = useStyles();
  const { t } = useTranslation();

  const dispatch = useDispatch();
  const selectedPartPk = useSelector(selectSelectedPartPk) as number;

  let selectedPart = useSelector(
    isBuyerProfilePart
      ? selectBuyerProfilePart
      : isRFQPart
      ? selectRFQPart(selectedPartPk)
      : isRFQEditMode
      ? selectRFQPartForEdit(selectedPartPk)
      : selectProjectCompanyPart(selectedPartPk)
  );
  const fileUploadProgress = useSelector(selectFileUploadProgress);

  if (selectedPart?.part_uploads_list && !selectedPart && !isBuyerProfilePart) {
    selectedPart = part;
  }

  const projectUpdatePartLoading = useSelector(selectProjectUpdatePartLoading);
  const uploadMultiplePartFilesLoading = useSelector(
    selectUploadMultiplePartFiles
  );

  const partUploadsList = useMemo(
    () => selectedPart?.part_uploads_list || [],
    [selectedPart?.part_uploads_list]
  );

  const [selectedFile, setSelectedFile] = useState<PartUpload>(
    partUploadsList.find((file) => file.pk === preSelectedFileId) ||
      partUploadsList[0]
  );
  const [fileToDelete, setFileToDelete] = useState<PartUpload>();

  useEffect(() => {
    if (preSelectedFileId) {
      setSelectedFile(
        partUploadsList.find((file) => file.pk === preSelectedFileId) ||
          partUploadsList[0]
      );
    }
  }, [partUploadsList, preSelectedFileId]);

  const deleteUploadingFile = () => {
    dispatch(
      workspaceNextActions.removeFileUploadProgress({
        filesName: `${fileToDelete?.file_name}.${fileToDelete?.file_extension}`,
      })
    );
  };

  const handleDeleteDuringEdit = (filePk: number) => {
    const updatedPartAttachement = partUploadsList.filter(
      (part) => part.pk !== filePk
    );
    dispatch(
      workspaceActions.editRFQPartsAttachement(
        selectedPartPk,
        updatedPartAttachement
      )
    );
  };

  const onDeleteFile = (file: PartUpload) => {
    setFileToDelete(file);
    dispatch(
      modalsActions.showModal({
        key: ProjectModalTypes.FILE_DELETE_CONFIRMATION_MODAL,
        data: {
          deleteFilePk: file?.pk,
          isRFQPart: isRFQPart,
          isRFQEditMode: isRFQEditMode,
          customDeleteHandler: deleteUploadingFile,
          customUpdateHandler: handleDeleteDuringEdit,
        },
      })
    );
  };

  return (
    <Box className={classes.container}>
      <Box className={classes.mainContainer}>
        <Box className={classes.leftBox}>
          {selectedPart?.part_uploads_list?.length ? (
            <PartViewer
              src={selectedFile?.file_url}
              activeFilePk={selectedFile?.pk}
              disableSnapshot={true}
            />
          ) : (
            <Box className={classes.viewerPlaceholder}>
              {t("project:projectFileModal:looksLikeItIsEmpty")}
            </Box>
          )}
        </Box>
        <Box className={classes.rightBox}>
          <Box className={classes.header}>
            <Typography variant="h6">
              {t("project:projectFileModal:partViewer")}
            </Typography>
            <IconButton
              aria-label="close"
              color="inherit"
              className={classes.closeButton}
              onClick={onClose}
              size="large"
            >
              <CloseIcon className={classes.closeIcon} />
            </IconButton>
          </Box>

          <Box className={classes.content}>
            <Box className={classes.item}>
              <Box className={classes.itemLabel}>
                {" "}
                {t("project:projectFileModal:partName")}
              </Box>
              <Box>{selectedPart?.name}</Box>
            </Box>

            {selectedPart?.material ? (
              <Box className={classes.item}>
                <Box className={classes.itemLabel}>
                  {t("project:projectFileModal:material")}
                </Box>
                <Box>{selectedPart?.material}</Box>
              </Box>
            ) : null}

            {selectedPart?.process ? (
              <Box className={classes.item}>
                <Box className={classes.itemLabel}>
                  {t("project:projectFileModal:process")}
                </Box>
                <Box>{selectedPart?.process}</Box>
              </Box>
            ) : null}

            <Box className={classes.item}>
              <Box className={classes.itemLabel}>
                {t("project:projectFileModal:quantity")}
              </Box>
              <Box>{selectedPart?.quantity}</Box>
            </Box>

            <Box>
              <Box className={classes.itemLabel}>Description</Box>
              <Box className={classes.itemDescription}>
                <Typography
                  className={classes.itemDescriptionText}
                  variant="body2"
                >
                  {selectedPart?.additional_details}
                </Typography>
              </Box>
            </Box>

            <Box mt="12px">
              <Box className={classes.itemLabel}>
                {t("project:projectFileModal:files")}
              </Box>

              {!readOnly ? (
                <Box className={classes.uploadBox}>
                  {projectUpdatePartLoading ||
                  uploadMultiplePartFilesLoading ? (
                    <CustomCircularProgress />
                  ) : readOnly ? null : (
                    <FileDropzone
                      isRFQPart={isRFQPart}
                      isRFQEditMode={isRFQEditMode}
                    />
                  )}
                </Box>
              ) : null}

              <Box className={classes.fileListContainer}>
                <FileList
                  files={partUploadsList}
                  filesUploading={fileUploadProgress}
                  selectedFile={selectedFile}
                  setSelectedFile={setSelectedFile}
                  onDelete={onDeleteFile}
                  readOnly={readOnly}
                />
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>

      <ProjectFileDeleteConfirmationModal />
    </Box>
  );
};
