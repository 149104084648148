import { Theme } from "@mui/material";
import React from "react";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import CheckOutlinedIcon from "@mui/icons-material/CheckOutlined";
import CustomButton from "../custom-button";
import { useTranslation } from "react-i18next";
import { createStyles, makeStyles } from "@mui/styles";

const useStyles = makeStyles<Theme>(() =>
  createStyles({
    root: {
      display: "flex",
    },
    portalRightButton: { marginLeft: "8px" },
  })
);

export enum ItbItnbStatus {
  "INTENT_TO_QOUTE",
  "NO_QUOTE",
  "NO_SELECTION",
}

type Props = {
  status: ItbItnbStatus;
  setItbItnbStatus: (status: ItbItnbStatus) => void;
  loading?: boolean;
  isRFI?: boolean;
};

const ItbItnbToggle: React.FC<Props> = ({
  status = ItbItnbStatus.NO_SELECTION,
  setItbItnbStatus,
  loading,
  isRFI,
}) => {
  const classes = useStyles();
  const { t } = useTranslation();

  const onClickNoQuote = () => {
    const newItbItnbStatus =
      status === ItbItnbStatus.NO_QUOTE
        ? ItbItnbStatus.NO_SELECTION
        : ItbItnbStatus.NO_QUOTE;

    setItbItnbStatus(newItbItnbStatus);
  };

  const onClickIntentToQuote = () => {
    const newItbItnbStatus =
      status === ItbItnbStatus.INTENT_TO_QOUTE
        ? ItbItnbStatus.NO_SELECTION
        : ItbItnbStatus.INTENT_TO_QOUTE;

    setItbItnbStatus(newItbItnbStatus);
  };

  return (
    <div className={classes.root}>
      <CustomButton
        startIcon={<CloseOutlinedIcon />}
        variant={status === ItbItnbStatus.NO_QUOTE ? "contained" : "outlined"}
        onClick={onClickNoQuote}
        color={status === ItbItnbStatus.NO_QUOTE ? "error" : "default"}
        disabled={loading}
      >
        {t(
          isRFI
            ? "workspaceNext:itnbItbToggle:notInterested"
            : "workspaceNext:itnbItbToggle:noQuote"
        )}
      </CustomButton>

      <div className={classes.portalRightButton}>
        <CustomButton
          startIcon={<CheckOutlinedIcon />}
          variant={
            status === ItbItnbStatus.INTENT_TO_QOUTE ? "contained" : "outlined"
          }
          onClick={onClickIntentToQuote}
          color={
            status === ItbItnbStatus.INTENT_TO_QOUTE ? "success" : "default"
          }
          disabled={loading}
        >
          {t(
            isRFI
              ? "workspaceNext:itnbItbToggle:intentToSubmit"
              : "workspaceNext:itnbItbToggle:intentToQuote"
          )}
        </CustomButton>
      </div>
    </div>
  );
};

export default ItbItnbToggle;
