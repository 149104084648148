import React, { forwardRef } from "react";

import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

import { useHistory } from "react-router-dom";
import { useTracking } from "react-tracking";

//helper function use it to redirect to another page

import {
  AppBar,
  Box,
  Button,
  Dialog,
  DialogContent,
  Slide,
} from "@mui/material";

import QuotationView from "../../components/marketplace/quotation-view/quotation-view.component";

import { modalsActions } from "../../services/modals/modals.actions";

//  A slide up animation of the component when it is opened.
const TransitionToQuotationView = forwardRef((props, ref) => {
  return <Slide direction="up" ref={ref} {...props} />;
});

export const EditQuotationDialog = ({ open }) => {
  const browserHistory = useHistory();
  const tracking = useTracking();
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const quotationDetails = useSelector(
    (state) => state.quotation.quotationDetails
  );

  const profile = useSelector((state) => state.profile);

  // onClick close edit quotation dialog
  const onClickCloseEditQuotationDialog = () => {
    dispatch(modalsActions.hideModal("isEditQuotationDialogOpen"));
    browserHistory.goBack();

    tracking.trackEvent({
      scout_category: "feature",
      scout_feature_name: "close_edit_quotation_dialog",
      scout_company: profile["company"]["name"],
      scout_username: profile["user"]["name"],
      scout_user_type: profile["user"]["type"],
      scout_date: new Date(),
    });
  };

  return (
    <Dialog
      fullScreen
      open={open}
      onClose={onClickCloseEditQuotationDialog}
      TransitionComponent={TransitionToQuotationView}
      className={"c-edit-quotation-dialog"}
    >
      <div className="c-edit-quotation-dialog--container">
        <AppBar
          elevation={1}
          className={`c-edit-quotation-dialog--app-bar`}
          position="fixed"
        >
          <Box display="flex" alignItems="center">
            <Box flexGrow={1}>
              <Box
                ml={1}
                fontSize={20}
                color="#000000DE"
                letterSpacing={0.15}
                fontWeight="fontWeightMedium"
              >
                {quotationDetails &&
                  quotationDetails?.rfq_info?.rfq_display_name}
              </Box>
            </Box>
            <Box>
              <Button
                variant="contained"
                color="secondary"
                onClick={onClickCloseEditQuotationDialog}
              >
                {t("cancel")}
              </Button>
            </Box>
          </Box>
        </AppBar>

        <DialogContent className="c-edit-quotation-dialog--content">
          <QuotationView
            token={profile.token}
            activeQuote={quotationDetails}
            isRfqDetailsLoading={false}
          />
        </DialogContent>
      </div>
    </Dialog>
  );
};
