import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

// component file imports
import Modal from "@next/components/custom-modal";

// action file imports
import { modalsActions } from "../../services/modals/modals.actions";
import { workspaceActions } from "../../services/workspace";
import { marketplaceActions } from "../../services/marketplace";
import { NdaUpload } from "../nda-upload/nda-upload.component";

const BuyerNdaUploadModal = ({
  isModalOpen,
  setIsNDAUploadModalOpen,
  isSeller = false,
  isFinalSignedNda = false,
  rfqPk = "",
  isFinalNDAUploadDone,
}) => {
  const dispatch = useDispatch();
  const { hideModal } = modalsActions;
  const { t } = useTranslation("workspace");

  const buyerNdaAttachments = useSelector(
    (state) => state.workspace.ndaAttachments
  );
  const sellerNdaAttachments = useSelector(
    (state) => state.marketplace.sellerNdaAttachments
  );

  const onUploadSelectionChange = (event, attachments) => {
    if (isSeller) {
      if (
        sellerNdaAttachments &&
        sellerNdaAttachments.length === 0 &&
        attachments &&
        attachments.length > 0
      ) {
        dispatch(marketplaceActions.setSellerNdaAttachments(attachments));
      }
    } else {
      if (
        !isFinalSignedNda &&
        buyerNdaAttachments &&
        buyerNdaAttachments.length === 0 &&
        attachments &&
        attachments.length > 0
      ) {
        dispatch(workspaceActions.setBuyerNdaAttachments(attachments));
      }
    }
  };

  const handleClose = (type) => {
    // handler to change NDA stepper to next step on upload NDA modal save button click
    if (type === "save" && isSeller) {
      dispatch(marketplaceActions.setNDAStepperIndex(1));
    }

    // close upload NDA modal
    if (isSeller) {
      return setIsNDAUploadModalOpen ? setIsNDAUploadModalOpen(false) : null;
    } else {
      if (isFinalSignedNda) {
        dispatch(hideModal("isFinalSignedNDAModalOpen"));
        isFinalNDAUploadDone();
      } else {
        dispatch(hideModal("isBuyerNDAUploadModalOpen"));
      }
    }
  };

  const [modalState, setModalState] = useState({
    modalHeader: t("addNda"),
    modalBody: (
      <NdaUpload
        attachments={buyerNdaAttachments}
        onUploadSelectionChange={onUploadSelectionChange}
        isPurchaseOrder={true}
        isNDA={true}
        isFinalSignedNda={isFinalSignedNda}
        t={t}
        handleClose={handleClose}
        rfqPk={rfqPk}
      />
    ),
  });

  return (
    <>
      {
        <Modal
          handleModalClose={() => {
            setModalState({
              ...modalState,
              modalHeader: "",
              modalBody: "",
            });
            handleClose("close");
          }}
          modalState={modalState}
          isModalOpen={isModalOpen}
        />
      }
    </>
  );
};

export default BuyerNdaUploadModal;
