import React from "react";
import { GridRenderCellParams } from "@mui/x-data-grid-pro-v5";
import { CustomTypography } from "../custom-typography";

const NumberCell: React.FC<GridRenderCellParams> = (params: any) => {
  return (
    <CustomTypography
      tooltipTitle={params?.value?.toString() || ""}
      variant="body2"
    >
      {params?.value}
    </CustomTypography>
  );
};

export default NumberCell;
