import React, { useMemo } from "react";
import { Box, Dialog, DialogTitle, IconButton } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { getModal, modalsActions } from "@next/redux/modalsSlices";
import CloseIcon from "@mui/icons-material/Close";
import { DashboardModalTypes } from "../types";
import { ProjectOrderTable } from "@next/modules/project/components/order/project-order-table-v4";

import { selectProjectCompanyOrdersLoading } from "@next/modules/project/redux/selectors";
import { DashboardFetchType } from "./dashboard-rfqs-modal.types";
import { getQueryForDashboardFetchType } from "./dashboard-rfqs-modal.utils";
import { getTableColumnsForDashboardFetchType } from "./dashboard-rfqs-modal.columns";
import i18n from "assets/configi18n/i18n";

export const DashboardRfqsModal = () => {
  const dispatch = useDispatch();
  const modal = useSelector(getModal(DashboardModalTypes.DASHBOARD_RFQS_MODAL));
  const companyOrdersLoading = useSelector(selectProjectCompanyOrdersLoading);
  const data = modal?.data as { title: string; fetchType: DashboardFetchType };

  const orderTableColumns = useMemo(
    () => getTableColumnsForDashboardFetchType(modal?.data?.fetchType),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [modal?.data?.fetchType, companyOrdersLoading, i18n?.language]
  );

  if (!modal || !orderTableColumns) return null;

  const onClose = () => {
    dispatch(
      modalsActions.closeModal(DashboardModalTypes.DASHBOARD_RFQS_MODAL)
    );
  };

  return (
    <Dialog
      open={!!modal}
      onClose={onClose}
      closeAfterTransition
      fullWidth={true}
      maxWidth="lg"
    >
      <DialogTitle>
        <Box display="flex" alignItems="center">
          <Box flexGrow={1}> {data?.title}</Box>
          <Box>
            <IconButton onClick={onClose} size="large">
              <CloseIcon />
            </IconButton>
          </Box>
        </Box>
      </DialogTitle>

      <Box padding={"24px"}>
        <ProjectOrderTable
          query={getQueryForDashboardFetchType(data?.fetchType)}
          rfqDetailOpenVariant="new-tab"
          refetchDataAfterCancelRfq={true}
          dataGridProProps={{
            columns: orderTableColumns as any,
          }}
        />
      </Box>
    </Dialog>
  );
};
