import i18n from "i18next";
import { initReactI18next } from "react-i18next";

import globalEn from "../i18n/en.json";
import globalFr from "../i18n/fr.json";

import enCA from "../translations/translations-converted/en-CA.json";
import frCA from "../translations/translations-converted/fr-CA.json";

// TODO: Here add importing of language module by module with composition
// i18next goes beyond just providing the standard i18n features such as
// (interpolation, format)

// Here we will concatenate the module specific languages
const en = Object.assign(globalEn, enCA);
const fr = Object.assign(globalFr, frCA);

// Then we concatenate the namespaces
let nameSpace = [
  "BID",
  "BID_FORM",
  "BUNDLE",
  "CALLING_PLACES",
  "CHANGE_PASSWORD_FORM",
  "COMMING_SOON",
  "COMPANY",
  "CONTRACTS_VIEW",
  "CONTRACT_DETAILS",
  "EMAIL_RESET_PASSWORD_FORM",
  "ERRORS",
  "FOLLOW_UP",
  "HOME",
  "INVITE_MEMBER_FORM",
  "LANDING_FOOTER",
  "LANDING_NAVBAR",
  "LOAD_LANGUAGE",
  "MODAL",
  "PROFILE",
  "QUOTE",
  "QUOTE_DETAILS",
  "QUOTE_LIST",
  "RESEND_VERIFICATION_EMAIL",
  "RESET_PASSWORD_FORM",
  "RFQ",
  "SEARCH_BAR",
  "SIDE_BAR",
  "MARKETPLACE",
];

i18n.use(initReactI18next).init({
  // resources is the two files en.json and fr.json
  resources: {
    en,
    fr,
  },
  fallbackLng: "en",
  debug: true,
  //All the namesSpaces contained in the files en.json and fr.json
  ns: nameSpace,
  interpolation: {
    escapeValue: false,
    formatSeparator: ",",
  },
  react: {
    wait: true,
    transKeepBasicHtmlNodesFor: ["a", "br", "b", "ul", "li"],
  },
});

// en pluralization follows fr (0 & 1 => singular, 2+ => plural)
i18n.services.pluralResolver.addRule(
  "en",
  i18n.services.pluralResolver.getRule("fr")
);

export default i18n;

export const t = i18n.t.bind(i18n);
