import * as yup from "yup";
import { phoneRegExp, urlRegex } from "helpers/formRegex";
import { t } from "assets/configi18n/i18n";

export const validationSchema = yup.object().shape({
  name: yup.string().required(t("userCompany:theNameOfTheCompanyIsRequired")),
  address: yup.object().shape({
    street: yup.string().required(t("userCompany:theStreetIsRequired")),
    city: yup.string().required(t("userCompany:theCityIsRequired")),
    province: yup.string().required(t("userCompany:provinceIsRequired")),
    postal_code: yup.string().required(t("userCompany:postalCodeIsRequired")),
    country: yup.string().required(t("userCompany:countryIsRequired")),
  }),
  public_email: yup.string().email(t("auth:error:errorEmailInvalid")),
  phone_number: yup
    .string()
    .required(t("userCompany:thePhoneNumberIsRequired"))
    .matches(phoneRegExp, t("userCompany:pleaseEnterAValidPhoneNumber")),
  website: yup
    .string()
    .matches(
      urlRegex,
      t("userCompany:invalidWebsiteTheFormatShouldBeSimilarTo")
    )
    .required(t("userCompany:theWebsiteIsRequired")),
  shop_floor_area: yup
    .number()
    .min(0, t("common:validation:mustHaveMinValue", { value: 0 })),
  number_employee: yup
    .number()
    .nullable()
    .integer(t("common:validation:mustBeInteger"))
    .min(1, t("common:validation:mustHaveMinValue", { value: 1 })),
});
