import * as yup from "yup";

export enum FinalizeReviewOptions {
  ACCEPT_AND_REVISE = "accept_and_revise",
  SIMPLY_ACCEPT = "simply_accept",
}

export type OrderDetailFinalizeReviewFormState = {
  reviewOptions: FinalizeReviewOptions;
  note: string;
  file?: File | null;
};

export const orderDetailFinalizeReviewFormState: OrderDetailFinalizeReviewFormState =
  {
    reviewOptions: FinalizeReviewOptions.SIMPLY_ACCEPT,
    note: "",
    file: null,
  };

export const orderDetailFinalizeReviewFormValidationSchema = yup.object({
  note: yup.string(),
  file: yup.mixed().when("reviewOptions", {
    is: FinalizeReviewOptions.ACCEPT_AND_REVISE,
    then: yup.mixed().required("common:error:thisFieldIsRequired"),
  }),
});
