import { RootState } from "store";
import { Company } from "@next/modules/profile/redux";

// TODO: convert store to type safety store
export const getProfile = (state: RootState) => state.profile;
export const getProfilePreferences = (state: RootState) =>
  state.profile.preferences;
export const getToken = (state: RootState) => state.profile.token;
export const getRefreshToken = (state: RootState) => state.profile.refresh;
export const getCompany = (state: RootState) =>
  (state.profile.company as Company) || (state.profile.user.company as Company);
export const getUser = (state: RootState) => state.profile.user;
export const getUserLanguage = (state: RootState) =>
  state.profile.user?.language;
export const getUsername = (state: RootState) =>
  state.profile.user ? state.profile.user.username : "";
export const getIsLoading = (state: RootState) => state.profile.isLoading;
export const getIsNewUser = (state: RootState) =>
  state?.profile?.user ? state.profile.user?.is_getting_started : false;

export const selectCompayStats = (state: RootState) =>
  state.profile.user?.company_stats;

export const selectIsStaffUser = (state: RootState) =>
  !!state.profile.user?.is_staff;

export const selectCompanyUsers = (state: RootState) =>
  (state.profile.company as Company)?.users || [];

export const selectIsRegisterLoading = (state: RootState) =>
  state.profile.isRegisterLoading;

export const profileSelectors = {
  getToken,
  getRefreshToken,
  getCompany,
  getUser,
  getUserLanguage,
  getUsername,
  getIsLoading,
  selectIsRegisterLoading,
};
