// RF-TODO: convert store to type safety store
export const getActiveQuotationThread = (state: any) =>
  state.inbox.activeQuotationThread;
export const getMessagePage = (state: any) => state.inbox.messagesPage;
export const getMessages = (state: any) => state.inbox.messages;

export const inboxSelectors = {
  getActiveQuotationThread,
  getMessagePage,
  getMessages,
};
