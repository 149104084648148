import { IconButton, Tooltip } from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";
import NotificationsIcon from "@mui/icons-material/Notifications";
import BadgeWrapper from "@next/components/badge-wrapper/badge-wrapper";
import Popover from "@mui/material/Popover";
import { Notifications } from "./notifications/notifications";
import { getUser } from "services/profile/profile.selectors";
import { useSelector } from "react-redux";

export const NotificationsActionItem = () => {
  const { t } = useTranslation();
  const user = useSelector(getUser);
  const companyStats = user?.company_stats;
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(
    null
  );

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "notifications-popover" : undefined;

  return (
    <>
      <Tooltip title={t("appTour:notifications")}>
        <IconButton
          data-tut="reactour__notifications"
          data-pid="btnNotifications"
          onClick={handleClick}
          size="large"
        >
          <BadgeWrapper
            color="primary"
            badgeContent={companyStats?.new_qa_messages}
            right="-6%"
            top="15%"
          >
            <NotificationsIcon style={{ color: "white" }} />
          </BadgeWrapper>
        </IconButton>
      </Tooltip>

      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        sx={{ marginTop: "20px" }}
      >
        <Notifications />
      </Popover>
    </>
  );
};
