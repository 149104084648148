import React from "react";
import { ActionPanelBox } from "./order-detail-actions-panel.styled";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import { Box, Divider, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { ReviewOrderForm } from "../../review-order-form/review-order-form";
import { batch, useDispatch, useSelector } from "react-redux";
import { ReviewOrderFormState } from "../../review-order-form/review-order-form.definitions";
import { convertLineItemChangesToUpdatedLineItems } from "@next/modules/orders/redux/utils";
import {
  selectNotSavedChangesInLineItems,
  selectUpdatePortalOrderDeliveryDateLoading,
} from "@next/modules/orders/redux/selectors";
import {
  ActionPanelType,
  OrderLineItemChanges,
  ordersActions,
} from "@next/modules/orders/redux";
import { useQueryParam } from "@next/hooks/useQuery";
import { RightPanelTabs } from "../order-detail-right-tabs/order-detail-right-tabs";
import { useParams } from "react-router-dom";
import { OrderDetailFinalizeReviewForm } from "../order-detail-finalize-review-form/order-detail-finalize-review-form";

type Props = {
  isPortal?: boolean;
};

export const ActionsPanelProposeQtyAndPriceChange: React.FC<Props> = ({
  isPortal,
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const updateUpdatePortalOrderDeliveryDateLoading = useSelector(
    selectUpdatePortalOrderDeliveryDateLoading
  );
  const params = useParams<{ orderToken?: string; qaToken?: string }>();
  const orderToken = params?.orderToken;
  const [_rightPanelTab, setRightPanelTab] = useQueryParam("panelTab");
  const notSavedChangesInLineItems = useSelector(
    selectNotSavedChangesInLineItems(isPortal)
  );
  const onClose = () => {
    batch(() => {
      dispatch(ordersActions.resetUpdatedLineItems({ isPortal }));
      dispatch(ordersActions.setOrderActionPanelType(ActionPanelType.Default));
    });
  };

  const onClickSave = (values: ReviewOrderFormState) => {
    const updatedLineItems = convertLineItemChangesToUpdatedLineItems(
      notSavedChangesInLineItems
    );
    if (isPortal) {
      dispatch(
        ordersActions.updatePortalOrderLineItemsRequest({
          orderToken: orderToken,
          updated_line_items: updatedLineItems,
          update_note: values?.update_note,
          confirmation_file: values?.confirmation_file,
          onSuccess: () => {
            dispatch(
              ordersActions.setOrderActionPanelType(ActionPanelType.Default)
            );
            setRightPanelTab(RightPanelTabs.SUPPLIER_CHAT);
          },
        })
      );
    }
  };
  return (
    <div>
      <ActionPanelBox>
        <Box display="flex" alignItems="center" gap={2}>
          <EditOutlinedIcon />

          <Typography variant="h6">
            {t("orders:orderDetail:actionPanel:proposePriceQty:title")}
          </Typography>
        </Box>
      </ActionPanelBox>
      <Divider />
      <ActionPanelBox>
        <Typography variant="body1">
          {t("orders:orderDetail:actionPanel:proposePriceQty:description")}
        </Typography>
        <Box width={"100%"}>
          {isPortal ? (
            <ReviewOrderForm
              buttonText={t("common:submit")}
              onClose={onClose}
              onSubmit={onClickSave}
              previewLineItemChanges={true}
              loading={updateUpdatePortalOrderDeliveryDateLoading}
              isPortal={isPortal}
              notePlaceholder={t(
                "orders:orderDetail:actionPanel:proposePriceQty:noteLabel"
              )}
            />
          ) : (
            <OrderDetailFinalizeReviewForm
              onClose={onClose}
              lineItemsChanges={
                notSavedChangesInLineItems as OrderLineItemChanges[]
              }
              onSubmitted={() =>
                dispatch(
                  ordersActions.setOrderActionPanelType(ActionPanelType.Default)
                )
              }
              makeChangesFromBuyer={true}
            />
          )}
        </Box>
      </ActionPanelBox>
    </div>
  );
};
