import React, { useEffect, useMemo } from "react";
import { Box, Typography } from "@mui/material";
import {
  selectLineItemsTableSelectionModel,
  selectOrderActionPanelType,
  selectOrderByIsPortal,
  selectRevisionProposals,
} from "@next/modules/orders/redux/selectors";
import { useDispatch, useSelector } from "react-redux";
import { ActionsPanelDefaultView } from "./actions-panel-default-view";
import ActionsPanelFooter from "./actions-panel-footer";
import { ActionsPanelReviewChanges } from "./actions-panel-review-changes";
import { LoadingContainer } from "./order-detail-actions-panel.styled";
import { useTranslation } from "react-i18next";
import { ActionsPanelProposeQtyAndPriceChange } from "./actions-panel-propose-qty-price";
import { ActionsPanelModifyDeliveryDate } from "./actions-panel-modify-delivery-date";
import { ActionPanelType, ordersActions } from "@next/modules/orders/redux";

type Props = {
  isPortal?: boolean;
  loading?: boolean;
};

export const OrderDetailActionsPanel: React.FC<Props> = ({
  isPortal,
  loading,
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const orderDetail = useSelector(selectOrderByIsPortal(isPortal));
  const selectedLineItems = useSelector(selectLineItemsTableSelectionModel);
  const revisionProposal = useSelector(selectRevisionProposals);
  const panelType = useSelector(selectOrderActionPanelType);

  const itemsToUpdate = useMemo(() => {
    return orderDetail?.line_items.filter((item) =>
      selectedLineItems.includes(item.id)
    );
  }, [orderDetail, selectedLineItems]);

  useEffect(() => {
    if (revisionProposal?.length > 0) {
      dispatch(ordersActions.setOrderActionPanelType(ActionPanelType.Review));
      return;
    } else if (panelType === ActionPanelType.Review) {
      dispatch(ordersActions.setOrderActionPanelType(ActionPanelType.Default));
    }
  }, [revisionProposal]);

  const renderPanel = () => {
    switch (panelType) {
      case ActionPanelType.ProposeQtyAndPriceChange:
        return <ActionsPanelProposeQtyAndPriceChange isPortal={isPortal} />;
      case ActionPanelType.ModifyDeliveryDate:
        return (
          <ActionsPanelModifyDeliveryDate
            isPortal={isPortal}
            selectedItemsToUpdate={itemsToUpdate}
          />
        );
      case ActionPanelType.Review:
        return <ActionsPanelReviewChanges isPortal={isPortal} />;
      case ActionPanelType.Default:
      default:
        return <ActionsPanelDefaultView isPortal={isPortal} />;
    }
  };

  if (loading) {
    return (
      <LoadingContainer>
        <Typography variant="h5">{t("common:pleaseWait")}</Typography>
      </LoadingContainer>
    );
  }

  return (
    <Box width={"100%"} display={"flex"} flexDirection={"column"}>
      <Box sx={{ height: "100%", overflowY: "scroll" }}>{renderPanel()}</Box>
      <ActionsPanelFooter />
    </Box>
  );
};
