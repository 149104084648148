import React, { useRef } from "react";

import { TagDialog } from "../../components/tag-dialog/tag-dialog.component";
import { OneTag } from "./one-tag/one-tag.component";
import { ListOfTags } from "./list-of-tags/list-of-tags.component";
import { NoTag } from "./no-tag/no-tag.component";

const AddTags = ({
  onclickCloseTagDialog,
  onClickOpenTagDialog,
  partPk,
  t,
  open,
  tags,
  setTagsHeight,
}) => {
  const tagsList = useRef();
  // getting tags container height
  if (tagsList && tagsList.current) {
    const tagsHeight = tagsList.current.getBoundingClientRect().height;
    setTagsHeight(tagsHeight);
  }

  return (
    <div ref={tagsList}>
      {tags && tags.length === 0 && (
        <NoTag onClickOpenTagDialog={onClickOpenTagDialog} t={t} />
      )}
      {tags && tags.length === 1 && (
        <OneTag tags={tags} onClickOpenTagDialog={onClickOpenTagDialog} t={t} />
      )}
      {tags && tags.length > 1 && (
        <ListOfTags
          tags={tags}
          onClickOpenTagDialog={onClickOpenTagDialog}
          t={t}
        />
      )}
      <TagDialog
        open={open}
        partPk={partPk}
        partTags={tags}
        onclickCloseTagDialog={onclickCloseTagDialog}
        t={t}
      />
    </div>
  );
};

export default AddTags;
