import React from "react";
import {
  Chip,
  IconButton,
  ListItem,
  ListItemAvatar,
  ListItemSecondaryAction,
  ListItemText,
  Theme,
} from "@mui/material";
import ClearIcon from "@mui/icons-material/Clear";
import { PartUpload } from "@next/modules/project/redux";
import { CustomTypography } from "@next/components/custom-typography";
import { createStyles, makeStyles } from "@mui/styles";
import { CircularProgressWithLabel } from "@next/components/circular-progress";
import { formatFileSize } from "@next/utils/fileUtils";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import { downloadFile } from "helpers/download";

const useStyles = makeStyles<Theme>((theme) =>
  createStyles({
    button: {
      borderRadius: "8px",
      borderColor: theme.palette.divider,
    },
    avatar: {
      textAlign: "center",
    },
    text: {
      paddingLeft: "14px",
    },
    fileSize: {
      color: theme.palette.text.secondary,
      marginTop: 5,
      marginLeft: 8,
      display: "inline-block",
      lineHeight: "unset",
    },
    fileName: {
      display: "inline-block",
      maxWidth: "60%",
      whiteSpace: "nowrap",
    },
    uploading: {
      display: "inline-block",
      maxWidth: "50%",
      whiteSpace: "nowrap",
    },
    actionButton: {
      color: theme.palette.common.black,
    },
  })
);

type Props = {
  file: PartUpload;
  selected?: boolean;
  onClick: (file: PartUpload) => void;
  onDelete: (file: PartUpload) => void;
  readOnly?: boolean;
};

export const FileListItem: React.FC<Props> = ({
  file,
  selected,
  onClick,
  onDelete,
  readOnly,
}) => {
  const classes = useStyles();

  return (
    <ListItem
      button
      selected={selected}
      onClick={() => onClick(file)}
      classes={{ button: classes.button }}
    >
      <ListItemAvatar className={classes.avatar}>
        <Chip label={file.file_extension?.toUpperCase()} />
      </ListItemAvatar>
      <ListItemText
        primary={
          <>
            <CustomTypography
              className={
                file?.file_uploaded ? classes.uploading : classes.fileName
              }
              variant="body2"
            >
              {file.file_name}
            </CustomTypography>
            <CustomTypography className={classes.fileSize}>
              {file?.file_size ? formatFileSize(file?.file_size, 2) : null}
            </CustomTypography>
          </>
        }
        className={classes.text}
      />
      {file?.file_uploaded && (
        <CircularProgressWithLabel progress={file.file_uploaded} />
      )}
      {file.file_url && (
        <IconButton>
          <ArrowDownwardIcon
            className={classes.actionButton}
            onClick={(e) => downloadFile(e, file.file_url)}
          />
        </IconButton>
      )}
      {readOnly ? null : (
        <ListItemSecondaryAction onClick={() => onDelete(file)}>
          <IconButton
            className={classes.actionButton}
            edge="end"
            aria-label="delete"
            size="large"
          >
            <ClearIcon />
          </IconButton>
        </ListItemSecondaryAction>
      )}
    </ListItem>
  );
};
