import { Box, Button, Typography } from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";
import PersonAddRoundedIcon from "@mui/icons-material/PersonAddRounded";

interface Props {
  searchTerm: string;
  onClickAddSupplier: () => void;
}

const MySuppliersTableEmpty: React.FC<Props> = ({
  searchTerm,
  onClickAddSupplier,
}) => {
  const { t } = useTranslation();

  return (
    <Box
      mt={10}
      width="100%"
      display="flex"
      flexDirection="column"
      alignItems="center"
    >
      <Typography variant="body1">
        {t("workspaceNext:supplierTable:empty:search", {
          searchTerm,
        })}
      </Typography>
      <Box display="flex" justifyContent="center" gap="8px" mt={3}>
        <Button
          variant="contained"
          color="primary"
          onClick={onClickAddSupplier}
          startIcon={<PersonAddRoundedIcon />}
        >
          {t("workspaceNext:supplierTable:empty:addSupplier")}
        </Button>
      </Box>
    </Box>
  );
};

export default MySuppliersTableEmpty;
