import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { Box } from "@mui/material";
import { modalsActions } from "@next/redux/modalsSlices";
import { AirbusSpecificationTable } from "../../../table/airbus-specification-table/airbus-specification-table";
import { FormAddButton } from "../../../form-add-button/form-add-button";
import { FormFieldErrorTypography } from "../../../form-field-error-typography/form-field-error-typography";
import { AddSpecListModal } from "../../../modals/add-spec-list-modal/add-spec-list-modal";
import { TableLinesBox } from "../../../table-lines-box/table-lines-box";
import {
  FieldMandatoryText,
  PaginationSizeForFormTable,
  PartialStep,
  SpecificationTableRow,
  VendorManagementModalTypes,
  vendorManagementActions,
} from "@next/modules/vendor-management/redux";
import {
  selectFormData,
  selectIsFormCompleted,
  selectSpecsOfScope,
  selectSpecsOfScopeLoading,
  selectVisibleSteps,
} from "@next/modules/vendor-management/redux/selectors";
import { getActiveStepIndex } from "@next/modules/vendor-management/utils/getActiveStepIndex";
import * as S from "./add-airbus-specification-field.styled";
import { InfoTitle } from "../../../form-field-label/form-field-label";

type Props = {
  isShowTableErrorsIfExist: boolean;
};

export const AddAirbusSpecificationField: React.FC<Props> = ({
  isShowTableErrorsIfExist,
}) => {
  const dispatch = useDispatch();
  const { companyId } = useParams<{ companyId: string }>();
  const formData = useSelector(selectFormData);
  const specTableData = useSelector(selectSpecsOfScope);
  const specTableRows = specTableData?.results?.data || [];
  const [page, setPage] = useState(1);
  const specTableRowsLoading = useSelector(selectSpecsOfScopeLoading);
  const steps = useSelector(selectVisibleSteps);
  const activeFormStepIndex = getActiveStepIndex(steps);
  const step = steps[activeFormStepIndex];
  const isFormCompleted = useSelector(selectIsFormCompleted);
  const [hideCompletedTableLines, setHideCompletedTableLines] = useState(false);
  const specTypesForTableSpecs = ["A2ERD", "A2TS"];

  useEffect(() => {
    if (formData) {
      dispatch(
        vendorManagementActions.fetchSpecsOfScopeRequest({
          companyId: companyId,
          specType: specTypesForTableSpecs,
          pagination: { page: page, pageSize: PaginationSizeForFormTable },
          stepId: step.step,
          filter_completed: hideCompletedTableLines,
        })
      );
    }
  }, [page, hideCompletedTableLines]);

  const handleAddSpecToScope = (row: SpecificationTableRow) => {
    dispatch(
      vendorManagementActions.addSpecToScopeRequest({
        companyId: companyId,
        row: row,
        onSuccess: onAddSpecSuccess,
      })
    );
  };

  const onAddSpecSuccess = () => {
    reFetchTableDataToGetUpdatedCount();
    checkInputChange();
  };

  const reFetchTableDataToGetUpdatedCount = () => {
    dispatch(
      vendorManagementActions.fetchSpecsOfScopeRequest({
        companyId: companyId,
        specType: specTypesForTableSpecs,
        pagination: { page: page, pageSize: PaginationSizeForFormTable },
        stepId: step.step,
        filter_completed: hideCompletedTableLines,
        isForFetchingToGetCountData: true,
      })
    );
  };

  const checkInputChange = () => {
    if (isFormCompleted) {
      updateFieldStatusOfStepForSaveAndCompletion("is_completed", false);
    } else {
      updateFieldStatusOfStepForSaveAndCompletion("is_partially_filled", true);
    }
  };

  const updateFieldStatusOfStepForSaveAndCompletion = (
    key: "is_completed" | "is_partially_filled",
    value: boolean
  ) => {
    const step = steps[activeFormStepIndex];
    const partialStep: PartialStep = {
      [key]: value,
    };
    dispatch(
      vendorManagementActions.updateStepRequest({
        stepId: step.id,
        data: partialStep,
      })
    );
  };

  const onClickOpenAddSpecListModal = () => {
    dispatch(
      modalsActions.showModal({
        key: VendorManagementModalTypes.ADD_SPEC_LIST_MODAL,
      })
    );
  };

  return (
    <>
      <S.StyledBox>
        <Box>
          <S.StyledBox>
            <TableLinesBox
              completedCount={specTableData?.results?.completed_count}
              totalCount={specTableData?.results?.total_count}
              hideCompletedTableLines={hideCompletedTableLines}
              setHideCompletedTableLines={setHideCompletedTableLines}
              setPage={setPage}
            />
            <InfoTitle />
            <AirbusSpecificationTable
              hideCompletedTableLines={hideCompletedTableLines}
              page={page}
              setPage={setPage}
              pageSize={PaginationSizeForFormTable}
            />
          </S.StyledBox>

          <S.StyledBtnBox>
            <FormAddButton
              btnText={"Add Airbus specification"}
              onClick={onClickOpenAddSpecListModal}
              disabled={specTableRowsLoading}
            />
          </S.StyledBtnBox>
        </Box>

        {isShowTableErrorsIfExist && specTableRows.length === 0 ? (
          <FormFieldErrorTypography text={FieldMandatoryText} />
        ) : null}
      </S.StyledBox>

      <AddSpecListModal
        rowsAlreadyAdded={specTableRows}
        handleAddSpecToFormTable={handleAddSpecToScope}
        title={"Spec list"}
      />
    </>
  );
};
