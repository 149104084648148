import { store } from "store";
import moment from "moment";
import { EN_DATE_FORMAT, FR_DATE_FORMAT, TIME_FORMAT } from "@next/constants";

import { t } from "assets/configi18n/i18n";

// Use this function to change date depend of user language
export function formatDate(date, customFormat) {
  let momentDate = moment.utc(date);

  if (!momentDate.isValid()) {
    return "";
  }
  if (customFormat) {
    return momentDate.format(customFormat);
  }
  const language = store.getState().profile?.user?.language;
  if (language === "en") {
    return momentDate.format(EN_DATE_FORMAT);
  } else {
    return momentDate.format(FR_DATE_FORMAT);
  }
}

export function formatDateAndTime(date, customDateFormat, customTimeFormat) {
  const language = store.getState().profile?.user?.language;
  if (language === "en") {
    return moment(date).format(
      `${customDateFormat || EN_DATE_FORMAT} ${customTimeFormat || TIME_FORMAT}`
    );
  } else {
    return moment(date).format(
      `${customDateFormat || FR_DATE_FORMAT} ${customTimeFormat || TIME_FORMAT}`
    );
  }
}

export const getRfqDeadlineLabel = (date) => {
  const language = store.getState().profile?.user?.language;
  const deadline = formatDate(date);
  const today = moment().format(
    language === "en" ? `${EN_DATE_FORMAT}` : `${FR_DATE_FORMAT}`
  );
  return deadline < today
    ? `${t("workspace:endedOn", {
        deadline,
      })} `
    : `${t("workspace:endOn", {
        deadline,
      })} `;
};
