//this component is used to show application tour

// general imports
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTracking } from "react-tracking";

import { useTranslation } from "react-i18next";

import { disableBodyScroll, enableBodyScroll } from "body-scroll-lock";
import Tour from "reactour";
import { modalsActions } from "../../../services/modals/modals.actions";
import { AppTheme } from "layout/AppTheme";

const RfqViewTour = () => {
  const { t } = useTranslation("rfqViewTour");
  const tourConfig = [
    {
      selector: '[data-tut="reactour__information"]',
      content: () => (
        <div>
          <h3>{t("information")}</h3>
          <p>{t("informationDescription")}</p>
        </div>
      ),
    },
    {
      selector: '[data-tut="reactour__QA"]',
      content: () => (
        <div>
          <h3>{t("questionAndAnswer")}</h3>
          <p>{t("questionAndAnswerDescription")}</p>
        </div>
      ),
    },
    {
      selector: '[data-tut="reactour__quotation"]',
      content: () => (
        <div>
          <h3>{t("quotations")}</h3>
          <p>{t("quotationDescription")}</p>
          <CloseBtn />
        </div>
      ),
    },
  ];

  const CloseBtn = () => {
    const dispatch = useDispatch();
    const tracking = useTracking();
    const profile = useSelector((state) => state.profile);
    const tourDoneClick = () => {
      dispatch(modalsActions.hideModal("isRfqViewTourOpen"));
      // tracking close rfq view tour click
      tracking.trackEvent({
        scout_category: "tour_guide",
        scout_feature_name: "tour_done",
        scout_company: profile["company"]["name"],
        scout_username: profile["user"]["name"],
        scout_user_type: profile["user"]["type"],
        scout_date: new Date(),
      });
    };
    return (
      <button class="c-tour--done-btn" onClick={() => tourDoneClick()}>
        {t("gotItButton")}
      </button>
    );
  };
  const dispatch = useDispatch();
  const tracking = useTracking();
  const isRfqViewTourOpen = useSelector(
    (state) => state.modals.isRfqViewTourOpen
  );
  const profile = useSelector((state) => state.profile);
  const closeTour = () => {
    dispatch(modalsActions.hideModal("isRfqViewTourOpen"));
    // tracking close rfq view tour click
    tracking.trackEvent({
      scout_category: "tour_guide",
      scout_feature_name: "close_rfq_view_tour",
      scout_company: profile["company"]["name"],
      scout_username: profile["user"]["name"],
      scout_user_type: profile["user"]["type"],
      scout_date: new Date(),
    });
  };

  const disableBody = (target) => disableBodyScroll(target);
  const enableBody = (target) => enableBodyScroll(target);
  const accentColor = AppTheme.palette.primary.main;
  const trackingApi = (step) => {
    tracking.trackEvent({
      scout_category: "tour_guide",
      scout_feature_name: "ongoing_rfq_view_tour",
      scout_company: profile["company"]["name"],
      scout_username: profile["user"]["name"],
      scout_user_type: profile["user"]["type"],
      scout_date: new Date(),
    });
  };
  return (
    <section className="c-app-tour">
      <Tour
        onRequestClose={closeTour}
        steps={tourConfig}
        isOpen={isRfqViewTourOpen}
        maskClassName="mask"
        className="helper"
        rounded={5}
        accentColor={accentColor}
        onAfterOpen={disableBody}
        onBeforeClose={enableBody}
        getCurrentStep={(step) => trackingApi(step)}
      />
    </section>
  );
};

export default RfqViewTour;
