import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getModal, modalsActions } from "@next/redux/modalsSlices";
import { ProfileModalTypes } from "./types";
import { useTranslation } from "react-i18next";
import { UserProfileSettings } from "../components/user-profile/user-profile-settings/user-profile-settings";
import Modal from "@next/components/custom-modal";
import { workspaceNextActions } from "@next/modules/workspace/redux";

export const UserProfileSettingsModal: React.FC<{
  initiallyOpen?: boolean;
}> = ({ initiallyOpen }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const modal = useSelector(
    getModal(ProfileModalTypes.USER_PROFILE_SETTINGS_MODAL)
  );

  useEffect(() => {
    if (initiallyOpen && !modal) {
      dispatch(
        modalsActions.showModal({
          key: ProfileModalTypes.USER_PROFILE_SETTINGS_MODAL,
        })
      );
    }
  }, [initiallyOpen]);

  if (!modal) return null;

  const onClose = () => {
    dispatch(workspaceNextActions.resetOTT());
    dispatch(
      modalsActions.closeModal(ProfileModalTypes.USER_PROFILE_SETTINGS_MODAL)
    );
  };

  return (
    <Modal
      customWidth="650px"
      handleModalClose={onClose}
      modalState={{
        modalHeader: t("profile:menu:profileSettings"),
        modalBody: <UserProfileSettings />,
      }}
      isModalOpen={true}
      dialogProps={{ fullWidth: true }}
      disableContentPadding={true}
      dividers={false}
    />
  );
};
