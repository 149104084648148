import { Box, Grid, Theme } from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";
import InsertDriveFileIcon from "@mui/icons-material/InsertDriveFile";
import PersonAddRoundedIcon from "@mui/icons-material/PersonAddRounded";
import AssignmentTurnedInRoundedIcon from "@mui/icons-material/AssignmentTurnedInRounded";
import StarRoundedIcon from "@mui/icons-material/StarRounded";
import { frontendUrl } from "urls";
import { batch, useDispatch, useSelector } from "react-redux";
import { getUser } from "services/profile/profile.selectors";
import { Link } from "react-router-dom";
import { useAxyaTracking } from "@next/hooks/useAxyaTracking";
import { DashboardRfqsModal } from "../modals/dashboard-rfqs-modal/dashboard-rfqs-modal";
import { modalsActions } from "@next/redux/modalsSlices";
import { ActionCard } from "@next/components/action-card/action-card";
import { DashboardModalTypes } from "../modals/types";
import { DashboardFetchType } from "../modals/dashboard-rfqs-modal/dashboard-rfqs-modal.types";
import { DashboardCard } from "../components/buyer-dashboard-card/buyer-dashboard-card";
import { DashboardInformation } from "../components/dashboard-information/dashboard-information";
import FreeBreakfastOutlinedIcon from "@mui/icons-material/FreeBreakfastOutlined";
import FlashOnRoundedIcon from "@mui/icons-material/FlashOnRounded";
import SearchRoundedIcon from "@mui/icons-material/SearchRounded";
import ShowChartOutlinedIcon from "@mui/icons-material/ShowChartOutlined";
import { AppTheme } from "layout/AppTheme";
import LanguageIcon from "@mui/icons-material/Language";
import { ProfileModalTypes } from "@next/modules/profile/modals/types";
import { DiscoveryModal } from "@next/modules/profile/modals/user-profile-discovery-modal";
import { profileNextActions } from "@next/modules/profile/redux";
import { createStyles, makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {},
    paper: {
      padding: theme.spacing(2),
      textAlign: "center",
      color: theme.palette.text.secondary,
    },
    cards: {
      marginTop: theme.spacing(1.5),
      display: "flex",
      justifyContent: "center",
      maxWidth: "1900px",
      margin: "0 auto",
    },
    cardBottom: {
      marginTop: "96px",
    },
    date: {
      display: "flex",
    },
  })
);

export const BuyerDashboard = () => {
  const axyaTrack = useAxyaTracking();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const classes = useStyles();
  const user = useSelector(getUser);
  const companyStats = user?.company_stats;

  const handleOnClickAnalytic = (featureName: string) => () => {
    axyaTrack({
      scout_category: "dashboard",
      scout_feature_name: featureName,
    });
  };

  const handleOnClickCard =
    (fetchType: DashboardFetchType, modalTitle: string) => () => {
      axyaTrack({
        scout_category: "dashboard",
        scout_feature_name: fetchType.toString(),
      });

      dispatch(
        modalsActions.showModal({
          key: DashboardModalTypes.DASHBOARD_RFQS_MODAL,
          data: {
            title: modalTitle,
            fetchType,
          },
        })
      );
    };

  const onAxyaNetworkClick = () => {
    window.location.href = `/suppliers_directory`;
  };

  const onGetRecommendationClick = () => {
    const onClose = () => {
      batch(() => {
        dispatch(
          modalsActions.closeModal(
            ProfileModalTypes.USER_PROFILE_SEND_DISCOVERY_DRAWER
          )
        );
      });
    };

    const onSubmit = (values: any) => {
      dispatch(
        profileNextActions.sendDiscoveryRequest({
          type: "connection",
          files: values.files?.map((item: any) => item?.file),
          message: values.message as any,
        })
      );
    };

    dispatch(
      modalsActions.showModal({
        key: ProfileModalTypes.USER_PROFILE_SEND_DISCOVERY_DRAWER,
        data: {
          onSubmit: onSubmit,
          onClose: onClose,
          dialogTitle: t("discovery:recommendation:dialogHeader"),
          descriptionTitle: t("discovery:recommendation:dialogTitle"),
          descriptionText: t("discovery:recommendation:dialogDescription"),
          messageInputLabel: t("discovery:recommendation:dialogInputLabel"),
          descriptionIcon: FlashOnRoundedIcon,
          sendButtonText: t("common:send"),
        },
      })
    );
  };

  return (
    <div className={classes.root}>
      <DashboardInformation
        subTitle={t("dashboard:subTitle", { userName: user?.first_name })}
        Icon={FreeBreakfastOutlinedIcon}
        showDate={true}
      />

      <Grid container spacing={2} className={classes.cards}>
        <Grid
          item
          onClick={handleOnClickCard(
            DashboardFetchType.ACTIVE_RFQS,
            t("dashboard:openRfqs", {
              count: companyStats?.active_rfqs,
            })
          )}
        >
          <DashboardCard
            title={t("dashboard:openRfqs", {
              count: companyStats?.active_rfqs,
            })}
            count={companyStats?.active_rfqs}
            Icon={InsertDriveFileIcon}
          />
        </Grid>

        <Grid
          item
          onClick={handleOnClickCard(
            DashboardFetchType.WAITING_RFQS,
            t("dashboard:rfqsReadyForDecision", {
              count: companyStats?.waiting_rfqs,
            })
          )}
        >
          <DashboardCard
            title={t("dashboard:rfqsReadyForDecision", {
              count: companyStats?.waiting_rfqs,
            })}
            count={companyStats?.waiting_rfqs}
            Icon={AssignmentTurnedInRoundedIcon}
            iconColor={AppTheme.palette.success.dark}
          />
        </Grid>

        {/* <Grid
          item
          onClick={handleOnClickCard(
            DashboardFetchType.PO_PENDING_CONTRACTS,
            t("dashboard:contractWaitingForPO", {
              count: companyStats?.po_pending_contracts,
            })
          )}
        >
          <DashboardCard
            title={t("dashboard:contractWaitingForPO", {
              count: companyStats?.po_pending_contracts,
            })}
            count={companyStats?.po_pending_contracts}
            Icon={WorkIcon}
          />
        </Grid> */}

        <Grid
          item
          onClick={handleOnClickCard(
            DashboardFetchType.WAITING_RATE,
            t("dashboard:contractWaitingForARating", {
              count: companyStats?.rate_pending_contracts,
            })
          )}
        >
          <DashboardCard
            title={t("dashboard:contractWaitingForARating", {
              count: companyStats?.rate_pending_contracts,
            })}
            count={companyStats?.rate_pending_contracts}
            Icon={StarRoundedIcon}
            iconColor={AppTheme.palette.warning.main}
          />
        </Grid>

        <Grid item>
          <Link
            to={frontendUrl.mySuppliers}
            onClick={handleOnClickAnalytic("favorite_suppliers")}
          >
            <DashboardCard
              title={t("dashboard:suppliersInYourSupplierList")}
              count={companyStats?.favorite_suppliers}
              Icon={PersonAddRoundedIcon}
              chipCount={companyStats?.pending_connections}
            />
          </Link>
        </Grid>
      </Grid>
      <Box mt="96px">
        <DashboardInformation
          subTitle={t("dashboard:subTitleStats", {
            companyName: user?.company?.name,
          })}
          Icon={ShowChartOutlinedIcon}
        />
      </Box>
      <Grid container spacing={2} className={classes.cards}>
        <Grid item>
          <DashboardCard
            title={t("companyStats:activeRfqs", {
              count: companyStats?.active_rfqs || 0,
            })}
            count={companyStats?.active_rfqs || 0}
            compactMode={true}
          />
        </Grid>

        <Grid item>
          <DashboardCard
            title={t("companyStats:canceledRfqs", {
              count: companyStats?.canceled_rfqs || 0,
            })}
            count={companyStats?.canceled_rfqs || 0}
            compactMode={true}
          />
        </Grid>

        <Grid item>
          <DashboardCard
            title={t("companyStats:contractsAwarded", {
              count: companyStats?.contracts_awarded || 0,
            })}
            count={companyStats?.contracts_awarded || 0}
            compactMode={true}
          />
        </Grid>

        <Grid item>
          <DashboardCard
            title={t("companyStats:totalContractedValue", {
              count: companyStats?.total_contracted_value || 0,
            })}
            count={companyStats?.total_contracted_value || 0}
            compactMode={true}
            isCurrency={true}
          />
        </Grid>

        <Grid item>
          <DashboardCard
            title={t("companyStats:avgDecisionTime", {
              count: companyStats?.avg_decision_time || 0,
            })}
            count={companyStats?.avg_decision_time || 0}
            compactMode={true}
            tooltip={t("companyStats:avgDecisionTimeTooltip")}
          />
        </Grid>
      </Grid>
      <Box mt="96px">
        <DashboardInformation
          subTitle={t("discovery:buyerTitle")}
          Icon={LanguageIcon}
        />
      </Box>
      <Box justifyContent="center">
        <Grid container spacing={2} className={classes.cards}>
          <Grid item xs={12} sm={12} md={6}>
            <ActionCard
              Icon={FlashOnRoundedIcon}
              title={t("discovery:recommendation:cardTitle")}
              btnText={t("discovery:recommendation:cardButton")}
              onClick={onGetRecommendationClick}
              btnTestId="btnGetMyRecommendation"
            />
          </Grid>
          <Grid item xs={12} sm={12} md={6}>
            <ActionCard
              Icon={SearchRoundedIcon}
              title={t("discovery:network:cardTitle")}
              btnText={t("discovery:network:cardButton")}
              onClick={onAxyaNetworkClick}
              btnTestId="btnSearchTheDirectory"
            />
          </Grid>
        </Grid>
      </Box>
      <DashboardRfqsModal />
      <DiscoveryModal />
    </div>
  );
};
