import React from "react";

// material UI imports
import { Button, Grid } from "@mui/material";

// component file imports
import PartAttachments from "../part-dialog/attachments/attachments.component";

export const NdaUpload = ({
  isNDA,
  attachments,
  onUploadSelectionChange,
  t,
  handleClose,
  isPurchaseOrder,
  isFinalSignedNda,
  rfqPk,
}) => {
  const [currentAttachment, setCurrentAttachment] = React.useState([]);

  const handleAttachments = (prevAttachment, currentAttachment) => {
    setCurrentAttachment(currentAttachment);
    onUploadSelectionChange(prevAttachment, currentAttachment);
  };

  return (
    <React.Fragment>
      <Grid item xs={12} className="c-add-more-po-files">
        <Grid
          container
          direction="column"
          justifyContent="center"
          alignItems="center"
        >
          <Grid
            item
            xs={12}
            className={
              currentAttachment && currentAttachment.length === 0
                ? "c-add-more-po-files--uploadSvg"
                : "c-add-more-po-files--attachments"
            }
          >
            <PartAttachments
              attachments={attachments}
              onAttachmentSelectionChange={(
                prevAttachment,
                currentAttachment
              ) => handleAttachments(prevAttachment, currentAttachment)}
              isPurchaseOrder={isPurchaseOrder}
              isNDA={isNDA}
              isFinalSignedNda={isFinalSignedNda}
              rfqPk={rfqPk}
              height={"50px"}
            />
          </Grid>
          <Grid item xs={12} className="c-buyer-nda-upload--btn">
            <Grid
              container
              direction="row"
              justifyContent="flex-end"
              alignItems="flex-end"
            >
              {currentAttachment && currentAttachment.length > 0 && (
                <Button
                  size="large"
                  color={"primary"}
                  variant="contained"
                  onClick={() => handleClose("save")}
                >
                  {t("save")}
                </Button>
              )}
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </React.Fragment>
  );
};
