import { Button, Divider, Theme, Typography } from "@mui/material";
import React, { useEffect, useMemo, useState } from "react";
import { projectActions } from "../../redux";
import { CreateMultiContractSummary } from "./create-multi-contract-summary";
import { CreateMultiContractTable } from "./create-multi-contract-table";
import { CreateMultiContractData } from "./create-multi-contract-table.types";
import GavelIcon from "@mui/icons-material/Gavel";
import { useTranslation } from "react-i18next";
import {
  getGroupedMultiContractData,
  getSuppliersOptionsFromQuotations,
  normalizeDataForApi,
} from "./create-multi-contract.utils";
import { useDispatch, useSelector } from "react-redux";
import {
  selectCreateMultiContractRFQData,
  selectCreateMultiContractRFQLoading,
  selectExportMultiContractRFQExcelLoading,
  selectImportMultiContractRFQExcelLoading,
  selectImportMultiContractRFQExcelReport,
} from "../../redux/selectors";
import { modalsActions } from "@next/redux/modalsSlices";
import {
  CreateMultiContractModalData,
  ProjectModalTypes,
} from "../../modals/types";
import CustomButton from "@next/components/custom-button";
import { CreateMultiContractExcelImport } from "./create-multi-contract-excel-import";
import { createStyles, makeStyles } from "@mui/styles";

const useStyles = makeStyles<
  Theme,
  {
    isSummary: boolean;
  }
>((theme) =>
  createStyles({
    stepText: { paddingRight: theme.spacing(2) },
    footer: {
      display: "flex",
      alignItems: "center",
      justifyContent: "flex-end",
      padding: theme.spacing(2),
    },
    content: {
      height: "100%",
    },
    table: {
      display: ({ isSummary }) => (isSummary ? "none" : "block"),
      paddingLeft: theme.spacing(3),
      paddingRight: theme.spacing(3),
    },
    summary: {
      display: ({ isSummary }) => (isSummary ? "block" : "none"),
    },
    validateButton: {
      marginLeft: theme.spacing(2),
    },
  })
);

export const CreateMultiContract: React.FC<CreateMultiContractModalData> = ({
  rfq,
  onSuccess,
  onClose,
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const createMultiContractRFQLoading = useSelector(
    selectCreateMultiContractRFQLoading
  );
  const exportMultiContractRFQExcelLoading = useSelector(
    selectExportMultiContractRFQExcelLoading
  );
  const importMultiContractRFQExcelLoading = useSelector(
    selectImportMultiContractRFQExcelLoading
  );
  const importMultiContractRFQExcelReport = useSelector(
    selectImportMultiContractRFQExcelReport
  );
  const createMultiContractRFQData =
    useSelector(selectCreateMultiContractRFQData) || [];

  const isExcelImportReportAvailable =
    importMultiContractRFQExcelReport?.payload?.length > 0 ||
    importMultiContractRFQExcelReport?.errors?.length > 0;

  const [isSummary, setIsSummary] = useState<boolean>(false);

  const classes = useStyles({ isSummary });

  const [multiContractData, setMultiContractData] = useState<
    CreateMultiContractData[]
  >([]);

  const selectedMultiContractData = useMemo(
    () => getGroupedMultiContractData(multiContractData),
    [multiContractData]
  );
  const groupedMultiContractData = isExcelImportReportAvailable
    ? importMultiContractRFQExcelReport?.payload
    : selectedMultiContractData;

  const suppliersOptions = useMemo(
    () => getSuppliersOptionsFromQuotations(rfq?.quotations),
    [rfq?.quotations, isExcelImportReportAvailable]
  );

  // only enable excel import if there are more than 30 parts
  const onlyExcelImport = (rfq?.parts?.length ?? 0) > 30;

  useEffect(() => {
    // if there is an import report, show it
    if (isExcelImportReportAvailable) {
      setIsSummary(true);
    }
  }, [isExcelImportReportAvailable]);

  const onClickValidateSelection = () => {
    const normalizedData = normalizeDataForApi(groupedMultiContractData);

    dispatch(
      normalizedData.length === 0
        ? modalsActions.closeModal(
            ProjectModalTypes.CREATE_MULTI_CONTRACT_MODAL
          )
        : projectActions.createMultiContractRFQRequest({
            rfq_pk: rfq?.pk,
            contractData: normalizedData,
            onSuccess: isExcelImportReportAvailable ? undefined : onSuccess,
          })
    );
  };

  const onClickBackNextButton = () => {
    if (isSummary) {
      // reset report
      dispatch(projectActions.resetImportMultiContractRFQExcelReport());
    }

    setIsSummary(!isSummary);
  };

  return (
    <div>
      <div className={classes.content}>
        {!onlyExcelImport ? (
          <div className={classes.table}>
            <CreateMultiContractTable
              parts={rfq?.parts}
              suppliersOptions={suppliersOptions}
              onChange={setMultiContractData}
              filterSuppliers={rfq?.is_no_quote_detected !== false}
            />

            <Divider />
          </div>
        ) : null}

        {!isSummary ? (
          <CreateMultiContractExcelImport
            rfqPk={rfq?.pk}
            rfqName={rfq?.rfq_display_name}
            onlyExcelImport={onlyExcelImport}
          />
        ) : null}

        <div className={classes.summary}>
          <CreateMultiContractSummary
            groupedMultiContractData={groupedMultiContractData}
            suppliersOptions={suppliersOptions}
            errorsCount={importMultiContractRFQExcelReport?.errors?.length ?? 0}
            actualRfqPk={rfq?.pk}
          />
        </div>
      </div>

      <>
        {!isSummary && !onlyExcelImport ? <Divider /> : null}

        <div
          className={
            !onlyExcelImport || isExcelImportReportAvailable
              ? classes.footer
              : ""
          }
        >
          {createMultiContractRFQData?.length > 0 ? (
            <Button variant={"outlined"} onClick={onClose}>
              {t("common:close")}
            </Button>
          ) : (
            <>
              {!onlyExcelImport && (
                <Typography variant="body2" className={classes.stepText}>
                  {t("project:multiContract:step", {
                    count: isSummary ? "2" : "1",
                  })}
                </Typography>
              )}

              {!onlyExcelImport || isExcelImportReportAvailable ? (
                <Button
                  color={isSummary ? "inherit" : "primary"}
                  variant={isSummary ? "outlined" : "contained"}
                  onClick={onClickBackNextButton}
                  disabled={
                    createMultiContractRFQLoading ||
                    exportMultiContractRFQExcelLoading ||
                    importMultiContractRFQExcelLoading
                  }
                >
                  {t(isSummary ? "common:back" : "common:next")}
                </Button>
              ) : null}

              {isSummary ? (
                <CustomButton
                  loading={createMultiContractRFQLoading}
                  color="primary"
                  variant="contained"
                  className={classes.validateButton}
                  startIcon={<GavelIcon />}
                  onClick={onClickValidateSelection}
                >
                  {t("project:multiContract:validateSelection")}
                </CustomButton>
              ) : null}
            </>
          )}
        </div>
      </>
    </div>
  );
};
