import { styled } from "@mui/material";
import { DataGridProV5 } from "@next/components/data-grid-pro-v5";

export const StyledDataGridPro = styled(DataGridProV5)(({ theme }) => ({
  width: "100%",
  height: "100%",
  "& .MuiDataGrid-columnSeparator": {
    display: "none",
  },
  "& .MuiDataGrid-row.Mui-selected": {
    backgroundColor: `${theme.palette.background.default} !important`,
  },
  "& .MuiDataGrid-row": {
    borderTop: "none",
    borderBottom: `1px solid ${theme.palette.divider}`,
  },
  "& .MuiDataGrid-cell": {
    borderRight: `1px solid ${theme.palette.divider}`,
  },
  "& .MuiDataGrid-cell:last-child": {
    borderRight: "none",
  },
}));
