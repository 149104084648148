import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { PartialStep, vendorManagementActions } from "../../redux";
import {
  selectIsThereUnsavedChangesInFormStep,
  selectVisibleSteps,
} from "../../redux/selectors";
import { getActiveStepIndex } from "../../utils/getActiveStepIndex";
import * as S from "./form-step-box.styled";

type FormStepBoxProps = {
  name: string;
  isActive: boolean;
  isCompleted: boolean | undefined;
  isPartiallyFilled: boolean;
  stepIndex: number;
};

export const FormStepBox: React.FC<FormStepBoxProps> = ({
  name,
  isActive,
  isCompleted,
  isPartiallyFilled,
  stepIndex,
}) => {
  const dispatch = useDispatch();
  const isThereUnsavedChanges = useSelector(
    selectIsThereUnsavedChangesInFormStep
  );
  const steps = useSelector(selectVisibleSteps);
  const activeFormStepIndex = getActiveStepIndex(steps);

  const handleClick = () => {
    if (activeFormStepIndex !== stepIndex) {
      if (!isThereUnsavedChanges) {
        setActiveFormStepIndex(stepIndex);
      } else {
        const confirmLeave = window.confirm(
          "You have unsaved changes, are you sure you want to leave?"
        );
        if (confirmLeave) {
          // initially, isThereUnsavedChanges must be false when new form is rendered
          dispatch(
            vendorManagementActions.setIsThereUnsavedChangesInFormStep(false)
          );
          setActiveFormStepIndex(stepIndex);
        }
      }
    }
  };

  const setActiveFormStepIndex = (index: number) => {
    const step = steps[index];
    const partialStep: PartialStep = {
      is_active: true,
    };
    dispatch(
      vendorManagementActions.updateStepRequest({
        stepId: step.id,
        data: partialStep,
      })
    );
    dispatch(vendorManagementActions.makeFormDataUndefined()); // to reset form data
  };

  const StepIcon = () => {
    if (isCompleted) {
      return <S.StyledCompleteIcon />;
    }

    if (isPartiallyFilled) {
      return <S.StyledPartialCompleteIcon />;
    }

    return <S.StyledIncompleteIcon />;
  };

  return (
    <S.StyledBox active={isActive} onClick={handleClick}>
      <StepIcon />

      <S.StyledTypography>{name}</S.StyledTypography>
    </S.StyledBox>
  );
};
