import React from "react";
import { GridRenderCellParams, GridRowId } from "@mui/x-data-grid-pro-v5";
import IconButton from "@mui/material/IconButton";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "inline-flex",
    alignItems: "center",
    gap: theme.spacing(1),
    color: theme.palette.text.secondary,
  },
  textPrimary: {
    color: theme.palette.text.primary,
  },
}));

export const RowMenuCell = (
  props: GridRenderCellParams & {
    onClickDelete?: (id: GridRowId) => void;
  }
) => {
  const { api, id, onClickDelete } = props;
  const classes = useStyles();

  const handleDeleteClick = (event: any) => {
    event.stopPropagation();

    if (onClickDelete) {
      onClickDelete(id);
    } else {
      api.updateRows([{ id, _action: "delete" }]);
    }
  };

  return (
    <div className={classes.root}>
      <IconButton
        color="inherit"
        size="small"
        aria-label="delete"
        onClick={handleDeleteClick}
      >
        <DeleteOutlineOutlinedIcon
          fontSize="small"
          style={{ color: "black" }}
        />
      </IconButton>
    </div>
  );
};
