import React from "react";

import { BulkFileImportSummaryItemType } from "./bulk-file-import-summary-item";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import HelpIcon from "@mui/icons-material/Help";
import CancelIcon from "@mui/icons-material/Cancel";
import { AppTheme } from "layout/AppTheme";
import { Trans } from "react-i18next";
import { BomFilesUploadReport } from "@next/modules/project/redux";

export const getSummaryItemText = (
  type: BulkFileImportSummaryItemType,
  report: Partial<BomFilesUploadReport>
) => {
  switch (type) {
    case BulkFileImportSummaryItemType.SUCCESS:
      return (
        <Trans
          i18nKey="project:bulkFileImport:summary:success"
          components={[<b />]}
          values={{
            successFilesCount: report?.files_success_count,
            partsCount: report?.parts_success_count,
          }}
        />
      );

    case BulkFileImportSummaryItemType.WARNING:
      return (
        <Trans
          i18nKey="project:bulkFileImport:summary:warning"
          components={[<b />]}
          values={{ filesCount: report?.files_warning_count }}
        />
      );

    case BulkFileImportSummaryItemType.ERROR:
      return (
        <Trans
          i18nKey="project:bulkFileImport:summary:error"
          components={[<b />]}
          values={{ errorFilesCount: report?.files_error_count }}
        />
      );

    case BulkFileImportSummaryItemType.ATTACHMENT:
      return (
        <Trans
          i18nKey="project:bulkFileImport:summary:warning"
          components={[<b />]}
          values={{ filesCount: report?.rfq_attachments?.length }}
        />
      );

    default:
      return null;
  }
};

export const getSummaryItemIcon = (type: BulkFileImportSummaryItemType) => {
  const commonStyle = { width: "20px", height: "20px" };

  switch (type) {
    case BulkFileImportSummaryItemType.SUCCESS:
      return (
        <CheckCircleIcon
          style={{
            ...commonStyle,
            color: AppTheme.palette.success.main,
          }}
        />
      );
    case BulkFileImportSummaryItemType.WARNING:
      return (
        <HelpIcon
          style={{
            ...commonStyle,
            color: AppTheme.palette.warning.main,
          }}
        />
      );
    case BulkFileImportSummaryItemType.ERROR:
      return (
        <CancelIcon
          style={{
            ...commonStyle,
            color: AppTheme.palette.error.main,
          }}
        />
      );
    case BulkFileImportSummaryItemType.ATTACHMENT:
      return (
        <CheckCircleIcon
          style={{
            ...commonStyle,
            color: AppTheme.palette.success.main,
          }}
        />
      );
    default:
      return null;
  }
};
