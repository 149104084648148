import { Box, Button, Paper, Theme, Typography } from "@mui/material";
import { CustomTypography } from "@next/components/custom-typography";
import React from "react";
import VerifiedUserIcon from "@mui/icons-material/VerifiedUser";
import { AppTheme } from "layout/AppTheme";
import CallIcon from "@mui/icons-material/Call";
import { useTranslation } from "react-i18next";
import { getPremiumSupplierSubscriptionCost } from "@next/utils/constantUtils";
import { PREMIUM_SUPPLIER_SUBSCRIPTION_LINK } from "@next/constants";
import { createStyles, makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    premiumInfoCard: {
      padding: "16px",
      boxShadow: "none",
      backgroundColor: "#FAFAFA",
      marginTop: "16px",
    },
    monthText: {
      color: theme.palette.text.disabled,
    },
    callIcon: {
      width: "24px",
      height: "24px",
      color: theme.palette.text.disabled,
    },
  })
);

export const JoinPrivateB2BNetwork = () => {
  const classes = useStyles();
  const { t } = useTranslation();

  const onClickUpgradePlan = () => {
    window.open(PREMIUM_SUPPLIER_SUBSCRIPTION_LINK, "_blank", "noreferrer");
  };

  return (
    <Box>
      <Typography variant="h6">
        {t("marketPlace:joinPrivateB2BNetwork:subTitle")}
      </Typography>

      <Paper className={classes.premiumInfoCard}>
        <CustomTypography variant="body1" color="textSecondary">
          {t("marketPlace:joinPrivateB2BNetwork:premiumMembership")}
        </CustomTypography>

        <Box mt="16px" display="flex">
          <CustomTypography variant="h5">
            {getPremiumSupplierSubscriptionCost()}/
          </CustomTypography>
          <CustomTypography variant="h5" className={classes.monthText}>
            {t("marketPlace:joinPrivateB2BNetwork:month")}
          </CustomTypography>
        </Box>

        <JoinPrivateB2BNetworkInfoItem
          text={t("marketPlace:joinPrivateB2BNetwork:premiumInfo1")}
        />
        <JoinPrivateB2BNetworkInfoItem
          text={t("marketPlace:joinPrivateB2BNetwork:premiumInfo2")}
        />
        <JoinPrivateB2BNetworkInfoItem
          text={t("marketPlace:joinPrivateB2BNetwork:premiumInfo3")}
        />
      </Paper>

      <Box mt="16px">
        <Button
          fullWidth
          size="large"
          variant="contained"
          color="primary"
          onClick={onClickUpgradePlan}
        >
          {t("marketPlace:joinPrivateB2BNetwork:upgradePlan")}
        </Button>
      </Box>

      <Box display="flex" justifyContent="center" mt="16px">
        <CallIcon className={classes.callIcon} />

        <Box ml="8px">
          <CustomTypography variant="body1">
            {t("marketPlace:joinPrivateB2BNetwork:anyQuestionContactUs")}
          </CustomTypography>
        </Box>
      </Box>
    </Box>
  );
};

export const JoinPrivateB2BNetworkInfoItem: React.FC<{ text: string }> = ({
  text,
}) => {
  return (
    <Box display="flex" mt="16px">
      <VerifiedUserIcon style={{ color: AppTheme.palette.success.main }} />

      <Box ml="8px">
        <CustomTypography variant="body1">{text}</CustomTypography>
      </Box>
    </Box>
  );
};
