import { useDispatch, useSelector } from "react-redux";

import { ProjectCompanyPart } from "@next/modules/project/redux";

import { getSellerDashboardUserViewsTableColumns } from "./seller-dashboard-user-views-table.columns";
import { GridColDef } from "@mui/x-data-grid-pro-v5";
import { useEffect, useState } from "react";
import { dashboardActions } from "../../redux";
import {
  selectCompanyProfileViews,
  selectCompanyProfileViewsCount,
  selectCompanyProfileViewsLoading,
} from "../../redux/selectors";
import { generatePaginationAndSearchQuery } from "@next/utils/paginationUtils-v5";

export type GridStatePartData = {
  rows: Partial<ProjectCompanyPart>[];
  columns: GridColDef[];
};

export const useSellerDashboardUserViewsTableData = () => {
  const dispatch = useDispatch();
  const [currentPage, setCurrentPage] = useState(1);

  const companyProfileViews = useSelector(selectCompanyProfileViews);
  const companyProfileViewsCount = useSelector(selectCompanyProfileViewsCount);
  const companyProfileViewsLoading = useSelector(
    selectCompanyProfileViewsLoading
  );

  useEffect(() => {
    dispatch(
      dashboardActions.fetchCompanyProfileViewsRequest({
        query: generatePaginationAndSearchQuery({
          currentPage,
          pageSize: 7,
        }),
      })
    );

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPage]);

  return {
    currentPage,
    setCurrentPage,
    gridData: {
      rows: companyProfileViews || [],
      columns: getSellerDashboardUserViewsTableColumns() || [],
    },
    count: companyProfileViewsCount,
    loading: companyProfileViewsLoading,
  };
};
