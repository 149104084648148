import React, { useEffect } from "react";
import { Box, Button, Grid, Typography } from "@mui/material";
import { AnalyticCard } from "../components/analytic-card/analytic-card";
import { AnalyticGraphCard } from "../components/analytic-graph-card/analytic-graph-card";
import { RequestsReportStackedColumnChart } from "../components/requests-report/requests-report-stacked-column-chart/requests-report-stacked-column-chart";
import { styled } from "@material-ui/core";
import { RequestsReportCumulativeColumnChart } from "../components/requests-report/requests-report-cumulative-column-chart/requests-report-cumulative-column-chart";
import { AnalyticFilters } from "../components/analytic-filters/analytic-filters";
import { useDispatch, useSelector } from "react-redux";
import { analyticsActions } from "../redux";
import {
  selectAnalyticFilters,
  selectRequestsReport,
  selectRequestsReportError,
  selectRequestsReportLoading,
} from "../redux/selectors";
import { RequestsReportTable } from "../components/requests-report/requests-report-table/requests-report-table";
import { formatNumber } from "helpers/formatNumber";
import { LoadingScreen } from "../components/loading-screen/loading-screen";
import { AnalyticCardBeta } from "../components/analytic-card-beta/analytic-card-beta";
import { useTranslation } from "react-i18next";
import RefreshIcon from "@mui/icons-material/Refresh";

const RootDiv = styled("div")({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  width: "100%",
});

const MessageDiv = styled("div")({
  position: "fixed",
  left: "0",
  top: "0",
  width: "100%",
  height: "100%",
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
});

export const RequestsReport = () => {
  const dispatch = useDispatch();
  const requestReport = useSelector(selectRequestsReport);
  const requestsReportLoading = useSelector(selectRequestsReportLoading);
  const requestsReportError = useSelector(selectRequestsReportError);
  const analyticFilters = useSelector(selectAnalyticFilters);
  const { t } = useTranslation();

  useEffect(() => {
    dispatch(analyticsActions.fetchRequestsReportRequest(analyticFilters));
  }, [analyticFilters]);

  if (requestsReportError) {
    return (
      <MessageDiv>
        <Typography variant="h5">{requestsReportError.message}</Typography>
        <Button onClick={() => window.location.reload()}>
          {<RefreshIcon fontSize="large" color="primary" />}
        </Button>
      </MessageDiv>
    );
  }

  if (
    requestReport === undefined &&
    requestsReportError === undefined &&
    requestsReportLoading === false
  ) {
    return (
      <MessageDiv>
        <Typography variant="h5">{"No data received."}</Typography>
      </MessageDiv>
    );
  }

  return (
    <RootDiv>
      <AnalyticFilters isInSupplierReport={false} />

      {requestsReportLoading || !requestReport ? (
        <LoadingScreen />
      ) : (
        <Box p="36px" pt="24px" sx={{ marginTop: "24px", width: "100%" }}>
          <Grid container spacing={2}>
            <Grid item container spacing={2}>
              <Grid item xs={12} sm={6} md={3} lg={3}>
                <AnalyticCard
                  title={t("analytics:requestsReport:cards:targetedSupplier")}
                  value={requestReport?.targeted_supplier_count}
                />
              </Grid>

              <Grid item xs={12} sm={6} md={3} lg={3}>
                <AnalyticCard
                  title={t("analytics:requestsReport:cards:rfqsCount")}
                  value={requestReport?.rfqs_count}
                />
              </Grid>

              <Grid item xs={12} sm={6} md={3} lg={3}>
                <AnalyticCard
                  title={t("analytics:requestsReport:cards:closedItems")}
                  value={requestReport?.parts_count}
                />
              </Grid>

              <Grid item xs={12} sm={6} md={3} lg={3}>
                <AnalyticCard
                  title={t("analytics:requestsReport:cards:totalValue")}
                  value={`${formatNumber(requestReport?.total_value)} $`}
                />
              </Grid>
            </Grid>

            <Grid item container spacing={2}>
              <Grid item xs={12} sm={6} md={3} lg={3}>
                <AnalyticCard
                  title={t(
                    "analytics:requestsReport:cards:singleSourcing:text"
                  )}
                  value={`${requestReport?.single_sourcing_percentage}%`}
                  smallLabel={
                    requestReport?.single_sourcing_count +
                    " " +
                    t("analytics:global:items")
                  }
                  tooltipTitle={t(
                    "analytics:requestsReport:cards:singleSourcing:tooltipTitle"
                  )}
                />
              </Grid>

              <Grid item xs={12} sm={6} md={3} lg={3}>
                <AnalyticCard
                  title={t("analytics:requestsReport:cards:averageQuote")}
                  value={requestReport?.average_quotes_per_rfq}
                />
              </Grid>

              <Grid item xs={12} sm={6} md={3} lg={3}>
                <AnalyticCard
                  title={t("analytics:requestsReport:cards:responseRate:text")}
                  value={requestReport?.response_rate + "%"}
                  tooltipTitle={t(
                    "analytics:requestsReport:cards:responseRate:tooltipTitle"
                  )}
                />
              </Grid>

              <Grid item xs={12} sm={6} md={3} lg={3}>
                <AnalyticCardBeta />
              </Grid>
            </Grid>

            <Grid item xs={12}>
              <AnalyticGraphCard
                content={{
                  mainTitle: t("analytics:requestsReport:charts:volume:title"),
                  cardContents: [
                    {
                      title: t(
                        "analytics:requestsReport:charts:volume:cards:closedItems"
                      ),
                      boldTypographyContent: `${formatNumber(
                        requestReport?.parts_count
                      )}`,
                    },
                    {
                      title: t(
                        "analytics:requestsReport:charts:volume:cards:valueAsl"
                      ),
                      boldTypographyContent: `${formatNumber(
                        requestReport?.total_value_private
                      )} $`,
                    },
                    {
                      title: t(
                        "analytics:requestsReport:charts:volume:cards:valueMarket"
                      ),
                      boldTypographyContent: `${formatNumber(
                        requestReport?.total_value_public
                      )} $`,
                    },
                  ],
                }}
                GraphComponent={
                  <RequestsReportCumulativeColumnChart
                    id={requestReport?.charts[0].id}
                    x_axis={requestReport?.charts[0].x_axis}
                    y_axes={requestReport?.charts[0].y_axes}
                  />
                }
              />
            </Grid>

            <Grid item xs={12}>
              <AnalyticGraphCard
                content={{
                  mainTitle: t(
                    "analytics:requestsReport:charts:supplierResponseRate:title"
                  ),
                  cardContents: [
                    {
                      title: t(
                        "analytics:requestsReport:charts:supplierResponseRate:cards:quote"
                      ),
                      boldTypographyContent: `${requestReport?.quoted_quotation_parts_percentage}%`,
                      smallTypographyContent: `${formatNumber(
                        requestReport?.quoted_quotation_parts_count
                      )} ${t("analytics:global:items")}`,
                    },
                    {
                      title: t(
                        "analytics:requestsReport:charts:supplierResponseRate:cards:noResponse"
                      ),
                      boldTypographyContent: `${requestReport?.no_response_quotation_parts_percentage}%`,
                      smallTypographyContent: `${formatNumber(
                        requestReport?.no_response_quotation_parts_count
                      )} ${t("analytics:global:items")}`,
                    },
                    {
                      title: t(
                        "analytics:requestsReport:charts:supplierResponseRate:cards:noQuote"
                      ),
                      boldTypographyContent: `${requestReport?.no_quoted_quotation_parts_percentage}%`,
                      smallTypographyContent: `${formatNumber(
                        requestReport?.no_quoted_quotation_parts_count
                      )} ${t("analytics:global:items")}`,
                    },
                  ],
                }}
                GraphComponent={
                  <RequestsReportStackedColumnChart
                    id={requestReport?.charts[1].id}
                    x_axis={requestReport?.charts[1].x_axis}
                    y_axes={requestReport?.charts[1].y_axes}
                  />
                }
              />
            </Grid>

            <Grid item xs={12}>
              <RequestsReportTable
                id={requestReport?.tables[0].id}
                rows={requestReport?.tables[0].rows}
              />
            </Grid>
          </Grid>
        </Box>
      )}
    </RootDiv>
  );
};
