// General imports
import React, { useEffect, useState } from "react";

// Material UI
import { Box, Card, CardContent, Grid, InputAdornment } from "@mui/material";
import { Field } from "formik";

// Components
import { FormikGenericTextFieldBlackBorder } from "../../../../components/css-text-field/css-text-field.component";
import { PartTilesDetails } from "../../../../components/rfqs-details-summary/details-summary-part-tiles/part-tiles-details/part-tiles-details.component";
import { CurrencyFormatCustom } from "../currency-format-custom/currency-format-custom.component";
import PartDetailsDialog from "../../../../components/part-dialog/part-details-dialog.component";
import CurrencyField from "../../../../components/common/fields/CurrencyField";

export const PartTile = (props) => {
  const {
    part,
    onPartPriceFieldChange,
    quotation,
    values,
    setFieldValue,
    t,
    nda,
    index,
    touched,
    errors,
  } = props;
  const [totalPartPrice, setTotalPartPrice] = useState(0);
  const [open, setOpen] = useState(false);

  const _onPartPriceFieldChange = (e) => {
    const price = e.target.value
      ? parseFloat(e.target.value.replace(",", "."))
      : 0;
    const partId = part.pk;
    const qt = part.quantity;
    setTotalPartPrice(Number.parseFloat(qt * price).toFixed(2));
    onPartPriceFieldChange(partId, qt, price);
    setFieldValue("unitPrice", price);
  };

  useEffect(() => {
    const qt = part.quantity;
    const partId = part.pk;
    const price =
      values["unitPrice"] && values["unitPrice"][index]
        ? values["unitPrice"][index]
        : "";
    if (qt && price) {
      setTotalPartPrice(Number.parseFloat(qt * price).toFixed(2));
      onPartPriceFieldChange(partId, qt, price);
      setFieldValue("unitPrice", price ? price : "");
    }
  }, [part, values, index]);

  const clickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Grid item xs={12} className="c-part-tile">
      <Card elevation={0} className="c-part-tile--container">
        <PartTilesDetails
          nda={nda}
          part={part}
          clickOpen={clickOpen}
          quotation={{ rfqPk: quotation["pk"], rfqName: quotation["rfq_name"] }}
          t={t}
          isSeller={true}
        />
        <CardContent className="c-part-tile--price-container">
          <Box pl={4} pb={7.5} pt={7.5} className="c-part-tile--quantity">
            <Grid
              container
              direction="row"
              justifyContent="space-around"
              alignItems="center"
            >
              <Grid item xs={4}>
                <Box
                  textAlign="left"
                  fontWeight="fontWeightRegular"
                  fontSize={16}
                >
                  {t("quantity")}
                </Box>
              </Grid>
              <Grid item xs={4}>
                <Box fontWeight="fontWeightMedium" fontSize="h6.fontSize">
                  {part.quantity}
                </Box>
              </Grid>
              <Grid item xs={4}></Grid>
            </Grid>
          </Box>
          <Box
            pl={4}
            pr={4}
            pt={5.5}
            pb={5.5}
            className="c-part-tile--card--flex-grow"
          >
            <Grid
              container
              direction="row"
              justifyContent="space-between"
              alignItems="center"
            >
              <Grid item xs={4}>
                <Box
                  textAlign="left"
                  fontWeight="fontWeightRegular"
                  fontSize={16}
                >
                  {t("unitPrice")}
                </Box>
              </Grid>
              <Grid item xs={3}>
                <Field
                  name="unitPrice"
                  component={FormikGenericTextFieldBlackBorder}
                  textFieldProps={{
                    value: values["unitPrice"][index],
                    onChange: _onPartPriceFieldChange,
                    className: `${nda ? "nda-form-disable" : ""}`,
                    variant: "outlined",
                    fullWidth: true,
                    InputProps: {
                      inputComponent: CurrencyFormatCustom,
                      startAdornment: (
                        <InputAdornment position="end">$</InputAdornment>
                      ),
                    },
                  }}
                />
              </Grid>
              <Grid item xs={3}>
                <Box
                  textAlign="right"
                  fontWeight="fontWeightMedium"
                  fontSize="h6.fontSize"
                >
                  <CurrencyField value={totalPartPrice} />
                </Box>
              </Grid>
            </Grid>
          </Box>
        </CardContent>
      </Card>
      <PartDetailsDialog
        isOpen={open}
        part={part}
        onClose={handleClose}
        {...props}
        t={t}
      />
    </Grid>
  );
};
