import { AppBar, Box, Theme, Toolbar } from "@mui/material";
import { useHomePageLink } from "@next/hooks/useHomePageLink";
import { SharedModals } from "@next/modals/shared-modals";
import React, { PropsWithChildren } from "react";
import { Link } from "react-router-dom";
import NextAxyaLogo from "@next/assets/nextLogo.png";
import LanguageToggle from "@next/components/language-toggle/language-toggle";
import { createStyles, makeStyles } from "@mui/styles";

const useStyles = makeStyles<Theme>((theme: Theme) =>
  createStyles({
    root: {
      height: "100%",
    },
    logo: {
      width: "24px",
      height: "24px",
    },
    appBar: {
      borderBottom: `1px solid ${theme.palette.divider}`,
      backgroundColor: "white",
      color: "black",
      boxShadow: "none",
      zIndex: 1199,
    },
    toolbarRoot: {
      paddingLeft: "16px",
      width: "100%",
      display: "flex",
      justifyContent: "space-between",
    },
    itemsContainer: {},
  })
);

export const PublicLayout: React.FC<
  PropsWithChildren<{ showAppBar: boolean }>
> = ({ children, showAppBar }) => {
  const classes = useStyles();
  const homePageLink = useHomePageLink();

  return (
    <div className={classes.root}>
      {showAppBar ? (
        <AppBar position="static" className={classes.appBar}>
          <Toolbar className={classes.toolbarRoot}>
            <Link to={homePageLink}>
              <img src={NextAxyaLogo} alt="Axya" className={classes.logo} />
            </Link>

            <Box className={classes.itemsContainer}>
              <LanguageToggle noBg iconColor="black" />
            </Box>
          </Toolbar>
        </AppBar>
      ) : null}

      {children}

      {/* Shared Modals */}
      <SharedModals />
    </div>
  );
};
