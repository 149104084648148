import React from "react";
import { Box } from "@mui/material";
import { CustomTypography } from "@next/components/custom-typography";
import * as S from "./analytic-card.styled";
import { ModifiedTooltip } from "../analytic-tooltip/analytic-tooltip";

type PropType = {
  title: string;
  value: string | number;
  smallLabel?: string;
  tooltipTitle?: string;
};

export const AnalyticCard = ({
  title,
  value,
  smallLabel,
  tooltipTitle,
}: PropType) => {
  return (
    <S.RootBox>
      <Box>
        <CustomTypography
          tooltipTitle={null}
          variant="body1"
          color="textSecondary"
        >
          {title}
          <span style={{ marginLeft: "8px" }}>
            <ModifiedTooltip tooltipTitle={tooltipTitle} />
          </span>
        </CustomTypography>
      </Box>

      <Box display="flex" alignItems="center" mt="16px">
        <CustomTypography fontFamily={"Space Grotesk"} variant="h4">
          {value}
        </CustomTypography>

        {smallLabel && (
          <S.StyledTypography color="textSecondary">
            {smallLabel}
          </S.StyledTypography>
        )}
      </Box>
    </S.RootBox>
  );
};
