import React, { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import CloseIcon from "@mui/icons-material/Close";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  IconButton,
  TextField,
  Theme,
  Typography,
} from "@mui/material";
import { createStyles, makeStyles } from "@mui/styles";
import { useDispatch, useSelector } from "react-redux";

import { getModal, modalsActions } from "@next/redux/modalsSlices";
import { CircularAvatarsList } from "@next/components/circular-avatars-list";

import { ProjectModalTypes, ShortlistSuppliersModalData } from "./types";
import { selectRfiShortlistLoading } from "services/workspace/workspace.selectors";
import CustomButton from "@next/components/custom-button";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    dialogTitle: { display: "flex", alignItems: "center" },
    dialogTitleText: {
      display: "flex",
      alignItems: "center",
      flexGrow: 1,
    },
    dialogContent: {
      display: "flex",
      flexDirection: "column",
      gap: "24px",
      marginTop: "4px",
      marginBottom: "4px",
    },
    dialogAction: {
      display: "flex",
      padding: "1rem",
      gap: "8px",
    },
    dialogActionNote: { flex: "1 0 0" },
    paper: { minHeight: "264px" },
    recipients: {
      display: "flex",
      alignItems: "center",
      gap: "12px",
    },
    note: { width: "100%" },
  })
);

export const ProjectShortlistSupplierModal = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const modal = useSelector(
    getModal(ProjectModalTypes.SHORTLIST_SUPPLIER_MODAL)
  );
  const [winnersNote, setWinnersNote] = useState("");
  const [losersNote, setLosersNote] = useState("");

  const isLoading = useSelector(selectRfiShortlistLoading);

  const data = modal?.data;

  const { rfq, winnerIDs, onSuccess } =
    (data as ShortlistSuppliersModalData) || {};

  const winners = useMemo(
    () =>
      (rfq?.quotations || [])
        .filter(({ company_info }) =>
          winnerIDs.includes(company_info.company_pk)
        )
        .map((quotation) => ({
          id: quotation.company_info.company_pk,
          name: quotation.company_info.company_name,
          image: quotation.company_info.company_picture,
        })),
    [data, rfq?.quotations, winnerIDs]
  );

  const losers = useMemo(
    () =>
      (rfq?.quotations || [])
        .filter(
          ({ company_info }) => !winnerIDs.includes(company_info.company_pk)
        )
        .map((quotation) => ({
          id: quotation.company_info.company_pk,
          name: quotation.company_info.company_name,
          image: quotation.company_info.company_picture,
        })),
    [data, rfq?.quotations, winnerIDs]
  );

  if (!modal || !data) {
    return null;
  }

  const onClose = () => {
    dispatch(
      modalsActions.closeModal(ProjectModalTypes.SHORTLIST_SUPPLIER_MODAL)
    );
  };

  return (
    <Dialog
      open={!!modal}
      onClose={onClose}
      closeAfterTransition
      fullWidth={true}
      maxWidth="sm"
      classes={{ paper: classes.paper }}
    >
      <DialogTitle className={classes.dialogTitle}>
        <Box className={classes.dialogTitleText}>
          <Typography variant="h6">
            {t("rfq:quotations:shortlist:noteForSuppliers")}
          </Typography>
        </Box>

        <IconButton onClick={onClose} size="large">
          <CloseIcon />
        </IconButton>
      </DialogTitle>

      <Divider />

      <DialogContent className={classes.dialogContent}>
        <div className={classes.recipients}>
          <Typography>{t("orders:sendOrderDrawer:to")}</Typography>
          <CircularAvatarsList
            size="small"
            images={winners.map(({ name, image }) => ({
              name: name,
              url: image,
              tooltip: name,
            }))}
          />
          {winners.length > 0 && (
            <Typography>
              {winners.length > 1
                ? `${winners.length} ` + t("rfq:quotations:shortlist:suppliers")
                : winners[0].name}
            </Typography>
          )}
          <Typography variant="body2" color="textSecondary">
            {t("rfq:quotations:shortlist:awarded")}
          </Typography>
        </div>
        <TextField
          className={classes.note}
          multiline
          maxRows={10}
          size="small"
          variant="outlined"
          placeholder={t("rfq:quotations:shortlist:noteOptional")}
          value={winnersNote}
          onChange={(e) => setWinnersNote(e.target.value)}
        />

        {losers.length > 0 && (
          <React.Fragment>
            <Divider />

            <div className={classes.recipients}>
              <Typography>{t("orders:sendOrderDrawer:to")}</Typography>
              <CircularAvatarsList
                size="small"
                images={losers.map(({ name, image }) => ({
                  name: name,
                  url: image,
                  tooltip: name,
                }))}
              />
              {losers.length > 0 && (
                <Typography>
                  {losers.length > 1
                    ? `${losers.length} ` +
                      t("rfq:quotations:shortlist:suppliers")
                    : losers[0].name}
                </Typography>
              )}
              <Typography variant="body2" color="textSecondary">
                {t("rfq:quotations:shortlist:notAwarded")}
              </Typography>
            </div>

            <TextField
              className={classes.note}
              multiline
              maxRows={10}
              size="small"
              variant="outlined"
              placeholder={t("rfq:quotations:shortlist:noteOptional")}
              value={losersNote}
              onChange={(e) => setLosersNote(e.target.value)}
            />
          </React.Fragment>
        )}
      </DialogContent>

      <Divider />

      <DialogActions className={classes.dialogAction}>
        <Typography variant="body2" className={classes.dialogActionNote}>
          {t("rfq:quotations:shortlist:individualNote")}
        </Typography>

        <Button
          variant="outlined"
          size="large"
          color="secondary"
          onClick={onClose}
        >
          {t("common:cancel")}
        </Button>
        <CustomButton
          variant="contained"
          size="large"
          color="primary"
          loading={isLoading}
          disabled={isLoading}
          onClick={() => {
            onSuccess(winnerIDs, winnersNote, losersNote);
            onClose();
          }}
        >
          {t("common:submit")}
        </CustomButton>
      </DialogActions>
    </Dialog>
  );
};
