import React, { useEffect } from "react";
import { Box, Grid, styled } from "@mui/material";
import { AnalyticCard } from "../components/analytic-card/analytic-card";
import {
  AnalyticGraphCard,
  ContentType,
} from "../components/analytic-graph-card/analytic-graph-card";
import { SupplierReportColumnChart } from "../components/supplier-report/supplier-report-column-chart/supplier-report-column-chart";
import { useDispatch, useSelector } from "react-redux";
import {
  selectSupplierReport,
  selectSupplierReportLoading,
} from "../redux/selectors";
import { analyticsActions } from "../redux";
import { formatNumber } from "helpers/formatNumber";
import { AnalyticFilters } from "../components/analytic-filters/analytic-filters";
import { LoadingScreen } from "../components/loading-screen/loading-screen";

const chartContent: ContentType = {
  mainTitle: "Spending per supplier",
};

const RootDiv = styled("div")({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  width: "100%",
});

export const SupplierReport = () => {
  const dispatch = useDispatch();
  const supplierReport = useSelector(selectSupplierReport);
  const supplierReportLoading = useSelector(selectSupplierReportLoading);

  useEffect(() => {
    dispatch(analyticsActions.fetchSupplierReportRequest());
  }, []);

  if (supplierReportLoading || !supplierReport) {
    return <LoadingScreen />;
  }

  return (
    <RootDiv>
      <AnalyticFilters isInSupplierReport={true} />

      <Box pt="24px" sx={{ marginTop: "72px", width: "100%" }}>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6} md={6} lg={6}>
            <AnalyticCard
              title={"Active suppliers"}
              tooltipTitle={
                "Total number of suppliers that have at least one order or request sent on Axya"
              }
              value={supplierReport.active_suppliers.toString()}
            />
          </Grid>

          <Grid item xs={12} sm={6} md={6} lg={6}>
            <AnalyticCard
              title={"Total spend (CAD $)"}
              value={formatNumber(supplierReport.total_spent)}
            />
          </Grid>

          <Grid item xs={12}>
            <AnalyticGraphCard
              content={chartContent}
              GraphComponent={
                <SupplierReportColumnChart
                  id={supplierReport.charts[0].id}
                  x_axis={supplierReport.charts[0].x_axis}
                  y_axes={supplierReport.charts[0].y_axes}
                />
              }
            />
          </Grid>
        </Grid>
      </Box>
    </RootDiv>
  );
};
