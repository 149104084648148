import React from "react";

import { t } from "assets/configi18n/i18n";
import { AppTheme } from "layout/AppTheme";
import ErrorRoundedIcon from "@mui/icons-material/ErrorRounded";
import CancelRoundedIcon from "@mui/icons-material/CancelRounded";
import CheckCircleRoundedIcon from "@mui/icons-material/CheckCircleRounded";
import WarningRoundedIcon from "@mui/icons-material/WarningRounded";
import PlayCircleFilledRoundedIcon from "@mui/icons-material/PlayCircleFilledRounded";
import PanToolOutlinedIcon from "@mui/icons-material/PanToolOutlined";
import { RFQStatus } from "@next/modules/project/redux";
import { alpha } from "@mui/material";

export enum Status {
  INPROGRESS = "InProgress",
  WAITING = "Waiting",
  CANCELED = "Canceled",
  ACTIVE = "Active",
  CONTRACT = "Contract",
  ON_HOLD = "ON_HOLD",
  SPLITTED = "Splitted",
}

const baseStyle: React.CSSProperties = {
  cursor: "unset",
  textOverflow: "ellipsis",
  overflow: "hidden",
};

export const getStatusTagStyle = (
  status: string
): React.CSSProperties | undefined => {
  switch (status) {
    case Status.INPROGRESS:
      return {
        ...baseStyle,
        color: AppTheme.palette.primary.main,
        backgroundColor: alpha(AppTheme.palette.primary.main, 0.1),
      };
    case Status.WAITING:
      return {
        ...baseStyle,
        color: AppTheme.palette.error.main,
        backgroundColor: alpha(AppTheme.palette.error.main, 0.1),
      };
    case Status.CANCELED:
      return baseStyle;
    case Status.ON_HOLD:
      return baseStyle;
    default:
      return undefined;
  }
};

export const getStatusTagText = (status: string, statusNote?: string) => {
  const prefix = t(
    `tabView:status:${status === Status.WAITING ? "decisionNeeded" : status}`
  );

  return statusNote ? `${prefix}: ${statusNote}` : prefix;
};

export const getStateTagText = (
  state?: State,
  state_info?: StateInfo,
  quotationsCount?: number
) => {
  if (!state && !state_info) return null;

  return state_info
    ? `${t(`tabView:state:${state}`)}: ${t(`tabView:stateInfo:${state_info}`, {
        count: quotationsCount,
      })}`
    : t(`tabView:state:${state}`);
};

export enum State {
  ON_TRACK = "ON_TRACK",
  AT_RISK = "AT_RISK",
  URGENT = "URGENT",
  READY_FOR_DECISION = "READY_FOR_DECISION",
  BLOCKED = "BLOCKED",
}

export enum StateInfo {
  NO_QUOTE = "NO_QUOTE",
  RFQ_SUBMITTED = "RFQ_SUBMITTED",
  POSITIVE_RESPONSE = "POSITIVE_RESPONSE",
  NO_POSITIVE_RESPONSE = "NO_POSITIVE_RESPONSE",
  RECEIVED_X_QUOTES = "RECEIVED_X_QUOTES",
  RECEIVED_NO_QUOTES = "RECEIVED_NO_QUOTES",
}

export const getStateTagIcon = (state?: string): JSX.Element | undefined => {
  switch (state) {
    case State.ON_TRACK:
      return (
        <PlayCircleFilledRoundedIcon
          style={{ color: AppTheme.palette.primary.main }}
        />
      );
    case State.AT_RISK:
      return (
        <ErrorRoundedIcon style={{ color: AppTheme.palette.warning.main }} />
      );
    case State.URGENT:
      return (
        <WarningRoundedIcon style={{ color: AppTheme.palette.warning.main }} />
      );
    case State.READY_FOR_DECISION:
      return (
        <CheckCircleRoundedIcon
          style={{ color: AppTheme.palette.success.main }}
        />
      );
    case State.BLOCKED:
      return (
        <CancelRoundedIcon style={{ color: AppTheme.palette.error.main }} />
      );
    default:
      return undefined;
  }
};

export const getStatusTagIcon = (status?: string): JSX.Element | undefined => {
  switch (status) {
    case RFQStatus.ON_HOLD:
      return <PanToolOutlinedIcon />;

    default:
      return undefined;
  }
};

export const getStateTagStyle = (
  state: string
): React.CSSProperties | undefined => {
  switch (state) {
    case State.AT_RISK:
      return {
        ...baseStyle,
        color: AppTheme.palette.warning.dark,
        backgroundColor: alpha(AppTheme.palette.warning.main, 0.1),
      };
    case State.URGENT:
      return {
        ...baseStyle,
        color: AppTheme.palette.warning.dark,
        backgroundColor: alpha(AppTheme.palette.warning.main, 0.1),
      };
    case State.BLOCKED:
      return {
        ...baseStyle,
        color: AppTheme.palette.error.dark,
        backgroundColor: alpha(AppTheme.palette.error.main, 0.1),
      };
    case State.READY_FOR_DECISION:
      return {
        ...baseStyle,
        color: AppTheme.palette.success.dark,
        backgroundColor: alpha(AppTheme.palette.success.main, 0.1),
      };
    case State.ON_TRACK:
      return {
        ...baseStyle,
        color: AppTheme.palette.primary.dark,
        backgroundColor: alpha(AppTheme.palette.primary.main, 0.1),
      };
    default:
      return undefined;
  }
};
